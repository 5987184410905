import React, { useState,useEffect, useRef  } from "react";
import { DateTime } from "luxon";
import {
  useGetOutstandingAmountsQuery,
  useGetTopNonPayingCustomersQuery,
} from "../../hasura.graphql";

const cardStyle = {
  flex: "1",
  width: "100%",
  height: "100px",
  padding: "24px",
  gap: "8px",
  margin: "0 4px 8px 0",
  border: "1px solid #e0e0e0",
};

const DQCustomerLossMetrics: React.FC = () => {
  const [dateRange, setDateRange] = useState<string>("12");
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const { loading, error, data } = useGetOutstandingAmountsQuery({
    variables: {
      from_date: getFromDate(dateRange),
      to_date: getToDate(),
    },
  });

const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const nonPayingCustomersQuery = useGetTopNonPayingCustomersQuery();
  const nonPayingCustomersData =
    nonPayingCustomersQuery.data?.cdp_top10_non_paying_cust_mv;

  const [expandedCells, setExpandedCells] = useState<string | null>(null);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const outstandingAmounts = data?.customer_outstanding_amount;
  const churnRevenue = data?.customer_churn_revenue;
  const downsellRevenue = data?.customer_downsell_revenue;

  const totalDueAmount = outstandingAmounts?.find(
    (item) => item?.revenue_type === "outstanding_amounts"
  )?.due_amount;
  const totalOverdueAmount = outstandingAmounts?.find(
    (item) => item?.revenue_type === "outstanding_amounts"
  )?.overdue_amount;
  const totalChurnAmount = churnRevenue?.find(
    (item) => item?.revenue_type === "churn_revenue"
  )?.value;
  const totalDownsellAmount = downsellRevenue?.find(
    (item) => item?.revenue_type === "downsell_revenue"
  )?.value;

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-4">
          <div className="text-xl font-semibold">
            Outstanding / Lost billed revenue
          </div>
        </div>
        <div className="relative">
          <div
            onClick={() => setDropdownOpen(!isDropdownOpen)}
            className="cursor-pointer flex items-center bg-gray-200 rounded-md border border-black p-1 gap-2"
            style={{
              width: "149px",
              height: "32px",
              borderRadius: "4px",
              border: "1px solid #CDD2D5",
              background: "#EDF2F6",
            }}
          >
            <div className="ml-1">{`Last ${dateRange} months`}</div>
            {/* Add your custom calendar icon here */}
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="text-gray-500"
            >
              <path
                d="M21 3H20V1H18V3H6V1H4V3H3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H21C22.1046 21 23 20.1046 23 19V5C23 3.89543 22.1046 3 21 3ZM21 19H3V8H21V19ZM21 6H3V5H21V6Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <div className="relative" ref={dropdownRef}>
          {isDropdownOpen && (
            <div
              className="absolute top-full left-0 mt-1 bg-white border rounded-md overflow-hidden"
              style={{
                width: "149px", // Set the width
                borderRadius: "4px", // Set the radius
                border: "1px solid #e0e0e0",
                padding: "6px 12px", // Set the padding
                gap: "2px", // Set the gap
              }}
            >
              <div
                onClick={() => {
                  setDropdownOpen(false);
                  setDateRange("3");
                }}
                className="text-sm text-gray-800 cursor-pointer p-2 hover:bg-gray-100"
              >
                Last 3 months
              </div>
              <div
                onClick={() => {
                  setDropdownOpen(false);
                  setDateRange("6");
                }}
                className="text-sm text-gray-800 cursor-pointer p-2 hover:bg-gray-100"
              >
                Last 6 months
              </div>
              <div
                onClick={() => {
                  setDropdownOpen(false);
                  setDateRange("12");
                }}
                className="text-sm text-gray-800 cursor-pointer p-2 hover:bg-gray-100"
              >
                Last 12 months
              </div>
            </div>
          )}
        </div>
      </div>
      </div>
      
      <div className="w-full flex gap-2">
        <div className="w-full flex flex-col gap-2">
          <div>
            <h4
              style={{
                fontFamily: "Roboto",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24px",
                color: "#0F0F0F",
              }}
            >
              <div style={{ display: "inline-flex", alignItems: "center" }}>
                Outstanding amounts
                <div className="icon-container">
                  <div className="info-icon" title={"Outstanding Amounts"}>
                    {renderInfoIcon()}
                  </div>
                </div>
              </div>
            </h4>
          </div>
          <div className="w-full flex gap-2">
            {renderCard("Amount due", totalDueAmount)}
            {renderCard("Amount overdue", totalOverdueAmount)}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <div style={{ display: "inline-flex", alignItems: "center" }}>
            <h4
              style={{
                fontFamily: "Roboto",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24px",
                color: "#0F0F0F",
              }}
            >
              Lost revenue
            </h4>
            <div className="icon-container">
              <div className="info-icon" title={"Lost revenue"}>
                {renderInfoIcon()}
              </div>
            </div>
          </div>
          <div className="w-full flex gap-2">
            {renderCard("Churned", totalChurnAmount)}
            {renderCard("Downsell", totalDownsellAmount)}
          </div>
        </div>
      </div>

      <div className="mt-8">
        <div className="font-semibold text-lg mb-4 flex items-center relative">
          Top non-paying customers
          <div className="icon-container">
            <div className="info-icon" title="Top non-paying customers">
              {renderInfoIcon()}
            </div>
          </div>
        </div>
        <div className="flex gap-4">
        {nonPayingCustomersData
    ?.slice(0, 5)
    ?.map((customer: any, index: number) => (
      <div
              key={customer?.account_name}
              className="bg-white p-4 border rounded-md"
              style={{
                borderRadius: "8px",
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "8px 16px",
              }}
            >
        <div className="flex items-center"
           style={{ margin: 0, padding: 0 }}
        >
          <div
            className="logo-box rounded-md bg-lightPrimary"
            style={{
              width: "80px",
              height: "62px",
              overflow: "hidden",
              marginLeft: "-16px",
              marginTop: "-16px",
              marginBottom: "-16px",
              borderRadius: "5px 0 0 5px",
            }}
          >
            {!customer.logo && (
              <div
                className="max-w-full max-h-full mr-2 rounded-lg w-full h-full flex font-semibold text-xl text-gray-300 items-center justify-center object-contain border-b bg-gradient-to-r from-gradientBlue to-gradientOrange"
                style={{
                  width: "100%",
                  height: "100%",
                  border: "0px 0px 1px 0px",
                  margin: 0,
                  padding: 0,
                  borderRadius: "5px 0 0 5px",
                }}
              >
                {customer.account_name
                  ? customer.account_name[0]
                  : "null"}
              </div>
            )}
            {customer.logo && (
              <img
                src={`logos/${customer.logo}`}
                alt={customer.account_name || "null"}
                className="max-w-full max-h-full mr-2"
                style={{
                  width: "100%",
                  height: "100%",
                  border: "0px 0px 1px 0px",
                  margin: 0,
                  padding: 0,
                  borderRadius: "5px 0 0 5px",
                }}
                onError={(e) => {
                  e.currentTarget.src = `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${
                    customer.account_name
                      ? customer.account_name[0]
                      : "null"
                  }`;
                }}
              />
            )}
          </div>
          <div className="flex-grow">
          <span className="text-black ml-2">{index + 1}</span>
          <a
                  href={`/customers/${customer.amagi_id}/details`}
                  className="text-blue-500 ml-2 font-bold"
                  onMouseEnter={() => setExpandedCells(customer.account_name)}
                  onMouseLeave={() => setExpandedCells(null)}
                  style={{ cursor: "pointer" }}
                  >
                  {expandedCells === customer.account_name ||
                  customer.account_name === null
                  ? customer?.account_name || "null"
                  : truncateAccountName(customer?.account_name)}
              </a>
              <div className="text-gray-500 font-bold ml-2">
              {(customer?.final_due_amount / 1_000_000 || 0).toFixed(2)} M USD
            </div>
            </div>
          </div>
        </div>
      ))}
  </div>
  </div>
  </div>
  );
};

export default DQCustomerLossMetrics;

function truncateAccountName(accountName: string | null | undefined) {
  if (!accountName) return "";
  const maxLength = 15;
  return accountName.length > maxLength
    ? accountName.substring(0, maxLength) + "..."
    : accountName;
}

function renderCard(title: string, amount: number | undefined) {
  return (
    <div
      className="w-full bg-white p-4 border border-gray-100 rounded-md text-center"
      style={cardStyle}
      key={title}
    >
      <div>
        <div className="font-bold mb-2 flex items-center justify-center relative">
          {title}
          <div className="icon-container">
            <div className="info-icon" title={title}>
              {renderInfoIcon()}
            </div>
          </div>
        </div>
        <div className="text-2xl font-bold text-gray-800">
          {formatAmount(amount)}
        </div>
      </div>
    </div>
  );
}

function formatAmount(amount: number | undefined) {
  if (amount !== undefined) {
    const amountInMillion = amount / 1_000_000;
    const formattedAmount = `${amountInMillion.toFixed(2)}M USD`;

    // Split the formatted amount into parts
    const [amountValue, currency] = formattedAmount.split(" ");

    return (
      <span>
        <span style={{ fontSize: "1.2em", color: "#0F0F0F" }}>
          {amountValue}
        </span>
        <span style={{ fontSize: "0.8em", color: "grey", marginLeft: "4px" }}>
          {currency}
        </span>
      </span>
    );
  }
  return "0M USD";
}

function renderInfoIcon() {
  return (
    <svg
      width="13.33"
      height="13.33"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="text-black"
      style={{
        width: "25.33px",
        height: "13.33px",
        top: "1.33px",
        left: "4.33px",
      }}
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        fill="rgba(0,0,0,0)"
        stroke="black"
        strokeWidth="2"
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dy=".3em"
        fontSize="14"
        fill="black"
      >
        i
      </text>
    </svg>
  );
}

function getFromDate(monthRange: string) {
  return DateTime.local()
    .startOf("month")
    .minus({ months: parseInt(monthRange) })
    .toISO()
    .slice(0, 10);
}

function getToDate() {
  return DateTime.local()
    .minus({ months: 1 })
    .endOf("month")
    .toISO()
    .slice(0, 10);
}
