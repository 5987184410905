import React, { useState } from "react";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { CustomerAllDataFragment } from "../hasura.graphql";
import { ExternalLinkIcon } from "@heroicons/react/outline";

const FolderTree: React.FC<{ customer: CustomerAllDataFragment }> = ({
  customer,
}) => {
  const [expand, setExpand] = useState(false);

  console.log(customer.parent, customer.children);

  return (
    <div className="my-2">
      <div
        className="flex cursor-pointer"
        style={{ fontSize: "14px", color: "#4E4E4E" }}
        onClick={() => setExpand(!expand)}
      >
        {(customer.parent || customer.children.length > 0) && expand ? (
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        ) : (
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        )}
        {(customer.parent || customer.children.length > 0) && (
          <div className="flex">
            <span>
              {customer.parent
                ? `${customer.parent.account_name} - ${customer.parent.amagi_id}`
                : `${customer.account_name} - ${customer.amagi_id}`}
              <br />
            </span>
            {customer.parent && (
              <a
                href={`/customers/${customer.parent.amagi_id}/details`}
                target="_blank"
                rel="noreferrer"
                className="ml-2"
              >
                <ExternalLinkIcon className="h-5 w-5 text-blue-500" />
              </a>
            )}
          </div>
        )}
      </div>
      {customer.children.length > 0 ? (
        <div className={`${expand ? "block" : "hidden"} pl-3 ml-5`}>
          {customer.children.map((chd: any, index: number) => {
            return (
              <ul
                key={index}
                className="flex cursor-pointer"
                style={{
                  fontSize: "14px",
                  color: "#4E4E4E",
                  listStyleType: "circle",
                  margin: "7px 0px",
                }}
              >
                <li>
                  {chd.account_name} - {chd.amagi_id}
                  <br />
                </li>
                {chd.amagi_id !== customer.amagi_id && (
                  <a
                    href={`/customers/${chd.amagi_id}/details`}
                    target="_blank"
                    rel="noreferrer"
                    className="ml-2"
                  >
                    <ExternalLinkIcon className="h-5 w-5 text-blue-500" />
                  </a>
                )}
              </ul>
            );
          })}
        </div>
      ) : (
        customer.parent &&
        customer.parent?.children.length > 0 && (
          <div className={`${expand ? "block" : "hidden"} pl-3 ml-5`}>
            {customer.parent?.children.map((chd: any, index: number) => {
              return (
                <ul
                  key={index}
                  className="flex cursor-pointer"
                  style={{
                    fontSize: "14px",
                    color: "#4E4E4E",
                    listStyleType: "circle",
                    margin: "7px 0px",
                  }}
                >
                  <li>
                    {chd.account_name} - {chd.amagi_id}
                    <br />
                  </li>
                  {chd.amagi_id !== customer.amagi_id && (
                    <a
                      href={`/customers/${chd.amagi_id}/details`}
                      target="_blank"
                      rel="noreferrer"
                      className="ml-2"
                    >
                      <ExternalLinkIcon className="h-5 w-5 text-blue-500" />
                    </a>
                  )}
                </ul>
              );
            })}
          </div>
        )
      )}
    </div>
  );
};

export default FolderTree;
