import { useState } from "react";
import { DeliveryFragment } from "../../../hasura.graphql";
import ViewMultiPicklist from "../../channels/channel-view-form/view-multi-picklist";
import {
  backlogStatus,
  churnedStatus,
  closedLostStatus,
  greenStatus,
  onHoldStatus,
  progressStatus,
  statusChip,
} from "../../utils";
import ToggleBox from "../../toggle-box";
import ViewDeliveryRegions from "../../channel-delivery/delivery-view-form/view-delivery-region";
import ViewDeliveryProducts from "../../channel-delivery/delivery-view-form/view-delivery-products";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Timeline from "../../timeline";

const VODDeliveryFieldsForm: React.FC<{
  deliveryData: DeliveryFragment;
}> = ({ deliveryData }) => {
  const [hideColumns, setHideColumns] = useState<Array<string>>([]);

  const handleHideColumns = (tab: string) => {
    setHideColumns((hideColumns) => [...hideColumns, tab]);
  };
  const handleUnhideColumns = (tab: string) => {
    setHideColumns(hideColumns.filter((f: string) => f !== tab));
  };

  // const timelineItems = [
  //   {
  //     name: "In Progress",
  //     active: true,
  //   },
  //   {
  //     name: "Waiting to go live",
  //     active: deliveryData.delivery_status !== "Progress",
  //   },
  //   {
  //     name: "Active",
  //     active:
  //       deliveryData.delivery_status === "Active" ||
  //       deliveryData.delivery_status === "Churned",
  //   },
  //   {
  //     name: "Churned",
  //     active: deliveryData.delivery_status === "Churned",
  //   },
  // ];

  const filtered_churned_reason = deliveryData.churned_reason
    ? deliveryData.churned_reason.replaceAll(/[()"']/g, "")
    : null;

  const allTimelineItems = [
    {
      name: "Ordering",
      active: true,
      date: deliveryData.ordered_date || null,
      reason: null,
    },
    {
      name: "Provisioning Backlog",
      active: [
        ...backlogStatus,
        ...onHoldStatus,
        ...progressStatus,
        ...greenStatus,
        ...closedLostStatus,
        ...churnedStatus,
      ].includes(deliveryData.delivery_status!),
      date: deliveryData.provisioning_start_date || null,
      reason: null,
    },
    {
      name: "Provisioning",
      active: [
        ...onHoldStatus,
        ...progressStatus,
        ...greenStatus,
        ...closedLostStatus,
        ...churnedStatus,
      ].includes(deliveryData.delivery_status!),
      date: null,
      reason: null,
    },
    {
      name: "Provisioning On Hold",
      active: [
        ...onHoldStatus,
        ...greenStatus,
        ...closedLostStatus,
        ...churnedStatus,
        "Provisioning Blocked",
      ].includes(deliveryData.delivery_status!),
      date: deliveryData.on_hold_date || null,
      reason: (
        (deliveryData.on_hold_reason || deliveryData.blocked_reason) ? (
          <div className="text-left">
            {deliveryData.on_hold_reason && (
              <div className="mt-2">
                <span className="font-bold">On Hold Reason:</span> {deliveryData.on_hold_reason}
              </div>
            )}
            {deliveryData.blocked_reason && (
              <div className="mt-2">
                <span className="font-bold">Blocked Reason:</span> {deliveryData.blocked_reason}
              </div>
            )}
            {deliveryData.blocked_reason_details && (
              <div className="mt-2">
                <span className="font-bold">Blocked Reason Details:</span> {deliveryData.blocked_reason_details}
              </div>
            )}
          </div>
        ) : null
      ) as string | null,
    },    
    {
      name: "Delivered & Active",
      active: [...greenStatus, ...closedLostStatus, ...churnedStatus].includes(
        deliveryData.delivery_status!
      ),
      date: deliveryData.go_live_date || deliveryData.delivered_date || null,
      reason: null,
    },
    {
      name: "Delivery Closed Lost",
      active: [...closedLostStatus, ...churnedStatus].includes(
        deliveryData.delivery_status!
      ),
      date: null,
      reason: null,
    },
    {
      name: "Suspended",
      active: churnedStatus.includes(deliveryData.delivery_status!),
      date: deliveryData.churned_date || null,
      reason: filtered_churned_reason || null,
    },
  ];

  return (
    <div>
      <div style={{ color: "#4E4E4E", fontSize: "14px", fontWeight: "500" }}>
        <ToggleBox
          header="Delivery Details"
          handleHideColumns={handleHideColumns}
          handleUnhideColumns={handleUnhideColumns}
          columns={hideColumns}
        >
          <>
            <div className="grid grid-cols-3 gap-y-8 gap-x-36 pb-8">
              <div>
                <label className="text-gray-400">Delivery ID</label>
                <div className="mt-2">{deliveryData?.id || "-"}</div>
              </div>
              <div>
                <label className="text-gray-400">Request Origin</label>
                <div className="mt-2">
                  {deliveryData?.request_origin || "-"}
                </div>
              </div>
              <div>
                <label className="text-gray-400">
                  Delivered using Amagi NOW
                </label>
                <div className="mt-2">
                  {deliveryData?.delivered_using_amagi_now || "-"}
                </div>
              </div>
            </div>
            <div className="text-base font-semibold mb-3">Delivery for</div>
            <div className="grid grid-cols-3 gap-y-8 gap-x-36 pb-8 border-b">
              <div className="flex">
                {deliveryData.order?.customer.amagi_id && (
                  <div className="w-16 h-12">
                    <LazyLoadImage
                      className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                      src={
                        deliveryData.order.customer.logo
                          ? `/logos/${deliveryData.order.customer.logo}`
                          : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${deliveryData.order?.customer.account_name}`
                      }
                      alt={deliveryData.order.customer.account_name}
                    />
                  </div>
                )}
                <div className="ml-3 flex flex-col">
                  <a
                    href={`/customers/${deliveryData.order?.customer.amagi_id}/details`}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-blue-500 hover:underline"
                  >
                    {deliveryData.order?.customer.account_name &&
                    deliveryData.order?.customer.account_name.length > 20
                      ? `${deliveryData.order?.customer.account_name.slice(
                          0,
                          20
                        )}...`
                      : deliveryData.order?.customer.account_name}
                  </a>
                  <span className="text-sm text-gray-500">
                    {deliveryData.order?.customer.amagi_id}
                  </span>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-y-8 gap-x-36 pb-8 border-b mt-8">
              <div>
                <div className="text-base font-semibold mb-3">Channel</div>
                <div className="flex">
                  {deliveryData.channel?.id && (
                    <div className="w-16 h-12">
                      <LazyLoadImage
                        className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                        src={
                          deliveryData.channel.logo
                            ? `/${deliveryData.channel.logo}`
                            : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${deliveryData.channel?.name}`
                        }
                        alt={deliveryData.channel.name}
                      />
                    </div>
                  )}
                  <div className="ml-3 flex flex-col">
                    <a
                      href={`/channels/${
                        deliveryData?.channel?.channels_grouped !== null &&
                        deliveryData?.channel?.channels_grouped !== "No"
                          ? "grouped-channel"
                          : deliveryData.channel.channel_type
                          ? deliveryData.channel.channel_type.toLowerCase()
                          : "linear"
                      }/${deliveryData?.channel?.id}`}
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-blue-500 hover:underline"
                    >
                      {deliveryData?.channel?.name || ""}
                    </a>
                    <span className="text-sm text-gray-500">
                      {deliveryData?.channel?.id}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="text-base font-semibold mb-3">Platform</div>
                <div className="flex">
                  {deliveryData.platform?.id && (
                    <div className="w-16 h-12">
                      <LazyLoadImage
                        className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                        src={
                          deliveryData.platform.logo
                            ? `/${deliveryData.platform.logo}`
                            : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${deliveryData.platform?.name}`
                        }
                        alt={deliveryData.platform.name}
                      />
                    </div>
                  )}
                  <div className="ml-3 flex flex-col">
                    <a
                      href={`/platforms/${
                        deliveryData?.platform?.is_platform_group !== null &&
                        deliveryData?.platform?.is_platform_group !== "No"
                          ? "grouped-platform"
                          : "platform"
                      }/${deliveryData?.platform?.id}`}
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-blue-500 hover:underline"
                    >
                      {deliveryData?.platform?.name || ""}
                    </a>
                    <span className="text-sm text-gray-500">
                      {deliveryData?.platform?.id}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <label className="text-base font-semibold">
                  Delivery Status
                </label>
                <div
                  className={`mt-4 w-max rounded-full px-2 text-xs font-semibold leading-5 ${statusChip(
                    deliveryData.delivery_status!
                  )}`}
                >
                  {deliveryData?.delivery_status || "-"}
                </div>
              </div>
            </div>
            <div className="my-8">
              <label className="text-gray-400">Test Delivery</label>
              <div className="mt-2">{deliveryData?.test_delivery || "-"}</div>
            </div>
            <div className="my-8">
              <ViewDeliveryRegions delRegions={deliveryData.delivered_region} />
            </div>
            <div className="grid grid-cols-3 gap-y-8 gap-x-36 items-start pb-8 border-b">
              <div style={{ display: "grid", gridColumn: "1" }}>
                <label className="text-gray-400">Channel / Feed Type</label>
                <div className="mt-2">{deliveryData?.feed_type || "-"}</div>
              </div>
              <div style={{ display: "grid", gridColumn: "1" }}>
                <label className="text-gray-400">Delivery Type</label>
                <div className="mt-2">{deliveryData?.delivery_type || "-"}</div>
              </div>
              <div style={{ display: "grid", gridColumn: "1" }}>
                <label className="text-gray-400">SSAI</label>
                <div className="mt-2">
                  {deliveryData?.ssai !== null
                    ? deliveryData.ssai
                      ? "Yes"
                      : "No"
                    : "-"}
                </div>
              </div>
              <div style={{ display: "grid", gridColumn: "1" }}>
                <label className="text-gray-400 text-lg">CDN Distributions</label>
                <div className="mt-2">
                  <div>
                    <label className="text-gray-400">Tag 1</label>
                    <div className="mt-2 text-lg">
                      {deliveryData?.cdn_tag_1 || "--"}
                    </div>
                  </div>
                  <div>
                    <label className="text-gray-400">Tag 2</label>
                    <div className="mt-2 text-lg">
                      {deliveryData?.cdn_tag_2 || "--"}
                    </div>
                  </div>
                  <div>
                    <label className="text-gray-400">Tag 3</label>
                    <div className="mt-2 text-lg">
                      {deliveryData?.cdn_tag_3 || "--"}
                    </div>
                  </div>
                  <div>
                    <label className="text-gray-400">Tag 4</label>
                    <div className="mt-2 text-lg">
                      {deliveryData?.cdn_tag_4 || "--"}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <label className="text-gray-400">TS Delivery ID</label>
                <div className="mt-2">
                  {deliveryData?.point_product_delivery_id || "-"}
                </div>
              </div>
              <div>
                <label className="text-gray-400">TS Setup</label>
                <div className="mt-2">{deliveryData?.ts_setup || "-"}</div>
              </div>
              <div style={{ display: "grid", gridColumn: "1" }}>
                <ViewMultiPicklist
                  inlineLabel="Business Model"
                  picklist={deliveryData.business_model}
                />
              </div>
              <div style={{ display: "grid", gridColumn: "1" }}>
                <label className="text-gray-400">Ads Plus</label>
                <div className="mt-2">{deliveryData?.ads_plus || "-"}</div>
              </div>
              <div style={{ display: "grid", gridColumn: "1" }}>
                <label className="text-gray-400">Content Plus</label>
                <div className="mt-2">{deliveryData?.content_plus || "-"}</div>
              </div>
            </div>
            <div className="text-base font-semibold mt-8">
              Delivery Timeline
            </div>
            <div className="w-full mt-20 border-b">
            <div className="mx-20" style={{ height: '200px',width: '1000px' }}>
                <Timeline items={allTimelineItems} />
              </div>
            </div>

            <div className="text-base font-semibold mt-8 mb-2">
              Deal Details
            </div>
            <div className="border-b">
              <div className="my-8">
                <label className="text-gray-400">
                  Connect Distribution Model
                </label>
                <div className="mt-2">
                  {deliveryData?.connect_distribution_model || "-"}
                </div>
              </div>
              <div>
                <label className="text-gray-400">Deal Type</label>
                <div className="mt-2">
                  {deliveryData?.deal_type_for_content_partner || "-"}
                </div>
              </div>
              {/* Inventory Split */}
              {deliveryData.deal_type_for_content_partner ===
                "Inventory Split" && (
                <div className="grid grid-cols-3 gap-y-8 gap-x-36 py-8">
                  <div>
                    <label className="text-gray-400">
                      Deal Split with Content Partner
                    </label>
                    <div className="mt-2">
                      {deliveryData?.deal_split_with_content_partner
                        ? `${deliveryData?.deal_split_with_content_partner}%`
                        : "-"}
                    </div>
                  </div>
                  <div>
                    <label className="text-gray-400">
                      Inventory Share Monetised by - Primary
                    </label>
                    <div className="mt-2">
                      {deliveryData?.content_partner_primary
                        ? deliveryData?.content_partner_primary
                        : "-"}
                    </div>
                  </div>
                  <div>
                    <label className="text-gray-400">
                      Inventory Share Monetised by - Backfill
                    </label>
                    <div className="mt-2">
                      {deliveryData?.content_partner_backfill
                        ? deliveryData?.content_partner_backfill
                        : "-"}
                    </div>
                  </div>
                  <div style={{ display: "grid", gridColumn: "2" }}>
                    <label className="text-gray-400">
                      Inventory Share - Content Partner Primary Ad Tag
                    </label>
                    <div className="mt-2">
                      {deliveryData?.inventory_share_content_partner_primary_ad_tag
                        ? deliveryData?.inventory_share_content_partner_primary_ad_tag
                        : "-"}
                    </div>
                  </div>
                  <div>
                    <label className="text-gray-400">
                      Inventory Share - Content Partner Backfill Ad Tag
                    </label>
                    <div className="mt-2">
                      {deliveryData?.inventory_share_content_partner_backfill_ad_tag
                        ? deliveryData?.inventory_share_content_partner_backfill_ad_tag
                        : "-"}
                    </div>
                  </div>
                  <div>
                    <label className="text-gray-400">
                      Deal Split with Platform
                    </label>
                    <div className="mt-2">
                      {deliveryData?.deal_split_with_platform_partner
                        ? `${deliveryData?.deal_split_with_platform_partner}%`
                        : "-"}
                    </div>
                  </div>
                  <div>
                    <label className="text-gray-400">
                      Inventory Share Monetised by - Primary
                    </label>
                    <div className="mt-2">
                      {deliveryData?.platform_partner_primary
                        ? deliveryData?.platform_partner_primary
                        : "-"}
                    </div>
                  </div>
                  <div>
                    <label className="text-gray-400">
                      Inventory Share Monetised by - Backfill
                    </label>
                    <div className="mt-2">
                      {deliveryData?.platform_partner_backfill
                        ? deliveryData?.platform_partner_backfill
                        : "-"}
                    </div>
                  </div>
                  <div style={{ display: "grid", gridColumn: "2" }}>
                    <label className="text-gray-400">
                      Inventory Share - Platform Primary Ad Tag
                    </label>
                    <div className="mt-2">
                      {deliveryData?.inventory_share_platform_primary_ad_tag
                        ? deliveryData?.inventory_share_platform_primary_ad_tag
                        : "-"}
                    </div>
                  </div>
                  <div>
                    <label className="text-gray-400">
                      Inventory Share - Platform Backfill Ad Tag
                    </label>
                    <div className="mt-2">
                      {deliveryData?.inventory_share_platform_backfill_ad_tag
                        ? deliveryData?.inventory_share_platform_backfill_ad_tag
                        : "-"}
                    </div>
                  </div>
                  <div>
                    <label className="text-gray-400">
                      Deal Split - Amagi Share
                    </label>
                    <div className="mt-2">
                      {deliveryData?.deal_split_amagi_share
                        ? `${deliveryData?.deal_split_amagi_share}%`
                        : "-"}
                    </div>
                  </div>
                  <div>
                    <label className="text-gray-400">
                      Inventory Share Monetised by - Primary
                    </label>
                    <div className="mt-2">
                      {deliveryData?.amagi_share_primary
                        ? deliveryData?.amagi_share_primary
                        : "-"}
                    </div>
                  </div>
                  <div>
                    <label className="text-gray-400">
                      Inventory Share Monetised by - Backfill
                    </label>
                    <div className="mt-2">
                      {deliveryData?.amagi_share_backfill
                        ? deliveryData?.amagi_share_backfill
                        : "-"}
                    </div>
                  </div>
                  <div style={{ display: "grid", gridColumn: "2" }}>
                    <label className="text-gray-400">
                      Inventory Share - Amagi Primary Ad Tag
                    </label>
                    <div className="mt-2">
                      {deliveryData?.inventory_share_amagi_primary_ad_tag
                        ? deliveryData?.inventory_share_amagi_primary_ad_tag
                        : "-"}
                    </div>
                  </div>
                  <div>
                    <label className="text-gray-400">
                      Inventory Share - Amagi Backfill Ad Tag
                    </label>
                    <div className="mt-2">
                      {deliveryData?.inventory_share_amagi_backfill_ad_tag
                        ? deliveryData?.inventory_share_amagi_backfill_ad_tag
                        : "-"}
                    </div>
                  </div>
                </div>
              )}
              {/* Revenue Share */}
              {deliveryData.deal_type_for_content_partner ===
                "Revenue Share" && (
                <div className="grid grid-cols-3 gap-y-8 gap-x-36 py-8">
                  <div style={{ display: "grid", gridColumn: "2" }}>
                    <label className="text-gray-400">
                      Revenue Share Monetised by - Primary
                    </label>
                    <div className="mt-2">
                      {deliveryData?.revenue_share_monetised_by_primary
                        ? deliveryData?.revenue_share_monetised_by_primary
                        : "-"}
                    </div>
                  </div>
                  <div>
                    <label className="text-gray-400">
                      Revenue Share Monetised by - Backfill
                    </label>
                    <div className="mt-2">
                      {deliveryData?.revenue_share_monetised_by_backfill
                        ? deliveryData?.revenue_share_monetised_by_backfill
                        : "-"}
                    </div>
                  </div>
                  <div style={{ display: "grid", gridColumn: "2" }}>
                    <label className="text-gray-400">
                      Revenue Share - Primary Ad Tag
                    </label>
                    <div className="mt-2">
                      {deliveryData?.revenue_share_primary_ad_tag
                        ? deliveryData?.revenue_share_primary_ad_tag
                        : "-"}
                    </div>
                  </div>
                  <div>
                    <label className="text-gray-400">
                      Revenue Share - Backfill Ad Tag
                    </label>
                    <div className="mt-2">
                      {deliveryData?.revenue_share_backfill_ad_tag
                        ? deliveryData?.revenue_share_backfill_ad_tag
                        : "-"}
                    </div>
                  </div>
                </div>
              )}
              <div className="my-8">
                <label className="text-gray-400">CDN Fee Owner</label>
                <div className="mt-2">{deliveryData?.cdn_fee_owner || "-"}</div>
              </div>
              <div className="mb-8">
                <label className="text-gray-400">SSAI Fee Owner</label>
                <div className="mt-2">
                  {deliveryData?.ssai_fee_owner || "-"}
                </div>
              </div>
              <div className="mb-8">
                <label className="text-gray-400">
                  Content Partner Ad Sales Commission %
                </label>
                <div className="mt-2">
                  {deliveryData?.content_partner_ad_sales_commission_percentage
                    ? `${deliveryData?.content_partner_ad_sales_commission_percentage}%`
                    : "-"}
                </div>
              </div>
              <div className="mb-8">
                <label className="text-gray-400">
                  Platform Ad Sales Commission %
                </label>
                <div className="mt-2">
                  {deliveryData?.platform_ad_sales_commission_percentage
                    ? `${deliveryData?.platform_ad_sales_commission_percentage}%`
                    : "-"}
                </div>
              </div>
            </div>
            <div className="my-8">
              <ViewDeliveryProducts
                products={deliveryData.product_purchased_for_this_delivery}
              />
            </div>
          </>
        </ToggleBox>
        <ToggleBox
          header="Meta"
          handleHideColumns={handleHideColumns}
          handleUnhideColumns={handleUnhideColumns}
          columns={hideColumns}
        >
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Order ID</label>
              <div className="mt-2">
                {deliveryData?.salesforce_order_id || "-"}
              </div>
            </div>
            <div>
              <label className="text-gray-400">Order Product ID</label>
              <div className="mt-2">
                {deliveryData?.salesforce_order_product_id || "-"}
              </div>
            </div>
            <div>
              <label className="text-gray-400">Order Item ID</label>
              <div className="mt-2">
                {deliveryData?.salesforce_order_item_id || "-"}
              </div>
            </div>
          </div>
        </ToggleBox>
        <ToggleBox
          header="Onboarding PM"
          handleHideColumns={handleHideColumns}
          handleUnhideColumns={handleUnhideColumns}
          columns={hideColumns}
        >
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Name</label>
              <div className="mt-2">
                {deliveryData?.user && deliveryData?.user.name
                  ? deliveryData?.user.name
                  : "-"}
              </div>
            </div>
            <div>
              <label className="text-gray-400">Email</label>
              <div className="mt-2">
                {deliveryData?.user && deliveryData?.user.email
                  ? deliveryData?.user.email
                  : "-"}
              </div>
            </div>
          </div>
        </ToggleBox>
      </div>
    </div>
  );
};

export default VODDeliveryFieldsForm;
