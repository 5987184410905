import {
  ChevronDownIcon,
  ChevronRightIcon,
  ExternalLinkIcon,
} from "@heroicons/react/outline";
import { useMemo, useState } from "react";
import { DeliveryInfoFragment } from "../../hasura.graphql";
import { statusChip } from "../utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SubTableContainer from "../../common/sub-table";
import { DeliveryRegionsType } from "../channel-delivery/delivery-edit-form/types";
import _ from "lodash";
import TableRowsFilter from "../../common/filters/table-rows-filter";

const DeliveryTable: React.FC<{
  deliveriesData: Array<DeliveryInfoFragment>;
  debouncedDeliverySearch: string;
  header: string;
}> = ({ deliveriesData, debouncedDeliverySearch, header }) => {
  const [deliveryPlatform, setDeliveryPlatform] = useState<Array<string>>([]);

  const handleToggleDeliveryPlatform = (channel: string) => {
    setDeliveryPlatform((channels) => [...channels, channel]);
  };
  const handleUnToggleDeliveryPlatform = (channel: string) => {
    setDeliveryPlatform(deliveryPlatform.filter((f) => f !== channel));
  };

  const filteredDeliveries = _.sortBy(
    Array.from(new Set(deliveriesData.map((p) => p.channel?.name))).map(
      (delivery) => {
        return {
          channelId: deliveriesData.find(
            (del) => del.channel?.name === delivery
          )?.channel?.id,
          channelName: delivery,
          isChannelGroup: deliveriesData.find(
            (del) => del.channel?.name === delivery
          )?.channel?.channels_grouped,
          channelLogo: deliveriesData.find(
            (del) => del.channel?.name === delivery
          )?.channel?.logo,
          channelType: deliveriesData.find(
            (del) => del.channel?.name === delivery
          )?.channel?.channel_type,
          platforms: _.sortBy(
            deliveriesData
              .filter((platform) => platform.channel?.name === delivery)
              .map((del) => {
                return {
                  deliveryId: del.id,
                  platformId: del.platform?.id,
                  platformName: del.platform?.name,
                  isPlatformGroup: del.platform?.is_platform_group,
                  platformLogo: del.platform?.logo,
                  platformTier: del.platform?.platform_tier,
                  region:
                    del.delivered_region && del.delivered_region.length > 0
                      ? del.delivered_region.map(
                          (reg: DeliveryRegionsType) => reg.region
                        )
                      : [],
                  ssai: del.ssai,
                  businessModel:
                    del?.business_model && del?.business_model.length > 0
                      ? del?.business_model
                      : [],
                  dealType: del?.deal_type_for_content_partner
                    ? del?.deal_type_for_content_partner
                    : "-",
                  dealSplitWithContent: del?.deal_split_with_content_partner,
                  dealSplitWithPlatform: del?.deal_split_with_platform_partner,
                  dealSplitWithAmagi: del?.deal_split_amagi_share,
                  deliveryType: del?.delivery_type ? del?.delivery_type : "-",
                  status: del?.delivery_status!,
                };
              }),
            (plat) => plat.platformName
          ),
        };
      }
    ),
    (chn) => chn.channelName
  );

  const debouncedChannelDeliveries = filteredDeliveries.filter((delivery) => {
    if (!debouncedDeliverySearch) {
      return true;
    }
    return `${delivery.channelName} ${delivery.platforms.map(
      (plat) => plat.platformName
    )} ${delivery.platforms.map((plat) => plat.platformTier)}`
      .toLowerCase()
      .includes(debouncedDeliverySearch.toLowerCase());
  });

  const columns: any = useMemo(
    () => [
      {
        Header: "PLATFORM",
        accessor: "platformName",
        Cell: ({ cell }: any) => (
          <>
            {cell.row.original.platformName ? (
              <div className="flex items-center">
                <div className="w-16 h-12">
                  <LazyLoadImage
                    className="h-full w-full rounded-lg object-contain bg-lightPrimary"
                    src={
                      cell.row.original.platformLogo
                        ? `/${cell.row.original.platformLogo}`
                        : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${cell.row.original.platformName}`
                    }
                    alt={cell.row.original.platformName}
                  />
                </div>
                <a
                  href={`/platforms/${
                    cell.row.original.isPlatformGroup !== null &&
                    cell.row.original.isPlatformGroup !== "No"
                      ? "grouped-platform"
                      : "platform"
                  }/${cell.row.original.platformId}`}
                  className="ml-3 hover:text-blue-500 hover:underline font-semibold"
                  target="_blank"
                  rel="noreferrer"
                >
                  {cell.row.original.platformName}
                </a>
              </div>
            ) : (
              <span>-</span>
            )}
          </>
        ),
        Filter: TableRowsFilter,
        position: "relative",
        positionStyles: "left-0",
        filter: "multiSelect",
      },
      {
        Header: "REGION",
        accessor: "region",
        Cell: ({ cell }: any) => (
          <div className="flex">
            {cell.row.original.region && cell.row.original.region.length > 0 ? (
              <>
                {cell.row.original.region &&
                  cell.row.original.region.map((bus: string) => (
                    <span
                      className={`my-1 rounded-full w-max px-2 text-xs leading-5 text-gray-800`}
                      style={{ background: "#E9DDF7" }}
                    >
                      {bus}
                    </span>
                  ))}
              </>
            ) : (
              <span>-</span>
            )}
          </div>
        ),
        Filter: TableRowsFilter,
        position: "relative",
        positionStyles: "left-0",
        filter: "multiSelect",
      },
      {
        Header: "BUSINESS MODEL",
        accessor: "businessModel",
        Cell: ({ cell }: any) => (
          <div className="flex">
            {cell.row.original.businessModel.length > 0 ? (
              <>
                {cell.row.original.businessModel.map((bus: string) => (
                  <span
                    className={`my-1 rounded-full w-max px-2 text-xs leading-5 text-gray-800`}
                    style={{ background: "#E9DDF7" }}
                  >
                    {bus}
                  </span>
                ))}
              </>
            ) : (
              <span>-</span>
            )}
          </div>
        ),
      },
      {
        Header: "SSAI",
        accessor: "ssai",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.original.ssai !== null
              ? cell.row.original.ssai
                ? "Yes"
                : "No"
              : "-"}
          </span>
        ),
      },
      {
        Header: "PLATFORM TIER",
        accessor: "platformTier",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.original.platformTier
              ? cell.row.original.platformTier
              : "-"}
          </span>
        ),
      },
      {
        Header: "DEAL TYPE/SHARE",
        accessor: "dealType",
        Cell: ({ cell }: any) => (
          <div>
            <span>{cell.row.original.dealType}</span>
            <div className="flex text-xs font-normal text-gray-600">
              {cell.row.original.dealSplitWithContent ? (
                <span className="mr-1">
                  Customer: {cell.row.original.dealSplitWithContent}% |
                </span>
              ) : null}
              {cell.row.original.dealSplitWithPlatform ? (
                <span className="mr-1">
                  Platform: {cell.row.original.dealSplitWithPlatform}% |
                </span>
              ) : null}
              {cell.row.original.dealSplitWithAmagi ? (
                <span className="">
                  Amagi: {cell.row.original.dealSplitWithAmagi}%
                </span>
              ) : null}
            </div>
          </div>
        ),
        Filter: TableRowsFilter,
        position: "relative",
        filter: "multiSelect",
      },
      {
        Header: "DELIVERY TYPE",
        accessor: "deliveryType",
        Filter: TableRowsFilter,
        position: "relative",
        filter: "multiSelect",
      },
      {
        Header: "STATUS",
        accessor: "status",
        Cell: ({ cell }: any) => (
          <>
            {cell.row.original.status !== "--" ? (
              <span
                // style={{ maxWidth: 100 }}
                className={`flex flex-wrap w-max rounded-full px-2 text-xs font-semibold leading-5 ${statusChip(
                  cell.row.original.status
                )}`}
              >
                {cell.row.original.status}
              </span>
            ) : (
              <span>--</span>
            )}
          </>
        ),
        Filter: TableRowsFilter,
        position: "relative",
        filter: "multiSelect",
      },
      {
        Header: "DELIVERY",
        accessor: "deliveryId",
        isSortable: false,
        Cell: ({ cell }: any) => (
          <a
            href={`/deliveries/${
              header === "Channel Deliveries"
                ? "channel-deliveries"
                : "vod-deliveries"
            }/${cell.row.values.deliveryId}`}
            target="_blank"
            rel="noreferrer"
          >
            <ExternalLinkIcon className="h-5 w-5 text-blue-500" />
          </a>
        ),
      },
    ],
    [header]
  );

  return (
    <div
      className="overflow-y-scroll bg-white"
      style={{
        maxHeight: `${window.innerHeight - 250}px`,
      }}
    >
      {debouncedChannelDeliveries.map((del: any, index: any) => {
        return (
          <>
            <div
              key={index}
              className={`border-b last:border-0 p-3 grid grid-cols-12 justify-start items-center`}
            >
              <div className="col-span-1">
                <span className="cursor-pointer">
                  {deliveryPlatform.includes(del.channelName) ? (
                    <div className="p-px border w-max border-primaryColor bg-primaryColor text-white rounded">
                      <ChevronDownIcon
                        className="h-5 w-5"
                        onClick={() =>
                          handleUnToggleDeliveryPlatform(del.channelName)
                        }
                      />
                    </div>
                  ) : (
                    <div className="p-px w-max border rounded">
                      <ChevronRightIcon
                        className="h-5 w-5"
                        onClick={() =>
                          handleToggleDeliveryPlatform(del.channelName)
                        }
                      />
                    </div>
                  )}
                </span>
              </div>
              <div className="flex col-span-5 items-center">
                <div className="w-16 h-12">
                  <LazyLoadImage
                    className="h-full w-full rounded-lg object-contain bg-lightPrimary"
                    src={
                      del.channelLogo
                        ? `/${del.channelLogo}`
                        : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${del.channelName}`
                    }
                    alt={del.channelLogo}
                  />
                </div>
                <div className="ml-3 flex flex-col col-span-3 text-left flex-wrap">
                  {del.channelName ? (
                    <a
                      href={`/channels/${
                        del?.isChannelGroup !== null &&
                        del?.isChannelGroup !== "No"
                          ? "grouped-channel"
                          : del.channel_type
                          ? del.channel_type.toLowerCase()
                          : "linear"
                      }/${del.channelId}`}
                      className="hover:text-blue-500 hover:underline w-max font-semibold"
                      target="_blank"
                      style={{ fontSize: 16 }}
                      rel="noreferrer"
                    >
                      {del?.channelName}
                    </a>
                  ) : (
                    <span>-</span>
                  )}
                  <span className="text-sm">{del.channelType} Channel</span>
                  {/* <span style={{ fontSize: 14 }}>{del.channelName}</span> */}
                </div>
              </div>
              <div className="flex flex-col col-span-2 text-left">
                <span style={{ fontSize: 16 }} className="font-semibold">
                  {del.platforms.length}
                </span>
                <span className="text-sm">Platforms</span>
              </div>
            </div>
            {deliveryPlatform.includes(del.channelName) &&
              del.platforms.length > 0 && (
                <div
                  className="p-2"
                  style={{
                    margin: 0,
                    padding: 0,
                    background: "#E4EAFA",
                  }}
                >
                  <div className="flex">
                    <div
                      className="bg-primaryColor"
                      style={{ width: 5, margin: "0px 22px" }}
                    ></div>
                    <div className="my-3">
                      <SubTableContainer
                        width={window.innerWidth - 1050}
                        isSearchable={false}
                        data={del.platforms}
                        columns={columns}
                      />
                      {/* <Table data={del.platforms} columns={columns} /> */}
                    </div>
                  </div>
                </div>
              )}
          </>
        );
      })}
    </div>
  );
};

export default DeliveryTable;
