import { useCDM } from "../../cdm-context";
import Loader from "../../common/loader";
import { useCustomerCreditMemosByPkQuery } from "../../hasura.graphql";
import { useMemo, useState } from "react";
import DelayedToolTip from "../delayed-tooltip";
import { DateParser, DateSort, MonthParser } from "../utils";
import { DocumentDownloadIcon } from "@heroicons/react/outline";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-hot-toast";
import FinTable from "./fin-table";
import { DateTime } from "luxon";
import { useSearchParams } from "react-router-dom";
import { TransactionType } from ".";
import TableRowsFilter from "../../common/filters/table-rows-filter";

const CreditMemos: React.FC<{
  transaction: TransactionType;
}> = ({ transaction }) => {
  const { show } = useCDM();
  const [searchParams] = useSearchParams();
  const [transactionId, setTransactionId] = useState<string | null>(null);
  const searchedFromDate =
    transaction.date || searchParams.get("date")
      ? DateTime.local(
          transaction.date
            ? parseInt(transaction.date!.slice(0, 4))
            : parseInt(searchParams.get("date")!.slice(0, 4)),
          transaction.date
            ? parseInt(transaction.date!.slice(5, 7))
            : parseInt(searchParams.get("date")!.slice(5, 7))
        )
          .startOf("month")
          .toISO()
          .slice(0, 10)
      : DateTime.local()
          .minus({ year: 1 })
          .startOf("month")
          .toISO()
          .slice(0, 10);
  const searchedToDate =
    transaction.date || searchParams.get("date")
      ? DateTime.local(
          transaction.date
            ? parseInt(transaction.date!.slice(0, 4))
            : parseInt(searchParams.get("date")!.slice(0, 4)),
          transaction.date
            ? parseInt(transaction.date!.slice(5, 7))
            : parseInt(searchParams.get("date")!.slice(5, 7))
        )
          .endOf("month")
          .toISO()
          .slice(0, 10)
      : DateTime.local().endOf("month").toISO().slice(0, 10);
  const [dateFilter, setDateFilter] = useState<{ from: string; to: string }>({
    from: searchedFromDate,
    to: searchedToDate,
  });
  const statusColorCode = (status: string) => {
    if (status === "Applied") return "text-chartGreen border-chartGreen";
    return "text-chartOrange border-chartOrange";
  };

  const credit_columns: any = useMemo(
    () => [
      {
        Header: "Transaction ID",
        accessor: "invoice_id",
        Cell: ({ cell }: any) => (
          <div className="flex w-full">
            <span
              className="text-blue-500 underline cursor-pointer mr-2"
              onClick={() => setTransactionId(cell.row.original.invoice_id)}
            >
              <DelayedToolTip title="download">
                <DocumentDownloadIcon className="h-5 w-5 text-blue-500" />
              </DelayedToolTip>
            </span>
            <span className="mr-2">{cell.row.original.invoice_id}</span>
            <DelayedToolTip title="copy">
              <CopyToClipboard text={cell.row.original.invoice_id}>
                <img
                  src="/assets/file-copy.svg"
                  onClick={() => toast.success("Copied")}
                  alt="file-copy"
                  className="w-full h-full"
                />
              </CopyToClipboard>
            </DelayedToolTip>
          </div>
        ),
      },
      {
        Header: "Transaction Date",
        accessor: "invoice_date",
        sortType: (a: any, b: any) =>
          DateSort(a.values.invoice_date, b.values.invoice_date),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.invoice_date !== "--"
              ? DateParser(cell.row.values.invoice_date)
              : "--"}
          </span>
        ),
        autoResetSortBy: false,
      },
      {
        Header: "Billing Period",
        accessor: "billing_period",
        sortType: (a: any, b: any) =>
          DateSort(a.values.billing_period, b.values.billing_period),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.billing_period !== "--"
              ? MonthParser(cell.row.values.billing_period)
              : "--"}
          </span>
        ),
        autoResetSortBy: false,
      },
      {
        Header: "Amount",
        accessor: "invoice_amount",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.invoice_amount !== "--"
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: cell.row.original.currency
                    ? cell.row.original.currency
                    : show?.currency,
                }).format(cell.row.values.invoice_amount)
              : "--"}
          </span>
        ),
        textAlign: "text-right",
      },
      {
        Header: "Currency",
        accessor: "currency",
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: TableRowsFilter,
        filter: "multiSelect",
        Cell: ({ cell }: any) => (
          <span
            className={`border ${statusColorCode(
              cell.row.values.status
            )} rounded-full px-2 inline-flex leading-5 text-xs`}
          >
            {cell.row.values.status}
          </span>
        ),
      },
      {
        Header: "SO/PO Ref",
        accessor: "so_po_reference_number",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "Root Cause Reason",
        accessor: "root_cause_reason",
        Cell: ({ cell }: any) => (
          <div>
            {cell.row.original.type !== "invoice" ? (
              <DelayedToolTip
                title={cell.row.original.root_cause_reason}
                position="top"
              >
                <span className="inline-block">
                  {cell.row.values.root_cause_reason &&
                  cell.row.values.root_cause_reason.length > 30
                    ? `${cell.row.values.root_cause_reason.slice(0, 30)}...`
                    : cell.row.values.root_cause_reason || "--"}
                </span>
              </DelayedToolTip>
            ) : (
              "--"
            )}
          </div>
        ),
      },
    ],
    [show?.currency]
  );

  const { data, loading, error } = useCustomerCreditMemosByPkQuery({
    variables: {
      amagi_id: show?.amg_id!,
      from: dateFilter.from,
      to: dateFilter.to,
    },
  });

  if (loading) {
    return (
      <div className="rounded font-medium bg-white mt-4 px-4 py-2">
        <Loader />
      </div>
    );
  }
  if (error) {
    return <i>Error</i>;
  }
  if (!data || !data.customer) {
    return <i>Could not retrieve customer financials data</i>;
  }

  const yearFields = Array.from(
    new Set(data.customer.date?.map((da) => da.invoice_date.slice(0, 4)))
  );

  const customerFinancial = data.customer;

  return (
    <FinTable
      warning="Credit Memos"
      searchParam={transaction.id}
      dateFilter={dateFilter}
      setDateFilter={setDateFilter}
      yearFields={yearFields}
      customerFinancial={customerFinancial}
      isToggle={false}
      height={310}
      columns={credit_columns}
      transactionId={transactionId}
      setTransactionId={setTransactionId}
      header="Credit Memos"
    />
  );
};

export default CreditMemos;
