import ReactS3Uploader from "react-s3-uploader";
import { Auth } from "aws-amplify";
import { CloudUploadIcon } from "@heroicons/react/outline";
// import { AwsV4Signer } from "aws4fetch";

const FileUpload: React.FC<{
  amagi_id: string;
  value?: string | Array<string>;
  onUpload: (path: string) => void;
  setProgress: (value: number) => void;
  fileType: string;
}> = ({ amagi_id, value, onUpload, fileType, setProgress }) => {
  return (
    <div>
      <label
        // htmlFor="file-upload"
        className="px-3 py-1 border border-blue-500 flex text-blue-500 rounded cursor-pointer"
      >
        <span>
          <CloudUploadIcon className="h-5 w-5 text-blue-500 mr-2" />
        </span>
        <ReactS3Uploader
          // id="file-upload"
          style={{ display: "none" }}
          // signingUrl="/s3/sign"
          // signingUrlMethod="POST"
          accept={fileType}
          // s3path="/uploads/"
          getSignedUrl={async (file, callback) => {
            const params = {
              objectName: file.name.replace(/[^\w\d_\-.]+/gi, ""),
              contentType: file.type,
              amagiId: amagi_id,
            };
            // const credentials = await Auth.currentUserCredentials();
            // const signer = new AwsV4Signer({
            //   url: `${document.location.origin}/functions/s3-signed-url`,
            //   method: "POST",
            //   body: JSON.stringify(params),
            //   accessKeyId: credentials.accessKeyId, // required, akin to AWS_ACCESS_KEY_ID
            //   secretAccessKey: credentials.secretAccessKey, // required, akin to AWS_SECRET_ACCESS_KEY
            //   sessionToken: credentials.sessionToken, // akin to AWS_SESSION_TOKEN if using temp credentials
            //   service: "execute-api", // AWS service, by default parsed at fetch time
            //   region: "us-east-1", // AWS region, by default parsed at fetch time
            // });
            // const { method, headers, body } = await signer.sign();
            const response = await fetch(
              `${document.location.origin}/functions/s3-signed-url`,
              {
                headers: {
                  Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`,
                },
                body: JSON.stringify(params),
                method: "POST",
              }
            );
            const signedUrl = await response.json();
            console.log("debug:signedUrl: ", signedUrl);
            callback({
              signedUrl,
            });
          }}
          uploadRequestHeaders={{
            "x-amz-acl": "private",
          }}
          onProgress={(p) => {
            console.log(`debug: progress: ${p}`);
            setProgress(p);
          }}
          onError={(message) => {
            alert(`error: ${message}`);
          }}
          onFinish={(result) => {
            const url = new URL(result.signedUrl);
            onUpload(url.pathname.slice(1));
          }}
          contentDisposition="auto"
          autoUpload={true}
        />
        Upload
      </label>
    </div>
  );
};

export default FileUpload;
