import React, { Dispatch, SetStateAction, useState } from "react";
import FormikInput from "../../formik/input";
import FormikSelect from "../../formik/select";
import FormikMultiSelect from "../../formik/multi-select";
import { channelFields, platformFields } from "../../../constants";
import FileUpload from "../../file-upload";
import ReactPlayer from "react-player";
import ProgressBarComp from "../../progress-bar";
import { ChannelVideosType } from ".";
import FormikTextField from "../../formik/text-field";
import FormikRange from "../../formik/range";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillTwitterSquare,
} from "react-icons/ai";
import { ChannelTrailersType } from "../../channels/channel-edit-form/types";
import { ReactSelectType } from "../../channels/channel-edit-form/business-fields-form";
import FormikAvailabilityRegions from "../../formik/availability-regions";
import { PlatformEditTypes } from "./types";
import FormikPlatformArtwork from "../../formik/artwork";
import SearchSelect from "../../formik/search-select";
import FormikKeyring from "../../formik/keyring";
import ToggleBox from "../../toggle-box";
import PlatformContacts from "./platform-contacts";
import DelayedToolTip from "../../delayed-tooltip";
import { InformationCircleIcon } from "@heroicons/react/outline";
import ReferenceLinks from "./reference-links";
import FormikSwitch from "../../formik/switch";

const PlatformBusinessFieldsForm: React.FC<{
  posters: Array<string>;
  videos: Array<ChannelTrailersType>;
  amagiIds: Array<ReactSelectType>;
  platformIds: Array<ReactSelectType>;
  presentations: Array<string>;
  setPosters: Dispatch<SetStateAction<Array<string>>>;
  setVideos: Dispatch<SetStateAction<Array<ChannelVideosType>>>;
  setPresentations: Dispatch<SetStateAction<Array<string>>>;
  values: PlatformEditTypes;
  setFieldValue: any;
}> = ({
  posters,
  videos,
  amagiIds,
  platformIds,
  presentations,
  setPosters,
  setVideos,
  setPresentations,
  values,
  setFieldValue,
}) => {
  const [hideColumns, setHideColumns] = useState<Array<string>>([]);
  const [logoProgress, setLogoProgress] = useState<number>(0);
  const [posterProgress, setPosterProgress] = useState<number>(0);
  const [trailerProgress, setTrailerProgress] = useState<number>(0);
  const [presentationProgress, setPresentationProgress] = useState<number>(0);

  const handleHideColumns = (tab: string) => {
    setHideColumns((hideTabs) => [...hideTabs, tab]);
  };
  const handleUnhideColumns = (tab: string) => {
    setHideColumns(hideColumns.filter((f: string) => f !== tab));
  };

  return (
    <div>
      <ToggleBox
        columns={hideColumns}
        handleHideColumns={handleHideColumns}
        handleUnhideColumns={handleUnhideColumns}
        header="Basic Information"
      >
        <>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36 mb-8">
            <div>
              <FormikInput
                inlinelabel="Platform Name"
                name="name"
                defaultValue={values?.name}
              />
            </div>
            <div>
              <FormikInput
                inlinelabel="Platform ID"
                name="platform_id"
                disabled={true}
                defaultValue={values?.platform_id}
              />
            </div>
            <div>
              <FormikInput
                inlinelabel="Platform Brand"
                name="platform_brand"
                defaultValue={values?.platform_brand}
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36 mb-8">
          <div style={{ display: "grid", gridColumn: "1" }}>
          <div className="text-base font-semibold mb-2">Onboarded Date</div>
          <input
            type="date"
            id="onboarded_date"
            name="onboarded_date"
            value={
              values.onboarded_date
                ? new Date(values.onboarded_date).toISOString().split('T')[0] 
                : ""
            }
            onChange={(e) => {
              const value = e.target.value;
              setFieldValue("onboarded_date", value === "" ? null : value);
            }}
          />
        </div>
        </div>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36 mb-8">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <div className="text-base font-semibold mb-2">Platform Owner</div>
              <SearchSelect
                inlinelabel="Amagi ID"
                name="salesforce_account_id"
                defaultValue={values.salesforce_account_id}
                defFields={amagiIds}
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36 mb-8">
            <div>
              <div className="flex items-center text-base font-semibold mb-2">
                <span className="mr-2">Platform Manager</span>
                <DelayedToolTip
                  delay={0}
                  title="Only assigned if Platforms are managed by Platforms Team otherwise if platform delivery is managed by respective channel partner project manager then it will be N/A"
                  position="top"
                >
                  <InformationCircleIcon className="h-4 w-4 text-blue-500" />
                </DelayedToolTip>
              </div>
              <FormikInput
                defaultValue={
                  values.platform_manager ? values.platform_manager.name : ""
                }
                inlinelabel="Name"
                name="platform_manager.name"
                placeholder="Enter name"
              />
            </div>
            <div>
              <div
                className="text-base font-semibold mb-2"
                style={{ height: 24 }}
              ></div>
              <FormikInput
                defaultValue={
                  values.platform_manager ? values.platform_manager.email : ""
                }
                inlinelabel="Email"
                name="platform_manager.email"
                placeholder="Enter email"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-y-8 gap-x-36 my-4">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikTextField
                name="platform_description"
                inlinelabel="Platform Description"
                defaultValue={values.platform_description}
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Platform Tier"
                name="platform_tier"
                defaultValue={values.platform_tier}
                defFields={platformFields.tier}
              />
            </div>
            <div>
              <FormikInput
                inlinelabel="Website URL"
                placeholder="Ex: https://url.com"
                name="website_url"
                defaultValue={values?.website_url}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikMultiSelect
                inlinelabel="Target Devices"
                name="target_audience"
                defaultValue={values.devices_field}
                defFields={platformFields.devices.sort()}
              />
            </div>
          </div>
        </>
      </ToggleBox>
      <ToggleBox
        columns={hideColumns}
        handleHideColumns={handleHideColumns}
        handleUnhideColumns={handleUnhideColumns}
        header="Keyring"
      >
        <FormikKeyring
          keyring={values.keyring}
          defaultId={values.salesforce_id!}
        />
      </ToggleBox>
      <ToggleBox
        columns={hideColumns}
        handleHideColumns={handleHideColumns}
        handleUnhideColumns={handleUnhideColumns}
        header="Platform Artwork and Social Media"
      >
        <>
          <div className="mb-12">
            <span className="text-gray-400">Platform Logo</span>
            {values.logo && (
              <div className="flex mt-4">
                <div className="w-44 h-24 flex justify-center">
                  <img
                    className="w-24 h-24 object-fill"
                    src={`/${values.logo}`}
                    alt="Platform Logo"
                  />
                </div>
                <div className="ml-4">
                  <div>Platform Logo</div>
                  <div
                    className="text-blue-500 mt-2 cursor-pointer"
                    onClick={() => {
                      setFieldValue("logo", "");
                    }}
                  >
                    Remove
                  </div>
                </div>
              </div>
            )}
            {logoProgress > 0 && logoProgress < 100 && (
              <ProgressBarComp progress={logoProgress} color="green" />
            )}
            <div className="flex items-center cursor-pointer mt-3 text-blue-500">
              <FileUpload
                setProgress={setLogoProgress}
                value={values.logo!}
                fileType=".jpg, .png"
                amagi_id={values.account?.amagi_id!}
                onUpload={(filekey) => {
                  setFieldValue("logo", filekey);
                }}
              />
            </div>
          </div>
          <div className="mb-12">
            <span className="text-gray-400">Posters</span>
            {posters.length > 0 &&
              posters.length < 7 &&
              posters.map((poster: string, index: number) => {
                return (
                  <div className="flex mt-4">
                    <div className="w-44 h-24 flex justify-center">
                      <img
                        className="w-full rounded-md"
                        src={`/${poster}`}
                        alt={poster}
                      />
                    </div>
                    <div className="ml-4">
                      <div>{`Platform Poster ${index + 1}`}</div>
                      <div
                        className="text-blue-500 mt-2 cursor-pointer"
                        onClick={() =>
                          setPosters(
                            posters.filter((post: string) => post !== poster)
                          )
                        }
                      >
                        Remove
                      </div>
                    </div>
                  </div>
                );
              })}
            {posterProgress > 0 && posterProgress < 100 && (
              <ProgressBarComp progress={posterProgress} color="green" />
            )}
            <div className="flex items-center cursor-pointer mt-3 text-blue-500">
              <FileUpload
                setProgress={setPosterProgress}
                fileType=".png, .jpg"
                amagi_id={values.account?.amagi_id!}
                onUpload={(filekey) => {
                  setPosters((prevPost) => [...prevPost, filekey]);
                }}
              />
            </div>
          </div>
          <div className="mb-12">
            <span className="text-gray-400">Trailers</span>
            {videos &&
              videos.map((trail: ChannelTrailersType, index: number) => {
                return (
                  <div className="flex mt-4">
                    <div className="flex justify-center rounded-md bg-gray-600">
                      <ReactPlayer
                        style={{ width: 300, height: 250 }}
                        url={`/${trail.url}`}
                        light={true}
                        controls={true}
                      />
                    </div>
                    <div className="ml-4">
                      <div>{`Trailer ${index + 1}`}</div>
                      <div
                        className="text-blue-500 mt-2 cursor-pointer"
                        onClick={() =>
                          setVideos(
                            videos.filter(
                              (video: ChannelTrailersType) =>
                                video.url !== trail.url
                            )
                          )
                        }
                      >
                        Remove
                      </div>
                    </div>
                  </div>
                );
              })}
            {trailerProgress > 0 && trailerProgress < 100 && (
              <ProgressBarComp progress={trailerProgress} color="green" />
            )}
            <div className="flex items-center cursor-pointer mt-3 text-blue-500">
              <FileUpload
                setProgress={setTrailerProgress}
                fileType=".mp4, .mov"
                amagi_id={values.account?.amagi_id!}
                onUpload={(filekey) => {
                  setVideos((prevVideo) => [
                    ...prevVideo,
                    {
                      url: filekey,
                      isPrimary: values.video.length > 0 ? false : true,
                    },
                  ]);
                }}
              />
            </div>
          </div>
          <div className="mb-12">
            <span className="text-gray-400">Presentations</span>
            {presentationProgress > 0 && presentationProgress < 100 && (
              <ProgressBarComp progress={presentationProgress} color="green" />
            )}
            {presentations.length > 0 &&
              presentations.map((pres: string, index: number) => {
                return (
                  <>
                    <div className="flex mt-4">
                      <div className="w-44 h-24 border flex justify-center rounded">
                        <img
                          src={
                            pres.slice(pres.length - 3) === "pdf"
                              ? "/assets/pdf-icon.svg"
                              : "/assets/ppt-icon.svg"
                          }
                          alt="file-icon"
                        />
                      </div>
                      <div className="ml-4">
                        <div
                          className="hover:text-blue-500 hover:cursor-pointer hover:underline"
                          // onClick={() => setOpenPresentation(true)}
                        >{`Presentation ${index + 1}`}</div>
                        <div
                          className="text-blue-500 mt-2 cursor-pointer"
                          onClick={() =>
                            setPresentations(
                              presentations.filter((prest) => prest !== pres)
                            )
                          }
                        >
                          Remove
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            <div className="flex items-center cursor-pointer mt-3 text-blue-500">
              <FileUpload
                setProgress={setPresentationProgress}
                fileType=".pdf, .ppt, .pptx"
                amagi_id={values.account?.amagi_id!}
                onUpload={(filekey) => {
                  setPresentations((present) => [...present, filekey]);
                }}
              />
            </div>
          </div>
          <div className="text-base font-semibold mt-8 mb-2">
            Social Profiles
          </div>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div className="flex justify-start items-center">
              <span className="mr-2 mt-5 text-xl">
                <AiFillInstagram />
              </span>
              <FormikInput
                inlinelabel="Instagram"
                name="instagram"
                placeholder="Ex: https://instagram.com"
                defaultValue={values.instagram}
              />
            </div>
            <div className="flex justify-start items-center">
              <span className="mr-2 mt-5 text-xl">
                <AiFillFacebook />
              </span>
              <FormikInput
                inlinelabel="Facebook"
                name="facebook"
                placeholder="Ex: https://facebook.com"
                defaultValue={values.facebook}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <div className="flex justify-start items-center">
                <span className="mr-2 mt-5 text-xl">
                  <AiFillLinkedin />
                </span>
                <FormikInput
                  inlinelabel="Linkedin"
                  name="linkedin"
                  placeholder="Ex: https://linkedin.com"
                  defaultValue={values.linkedin}
                />
              </div>
            </div>
            <div className="flex justify-start items-center">
              <span className="mr-2 mt-5 text-xl">
                <AiFillTwitterSquare />
              </span>
              <FormikInput
                inlinelabel="Twitter"
                name="twitter"
                placeholder="Ex: https://twitter.com"
                defaultValue={values.twitter}
              />
            </div>
          </div>
        </>
      </ToggleBox>
      <ToggleBox
        columns={hideColumns}
        handleHideColumns={handleHideColumns}
        handleUnhideColumns={handleUnhideColumns}
        header="Platform Preferences"
      >
        <>
          <div className="mb-8">
            <FormikAvailabilityRegions
              availRegion={
                values?.availablity_country_and_regions.length > 0
                  ? values?.availablity_country_and_regions
                  : [
                      {
                        country: [],
                        region: "",
                      },
                    ]
              }
              fieldName="availablity_country_and_regions"
            />
          </div>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikMultiSelect
                inlinelabel="Target Audience"
                name="target_audience"
                defaultValue={values.target_audience}
                defFields={channelFields.targetAudience.sort()}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Available in Amagi NOW</label>
              <FormikSwitch
                name="available_in_amagi_now"
                defaultValue={values.available_in_amagi_now}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikMultiSelect
                inlinelabel="Preferred Genre(s)"
                name="preferred_genre"
                defaultValue={values.preferred_genre}
                defFields={channelFields.genre}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikMultiSelect
                inlinelabel="Preferred Language(s)"
                name="preferred_language"
                defaultValue={values.preferred_language}
                defFields={channelFields.languages}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="SSAI By"
                name="ssai_by"
                defaultValue={values.ssai_by}
                defFields={platformFields.ssai_by.sort()}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Inventory Model"
                name="inventory_model"
                defaultValue={values.inventory_model}
                defFields={platformFields.inventory_model}
              />
            </div>
          </div>
        </>
      </ToggleBox>
      <ToggleBox
        columns={hideColumns}
        handleHideColumns={handleHideColumns}
        handleUnhideColumns={handleUnhideColumns}
        header="Platform Insights"
      >
        <>
          <div className="text-base font-semibold mb-2">
            Metrics (Region-wise):
          </div>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div>
              <FormikSelect
                inlinelabel="Avg. Monthly Active Users"
                name="avg_monthly_active_user"
                defaultValue={values.avg_monthly_active_user}
                defFields={platformFields.active_users}
              />
            </div>
            <div>
              <FormikSelect
                inlinelabel="Avg. Monthly HOV"
                name="avg_monthly_hov"
                defaultValue={values.avg_monthly_hov}
                defFields={platformFields.monthly_hov}
              />
            </div>
            <div>
              <FormikRange
                inlinelabel="Avg. Ad-minutes per Hour"
                placeholder="Range: 0 - 60"
                min="0"
                max="60"
                subText="mins"
                name="avg_ad_minute_per_hour"
                defaultValue={values.avg_ad_minute_per_hour}
              />
            </div>
            <div>
              <FormikRange
                inlinelabel="Estimated Channel Deliveries"
                placeholder="Range: 0 - x"
                min="0"
                name="estimated_channel_deliveries"
                defaultValue={values.estimated_channel_deliveries}
              />
            </div>
          </div>
          <div className="mt-8">
            <FormikPlatformArtwork artwork={values.platform_artwork} />
          </div>
        </>
      </ToggleBox>
      <ToggleBox
        columns={hideColumns}
        handleHideColumns={handleHideColumns}
        handleUnhideColumns={handleUnhideColumns}
        header="VOD Details"
      >
        <>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="VOD Support"
                name="vod_support"
                defaultValue={values.vod_support}
                defFields={["Yes", "No"]}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="VOD Delivery Mode"
                name="vod_delivery_mode"
                defaultValue={values.vod_delivery_mode}
                defFields={platformFields.vod_delivery_mode.sort()}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="VOD Connector Availability"
                name="vod_connector_availability"
                defaultValue={values.vod_connector_availability}
                defFields={["Yes", "No"]}
              />
            </div>
          </div>
        </>
      </ToggleBox>
      <ToggleBox
        columns={hideColumns}
        handleHideColumns={handleHideColumns}
        handleUnhideColumns={handleUnhideColumns}
        header="Platform Contacts"
      >
        <div>
          <PlatformContacts
            contacts={
              values.platform_contacts && values.platform_contacts.length > 0
                ? values.platform_contacts
                : [
                    {
                      name: "",
                      email: "",
                    },
                    ...values.platform_contacts,
                  ]
            }
          />
        </div>
      </ToggleBox>
      <ToggleBox
        columns={hideColumns}
        handleHideColumns={handleHideColumns}
        handleUnhideColumns={handleUnhideColumns}
        header="Reference Links"
      >
        <div>
          <ReferenceLinks
            references={
              values.reference_links && values.reference_links.length > 0
                ? values.reference_links
                : [
                    {
                      name: "",
                      link: "",
                    },
                    ...values.reference_links,
                  ]
            }
          />
        </div>
      </ToggleBox>
    </div>
  );
};

export default PlatformBusinessFieldsForm;
