import React, { ChangeEvent, useEffect, useState } from "react";
import { useField } from "formik";
import { TrashIcon } from "@heroicons/react/outline";
import { platformFields } from "../../constants";
import { PlatformArtworkType } from "../platforms/platform-edit-form/types";

type InputType = {
  inputName: string;
  inputArtwork: string;
  inputAspect: string;
  inputFormat: string;
  inputMaxFileSize: number | null;
  inputApplication: string;
  inputComments: string;
};

const FormikPlatformArtwork: React.FC<{
  artwork: Array<PlatformArtworkType>;
}> = ({ artwork }) => {
  const [inputTable, setInputTable] =
    useState<Array<PlatformArtworkType>>(artwork);
  const [input, setInput] = useState<InputType>({
    inputName: "",
    inputArtwork: "",
    inputAspect: "",
    inputFormat: "",
    inputMaxFileSize: null,
    inputApplication: "",
    inputComments: "",
  });
  const [format, setFormat] = useState<string>("Please select");
  const [mandatory, setMandatory] = useState<boolean>(false);

  const handleInputString = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>,
    input: string
  ) => {
    let updatedInputString = {};
    updatedInputString = { [input]: e.target.value };
    setInput((input) => ({
      ...input,
      ...updatedInputString,
    }));
  };

  const handleInputNumber = (
    e: ChangeEvent<HTMLInputElement>,
    input: string
  ) => {
    let updatedInputNumber = {};
    updatedInputNumber = { [input]: parseInt(e.target.value) };
    setInput((input) => ({
      ...input,
      ...updatedInputNumber,
    }));
  };

  const handleSubmit = () => {
    input.inputName.length > 0 &&
      input.inputArtwork.length > 0 &&
      input.inputAspect.length > 0 &&
      input.inputFormat.length > 0 &&
      input.inputMaxFileSize !== null &&
      input.inputApplication.length > 0 &&
      input.inputComments.length > 0 &&
      setInputTable((inputTable) => [
        ...inputTable,
        {
          name: input.inputName,
          mandatory: mandatory,
          artwork: input.inputArtwork,
          aspect_ratio: input.inputAspect,
          format: input.inputFormat,
          max_file_size: input.inputMaxFileSize,
          application: input.inputApplication,
          comments: input.inputComments,
        },
      ]);
    setInput({
      inputName: "",
      inputArtwork: "",
      inputAspect: "",
      inputFormat: "",
      inputMaxFileSize: null,
      inputApplication: "",
      inputComments: "",
    });
    setFormat("Please select");
    setMandatory(false);
  };

  const _field = useField("platform_artwork");
  const { setValue } = _field[2];

  useEffect(() => {
    setValue(inputTable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputTable.length]);

  return (
    <div style={{ padding: "5px 0px" }}>
      <label className="text-gray-400">
        Platform's Channel Artwork Requirement
      </label>
      <table className="mt-4" style={{ width: "100%", fontSize: "16px" }}>
        <thead>
          <tr className="bg-gray-300">
            <th className="py-2 pl-2">S No.</th>
            <th className="text-left py-2 pl-2">Name</th>
            <th className="text-left py-2 pl-2">Mandatory</th>
            <th className="text-left py-2 pl-2">Artwork Dimension</th>
            <th className="text-left py-2 pl-2">Aspect Ratio</th>
            <th className="text-left py-2 pl-2">Format</th>
            <th className="text-left py-2 pl-2">Max File Size</th>
            <th className="text-left py-2 pl-2">Application</th>
            <th className="text-left py-2 pl-2">Comments</th>
          </tr>
        </thead>
        <tbody className="bg-gray-50">
          {inputTable.map((data: PlatformArtworkType, index: number) => {
            return (
              <tr key={index}>
                <td style={{ fontSize: "16px" }} className="py-2 pl-2">
                  {index + 1}
                </td>
                <td
                  style={{ fontSize: "16px" }}
                  className="text-left py-2 pl-2"
                >
                  {data.name}
                </td>
                <td
                  style={{ fontSize: "16px" }}
                  className="text-left py-2 pl-2"
                >
                  {data.mandatory ? "Yes" : "No"}
                </td>
                <td
                  style={{ fontSize: "16px" }}
                  className="text-left py-2 pl-2"
                >
                  {data.artwork}
                </td>
                <td
                  style={{ fontSize: "16px" }}
                  className="text-left py-2 pl-2"
                >
                  {data.aspect_ratio}
                </td>
                <td
                  style={{ fontSize: "16px" }}
                  className="text-left py-2 pl-2"
                >
                  {data.format}
                </td>
                <td
                  style={{ fontSize: "16px" }}
                  className="text-left py-2 pl-2"
                >
                  {data.max_file_size} MB
                </td>
                <td
                  style={{ fontSize: "16px" }}
                  className="text-left py-2 pl-2"
                >
                  {data.application}
                </td>
                <td
                  style={{ fontSize: "16px" }}
                  className="text-left py-2 pl-2"
                >
                  {data.comments}
                </td>
                <td className="bg-white py-2 pl-2 cursor-pointer">
                  <TrashIcon
                    onClick={() =>
                      setInputTable(inputTable.filter((f) => f !== data))
                    }
                    className="h-5 w-5"
                  />
                </td>
              </tr>
            );
          })}
          <tr>
            <td className="py-1 pl-2" style={{ fontSize: "16px" }}>
              {inputTable.length + 1}
            </td>
            <td className="text-left py-1 pl-2 w-32">
              <input
                type="text"
                value={input.inputName}
                onChange={(e) => {
                  handleInputString(e, "inputName");
                }}
                className="py-0 px-0 border-0 focus:ring-0 outline-none placeholder:text-gray-200 bg-transparent w-full"
                placeholder="Enter text"
              />
            </td>
            <td className="text-left py-1 pl-2">
              <div className="flex">
                <div className="flex justify-between items-center">
                  <input
                    id={`mandatory_yes`}
                    name={"mandatory"}
                    type="radio"
                    onClick={() => setMandatory(true)}
                    defaultChecked={mandatory}
                    className="checked:bg-black focus:ring-0"
                    style={{
                      border: "2px solid black",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  <label
                    htmlFor={`mandatory_yes`}
                    className="ml-2 font-semibold"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex justify-between items-center ml-3">
                  <input
                    id={`mandatory_no`}
                    name={"mandatory"}
                    type="radio"
                    onClick={() => setMandatory(false)}
                    defaultChecked={!mandatory}
                    className="checked:bg-black focus:ring-0"
                    style={{
                      border: "2px solid black",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  <label
                    htmlFor={`mandatory_no`}
                    className="ml-2 font-semibold"
                  >
                    No
                  </label>
                </div>
              </div>
            </td>
            <td className="text-left py-1 pl-2 w-32">
              <input
                type="text"
                value={input.inputArtwork}
                onChange={(e) => {
                  handleInputString(e, "inputArtwork");
                }}
                className="py-0 px-0 border-0 focus:ring-0 outline-none placeholder:text-gray-200 bg-transparent w-full"
                placeholder="Ex. 1920px x 1080px"
              />
            </td>
            <td className="text-left py-1 pl-2 w-32">
              <input
                type="text"
                value={input.inputAspect}
                onChange={(e) => {
                  handleInputString(e, "inputAspect");
                }}
                className="py-0 px-0 border-0 focus:ring-0 outline-none placeholder:text-gray-200 bg-transparent w-full"
                placeholder="Ex. 16:9, 4:3"
              />
            </td>
            <td className="text-left py-1 pl-2">
              <select
                style={{ padding: "5.5px 0px" }}
                value={format}
                onChange={(e) => {
                  handleInputString(e, "inputFormat");
                  setFormat(e.target.value);
                }}
                className="border-0 focus:ring-0 outline-none bg-transparent w-full"
              >
                <option disabled>Please select</option>
                {platformFields.format
                  .sort()
                  .map((d: string, index: number) => {
                    return <option key={index}>{d}</option>;
                  })}
              </select>
            </td>
            <td className="text-left py-1 pl-2">
              <div className="flex items-center">
                <input
                  type="number"
                  value={input.inputMaxFileSize!}
                  onChange={(e) => {
                    handleInputNumber(e, "inputMaxFileSize");
                  }}
                  className="focus:ring-0 px-2 py-1 bg-transparent w-full rounded"
                  placeholder="1 - 100"
                  min="1"
                  max="100"
                />
                <span className="ml-2">MB</span>
              </div>
            </td>
            <td className="text-left py-1 pl-2">
              <textarea
                value={input.inputApplication}
                onChange={(e) => {
                  handleInputString(e, "inputApplication");
                }}
                className="py-0 px-0 border-0 focus:ring-0 outline-none placeholder:text-gray-200 bg-transparent w-full"
                placeholder="Enter your Application"
              />
            </td>
            <td className="text-left py-1 pl-2">
              <textarea
                value={input.inputComments}
                onChange={(e) => {
                  handleInputString(e, "inputComments");
                }}
                className="py-0 px-0 border-0 focus:ring-0 outline-none placeholder:text-gray-200 bg-transparent w-full"
                placeholder="Enter your comments"
              />
            </td>
            <td className="py-1 pl-2 bg-white text-left cursor-pointer text-blue-500">
              <span onClick={handleSubmit}>+ ADD</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FormikPlatformArtwork;
