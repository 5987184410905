import React, { useState } from "react";
import ErrorBoundary from "../error-boundary";
import SubTabs from "../sub-tabs";
import ContractTerms from "./contract-terms";
import ContractDocuments from "./documents";
import MSATable from "./msa-table";

const ContractDetails = () => {
  const [ticketTab, setTicketTab] = useState<string>("MSA Rate Card");
  return (
    <ErrorBoundary>
      <div>
        <div className="">
          <SubTabs
            customStyles=""
            currentTab={ticketTab}
            toggleTab={setTicketTab}
            tabList={[
              { name: "MSA Rate Card" },
              { name: "Contract Terms" },
              { name: "Documents" },
            ]}
          />
        </div>
        {ticketTab === "MSA Rate Card" && <MSATable />}
        {ticketTab === "Contract Terms" && <ContractTerms />}
        {ticketTab === "Documents" && <ContractDocuments />}
      </div>
    </ErrorBoundary>
  );
};

export default ContractDetails;
