import { ChevronRightIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCDPRole } from "../../../cdp-role-context";
import Loader from "../../../common/loader";
import { useDeliverybyPkQuery } from "../../../hasura.graphql";
import { ModalType } from "../../../pages/channels";
import ErrorBoundary from "../../error-boundary";
import Modal from "../../modal";
import LiveDeliveryEditForm from "../delivery-edit-form";
import LiveDeliveryFieldsForm from "./delivery-fields";
import SubTabs from "../../sub-tabs";

const LiveDeliveryViewForm: React.FC<{
  deliveryId: string;
  isModal: boolean;
}> = ({ deliveryId, isModal }) => {
  const navigate = useNavigate();
  const [liveTab, setLiveTab] = useState("Delivery");
  const [editForm, setEditForm] = useState<ModalType | null>(null);
  const { CDPRole } = useCDPRole();

  const { data, loading, error } = useDeliverybyPkQuery({
    variables: {
      id: deliveryId,
    },
  });

  if (loading) {
    return (
      <div className={`${!isModal && "mx-6 mt-4"}`}>
        <Loader />
      </div>
    );
  }
  if (error?.message.includes("invalid input syntax")) {
    return <div className="italic text-lg">Delivery doesn't exist</div>;
  }
  if (error) {
    return <i>Error</i>;
  }
  if (!data) {
    return <i>Live data not available</i>;
  }

  const deliveryData = data?.delivery[0];

  return (
    <ErrorBoundary message="Delivery doesn't exist">
      <div className={`${!isModal && "mx-6"}`}>
        {isModal ? (
          <>
            <div className="mb-2 text-sm sticky bg-secondaryBg top-12 z-20 flex justify-between">
              <div>
                <SubTabs
                  customStyles=""
                  currentTab={liveTab}
                  tabList={[{ name: "Delivery" }]}
                  toggleTab={setLiveTab}
                />
              </div>
              {CDPRole.includes("channel-edit") && (
                <div className="">
                  <button
                    type="button"
                    onClick={() =>
                      setEditForm({
                        id: deliveryData?.id!,
                        name: deliveryData?.id!,
                      })
                    }
                    className="py-1 px-3 bg-primaryColor text-white rounded-sm"
                  >
                    Edit
                  </button>
                </div>
              )}
            </div>
            {editForm && CDPRole.includes("channel-edit") && (
              <Modal
                title="All Live Deliveries"
                modalWidth="100vw"
                modalShow={editForm}
                onClose={setEditForm}
              >
                <LiveDeliveryEditForm
                  close={() => setEditForm(null)}
                  deliveryId={deliveryData?.id!}
                  isModal={true}
                />
              </Modal>
            )}
          </>
        ) : (
          <>
            <div className="sticky top-14 z-10">
              <div className="flex justify-between items-center sticky top-14 h-14 w-full bg-secondaryBg">
                <div className="flex items-center">
                  <span
                    onClick={() => navigate(`/deliveries/live-deliveries`)}
                    className="font-medium text-gray-600 cursor-pointer hover:text-blue-500 hover:underline"
                  >
                    All Live Deliveries
                  </span>
                  <ChevronRightIcon className="h-5 w-5 mx-3 text-gray-400" />
                  <span>{deliveryData?.id}</span>
                </div>
                {CDPRole.includes("channel-edit") && (
                  <div className="">
                    <button
                      type="button"
                      onClick={() =>
                        navigate(
                          `/deliveries/live-deliveries/${deliveryId}/edit`
                        )
                      }
                      className="py-1 px-3 bg-primaryColor text-white rounded-sm"
                    >
                      Edit
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="mb-4 sticky top-28 z-20">
              <SubTabs
                customStyles=""
                currentTab={liveTab}
                tabList={[{ name: "Delivery" }]}
                toggleTab={setLiveTab}
              />
            </div>
          </>
        )}

        <div className="w-full">
          {liveTab === "Delivery" && (
            <LiveDeliveryFieldsForm deliveryData={deliveryData!} />
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default LiveDeliveryViewForm;
