import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import Loader from "../../common/loader";
import {
  CustomerOverviewRevenueDataFragment,
  useCustomerOverviewRevenueQuery,
} from "../../hasura.graphql";
import CommonChart from "../charts/chart";
import { months, DateSort } from "../utils";

const OverviewRevenue: React.FC<{ amagi_id: string }> = ({ amagi_id }) => {
  const [fromDate, setFromDate] = useState<string>("6");
  const [overviewRevenue, setOverviewRevenue] =
    useState<CustomerOverviewRevenueDataFragment>();
  const revenue_split = useMemo(
    () =>
      overviewRevenue?.customer_revenue_split
        ? overviewRevenue.customer_revenue_split
        : [],
    [overviewRevenue]
  );
  const chart_label = Array.from(new Set(revenue_split?.map((a) => a.month)));
  chart_label.sort((a, b) => DateSort(a, b));

  const filtered_chart_label =
    chart_label.length > 12
      ? chart_label.slice(chart_label.length - 12, chart_label.length)
      : chart_label;

  const subscription_chart_data = useMemo(() => {
    return filtered_chart_label.map((data: any) => {
      let subscription = revenue_split?.find(
        (s) => s.month === data && s.revenue_split === "Subscription"
      );
      if (!subscription) {
        return {
          amount: null,
        };
      }
      return {
        amount: subscription.amount,
      };
    });
  }, [filtered_chart_label, revenue_split]);

  const consumption_chart_data = useMemo(() => {
    return filtered_chart_label.map((data: any) => {
      let consumption = revenue_split?.find(
        (s) => s.month === data && s.revenue_split === "Consumption"
      );
      if (!consumption) {
        return {
          amount: null,
        };
      }
      return {
        amount: consumption.amount,
      };
    });
  }, [filtered_chart_label, revenue_split]);

  const others_chart_data = useMemo(() => {
    return filtered_chart_label.map((data: any) => {
      let others = revenue_split?.find(
        (s) => s.month === data && s.revenue_split === "Others"
      );
      if (!others) {
        return {
          amount: null,
        };
      }
      return {
        amount: others.amount,
      };
    });
  }, [filtered_chart_label, revenue_split]);
  const { data, loading, error } = useCustomerOverviewRevenueQuery({
    variables: {
      amagi_id: amagi_id,
      from_date: DateTime.local()
        .startOf("month")
        .minus({ months: parseInt(fromDate) - 1 })
        .toISO()
        .slice(0, 10),
      to_date: DateTime.local().startOf("month").toISO().slice(0, 10),
    },
    onCompleted: (data) => {
      setOverviewRevenue(data.customer!);
    },
  });

  if (loading) return <Loader />;
  if (error) return <i>Error</i>;
  if (!data) return <i>No Customer Revenue Data</i>;

  return (
    <div
      className="bg-white rounded-md pb-4 flex flex-col justify-center"
      style={{ border: "1px solid #E0E0E0" }}
    >
      <div className="flex justify-between border-b border-gray-200">
        <div className="text-left pl-4 py-2 font-medium">Revenue</div>
        <select
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          className="border-0 rounded text-sm text-gray-500 focus:ring-0"
        >
          <option value={3}>Last 3 months</option>
          <option value={6}>Last 6 months</option>
          <option value={12}>Last 12 months</option>
        </select>
      </div>
      <div className="p-2 mt-2">
        <div className="flex justify-end items-center mx-2">
          <div
            style={{ width: 6, height: 6, background: "#716AE8" }}
            className="rounded-full"
          ></div>
          <div className="mx-2 text-xs text-gray-600">Subscription</div>
          <div
            style={{ width: 6, height: 6, background: "#67B045" }}
            className="rounded-full ml-4"
          ></div>
          <div className="mx-2 text-xs text-gray-600">Consumption</div>
          <div
            style={{ width: 6, height: 6, background: "#FF8D20" }}
            className="rounded-full ml-4"
          ></div>
          <div className="mx-2 text-xs text-gray-600">Others</div>
        </div>
        <div
          style={{
            height: `${window.innerHeight - 600}px`,
          }}
        >
          <CommonChart
            stackedBar={true}
            showLegend={false}
            labelRotation={false}
            showDatalabels={true}
            chartData={{
              labels: filtered_chart_label.map(
                (label) =>
                  `${months[parseInt(label.slice(5)) - 1]}'${label.slice(2, 4)}`
              ),
              datasets: [
                {
                  type: "bar" as const,
                  label: "Subscription",
                  data: subscription_chart_data?.map((c) => c.amount),
                  maxBarThickness: 25,
                  backgroundColor: ["#716AE8"],
                },
                {
                  type: "bar" as const,
                  label: "Consumption",
                  data: consumption_chart_data?.map((c) => c.amount),
                  maxBarThickness: 25,
                  backgroundColor: ["#67B045"],
                },
                {
                  type: "bar" as const,
                  label: "Others",
                  data: others_chart_data?.map((c) => c.amount),
                  maxBarThickness: 25,
                  backgroundColor: ["#FF8D20"],
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OverviewRevenue;
