import React from "react";
import { CustomerAllDataFragment } from "../../hasura.graphql";
import "./styles.scss";
import "./styles.scss";
import { CustomerTabs } from "./tabs";

const Customer: React.FC<{
  customer: CustomerAllDataFragment;
  isModal: boolean;
  param?: string;
}> = ({ customer, isModal, param }) => {
  return (
    <div className="customer-page-container">
      <div className="first-section">
        <div className="flex">
          <div className="grid w-full">
            <div>
              <CustomerTabs
                isModal={isModal}
                param={param}
                customer={customer}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customer;
