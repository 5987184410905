import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import Filter from "./filter";
import { AllCustomerDataFragment } from "../../hasura.graphql";

const SidebarFilter: React.FC<{ customers: AllCustomerDataFragment[] }> = ({
  customers,
}) => {
  const [openFilter, setOpenFilter] = useState(true);
  const filters = [
    { label: "Customer", filter_type: "account_name" },
    { label: "Type", filter_type: "customer_type" },
    { label: "Segment", filter_type: "segment" },
    { label: "Region", filter_type: "region" },
    { label: "Size", filter_type: "size" },
    { label: "Status", filter_type: "status" },
  ];

  const filteredData = customers.map((cust) => {
    return {
      amagi_id: cust.amagi_id,
      account_name: cust.account_name,
      logo: cust.logo ? cust.logo : null,
      account_legal_name: cust.account_legal_name,
      customer_type: cust.customer_type ? cust.customer_type : "-",
      customer_status: cust.status ? cust.status : "-",
      status: cust.status ? cust.status : "-",
      invoicing_currency: cust.invoicing_currency
        ? cust.invoicing_currency
        : "USD",
      segment: cust.industry_segment ? cust.industry_segment : "-",
      region: cust.address?.region ? cust.address?.region : "-",
      size: cust.size ? cust.size : "-",
    };
  });

  return (
    <div className="p-2 border-r">
      <div className="flex justify-between items-center px-2">
        <div className="text-base font-medium">Filter</div>
        <div onClick={() => setOpenFilter(!openFilter)}>
          {openFilter ? (
            <ChevronUpIcon className="h-5 w-5" />
          ) : (
            <ChevronDownIcon className="h-5 w-5" />
          )}
        </div>
      </div>
      <div className={`${openFilter ? "" : "hidden"}`}>
        {filters.map((fil) => (
          <Filter {...fil} customers={filteredData} />
        ))}
      </div>
    </div>
  );
};

export default SidebarFilter;
