import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilSquare } from '@fortawesome/free-solid-svg-icons';
import { useUpdateCustomerLogoMutation } from "../hasura.graphql";

const CustomerLogoUpload: React.FC<{
  amagi_id: string;
  value?: string | Array<string>;
  onUpload: (path: string) => void;
  fileType: string;
}> = ({ amagi_id, value, onUpload, fileType }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [controller, setController] = useState<AbortController | null>(null);
  const updateCustomerLogoMutation = useUpdateCustomerLogoMutation()[0];
  const [logoImageUrl, setLogoImageUrl] = useState<string | null>(null);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFile(null);
    setLogoImageUrl(null);
    if (!isUploading && controller) {
      controller.abort(); 
      setController(null);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setLogoImageUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const compressImage = async (file: File): Promise<Blob | null> => {
    return new Promise((resolve, reject) => {
      if (!file) {
        resolve(null);
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = event => {
        const img = new Image();
        img.src = event.target?.result as string;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
          const width = img.width / 4;
          const height = img.height / 4;
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob(resolve, file.type);
        };
        img.onerror = error => reject(error);
      };
    });
  };

  const uploadLogo = async (logoUrl: string) => {
    try {
      await updateCustomerLogoMutation({
        variables: {
          amagi_id: amagi_id,
          logoUrl: logoUrl,
        },
      });
      toast.success("Uploaded Successfully");
      closeModal();
    } catch (error: any) {
      if (error.name === 'AbortError') {
        console.log('Upload cancelled by user');
      } else {
        console.error("Error uploading file:", error);
        toast.error("Upload failed. Please try again.");
      }
    } finally {
      setIsUploading(false);
      setController(null);
    }
  };

  const handleSubmit = async () => {
    if (selectedFile) {
      setIsUploading(true);
      const newController = new AbortController();
      setController(newController);

      let fileToUpload: File | null = selectedFile;
      if (selectedFile.size > 1024 * 1024) { 
        try {
          const compressedFile = await compressImage(selectedFile);
          if (compressedFile) {
            fileToUpload = new File([compressedFile], selectedFile.name, {
              type: selectedFile.type,
            });
          }
        } catch (error) {
          console.error("Error compressing image:", error);
          toast.error("Error compressing image. Please try again.");
          setIsUploading(false);
          return;
        }
      }

      const params = {
        objectName: `${fileToUpload.name.replace(/[^\w\d_\-.]+/gi, "")}`,
        contentType: fileToUpload.type,
        amagiId: amagi_id,
      };
  
      try {
        const response = await fetch(
          `${document.location.origin}/functions/s3-signed-url`,
          {
            headers: {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            },
            body: JSON.stringify(params),
            method: "POST",
            signal: newController.signal, 
          }
        );
  
        if (response.status !== 200) {
          toast.error("Upload cancelled due to server error.");
          setIsUploading(false);
          return;
        }
  
        const signedUrl = await response.json();
  
        await fetch(signedUrl, {
          method: "PUT",
          body: fileToUpload,
          headers: {
            "Content-Type": fileToUpload.type,
          },
          signal: newController.signal,
        });
  
        const url = new URL(signedUrl);
        onUpload(url.pathname.slice(1));
        
        // Extracting file extension dynamically
        const extension = url.pathname.substring(url.pathname.lastIndexOf("."));
  
        let logoUrl = `uploads${url.pathname.slice(
          url.pathname.indexOf("uploads") + "uploads".length,
          url.pathname.indexOf(extension) + extension.length
        )}`;
        console.log("the logo url is ", logoUrl);
        uploadLogo(logoUrl);
      } catch (error: any) {
        if (error.name === 'AbortError') {
          console.log('Upload cancelled by user');
        } else {
          console.error("Error uploading file:", error);
          toast.error("Upload failed. Please try again.");
        }
      } finally {
        setIsUploading(false);
        setController(null);
      }
    }
  };

  const handleCancel = async () => {
    if (isUploading && controller) {
      controller.abort(); 
      setIsUploading(false);
      setController(null);
    }
    closeModal();
  };

  return (
    <div className="relative">
      <button
        className="absolute bottom-2 right-2 bg-white bg-opacity-80 px-2 py-1 rounded-full cursor-pointer"
        onClick={openModal}
        title="Click to Update Logo Image"
      >
        <FontAwesomeIcon icon={faPencilSquare} className="h-5 w-5 text-gray-500" style={{ backgroundColor: "transparent" }} />
      </button>

      <ToastContainer />

      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg overflow-auto max-h-full">
            <button
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
              onClick={closeModal}
            >
              Close
            </button>

            <input
              type="file"
              accept={fileType}
              onChange={handleFileChange}
              className="mb-4"
            />

            {logoImageUrl && (
              <div className="mb-4"> 
                <img
                  src={logoImageUrl}
                  alt="Customer Logo"
                  className="w-full h-auto"
                />
              </div>
            )}

            <div className="flex justify-end">
              <button
                className="bg-blue-500 text-white px-4 py-2 mr-2 rounded"
                onClick={handleSubmit}
                disabled={!selectedFile || isUploading}
              >
                {isUploading ? "Uploading..." : "Submit"}
              </button>
              <button
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerLogoUpload;
