import { useNavigate, useParams } from "react-router-dom";
import PlatformEditForm from "../components/platforms/platform-edit-form";
import { useFeatureFlagQuery } from "../hasura.graphql";

const PlatformByIdEdit: React.FC<{ header: string; url: string }> = ({
  header,
  url,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const role_data = useFeatureFlagQuery();

  return (
    <>
      {role_data.data?.feature_flag.find(
        (feat) => feat.feature === "channel-edit"
      ) !== undefined ? (
        <PlatformEditForm
          header={header}
          url={url}
          close={() => navigate(`${url}/${id}`)}
          platformId={id!}
          isModal={false}
        />
      ) : (
        navigate(`${url}/${id}`)
      )}
    </>
  );
};

export default PlatformByIdEdit;
