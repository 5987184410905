import React, { Fragment, useEffect, useState } from "react";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useFilters,
  Row,
} from "react-table";
import { TableInstanceWithHooks } from "../../common/custom-table";
import TableFilter from "../../common/table-filter";
import PopoverMenu from "../popover-menu";
import ExportCSV from "../exportComponents/exportCSV";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import moment from "moment";
import {
  contentWidth,
  tableCellCheckboxStyle,
  tableCellStyle,
  tableExcludeCellStyle,
  tableExcludeHeaderStyle,
  tableHeaderStyle,
} from "../utils";
import DelayedToolTip from "../delayed-tooltip";
import { FinancialTableType } from "./types";
import MonthRangePicker from "../date-range-pickers/month-range-picker";
import { Toaster } from "react-hot-toast";
import { Popover, Transition } from "@headlessui/react";

const FinancialTable: React.FC<FinancialTableType> = ({
  subTable,
  chart,
  columns,
  header,
  data,
  height,
  chartHeight,
  placeholder = "Search",
  highlightRow,
  exportData,
  exportHeader,
  fileName,
  setOpen,
  open,
  isSubTable = true,
  isToggle = false,
  dateFilter,
  setDateFilter,
  yearFields,
  searchParam,
  warning = "rows",
}) => {
  const [toggleView, setToggleView] = useState<string>("table");
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
  const [filteredRows, setFilteredRows] = useState<Array<any>>([]);
  const [isSearched, setIsSearched] = useState<boolean>(false);
  const [checked, setChecked] = useState<Array<string>>([]);
  const defaultColumn = React.useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  const handleDateRange = (fromDate: string, toDate: string) => {
    setDateFilter({
      from: fromDate,
      to: toDate,
    });
  };

  const filterTypes = React.useMemo(
    () => ({
      multiSelect: (rows: Row<any>[], id: string | any, filterValues: any) => {
        if (filterValues.length === 0) {
          setFilteredRows([]);
          return [];
        } else {
          setFilteredRows(
            rows.filter((r) => filterValues.includes(r.values[id]))
          );
          return rows.filter((r) => filterValues.includes(r.values[id]));
        }
      },
    }),
    []
  );

  const toggleRowOpen = (id: string) => {
    if (open === id) {
      setOpen(null);
    } else {
      setOpen(id);
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
    setGlobalFilter,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    gotoPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    allColumns,
    getToggleHideAllColumnsProps,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 100, hiddenColumns: [] },
      autoResetHiddenColumns: false,
      autoResetResize: false,
      autoResetGlobalFilter: false,
      autoResetPage: isSearched ? true : false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as TableInstanceWithHooks<object>;

  const { globalFilter } = state;

  useEffect(() => {
    setIsSearched(globalFilter && globalFilter.length > 0 ? true : false);
  }, [globalFilter]);

  const handleSelectRows = (id: string) => {
    if (checked.length === 1 && checked.includes(id)) {
      setSelectedRows([]);
      setChecked([]);
    } else if (checked && checked.includes(id)) {
      setSelectedRows(
        selectedRows!.filter((row, index) => index !== parseInt(id))
      );
      setChecked((prevChecked) => prevChecked.filter((v) => v !== id));
    } else {
      setSelectedRows((selRows) => [...selRows, exportData![parseInt(id)]]);
      setChecked((prevChecked) => [...prevChecked, id]);
    }
  };

  const handleSelectAllRows = () => {
    if (checked.length === rows.length) {
      setChecked([]);
      setSelectedRows([]);
    } else {
      if (filteredRows.length > 0) {
        setChecked(filteredRows.map((row) => row.id));
        setSelectedRows(
          filteredRows!.map((fRows) => {
            let temp = exportData!.find(
              (data, index) => index === parseInt(fRows.id)
            );
            return temp;
          })
        );
      } else {
        setChecked(rows.map((row) => row.id));
        setSelectedRows(
          rows!.map((fRows) => {
            let temp = exportData!.find(
              (data, index) => index === parseInt(fRows.id)
            );
            return temp;
          })
        );
      }
    }
  };

  return (
    <>
      <Toaster />
      <div className="rounded mt-4">
        <div
          className="custom_container rounded-t p-2 flex justify-between items-center"
          style={{
            width: contentWidth,
          }}
        >
          <div className="flex w-1/2">
            <div
              className={`px-3 ${
                !isToggle && "w-1/2"
              } flex items-center font-medium`}
            >
              {header}
            </div>
            <div
              className={`flex ${
                isToggle ? "justify-between" : "justify-end"
              } w-full`}
            >
              {isToggle && (
                <div className="flex items-center ml-3 w-2/12">
                  <div
                    onClick={() => setToggleView("table")}
                    className={`p-2 w-full h-9 flex items-center justify-center rounded-sm ${
                      toggleView === "table"
                        ? "border-2 border-primaryColor"
                        : "border-2 border-r-0 border-gray-300"
                    }`}
                  >
                    <DelayedToolTip title="table view">
                      <img src="/assets/table-icon.svg" alt="table-icon" />
                    </DelayedToolTip>
                  </div>
                  <div
                    onClick={() => setToggleView("chart")}
                    className={`p-2 w-full h-9 flex items-center justify-center rounded-sm ${
                      toggleView === "chart"
                        ? "border-2 border-primaryColor"
                        : "border-2 border-l-0 border-gray-300"
                    }`}
                  >
                    <DelayedToolTip title="graph view">
                      <img src="/assets/chart-icon.svg" alt="chart-icon" />
                    </DelayedToolTip>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-2/3 flex justify-end items-center">
            {toggleView === "table" ? (
              <>
                <MonthRangePicker
                  fromDate={dateFilter.from}
                  toDate={dateFilter.to}
                  onChange={handleDateRange}
                />
                <div className="w-3/5 ml-3">
                  <TableFilter
                    filter={globalFilter}
                    search={searchParam}
                    setFilter={setGlobalFilter}
                    placeholder={placeholder}
                  />
                </div>
                <div className="mx-3 z-10">
                  <DelayedToolTip title={`Download selected ${warning}`}>
                    <ExportCSV
                      csvData={selectedRows?.flat(1) || []}
                      csvHeaders={exportHeader || []}
                      fileName={`${fileName}_${moment().format(
                        "YYYYMMDDHHmmss"
                      )}.csv`}
                      warning={warning}
                    />
                  </DelayedToolTip>
                </div>
                <PopoverMenu
                  icon="column-filter.svg"
                  header="Manage Column"
                  positionStyles="right-6"
                  position="relative"
                >
                  <DelayedToolTip title="select column">
                    <div style={{ fontSize: "12px" }} className="z-20">
                      <div className="py-1">
                        <input
                          id="select-all-column"
                          className="focus:ring-0"
                          type="checkbox"
                          {...getToggleHideAllColumnsProps()}
                        />
                        <label htmlFor="select-all-column" className="pl-2">
                          Toggle All
                        </label>
                      </div>
                      {allColumns.map((column: any) => (
                        <div key={column.id} className="py-1">
                          <input
                            id={column.id}
                            className="focus:ring-0"
                            type="checkbox"
                            {...column.getToggleHiddenProps()}
                          />
                          <label htmlFor={column.id} className="pl-2">
                            {column.Header}
                          </label>
                        </div>
                      ))}
                    </div>
                  </DelayedToolTip>
                </PopoverMenu>
              </>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        {toggleView === "table" ? (
          <>
            <div
              className="md:rounded-md"
              style={{
                width: contentWidth,
              }}
            >
              <div
                style={{
                  maxWidth: contentWidth,
                  height: height,
                  overflow: "scroll",
                }}
                className="rounded-t border border-b-0 bg-lightPrimary/50"
              >
                <table {...getTableProps()} className="rounded-t">
                  <thead
                    className="bg-white border-b shadow-sm"
                    style={{
                      position: "sticky",
                      insetBlockStart: 0,
                      zIndex: 10,
                    }}
                  >
                    {headerGroups.map((headerGroup: any, index: number) => (
                      <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                        <th
                          scope="col"
                          className="font-medium w-10"
                          style={tableHeaderStyle}
                        >
                          <span className="text-center"></span>
                        </th>
                        <th
                          scope="col"
                          className="font-medium text-left w-10"
                          style={tableHeaderStyle}
                        >
                          <span className="">
                            <input
                              type="checkbox"
                              className="focus:ring-0"
                              onChange={handleSelectAllRows}
                              checked={
                                filteredRows.length === 0
                                  ? checked.length === rows.length
                                  : checked.length === filteredRows.length
                              }
                            />
                          </span>
                        </th>
                        {headerGroup.headers.map(
                          (column: any, hIndex: number) => (
                            <th
                              key={hIndex}
                              scope="col"
                              className="text-left font-medium"
                              style={
                                column.render("Header") !== "Expand" &&
                                column.render("Header") !== "EXPAND" &&
                                column.render("Header") !== "DOCUMENTS"
                                  ? tableExcludeHeaderStyle
                                  : tableHeaderStyle
                              }
                            >
                              <div
                                className={`flex ${
                                  column.textAlign ? "justify-end" : ""
                                }`}
                              >
                                <span
                                  className={`${
                                    column.isSorted && "text-primaryColor"
                                  }`}
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps()
                                  )}
                                >
                                  {column.render("Header")}
                                </span>
                                {column.render("Header") !== "Expand" &&
                                column.render("Header") !== "EXPAND" &&
                                column.render("Header") !== "DOCUMENTS" ? (
                                  <span
                                    className="mt-px ml-1.5"
                                    {...column.getHeaderProps(
                                      column.getSortByToggleProps()
                                    )}
                                  >
                                    {column.isSorted ? (
                                      column.isSortedDesc ? (
                                        <ChevronDownIcon className="h-4 w-4 text-primaryColor" />
                                      ) : (
                                        <ChevronUpIcon className="h-4 w-4 text-primaryColor" />
                                      )
                                    ) : null}
                                  </span>
                                ) : null}
                                {column.canFilter ? (
                                  <div className="ml-1">
                                    <DelayedToolTip
                                      title="filter"
                                      position="bottom-end"
                                    >
                                      {column.canFilter
                                        ? column.render("Filter")
                                        : null}
                                    </DelayedToolTip>
                                  </div>
                                ) : null}
                              </div>
                            </th>
                          )
                        )}
                      </tr>
                    ))}
                  </thead>
                  <tbody
                    {...getTableBodyProps()}
                    className="divide-y divide-gray-200 bg-white"
                  >
                    {page.length > 0 ? (
                      <>
                        {page.map((row: any, i: number) => {
                          prepareRow(row);
                          return (
                            <React.Fragment key={i + "_frag"}>
                              <tr
                                {...row.getRowProps()}
                                style={{
                                  background: row.id === open ? "#EEF3FF" : "",
                                }}
                              >
                                <td
                                  className="text-left"
                                  style={tableExcludeCellStyle}
                                >
                                  {"tier_at_product_level" in row.original ? (
                                    row.original.tier_at_product_level &&
                                    row.original.tier_at_product_level.length >
                                      0 ? (
                                      <span
                                        className="flex cursor-pointer"
                                        onClick={() => {
                                          toggleRowOpen(row.id);
                                        }}
                                      >
                                        {open === row.id ? (
                                          <div className="p-px border border-primaryColor bg-primaryColor text-white rounded">
                                            <ChevronDownIcon className="h-5 w-5" />
                                          </div>
                                        ) : (
                                          <div className="p-px border rounded">
                                            <ChevronRightIcon className="h-5 w-5" />
                                          </div>
                                        )}
                                      </span>
                                    ) : (
                                      <span></span>
                                    )
                                  ) : (
                                    <span
                                      className="flex cursor-pointer"
                                      onClick={() => {
                                        toggleRowOpen(row.id);
                                      }}
                                    >
                                      {open === row.id ? (
                                        <div className="p-px border border-primaryColor bg-primaryColor text-white rounded">
                                          <ChevronDownIcon className="h-5 w-5" />
                                        </div>
                                      ) : (
                                        <div className="p-px border rounded">
                                          <ChevronRightIcon className="h-5 w-5" />
                                        </div>
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td
                                  className="text-left"
                                  style={tableCellCheckboxStyle}
                                >
                                  <span>
                                    <input
                                      type="checkbox"
                                      className="focus:ring-0"
                                      onChange={() => handleSelectRows(row.id)}
                                      checked={checked.includes(row.id)}
                                    />
                                  </span>
                                </td>
                                {row.cells.map((cell: any) => {
                                  return (
                                    <td
                                      {...cell.getCellProps()}
                                      className={`${
                                        cell.column.textAlign
                                          ? cell.column.textAlign
                                          : "text-left"
                                      } whitespace-nowrap py-2 font-normal ${
                                        highlightRow === row.id && "bg-gray-300"
                                      }`}
                                      style={
                                        cell.render("Header") !== "Expand" &&
                                        cell.render("Header") !== "EXPAND"
                                          ? tableExcludeCellStyle
                                          : tableCellStyle
                                      }
                                    >
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                              {open === row.id && (
                                <tr>
                                  <td
                                    colSpan={columns.length + 2}
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      background: "#E4EAFA",
                                    }}
                                  >
                                    {isSubTable ? (
                                      <div className="flex">
                                        <div
                                          className="bg-primaryColor mx-6"
                                          style={{ width: 5 }}
                                        ></div>
                                        <div className="mb-2">{subTable}</div>
                                      </div>
                                    ) : (
                                      <div></div>
                                    )}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </>
                    ) : (
                      <tr>
                        <td></td>
                        <td></td>
                        <td
                          colSpan={columns.length}
                          className="p-4 bg-white italic font-medium text-left"
                        >
                          No {header} generated for selected period
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div
                className="absolute bottom-7 bg-white px-4 py-3 flex items-center justify-between border border-gray-200 sm:px-6"
                style={{ width: contentWidth }}
              >
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      Showing{" "}
                      <span className="font-medium">
                        {pageIndex * pageSize + 1}
                      </span>{" "}
                      to{" "}
                      <span className="font-medium">
                        {page.length < pageSize
                          ? rows.length
                          : (pageIndex + 1) * page.length}
                      </span>{" "}
                      of <span className="font-medium">{rows.length}</span>{" "}
                      results
                    </p>
                  </div>
                  <div className="flex sm:items-center sm:justify-between">
                    <span className="mr-3 text-sm text-gray-700">
                      Rows per page
                    </span>
                    <Popover
                      as="div"
                      className="relative items-center max-w-sm"
                    >
                      {({ open, close }) => (
                        <>
                          <Popover.Button className="rounded-full w-full focus:outline-none">
                            <div
                              placeholder={"Enter Text"}
                              className="flex justify-between border rounded px-3 py-1.5 items-center text-left focus:ring-0 border-gray-300 outline-none bg-transparent w-full"
                            >
                              <span>{pageSize}</span>
                              <span className="ml-2">
                                <ChevronDownIcon className="h-4 w-4" />
                              </span>
                            </div>
                          </Popover.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Popover.Panel className="origin-top-right text-left z-10 absolute bottom-0 right-0 min-w-full max-h-60 overflow-y-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {() => (
                                <div className="">
                                  {[10, 20, 30, 40, 50, 100].map(
                                    (inp, rIndex) => {
                                      return (
                                        <div key={rIndex}>
                                          <div
                                            className={`focus:ring-0 cursor-pointer hover:bg-blue-100 px-5 py-1 ${
                                              pageSize === inp
                                                ? "bg-blue-100"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              close();
                                              setPageSize(Number(inp));
                                            }}
                                          >
                                            {inp}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </div>
                  <div>
                    <nav
                      className="relative z-0 inline-flex rounded-md -space-x-px"
                      aria-label="Pagination"
                    >
                      {canPreviousPage && (
                        <DelayedToolTip title="Previous page" position="top">
                          <button
                            onClick={() => previousPage()}
                            className={`relative inline-flex items-center px-2 py-2 rounded-l-md bg-white text-sm font-medium text-primaryColor`}
                          >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </button>
                        </DelayedToolTip>
                      )}
                      {pageOptions
                        .slice(
                          pageIndex >= 1 ? pageIndex - 1 : pageIndex,
                          pageIndex >= 1 ? pageIndex + 2 : pageIndex + 3
                        )
                        .map((page: number) => (
                          <button
                            key={page}
                            onClick={() => gotoPage(page)}
                            className={`z-10 m-0 ${
                              page === pageIndex
                                ? "text-gray-200"
                                : "bg-white border-gray-300 border-l-0 text-primaryColor hover:underline"
                            } ${
                              page < pageIndex ? "border-r-0" : ""
                            } relative inline-flex items-center px-4 py-2 text-sm font-medium`}
                          >
                            {page + 1}
                          </button>
                        ))}
                      {canNextPage && (
                        <DelayedToolTip title="Next page" position="top">
                          <button
                            onClick={() => nextPage()}
                            className={`relative inline-flex items-center px-2 py-2 rounded-r-md bg-white text-sm font-medium text-primaryColor`}
                          >
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </button>
                        </DelayedToolTip>
                      )}
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{ height: chartHeight }}
              className="bg-white border rounded-md"
            >
              {chart}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default FinancialTable;
