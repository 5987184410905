import React, { useState } from "react";
import FormikInput from "../../formik/input";
import FormikSelect from "../../formik/select";
import FormikMultiSelect from "../../formik/multi-select";
import {
  channelFields,
  channelDeliveryFields,
  platformFields,
} from "../../../constants";
// import FormikInputDateTime from "../../formik/input-datetime";
import FormikRange from "../../formik/range";
import { DeliveryEditType, ProductListType } from "./types";
// import SearchSelect from "../../formik/search-select";
import FormikSwitch from "../../formik/switch";
import FormikDeliveryRegions from "../../formik/delivery-regions";
import FormikInputDate from "../../formik/input-date";
import FormikDeliveryProducts from "../../formik/delivery-products";
import ToggleBox from "../../toggle-box";
import FormikTextField from "../../formik/text-field";

export type ReactSelectType = {
  label: string;
  value: string;
};

const LiveDeliveryFieldsEditForm: React.FC<{
  values: DeliveryEditType;
  liveIds: Array<ReactSelectType>;
  platformIds: Array<ReactSelectType>;
  productLists: Array<ProductListType>;
}> = ({ values, liveIds, platformIds, productLists }) => {
  const [hideTabs, setHideTabs] = useState<Array<string>>([]);

  const handleHideTabs = (tab: string) => {
    setHideTabs((hideTabs) => [...hideTabs, tab]);
  };
  const handleUnhideTabs = (tab: string) => {
    setHideTabs(hideTabs.filter((f: string) => f !== tab));
  };

  return (
    <div>
      <ToggleBox
        columns={hideTabs}
        handleHideColumns={handleHideTabs}
        handleUnhideColumns={handleUnhideTabs}
        header="Delivery Details"
      >
        <>
          {/* <div className="grid grid-cols-3 gap-y-8 gap-x-36 mb-8">
            <div>
              <FormikInput
                inlinelabel="Point Product Delivery ID"
                name="point_product_delivery_id"
                defaultValue=""
              />
            </div>
          </div> */}
          {/* <div className="text-base font-semibold mb-2">Live</div>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <SearchSelect
                inlinelabel="Live ID"
                name="live_salesforce_id"
                defaultValue={values.live_salesforce_id}
                defFields={liveIds}
              />
            </div>
            <div>
              <FormikInput
                inlinelabel="Live Name"
                name="live_name"
                disabled
                defaultValue={values.live?.name}
              />
            </div>
          </div>
          <div className="text-base font-semibold mt-8 mb-2">Platform</div>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36 mb-8">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <SearchSelect
                inlinelabel="Platform ID"
                name="platform_salesforce_id"
                defaultValue={values.platform_salesforce_id}
                defFields={platformIds}
              />
            </div>
            <div>
              <FormikInput
                inlinelabel="Platform Name"
                name="platform_name"
                disabled
                defaultValue={values.platform?.name}
              />
            </div>
          </div> */}
          <div className="mb-8">
            <FormikSelect
              inlinelabel="Test Delivery"
              name="test_delivery"
              defaultValue={values.test_delivery}
              defFields={["Yes", "No"]}
            />
          </div>
          <div className="mb-8">
            <FormikDeliveryRegions
              availRegion={
                values.delivered_region.length > 0
                  ? values.delivered_region
                  : [
                      {
                        region: "",
                        countries: [],
                        countries_exception: [],
                      },
                    ]
              }
              fieldName="delivered_region"
            />
          </div>
          <div className="grid grid-cols-3 gap-y-8 items-start gap-x-36 my-8">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Live / Feed Type"
                name="feed_type"
                defaultValue={values.feed_type}
                defFields={channelDeliveryFields.feedType.sort()}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikMultiSelect
                inlinelabel="Delivery Stream Type"
                name="delivery_stream_type"
                defaultValue={values.delivery_stream_type}
                defFields={platformFields.stream_type.sort()}
              />
            </div>
            <div
              style={{ display: "grid", gridColumn: "1", padding: "5px 0px" }}
            >
              <label className="text-gray-400">SSAI</label>
              <FormikSwitch name="ssai" defaultValue={values.ssai} />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">CDN Distributions</label>
              <div className="mt-2">
                <div>
                  <label className="text-gray-400">Tag 1</label>
                  <div className="mt-2">
                    <FormikInput
                      inlinelabel=""
                      name="cdn_tag_1"
                      defaultValue={values.cdn_tag_1}
                    />
                  </div>
                </div>
                <div>
                  <label className="text-gray-400">Tag 2</label>
                  <div className="mt-2">
                    <FormikInput
                      inlinelabel=""
                      name="cdn_tag_2"
                      defaultValue={values.cdn_tag_2}
                    />
                  </div>
                </div>
                <div>
                  <label className="text-gray-400">Tag 3</label>
                  <div className="mt-2">
                    <FormikInput
                      inlinelabel=""
                      name="cdn_tag_3"
                      defaultValue={values.cdn_tag_3}
                    />
                  </div>
                </div>
                <div>
                  <label className="text-gray-400">Tag 4</label>
                  <div className="mt-2">
                    <FormikInput
                      inlinelabel=""
                      name="cdn_tag_4"
                      defaultValue={values.cdn_tag_4}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <FormikInput
                inlinelabel="TS Delivery ID"
                name="point_product_delivery_id"
                defaultValue={values.point_product_delivery_id}
              />
            </div>
            <div>
              <FormikSelect
                inlinelabel="TS Setup"
                name="ts_setup"
                defaultValue={values.ts_setup}
                defFields={channelDeliveryFields.tsSetup}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikRange
                inlinelabel="Ad Load (minutes)"
                placeholder="Range: 0 - x"
                min="0"
                // max="100"
                subText=""
                name="ad_load_minutes"
                defaultValue={values.ad_load_minutes}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Turnaround</label>
              <FormikSwitch
                name="turnaround"
                defaultValue={values.turnaround}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
            <FormikMultiSelect
                inlinelabel="Business Model"
                name="business_model"
                defaultValue={values.business_model}
                defFields={
                  values.business_model.includes("Content+")
                    ? ["Ads+", "Content+"]
                    : values.business_model.includes("Direct")
                    ? ["Ads+", "Direct"]
                    : channelDeliveryFields.businessModel
                        .filter((option) => option !== "Connect") 
                        .sort()
                }
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikRange
                inlinelabel="Live Redundancy"
                placeholder="Range: 0 - x"
                min="0"
                // max="100"
                subText=""
                name="redundancy"
                defaultValue={values.redundancy}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikMultiSelect
                inlinelabel="Audio Languages"
                name="audio_languages"
                defaultValue={values.audio_languages}
                defFields={channelFields.languages.sort()}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Ads Plus"
                name="ads_plus"
                defaultValue={values.ads_plus}
                defFields={["Yes", "No"]}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Content Plus"
                name="content_plus"
                defaultValue={values.content_plus}
                defFields={["Yes", "No"]}
              />
            </div>
          </div>
          <div className="text-base font-semibold mb-2">Delivery Timelines</div>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikInputDate
                name="ordered_date"
                defaultValue={values.ordered_date}
                inlinelabel="Ordered Date"
                minDate=""
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikInputDate
                name="provisioning_start_date"
                defaultValue={values.provisioning_start_date}
                inlinelabel="Provisioning Start Date"
                minDate={values.ordered_date || ""}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikInputDate
                name="delivered_date"
                defaultValue={values.delivered_date}
                inlinelabel="Delivered Date"
                minDate={values.provisioning_start_date || ""}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikInputDate
                name="on_hold_date"
                defaultValue={values.on_hold_date}
                inlinelabel="On Hold Date"
                minDate={values.delivered_date || ""}
              />
            </div>
            {values.on_hold_date && (
              <div>
                <FormikTextField
                  name="on_hold_reason"
                  defaultValue={values.on_hold_reason}
                  inlinelabel="On Hold Reason"
                />
              </div>
            )}

            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikInputDate
                name="go_live_date"
                defaultValue={values.go_live_date}
                inlinelabel="Go Live Date"
                minDate={values.delivered_date || ""}
              />
            </div>
            {/* {values.delivery_status === "Churned" && (
              <div style={{ display: "grid", gridColumn: "1" }}>
                <FormikInputDate
                  name="churned_date"
                  defaultValue={values.churned_date}
                  inlinelabel="Churned Date"
                  minDate={values.go_live_date || ""}
                />
              </div>
            )}
            {values.delivery_status === "Churned" && (
              <div style={{ display: "grid", gridColumn: "1" }}>
                <FormikInput
                  inlinelabel="Churned Reason"
                  name="churned_reason"
                  defaultValue={values.churned_reason}
                />
              </div>
            )} */}
            {/* <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Order ID</label>
              <div className="mt-2">{values?.order_salesforce_id || "-"}</div>
            </div> */}
          </div>
          <div className="text-base font-semibold mb-2 mt-8">Deal Details</div>
          <div>
            <div className="my-8">
              <FormikSelect
                inlinelabel="Connect Distribution Model"
                name="connect_distribution_model"
                defaultValue={values.connect_distribution_model}
                defFields={channelDeliveryFields.connectDistributionModel}
              />
            </div>
            <div>
              <FormikSelect
                inlinelabel="Deal Type"
                name="deal_type_for_content_partner"
                defaultValue={values.deal_type_for_content_partner}
                defFields={channelDeliveryFields.dealType.sort()}
              />
            </div>
            {/* Inventory Split */}
            {values.deal_type_for_content_partner === "Inventory Split" && (
              <div className="grid grid-cols-3 gap-y-8 gap-x-28 mt-8">
                <div>
                  <FormikRange
                    inlinelabel="Deal Split with Content Partner"
                    placeholder="Range: 0 - 100%"
                    min="0"
                    max="100"
                    subText="%"
                    name="deal_split_with_content_partner"
                    defaultValue={values.deal_split_with_content_partner}
                  />
                </div>
                {values.deal_split_with_content_partner ? (
                  <>
                    <div>
                      <FormikSelect
                        inlinelabel="Inventory Share Monetised by - Primary"
                        name="content_partner_primary"
                        defaultValue={values.content_partner_primary}
                        defFields={["Content Partner", "Platform", "Amagi"]}
                      />
                    </div>
                    <div>
                      <FormikSelect
                        inlinelabel="Inventory Share Monetised by - Backfill"
                        name="content_partner_backfill"
                        defaultValue={
                          values.content_partner_backfill
                            ? values.content_partner_backfill
                            : values.content_partner_primary === "Amagi"
                            ? "Content Partner"
                            : values.content_partner_primary ===
                              "Content Partner"
                            ? "Amagi"
                            : values.content_partner_backfill
                        }
                        defFields={["Content Partner", "Platform", "Amagi"]}
                      />
                    </div>
                    <div style={{ display: "grid", gridColumn: "2" }}>
                      <FormikTextField
                        inlinelabel="Inventory Share - Content Partner Primary Ad Tag"
                        name="inventory_share_content_partner_primary_ad_tag"
                        defaultValue={
                          values.inventory_share_content_partner_primary_ad_tag
                        }
                      />
                    </div>
                    <div>
                      <FormikTextField
                        inlinelabel="Inventory Share - Content Partner Backfill Ad Tag"
                        name="inventory_share_content_partner_backfill_ad_tag"
                        defaultValue={
                          values.inventory_share_content_partner_backfill_ad_tag
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                    <div></div>
                  </>
                )}
                <div>
                  <FormikRange
                    inlinelabel="Deal Split with Platform"
                    placeholder="Range: 0 - 100%"
                    min="0"
                    max="100"
                    subText="%"
                    name="deal_split_with_platform_partner"
                    defaultValue={values.deal_split_with_platform_partner}
                  />
                </div>
                {values.deal_split_with_platform_partner ? (
                  <>
                    <div>
                      <FormikSelect
                        inlinelabel="Inventory Share Monetised by - Primary"
                        name="platform_partner_primary"
                        defaultValue={values.platform_partner_primary}
                        defFields={["Platform", "Amagi"]}
                      />
                    </div>
                    <div>
                      <FormikSelect
                        inlinelabel="Inventory Share Monetised by - Backfill"
                        name="platform_partner_backfill"
                        defaultValue={
                          values.platform_partner_backfill
                            ? values.platform_partner_backfill
                            : values.platform_partner_primary === "Amagi"
                            ? "Platform"
                            : values.platform_partner_primary === "Platform"
                            ? "Amagi"
                            : values.platform_partner_backfill
                        }
                        defFields={["Platform", "Amagi"]}
                      />
                    </div>
                    <div style={{ display: "grid", gridColumn: "2" }}>
                      <FormikTextField
                        inlinelabel="Inventory Share - Platform Primary Ad Tag"
                        name="inventory_share_platform_primary_ad_tag"
                        defaultValue={
                          values.inventory_share_platform_primary_ad_tag
                        }
                      />
                    </div>
                    <div>
                      <FormikTextField
                        inlinelabel="Inventory Share - Platform Backfill Ad Tag"
                        name="inventory_share_platform_backfill_ad_tag"
                        defaultValue={
                          values.inventory_share_platform_backfill_ad_tag
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                    <div></div>
                  </>
                )}
                <div>
                  <FormikRange
                    inlinelabel="Deal Split - Amagi Share"
                    placeholder="Range: 0 - 100%"
                    min="0"
                    max="100"
                    subText="%"
                    name="deal_split_amagi_share"
                    defaultValue={values.deal_split_amagi_share}
                  />
                </div>
                {values.deal_split_amagi_share ? (
                  <>
                    <div>
                      <FormikSelect
                        inlinelabel="Inventory Share Monetised by - Primary"
                        name="amagi_share_primary"
                        defaultValue={values.amagi_share_primary}
                        defFields={["Content Partner", "Platform", "Amagi"]}
                      />
                    </div>
                    <div>
                      <FormikSelect
                        inlinelabel="Inventory Share Monetised by - Backfill"
                        name="amagi_share_backfill"
                        defaultValue={values.amagi_share_backfill}
                        defFields={["Content Partner", "Platform", "Amagi"]}
                      />
                    </div>
                    <div style={{ display: "grid", gridColumn: "2" }}>
                      <FormikTextField
                        inlinelabel="Inventory Share - Amagi Primary Ad Tag"
                        name="inventory_share_amagi_primary_ad_tag"
                        defaultValue={
                          values.inventory_share_amagi_primary_ad_tag
                        }
                      />
                    </div>
                    <div>
                      <FormikTextField
                        inlinelabel="Inventory Share - Amagi Backfill Ad Tag"
                        name="inventory_share_amagi_backfill_ad_tag"
                        defaultValue={
                          values.inventory_share_amagi_backfill_ad_tag
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div></div>
                    <div></div>
                  </>
                )}
              </div>
            )}
            {/* Revenue Share */}
            {values.deal_type_for_content_partner === "Revenue Share" && (
              <div className="grid grid-cols-3 gap-y-8 gap-x-28 mt-8">
                <div style={{ display: "grid", gridColumn: "2" }}>
                  <FormikSelect
                    inlinelabel="Revenue Share Monetised by - Primary"
                    name="revenue_share_monetised_by_primary"
                    defaultValue={values.revenue_share_monetised_by_primary}
                    defFields={["Content Partner", "Platform", "Amagi"]}
                  />
                </div>
                <div>
                  <FormikSelect
                    inlinelabel="Revenue Share Monetised by - Backfill"
                    name="revenue_share_monetised_by_backfill"
                    defaultValue={values.revenue_share_monetised_by_backfill}
                    defFields={["Content Partner", "Platform", "Amagi"]}
                  />
                </div>
                <div style={{ display: "grid", gridColumn: "2" }}>
                  <FormikTextField
                    inlinelabel="Revenue Share - Primary Ad Tag"
                    name="revenue_share_primary_ad_tag"
                    defaultValue={values.revenue_share_primary_ad_tag}
                  />
                </div>
                <div>
                  <FormikTextField
                    inlinelabel="Revenue Share - Backfill Ad Tag"
                    name="revenue_share_backfill_ad_tag"
                    defaultValue={values.revenue_share_backfill_ad_tag}
                  />
                </div>
              </div>
            )}
            <div className="my-8">
              <FormikSelect
                inlinelabel="CDN Fee Owner"
                name="cdn_fee_owner"
                defaultValue={values.cdn_fee_owner}
                defFields={channelDeliveryFields.cdnTagging}
              />
            </div>
            <div className="my-8">
              <FormikSelect
                inlinelabel="SSAI Fee Owner"
                name="ssai_fee_owner"
                defaultValue={values.ssai_fee_owner}
                defFields={channelDeliveryFields.ssai_fee_owner}
              />
            </div>
            <div className="grid grid-cols-3 gap-y-8 gap-x-28">
              <div>
                <FormikRange
                  inlinelabel="Content Partner Ad Sales Commission %"
                  placeholder="Range: 0 - 100%"
                  min="0"
                  max="100"
                  subText="%"
                  name="content_partner_ad_sales_commission_percentage"
                  defaultValue={
                    values.content_partner_ad_sales_commission_percentage
                  }
                />
              </div>
              <div style={{ display: "grid", gridColumn: "1" }}>
                <FormikRange
                  inlinelabel="Platform Ad Sales Commission %"
                  placeholder="Range: 0 - 100%"
                  min="0"
                  max="100"
                  subText="%"
                  name="platform_ad_sales_commission_percentage"
                  defaultValue={values.platform_ad_sales_commission_percentage}
                />
              </div>
            </div>
          </div>
          <div className="my-8">
            <FormikDeliveryProducts
              fieldName="product_purchased_for_this_delivery"
              products={values.product_purchased_for_this_delivery}
              productsLists={productLists}
            />
          </div>
        </>
      </ToggleBox>
    </div>
  );
};

export default LiveDeliveryFieldsEditForm;
