import { useMemo } from "react";
import * as d3 from "d3";

type DataItem = {
  name: string;
  value: number;
};
type DonutChartProps = {
  width: number;
  height: number;
  chartdata: DataItem[];
  colors: string[];
  TitleText: string;
  TotalText: string;
};

const MARGIN_X = 250;
const MARGIN_Y = 35;
const INFLEXION_PADDING = 30; // space between donut and label inflexion point

export const DonutChart = ({ width, height, chartdata, colors, TitleText, TotalText }: DonutChartProps) => {
  const radius = Math.min(width - 2 * MARGIN_X, height - 2 * MARGIN_Y) / 2;
  const innerRadius = radius / 2;

  const pie = useMemo(() => {
    const pieGenerator = d3
    .pie<any, DataItem>()
    .sort(null)
    .value((d) => d.value);
    return pieGenerator(chartdata);
  }, [chartdata]);

  console.log(pie,"is the pie data")

  const arcGenerator = d3.arc();

  const shapes = pie.map((grp, i) => {
    // First arc is for the donut
    const sliceInfo = {
      innerRadius,
      outerRadius: radius,
      startAngle: grp.startAngle,
      endAngle: grp.endAngle,
    };
    const centroid = arcGenerator.centroid(sliceInfo);
    const slicePath = arcGenerator(sliceInfo) || undefined;
    

    // Second arc is for the legend inflexion point
    const inflexionInfo = {
      innerRadius: radius + INFLEXION_PADDING,
      outerRadius: radius + INFLEXION_PADDING,
      startAngle: grp.startAngle,
      endAngle: grp.endAngle,
    };
    const inflexionPoint = arcGenerator.centroid(inflexionInfo);

    const isRightLabel = inflexionPoint[0] > 0;
    const labelPosX = inflexionPoint[0] + 60 * (isRightLabel ? 1 : -1);
    const textAnchor = isRightLabel ? "start" : "end"; // eslint-disable-next-line
    const label = grp.data.name +" "+ `(${grp.value.toFixed(2)}M USD)`;

    return (
      <g key={i}>
        <path d={slicePath} fill={colors[i]} />
        <circle cx={centroid[0]} cy={centroid[1]} r={2} />
        <line
          x1={centroid[0]}
          y1={centroid[1]}
          x2={inflexionPoint[0]}
          y2={inflexionPoint[1]}
          stroke={"black"}
          fill={"black"}
        />
        <line
          x1={inflexionPoint[0]}
          y1={inflexionPoint[1]}
          x2={labelPosX}
          y2={inflexionPoint[1]}
          stroke={"black"}
          fill={"black"}
        />
        <text
          x={labelPosX + (isRightLabel ? 2 : -2)}
          y={inflexionPoint[1]}
          textAnchor={textAnchor}
          dominantBaseline="middle"
          fontSize={13}
        >
          {label}
        </text>
      </g>
    );
  });

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <svg width="100%" height="100%" viewBox={`0 0 ${width} ${height}`} style={{ display: "inline-block" }}>
        <g transform={`translate(${width / 2}, ${height / 2})`}>
          {shapes}
          <text
            x={0}
            y={0}
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize={20}
            fill="black"
          >
            <tspan className="font-bold xl">{TitleText}</tspan>
            <tspan x="0" dy="1.2em">{TotalText}</tspan>
          </text>
        </g>
      </svg>
    </div>
  );
};
