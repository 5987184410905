import React, { useState } from "react";

const ViewCustomFields: React.FC<{
  values: any
}> = ({ values }) => {
  const attributeLists = Object.keys(values).map((key) => {
    return key
  })
  const [selectedField, setSelectedField] = useState<string>(attributeLists[0]);

  return (
    <div style={{ padding: "5px 0px" }}>
      <div>
        <label className="text-gray-400">Attribute Group</label>
        <div className="flex mt-2">
          {attributeLists.length > 0 ? (attributeLists.map((data: string, index: number) => {
            return (
              <div
                key={index}
                style={{ backgroundColor: `${selectedField === data ? "#BDC2CB" : "#fff"}`, border: "1px solid #BDC2CB" }}
                className="px-3 flex cursor-pointer rounded-sm text-black font-semibold mr-2"
              >
                <div onClick={() => setSelectedField(data)}>{data}</div>
              </div>
            )
          })) : "-"}
        </div>
      </div>
      {attributeLists.length > 0 ? (
        <div>
          <table className="mt-4" style={{ width: '55%', fontSize: '16px' }}>
            <thead>
              <tr className="bg-gray-300">
                <th className="py-2 pl-2">S No.</th>
                <th className="text-left py-2 pl-2">Key Parameter</th>
                <th className="text-left py-2 pl-2">Value</th>
              </tr>
            </thead>
            <tbody className="bg-gray-50">
              {values[selectedField] !== undefined && (
                Object.entries(values[selectedField]).map(([key, value]: any, index: number) => (
                  <tr key={index}>
                    <td style={{ fontSize: "16px" }} className="py-2 pl-2">{index + 1}</td>
                    <td style={{ fontSize: "16px" }} className="text-left py-2 pl-2">{key}</td>
                    <td style={{ fontSize: "16px" }} className="text-left py-2 pl-2">{value}</td>           
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  )
}

export default ViewCustomFields