import { useState } from "react";
import { ChanelFieldsFragment } from "../../../hasura.graphql";
import ViewDescription from "./view-description";
import ViewMultiPicklist from "./view-multi-picklist";
import ViewChannelRating from "./view-channel-rating";
import ViewChannelTrailer from "./view-trailer";
import ViewCheckTable from "./view-check-table";
import ViewCustomFields from "./view-custom-fields";
import ViewAvailabilityRegions from "./view-availability-regions";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillTwitterSquare,
} from "react-icons/ai";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast, Toaster } from "react-hot-toast";
import ViewKeyring from "./view-keyring";
import DelayedToolTip from "../../delayed-tooltip";
import ToggleBox from "../../toggle-box";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useGetRedundantChannelNamesQuery } from "../../../hasura.graphql";

const ChannelBusinessFields: React.FC<{
  channelData: ChanelFieldsFragment;
}> = ({ channelData }) => {
  const [hideColumns, setHideColumns] = useState<Array<string>>([]);

  const handleHideColumns = (tab: string) => {
    setHideColumns((hideColumns) => [...hideColumns, tab]);
  };
  const handleUnhideColumns = (tab: string) => {
    setHideColumns(hideColumns.filter((f: string) => f !== tab));
  };
  const { data: redundantChannelData} = useGetRedundantChannelNamesQuery({
    variables: {
      args: {
        input_channel_id: channelData.id
      },
    },
  });
  const redundantChannels = redundantChannelData?.get_redundant_channel_names[0]?.redundant_playout_channels || [];

  return (
    <div>
      <Toaster />
      <div style={{ color: "#4E4E4E", fontSize: "14px", fontWeight: "500" }}>
        <ToggleBox
          columns={hideColumns}
          handleHideColumns={handleHideColumns}
          handleUnhideColumns={handleUnhideColumns}
          header="Basic Information"
        >
          <>
            <div className="grid grid-cols-3 gap-4">
              <div>
                <div className="text-base font-semibold mb-3">
                  Channel Details
                </div>
                <div className="flex">
                  <div className="w-16 h-12">
                    <LazyLoadImage
                      className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                      src={
                        channelData?.logo
                          ? `/${channelData?.logo}`
                          : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${channelData?.name}`
                      }
                      alt={channelData?.name}
                    />
                  </div>
                  <div className="ml-3 flex flex-col">
                    {channelData?.name}
                    <div className="flex">
                      <span className="mr-2 text-sm text-gray-500">
                        {channelData?.id}
                      </span>
                      <DelayedToolTip title="copy">
                        <CopyToClipboard text={channelData?.id} data-tip="copy">
                          <img
                            src="/assets/file-copy.svg"
                            onClick={() => toast.success("Copied")}
                            alt="file-copy"
                          />
                        </CopyToClipboard>
                      </DelayedToolTip>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <div className="text-base font-semibold mb-3">
                  Channel Owner
                </div>
                {channelData?.customer?.amagi_id ? (
                  <div className="flex">
                    {channelData?.customer?.amagi_id && (
                      <div className="w-16 h-12">
                        <LazyLoadImage
                          className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                          src={
                            channelData.customer.logo
                              ?  channelData.customer.logo.startsWith("uploads/")
                                ? `/${channelData.customer.logo}`
                                : `/logos/${channelData.customer.logo}`
                              : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${channelData?.customer.account_name}`
                          }
                          alt={channelData.customer.account_name}
                        />
                      </div>
                    )}
                    <div className="ml-3 flex flex-col">
                      <a
                        href={`/customers/${channelData?.customer?.amagi_id}/details`}
                        target="_blank"
                        rel="noreferrer"
                        className="hover:text-blue-500 hover:underline"
                      >
                        {channelData?.customer?.account_name &&
                        channelData?.customer.account_name.length > 20
                          ? `${channelData?.customer.account_name.slice(
                              0,
                              20
                            )}...`
                          : channelData?.customer?.account_name}
                      </a>
                      <span className="text-sm text-gray-500">
                        {channelData?.customer?.amagi_id}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div>-</div>
                )}
              </div>
            </div>
            <div className="my-8">
                <div className="text-base font-semibold mb-3">Channel Brand</div>
                <span className="text-sm text-gray-500">
                    {channelData?.channel_brand ? channelData.channel_brand : "--"}
                </span>
            </div>
            <div className="my-8">
              <ViewKeyring
                keyring={channelData.keyring}
                defaultId={channelData.salesforce_id}
              />
            </div>

            <ViewDescription description={channelData?.description} />
            <div className="grid grid-cols-3 gap-y-8 gap-x-36 mt-8">
              <div>
                <ViewMultiPicklist
                  inlineLabel="Channel Genre"
                  picklist={channelData?.genre}
                />
              </div>
              <div style={{ display: "grid", gridColumn: "1" }}>
                <ViewMultiPicklist
                  inlineLabel="Sub Genre"
                  picklist={channelData?.sub_genre}
                />
              </div>
              <div style={{ display: "grid", gridColumn: "1" }}>
                <label className="text-gray-400">Channel Type</label>
                <div className="mt-2">{channelData?.channel_type || "-"}</div>
              </div>
              {channelData.channel_type === "Pop-up" && (
                <div>
                  <div className="text-gray-400">Active Timeline</div>
                  <div className="flex">
                    <div className="mr-8 flex">
                      <div style={{ padding: "5px 0px" }}>
                        <label className="text-gray-400 text-sm"></label>
                        <div>
                          {channelData?.channel_start_time !== null
                            ? `${channelData?.channel_start_time.slice(
                                0,
                                10
                              )} ${channelData?.channel_start_time.slice(
                                11,
                                16
                              )}`
                            : "-"}
                        </div>
                      </div>
                      <span
                        style={{ fontSize: 10 }}
                        className="py-2 text-gray-400 mx-4"
                      >
                        TO
                      </span>
                      <div style={{ padding: "5px 0px" }}>
                        <label className="text-gray-400 text-sm"></label>
                        <div>
                          {channelData?.channel_end_time !== null
                            ? `${channelData?.channel_end_time.slice(
                                0,
                                10
                              )} ${channelData?.channel_end_time.slice(11, 16)}`
                            : "-"}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div style={{ display: "grid", gridColumn: "1" }}>
                <label className="text-gray-400">Feed Type</label>
                <div className="mt-2">{channelData?.feed_type || "-"}</div>
              </div>
              <div style={{ display: "grid", gridColumn: "1" }}>
                <label className="text-gray-400">Fast</label>
                <div className="mt-2">{channelData?.fast || "-"}</div>
              </div>
              <div style={{ display: "grid", gridColumn: "1" }}>
                <ViewMultiPicklist
                  inlineLabel="Platforms delivered to"
                  picklist={[]}
                />
              </div>
              <div style={{ display: "grid", gridColumn: "1" }}>
              <ViewMultiPicklist
                  inlineLabel="Redundant Playout Channels"
                  isRedundant={true}
                  picklist={
                    redundantChannels
                      ? redundantChannels
                      : []
                  }
                />
              </div>
            </div>
          </>
        </ToggleBox>
        <ToggleBox
          header="Channel Demographics"
          columns={hideColumns}
          handleHideColumns={handleHideColumns}
          handleUnhideColumns={handleUnhideColumns}
        >
          <>
            <div className="grid grid-cols-3 gap-y-8 gap-x-36">
              <div style={{ display: "grid", gridColumn: "1" }}>
                <label className="text-gray-400">
                  Channel Origin Country / Region
                </label>
                <div className="mt-2">
                  {channelData?.channel_origin_country_region || "-"}
                </div>
              </div>
            </div>
            <div className="my-8">
              <ViewChannelRating chnRating={channelData?.channel_rating} />
            </div>
            <div className="grid grid-cols-3 gap-y-8 gap-x-36">
              <div style={{ display: "grid", gridColumn: "1" }}>
                <ViewMultiPicklist
                  inlineLabel="Target Audience"
                  picklist={channelData?.target_audience}
                />
              </div>
              {/* <div style={{ display: "grid", gridColumn: "1" }}>
                <ViewMultiPicklist
                  inlineLabel="Regions"
                  picklist={channelData?.availability_regions}
                />
              </div>
              <div style={{ display: "grid", gridColumn: "1" }}>
                <ViewMultiPicklist
                  inlineLabel="Available to countries"
                  picklist={channelData?.availability_countries}
                />
              </div> */}
            </div>
            <div className="mt-8">
              <ViewAvailabilityRegions
                availRegions={channelData.availablity_country_and_regions}
              />
            </div>
          </>
        </ToggleBox>
        <ToggleBox
          header="Channel Artwork and Social Media"
          columns={hideColumns}
          handleHideColumns={handleHideColumns}
          handleUnhideColumns={handleUnhideColumns}
        >
          <>
            <div className="mb-8">
              <span className="text-gray-400">Channel Logo</span>
              {channelData?.logo ? (
                <div className="flex mt-4">
                  <div className="w-44 h-24">
                    <LazyLoadImage
                      className="h-full w-full rounded-lg object-contain bg-lightPrimary"
                      src={
                        channelData?.logo
                          ? `/${channelData?.logo}`
                          : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${channelData?.name}`
                      }
                      alt={channelData?.name}
                    />
                  </div>
                </div>
              ) : (
                <div>-</div>
              )}
            </div>
            <div className="mb-8">
              <span className="text-gray-400">Poster</span>
              {channelData?.posters.length > 0 ? (
                channelData?.posters.map((poster: string, index: number) => {
                  return (
                    <div className="flex mt-4">
                      {/* <div className="w-44 h-24 flex justify-center">
                        <img
                          className="w-full rounded-md"
                          src={`/${poster}`}
                          alt={poster}
                        />
                      </div> */}
                      <div className="w-44 h-24">
                        <LazyLoadImage
                          className="h-full w-full rounded-lg object-contain bg-lightPrimary"
                          src={
                            poster
                              ? `/${poster}`
                              : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=poster-${index}`
                          }
                          alt={`poster-${index}`}
                        />
                      </div>
                      <div className="ml-4">
                        <div>{`Channel Poster ${index + 1}`}</div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>-</div>
              )}
            </div>
            <div>
              <span className="text-gray-400">Trailer</span>
              <ViewChannelTrailer trailers={channelData?.videos} />
            </div>
            <div className="my-8">
              <span className="text-gray-400 mb-2">Presentations</span>
              {channelData?.presentations.length > 0 ? (
                channelData?.presentations.map(
                  (pres: string, index: number) => {
                    return (
                      <div className="flex mt-4">
                        <div className="w-44 h-24 border flex justify-center rounded">
                          <img
                            src={
                              pres.slice(pres.length - 3) === "pdf"
                                ? "/assets/pdf-icon.svg"
                                : "/assets/ppt-icon.svg"
                            }
                            alt="file-icon"
                          />
                        </div>
                        <div className="ml-4">
                          <a
                            href={`/${pres}`}
                            download
                            className="text-blue-500 underline"
                          >{`...${pres.slice(pres.length - 15)}`}</a>
                        </div>
                        {/* {openPresentation && (
                          <DialogModal
                            onClose={setOpenPresentation}
                            modalShow={openPresentation}
                          >
                            <div>
                              <DocViewer
                                documents={channelData.presentations
                                  .filter((present: string) => present === pres)
                                  .map((prest: string) => {
                                    return {
                                      uri: `/${prest}`,
                                    };
                                  })}
                                pluginRenderers={DocViewerRenderers}
                              />
                            </div>
                          </DialogModal>
                        )} */}
                      </div>
                    );
                  }
                )
              ) : (
                <div>-</div>
              )}
            </div>
            <div className="text-base font-semibold my-5">Social Profiles</div>
            <div className="grid grid-cols-3 gap-y-8 gap-x-36">
              <div>
                <label className="text-gray-400">Instagram</label>
                <div className="flex items-center mt-1">
                  <span className="mr-2 text-xl">
                    <AiFillInstagram />
                  </span>
                  {channelData.instagram ? (
                    <a
                      href={channelData.instagram || "#"}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500"
                    >
                      <span>{channelData.instagram || "-"}</span>
                    </a>
                  ) : (
                    <span>-</span>
                  )}
                </div>
              </div>
              <div>
                <label className="text-gray-400">Facebook</label>
                <div className="flex items-center mt-1">
                  <span className="mr-2 text-xl">
                    <AiFillFacebook />
                  </span>
                  {channelData.facebook ? (
                    <a
                      href={channelData.facebook}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500"
                    >
                      <span>{channelData.facebook}</span>
                    </a>
                  ) : (
                    <span>-</span>
                  )}
                </div>
              </div>
              <div style={{ display: "grid", gridColumn: "1" }}>
                <label className="text-gray-400">Linkedin</label>
                <div className="flex items-center mt-1">
                  <span className="mr-2 text-xl">
                    <AiFillLinkedin />
                  </span>
                  {channelData.linkedin ? (
                    <a
                      href={channelData.linkedin}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500"
                    >
                      <span>{channelData.linkedin}</span>
                    </a>
                  ) : (
                    <span>-</span>
                  )}
                </div>
              </div>
              <div>
                <label className="text-gray-400">Twitter</label>
                <div className="flex items-center mt-1">
                  <span className="mr-2 text-xl">
                    <AiFillTwitterSquare />
                  </span>
                  {channelData.twitter ? (
                    <a
                      href={channelData.twitter}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500"
                    >
                      <span>{channelData.twitter}</span>
                    </a>
                  ) : (
                    <span>-</span>
                  )}
                </div>
              </div>
            </div>
          </>
        </ToggleBox>
        <ToggleBox
          header="Channel Meta"
          columns={hideColumns}
          handleHideColumns={handleHideColumns}
          handleUnhideColumns={handleUnhideColumns}
        >
          <>
            <div className="grid grid-cols-3 gap-y-8 gap-x-36 mb-3">
              <div style={{ display: "grid", gridColumn: "1" }}>
                <label className="text-gray-400">Original Audio Language</label>
                <div className="mt-2">
                  {channelData.original_audio_language || "-"}
                </div>
              </div>
              <div>
                <ViewMultiPicklist
                  inlineLabel="Dubbing Languages"
                  picklist={channelData.dubbing_language}
                />
              </div>
              <div style={{ display: "grid", gridColumn: "1" }}>
                <label className="text-gray-400">Subtitle Support</label>
                <div className="mt-2">
                  {channelData?.subtitle_support !== null
                    ? channelData?.subtitle_support
                      ? "Yes"
                      : "No"
                    : "-"}
                </div>
              </div>
              {channelData?.subtitle_support && (
                <>
                  <div>
                    <ViewMultiPicklist
                      inlineLabel="Subtitle Language"
                      picklist={channelData?.subtitle_language || []}
                    />
                  </div>
                  <div>
                    <label className="text-gray-400">Subtitle Type</label>
                    <div className="mt-2">
                      {channelData?.subtitle_type || "-"}
                    </div>
                  </div>
                </>
              )}
              <div style={{ display: "grid", gridColumn: "1" }}>
                <label className="text-gray-400">SSAI Partner</label>
                <div className="mt-2">{channelData?.ssai_partner || "-"}</div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-y-8 gap-x-36 mb-8">
              <ViewCheckTable
                paid_flag={channelData?.paid_flag}
                ad_flag={channelData?.ad_flag}
                promotion_flag={channelData?.promotion_flag}
                is_adsplus_channel={channelData?.is_adsplus_channel}
                is_contentplus_channel={channelData?.is_contentplus_channel}
              />
            </div>
            <div className="grid grid-cols-3 gap-y-8 gap-x-36">
              <div>
                <label className="text-gray-400">Channel Redundant</label>
                <div className="mt-2">
                  {channelData?.is_redundant !== null
                    ? channelData?.is_redundant
                      ? "Yes"
                      : "No"
                    : "-"}
                </div>
              </div>
            </div>
          </>
        </ToggleBox>
        <ToggleBox
          columns={hideColumns}
          handleHideColumns={handleHideColumns}
          handleUnhideColumns={handleUnhideColumns}
          header="Associations"
        >
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div>
              <label className="text-gray-400">Is Channel Group</label>
              <div className="mt-2">{channelData.channels_grouped || "-"}</div>
            </div>
            {channelData.channels_grouped === "Yes" ? (
              <div style={{ display: "grid", gridColumn: "1" }}>
                <label className="text-gray-400">Channels Grouped</label>
                <div className="mt-2">
                  {channelData.channel_to_channel &&
                  channelData.channel_to_channel.length > 0
                    ? channelData.channel_to_channel.map((channel) => {
                        return (
                          <div className="flex mb-4">
                            <div className="w-16 h-12">
                              <LazyLoadImage
                                className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                                src={
                                  channel.logo
                                    ? `/${channel.logo}`
                                    : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${channel.name}`
                                }
                                alt={channel.logo!}
                              />
                            </div>
                            <div className="ml-3 flex flex-col">
                              <div className="flex">
                                <a
                                  href={`/channels/${
                                    channel.channel_type
                                      ? channel.channel_type.toLowerCase()
                                      : "linear"
                                  }/${channel.id}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="hover:text-blue-500 hover:underline"
                                >
                                  {channel.name.length > 30
                                    ? `${channel.name.slice(0, 30)}...`
                                    : channel.name}
                                </a>
                              </div>
                              <span className="text-sm text-gray-500">
                                {channel.salesforce_id}
                              </span>
                            </div>
                          </div>
                        );
                      })
                    : "-"}
                </div>
              </div>
            ) : (
              <div>
                <label className="text-gray-400">Channel Grouped Under</label>
                {channelData.channel_to_channel_of_grouped ? (
                  <div className="flex mt-2">
                    <div className="w-16 h-12">
                      <LazyLoadImage
                        className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                        src={
                          channelData.channel_to_channel_of_grouped?.logo
                            ? `/${channelData.channel_to_channel_of_grouped?.logo}`
                            : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${channelData.channel_to_channel_of_grouped?.name}`
                        }
                        alt={channelData.channel_to_channel_of_grouped?.logo!}
                      />
                    </div>
                    <div className="ml-3 flex flex-col">
                      <div className="flex">
                        <a
                          href={`/channels/grouped/${channelData.channel_to_channel_of_grouped?.id}`}
                          target="_blank"
                          rel="noreferrer"
                          className="hover:text-blue-500 hover:underline"
                        >
                          {channelData.channel_to_channel_of_grouped?.name &&
                          channelData.channel_to_channel_of_grouped?.name
                            .length > 30
                            ? `${channelData.channel_to_channel_of_grouped?.name.slice(
                                0,
                                30
                              )}...`
                            : channelData.channel_to_channel_of_grouped?.name}
                        </a>
                      </div>
                      <span className="text-sm text-gray-500">
                        {
                          channelData.channel_to_channel_of_grouped
                            ?.salesforce_id
                        }
                      </span>
                    </div>
                  </div>
                ) : (
                  <div>-</div>
                )}
              </div>
            )}
          </div>
        </ToggleBox>
        <ToggleBox
          header="Custom Inputs"
          columns={hideColumns}
          handleHideColumns={handleHideColumns}
          handleUnhideColumns={handleUnhideColumns}
        >
          <>
            <div className="grid grid-cols-3 gap-y-8 gap-x-36 mb-8">
              <div>
                <ViewMultiPicklist
                  inlineLabel="Custom Tags"
                  picklist={channelData?.custom_tags}
                />
              </div>
            </div>
            <div>
              <ViewCustomFields values={channelData?.custom_fields} />
            </div>
          </>
        </ToggleBox>
      </div>
    </div>
  );
};

export default ChannelBusinessFields;
