/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useState } from "react";
import { channelFields } from "../../constants";
import { useField } from "formik";
import { XIcon } from "@heroicons/react/outline";

interface DescriptionType {
  language: string;
  short: string;
  long: string;
}

type DescriptionTextType = {
  short: string;
  long: string;
};

const Description: React.FC<{
  description: Array<DescriptionType>;
  name: string;
}> = ({ description, name }) => {
  const _field = useField(name);
  const { setValue } = _field[2];
  const [attributeLists, setAttributeLists] = useState<Array<string>>(
    description.map((val: DescriptionType) => {
      return val.language;
    })
  );
  const [selectedField, setSelectedField] = useState<string>(attributeLists[0]);
  const [input, setInput] = useState<DescriptionTextType>(
    description.find((f: DescriptionType) => f.language === selectedField) || {
      short: "",
      long: "",
    }
  );
  const [inputTable, setInputTable] =
    useState<Array<DescriptionType>>(description);
  let mappedField = channelFields.languages.sort().filter((f: string) => {
    return !attributeLists.includes(f);
  });

  const handleSubmit = () => {
    if (
      inputTable.find((inp) => inp.language === selectedField) !== undefined
    ) {
      let description: Array<any> = inputTable;
      let index = description.findIndex(
        (inp) => inp.language === selectedField
      );
      description[index]["short"] = input.short;
      description[index]["long"] = input.long;
    } else {
      setInputTable((inputTable) => [
        ...inputTable,
        { language: selectedField, short: input.short, long: input.long },
      ]);
    }
    setInput({ short: "", long: "" });
    setSelectedField("");
  };

  const handleInputShort = (e: ChangeEvent<HTMLTextAreaElement>) => {
    let updatedInputShort = {};
    updatedInputShort = { short: e.target.value };
    setInput((input) => ({
      ...input,
      ...updatedInputShort,
    }));
  };

  const handleInputLong = (e: ChangeEvent<HTMLTextAreaElement>) => {
    let updatedInputLong = {};
    updatedInputLong = { long: e.target.value };
    setInput((input) => ({
      ...input,
      ...updatedInputLong,
    }));
  };

  console.log(input, inputTable, selectedField);

  useEffect(() => {
    setValue(inputTable);
  }, [inputTable.length, input.long, input.short]);

  return (
    <div className="bg-gray-100">
      <div className="grid grid-cols-3 gap-y-8 gap-x-36 pt-4 ml-5">
        <div>
          <div style={{ padding: "5px 0px" }}>
            <label className="text-gray-400">Description Languages</label>
            <select
              value="Please select"
              name={name}
              onChange={(e) => {
                setAttributeLists((oldArr) => [...oldArr, e.target.value]);
                setSelectedField(e.target.value);
                setInput(
                  inputTable.find((f) => f.language === e.target.value) || {
                    short: "",
                    long: "",
                  }
                );
              }}
              style={{ padding: "5.5px 0px" }}
              className="border-0 border-b focus:ring-0 border-gray-300 outline-none bg-transparent w-full"
            >
              <option disabled>Please select</option>
              {mappedField.map((lang: string, index: number) => {
                return (
                  <option key={index} value={lang}>
                    {lang}
                  </option>
                );
              })}
            </select>
            <div className="flex mt-2">
              {attributeLists.map((data: string, index: number) => {
                return (
                  <div
                    key={index}
                    style={{
                      backgroundColor: `${
                        selectedField === data ? "#BDC2CB" : "#fff"
                      }`,
                      border: "1px solid #BDC2CB",
                    }}
                    className="px-3 flex cursor-pointer rounded-sm text-black font-semibold mr-2"
                  >
                    <div
                      onClick={() => {
                        setSelectedField(data);
                        setInput(
                          inputTable.find((f) => f.language === data) || {
                            short: "",
                            long: "",
                          }
                        );
                      }}
                    >
                      {data}
                    </div>
                    <XIcon
                      onClick={() => {
                        setAttributeLists(
                          attributeLists.filter((f) => f !== data)
                        );
                        setInputTable(
                          inputTable.filter((f) => f.language !== data)
                        );
                        setInput({ long: "", short: "" });
                        setSelectedField("");
                      }}
                      className="h-4 w-4 text-gray-400"
                      style={{ marginTop: "2.2px", marginLeft: "12px" }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="p-5">
          <div className="grid grid-cols-2 gap-4 w-full">
            <div style={{ padding: "5px 0px" }} className="mb-2">
              <label className="text-gray-400">Short Description</label>
              <textarea
                name={name}
                value={input.short}
                onChange={handleInputShort}
                maxLength={1024}
                disabled={selectedField ? false : true}
                className="py-2 px-3 rounded focus:ring-0 border-gray-300 outline-none bg-transparent w-full h-32"
                placeholder={
                  selectedField
                    ? `${selectedField} short`
                    : "Select Language and enter"
                }
              />
            </div>
            <div style={{ padding: "5px 0px" }} className="mb-2">
              <label className="text-gray-400">Long Description</label>
              <textarea
                name={name}
                value={input.long}
                onChange={handleInputLong}
                maxLength={2048}
                disabled={selectedField ? false : true}
                className="py-2 px-3 rounded focus:ring-0 border-gray-300 outline-none bg-transparent w-full h-32"
                placeholder={
                  selectedField
                    ? `${selectedField} long`
                    : "Select Language and enter"
                }
              />
            </div>
          </div>
          <button
            type="button"
            className="py-1.5 px-3 bg-primaryColor text-white rounded-sm"
            onClick={handleSubmit}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default Description;
