// import { DateTime } from "luxon";
import { TransactionType } from ".";
import { useCDM } from "../../cdm-context";
import Loader from "../../common/loader";
import { useCustomerFinancialCumulativeByPkQuery } from "../../hasura.graphql";
import CountCard from "../cards/count-card";
import { currencyConverter } from "../utils";
import InvoicesTable from "./invoices-table";

const Invoices: React.FC<{ transaction: TransactionType }> = ({
  transaction,
}) => {
  const { show } = useCDM();
  const { data, loading, error } = useCustomerFinancialCumulativeByPkQuery({
    variables: {
      amagi_id: show?.amg_id!,
      // from_date: DateTime.local().startOf("month").toISO().slice(0, 10),
    },
  });

  if (loading) {
    return (
      <div>
        <div className="rounded font-medium bg-white mt-4 px-4 py-2">
          <Loader />
        </div>
        <InvoicesTable height={375} transaction={transaction} />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <div className="italic rounded bg-white mt-4 px-4 py-2">
          Could not fetch financial data
        </div>
        <InvoicesTable height={375} transaction={transaction} />
      </div>
    );
  }
  if (!data || !data.customer) {
    return <i>Could not retrieve customer financials data</i>;
  }

  const customerFinancial = data.customer;

  return (
    <div>
      <div className="my-4 grid grid-cols-3 gap-4">
        <CountCard
          count={`${currencyConverter(
            customerFinancial?.booked_revenue &&
              customerFinancial?.booked_revenue!.length > 0 &&
              customerFinancial?.booked_revenue![
                customerFinancial?.booked_revenue!.length - 1
              ].amount,
            show?.currency || "USD",
            "0.00a"
          )}`}
          cardText="Total Booked"
          subText="Since Beginning"
          icon="/assets/total-booked.svg"
        />
        <CountCard
          count={`${currencyConverter(
            customerFinancial?.billed_revenue &&
              customerFinancial?.billed_revenue!.length > 0 &&
              customerFinancial?.billed_revenue![
                customerFinancial?.billed_revenue!.length - 1
              ].amount,
            show?.currency || "USD",
            "0.00a"
          )}`}
          cardText="Total Billed"
          subText="Since Beginning"
          icon="/assets/total-billed.svg"
        />
        <CountCard
          count={`${currencyConverter(
            customerFinancial?.actual_revenue &&
              customerFinancial?.actual_revenue!.length > 0 &&
              customerFinancial?.actual_revenue![
                customerFinancial?.actual_revenue!.length - 1
              ].amount,
            show?.currency || "USD",
            "0.00a"
          )}`}
          cardText="Total Paid"
          subText="Since Beginning"
          icon="/assets/total-paid.svg"
        />
      </div>
      <InvoicesTable height={418} transaction={transaction} />
    </div>
  );
};

export default Invoices;
