import React, { ChangeEvent, useState, useMemo } from "react";
import { useDebounce } from "use-debounce";
import PopoverMenu from "../../components/popover-menu";
import TableFilter from "../table-filter";
import { capitalize } from "../../components/utils";

const TableRowsFilter: React.FC<any> = ({
  column: {
    setFilter,
    preFilteredRows,
    id,
    Header,
    positionStyles,
    styles,
    position,
  },
}) => {
  const [columnSearch, setColumnSearch] = React.useState<string>("");
  const [debouncedColumnSearch] = useDebounce(columnSearch, 100);
  const options = useMemo(() => {
    let counts: any = {};
    preFilteredRows.forEach((x: any) => {
      x = x.values[id];
      counts[x] = (counts[x] || 0) + 1;
    });
    return counts;
  }, [id, preFilteredRows]);

  const debouncedColumns = Object.entries(options).filter(([opt, count]) => {
    if (!debouncedColumnSearch) {
      return true;
    }
    return opt.toLowerCase().includes(debouncedColumnSearch.toLowerCase());
  });

  const [checked, setChecked] = useState<Array<string>>([]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const t = e.target.name;
    if (checked.length === 1 && checked.includes(t)) {
      setFilter(Object.keys(options));
      setChecked([]);
    } else if (checked && checked.includes(t)) {
      setFilter(checked.filter((v) => v !== t));
      setChecked((prevChecked) => {
        return prevChecked.filter((v) => v !== t);
      });
    } else {
      setFilter([...checked, t]);
      setChecked((prevChecked) => [...prevChecked, t]);
    }
  };

  return (
    <PopoverMenu
      icon={
        checked.length > 0 && Object.keys(options).length > 0
          ? "filter-fill.svg"
          : "filter.svg"
      }
      styles={styles || "ml-1"}
      positionStyles={positionStyles || "right-6"}
      position={position}
      header={capitalize(Header)}
    >
      <div className="py-2">
        <TableFilter
          filter={columnSearch}
          setFilter={setColumnSearch}
          placeholder="Search"
        />
      </div>
      <div className="flex items-center justify-between">
        <button
          className="text-blue-500 py-1 hover:underline"
          onClick={() => {
            setFilter(Object.keys(options));
            setChecked(Object.keys(options));
          }}
        >
          Select all
        </button>
        <button
          className="text-blue-500 py-1 hover:underline"
          onClick={() => {
            setFilter([]);
            setChecked([]);
          }}
        >
          Clear all
        </button>
      </div>
      {debouncedColumns.sort().map(([option, count], index) => (
        <div className="py-1 top-0" key={index}>
          <input
            type="checkbox"
            className="focus:ring-0"
            id={option}
            name={option}
            onChange={onChange}
            title={`${option} (${count})`}
            checked={checked.includes(option)}
          />
          <label
            htmlFor={option}
            className="pl-2"
          >{`${option} (${count})`}</label>
        </div>
      ))}
    </PopoverMenu>
  );
};

export default TableRowsFilter;
