import { useState } from "react";
import { PlatformFieldsFragment } from "../../../hasura.graphql";
import ViewMultiPicklist from "../../channels/channel-view-form/view-multi-picklist";
import ViewChannelTrailer from "../../channels/channel-view-form/view-trailer";
import ViewArtworkTable from "./view-artwork-table";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillTwitterSquare,
} from "react-icons/ai";
import ViewAvailabilityRegions from "../../channels/channel-view-form/view-availability-regions";
// import DialogModal from "../../dialog";
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast, Toaster } from "react-hot-toast";
import ViewKeyring from "../../channels/channel-view-form/view-keyring";
import DelayedToolTip from "../../delayed-tooltip";
import ToggleBox from "../../toggle-box";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { InformationCircleIcon } from "@heroicons/react/outline";
import ViewPlatformContacts from "./view-platform-contacts";
import ViewReferenceLinks from "./view-reference-links";

const PlatformBusinessFields: React.FC<{
  platformData: PlatformFieldsFragment;
}> = ({ platformData }) => {
  const [hideColumns, setHideColumns] = useState<Array<string>>([]);
  // const [openPresentation, setOpenPresentation] = useState<boolean>(false);

  const handleHideColumns = (tab: string) => {
    setHideColumns((hideColumns) => [...hideColumns, tab]);
  };
  const handleUnhideColumns = (tab: string) => {
    setHideColumns(hideColumns.filter((f: string) => f !== tab));
  };

  return (
    <div>
      <Toaster />
      <div style={{ color: "#4E4E4E", fontSize: "14px", fontWeight: "500" }}>
        <ToggleBox
          columns={hideColumns}
          handleHideColumns={handleHideColumns}
          handleUnhideColumns={handleUnhideColumns}
          header="Basic Information"
        >
          <>
            <div className="grid grid-cols-3 gap-4">
              <div>
                <div className="text-base font-semibold mb-3">
                  Platform Details
                </div>
                <div className="flex">
                  <div className="w-16 h-12">
                    <LazyLoadImage
                      className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                      src={
                        platformData?.logo
                          ? `/${platformData?.logo}`
                          : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${platformData?.name}`
                      }
                      alt={platformData?.name}
                    />
                  </div>
                  <div className="ml-3 flex flex-col">
                    {platformData?.name}
                    <div className="flex">
                      <span className="mr-2 text-sm text-gray-500">
                        {platformData?.id}
                      </span>
                      <DelayedToolTip title="copy">
                        <CopyToClipboard
                          text={platformData?.id}
                          data-tip="copy"
                        >
                          <img
                            src="/assets/file-copy.svg"
                            onClick={() => toast.success("Copied")}
                            alt="file-copy"
                          />
                        </CopyToClipboard>
                      </DelayedToolTip>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="text-base font-semibold mb-3">
                  Platform Owner
                </div>
                {platformData?.account?.amagi_id ? (
                  <div className="flex">
                    {platformData?.account?.amagi_id && (
                      <div className="w-16 h-12">
                        <LazyLoadImage
                          className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                          src={
                            platformData.account.logo
                              ?  platformData.account.logo.startsWith("uploads/")
                                ? `/${platformData.account.logo}`
                                : `/logos/${platformData.account.logo}`
                              : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${platformData?.account.account_name}`
                          }
                          alt={platformData.account.account_name}
                        />
                      </div>
                    )}
                    <div className="ml-3 flex flex-col">
                      <a
                        href={`/customers/${platformData?.account?.amagi_id}/details`}
                        target="_blank"
                        rel="noreferrer"
                        className="hover:text-blue-500 hover:underline"
                      >
                        {platformData?.account?.account_name &&
                        platformData?.account.account_name.length > 20
                          ? `${platformData?.account.account_name.slice(
                              0,
                              20
                            )}...`
                          : platformData?.account?.account_name}
                      </a>
                      <span className="text-sm text-gray-500">
                        {platformData?.account?.amagi_id}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div>-</div>
                )}
              </div>
            </div>
            <div className="my-8">
                <div className="text-base font-semibold mb-3">Platform Brand</div>
                <span className="text-sm text-gray-500">
                    {platformData?.platform_brand ? platformData.platform_brand : "--"}
                </span>
            </div>
            <div className="my-8">
                <div className="text-base font-semibold mb-3">Onboarded Date</div>
                <span className="text-sm text-gray-500">
                {platformData?.onboarded_date
                ? new Date(platformData.onboarded_date).toLocaleDateString('en-GB')
                : "--"}
                </span>
            </div>
            <div className="flex items-center text-base font-semibold mt-8 mb-2">
              <span className="mr-2">Platform Manager</span>
              <DelayedToolTip
                delay={0}
                title="Only assigned if Platforms are managed by Platforms Team otherwise if platform delivery is managed by respective channel partner project manager then it will be N/A"
                position="top"
              >
                <InformationCircleIcon className="h-4 w-4 text-blue-500" />
              </DelayedToolTip>
            </div>
            <div className="grid grid-cols-3 gap-y-8 gap-x-36 mb-8">
              <div>
                <label className="text-gray-400">Name</label>
                <div className="mt-2">
                  {platformData.platform_manager &&
                  platformData.platform_manager.name
                    ? platformData.platform_manager.name
                    : "-"}
                </div>
              </div>
              <div>
                <label className="text-gray-400">Email</label>
                <div className="flex mt-2">
                  <span className="mr-3">
                    {platformData.platform_manager &&
                    platformData.platform_manager.email
                      ? platformData.platform_manager.email
                      : "-"}
                  </span>
                  {platformData.platform_manager &&
                    platformData.platform_manager.email && (
                      <DelayedToolTip title="copy">
                        <CopyToClipboard
                          text={platformData.platform_manager.email}
                        >
                          <img
                            src="/assets/file-copy.svg"
                            onClick={() => toast.success("Copied")}
                            alt="file-copy"
                          />
                        </CopyToClipboard>
                      </DelayedToolTip>
                    )}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-y-8 gap-x-36 my-8">
              <div
                className={`${
                  platformData?.platform_description
                    ? "col-span-2"
                    : "col-span-1"
                }`}
              >
                <label className="text-gray-400">Platform Description</label>
                <div className="mt-2">
                  {platformData?.platform_description || "-"}
                </div>
              </div>
              <div
                style={
                  platformData?.platform_description
                    ? { display: "grid", gridColumn: "1" }
                    : {}
                }
              >
                <label className="text-gray-400">Website URL</label>
                <div className="mt-2">
                  {platformData.website_url ? (
                    <a
                      href={platformData.website_url || "/"}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500 underline"
                    >
                      {platformData.website_url}
                    </a>
                  ) : (
                    <div>-</div>
                  )}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-y-8 gap-x-36">
              <div style={{ display: "grid", gridColumn: "1" }}>
                <label className="text-gray-400">Platform Tier</label>
                <div className="mt-2">{platformData?.platform_tier || "-"}</div>
              </div>
              <div style={{ display: "grid", gridColumn: "1" }}>
                <ViewMultiPicklist
                  inlineLabel="Target Devices"
                  picklist={platformData.devices_field}
                />
              </div>
            </div>
          </>
        </ToggleBox>
        <ToggleBox
          columns={hideColumns}
          handleHideColumns={handleHideColumns}
          handleUnhideColumns={handleUnhideColumns}
          header="Keyring"
        >
          <ViewKeyring
            keyring={platformData.keyring}
            defaultId={platformData.salesforce_id}
          />
        </ToggleBox>
        <ToggleBox
          columns={hideColumns}
          handleHideColumns={handleHideColumns}
          handleUnhideColumns={handleUnhideColumns}
          header="Platform Artwork and Trailers"
        >
          <>
            <div className="mb-8">
              <span className="text-gray-400">Platform Logo</span>
              {platformData?.logo ? (
                <div className="flex mt-4">
                  <div className="w-44 h-24">
                    <LazyLoadImage
                      className="h-full w-full rounded-lg object-contain bg-lightPrimary"
                      src={
                        platformData?.logo
                          ? `/${platformData?.logo}`
                          : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${platformData?.name}`
                      }
                      alt={platformData?.name}
                    />
                  </div>
                </div>
              ) : (
                <div>-</div>
              )}
            </div>
            <div className="mb-8">
              <span className="text-gray-400">Posters</span>
              {platformData?.poster.length > 0 ? (
                platformData?.poster.map((poster: string, index: number) => {
                  return (
                    <div className="flex mt-4">
                      <div className="w-44 h-24">
                        <LazyLoadImage
                          className="h-full w-full rounded-lg object-contain bg-lightPrimary"
                          src={
                            poster
                              ? `/${poster}`
                              : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=poster-${index}`
                          }
                          alt={`poster-${index}`}
                        />
                      </div>
                      <div className="ml-4">
                        <div>{`Platform Poster ${index + 1}`}</div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>-</div>
              )}
            </div>
            <div className="mb-8">
              <span className="text-gray-400">Videos</span>
              <ViewChannelTrailer trailers={platformData.video} />
            </div>
            <div className="my-8">
              <span className="text-gray-400">Presentations</span>
              {platformData?.presentation.length > 0 ? (
                platformData?.presentation.map(
                  (pres: string, index: number) => {
                    return (
                      <div className="flex mt-4">
                        <div className="w-44 h-24 border flex justify-center rounded">
                          <img
                            src={
                              pres.slice(pres.length - 3) === "pdf"
                                ? "/assets/pdf-icon.svg"
                                : "/assets/ppt-icon.svg"
                            }
                            alt="file-icon"
                          />
                        </div>
                        <div className="ml-4">
                          <a
                            href={`/${pres}`}
                            download
                            className="text-blue-500 underline"
                          >{`...${pres.slice(pres.length - 15)}`}</a>
                        </div>
                        {/* {openPresentation && (
                          <DialogModal
                            onClose={setOpenPresentation}
                            modalShow={openPresentation}
                          >
                            <div>
                              <DocViewer
                                documents={platformData.presentations
                                  .filter((present: string) => present === pres)
                                  .map((prest: string) => {
                                    return {
                                      uri: `/${prest}`,
                                    };
                                  })}
                                pluginRenderers={DocViewerRenderers}
                              />
                            </div>
                          </DialogModal>
                        )} */}
                      </div>
                    );
                  }
                )
              ) : (
                <div>-</div>
              )}
            </div>
            <div className="text-base font-semibold my-5">Social Profiles</div>
            <div className="grid grid-cols-3 gap-y-8 gap-x-36">
              <div>
                <label className="text-gray-400">Instagram</label>
                <div className="flex items-center mt-1">
                  <span className="mr-2 text-xl">
                    <AiFillInstagram />
                  </span>
                  {platformData.instagram ? (
                    <a
                      href={platformData.instagram || "#"}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500"
                    >
                      <span>{platformData.instagram || "-"}</span>
                    </a>
                  ) : (
                    <span>-</span>
                  )}
                </div>
              </div>
              <div>
                <label className="text-gray-400">Facebook</label>
                <div className="flex items-center mt-1">
                  <span className="mr-2 text-xl">
                    <AiFillFacebook />
                  </span>
                  {platformData.facebook ? (
                    <a
                      href={platformData.facebook}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500"
                    >
                      <span>{platformData.facebook}</span>
                    </a>
                  ) : (
                    <span>-</span>
                  )}
                </div>
              </div>
              <div style={{ display: "grid", gridColumn: "1" }}>
                <label className="text-gray-400">Linkedin</label>
                <div className="flex items-center mt-1">
                  <span className="mr-2 text-xl">
                    <AiFillLinkedin />
                  </span>
                  {platformData.linkedin ? (
                    <a
                      href={platformData.linkedin}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500"
                    >
                      <span>{platformData.linkedin}</span>
                    </a>
                  ) : (
                    <span>-</span>
                  )}
                </div>
              </div>
              <div>
                <label className="text-gray-400">Twitter</label>
                <div className="flex items-center mt-1">
                  <span className="mr-2 text-xl">
                    <AiFillTwitterSquare />
                  </span>
                  {platformData.twitter ? (
                    <a
                      href={platformData.twitter}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500"
                    >
                      <span>{platformData.twitter}</span>
                    </a>
                  ) : (
                    <span>-</span>
                  )}
                </div>
              </div>
            </div>
          </>
        </ToggleBox>
        <ToggleBox
          columns={hideColumns}
          handleHideColumns={handleHideColumns}
          handleUnhideColumns={handleUnhideColumns}
          header="Platform Preferences"
        >
          <>
            <div className="mb-8">
              <ViewAvailabilityRegions
                availRegions={platformData.availablity_country_and_regions}
              />
            </div>
            <div className="grid grid-cols-3 gap-y-8 gap-x-36">
              <div style={{ display: "grid", gridColumn: "1" }}>
                <ViewMultiPicklist
                  inlineLabel="Target Audience"
                  picklist={platformData.target_audience}
                />
              </div>
              <div style={{ display: "grid", gridColumn: "1" }}>
                <div>
                  <label className="text-gray-400">
                    Available in Amagi NOW
                  </label>
                  <div className="mt-2">
                    {platformData?.available_in_amagi_now !== null
                      ? platformData?.available_in_amagi_now
                        ? "Yes"
                        : "No"
                      : "-"}
                  </div>
                </div>
              </div>
              <div style={{ display: "grid", gridColumn: "1" }}>
                <ViewMultiPicklist
                  inlineLabel="Preferred Genre(s)"
                  picklist={platformData.preferred_genre}
                />
              </div>
              <div style={{ display: "grid", gridColumn: "1" }}>
                <ViewMultiPicklist
                  inlineLabel="Preferred Language(s)"
                  picklist={platformData.preferred_language}
                />
              </div>
              {/* <div style={{ display: "grid", gridColumn: "1" }}>
                <div>
                  <label className="text-gray-400">
                    Preferred Business Model
                  </label>
                  <div className="mt-2">
                    {platformData?.preferred_business_model || "-"}
                  </div>
                </div>
              </div> */}
              <div style={{ display: "grid", gridColumn: "1" }}>
                <div>
                  <label className="text-gray-400">SSAI By</label>
                  <div className="mt-2">{platformData?.ssai_by || "-"}</div>
                </div>
              </div>
              <div style={{ display: "grid", gridColumn: "1" }}>
                <div>
                  <label className="text-gray-400">Inventory Model</label>
                  <div className="mt-2">
                    {platformData?.inventory_model || "-"}
                  </div>
                </div>
              </div>
            </div>
          </>
        </ToggleBox>
        <ToggleBox
          columns={hideColumns}
          handleHideColumns={handleHideColumns}
          handleUnhideColumns={handleUnhideColumns}
          header="Platform Insights"
        >
          <>
            <div className="text-base font-semibold mb-2">
              Metrics (Region-wise):
            </div>
            <div className="grid grid-cols-3 gap-y-8 gap-x-36">
              <div>
                <label className="text-gray-400">
                  Avg. Monthly Active Users
                </label>
                <div className="mt-2">
                  {platformData.avg_monthly_active_user || "-"}
                </div>
              </div>
              <div>
                <label className="text-gray-400">Avg. Monthly HOV</label>
                <div className="mt-2">
                  {platformData.avg_monthly_hov || "-"}
                </div>
              </div>
              <div>
                <label className="text-gray-400">
                  Avg. Ad-minutes per Hour
                </label>
                <div className="mt-2">
                  {platformData.avg_ad_minute_per_hour
                    ? `${platformData.avg_ad_minute_per_hour} mins`
                    : "-"}
                </div>
              </div>
            </div>
            <div className="mt-8">
              <label className="text-gray-400">
                Estimated Channel Deliveries
              </label>
              <div className="mt-2">
                {platformData.estimated_channel_deliveries || "-"}
              </div>
            </div>
            <div className="mt-8">
              <ViewArtworkTable artwork={platformData.platform_artwork} />
            </div>
          </>
        </ToggleBox>
        <ToggleBox
          columns={hideColumns}
          handleHideColumns={handleHideColumns}
          handleUnhideColumns={handleUnhideColumns}
          header="Associations"
        >
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div>
              <label className="text-gray-400">Is Platform Group</label>
              <div className="mt-2">
                {platformData.is_platform_group || "-"}
              </div>
            </div>
            {platformData.is_platform_group === "Yes" ? (
              <div style={{ display: "grid", gridColumn: "1" }}>
                <label className="text-gray-400">Platforms Grouped</label>
                <div className="mt-2">
                  {platformData.platform_to_platform &&
                  platformData.platform_to_platform.length > 0 ? (
                    platformData.platform_to_platform.map((platform) => {
                      return (
                        <div className="flex mb-4">
                          <div className="w-16 h-12">
                            <LazyLoadImage
                              className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                              src={
                                platform.logo
                                  ? `/${platform.logo}`
                                  : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${platform.name}`
                              }
                              alt={platform.logo!}
                            />
                          </div>
                          <div className="ml-3 flex flex-col">
                            <div className="flex">
                              <a
                                href={`/platforms/platform/${platform.id}`}
                                target="_blank"
                                rel="noreferrer"
                                className="hover:text-blue-500 hover:underline"
                              >
                                {platform.name.length > 30
                                  ? `${platform.name.slice(0, 30)}...`
                                  : platform.name}
                              </a>
                            </div>
                            <span className="text-sm text-gray-500">
                              {platform.salesforce_id}
                            </span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="mt-2">-</div>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <label className="text-gray-400">Platform Grouped Under</label>
                {platformData.platform_to_platform_of_grouped ? (
                  <div className="flex mt-2">
                    <div className="w-16 h-12">
                      <LazyLoadImage
                        className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                        src={
                          platformData.platform_to_platform_of_grouped?.logo
                            ? `/${platformData.platform_to_platform_of_grouped?.logo}`
                            : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${platformData.platform_to_platform_of_grouped?.name}`
                        }
                        alt={
                          platformData.platform_to_platform_of_grouped?.logo!
                        }
                      />
                    </div>
                    <div className="ml-3 flex flex-col">
                      <div className="flex">
                        <a
                          href={`/platforms/grouped-platform/${platformData.platform_to_platform_of_grouped?.id}`}
                          target="_blank"
                          rel="noreferrer"
                          className="hover:text-blue-500 hover:underline"
                        >
                          {platformData.platform_to_platform_of_grouped?.name &&
                          platformData.platform_to_platform_of_grouped?.name
                            .length > 30
                            ? `${platformData.platform_to_platform_of_grouped?.name.slice(
                                0,
                                30
                              )}...`
                            : platformData.platform_to_platform_of_grouped
                                ?.name}
                        </a>
                      </div>
                      <span className="text-sm text-gray-500">
                        {
                          platformData.platform_to_platform_of_grouped
                            ?.salesforce_id
                        }
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="mt-2">-</div>
                )}
              </div>
            )}
          </div>
        </ToggleBox>
        <ToggleBox
          columns={hideColumns}
          handleHideColumns={handleHideColumns}
          handleUnhideColumns={handleUnhideColumns}
          header="VOD Details"
        >
          <>
            <div>
              <div className="flex items-center mb-2">
                <label className="text-gray-400 mr-2">VOD Support</label>
                <DelayedToolTip
                  delay={0}
                  title="Is VOD mandatory with Linear Channel ?"
                  position="top"
                >
                  <InformationCircleIcon className="h-4 w-4 text-blue-500" />
                </DelayedToolTip>
              </div>

              <div className="mt-2">{platformData.vod_support || "-"}</div>
            </div>
            <div className="my-8">
              <label className="text-gray-400">VOD Delivery Mode</label>
              <div className="mt-2">
                {platformData.vod_delivery_mode || "-"}
              </div>
            </div>
            <div>
              <label className="text-gray-400">
                VOD Connector Availability
              </label>
              <div className="mt-2">
                {platformData.vod_connector_availability || "-"}
              </div>
            </div>
          </>
        </ToggleBox>
        <ToggleBox
          columns={hideColumns}
          handleHideColumns={handleHideColumns}
          handleUnhideColumns={handleUnhideColumns}
          header="Platform Contacts"
        >
          <ViewPlatformContacts contacts={platformData.platform_contacts} />
        </ToggleBox>
        <ToggleBox
          columns={hideColumns}
          handleHideColumns={handleHideColumns}
          handleUnhideColumns={handleUnhideColumns}
          header="References"
        >
          <ViewReferenceLinks references={platformData.reference_links} />
        </ToggleBox>
      </div>
    </div>
  );
};

export default PlatformBusinessFields;
