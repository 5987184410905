import { ProgressBar } from "@amagimedia/design-system";

const ProgressBarComp: React.FC<{ progress: number; color: string }> = ({
  progress,
  color,
}) => {
  return (
    <div>
      <ProgressBar progress={progress} color={color} />
    </div>
  );
};

export default ProgressBarComp;
