import getSymbolFromCurrency from "currency-symbol-map";
import { DateTime } from "luxon";
import numeral from "numeral";
// import { useState } from "react";

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function capitalize(str: any) {
  str = str.split(" ");
  const idIndex = str.findIndex((st: string) => st === "id");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].toLowerCase();
    str[i] = str[i][0].toUpperCase() + str[i].substr(1);
  }
  if (idIndex > 0) {
    str.splice(idIndex, 1);
    str.push("ID");
  }
  return str.join(" ");
}

export const DateParser = (date: string) => {
  return DateTime.fromISO(date).toFormat("LLL dd, yyyy");
};

export const MonthParser = (date: string) => {
  return DateTime.fromISO(date).toFormat("LLL, yyyy");
};

export const currencyConverter = (
  value: number,
  currency: string,
  format: string = "0a"
) => {
  return `${getSymbolFromCurrency(currency)} ${numeral(value)
    .format(format)
    .toUpperCase()}`;
};

export const DateSort = (a: string, b: string) => {
  return a < b ? -1 : a > b ? 1 : 0;
};

export const DescDateSort = (a: string, b: string) => {
  return a < b ? 1 : a > b ? -1 : 0;
};

export const contentWidth = `${window.innerWidth - 50}px`;

export const tableCellCheckboxStyle = {
  padding: "10px 0px",
  paddingLeft: 10,
  maxWidth: contentWidth,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
} as React.CSSProperties;

export const tableExcludeHeaderStyle = {
  maxWidth: contentWidth,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  fontSize: "12px",
  padding: "10px 15px",
  color: "#47536B",
} as React.CSSProperties;

export const tableExcludeCellStyle = {
  color: "#181D25",
  maxWidth: contentWidth,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
} as React.CSSProperties;

export const tableCellStyle = {
  color: "#181D25",
  maxWidth: contentWidth,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  padding: "10px 0px",
  // paddingLeft: 20,
} as React.CSSProperties;

export const tableHeaderStyle = {
  fontSize: "12px",
  color: "#47536B",
  maxWidth: contentWidth,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  padding: "10px 0px",
  paddingLeft: 10,
} as React.CSSProperties;

export const cloudportProducts = [
  "CP_HW_010",
  "CP_DL_023",
  "CP_FS_004_C01",
  "CP_FS_044",
  "CP_SA_001",
  "CP_SA_004",
  "CP_FS_055",
  "CP_FS_007",
  "CP_FS_003",
  "CP_SA_002",
  "CP_SA_003",
  "CP_FS_043",
  "CP_FS_009",
  "CP_FS_002_C01",
  "CP_SU_016",
  "CP_FS_027",
  "CP_FS_030",
  "CP_FS_032",
  "CP_FS_033",
  "CP_DL_030",
  "CP_DL_031",
  "CP_FS_029",
  "CP_HW_006",
  "CP_FS_046",
  "CP_SU_006",
  "CP_FS_016",
  "CP_HW_004",
  "CP_DL_027",
  "CP_SU_015",
  "CP_DL_033",
  "CP_HW_007",
  "CP_HW_009",
  "CP_FS_058",
  "CP_DL_006",
  "CP_FS_002_C02",
  "CP_DL_028",
  "CP_HW_003",
  "CP_HW_005",
  "CP_FS_002",
  "CP_FS_038",
  "CP_FS_035",
  "CP_FS_039",
  "CP_FS_040",
  "CP_FS_053",
  "CP_FS_014",
  "CP_SU_005",
  "CP_FS_056",
  "CP_CR_016",
  "CP_HW_012",
  "CP_FS_005",
  "CP_SU_017",
  "CP_FS_002_C03",
  "CP_FS_002_C04",
  "CP_HW_011",
  "CP_FS_004",
  "CP_FS_006",
  "CP_PL_007",
  "CP_SU_010",
  "CP_SU_001",
  "CP_SU_011",
  "CP_FS_057",
  "CP_FS_045",
  "CP_FS_041",
  "CP_FS_052",
  "CP_DL_035",
  "CP_FS_031",
  "CP_DL_024",
  "CP_AI_002",
  "CP_AI_003",
  "CP_SU_002",
  "CP_HW_001",
  "CP_HW_002",
];

export const greenStatus = [
  "Delivered & Waiting for Go Live, Billed",
  "Delivered & Waiting for Go Live, Not Billed",
  "Delivered & Waiting for Go Live, Not Billable",
  "Active, Not Billable",
  "Active, Billed",
  "Active, Not Billed",
];

export const greenBilledStatus = [
  "Delivered & Waiting for Go Live, Billed",
  "Active, Billed",
]

export const greenNotBilledStatus = [
  "Delivered & Waiting for Go Live, Not Billed",
  "Active, Not Billed",
]

export const greenNotBillableStatus =[
 "Delivered & Waiting for Go Live, Not Billable",
 "Active, Not Billable"
]

export const churnedStatus = ["Suspended"];
export const progressStatus = ["Provisioning In Progress"];
export const backlogStatus = ["Provisioning Not Started"];
export const orderingStatus = [
  "Order Creation Pending",
  "Order Creation In Progress",
  "Order Creation On Hold",
  "Order Created",
];
export const onHoldStatus = ["Provisioning Blocked", "Provisioning On Hold", "Provisioning At Risk"];

export const closedLostStatus = ["Delivery Closed Lost"];

export const statusChip = (status: string) => {
  if (greenStatus.includes(status)) return "bg-green-100 text-green-800";
  else if (churnedStatus.includes(status)) return "bg-red-100 text-red-800";
  else if (progressStatus.includes(status))
    return "bg-orange-200 text-orange-600";
  else if (backlogStatus.includes(status))
    return "bg-orange-100 text-orange-500";
  else if (onHoldStatus.includes(status))
    return "bg-orange-300 text-orange-700";
  else if (orderingStatus.includes(status)) return "bg-blue-100 text-blue-800";
  else if (closedLostStatus.includes(status))
    return "bg-matrixYellow text-yellow-700";
};

export const statusMatrixChip = (status: string | null) => {
  if (greenStatus.includes(status!)) return "bg-matrixGreen";
  else if (churnedStatus.includes(status!)) return "bg-matrixRed";
  else if (backlogStatus.includes(status!)) return "bg-matrixOrange";
  else if (onHoldStatus.includes(status!)) return "bg-matrixBrown";
  else if (progressStatus.includes(status!)) return "bg-matrixDarkOrange";
  else if (orderingStatus.includes(status!)) return "bg-matrixBlue";
  else if (closedLostStatus.includes(status!)) return "bg-matrixYellow";
  else return "bg-white";
};

export function compareHST(a: any, b: any) {
  const createdA = DateTime.fromISO(a.created_date);
  const resolvedA = DateTime.fromISO(a.resolved_date);
  const createdB = DateTime.fromISO(b.created_date);
  const resolvedB = DateTime.fromISO(b.resolved_date);
  const diffDaysA = resolvedA.diff(createdA, "days").toObject().days || 0;
  const diffDaysB = resolvedB.diff(createdB, "days").toObject().days || 0;
  return diffDaysB - diffDaysA;
}

// export function useLocalStorage<T>(key: string, initialValue: T) {
//   const [storedValue, setStoredValue] = useState<T>(() => {
//     if (typeof window === "undefined") {
//       return initialValue;
//     }
//     try {
//       const item = window.localStorage.getItem(key);
//       return item ? JSON.parse(item) : initialValue;
//     } catch (error) {
//       console.log(error);
//       return initialValue;
//     }
//   });

//   const setValue = (value: T | ((val: T) => T)) => {
//     try {
//       const valueToStore =
//         value instanceof Function ? value(storedValue) : value;
//       // Save state
//       setStoredValue(valueToStore);
//       // Save to local storage
//       if (typeof window !== "undefined") {
//         window.localStorage.setItem(key, JSON.stringify(valueToStore));
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   return [storedValue, setValue] as const;
// }
