import React, { Fragment, useEffect, useState } from "react";
import { useField } from "formik";
import { ChevronDownIcon, TrashIcon } from "@heroicons/react/outline";
import { channelFields } from "../../constants";
// import { DeliveryRegionsType } from "../channels/channel-edit-form/types";
import { Popover, Transition } from "@headlessui/react";
import { DeliveryRegionsType } from "../channel-delivery/delivery-edit-form/types";
import TableFilter from "../../common/table-filter";

export type AvailabilityCountriesandRegionsType = {
  country: Array<string>;
  countries_code: Array<string>;
  countries_exception: Array<string>;
  country_exception_code: Array<string>;
  region: string;
  region_code: string;
};

const FormikDeliveryRegions: React.FC<{
  availRegion: Array<DeliveryRegionsType>;
  fieldName: string;
}> = ({ availRegion, fieldName }) => {
  const [search, setSearch] = useState<string | null>(null);
  const [inputTable, setInputTable] = useState<Array<DeliveryRegionsType>>(
    availRegion.map((reg) => {
      return {
        region: reg.region,
        countries: reg.countries,
        countries_exception: reg.countries_exception,
      };
    })
  );

  const handleChangeRegion = (index: number, value: string) => {
    let availRegions: Array<DeliveryRegionsType> = JSON.parse(
      JSON.stringify(inputTable)
    );
    availRegions[index]["region"] = value;
    setInputTable(availRegions);
  };

  const handleChangeCountries = (index: number, value: string) => {
    let availRegions: Array<DeliveryRegionsType> = JSON.parse(
      JSON.stringify(inputTable)
    );
    let countries = [...availRegions[index].countries];

    if (!availRegions[index]["countries"].includes(value))
      countries.push(value);

    availRegions[index]["countries"] = availRegions[index][
      "countries"
    ].includes(value)
      ? availRegions[index].countries.filter((con) => con !== value)
      : countries;
    setInputTable(availRegions);
  };

  const handleChangeCountriesException = (index: number, value: string) => {
    let availRegions: Array<DeliveryRegionsType> = JSON.parse(
      JSON.stringify(inputTable)
    );
    let countries_exception = [...availRegions[index].countries_exception];

    if (!availRegions[index]["countries_exception"].includes(value))
      countries_exception.push(value);

    availRegions[index]["countries_exception"] = availRegions[index][
      "countries_exception"
    ].includes(value)
      ? availRegions[index].countries_exception.filter((con) => con !== value)
      : countries_exception;
    setInputTable(availRegions);
  };

  const handleAddClick = () => {
    setInputTable((input) => [
      ...input,
      {
        region: "",
        countries: [],
        countries_exception: [],
      },
    ]);
  };

  const _field = useField(fieldName);
  const { setValue } = _field[2];

  useEffect(() => {
    (inputTable.every(
      (tab) =>
        tab.countries.length > 0 ||
        tab.countries_exception.length > 0 ||
        tab.region.length > 0
    ) ||
      inputTable.length === 0) &&
      setValue(inputTable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputTable]);

  const debouncedRegions =
    channelFields.availability_countries_and_regions.filter((region) => {
      if (!search) {
        return true;
      }
      return region.country.toLowerCase().includes(search.toLowerCase());
    });

  return (
    <div style={{ padding: "5px 0px" }}>
      <label className="text-gray-400">Delivery Regions</label>
      {inputTable && inputTable.length > 0 && (
        <table className="mt-4" style={{ width: "80%", fontSize: "16px" }}>
          <thead>
            <tr className="bg-gray-300">
              <th className="py-2 pl-2">S No.</th>
              <th className="text-left py-2 pl-2">Region</th>
              <th className="text-left py-2 pl-2">Countries</th>
              <th className="text-left py-2 pl-2">Countries Exceptions</th>
            </tr>
          </thead>
          <tbody className="bg-gray-50">
            {inputTable.map((data: DeliveryRegionsType, index: number) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td className="py-2 pl-2">{index + 1}</td>
                    <td className="text-left py-1 pl-2">
                      <Popover
                        as="div"
                        className="relative items-center max-w-sm"
                      >
                        {({ open, close }) => (
                          <>
                            <Popover.Button className="rounded-full w-full focus:outline-none">
                              <div
                                placeholder={"Enter Text"}
                                style={{ padding: "5.5px 0px" }}
                                className="flex justify-between border-0 text-left focus:ring-0 border-gray-300 outline-none bg-transparent w-full"
                              >
                                <span>{data.region || "Please select"}</span>
                                <span className="mr-3">
                                  <ChevronDownIcon className="h-4 w-4" />
                                </span>
                              </div>
                            </Popover.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Popover.Panel className="origin-top-right text-left z-10 absolute right-0 min-w-full max-h-60 overflow-y-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {() => (
                                  <div className="">
                                    {channelFields.availablity_regions
                                      .sort()
                                      .filter(
                                        (con) =>
                                          !inputTable.some(
                                            (inp) => inp.region === con
                                          )
                                      )
                                      .map((inp: string, rIndex: number) => {
                                        return (
                                          <div key={rIndex} className="">
                                            <div
                                              className={`focus:ring-0 cursor-pointer hover:bg-blue-100 px-3 py-1`}
                                              onClick={() => {
                                                close();
                                                handleChangeRegion(index, inp);
                                              }}
                                            >
                                              {inp}
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                )}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </td>
                    <td className="text-left py-1 pl-2">
                      <Popover
                        as="div"
                        className="relative items-center max-w-sm"
                      >
                        {({ open }) => (
                          <>
                            <Popover.Button className="rounded-full w-full focus:outline-none">
                              <select
                                name=""
                                value={`${data.countries.length} selected`}
                                placeholder={"Enter Text"}
                                style={{ padding: "5.5px 0px" }}
                                onChange={() => null}
                                className="border-0 focus:ring-0 border-gray-300 outline-none bg-transparent w-full"
                              >
                                <option disabled>
                                  {data.countries.length} selected
                                </option>
                              </select>
                            </Popover.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Popover.Panel className="origin-top-right text-left z-10 absolute right-0 min-w-full max-h-60 overflow-y-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {() => (
                                  <div className="px-2">
                                    {data.region && (
                                      <div className="p-2 sticky top-0 bg-white">
                                        <TableFilter
                                          filter={search}
                                          setFilter={setSearch}
                                          placeholder="Search"
                                        />
                                      </div>
                                    )}
                                    {debouncedRegions
                                      .filter(
                                        (avail) => avail.region === data.region
                                      )
                                      .filter(
                                        (con) =>
                                          inputTable[
                                            index
                                          ].countries_exception.includes(
                                            con.country
                                          ) !== true
                                      )
                                      .map((inp, cIndex: number) => {
                                        return (
                                          <div key={cIndex} className="py-1">
                                            <input
                                              type="checkbox"
                                              className="focus:ring-0"
                                              id={inp.country_code}
                                              name={inp.country}
                                              onChange={(e) =>
                                                handleChangeCountries(
                                                  index,
                                                  inp.country
                                                )
                                              }
                                              title={inp.country}
                                              checked={data.countries.includes(
                                                inp.country
                                              )}
                                            />
                                            <label
                                              htmlFor={inp.country_code}
                                              className="pl-2"
                                            >
                                              {inp.country}
                                            </label>
                                          </div>
                                        );
                                      })}
                                  </div>
                                )}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </td>
                    <td className="text-left py-1 pl-2">
                      <Popover
                        as="div"
                        className="relative items-center max-w-sm"
                      >
                        {({ open }) => (
                          <>
                            <Popover.Button className="rounded-full w-full focus:outline-none">
                              <select
                                name=""
                                value={`${data.countries_exception.length} selected`}
                                placeholder={"Enter Text"}
                                style={{ padding: "5.5px 0px" }}
                                onChange={() => null}
                                className="border-0 focus:ring-0 border-gray-300 outline-none bg-transparent w-full"
                              >
                                <option disabled>
                                  {data.countries_exception.length} selected
                                </option>
                              </select>
                            </Popover.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Popover.Panel className="origin-top-right text-left z-10 absolute right-0 min-w-full max-h-60 overflow-y-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {() => (
                                  <div className="px-2">
                                    {data.region && (
                                      <div className="p-2 sticky top-0 bg-white">
                                        <TableFilter
                                          filter={search}
                                          setFilter={setSearch}
                                          placeholder="Search"
                                        />
                                      </div>
                                    )}
                                    {debouncedRegions
                                      .filter(
                                        (avail) => avail.region === data.region
                                      )
                                      .filter(
                                        (con) =>
                                          inputTable[index].countries.includes(
                                            con.country
                                          ) !== true
                                      )
                                      .map((inp, cIndex: number) => {
                                        return (
                                          <div key={cIndex} className="py-1">
                                            <input
                                              type="checkbox"
                                              className="focus:ring-0"
                                              id={inp.country_code}
                                              name={inp.country}
                                              onChange={(e) =>
                                                handleChangeCountriesException(
                                                  index,
                                                  inp.country
                                                )
                                              }
                                              title={inp.country}
                                              checked={data.countries_exception.includes(
                                                inp.country
                                              )}
                                            />
                                            <label
                                              htmlFor={inp.country_code}
                                              className="pl-2"
                                            >
                                              {inp.country}
                                            </label>
                                          </div>
                                        );
                                      })}
                                  </div>
                                )}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </td>
                    <td className="bg-white py-2 pl-2 cursor-pointer">
                      <TrashIcon
                        onClick={() =>
                          setInputTable(inputTable.filter((f) => f !== data))
                        }
                        className="h-5 w-5"
                      />
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      )}
      <div className="py-1 mt-1 w-max rounded px-2 text-sm text-left cursor-pointer text-blue-500">
        <span onClick={handleAddClick}>+ ADD NEW ROW</span>
      </div>
    </div>
  );
};

export default FormikDeliveryRegions;
