import {
  DocumentDownloadIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { DeliveryInfoFragment } from "../../hasura.graphql";
import { DeliveryRegionsType } from "../channel-delivery/delivery-edit-form/types";
import DelayedToolTip from "../delayed-tooltip";
import { useScreenshot, createFileName } from "use-react-screenshot";
import {
  churnedStatus,
  greenBilledStatus,
  greenNotBilledStatus,
  greenNotBillableStatus,
  onHoldStatus,
  orderingStatus,
  backlogStatus,
  progressStatus,
  statusMatrixChip,
  closedLostStatus,
} from "../utils";
import { ChangeEvent, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FilteredChannelsType, FilteredPlatformsType } from "./types";
import _ from "lodash";
import { useCDM } from "../../cdm-context";

const DeliveryChart: React.FC<{
  data: Array<DeliveryInfoFragment>;
  header: string;
  toggle: boolean;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ data, header, toggle, setToggle }) => {
  const { show } = useCDM();
  const ref = useRef(null);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/png",
    quality: 2.0,
  });


  console.log(image);


  const download = (
    image: any,
    { name = `${show?.amg_id}_Deliveries_Matrix`, extension = "png" } = {}
  ) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadScreenshot = () => {
    console.log(ref?.current);
    takeScreenShot(ref?.current).then(download);
  };

  const [status, setStatus] = useState<string[]>([
    ...greenNotBilledStatus,
    ...greenBilledStatus,
    ...greenNotBillableStatus,
    ...onHoldStatus,
    ...backlogStatus,
    ...orderingStatus,
    ...progressStatus,
    ...churnedStatus,
    ...closedLostStatus,
  ]);
  
const totalDeliveryCount = data.filter((del) => status.includes(del.delivery_status!)).length;

const filteredPlatforms: FilteredPlatformsType[] = Array.from(
  new Set(
    data
      .filter((del) => status.includes(del.delivery_status!))
      .map((del) => del.platform?.name)
  )
).map((plat) => {
  const platformDeliveries = data.filter(
    (del) => del.platform?.name === plat && status.includes(del.delivery_status!)
  );
  return {
    name: plat || undefined,
    logo: data.find((del) => del.platform?.name === plat)?.platform?.logo,
    totalDeliveryCount: platformDeliveries.length, 
  };
});

  const sortedPlatforms = _.sortBy(filteredPlatforms, (plat) => {
    return plat.name;
  });

  
  const filteredChannels: FilteredChannelsType[] = Array.from(
    new Set(
      data
        .filter((del) => status.includes(del.delivery_status!))
        .map((p) => p.channel?.name)
    )
  ).map((chn) => {
    const channelDeliveries = data.filter(
      (del) => del.channel?.name === chn && status.includes(del.delivery_status!)
    );
    return {
      name: chn || undefined,
      logo: data.find((del) => del.channel?.name === chn)?.channel?.logo,
      totalDeliveryCount: channelDeliveries.length,
      platforms: sortedPlatforms.map((plat) => {
        return {
          name: plat.name,
          delivery_id: data.find(
            (del) =>
              del.channel?.name === chn &&
              del?.platform?.name === plat.name &&
              status.includes(del.delivery_status!)
          )?.id,
        };
      }),
    };
  });
  
  const sortedChannels = _.sortBy(filteredChannels, (chn) => {
    return chn.name;
  });

  const handleStatus = (e: ChangeEvent<HTMLInputElement>) => {
    const sts = e.target.name;
    if (!status.includes(sts)) {
      if (sts === "Order Created") {
        setStatus((prevStatus) => [...prevStatus, ...orderingStatus]);
      }
      if (sts === "Provisioning In Progress") {
        setStatus((prevStatus) => [...prevStatus, ...progressStatus]);
      }
      if (sts === "Provisioning Not Started") {
        setStatus((prevStatus) => [...prevStatus, ...backlogStatus]);
      }
      if (sts === "Provisioning Blocked") {
        setStatus((prevStatus) => [...prevStatus, ...onHoldStatus]);
      }
      if (sts === "Provisioning At Risk") {
        setStatus(status.filter((st) => !onHoldStatus.includes(st)));
      }
      if (sts === "Active, Not Billed") {
        setStatus((prevStatus) => [...prevStatus, ...greenNotBilledStatus]);
      }
      if (sts === "Active, Billed") {
        setStatus((prevStatus) => [...prevStatus, ...greenBilledStatus]);
      }
      if (sts === "Active, Not Billable") {
        setStatus((prevStatus) => [...prevStatus, ...greenNotBillableStatus]);
      }
      if (sts === "Delivery Closed Lost") {
        setStatus((prevStatus) => [...prevStatus, ...closedLostStatus]);
      }
      if (sts === "Suspended") {
        setStatus((prevStatus) => [...prevStatus, ...churnedStatus]);
      }
     
    } else {
      if (sts === "Order Created") {
        setStatus(status.filter((st) => !orderingStatus.includes(st)));
      }
      if (sts === "Provisioning In Progress") {
        setStatus(status.filter((st) => !progressStatus.includes(st)));
      }
      if (sts === "Provisioning Not Started") {
        setStatus(status.filter((st) => !backlogStatus.includes(st)));
      }
      if (sts === "Provisioning Blocked") {
        setStatus(status.filter((st) => !onHoldStatus.includes(st)));
      }
      if (sts === "Provisioning At Risk") {
        setStatus(status.filter((st) => !onHoldStatus.includes(st)));
      }
      if (sts === "Active, Not Billed") {
        setStatus(status.filter((st) => !greenNotBilledStatus.includes(st)));
      }
      if (sts === "Active, Billed") {
        setStatus(status.filter((st) => !greenBilledStatus.includes(st)));
      }
      if (sts === "Active, Not Billable") {
        setStatus(status.filter((st) => !greenNotBillableStatus.includes(st)));
      }
      if (sts === "Delivery Closed Lost") {
        setStatus(status.filter((st) => !closedLostStatus.includes(st)));
      }
      if (sts === "Suspended") {
        setStatus(status.filter((st) => !churnedStatus.includes(st)));
      }
      
    }
  };

  const handleAllStatus = (e: ChangeEvent<HTMLInputElement>) => {
    if (status.length < 13) {
      setStatus([
        ...greenNotBilledStatus,
        ...greenBilledStatus,
        ...greenNotBillableStatus,
        ...onHoldStatus,
        ...backlogStatus,
        ...orderingStatus,
        ...progressStatus,
        ...churnedStatus,
        ...closedLostStatus,
      ]);
    } else {
      setStatus([]);
    }
  };

  const checkboxStatus = [
    {
      id: "Order Created",
      label: "Ordering",
      color: "matrixBlue",
      statuses: orderingStatus,
      tooltip:
        "Order Creation Pending, Order Creation In Progress, Order Creation On Hold, Order Created",
    },
    {
      id: "Provisioning Not Started",
      label: "Provisioning Backlog",
      color: "matrixOrange",
      statuses: backlogStatus,
      tooltip: "Provisioning Not Started",
    },
    {
      id: "Provisioning In Progress",
      label: "Provisioning",
      color: "matrixDarkOrange",
      statuses: progressStatus,
      tooltip: "Provisioning In Progress",
    },
    {
      id: "Provisioning Blocked",
      label: "Provisioning On Hold",
      color: "matrixBrown",
      statuses: onHoldStatus,
      tooltip:  "Provisoning Blocked ,Provisoning At Risk",
    },
    {
      id: "Active, Not Billed",
      label: "Delivered & Active, Not Billed",
      color: "matrixGreen",
      statuses: greenNotBilledStatus,
      tooltip:
        "“Delivered & Waiting for Go Live, Not Billed” ,“Active, Not Billed”",
    },
    {
      id: "Active, Billed",
      label: "Delivered & Active, Billed",
      color: "matrixGreen",
      statuses: greenBilledStatus,
      tooltip:
        "“Delivered & Waiting for Go Live, Billed”, “Active, Billed”",
    },
    {
      id: "Active, Not Billable",
      label: "Delivered & Active, Not Billable",
      color: "matrixGreen",
      statuses: greenNotBillableStatus,
      tooltip:
        "“Delivered & Waiting for Go Live, Not Billable”, “Active, Not Billable”",
    },
    {
      id: "Delivery Closed Lost",
      label: "Delivery Closed Lost",
      color: "matrixYellow",
      statuses: closedLostStatus,
      tooltip: null,
    },
    {
      id: "Suspended",
      label: "Suspended",
      color: "matrixRed",
      statuses: churnedStatus,
      tooltip: null,
    },
  ];

  return (
    <div>
      <div className="w-full bg-white border" style={{ background: "#D3DDFC" }}>
        <div className="p-2 text-sm flex justify-between bg-white">
          <div>
          <div>
        <strong>Total Delivery Count: {totalDeliveryCount}</strong>
        </div>
          </div>
          <div className="flex justify-center">
            <div className="flex items-center mr-6">
              <input
                type="checkbox"
                checked={status.length === 14}
                className="focus:ring-0"
                onChange={handleAllStatus}
              />
              <div className="ml-2 font-semibold">All</div>
            </div>
            {checkboxStatus.map((box) => (
              <div key={box.id} className="flex items-center mr-6">
                <input
                  type="checkbox"
                  id={box.id}
                  name={box.id}
                  checked={box.statuses.every((sts) => status.includes(sts))}
                  onChange={handleStatus}
                  className="mr-2 focus:ring-0"
                />
                <div className="flex flex-col">
                  <div className={`flex items-center`}>
                    <div className={`font-semibold text-${box.color}`}>
                      {box.label}
                    </div>
                    {box.tooltip ? (
                      <div className="ml-2">
                        <DelayedToolTip
                          position="top"
                          delay={0}
                          title={box.tooltip}
                        >
                          <InformationCircleIcon
                            className={`h-3 w-3 text-${box.color}`}
                          />
                        </DelayedToolTip>
                      </div>
                    ) : null}
                  </div>
                  <div className={`w-full h-1 bg-${box.color}`}></div>
                </div>
              </div>
            ))}
          </div>
          <DelayedToolTip title={toggle ? "Exit Full Screen" : "Full Screen"}>
            <div className="mr-2" onClick={() => setToggle(!toggle)}>
              <img
                className="w-6 h-6"
                src={`/assets/${toggle ? "normal" : "full"}-screen.svg`}
                alt="screen"
              />
            </div>
          </DelayedToolTip>
          <DelayedToolTip title="Download as Image">
            <button
              className="text-primaryColor px-2 py-1 rounded"
              onClick={downloadScreenshot}
            >
              <DocumentDownloadIcon className="h-5 w-5" />
            </button>
          </DelayedToolTip>
        </div>

        <div
          style={{
            maxWidth: window.innerWidth,
            maxHeight: `${window.innerHeight - (toggle ? 43 : 290)}px`,
            overflow: "scroll",
          }}
          className="border-t"
        >
          <table
            className="bg-white border-separate w-max"
            style={{ background: "#F1F4FE" }}
            ref={ref}
          >
            <thead
              className="border-y font-semibold bg-white"
              style={{
                position: "sticky",
                insetBlockStart: 0,
                zIndex: 10,
              }}
            >
              <tr>
                <td className="sticky left-0 bg-white border-r-4 border-b-4"></td>
                {sortedPlatforms.map((plat, index) => (
                  <td
                    key={index}
                    className="border-r border-b-4 py-1 px-px"
                    style={{ minWidth: "120px", maxWidth: "160px" }}
                  >
                    {plat.name ? (
                      // <DelayedToolTip
                      //   position="top"
                      //   delay={0}
                      //   title={
                      //     <table className="border">
                      //       <thead className="border-b">
                      //         <tr>
                      //           <th className="text-xs p-1">Deliveries</th>
                      //           <th className="text-xs p-1">
                      //             {
                      //               data.filter(
                      //                 (del) => del.platform?.name === plat.name
                      //               ).length
                      //             }
                      //           </th>
                      //         </tr>
                      //       </thead>
                      //       <tbody>
                      //         <tr>
                      //           <td className="text-xs p-1">
                      //             Delivered & Active
                      //           </td>
                      //           <td className="text-xs p-1">3</td>
                      //         </tr>
                      //       </tbody>
                      //     </table>
                      //   }
                      // >
                      <div className="flex flex-col justify-start items-center">
                        <div className="w-12 h-8">
                          <LazyLoadImage
                            className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                            src={
                              plat.logo
                                ? `/${plat.logo}`
                                : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${plat.name}`
                            }
                            alt={plat.name}
                          />
                        </div>
                        <div className="mt-1 text-sm">{plat.name}</div>
                        <div>
                        <div style={{ marginRight: '5px' }}>
                         {`(${plat.totalDeliveryCount})`} 
                        </div>
                        </div>
                      </div>
                    ) : (
                      // </DelayedToolTip>
                      <div>-</div>
                    )}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedChannels.map((chn, index) => (
                <tr className="border-b" key={index}>
                  <td
                    className="sticky left-0 bg-white font-semibold border-r-4 border-b"
                    style={{ width: "250px" }}
                  >
                    {chn.name ? (
                      <div className="flex items-center">
                        <div className="w-12 h-8">
                          <LazyLoadImage
                            className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                            src={
                              chn.logo
                                ? `/${chn.logo}`
                                : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${chn.name}`
                            }
                            alt={chn.name}
                          />
                        </div>
                        <div className="ml-2 text-sm text-left">
                          {chn.name.length > 25
                            ? `${chn.name.slice(0, 25)}...` 
                            : chn.name}
                          <div style={{ marginRight: '5px' }}>
                          {`(${chn.totalDeliveryCount})`} 
                          </div>
                        </div>
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                  {chn.platforms.map((plat, pIndex) => {
                    let delivery = data
                      .filter((del) => status.includes(del.delivery_status!))
                      .find(
                        (del) =>
                          del.channel?.name === chn.name &&
                          del.platform?.name === plat.name
                      );
                    return (
                      <td className="border-r border-b p-px" key={pIndex}>
                        {delivery ? (
                          <DelayedToolTip
                            delay={0}
                            title={
                              <DeliveryComponent
                                header={header}
                                delivery={delivery}
                              />
                            }
                            position="top"
                          >
                            <div
                              className={`w-full flex justify-center rounded h-14 ${statusMatrixChip(
                                delivery?.delivery_status || null
                              )}`}
                            >
                              <div
                                className={`${
                                  delivery.delivery_status === "Suspended" &&
                                  "text-white"
                                } flex flex-col items-stretch justify-between text-sm my-1`}
                              >
                                <div>
                                  {delivery?.delivery_stream_type?.join(", ")}
                                </div>
                                <div>
                                  {delivery?.business_model?.join(", ")}
                                </div>
                              </div>
                            </div>
                          </DelayedToolTip>
                        ) : null}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const DeliveryComponent: React.FC<{
  delivery: DeliveryInfoFragment | undefined;
  header: string;
}> = ({ delivery, header }) => {
  return (
    <div className="">
      <div>Status: {delivery?.delivery_status}</div>
      <div>
        Region:{" "}
        {delivery?.delivered_region.map(
          (reg: DeliveryRegionsType) => reg.region
        )}
      </div>
      <div>Delivery Stream Type: {delivery?.delivery_stream_type}</div>
      <div>Business Model: {delivery?.business_model}</div>
      <div>
        SSAI:{" "}
        {delivery?.ssai !== undefined ? (delivery?.ssai ? "Yes" : "No") : "-"}
      </div>
      <a
        href={`/deliveries/${
          header === "Channel Deliveries"
            ? "channel-deliveries"
            : "vod-deliveries"
        }/${delivery?.id}`}
        target="_blank"
        rel="noreferrer"
        className="text-blue-300"
      >
        Learn More
      </a>
    </div>
  );
};

export default DeliveryChart;
