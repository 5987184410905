/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";

interface DescriptionType {
  language: string;
  short: string;
  long: string;
}

const ViewDescription: React.FC<{ description: Array<DescriptionType> }> = ({
  description,
}) => {
  const attributeLists = description.map((val: DescriptionType) => {
    return val.language;
  });

  const [selectedField, setSelectedField] = useState<string>(attributeLists[0]);
  const descriptionText = description.find(
    (f: DescriptionType) => f.language === selectedField
  ) || { short: "", long: "" };

  return (
    <div>
      <div className="grid grid-cols-3 gap-y-8 gap-x-36 mb-3">
        <div>
          <div style={{ padding: "5px 0px" }}>
            <label className="text-gray-400">Description</label>
            <div className="flex mt-2">
              {attributeLists.length > 0
                ? attributeLists.map((data: string, index: number) => {
                    return (
                      <div
                        key={index}
                        style={{
                          backgroundColor: `${
                            selectedField === data ? "#BDC2CB" : "#fff"
                          }`,
                          border: "1px solid #BDC2CB",
                        }}
                        className="px-3 flex cursor-pointer rounded-sm text-black font-semibold mr-2"
                      >
                        <div onClick={() => setSelectedField(data)}>{data}</div>
                      </div>
                    );
                  })
                : "-"}
            </div>
          </div>
        </div>
      </div>
      {attributeLists.length > 0 ? (
        <div className="mt-4">
          <div className="bg-gray-100 p-5">
            <div style={{ padding: "5px 0px" }}>
              <label className="text-gray-400">Short Description</label>
              <textarea
                value={descriptionText.short}
                disabled
                className="py-2 px-3 rounded focus:ring-0 border-gray-300 outline-none bg-transparent w-full"
              />
            </div>
            <br />
            <div style={{ padding: "5px 0px" }}>
              <label className="text-gray-400">Long Description</label>
              <textarea
                value={descriptionText.long}
                disabled
                className="py-2 px-3 rounded focus:ring-0 border-gray-300 outline-none bg-transparent w-full"
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ViewDescription;
