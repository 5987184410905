import { DateTime } from "luxon";
import { useState } from "react";
import { useCDM } from "../../../cdm-context";
import Loader from "../../../common/loader";
import { useCustomerFinChartCummulativeByPkQuery } from "../../../hasura.graphql";
import ErrorBoundary from "../../error-boundary";
import FinChart from "./fin-chart";

const FinancialsChartWrapper: React.FC = () => {
  const [fromDate, setFromDate] = useState<string>("6");
  const { show } = useCDM();
  const { data, loading, error } = useCustomerFinChartCummulativeByPkQuery({
    variables: {
      amagi_id: show?.amg_id!,
      from_date: DateTime.local()
        .startOf("month")
        .minus({ months: parseInt(fromDate) - 1 })
        .toISO()
        .slice(0, 10),
    },
  });

  if (loading) {
    return (
      <div className="m-5">
        <Loader />
      </div>
    );
  }
  if (error) {
    return (
      <div className="mx-6 my-3">
        <i>Error</i>
      </div>
    );
  }
  if (!data || !data.customer) {
    return <i>Could not retrieve customer financials data</i>;
  }

  return (
    <ErrorBoundary>
      <FinChart
        data={data.customer}
        fromDate={fromDate}
        setFromDate={setFromDate}
      />
    </ErrorBoundary>
  );
};

export default FinancialsChartWrapper;
