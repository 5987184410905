import React, { useMemo, useState } from "react";
import {
  useLiveDeliveriesQuery,
  useFeatureFlagQuery,
} from "../hasura.graphql";
import Modal from "../components/modal";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import Loader from "../common/loader";
import { useCDPRole } from "../cdp-role-context";
import PageTable from "../common/page-table";
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment";
import { statusChip } from "../components/utils";
import ErrorBoundary from "../components/error-boundary";
import { useDebounce } from "use-debounce";
import LiveDeliveryViewForm from "../components/live-delivery/delivery-view-form";
import { useLocation, useNavigate } from "react-router-dom";
import { DeliveryRegionsType } from "../components/live-delivery/delivery-edit-form/types";
import TableRowsFilter from "../common/filters/table-rows-filter";

export type ModalType = {
  name: string;
};

const LiveDeliveries: React.FC = () => {
  const { setCDPRole } = useCDPRole();
  const [deliverySearch, setDeliverySearch] = useState<string>("");
  const navigate = useNavigate();
  const [deliveryId, setDeliveryId] = React.useState<ModalType | null>(null);
  const [debouncedDeliverySearch] = useDebounce(deliverySearch, 100);
  const { pathname } = useLocation();

  const deliveriesTab = [
    {
      label: "Playouts",
      url: "/deliveries/playouts",
      active: pathname === "/deliveries/playouts",
    },
    {
      label: "Channel Deliveries",
      url: "/deliveries/channel-deliveries",
      active: pathname === "/deliveries/channel-deliveries",
    },
    {
      label: "VOD Deliveries",
      url: "/deliveries/vod-deliveries",
      active: pathname === "/deliveries/vod-deliveries",
    },
    {
        label: "Live Deliveries",
        url: "/deliveries/live-deliveries",
        active: pathname === "/deliveries/live-deliveries",
    },
      
  ];

  const columns: any = useMemo(
    () => [
      {
        Header: "DELIVERY ID",
        accessor: "delivery_id",
        Cell: ({ cell }: any) => (
          <div className="flex items-center">
            <span
              className="hover:text-blue-500 hover:underline cursor-pointer mr-1"
              onClick={() => {
                setDeliveryId({
                  name: cell.row.original.delivery_id,
                });
              }}
            >
              {cell.row.original.delivery_id.length > 20
                ? `${cell.row.original.delivery_id.slice(0, 20)}...`
                : cell.row.original.delivery_id}
            </span>
            <a
              href={`/deliveries/live-deliveries/${cell.row.original.delivery_id}`}
              target="_blank"
              rel="noreferrer"
            >
              <ExternalLinkIcon className="h-5 w-5 text-blue-500" />
            </a>
          </div>
        ),
        show: true,
      },
      {
        Header: "CUSTOMER ID",
        accessor: "customer_id",
        show: false,
      },
      {
        Header: "CUSTOMER NAME",
        accessor: "customer_name",
        Cell: ({ cell }: any) => (
          <div className="flex">
            {cell.row.original.customer_id ? (
              <>
                {cell.row.original.customer_name && (
                  <div className="w-12 h-8">
                    <LazyLoadImage
                      className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                      src={
                        cell.row.original.customer_logo
                          ? cell.row.original.customer_logo.startsWith("uploads/")
                            ? `/${cell.row.original.customer_logo}`
                            : `/logos/${cell.row.original.customer_logo}`
                          : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${cell.row.original.customer_name}`
                      }
                      alt={cell.row.original.customer_logo}
                    />
                  </div>
                )}
                <div className="ml-2 flex flex-col">
                  <a
                    href={`/customers/${cell.row.original.customer_id}/details`}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-blue-500 hover:underline"
                  >
                    {cell.row.values.customer_name.length > 15
                      ? `${cell.row.values.customer_name.slice(0, 15)}...`
                      : cell.row.values.customer_name}
                  </a>
                  <span className="text-xs text-gray-500">
                    {cell.row.original.customer_id}
                  </span>
                </div>
              </>
            ) : (
              <span>-</span>
            )}
          </div>
        ),
        Filter: TableRowsFilter,
        // position: "relative",
        positionStyles: "left-0",
        filter: "multiSelect",
        show: true,
      },
      {
        Header: "CHANNEL ID",
        accessor: "channel_id",
        show: false,
      },
      {
        Header: "CHANNEL NAME",
        accessor: "channel_name",
        Cell: ({ cell }: any) => (
          <div className="flex">
            {cell.row.original.channel_id ? (
              <>
                {cell.row.original.channel_name && (
                  <div className="w-12 h-8">
                    <LazyLoadImage
                      className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                      src={
                        cell.row.original.channel_logo
                          ? `/${cell.row.original.channel_logo}`
                          : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${cell.row.original.channel_name}`
                      }
                      alt={cell.row.original.channel_logo}
                    />
                  </div>
                )}
                <div className="ml-2 flex flex-col">
                  <a
                    href={`/channels/${
                      cell.row.original.is_channel_group !== null &&
                      cell.row.original.is_channel_group !== "No"
                        ? "grouped-channel"
                        : cell.row.original.channel_type
                        ? cell.row.original.channel_type.toLowerCase()
                        : "linear"
                    }/${cell.row.original.channel_id}`}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-blue-500 hover:underline"
                  >
                    {cell.row.values.channel_name.length > 20
                      ? `${cell.row.values.channel_name.slice(0, 20)}...`
                      : cell.row.values.channel_name}
                  </a>
                  <span className="text-xs text-gray-500">
                    {cell.row.original.channel_id.length > 15
                      ? `${cell.row.original.channel_id.slice(0, 15)}...`
                      : cell.row.original.channel_id}
                  </span>
                </div>
              </>
            ) : (
              <div>--</div>
            )}
          </div>
        ),
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },
      {
        Header: "PLATFORM ID",
        accessor: "platform_id",
        show: false,
      },
      {
        Header: "PLATFORM NAME",
        accessor: "platform_name",
        Cell: ({ cell }: any) => (
          <div className="flex">
            {cell.row.original.platform_id ? (
              <>
                {cell.row.original.platform_name && (
                  <div className="w-12 h-8">
                    <LazyLoadImage
                      className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                      src={
                        cell.row.original.platform_logo
                          ? `/${cell.row.original.platform_logo}`
                          : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${cell.row.original.platform_name}`
                      }
                      alt={cell.row.original.platform_logo}
                    />
                  </div>
                )}
                <div className="ml-2 flex flex-col">
                  <a
                    href={`/platforms/${
                      cell.row.original.is_platform_group !== null &&
                      cell.row.original.is_platform_group !== "No"
                        ? "grouped-platform"
                        : "platform"
                    }/${cell.row.original.platform_id}`}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-blue-500 hover:underline"
                  >
                    {cell.row.values.platform_name.length > 20
                      ? `${cell.row.values.platform_name.slice(0, 20)}...`
                      : cell.row.values.platform_name}
                  </a>
                  <span className="text-xs text-gray-500">
                    {cell.row.original.platform_id.length > 15
                      ? `${cell.row.original.platform_id.slice(0, 15)}...`
                      : cell.row.original.platform_id}
                  </span>
                </div>
              </>
            ) : (
              <div>--</div>
            )}
          </div>
        ),
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },
      {
        Header: "REGION",
        accessor: "regions",
        Cell: ({ cell }: any) => (
          <div className="flex flex-wrap" style={{ fontSize: "12px" }}>
            {cell.row.original.regions.length > 0 ? (
              cell.row.original.regions
                .split(",")
                .map((g: any, index: number) => {
                  if (index < 3) {
                    return (
                      <span
                        key={index}
                        className="border border-gray-300 px-2 mr-1 my-1 rounded-full"
                      >
                        {g}
                      </span>
                    );
                  } else if (index === 3) {
                    return (
                      <span
                        key={index}
                        className="border border-gray-300 px-2 mr-1 rounded-full"
                      >{`+ ${
                        cell.row.original.regions.split(",").length - index
                      }`}</span>
                    );
                  } else return null;
                })
            ) : (
              <div>-</div>
            )}
          </div>
        ),
        show: true,
      },
      {
        Header: "AMAGI NOW",
        accessor: "delivered_using_amagi_now",
        Cell: ({ cell }: any) => (
          <>
            {cell.row.original.delivered_using_amagi_now !== "--" ? (
              <span>{cell.row.original.delivered_using_amagi_now}</span>
            ) : (
              <span>-</span>
            )}
          </>
        ),
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },
      {
        Header: "DELIVERY STATUS",
        accessor: "status",
        Cell: ({ cell }: any) => (
          <>
            {cell.row.original.status !== "--" ? (
              <span
                className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${statusChip(
                  cell.row.original.status
                )}`}
              >
                {cell.row.original.status}
              </span>
            ) : (
              <span>--</span>
            )}
          </>
        ),
        Filter: TableRowsFilter,
        filter: "multiSelect",
        show: true,
      },
    ],
    []
  );

  const exportHeaders = [
    { label: "Delivery ID", key: "delivery_id" },
    { label: "Customer ID", key: "customer_id" },
    { label: "Customer Name", key: "customer_name" },
    { label: "Channel ID", key: "channel_id" },
    { label: "Channel Name", key: "channel_name" },
    { label: "Platform ID", key: "platform_id" },
    { label: "Platform Name", key: "platform_name" },
    { label: "Region", key: "regions" },
    { label: "Delivered using Amagi NOW", key: "delivered_using_amagi_now" },
    { label: "Delivery Status", key: "status" },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const role_data = useFeatureFlagQuery({
    onCompleted: (data) => {
      setCDPRole(data.feature_flag.map((feat) => feat.feature));
    },
  });
  
  const { data, loading, error } = useLiveDeliveriesQuery();

  if (loading) {
    return (
      <>
        <div
          className={`hidden sm:block sticky top-14 z-20 bg-gradient-to-r from-gradientBlue via-gradientBlue to-gradientPurple w-full`}
        >
          <div className="border-b border-gray-200 rounded-t-md">
            <nav className="-mb-px px-6 py-1 flex space-x-8" aria-label="Tabs">
              {deliveriesTab.map((tab) => {
                return (
                  <div
                    onClick={() => {
                      navigate(tab.url);
                    }}
                    className={`${
                      tab.active
                        ? "text-orange-500"
                        : "text-white hover:scale-105 transition-all"
                    } whitespace-nowrap p-2 font-medium text-sm cursor-pointer`}
                  >
                    {tab.label}
                  </div>
                );
              })}
            </nav>
          </div>
        </div>
        <div className="mx-6 mt-4">
          <Loader />
        </div>
      </>
    );
  }
  if (error) {
    return <i>Error</i>;
  }
  if (!data) {
    return <i>Deliveries data not available</i>;
  }

  const debouncedDeliveries = data.delivery.filter((del) => {
    if (!debouncedDeliverySearch) {
      return true;
    }
    return `${del.id} ${del.channel?.name} ${del.salesforce_channel_id} ${
      del.platform?.name
    } ${del.salesforce_platform_id} ${del.order?.customer?.account_name} ${
      del.order?.customer?.amagi_id
    } ${del.delivery_status} ${
      del.point_product_delivery_id
    } ${del.delivered_region
      .map((reg: DeliveryRegionsType) => reg.region)
      .join(" ")}`
      .toLowerCase()
      .includes(debouncedDeliverySearch.toLowerCase());
  });

  const filteredDeliveries = debouncedDeliveries.map((del) => {
    return {
      delivery_id: del.id,
      customer_id: del.order?.customer?.amagi_id!,
      customer_name: del.order?.customer?.account_name! || "-",
      customer_logo: del.order?.customer?.logo!,
      channel_id: del.channel?.id!,
      channel_type: del.channel?.channel_type,
      channel_salesforce_id: del.channel?.salesforce_id!,
      delivered_using_amagi_now: del.delivered_using_amagi_now || "--",
      channel_name: del.channel?.name! || "-",
      is_channel_group: del.channel?.channels_grouped,
      platform_id: del.platform?.id!,
      platform_salesforce_id: del.platform?.salesforce_id!,
      platform_name: del.platform?.name! || "-",
      is_platform_group: del.platform?.is_platform_group,
      channel_logo: del.channel?.logo!,
      platform_logo: del.platform?.logo!,
      regions:
        del.delivered_region && del.delivered_region.length > 0
          ? del.delivered_region
              .map((reg: DeliveryRegionsType) => reg.region)
              .join()
          : "",
      status: del.delivery_status ? del.delivery_status : "--",
    };
  });

  return (
    <ErrorBoundary>
      <div
        className={`hidden sm:block sticky top-14 z-20 bg-gradient-to-r from-gradientBlue via-gradientBlue to-gradientPurple w-full`}
      >
        <div className="border-b border-gray-200 rounded-t-md">
          <nav className="-mb-px px-6 py-1 flex space-x-8" aria-label="Tabs">
            {deliveriesTab.map((tab) => {
              return (
                <div
                  onClick={() => {
                    navigate(tab.url);
                  }}
                  className={`${
                    tab.active
                      ? "text-orange-500"
                      : "text-white hover:scale-105 transition-all"
                  } whitespace-nowrap p-2 font-medium text-sm cursor-pointer`}
                >
                  {tab.label}
                </div>
              );
            })}
          </nav>
        </div>
      </div>
      <div className="mx-6">
        <PageTable
          columns={columns}
          height={window.innerHeight - 245}
          header="Channel Deliveries"
          search={deliverySearch}
          warning="Deliveries"
          setSearch={setDeliverySearch}
          rowData={filteredDeliveries}
          exportHeaders={exportHeaders}
          fileName={`CDP_Deliveries_${moment().format("YYYYMMDDHHmmss")}.csv`}
          placeholder="Search for delivery id, channel, platform, customer and delivery status" 
          setFromDate={null}
          setToDate={null}
        />
        {deliveryId && (
          <Modal
            title="All Live Deliveries"
            modalWidth="100vw"
            modalShow={deliveryId}
            onClose={setDeliveryId}
          >
            <LiveDeliveryViewForm
              deliveryId={deliveryId.name}
              isModal={true}
            />
          </Modal>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default LiveDeliveries;