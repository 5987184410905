import React, { Fragment, useEffect, useState } from "react";
import { useField } from "formik";
import { ChevronDownIcon, TrashIcon } from "@heroicons/react/outline";
import { platformFields } from "../../../constants";
import { PlatformTechnicalType } from "./types";
import { Popover, Transition } from "@headlessui/react";

const FormikStreamSpecs: React.FC<{
  streamSpecs: Array<PlatformTechnicalType>;
}> = ({ streamSpecs }) => {
  const [inputTable, setInputTable] = useState<Array<PlatformTechnicalType>>([
    ...streamSpecs,
  ]);

  const handleSelect = (
    index: number,
    value: number | string | Array<any>,
    inputKey: string
  ) => {
    let streamSpecs: Array<any> = JSON.parse(JSON.stringify(inputTable));
    streamSpecs[index][`${inputKey}` as keyof PlatformTechnicalType] = value;
    setInputTable(streamSpecs);
  };

  const handleMultiSelect = (
    index: number,
    value: string,
    inputKey: "frame_rate" | "resolution"
  ) => {
    let streamSpecs: Array<PlatformTechnicalType> = JSON.parse(
      JSON.stringify(inputTable)
    );
    let multiSelectValues = [...streamSpecs[index][inputKey]];

    if (!streamSpecs[index][inputKey].includes(value))
      multiSelectValues.push(value);

    streamSpecs[index][inputKey] = streamSpecs[index][inputKey].includes(value)
      ? streamSpecs[index][inputKey].filter((con) => con !== value)
      : multiSelectValues;
    setInputTable(streamSpecs);
  };

  const handleAddClick = () => {
    setInputTable((input) => [
      ...input,
      {
        resolution_type: "",
        resolution: [],
        bitrate: null,
        frame_rate: [],
        audio: "",
        gop: "",
      },
    ]);
  };

  const _field = useField("technical_spec");
  const { setValue } = _field[2];

  useEffect(() => {
    (inputTable.every(
      (tab) =>
        tab.resolution.length > 0 ||
        tab.resolution_type.length > 0 ||
        tab.bitrate !== null ||
        tab.frame_rate.length > 0 ||
        tab.audio.length > 0
    ) ||
      inputTable.length === 0) &&
      setValue(inputTable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputTable]);

  return (
    <div style={{ padding: "5px 0px" }}>
      <label className="text-gray-400">Stream Specs</label>
      {inputTable && inputTable.length > 0 && (
        <table className="mt-4" style={{ width: "80%", fontSize: "16px" }}>
          <thead>
            <tr className="bg-gray-300">
              <th className="py-2 pl-2">S No.</th>
              <th className="text-left py-2 pl-2">Resolution Type</th>
              <th className="text-left py-2 pl-2">Resolution</th>
              <th className="text-left py-2 pl-2">Bitrate</th>
              <th className="text-left py-2 pl-2">Frame Rate</th>
              <th className="text-left py-2 pl-2">Audio</th>
              <th className="text-left py-2 pl-2">GOP</th>
            </tr>
          </thead>
          <tbody className="bg-gray-50">
            {inputTable.map((data: PlatformTechnicalType, index: number) => {
              return (
                <React.Fragment>
                  <tr key={index}>
                    <td className="py-2 pl-2">{index + 1}</td>
                    <td className="text-left py-2 pl-2">
                      <Popover
                        as="div"
                        className="relative items-center max-w-sm"
                      >
                        {({ open, close }) => (
                          <>
                            <Popover.Button className="rounded-full w-full focus:outline-none">
                              <div
                                placeholder={"Enter Text"}
                                style={{ padding: "5.5px 0px" }}
                                className="flex justify-between border-0 text-left focus:ring-0 border-gray-300 outline-none bg-transparent w-full min-w-[100px]"
                              >
                                <span>
                                  {data.resolution_type || "Please select"}
                                </span>
                                <span className="mr-3">
                                  <ChevronDownIcon className="h-4 w-4" />
                                </span>
                              </div>
                            </Popover.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Popover.Panel className="origin-top-right text-left z-10 absolute right-0 min-w-full max-h-60 overflow-y-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {() => (
                                  <div className="">
                                    {platformFields.resolution_type
                                      .filter(
                                        (con) =>
                                          !inputTable.some(
                                            (inp) => inp.resolution_type === con
                                          )
                                      )
                                      .map((inp: string, rIndex: number) => {
                                        return (
                                          <div key={rIndex} className="">
                                            <div
                                              className={`focus:ring-0 cursor-pointer hover:bg-blue-100 px-3 py-1`}
                                              onClick={() => {
                                                close();
                                                handleSelect(
                                                  index,
                                                  inp,
                                                  "resolution_type"
                                                );
                                              }}
                                            >
                                              {inp}
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                )}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </td>
                    <td className="text-left py-2 pl-2">
                      <Popover
                        as="div"
                        className="relative items-center max-w-sm"
                      >
                        {({ open }) => (
                          <>
                            <Popover.Button className="rounded-full w-full focus:outline-none">
                              <div
                                placeholder={"Enter Text"}
                                style={{ padding: "5.5px 0px" }}
                                className="flex justify-between items-center border-0 text-left focus:ring-0 border-gray-300 outline-none bg-transparent w-full min-w-[100px]"
                              >
                                <span>
                                  {`${data.resolution.length} selected` ||
                                    "Please select"}
                                </span>
                                <span className="mr-3">
                                  <ChevronDownIcon className="h-4 w-4" />
                                </span>
                              </div>
                            </Popover.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Popover.Panel className="origin-top-right text-left z-10 absolute right-0 min-w-full max-h-60 overflow-y-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {() => (
                                  <div className="px-2">
                                    {platformFields.resolution.map(
                                      (inp, cIndex: number) => {
                                        return (
                                          <div key={cIndex} className="py-1">
                                            <input
                                              type="checkbox"
                                              className="focus:ring-0"
                                              id={inp}
                                              name={inp}
                                              onChange={(e) =>
                                                handleMultiSelect(
                                                  index,
                                                  inp,
                                                  "resolution"
                                                )
                                              }
                                              title={inp}
                                              checked={data.resolution.includes(
                                                inp
                                              )}
                                            />
                                            <label
                                              htmlFor={inp}
                                              className="pl-2"
                                            >
                                              {inp}
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </td>
                    <td className="text-left py-2 pl-2">
                      <div className="flex items-center min-w-[100px]">
                        <input
                          type="number"
                          value={data.bitrate!}
                          onChange={(e) => {
                            handleSelect(
                              index,
                              parseInt(e.target.value),
                              "bitrate"
                            );
                          }}
                          className="focus:ring-0 px-2 py-1 bg-transparent w-full rounded"
                          placeholder="0 - 8000"
                          min="0"
                          max="8000"
                        />
                        <span className="ml-2">kpbs</span>
                      </div>
                    </td>
                    <td className="text-left py-2 pl-2">
                      <Popover
                        as="div"
                        className="relative items-center max-w-sm"
                      >
                        {({ open }) => (
                          <>
                            <Popover.Button className="rounded-full w-full focus:outline-none">
                              <div
                                placeholder={"Enter Text"}
                                style={{ padding: "5.5px 0px" }}
                                className="flex justify-between items-center border-0 text-left focus:ring-0 border-gray-300 outline-none bg-transparent w-full min-w-[100px]"
                              >
                                <span>
                                  {`${data.frame_rate.length} selected` ||
                                    "Please select"}
                                </span>
                                <span className="mr-3">
                                  <ChevronDownIcon className="h-4 w-4" />
                                </span>
                              </div>
                            </Popover.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Popover.Panel className="origin-top-right text-left z-10 absolute right-0 min-w-full max-h-60 overflow-y-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {() => (
                                  <div className="px-2">
                                    {platformFields.frame_rate.map(
                                      (inp, cIndex: number) => {
                                        return (
                                          <div key={cIndex} className="py-1">
                                            <input
                                              type="checkbox"
                                              className="focus:ring-0"
                                              id={inp}
                                              name={inp}
                                              onChange={(e) =>
                                                handleMultiSelect(
                                                  index,
                                                  inp,
                                                  "frame_rate"
                                                )
                                              }
                                              title={inp}
                                              checked={data.frame_rate.includes(
                                                inp
                                              )}
                                            />
                                            <label
                                              htmlFor={inp}
                                              className="pl-2"
                                            >
                                              {inp}
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </td>
                    <td className="text-left py-2 pl-2">
                      <Popover
                        as="div"
                        className="relative items-center max-w-sm"
                      >
                        {({ open, close }) => (
                          <>
                            <Popover.Button className="rounded-full w-full focus:outline-none">
                              <div
                                placeholder={"Enter Text"}
                                style={{ padding: "5.5px 0px" }}
                                className="flex justify-between border-0 text-left focus:ring-0 border-gray-300 outline-none bg-transparent w-full min-w-[100px]"
                              >
                                <span>{data.audio || "Please select"}</span>
                                <span className="mr-3">
                                  <ChevronDownIcon className="h-4 w-4" />
                                </span>
                              </div>
                            </Popover.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Popover.Panel className="origin-top-right text-left z-10 absolute right-0 min-w-full max-h-60 overflow-y-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {() => (
                                  <div className="">
                                    {platformFields.audio
                                      .sort()
                                      .map((inp: string, rIndex: number) => {
                                        return (
                                          <div key={rIndex} className="">
                                            <div
                                              className={`focus:ring-0 cursor-pointer hover:bg-blue-100 px-3 py-1 ${
                                                inp === data.audio &&
                                                "bg-blue-600 text-white"
                                              }`}
                                              onClick={() => {
                                                close();
                                                handleSelect(
                                                  index,
                                                  inp,
                                                  "audio"
                                                );
                                              }}
                                            >
                                              {inp}
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                )}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </td>
                    <td className="text-left py-2 pl-2">
                      <Popover
                        as="div"
                        className="relative items-center max-w-sm"
                      >
                        {({ open, close }) => (
                          <>
                            <Popover.Button className="rounded-full w-full focus:outline-none">
                              <div
                                placeholder={"Enter Text"}
                                style={{ padding: "5.5px 0px" }}
                                className="flex justify-between border-0 text-left focus:ring-0 border-gray-300 outline-none bg-transparent w-full min-w-[100px]"
                              >
                                <span>{data.gop || "Please select"}</span>
                                <span className="mr-3">
                                  <ChevronDownIcon className="h-4 w-4" />
                                </span>
                              </div>
                            </Popover.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Popover.Panel className="origin-top-right text-left z-10 absolute right-0 min-w-full max-h-60 overflow-y-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {() => (
                                  <div className="">
                                    {platformFields.gop
                                      .sort()
                                      .map((inp: string, rIndex: number) => {
                                        return (
                                          <div key={rIndex} className="">
                                            <div
                                              className={`focus:ring-0 cursor-pointer hover:bg-blue-100 px-3 py-1 ${
                                                inp === data.gop &&
                                                "bg-blue-600 text-white"
                                              }`}
                                              onClick={() => {
                                                close();
                                                handleSelect(index, inp, "gop");
                                              }}
                                            >
                                              {inp}
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                )}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </td>
                    <td className="bg-white py-2 pl-2 cursor-pointer">
                      <TrashIcon
                        onClick={() =>
                          setInputTable(inputTable.filter((f) => f !== data))
                        }
                        className="h-5 w-5"
                      />
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      )}
      <div className="py-1 mt-1 w-max rounded px-2 text-sm text-left cursor-pointer text-blue-500">
        <span onClick={handleAddClick}>+ ADD NEW ROW</span>
      </div>
    </div>
  );
};

export default FormikStreamSpecs;
