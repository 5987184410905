import { ChevronRightIcon } from "@heroicons/react/outline";
import { Form, Formik } from "formik";
import { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Loader from "../../../common/loader";
import {
  DeliverybyPkDocument,
  useDeliverybyPkQuery,
  useUpdateDeliveryMutation,
} from "../../../hasura.graphql";
import { useCDPRole } from "../../../cdp-role-context";
import VODDeliveryFieldsEditForm from "./delivery-field-edit-form";
import SubTabs from "../../sub-tabs";

const VODDeliveryEditForm: React.FC<{
  deliveryId: string;
  isModal: boolean;
  close: () => void;
}> = ({ deliveryId, isModal, close }) => {
  const navigate = useNavigate();
  const [channelTab, setChannelTab] = useState("Delivery");
  const { CDPRole } = useCDPRole();
  const [update_delivery] = useUpdateDeliveryMutation({
    refetchQueries: [
      {
        query: DeliverybyPkDocument,
        variables: {
          id: deliveryId,
        },
      },
    ],
  });

  const { data, loading, error } = useDeliverybyPkQuery({
    variables: {
      id: deliveryId,
    },
  });

  if (loading) {
    return (
      <div className={`${!isModal && "mx-6 mt-4"}`}>
        <Loader />
      </div>
    );
  }
  if (error) {
    return <i>Error</i>;
  }
  if (!data) {
    return <i>Delivery data not available</i>;
  }

  const deliveryData = data.delivery[0];

  const channelIds = data.channel.map((cus) => {
    return {
      label: `${cus.salesforce_id} - ${cus.name}` || "",
      value: cus.salesforce_id || "",
    };
  });

  const platformIds = data.platform.map((cus) => {
    return {
      label: `${cus.salesforce_id} - ${cus.name}` || "",
      value: cus.salesforce_id || "",
    };
  });

  const productLists = data.product.map((prod) => {
    return {
      label: `${prod.code} - ${prod.name}` || "",
      code: prod.code || "",
      name: prod.name || "",
    };
  });

  return (
    <div className={`${!isModal && "mx-6"}`}>
      <Toaster />
      <Formik
        initialValues={{
          // channel_salesforce_id: deliveryData?.salesforce_channel_id
          //   ? deliveryData?.salesforce_channel_id
          //   : null,
          // platform_salesforce_id: deliveryData?.salesforce_platform_id
          //   ? deliveryData?.salesforce_platform_id
          //   : null,
          // order_salesforce_id: deliveryData?.salesforce_order_id
          //   ? deliveryData?.salesforce_order_id
          //   : null,
          // channel: deliveryData?.channel ? deliveryData?.channel : null,
          // platform: deliveryData?.platform ? deliveryData?.platform : null,
          point_product_delivery_id: deliveryData?.point_product_delivery_id
            ? deliveryData?.point_product_delivery_id
            : null,
          ts_setup: deliveryData?.ts_setup ? deliveryData?.ts_setup : null,
          test_delivery: deliveryData?.test_delivery
            ? deliveryData?.test_delivery
            : null,
          delivered_region: deliveryData?.delivered_region
            ? deliveryData?.delivered_region
            : [],
          ads_plus: deliveryData?.ads_plus ? deliveryData?.ads_plus : null,
          content_plus: deliveryData?.content_plus
            ? deliveryData?.content_plus
            : null,
          ordered_date: deliveryData?.ordered_date
            ? deliveryData?.ordered_date
            : null,
          provisioning_start_date: deliveryData?.provisioning_start_date
            ? deliveryData?.provisioning_start_date
            : null,
          delivered_date: deliveryData?.delivered_date
            ? deliveryData?.delivered_date
            : null,
          on_hold_date: deliveryData?.on_hold_date
            ? deliveryData?.on_hold_date
            : null,
          on_hold_reason: deliveryData?.on_hold_reason
            ? deliveryData?.on_hold_reason
            : null,
          go_live_date: deliveryData?.go_live_date
            ? deliveryData?.go_live_date
            : null,
          // churned_date: deliveryData?.churned_date
          //   ? deliveryData?.churned_date
          //   : null,
          // churned_reason: deliveryData?.churned_reason
          //   ? deliveryData?.churned_reason
          //   : null,
          delivery_type: deliveryData?.delivery_type
            ? deliveryData?.delivery_type
            : null,
          feed_type: deliveryData?.feed_type ? deliveryData?.feed_type : null,
          ssai: deliveryData?.ssai !== undefined ? deliveryData?.ssai : null,
          cdn_tag_1: deliveryData?.cdn_tag_1
          ? deliveryData?.cdn_tag_1 : null,
          cdn_tag_2: deliveryData?.cdn_tag_2
          ? deliveryData?.cdn_tag_2 : null,
          cdn_tag_3: deliveryData?.cdn_tag_3
          ? deliveryData?.cdn_tag_3 : null,
          cdn_tag_4: deliveryData?.cdn_tag_4
          ? deliveryData?.cdn_tag_4 : null,
          business_model: deliveryData?.business_model
            ? deliveryData?.business_model
            : [],
          product_purchased_for_this_delivery:
            deliveryData?.product_purchased_for_this_delivery
              ? deliveryData?.product_purchased_for_this_delivery
              : [],
          deal_type_for_content_partner:
            deliveryData?.deal_type_for_content_partner
              ? deliveryData?.deal_type_for_content_partner
              : null,
          deal_split_with_content_partner:
            deliveryData?.deal_split_with_content_partner
              ? deliveryData?.deal_split_with_content_partner
              : null,
          content_partner_primary: deliveryData?.content_partner_primary
            ? deliveryData?.content_partner_primary
            : null,
          content_partner_backfill: deliveryData?.content_partner_backfill
            ? deliveryData?.content_partner_backfill
            : deliveryData?.content_partner_primary === "Amagi"
            ? "Content Partner"
            : deliveryData?.content_partner_primary === "Content Partner"
            ? "Amagi"
            : null,
          inventory_share_content_partner_primary_ad_tag:
            deliveryData.inventory_share_content_partner_primary_ad_tag
              ? deliveryData.inventory_share_content_partner_primary_ad_tag
              : null,
          inventory_share_content_partner_backfill_ad_tag:
            deliveryData.inventory_share_content_partner_backfill_ad_tag
              ? deliveryData.inventory_share_content_partner_backfill_ad_tag
              : null,
          deal_type_for_platform_partner:
            deliveryData?.deal_type_for_platform_partner
              ? deliveryData?.deal_type_for_platform_partner
              : null,
          deal_split_with_platform_partner:
            deliveryData?.deal_split_with_platform_partner
              ? deliveryData?.deal_split_with_platform_partner
              : null,
          platform_partner_primary: deliveryData?.platform_partner_primary
            ? deliveryData?.platform_partner_primary
            : null,
          platform_partner_backfill: deliveryData?.platform_partner_backfill
            ? deliveryData?.platform_partner_backfill
            : null,
          inventory_share_platform_primary_ad_tag:
            deliveryData.inventory_share_platform_primary_ad_tag
              ? deliveryData.inventory_share_platform_primary_ad_tag
              : null,
          inventory_share_platform_backfill_ad_tag:
            deliveryData.inventory_share_platform_backfill_ad_tag
              ? deliveryData.inventory_share_platform_backfill_ad_tag
              : null,
          deal_split_amagi_share: deliveryData?.deal_split_amagi_share
            ? deliveryData?.deal_split_amagi_share
            : null,
          amagi_share_primary: deliveryData?.amagi_share_primary
            ? deliveryData?.amagi_share_primary
            : null,
          amagi_share_backfill: deliveryData?.amagi_share_backfill
            ? deliveryData?.amagi_share_backfill
            : null,
          inventory_share_amagi_primary_ad_tag:
            deliveryData.inventory_share_amagi_primary_ad_tag
              ? deliveryData.inventory_share_amagi_primary_ad_tag
              : null,
          inventory_share_amagi_backfill_ad_tag:
            deliveryData.inventory_share_amagi_backfill_ad_tag
              ? deliveryData.inventory_share_amagi_backfill_ad_tag
              : null,
          revenue_share_monetised_by_primary:
            deliveryData.revenue_share_monetised_by_primary
              ? deliveryData.revenue_share_monetised_by_primary
              : null,
          revenue_share_monetised_by_backfill:
            deliveryData.revenue_share_monetised_by_backfill
              ? deliveryData.revenue_share_monetised_by_backfill
              : null,
          revenue_share_primary_ad_tag:
            deliveryData.revenue_share_primary_ad_tag
              ? deliveryData.revenue_share_primary_ad_tag
              : null,
          revenue_share_backfill_ad_tag:
            deliveryData.revenue_share_backfill_ad_tag
              ? deliveryData.revenue_share_backfill_ad_tag
              : null,
          cdn_fee_owner: deliveryData.cdn_fee_owner
            ? deliveryData.cdn_fee_owner
            : null,
          ssai_fee_owner: deliveryData.ssai_fee_owner
            ? deliveryData.ssai_fee_owner
            : null,
          connect_distribution_model: deliveryData.connect_distribution_model
            ? deliveryData.connect_distribution_model
            : null,
          content_partner_ad_sales_commission_percentage:
            deliveryData.content_partner_ad_sales_commission_percentage
              ? deliveryData.content_partner_ad_sales_commission_percentage
              : null,
          platform_ad_sales_commission_percentage:
            deliveryData.platform_ad_sales_commission_percentage
              ? deliveryData.platform_ad_sales_commission_percentage
              : null
        }}
        onSubmit={async ({
          content_partner_primary,
          content_partner_backfill,
          platform_partner_primary,
          platform_partner_backfill,
          amagi_share_primary,
          amagi_share_backfill,
          ...values
        }) => {
          if (
            (values.deal_split_amagi_share ||
              values.deal_split_with_content_partner ||
              values.deal_split_with_platform_partner) &&
            (values.deal_split_amagi_share || 0) +
              (values.deal_split_with_content_partner || 0) +
              (values.deal_split_with_platform_partner || 0) !==
              100
          ) {
            toast.error("Sum of Deal Split Shares should be equalled to 100%");
          } else {
            try {
              await update_delivery({
                variables: {
                  id: deliveryData?.id,
                  input: {
                    content_partner_primary:
                      values.deal_split_with_content_partner
                        ? content_partner_primary
                        : null,
                    platform_partner_primary:
                      values.deal_split_with_platform_partner
                        ? platform_partner_primary
                        : null,
                    amagi_share_primary: values.deal_split_amagi_share
                      ? amagi_share_primary
                      : null,
                    content_partner_backfill:
                      values.deal_split_with_content_partner
                        ? content_partner_backfill
                          ? content_partner_backfill
                          : content_partner_primary === "Amagi"
                          ? "Content Partner"
                          : content_partner_primary === "Content Partner"
                          ? "Amagi"
                          : content_partner_backfill
                        : null,
                    platform_partner_backfill:
                      values.deal_split_with_platform_partner
                        ? platform_partner_backfill
                          ? platform_partner_backfill
                          : platform_partner_primary === "Amagi"
                          ? "Platform Partner"
                          : platform_partner_primary === "Platform Partner"
                          ? "Amagi"
                          : platform_partner_backfill
                        : null,
                    amagi_share_backfill: values.deal_split_amagi_share
                      ? amagi_share_backfill
                      : // ? amagi_share_backfill
                        // : amagi_share_primary === "Content Partner"
                        // ? "Platform Partner"
                        // : amagi_share_primary === "Platform Partner"
                        // ? "Content Partner"
                        // : amagi_share_backfill
                        null,
                    ...values,
                  },
                },
              });
              toast.success("Updated successfully");
              close();
            } catch (e) {
              toast.error(`There was an error ${e}`);
              console.log(e);
            }
            // console.log(values);
          }

          console.log(values);
        }}
      >
        {({ values, errors }) => (
          <Form
            style={{ color: "#4E4E4E", fontSize: "14px", fontWeight: "500" }}
          >
            {isModal !== true ? (
              <>
                <div className="sticky top-14 z-10 text-base">
                  <div className="flex justify-between items-center sticky top-14 h-14 w-full bg-secondaryBg">
                    <div className="flex items-center">
                      <span
                        onClick={() => navigate(`/channels`)}
                        className="font-medium text-gray-600 cursor-pointer hover:text-blue-500 hover:underline"
                      >
                        All VOD Deliveries
                      </span>
                      <ChevronRightIcon className="h-5 w-5 mx-3 text-gray-400" />
                      {/* <span>{data.channel?.name}</span> */}
                      <span>{deliveryData?.id}</span>
                    </div>
                    <div className="flex justify-end">
                      <button
                        type="submit"
                        className="py-1 px-3 bg-primaryColor text-white rounded-sm"
                      >
                        Submit
                      </button>
                      <button
                        className="py-1 px-3 text-primaryColor"
                        onClick={() =>
                          navigate(`/deliveries/vod-deliveries/${deliveryId}`)
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mb-4 sticky top-28 z-20">
                  <SubTabs
                    customStyles=""
                    currentTab={channelTab}
                    tabList={[{ name: "Delivery" }]}
                    toggleTab={setChannelTab}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="mb-2 sticky bg-secondaryBg top-12 z-20 flex justify-between">
                  <div>
                    <SubTabs
                      customStyles=""
                      currentTab={channelTab}
                      tabList={[{ name: "Delivery" }]}
                      toggleTab={setChannelTab}
                    />
                  </div>
                  {CDPRole.includes("channel-edit") && (
                    <div>
                      <button
                        type="submit"
                        className="py-1 px-3 bg-primaryColor text-white rounded-sm"
                      >
                        Submit
                      </button>
                      <button
                        className="py-1 px-3 text-primaryColor"
                        onClick={() => close()}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}

            <div className="w-full">
              {channelTab === "Delivery" && (
                <VODDeliveryFieldsEditForm
                  values={values}
                  channelIds={channelIds}
                  platformIds={platformIds}
                  productLists={productLists}
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VODDeliveryEditForm;
