import { useField } from "formik";
import { ReactSelectType } from "../channels/channel-edit-form/business-fields-form";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { Fragment, useState } from "react";
import TableFilter from "../../common/table-filter";

const SearchSelect: React.FC<{
  inlinelabel: string;
  defaultValue: string | null;
  defFields: Array<ReactSelectType>;
  name: string;
}> = ({ inlinelabel, defaultValue, defFields, name }) => {
  const [search, setSearch] = useState<string | null>(null);
  const _field = useField(name);
  const field = _field[0];
  const { setValue } = _field[2];

  const debouncedValues = defFields.filter((field: ReactSelectType) => {
    if (!search) {
      return true;
    }
    return field.label.toLowerCase().includes(search.toLowerCase());
  });

  const def = defFields.find((option: ReactSelectType) => {
    return option.value === defaultValue;
  });

  console.log(field);

  return (
    <div style={{ padding: "5px 0px" }}>
      <label className="text-gray-400">{inlinelabel}</label>
      <Popover as="div" className="relative items-center max-w-sm">
        {({ open, close }) => (
          <>
            <Popover.Button className="rounded-full w-full focus:outline-none">
              <div
                placeholder={"Enter Text"}
                style={{ padding: "5.5px 0px" }}
                className="flex justify-between border-0 border-b text-left focus:ring-0 border-gray-300 outline-none bg-transparent w-full"
              >
                <span>{def?.label || "Please select"}</span>
                <span className="mr-3">
                  <ChevronDownIcon className="h-4 w-4" />
                </span>
              </div>
            </Popover.Button>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Popover.Panel className="origin-top-right text-left z-10 absolute right-0 min-w-full max-h-60 overflow-y-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                {() => (
                  <div className="">
                    <div className="p-3 sticky top-0 bg-white">
                      <TableFilter
                        filter={search}
                        setFilter={setSearch}
                        placeholder="Search"
                      />
                    </div>
                    {[
                      { label: "Please select", value: "Please select" },
                      ...debouncedValues,
                    ].map((inp: ReactSelectType, index: number) => {
                      return (
                        <div key={index} className="">
                          <div
                            className={`focus:ring-0 cursor-pointer hover:bg-blue-100 px-3 py-1 ${
                              inp.value === (field.value || "Please select") &&
                              "bg-blue-500 text-white hover:bg-blue-400"
                            }`}
                            onClick={() => {
                              console.log(inp);
                              close();
                              setValue(
                                inp.label === "Please select" ? null : inp.value
                              );
                            }}
                          >
                            {inp.label}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default SearchSelect;
