import React, {
  ChangeEvent,
  Fragment,
  useCallback,
  useMemo,
  useState,
} from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { Column, useTable, useGlobalFilter, usePagination, useSortBy, useFilters, ColumnGroup, UsePaginationInstanceProps, UseSortByInstanceProps, UsePaginationState, TableInstance, Row } from "react-table";
import DateRangePickerComp from "./date-range-picker";
import TableFilter from "./table-filter";
import ExportCSV from "../components/exportComponents/exportCSV";
import { ExportHeaderType } from "./sub-table";
import DelayedToolTip from "../components/delayed-tooltip";
import PopoverMenu from "../components/popover-menu";
import { Toaster } from "react-hot-toast";
import { tableCellCheckboxStyle, tableExcludeCellStyle, tableExcludeHeaderStyle, tableHeaderStyle } from "../components/utils";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { useDebounce } from "use-debounce";
import { Popover, Transition } from "@headlessui/react";

export type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
  };

const PageTable: React.FC<{
  columns: Column<ColumnGroup>[];
  rowData: Array<any>;
  header?: string;
  exportHeaders?: Array<ExportHeaderType>;
  placeholder?: string;
  fileName?: string;
  isExternal?: boolean;
  toggleView?: string;
  isChannel?: boolean;
  setToggleView?: React.Dispatch<React.SetStateAction<string>>;
  search: string;
  height?: number;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  hideColumns?: string[];
  warning?: string;
  fromDate?: string;
  toDate?: string;
  setFromDate: React.Dispatch<React.SetStateAction<string>> | null; // Add setFromDate here
  setToDate: React.Dispatch<React.SetStateAction<string>> | null; 
  showColumnMenu?: boolean;
  isSKU?: boolean
  
}> = ({
  columns,
  rowData,
  header = "",
  placeholder = "Search",
  isChannel = false,
  exportHeaders = [],
  fileName = "",
  height = 260,
  isExternal = false,
  toggleView,
  setToggleView,
  search,
  setSearch,
  hideColumns = [],
  warning,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  showColumnMenu = true,
  isSKU = false
  
}) => {

  const [filteredRows, setFilteredRows] = useState<Array<any>>([]);
  const [checked, setChecked] = useState<Array<string>>([]);
  const [columnSearch, setColumnSearch] = useState<string>("");
  const [debouncedColumnSearch] = useDebounce(columnSearch, 100);
  const [channelStatus, setChannelStatus] = useState<Array<string>>([
    "Ordered and Active",
  ]);
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);

  const handleChannelStatus = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const t = e.target.name;
      if (channelStatus.length === 1 && channelStatus.includes(t)) {
        setChannelStatus([]);
      } else if (channelStatus && channelStatus.includes(t)) {
        setChannelStatus((prevChecked) => {
          return prevChecked.filter((v) => v !== t);
        });
      } else {
        setChannelStatus((prevChecked) => [...prevChecked, t]);
      }
    },
    [channelStatus]
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      multiSelect: (rows: Row<any>[], id: string | any, filterValues: any) => {
        if (filterValues.length === 0) {
          setFilteredRows([]);
          return [];
        } else {
          setFilteredRows(
            rows.filter((r) => filterValues.includes(r.values[id]))
          );
          return rows.filter((r) => filterValues.includes(r.values[id]));
        }
      },
    }),
    []
  );

  console.log(channelStatus);

  const data = useMemo(
    () =>
      isChannel
        ? rowData.filter((chn) => channelStatus.includes(chn.ordered_channel!))
        : rowData,
    [channelStatus,isChannel, rowData]
  );

  const totalcount = data.length
  const activeCount = data.filter((rep) => rep.active === 'Yes').length;
  const inactiveCount = data.filter((rep) => rep.active === 'No').length;
  const deprecatedCount = data.filter((rep) => rep.deprecated === 'Yes').length;

  function renderInfoIcon() {
    return (
      <svg
        width="13.33"
        height="13.33"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="text-black"
        style={{
          width: "25.33px",
          height: "13.33px",
          top: "1.33px",
          left: "4.33px",
        }}
      >
        <circle
          cx="12"
          cy="12"
          r="10"
          fill="rgba(0,0,0,0)"
          stroke="black"
          strokeWidth="2"
        />
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dy=".3em"
          fontSize="14"
          fill="black"
        >
          i
        </text>
      </svg>
    );
  }
  
  const cardStyle = {
    width: "340px",
    height: "124px",
    padding: "24px",
    gap: "8px",
    margin: "0 2px 4px 0",
    border: "1px solid #e0e0e0",
  };
  
  function renderCard(title: string, count: number | undefined) {
    return (
      <div
        className="w-full bg-white p-4 border border-gray-100 rounded-md text-center"
        style={cardStyle}
        key={title}
      >
        <div>
          <div className="font-bold mb-2 flex items-center justify-center relative">
            {title}
            <div className="icon-container">
              <div className="info-icon" title={title}>
                {renderInfoIcon()}
              </div>
            </div>
          </div>
          <div className="text-2xl font-bold text-gray-800">
            {count}
          </div>
        </div>
      </div>
    );
  }

 console.log(data.length,"is the data length")
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    gotoPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: 100,
        hiddenColumns: hideColumns,
        sortBy: [
          { id: "name", desc: false },
          { id: "customer_name", desc: false },
          // { id: "platform_name", desc: false },
          // { id: "channel_name", desc: false },
        ],
      },
      autoResetGlobalFilter: false,
      autoResetPage: true,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as TableInstanceWithHooks<object>;

  const handleSelectRows = (id: string) => {
    if (checked.length === 1 && checked.includes(id)) {
      setSelectedRows([]);
      setChecked([]);
    } else if (checked && checked.includes(id)) {
      setSelectedRows(
        selectedRows!.filter((row, index) => index !== parseInt(id))
      );
      setChecked((prevChecked) => {
        return prevChecked.filter((v) => v !== id);
      });
    } else {
      setSelectedRows((selRows) => [...selRows, data![parseInt(id)]]);
      setChecked((prevChecked) => [...prevChecked, id]);
    }
  };

  const handleSelectAllRows = () => {
    if (checked.length === rows.length) {
      setChecked([]);
      setSelectedRows([]);
    } else {
      if (filteredRows.length > 0) {
        setChecked(filteredRows.map((row) => row.id));
        setSelectedRows(
          filteredRows!.map((fRows) => {
            let temp = data!.find(
              (data, index) => index === parseInt(fRows.id)
            );
            return temp;
          })
        );
      } else {
        setChecked(rows.map((row) => row.id));
        setSelectedRows(
          rows!.map((fRows) => {
            let temp = data!.find(
              (data, index) => index === parseInt(fRows.id)
            );
            return temp;
          })
        );
      }
    }
  };

  const downloadData = useMemo(() => {
    let res = [];
    res = exportHeaders.filter((el) => {
      return (
        headerGroups &&
        headerGroups.length > 0 &&
        headerGroups[0].headers &&
        headerGroups[0].headers.find((element) => {
          return element.id === el.key;
        })
      );
    });
    return res;
  }, [exportHeaders, headerGroups]);

  const debouncedColumns = [
    "Ordered and Active",
    "Ordered and Churned",
    "Opportunity / Quote Created",
    "No Active Deal",
  ].filter((opt) => {
    if (!debouncedColumnSearch) {
      return true;
    }
    return opt.toLowerCase().includes(debouncedColumnSearch.toLowerCase());
  });

  const channelCount = isChannel
    ? [
        rowData.filter((chn) => chn.ordered_channel!.includes("No Active Deal"))
          .length,
        rowData.filter((chn) =>
          chn.ordered_channel!.includes("Opportunity / Quote Created")
        ).length,
        rowData.filter((chn) =>
          chn.ordered_channel!.includes("Ordered and Active")
        ).length,
        rowData.filter((chn) =>
          chn.ordered_channel!.includes("Ordered and Churned")
        ).length,
      ]
    : [];

  return (
    <>
      <Toaster />
      <div className={`ml-${isSKU ? '4' : '0'}`}>
          {isSKU && (
          <div className="flex flex-wrap gap-2">
            {renderCard("Total", totalcount)}
            {renderCard("Active", activeCount)}
            {renderCard("Inactive",inactiveCount)}
            {renderCard("Deprecated",deprecatedCount)}
          </div>)}
      </div>
        <div>
        <div className={`w-full flex-col justify-between items-center sticky w-full mb-4 bg-secondaryBg ${isSKU ? 'ml-4' : ''}`}>
          <div className="flex items-center w-1/3 g-0 ml-2"> 
            <div className="text-lg font-medium">
              {header ? `${header}` : ''}
            </div>
            <div className="ml-4">
              <DateRangePickerComp
                fromDate={fromDate}
                toDate={toDate}
                setFromDate={setFromDate}
                setToDate={setToDate}
              />
            </div>
            {isExternal && (
              <div className="flex items-center ml-3 w-3/12 cursor-pointer">
                <DelayedToolTip title="list view" styles="w-full">
                  <div
                    onClick={() => setToggleView && setToggleView("list")}
                    className={`p-2 w-full h-8 flex items-center justify-center rounded-sm ${
                      toggleView === "list"
                        ? "border-2 border-primaryColor"
                        : "border-2 border-r-0 border-gray-300"
                    }`}
                  >
                    <img src="/assets/channel-list.svg" alt="channel-list" />
                  </div>
                </DelayedToolTip>
                <DelayedToolTip title="grid view" styles="w-full">
                  <div
                    onClick={() => setToggleView && setToggleView("grid")}
                    className={`p-2 w-full h-8 flex items-center justify-center rounded-sm ${
                      toggleView === "grid"
                        ? "border-2 border-primaryColor"
                        : "border-2 border-l-0 border-gray-300"
                    }`}
                  >
                    <img src="/assets/channel-grid.svg" alt="channel-grid" />
                  </div>
                </DelayedToolTip>
              </div>
            )}
            </div>
            </div>
            <div className={`bg-[#FFFFFF] rounded-t border-t border-b-0 border-gray-200  ${isSKU ? 'ml-4' : ''}`} 
            style={{width: isSKU? "98.5%":"99%"}}>
            <div className="flex mt-2" style={{ height: "45px" , justifyContent: "space-between"}}>
            <div className="item-center ml-4 w-2/5" style={{alignSelf: 'center'}}>
            {rows.length} records
            </div>
            <div className="flex w-2/5 mr-5 items-center pr-4" style={{alignContent: "end"}}> 
            {isChannel && (
              <PopoverMenu
                icon={
                  channelStatus.length > 0 ? "filter-fill.svg" : "filter.svg"
                }
                styles={`right-6`}
              >
                <div className="py-2">
                  <TableFilter
                    filter={columnSearch}
                    setFilter={setColumnSearch}
                    placeholder="Search"
                  />
                </div>
                <button
                  className="text-blue-500 text-sm font-medium py-1 hover:underline"
                  onClick={() => {
                    setChannelStatus([]);
                  }}
                >
                  Remove filters
                </button>
                {debouncedColumns.sort().map((sts, index) => (
                  <div className="py-px" key={index}>
                    <input
                      type="checkbox"
                      className="focus:ring-0"
                      id={sts}
                      name={sts}
                      onChange={(e) => handleChannelStatus(e)}
                      title={sts}
                      checked={channelStatus.includes(sts)}
                    />
                    <label htmlFor={sts} className="pl-2 text-sm">
                      {sts} ({channelCount[index]})
                    </label>
                  </div>
                ))}
              </PopoverMenu>
            )}
            <div className={`${isExternal ? "w-4/5" : "w-full"}`}>
              <TableFilter
                filter={search}
                setFilter={setSearch}
                placeholder={placeholder}
              />
            </div>
          {showColumnMenu && (
            <div className="p-1.5 bg-primaryColor rounded ml-3">
              <DelayedToolTip title="select column" position="top">
                <PopoverMenu
                  icon="column-filter-white.svg"
                  header="Manage Columns"
                  positionStyles="right-6"
                  position="relative"
                >
                  <div style={{ fontSize: "12px" }} className="z-20 py-1">
                    <div className="py-1">
                      <input
                        id="select-all-column"
                        className="focus:ring-0"
                        type="checkbox"
                        {...getToggleHideAllColumnsProps()}
                      />
                      <label htmlFor="select-all-column" className="pl-2">
                        Toggle All
                      </label>
                    </div>
                    {allColumns.map((column: any) => (
                      <div key={column.id} className="py-1">
                        <input
                          id={column.id}
                          className="focus:ring-0"
                          type="checkbox"
                          {...column.getToggleHiddenProps()}
                        />
                        <label htmlFor={column.id} className="pl-2">
                          {column.Header}
                        </label>
                      </div>
                    ))}
                  </div>
                </PopoverMenu>
              </DelayedToolTip>
            </div>
            )}
            <div className="ml-2 w-1/12">
              <DelayedToolTip title="Download selected columns">
                <ExportCSV
                  csvData={selectedRows || []}
                  csvHeaders={downloadData}
                  fileName={fileName}
                  warning={warning}
                />
              </DelayedToolTip>
            </div>
            </div>
        </div>
        </div>
        </div>
        <div style={{ width: "100%"}}>
          <div
            style={{
              maxWidth:"100%",
              height: height,
              overflow: "scroll",
              marginLeft: isSKU ? "1rem" : "0", 
              
            }}
            className="rounded-t border border-b-0 bg-lightPrimary/50"
          >
            <table {...getTableProps()} className="rounded-t">
              <thead
                className="bg-white border-b shadow-sm"
                style={{
                  position: "sticky",
                  insetBlockStart: 0,
                }}
              >
                {headerGroups.map((headerGroup: any, index: number) => (
                  <tr key={index} {...headerGroup.getHeaderGroupProps()}
                style={{
                  background: index === 0 ? "#E7E7E7" : "inherit"
                }}
              >
                    <th
                      scope="col"
                      className="text-left font-medium w-10"
                      style={tableHeaderStyle}
                    >
                      <span>
                        <input
                          type="checkbox"
                          className="focus:ring-0"
                          onChange={handleSelectAllRows}
                          checked={
                            filteredRows.length === 0
                              ? checked.length === rows.length
                              : checked.length === filteredRows.length
                          }
                        />
                      </span>
                    </th>
                    {headerGroup.headers.map((column: any, hIndex: number) => {
                      return (
                        <>
                          {column.show ? (
                            <th
                              key={hIndex}
                              scope="col"
                              className={`text-left text-sm text-gray-500 ${
                                column.render("Header") === "DELIVERY" && "w-24"
                              }`}
                              style={tableExcludeHeaderStyle}
                            >
                              <div className="flex items-center">
                                <span
                                  className={`text-center ${
                                    column.isSorted && "text-primaryColor"
                                  }`}
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps()
                                  )}
                                >
                                  {column.render("Header")}
                                </span>
                                <span
                                  className="mt-px ml-1"
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps()
                                  )}
                                >
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <ChevronDownIcon className="h-4 w-4 text-primaryColor" />
                                    ) : (
                                      <ChevronUpIcon className="h-4 w-4 text-primaryColor" />
                                    )
                                  ) : null}
                                </span>
                                {column.filter ? (
                                  <div className="ml-1">
                                    <DelayedToolTip
                                      title="filter"
                                      position="bottom-end"
                                    >
                                      {column.canFilter
                                        ? column.render("Filter")
                                        : null}
                                    </DelayedToolTip>
                                  </div>
                                ) : null}
                              </div>
                            </th>
                          ) : null}
                        </>
                      );
                    })}
                  </tr>
                ))}
              </thead>

              <tbody
                {...getTableBodyProps()}
                className="divide-y divide-gray-200 bg-white"
              >
                {page.map((row: any) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      <td
                        className="text-left w-10"
                        style={tableCellCheckboxStyle}
                      >
                        <span>
                          <input
                            type="checkbox"
                            className="focus:ring-0"
                            onChange={() => handleSelectRows(row.id)}
                            checked={checked.includes(row.id)}
                          />
                        </span>
                      </td>
                      {row.cells.map((cell: any) => {
                        return (
                          <>
                            {cell.column.show ? (
                              <td
                                {...cell.getCellProps()}
                                className={`text-left text-sm whitespace-nowrap py-2 font-medium text-gray-900 ${
                                  cell.render("Header") === "DELIVERY" && "w-24"
                                }`}
                                style={tableExcludeCellStyle}
                              >
                                {cell.render("Cell")}
                              </td>
                            ) : null}
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div
            className= 'bg-white px-4 py-3 flex items-center justify-between border border-gray-200 sm:px-6'
            style={{ width: isSKU ? "99%":"100%", marginLeft: isSKU ? "1rem" : "0" }}
          >
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">
                    {pageIndex * pageSize + 1}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium">
                    {page.length < pageSize
                      ? rows.length
                      : (pageIndex + 1) * page.length}
                  </span>{" "}
                  of <span className="font-medium">{rows.length}</span> results
                </p>
              </div>
              <div className="flex sm:items-center sm:justify-between">
                <span className="mr-3 text-sm text-gray-700">
                  Rows per page
                </span>
                <Popover as="div" className="relative items-center max-w-sm">
                  {({ open, close }) => (
                    <>
                      <Popover.Button className="rounded-full w-full focus:outline-none">
                        <div
                          placeholder={"Enter Text"}
                          className="flex justify-between border rounded px-3 py-1.5 items-center text-left focus:ring-0 border-gray-300 outline-none bg-transparent w-full"
                        >
                          <span>{pageSize}</span>
                          <span className="ml-2">
                            <ChevronDownIcon className="h-4 w-4" />
                          </span>
                        </div>
                      </Popover.Button>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Popover.Panel className="origin-top-right text-left z-10 absolute bottom-0 right-0 min-w-full max-h-60 overflow-y-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {() => (
                            <div className="">
                              {[10, 20, 30, 40, 50, 100].map((inp, rIndex) => {
                                return (
                                  <div key={rIndex}>
                                    <div
                                      className={`focus:ring-0 cursor-pointer hover:bg-blue-100 px-5 py-1 ${
                                        pageSize === inp ? "bg-blue-100" : ""
                                      }`}
                                      onClick={() => {
                                        close();
                                        setPageSize(Number(inp));
                                      }}
                                    >
                                      {inp}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </div>
              <div>
                <nav
                  className="relative z-0 inline-flex rounded-md -space-x-px"
                  aria-label="Pagination"
                >
                  {canPreviousPage && (
                    <DelayedToolTip title="Previous page" position="top">
                      <button
                        onClick={() => previousPage()}
                        className={`relative inline-flex items-center px-2 py-2 rounded-l-md bg-white text-sm font-medium text-primaryColor`}
                      >
                        <span className="sr-only">Previous</span>
                        <ChevronLeftIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>
                    </DelayedToolTip>
                  )}
                  {pageOptions
                    .slice(
                      pageIndex >= 1 ? pageIndex - 1 : pageIndex,
                      pageIndex >= 1 ? pageIndex + 2 : pageIndex + 3
                    )
                    .map((page: number) => (
                      <button
                        key={page}
                        onClick={() => gotoPage(page)}
                        className={`z-10 m-0 ${
                          page === pageIndex
                            ? "text-gray-200"
                            : "bg-white border-gray-300 border-l-0 text-primaryColor hover:underline"
                        } ${
                          page < pageIndex ? "border-r-0" : ""
                        } relative inline-flex items-center px-4 py-2 text-sm font-medium`}
                      >
                        {page + 1}
                      </button>
                    ))}
                  {canNextPage && (
                    <DelayedToolTip title="Next page" position="top">
                      <button
                        onClick={() => nextPage()}
                        className={`relative inline-flex items-center px-2 py-2 rounded-r-md bg-white text-sm font-medium text-primaryColor`}
                      >
                        <span className="sr-only">Next</span>
                        <ChevronRightIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>
                    </DelayedToolTip>
                  )}
                </nav>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default PageTable;