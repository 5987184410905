import React from "react";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useFilters,
  useExpanded,
} from "react-table";
import { TableInstanceWithHooks } from "./custom-table";
import TableFilter from "./table-filter";
// import { ReactDatePicker } from "@kaos-agency/amagi-react-design-system";
// import CustomDatePicker from "../components/custom-date-picker";
import { contentWidth } from "../components/utils";

const ProductTableContainer: React.FC<any> = ({
  columns,
  data,
  search,
  setSearch,
  handleDateRange,
  dateRange,
}) => {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: { pageIndex: 0, pageSize: 50, hiddenColumns: [] },
        autoResetHiddenColumns: false,
        autoResetResize: false,
        autoResetGlobalFilter: true,
        autoResetExpanded: false,
        autoResetGroupBy: false,
        autoResetSelectedRows: false,
        autoResetSortBy: false,
        autoResetFilters: false,
        autoResetRowState: false,
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      useExpanded,
      usePagination
    ) as TableInstanceWithHooks<object>;

  return (
    <div className="table-container bg-white flex flex-col rounded">
      <div
        className="custom_container rounded-t p-2 flex justify-between items-center border-b bg-white"
        style={{
          width: contentWidth,
        }}
      >
        <div className="w-1/3 px-3 flex items-center font-medium">
          Delivery-wise Product Revenue
        </div>
        <div className="w-2/3 flex justify-end items-center">
          <div className="flex justify-end items-center mr-3">
            {/* <div>
              <div className="text-sm font-medium text-gray-500">From Date</div>
              <ReactDatePicker
                selected={new Date()}
                onChange={(date) => console.log(date)}
              />
            </div>
            <div className="ml-3">
              <div className="text-sm font-medium text-gray-500">To Date</div>
              <ReactDatePicker
                onChange={(date) => console.log(date)}
                selected={new Date()}
              />
            </div>
            <div className="ml-5">
              <button
                onClick={() => console.log("click")}
                className="bg-primaryColor text-white px-3 py-1.5 rounded text-sm font-medium"
              >
                Search
              </button>
            </div> */}
            {/* <CustomDatePicker
              handleDateRange={handleDateRange}
              fromDate={dateRange.from_date}
              toDate={dateRange.to_date}
            /> */}
          </div>
          <div className="w-2/5">
            <TableFilter
              filter={search}
              setFilter={setSearch}
              placeholder="Search by channel name"
            />
          </div>
        </div>
      </div>
      <div
        style={{
          maxWidth: contentWidth,
          maxHeight: `${window.innerHeight - 210}px`,
          overflow: "scroll",
        }}
      >
        <table {...getTableProps()} className="border-seperate">
          <thead
            className="bg-white border-y"
            style={{
              position: "sticky",
              insetBlockStart: 0,
              zIndex: 10,
            }}
          >
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {/* <th className="border-b"></th> */}
                {headerGroup.headers.map((column: any, index) => {
                  return (
                    <th
                      {...column.getHeaderProps()}
                      key={index}
                      className={`border-b ${column.positionStyles} ${
                        column.id === "expander_placeholder_0"
                          ? "sticky left-0 bg-white"
                          : ""
                      }`}
                    >
                      <div
                        className={`flex w-max h-max ${
                          column.render("Header") === "Platforms"
                            ? "justify-start ml-6"
                            : "justify-center"
                        } items-start`}
                      >
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`${
                            cell.column.id.includes("platform")
                              ? "text-right pr-8"
                              : "text-left"
                          } ${cell.column.positionStyles} border-b`}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductTableContainer;
