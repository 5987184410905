import { Fragment } from "react";
import { useCheckSalesforceNetsuiteInvoiceQuery } from "../../hasura.graphql";
import NetsuitePDF from "./netsuite-pdf";
import GetSalesforceId from "./salesforce-pdf";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import Loader from "../../common/loader";

const CheckNetsuiteSalesforcePDF: React.FC<{
  transaction_id: string;
  setOpenModal: React.Dispatch<React.SetStateAction<string | null>>;
}> = ({ transaction_id, setOpenModal }) => {
  const { data, loading, error } = useCheckSalesforceNetsuiteInvoiceQuery({
    variables: {
      transaction_id,
    },
  });

  if (loading)
    return (
      <Transition appear show={transaction_id ? true : false} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setOpenModal(null)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div
            className="fixed inset-0 overflow-y-auto"
            style={{ zIndex: 999 }}
          >
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div>
                    <div className="flex justify-between items-center mb-3">
                      <div className="font-semibold text-lg">
                        {transaction_id}
                      </div>
                      <span
                        className="cursor-pointer"
                        onClick={() => setOpenModal(null)}
                      >
                        <XIcon className="h-5 w-5 text-gray-500" />
                      </span>
                    </div>
                    <Loader />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  if (error)
    return (
      <Transition appear show={transaction_id ? true : false} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setOpenModal(null)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div
            className="fixed inset-0 overflow-y-auto"
            style={{ zIndex: 999 }}
          >
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div>
                    <div className="flex justify-between items-center mb-3">
                      <div className="font-semibold text-lg">
                        {transaction_id}
                      </div>
                      <span
                        className="cursor-pointer"
                        onClick={() => setOpenModal(null)}
                      >
                        <XIcon className="h-5 w-5 text-gray-500" />
                      </span>
                    </div>
                    <div>No Documents for {transaction_id}</div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );

  if (!data)
    return (
      <Transition appear show={transaction_id ? true : false} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setOpenModal(null)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div
            className="fixed inset-0 overflow-y-auto"
            style={{ zIndex: 999 }}
          >
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div>
                    <div className="flex justify-between items-center mb-3">
                      <div className="font-semibold text-lg">
                        {transaction_id}
                      </div>
                      <span
                        className="cursor-pointer"
                        onClick={() => setOpenModal(null)}
                      >
                        <XIcon className="h-5 w-5 text-gray-500" />
                      </span>
                    </div>
                    <div>No Documents for {transaction_id}</div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );

  const pdfSource = data?.sf_ns_check[0].check;

  console.log(pdfSource);

  return (
    <>
      <Transition appear show={transaction_id ? true : false} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setOpenModal(null)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto z-50">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="w-full h-full">
                    <div className="flex justify-between items-center mb-3">
                      <div className="font-semibold text-lg">
                        {transaction_id}
                      </div>
                      <span
                        className="cursor-pointer"
                        onClick={() => setOpenModal(null)}
                      >
                        <XIcon className="h-5 w-5 text-gray-500" />
                      </span>
                    </div>
                    {pdfSource === "netsuite" ? (
                      <NetsuitePDF transaction_id={transaction_id} />
                    ) : (
                      <GetSalesforceId transaction_id={transaction_id} />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { CheckNetsuiteSalesforcePDF };
