import { useState } from "react";
import {
  useCustomerGetSalesforceIDofInvoiceQuery,
  useCustomerSalesforcePdfQuery,
} from "../../hasura.graphql";
import Loader from "../../common/loader";
import NetsuitePDF from "./netsuite-pdf";
import { DocumentDownloadIcon } from "@heroicons/react/outline";
import axios from 'axios';

const GetSalesforceId: React.FC<{
  transaction_id: string;
}> = ({ transaction_id }) => {
  const { data, loading, error } = useCustomerGetSalesforceIDofInvoiceQuery({
    variables: {
      id: transaction_id,
    },
  });

  if (loading) return <Loader />;

  if (error) return <div>Error loading Salesforce data: {error.message}</div>;

  if (!data || !data.invoice || !data.invoice[0]?.salesforce_id) return <div>No Documents for {transaction_id}</div>;

  const salesforceId = data.invoice[0].salesforce_id;

  return (
    <SalesforcePDF
      transaction_id={transaction_id}
      salesforce_id={salesforceId}
    />
  );
};

const SalesforcePDF: React.FC<{
  transaction_id: string;
  salesforce_id: string | undefined;
}> = ({ transaction_id, salesforce_id }) => {
  const [localUrl, setLocalUrl] = useState<string | null>(null);

  const { loading, error } = useCustomerSalesforcePdfQuery({
    variables: {
      id: salesforce_id || '',
    },
    onCompleted: async (data) => {
      const documentBase64 =
        data.invoice[0]?.invoices?.records &&
        data.invoice[0]?.invoices?.records.length > 0
          ? data.invoice[0]?.invoices?.records[0]?.document.document_base64 || ''
          : "";
      try {
        const response = await axios.get(documentBase64);
        createLocalUrl(response.data);
      } catch (error) {
        console.error("Error while fetching data", error);
        createLocalUrl("");
      }
    },
  });

  if (loading) return <Loader />;
  if (error) return <div>Error loading Salesforce PDF: {error.message}</div>;

  function createLocalUrl(base64: string) {
    try {
      setLocalUrl(base64);
    } catch (error) {
      console.error("Error setting base64 data for the PDF:", error);
    }
  }

  return (
    <>
      {localUrl ? (
        <div style={{ position: "relative" }}>
          <a
            href={`data:application/pdf;base64,${localUrl}`}
            download={`${transaction_id}.pdf`}
            style={{
              padding: "10px 20px",
              backgroundColor: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              position: "absolute",
              top: "-50px",
              right: "80px",
              textDecoration: "none",
            }}
          >
            <DocumentDownloadIcon className="w-5 h-5" /> Download PDF
          </a>
          <iframe
            title={transaction_id || ''}
            className="w-full"
            style={{ height: "80vh" }}
            src={`data:application/pdf;base64,${localUrl}#toolbar=0&zoom=100`}
          />
        </div>
      ) : (
        <NetsuitePDF transaction_id={transaction_id} />
      )}
    </>
  );
};

export default GetSalesforceId;
