/* eslint-disable react-hooks/rules-of-hooks */
import DQChart from "../charts/dq-chart";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import Loader from "../../common/loader";
import {
  DataQualityTrendsQuery,
  useDataQualityTrendsQuery,
} from "../../hasura.graphql";
import DQProgressBars from "./dq-progressbars";
import { months } from "../../constants";
import DQPercentageChart from "../charts/dq-percentage-chart";
import { DateSort } from "../utils";

const DataQuality: React.FC = () => {
  const [timePeriod, setTimePeriod] = useState<number>(6);
  const [dataQuality, setDataQuality] = useState<DataQualityTrendsQuery>();

  const data_quality_chart = [
    ...(dataQuality?.data_quality_trend || []),
    ...(dataQuality?.data_growth_trend || []),
  ];

  const chart_label = Array.from(
    new Set(data_quality_chart.map((a) => a.date))
  );

  chart_label.sort((a, b) => DateSort(a, b));

  const data_growth_chart_data = useMemo(() => {
    return chart_label.map((label: string) => {
      let growth = dataQuality?.data_growth_trend?.find(
        (s) => s.date === label
      );
      if (!growth) {
        return {
          date: null,
          rows: null,
        };
      }
      return {
        date: growth.date,
        rows: growth.total_rows,
      };
    });
  }, [chart_label, dataQuality?.data_growth_trend]);

  // const accuracy_chart_data = useMemo(() => {
  //   return chart_label.map((label: string) => {
  //     let accuracy = dataQuality?.data_quality_trend?.find(
  //       (s) => s.date === label
  //     );
  //     if (!accuracy) {
  //       return {
  //         date: null,
  //         percent: null,
  //       };
  //     }
  //     return {
  //       date: accuracy.date,
  //       percent: accuracy.accuracy,
  //     };
  //   });
  // }, [chart_label, dataQuality?.data_quality_trend]);

  const completeness_chart_data = useMemo(() => {
    return chart_label.map((label: string) => {
      let completeness = dataQuality?.data_quality_trend?.find(
        (s) => s.date === label
      );
      if (!completeness) {
        return {
          date: null,
          percent: null,
        };
      }
      return {
        date: completeness.date,
        percent: completeness.completeness,
      };
    });
  }, [chart_label, dataQuality?.data_quality_trend]);

  const { data, loading, error } = useDataQualityTrendsQuery({
    variables: {
      from_date: DateTime.local()
        .startOf("day")
        .minus({ months: timePeriod })
        .toISO()
        .slice(0, 10),
      to_date: DateTime.local().toISO().slice(0, 10),
    },
    onCompleted: (data) => {
      setDataQuality(data);
    },
  });

  if (loading) {
    return (
      <>
        <div className="flex mb-8 cursor-pointer rounded justify-end">
          {[1, 6, 12].map((period, index) => {
            return (
              <div
                key={index}
                className={`border text-xs ${
                  timePeriod === period && "font-medium bg-white"
                } w-max px-2 py-1`}
                onClick={() => setTimePeriod(period)}
              >
                {`Last ${period} month`}
              </div>
            );
          })}
        </div>
        <Loader />
        <div className="mt-4">
          <DQProgressBars />
        </div>
      </>
    );
  }

  if (error) {
    return <i>Error</i>;
  }

  if (!data) {
    return <i>Data Quality Trend not found</i>;
  }

  const calculateDataGrowth = (prev: number, current: number) => {
    let diff = Math.abs(current - prev);
    return Math.round((diff / prev) * 100);
  };

  return (
    <div>
      <div className="flex mb-5 cursor-pointer rounded justify-end">
        {[1, 6, 12].map((period, index) => {
          return (
            <div
              key={index}
              className={`border text-xs ${
                timePeriod === period && "font-medium bg-white"
              } w-max px-2 py-1`}
              onClick={() => setTimePeriod(period)}
            >
              {`Last ${period} month`}
            </div>
          );
        })}
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div
          className="bg-white rounded-md pb-4 flex flex-col justify-center"
          style={{ border: "1px solid #E0E0E0" }}
        >
          <div className="flex justify-between border-b border-gray-300">
            <div className="text-left pl-4 py-2 font-medium">Data Growth</div>
          </div>
          <div className="p-2 mt-2">
            <div className="flex justify-end items-center mx-2">
              <div
                style={{ width: 6, height: 6, background: "#6d7615" }}
                className="rounded-full"
              ></div>
              <div className="mx-2 text-xs text-gray-600">Data Growth</div>
              {data_growth_chart_data.length > 1 && (
                <>
                  <div className="text-sm font-medium">{`${calculateDataGrowth(
                    data_growth_chart_data[data_growth_chart_data.length - 2]
                      .rows,
                    data_growth_chart_data[data_growth_chart_data.length - 1]
                      .rows
                  )}%`}</div>
                  <div className="ml-2">
                    {data_growth_chart_data[data_growth_chart_data.length - 1]
                      .rows >
                    data_growth_chart_data[data_growth_chart_data.length - 2]
                      .rows ? (
                      <img src="/assets/increase.svg" alt="increase" />
                    ) : (
                      <img src="/assets/decrease.svg" alt="decrease" />
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="h-48">
              <DQChart
                chartData={{
                  labels: chart_label.map(
                    (label) =>
                      `${label.slice(8, 10)} ${
                        months[parseInt(label.slice(5)) - 1]
                      }`
                  ),
                  datasets: [
                    {
                      type: "line" as const,
                      label: "Data Growth",
                      data: data_growth_chart_data.map((data) => data.rows),
                      borderColor: ["#6d7615"],
                      borderWidth: 1,
                      tension: 0.5,
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
        {/* <div
          className="bg-white rounded-md pb-4 flex flex-col justify-center"
          style={{ border: "1px solid #E0E0E0" }}
        >
          <div className="flex justify-between border-b border-gray-300">
            <div className="text-left pl-4 py-2 font-medium">Accuracy</div>
          </div>
          <div className="p-2 mt-2">
            <div className="flex justify-end items-center mx-2">
              <div
                style={{ width: 6, height: 6, background: "#715ec2" }}
                className="rounded-full"
              ></div>
              <div className="mx-2 text-xs text-gray-600">Accuracy</div>
              <div className="text-sm font-medium">{`${
                accuracy_chart_data.length > 0 &&
                Math.round(
                  accuracy_chart_data[accuracy_chart_data.length - 1].percent
                )
              }%`}</div>
            </div>
            <DQPercentageChart
              chartData={{
                labels: chart_label.map(
                  (label) =>
                    `${label.slice(8, 10)} ${
                      months[parseInt(label.slice(5)) - 1]
                    }`
                ),
                datasets: [
                  {
                    type: "line" as const,
                    label: "Accuracy",
                    data: accuracy_chart_data.map((data) => data.percent),
                    borderColor: ["#715ec2"],
                    borderWidth: 1,
                    tension: 0.5,
                  },
                ],
              }}
            />
          </div>
        </div> */}
        <div
          className="bg-white rounded-md pb-4 flex flex-col justify-center"
          style={{ border: "1px solid #E0E0E0" }}
        >
          <div className="flex justify-between border-b border-gray-300">
            <div className="text-left pl-4 py-2 font-medium">Completeness</div>
          </div>
          <div className="p-2 mt-2">
            <div className="flex justify-end items-center mx-2">
              <div
                style={{ width: 6, height: 6, background: "#b82b94" }}
                className="rounded-full"
              ></div>
              <div className="mx-2 text-xs text-gray-600">Completeness</div>
              <div className="text-sm font-medium">{`${
                completeness_chart_data.length > 0 &&
                Math.round(
                  completeness_chart_data[completeness_chart_data.length - 1]
                    .percent
                )
              }%`}</div>
            </div>
            <div className="h-48">
              <DQPercentageChart
                chartData={{
                  labels: chart_label.map(
                    (label) =>
                      `${label.slice(8, 10)} ${
                        months[parseInt(label.slice(5)) - 1]
                      }`
                  ),
                  datasets: [
                    {
                      type: "line" as const,
                      label: "Completeness",
                      data: completeness_chart_data.map((data) => data.percent),
                      borderColor: ["#b82b94"],
                      borderWidth: 1,
                      tension: 0.5,
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <DQProgressBars />
      </div>
    </div>
  );
};

export default DataQuality;
