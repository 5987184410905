import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useGetChannelsByIdAndNameQuery } from './hasura.graphql';


interface DeliveryData {
  id: string;
  logo?: string | null;
  name?: string;
  channels_grouped?: string | null;
  channel_type?: string | null;
}

const ChannelComponent: React.FC<{ name: string; id: string }> = ({ name, id }) => {
  const { data, loading, error } = useGetChannelsByIdAndNameQuery({
    variables: { id, name }
  });

  const [deliveryData, setDeliveryData] = useState<DeliveryData>();

  useEffect(() => {
    if (data?.channel) {
      setDeliveryData(data.channel[0]);
    }
  }, [data]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <div className="flex">
        {deliveryData?.id && (
          <div className="w-16 h-12">
            <LazyLoadImage
              className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
              src={
                deliveryData.logo
                  ? `/${deliveryData.logo}`
                  : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${deliveryData.name}`
              }
              alt={deliveryData.name}
            />
          </div>
        )}
        <div className="ml-3 flex flex-col">
          <a
            href={`/channels/${
              deliveryData?.channels_grouped !== null &&
              deliveryData?.channels_grouped !== "No"
                ? "grouped-channel"
                : deliveryData?.channel_type
                ? deliveryData?.channel_type.toLowerCase()
                : "linear"
            }/${deliveryData?.id}`}
            target="_blank"
            rel="noreferrer"
            className="hover:text-blue-500 hover:underline"
          >
            {deliveryData?.name || ""}
          </a>
          <span className="text-sm text-gray-500">{deliveryData?.id}</span>
        </div>
      </div>
    </div>
  );
};

const generateComponent = (name: string, id: string): JSX.Element => {
  return <ChannelComponent name={name} id={id} />;
};

export default generateComponent;
