import { useCDM } from "../../cdm-context";
import NestedToggleTableContainer from "../../common/nested-table-toggle-container";
import { ExportHeaderType } from "../../common/sub-table";
import { CustomerFdTicketsDataFragment } from "../../hasura.graphql";
import CountCard from "../cards/count-card";
import { compareHST, DateParser } from "../utils";
import { FDTicketType, StatusType } from "./types";

const ProductTicket: React.FC<{
  tickets: Array<CustomerFdTicketsDataFragment>;
  status: Array<StatusType>;
  source: Array<StatusType>;
  priority: Array<string>;
  columns: Array<any>;
  exportHeaders: Array<ExportHeaderType>;
}> = ({ tickets, status, source, priority, columns, exportHeaders }) => {
  const { show } = useCDM();
  const openTickets = tickets.filter((f) => f.status !== 5 && f.status !== 4);
  const resolvedTickets = tickets.filter((f) => f.status === 4);
  const closedTickets = tickets.filter((f) => f.status === 5);

  const high_standing_open_tickets = openTickets.slice().sort(compareHST);
  const high_standing_resolved_tickets = resolvedTickets
    .slice()
    .sort(compareHST);
  const high_standing_closed_tickets = closedTickets.slice().sort(compareHST);

  const all_tickets = [
    ...high_standing_open_tickets,
    ...high_standing_resolved_tickets,
    ...high_standing_closed_tickets,
  ];

  const filteredTickets: Array<FDTicketType> = all_tickets.map((ticket) => {
    return {
      ticket_id: ticket.ticket_id,
      subject: ticket.subject ? ticket.subject : "--",
      description: ticket.description ? ticket.description : "--",
      delivery_channel: ticket.delivery_channel
        ? ticket.delivery_channel
        : "--",
      impact: ticket.impact ? ticket.impact : "--",
      priority: priority[ticket.priority - 1],
      product_resp: ticket.product_resp ? ticket.product_resp : "--",
      product_resp_area: ticket.product_resp_area
        ? ticket.product_resp_area
        : "--",
      product_resp_area_component: ticket.product_resp_area_component
        ? ticket.product_resp_area_component
        : "--",
      ticket_type: ticket.ticket_type ? ticket.ticket_type : "--",
      status: status.find((st: any) => st.key === ticket.status)?.value || "--",
      created_date: ticket.created_date ? ticket.created_date : "--",
      closed_date:
        ticket.closed_at !== null &&
        status.find((st: any) => st.key === ticket.status)?.value === "Closed"
          ? ticket.closed_at
          : "--",
      tags: ticket.tags,
      root_cause: ticket.root_cause ? ticket.root_cause : "-",
      invoice_number: ticket.invoice_number
        ? ticket.invoice_number.split(", ")
        : [],
      invoice_month: ticket.invoice_month ? ticket.invoice_month : "--",
      invoice_amount: ticket.invoice_amount ? ticket.invoice_amount : "--",
      disputed_amount: ticket.disputed_amount ? ticket.disputed_amount : "--",
      charge_codes: ticket.charge_codes ? ticket.charge_codes : "--",
      region: ticket.region ? ticket.region : "--",
      coordinator: ticket.coordinator ? ticket.coordinator : "--",
      billing_issue_status: ticket.billing_issue_status
        ? ticket.billing_issue_status
        : "--",
      root_cause_category: ticket.root_cause_category
        ? ticket.root_cause_category
        : "--",
      root_cause_owner: ticket.root_cause_owner
        ? ticket.root_cause_owner
        : "--",
      group: ticket.group ? ticket.group : "--",
      group_agent: ticket.group_agent ? ticket.group_agent : "--",
      customer_id: ticket.customer_id ? ticket.customer_id : "--",
      customer_name: ticket.customer_name ? ticket.customer_name : "--",
      source: source.find((st: any) => st.key === ticket.source)?.value || "--",
      last_updated_date: ticket.last_updated_date
        ? ticket.last_updated_date
        : "--",
      dispute_type: ticket.dispute_type ? ticket.dispute_type : "--",
      dispute_outcome: ticket.dispute_outcome ? ticket.dispute_outcome : "--",
    };
  });

  const exportTickets = filteredTickets.map((ticket) => {
    return {
      ticket_id: ticket.ticket_id,
      subject: ticket.subject,
      description: ticket.description,
      delivery_channel: ticket.delivery_channel,
      impact: ticket.impact,
      priority: ticket.priority,
      product_resp: ticket.product_resp,
      product_resp_area: ticket.product_resp_area,
      product_resp_area_component: ticket.product_resp_area_component,
      ticket_type: ticket.ticket_type,
      status: ticket.status,
      created_date:
        ticket.created_date !== "--" ? DateParser(ticket.created_date) : "--",
      closed_date:
        ticket.closed_date !== "--" ? DateParser(ticket.closed_date) : "--",
      tags: ticket.tags,
      root_cause: ticket.root_cause,
      invoice_number: ticket.invoice_number,
      invoice_month: ticket.invoice_month,
      invoice_amount: ticket.invoice_amount,
      disputed_amount: ticket.disputed_amount,
      charge_codes: ticket.charge_codes,
      region: ticket.region,
      coordinator: ticket.coordinator,
      billing_issue_status: ticket.billing_issue_status,
      root_cause_category: ticket.root_cause_category,
      root_cause_owner: ticket.root_cause_owner,
      group: ticket.group,
      group_agent: ticket.group_agent,
      customer_id: ticket.customer_id,
      customer_name: ticket.customer_name,
      source: ticket.source,
      last_updated_date:
        ticket.last_updated_date !== "--"
          ? DateParser(ticket.last_updated_date)
          : "--",
      dispute_type: ticket.dispute_type,
      dispute_outcome: ticket.dispute_outcome,
    };
  });

  return (
    <div>
      <div className="my-4 grid grid-cols-4 gap-4">
        <CountCard
          count={tickets.length}
          cardText="Total Tickets"
          icon="/assets/total-tickets.svg"
        />
        <CountCard
          count={openTickets.length}
          cardText="Open Tickets"
          icon="/assets/open-tickets.svg"
        />
        <CountCard
          count={resolvedTickets.length}
          cardText="Resolved Tickets"
          icon="/assets/closed-tickets.svg"
        />
        <CountCard
          count={closedTickets.length}
          cardText="Closed Tickets"
          icon="/assets/closed-tickets.svg"
        />
      </div>
      {filteredTickets.length > 0 ? (
        <NestedToggleTableContainer
          height={`${window.innerHeight - 410}px`}
          open=""
          header="Ticket Details"
          warning="Tickets"
          placeholder="Search for tickets"
          data={filteredTickets}
          columns={columns}
          exportData={exportTickets}
          exportHeader={exportHeaders}
          fileName={`${show?.amg_id}_Product_Tickets`}
        />
      ) : (
        <div className="p-4 mt-4 bg-white border rounded-md italic font-medium">
          No Product Tickets generated
        </div>
      )}
    </div>
  );
};

export default ProductTicket;
