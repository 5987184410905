import CopyToClipboard from "react-copy-to-clipboard";
import DelayedToolTip from "../../delayed-tooltip";
import { PlatformContactsType } from "../platform-edit-form/types";
import { toast } from "react-hot-toast";

const ViewPlatformContacts: React.FC<{
  contacts: Array<PlatformContactsType>;
}> = ({ contacts }) => {
  return (
    <div className="w-1/2">
      <label className="text-gray-400">Platform Contacts</label>
      {contacts.length > 0 ? (
        <table className="mt-4" style={{ fontSize: "16px" }}>
          <thead className="border-0 border-b border-gray-50">
            <tr className="bg-gray-300">
              <th className="py-2 pl-2">S No.</th>
              <th className="text-left py-2 pl-2">Name</th>
              <th className="text-left py-2 pl-2">Email</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact: PlatformContactsType, index: number) => (
              <tr
                key={index}
                className="border-0 border-b border-gray-50 bg-gray-50"
              >
                <td className="py-1 pl-2">{index + 1}</td>
                <td className="text-left py-2 pl-2">{contact.name}</td>
                <td className="text-left py-2 pl-2 flex items-center">
                  <span className="mr-3">{contact.email}</span>
                  <DelayedToolTip title="copy">
                    <CopyToClipboard text={contact.email} data-tip="copy">
                      <img
                        src="/assets/file-copy.svg"
                        onClick={() => toast.success("Copied")}
                        alt="file-copy"
                      />
                    </CopyToClipboard>
                  </DelayedToolTip>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="mt-2">-</div>
      )}
    </div>
  );
};

export default ViewPlatformContacts;
