import React from "react";
import { useCustomerQuotesByPkQuery } from "../../hasura.graphql";
import Loader from "../../common/loader";
import ErrorBoundary from "../error-boundary";
import Quotes from ".";
import { useCDM } from "../../cdm-context";

const QuotesWrapper: React.FC<{
  isModal: boolean;
  setTab: (tab: string) => void;
  setSearchParams: (search: string) => void;
}> = ({ isModal, setTab, setSearchParams }) => {
  const { show } = useCDM();
  const { data, loading, error } = useCustomerQuotesByPkQuery({
    variables: {
      amagi_id: show?.amg_id!,
    },
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <i>${JSON.stringify(error)}</i>;
  }

  if (!data || !data.customer) {
    return <i>Could not retrieve customer sales data</i>;
  }

  return (
    <ErrorBoundary>
      <Quotes
        quotes={data.customer}
        isModal={isModal}
        setTab={setTab}
        setSearchParams={setSearchParams}
      />
    </ErrorBoundary>
  );
};

export default QuotesWrapper;
