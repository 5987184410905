import React, { useState } from "react";
import { useDebounce } from "use-debounce";
import { useCDM } from "../../cdm-context";
import TableFilter from "../../common/table-filter";
import { DeliveryInfoFragment } from "../../hasura.graphql";
import DelayedToolTip from "../delayed-tooltip";
import { contentWidth } from "../utils";
import DeliveryChart from "./delivery-chart";
import DeliveryTable from "./delivery-table";
import { ViewListIcon } from "@heroicons/react/outline";
import MatrixModal from "./matrix-modal";
// import Download from "../exportComponents/download";
import ExportCSV from "../exportComponents/exportCSV";

export type DeliveryDetailsType = {
  nameRow: string | number;
  status: string | null;
};

const DeliveriesChartTable: React.FC<{
  data: Array<DeliveryInfoFragment>;
  header: string;
  filterPosition?: string;
}> = ({ data, header, filterPosition }) => {
  const { show } = useCDM();
  const [toggleDeliveryView, setToggleDeliveryView] = useState<string>("chart");
  const [toggleDeliveryMatrix, setToggleDeliveryMatrix] = useState(false);
  const [deliverySearch, setDeliverySearch] = useState<string>("");
  const [debouncedDeliverySearch] = useDebounce(deliverySearch, 100);

  const debouncedChannelDeliveries = data.filter((del) => {
    if (!debouncedDeliverySearch) {
      return true;
    }
    return `${del.channel?.name} ${del.platform?.name} ${del.platform?.platform_tier}`
      .toLowerCase()
      .includes(debouncedDeliverySearch.toLowerCase());
  });

  const exportData = debouncedChannelDeliveries.map((del) => {
    return {
      delivery_id: del.id,
      region:
        del.delivered_region && del.delivered_region.length > 0
          ? del.delivered_region.join(", ")
          : "-",
      channel_id: del.channel?.id || "-",
      channel_name: del.channel?.name || "-",
      platform_id: del.platform?.id || "-",
      platform_name: del.platform?.name || "-",
      business_model:
        del.business_model && del.business_model.length > 0
          ? del.business_model.join(", ")
          : "-",
      ssai: del.ssai ? "Yes" : "No",
      platform_tier: del.platform?.platform_tier || "-",
      deal_type_for_content_partner: del.deal_type_for_content_partner || "-",
      deal_type_for_platform_partner: del.deal_type_for_platform_partner || "-",
      deal_split_with_content_partner:
        del.deal_split_with_content_partner || "-",
      deal_split_with_platform_partner:
        del.deal_split_with_platform_partner || "-",
      deal_split_amagi_share: del.deal_split_amagi_share || "-",
      delivery_type: del.delivery_type || "-",
      status: del.delivery_status || "-",
    };
  });

  const exportHeaders = [
    { label: "Delivery ID", key: "delivery_id" },
    { label: "Channel ID", key: "channel_id" },
    { label: "Channel Name", key: "channel_name" },
    { label: "Platform ID", key: "platform_id" },
    { label: "Platform Name", key: "platform_name" },
    { label: "Business Model", key: "business_model" },
    { label: "SSAI", key: "ssai" },
    { label: "Platform Tier", key: "platform_tier" },
    { label: "Delivery Region", key: "region" },
    {
      label: "Deal Type for Content Partner",
      key: "deal_type_for_content_partner",
    },
    {
      label: "Deal Type for Platform Partner",
      key: "deal_type_for_platform_partner",
    },
    {
      label: "Deal Split With Content Partner",
      key: "deal_split_with_content_partner",
    },
    {
      label: "Deal Split With Platform Partner",
      key: "deal_split_with_platform_partner",
    },
    { label: "Deal Split With Amagi Share", key: "deal_split_amagi_share" },
    { label: "Delivery Type", key: "delivery_type" },
    { label: "Status", key: "status" },
  ];

  return (
    <div
      className="rounded mt-4"
      style={{
        maxHeight: `${window.innerHeight - 450}px`,
      }}
    >
      <div
        className="custom_container p-2 px-4 flex justify-between items-center"
        style={{
          width: contentWidth,
        }}
      >
        <div className="flex items-center w-1/2">
          <div className="flex items-center w-full">
            <div className="font-medium">{header}</div>
            <div className="flex items-center ml-10 w-2/12">
              <div
                onClick={() => setToggleDeliveryView("chart")}
                className={`p-2 w-full h-9 flex items-center justify-center rounded-sm ${
                  toggleDeliveryView === "chart"
                    ? "border-2 border-primaryColor"
                    : "border-2 border-r-0 border-gray-300"
                }`}
              >
                <DelayedToolTip title="matrix view">
                  <img src="/assets/table-icon.svg" alt="table-icon" />
                </DelayedToolTip>
              </div>
              <div
                onClick={() => setToggleDeliveryView("table")}
                className={`p-2 w-full h-9 flex items-center justify-center rounded-sm ${
                  toggleDeliveryView === "table"
                    ? "border-2 border-primaryColor"
                    : "border-2 border-l-0 border-gray-300"
                }`}
              >
                <DelayedToolTip title="list view">
                  {/* <img src="/assets/chart-icon.svg" alt="chart-icon" /> */}
                  <ViewListIcon className="h-4 w-4 text-primaryColor" />
                </DelayedToolTip>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2 flex justify-end items-center">
          {toggleDeliveryView === "table" ? (
            <div className="w-4/5">
              <TableFilter
                filter={deliverySearch}
                setFilter={setDeliverySearch}
                placeholder="Search by channel name, platform name, tier"
              />
            </div>
          ) : (
            <div></div>
          )}
          <div className="ml-3">
            {/* <Download
              csvData={exportData}
              ref={ref}
              csvHeaders={exportHeaders}
              fileName={`${show?.amg_id}_Deliveries`}
            /> */}
            <ExportCSV
              csvData={exportData}
              csvHeaders={exportHeaders}
              fileName={`${show?.amg_id}_Deliveries`}
            />
          </div>
        </div>
      </div>
      {toggleDeliveryView === "table" ? (
        <DeliveryTable
          deliveriesData={data}
          debouncedDeliverySearch={debouncedDeliverySearch}
          header={header}
        />
      ) : toggleDeliveryMatrix ? (
        <MatrixModal
          onClose={() => setToggleDeliveryMatrix(false)}
          modalShow={toggleDeliveryMatrix}
        >
          <DeliveryChart
            data={data}
            header={header}
            toggle={toggleDeliveryMatrix}
            setToggle={setToggleDeliveryMatrix}
          />
        </MatrixModal>
      ) : (
        <DeliveryChart
          data={data}
          header={header}
          toggle={toggleDeliveryMatrix}
          setToggle={setToggleDeliveryMatrix}
        />
      )}
    </div>
  );
};

export default DeliveriesChartTable;
