import React from "react";
import { currencyConverter } from "../utils";
import numeral from "numeral";
import { useCDM } from "../../cdm-context";
import { useMrrQuery } from "../../hasura.graphql";
import { DateTime } from "luxon";
import { useProductMonth } from "../../product-month-context";
import Loader from "../../common/loader";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  ArcElement,
  ChartOptions,
  CategoryScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { months } from "../../constants";

ChartJS.register(ArcElement, ChartDataLabels, CategoryScale);

const MRR = () => {
  const { show } = useCDM();
  const { setDate } = useProductMonth();

  const { loading, data, error } = useMrrQuery({
    variables: {
      amagi_id: show?.amg_id!,
    },
    onCompleted: (data) => {
      const fromDate = DateTime.local(
        parseInt(
          data.customer_by_amagi_id?.latest_mrr &&
            data.customer_by_amagi_id?.latest_mrr.length > 0
            ? data.customer_by_amagi_id?.latest_mrr[0].month.slice(0, 4)
            : DateTime.local()
                .startOf("month")
                .minus({ month: 1 })
                .toISO()
                .slice(0, 4)
        ),
        parseInt(
          data.customer_by_amagi_id?.latest_mrr &&
            data.customer_by_amagi_id?.latest_mrr.length > 0
            ? data.customer_by_amagi_id?.latest_mrr[0].month.slice(5, 7)
            : DateTime.local()
                .startOf("month")
                .minus({ month: 1 })
                .toISO()
                .slice(5, 7)
        )
      )
        .startOf("month")
        .toISO()
        .slice(0, 10);
      const toDate = DateTime.local(
        parseInt(
          data.customer_by_amagi_id?.latest_mrr &&
            data.customer_by_amagi_id?.latest_mrr.length > 0
            ? data.customer_by_amagi_id?.latest_mrr[0].month.slice(0, 4)
            : DateTime.local()
                .endOf("month")
                .minus({ month: 1 })
                .toISO()
                .slice(5, 7)
        ),
        parseInt(
          data.customer_by_amagi_id?.latest_mrr &&
            data.customer_by_amagi_id?.latest_mrr.length > 0
            ? data.customer_by_amagi_id?.latest_mrr[0].month.slice(5, 7)
            : DateTime.local()
                .endOf("month")
                .minus({ month: 1 })
                .toISO()
                .slice(5, 7)
        )
      )
        .endOf("month")
        .toISO()
        .slice(0, 10);
      setDate({
        from_date: fromDate,
        to_date: toDate,
      });
    },
  });

  if (loading) {
    return (
      <div
        className={`bg-white p-4 rounded-md h-48 rounded-t-none border border-gray-300 border-t-0`}
      >
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <div
        className={`bg-white p-4 rounded-md h-48 rounded-t-none border border-gray-300 border-t-0`}
      >
        <i>There was an error</i>
      </div>
    );
  }

  if (!data) {
    return (
      <div
        className={`bg-white p-4 rounded-md h-48 rounded-t-none border border-gray-300 border-t-0`}
      >
        <i>Data Unavailable</i>
      </div>
    );
  }

  const mrr = {
    subscription_mrr:
      data.customer_by_amagi_id?.latest_mrr?.find(
        (revenue) => revenue.revenue_split === "Subscription"
      )?.mrr || 0,
    consumption_mrr:
      data.customer_by_amagi_id?.latest_mrr?.find(
        (revenue) => revenue.revenue_split === "Consumption"
      )?.mrr || 0,
    other_mrr:
      data.customer_by_amagi_id?.latest_mrr?.find(
        (revenue) => revenue.revenue_split === "Others"
      )?.mrr || 0,
    total_mrr: 0,
  };

  mrr.total_mrr = mrr.subscription_mrr + mrr.consumption_mrr + mrr.other_mrr;
  const { subscription_mrr, consumption_mrr, other_mrr, total_mrr } = mrr;

  const options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: total_mrr === 0 ? false : true,
        position: "right",
        align: "center",
      },
      datalabels: {
        display: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 1,
      },
    },
  };

  const mrr_chart_data = {
    maintainAspectRatio: false,
    responsive: false,
    labels: ["Subscription", "Consumption", "Others"],
    datasets: [
      {
        data: [subscription_mrr, consumption_mrr, other_mrr],
        backgroundColor: ["#716AE8", "#67B045", "#FF8D20"],
      },
    ],
  };

  return (
    <div
      className={`bg-white p-4 rounded-md rounded-t-none border border-gray-300 border-t-0`}
    >
      <div className="flex">
        <div className="w-1/2 flex items-center">
          <h1 className="text-3xl">
            {`${show?.currency} ${numeral(total_mrr)
              .format("0.00a")
              .toUpperCase()}`}
          </h1>
          <div className="text-lg ml-3">
            {data.customer_by_amagi_id?.latest_mrr &&
            data.customer_by_amagi_id?.latest_mrr.length > 0 &&
            data.customer_by_amagi_id?.latest_mrr.filter(
              (mrr) => mrr.month !== null
            ).length > 0 ? (
              <span className="text-sm">
                (
                {
                  months[
                    parseInt(
                      data.customer_by_amagi_id?.latest_mrr[0].month.slice(5)
                    ) - 1
                  ]
                }{" "}
                {data.customer_by_amagi_id?.latest_mrr[0].month.slice(0, 4)})
              </span>
            ) : (
              <span></span>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mt-4">
        <div className="w-2/5">
          {/* <div className="flex justify-start items-center">
            <span className="font-bold w-2/5 text-xl text-chartOrange my-1 mr-2">
              {currencyConverter(total_mrr, show?.currency || "USD", "0.00a")}
            </span>
            <span className="font-normal w-3/5 text-sm text-primaryText">
              {" "}
              Total MRR
            </span>
          </div> */}
          <div className="flex justify-start items-center my-2">
            <span className="font-bold text-right w-2/5 text-xl text-chartPurple my-1 mr-2">
              {currencyConverter(
                subscription_mrr,
                show?.currency || "USD",
                "0.00a"
              )}
            </span>
            <span className="font-normal w-3/5 text-sm text-primaryText">
              {" "}
              Subscription
            </span>
          </div>
          <div className="flex justify-start items-center my-2">
            <span className="font-bold text-right w-2/5 text-xl text-chartGreen my-1 mr-2">
              {currencyConverter(
                consumption_mrr,
                show?.currency || "USD",
                "0.00a"
              )}
            </span>
            <span className="font-normal w-3/5 text-sm text-primaryText">
              {" "}
              Consumption
            </span>
          </div>
          <div className="flex justify-start items-center my-2">
            <span className="font-bold text-right w-2/5 text-xl text-chartOrange my-1 mr-2">
              {currencyConverter(other_mrr, show?.currency || "USD", "0.00a")}
            </span>
            <span className="font-normal w-3/5 text-sm text-primaryText">
              {" "}
              Others
            </span>
          </div>
        </div>
        <div className="max-h-56">
          <Doughnut data={mrr_chart_data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default MRR;
