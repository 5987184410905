import { ExternalLinkIcon } from "@heroicons/react/outline";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ChannelGridFragment } from "../../hasura.graphql";

const CustomerChannelCard: React.FC<{
  channel: ChannelGridFragment;
  url: string;
}> = ({ channel, url }) => {
  const handleExternalLink = (e: any) => {
    e.stopPropagation();
  };
  const { name, genre, logo } = channel;
  return (
    <div className="rounded-md border bg-white border-gray-300">
      <LazyLoadImage
        className="w-full h-32 rounded-t-md object-contain border-b bg-lightPrimary"
        src={
          logo
            ? `/${logo}`
            : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${name}`
        }
        alt={name}
      />
      <div className="flex justify-between items-center">
        <div className="p-2">
          <div className="text-xs" style={{ color: "#47536B" }}>
            Channel Name
          </div>
          <div className="text-sm font-medium">
            {name.length > 30 ? `${name.slice(0, 30)}...` : name}
          </div>
        </div>
        <div className="p-2" onClick={(e) => handleExternalLink(e)}>
          <a href={url} target="_blank" rel="noreferrer">
            <ExternalLinkIcon className="h-5 w-5 text-blue-500" />
          </a>
        </div>
      </div>
      <div
        className="p-2 flex overflow-hidden h-12 items-center"
        style={{ fontSize: "12px" }}
      >
        {typeof genre === "object" ? (
          genre.map((g: string, index: number) => {
            if (index < 3) {
              return (
                <span
                  key={index}
                  className="border border-gray-300 px-1 mr-2 rounded-lg"
                >
                  {g}
                </span>
              );
            } else if (index === 3) {
              return (
                <span
                  key={index}
                  className="border border-gray-300 px-1 mr-2 rounded-lg"
                >{`+ ${genre.length - index}`}</span>
              );
            } else return null;
          })
        ) : (
          <span className="border border-gray-300 px-1 mr-2 rounded-lg">
            {genre}
          </span>
        )}
      </div>
    </div>
  );
};

export default CustomerChannelCard;
