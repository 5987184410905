import React from 'react';

const ErrorNotFound: React.FC = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="text-5xl text-primaryColor m-5">
        404 Error
      </div>
      <div className='text-2xl text-center'>
        <div>The requested page is not found or you don't have access to it</div>
        <div>To get access, please mail to <a className='text-blue-500' href='mailto:sushma.rai@amagi.com'>sushma.rai@amagi.com</a></div>
      </div>
    </div>
  )
}

export default ErrorNotFound