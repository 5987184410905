import React, { useState } from "react";
import { useCDM } from "../../cdm-context";
import Loader from "../../common/loader";
import { useDeliveriesByCustomerQuery } from "../../hasura.graphql";
import SubTabs from "../sub-tabs";
import DeliveriesByCustomer from "./delivery-by-customer";
import DeliveryOverview from "./delivery-overview";

const CustomerDeliveries = () => {
  const { show } = useCDM();
  const [deliveryTab, setDeliveryTab] = useState<string>("Channel Deliveries");
  const { data, loading, error } = useDeliveriesByCustomerQuery({
    variables: {
      amagi_id: show?.amg_id!,
    },
  });

  if (loading)
    return (
      <>
        <div className="">
          <SubTabs
            customStyles=""
            currentTab={deliveryTab}
            toggleTab={setDeliveryTab}
            tabList={[
              { name: "Overview" },
              { name: "Playouts"},
              { name: "Channel Deliveries" },
              { name: "VOD Deliveries" },
              { name: "Live Deliveries"}
            ]}
          />
        </div>
        <Loader />
      </>
    );
  if (error) return <i>Error</i>;
  if (!data) return <i>Deliveries data not found</i>;

  return (
    <div>
      <div>
        <SubTabs
          customStyles=""
          currentTab={deliveryTab}
          toggleTab={setDeliveryTab}
          tabList={[
            { name: "Overview" },
            { name: "Playouts"},
            { name: "Channel Deliveries" },
            { name: "VOD Deliveries" },
            { name: "Live Deliveries"}
          ]}
        />
      </div>
      {deliveryTab === "Overview" && (
        <DeliveryOverview
          channelDeliveryData={data.channel_delivery}
          vodDeliveryData={data.vod_delivery}
          livedeliverydata={data.live_delivery}
          playoutdata={data.playouts}
        />
      )}

      {deliveryTab === "Playouts" && (
        <DeliveriesByCustomer
          deliveryData={data.playouts}
          header="Playouts"
        />
      )}

      {deliveryTab === "Channel Deliveries" && (
        <DeliveriesByCustomer
          deliveryData={data.channel_delivery}
          header="Channel Deliveries"
        />
      )}
      {deliveryTab === "VOD Deliveries" && (
        <DeliveriesByCustomer
          deliveryData={data.vod_delivery}
          header="VOD Deliveries"
        />
      )}
       {deliveryTab === "Live Deliveries" && (
        <DeliveriesByCustomer
          deliveryData={data.live_delivery}
          header="Live Deliveries"
        />
      )}
    </div>
  );
};

export default CustomerDeliveries;
