import { createContext, useContext, Dispatch, SetStateAction } from "react";

export type ShowInfo = {
  amg_id: string;
  name: string;
  currency: string,
  type: string;
};

interface CDMContextProps {
  show: ShowInfo | null;
  setShow: Dispatch<SetStateAction<ShowInfo | null>>;
}

export const CDMContext = createContext({} as CDMContextProps);

export function useCDM() {
  return useContext(CDMContext);
}
