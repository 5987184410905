import React from "react";
import Contacts from ".";
import Loader from "../../common/loader";
import { useCustomerContactsByPkQuery } from "../../hasura.graphql";
import ErrorBoundary from "../error-boundary";

const ContactsWrapper: React.FC<{ amagi_id: string }> = ({ amagi_id }) => {
  const { data, loading, error } = useCustomerContactsByPkQuery({
    variables: {
      amagi_id,
    },
  });

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <i>Error</i>;
  }
  if (!data || !data.customer) {
    return <i>Could not retrieve customer contacts data</i>;
  }
  return (
    <ErrorBoundary>
      <Contacts {...data.customer} />
    </ErrorBoundary>
  );
};

export default ContactsWrapper;
