import React, { useMemo } from "react";
import CustomTable from "../common/custom-table";
import { useCustomerQuery } from "../hasura.graphql";
import { useCDM } from "../cdm-context";
import Loader from "../common/loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import ErrorBoundary from "../components/error-boundary";
import CustomerStatusColumnFilter from "../common/filters/customer-status-filter";
import TableRowsFilter from "../common/filters/table-rows-filter";

const Customers: React.FC = () => {
  const { setShow } = useCDM();

  const columns: any = useMemo(
    () => [
      {
        Header: "CUSTOMER",
        accessor: "account_name",
        Cell: ({ cell }: any) => (
          <div className="flex w-full">
            <div className="w-16 h-12">
             <LazyLoadImage
                          className="h-full w-full border rounded-lg object-contain"
                          style={{ background: "#C1CFFB" }}
                          src={
                            cell.row.original.logo && cell.row.original.logo.startsWith("uploads/")
                              ? `/${cell.row.original.logo}`
                              : cell.row.original.logo
                              ? `/logos/${cell.row.original.logo}`
                              : `https://via.placeholder.com/300x200.png/C1CFFB/1924C1?text=${cell.row.original.account_name}`
                          }
                          alt={cell.row.original.logo}
                        />
            </div>
            <div className="flex justify-between items-center w-full">
              <div className="ml-3 flex flex-col">
                <span
                  className="cursor-pointer mr-2 text-blue-500 underline"
                  data-tip={cell.row.original.account_name}
                  onClick={() => {
                    setShow({
                      amg_id: cell.row.values.amagi_id,
                      name: cell.row.original.account_name,
                      currency: cell.row.original.invoicing_currency,
                      type: "customer-modal",
                    });
                  }}
                >
                  {cell.row.original.account_name.length > 25
                    ? `${cell.row.original.account_name.slice(0, 25)}...`
                    : cell.row.original.account_name}
                </span>

                <span
                  data-tip={cell.row.original.account_legal_name}
                  className="text-sm"
                >
                  {cell.row.original.account_legal_name &&
                  cell.row.original.account_legal_name.length > 25
                    ? `${cell.row.original.account_legal_name.slice(0, 25)}...`
                    : cell.row.original.account_legal_name}
                </span>
              </div>
              <a
                href={`/customers/${cell.row.values.amagi_id}/details`}
                target="_blank"
                rel="noreferrer"
              >
                <ExternalLinkIcon className="h-5 w-5 text-blue-500" />
              </a>
            </div>
          </div>
        ),
        Filter: CustomerStatusColumnFilter,
        filter: "multiSelect",
      },
      {
        Header: "AMAGI ID",
        always_show: true,
        accessor: "amagi_id",
      },
      {
        Header: "TYPE",
        accessor: "customer_type",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "RECORD TYPE",
        accessor: "record_type",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "SEGMENT",
        accessor: "segment",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "REGION",
        accessor: "region",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "SIZE",
        accessor: "size",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "TAM",
        accessor: "tam",
        Filter: TableRowsFilter,
        filter: "multiSelect",

      }
    ],
    [setShow]
  );

  const { data, loading, error } = useCustomerQuery({
    fetchPolicy: "cache-first",
  });

  if (loading) {
    return (
      <div className="mx-6 mt-4">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <i>Error</i>;
  }

  if (!data) {
    return <i>Customer data not available</i>;
  }

  const filteredData = data.customer.map((cust) => {
    return {
      amagi_id: cust.amagi_id,
      account_name: cust.account_name,
      logo: cust.logo ? cust.logo : null,
      account_legal_name: cust.account_legal_name,
      customer_type: cust.customer_type ? cust.customer_type : "-",
      customer_status: cust.status ? cust.status : "-",
      status: cust.status ? cust.status : "-",
      invoicing_currency: cust.invoicing_currency
        ? cust.invoicing_currency
        : "USD",
      segment: cust.industry_segment ? cust.industry_segment : "-",
      region: cust.address?.region ? cust.address?.region : "-",
      size: cust.size ? cust.size : "-",
      record_type: cust.record_type?.name ? cust.record_type?.name : "-",
      tam: cust?.tam ? cust?.tam : "-"
    
    };
  });

  return (
    <ErrorBoundary>
      <div className="mx-6 mt-4">
        <CustomTable
          tooltipAlign="top"
          columns={columns}
          rowData={filteredData}
          placeholder="Search with customer attributes"
        />
      </div>
    </ErrorBoundary>
  );
};

export default Customers;
