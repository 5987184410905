import { useParams } from "react-router-dom";
import { useCDPRole } from "../cdp-role-context";
import Loader from "../common/loader";
import ChannelViewForm from "../components/channels/channel-view-form";
import { useFeatureFlagQuery } from "../hasura.graphql";

const ChannelById: React.FC<{ header: string; url: string }> = ({
  header,
  url,
}) => {
  const { id } = useParams();
  const { setCDPRole } = useCDPRole();
  const { data, loading, error } = useFeatureFlagQuery({
    onCompleted: (data) => {
      setCDPRole(data.feature_flag.map((feat) => feat.feature));
    },
  });
  if (loading)
    return (
      <div className="mx-6 mt-4">
        <Loader />
      </div>
    );
  if (error) return null;
  if (!data) return null;

  return (
    <ChannelViewForm
      isModal={false}
      channelId={id!}
      header={header}
      url={url}
    />
  );
};

export default ChannelById;
