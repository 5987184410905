import React, { ChangeEvent, useState } from "react";
import { useLinearChannelsQuery, useFeatureFlagQuery } from "../hasura.graphql";
import Modal from "../components/modal";
import Loader from "../common/loader";
import ChannelCard from "../components/cards/channel-card";
import { useDebounce } from "use-debounce";
import { useCDPRole } from "../cdp-role-context";
import ChannelViewForm from "../components/channels/channel-view-form";
import ErrorBoundary from "../components/error-boundary";
import TableFilter from "../common/table-filter";
import { ChannelKeyringType } from "../components/channels/channel-edit-form/types";
import { useLocation, useNavigate } from "react-router-dom";
import PopoverMenu from "../components/popover-menu";
import ChannelList from "../components/channels/channel-list";

export type ModalType = {
  id: string;
  name: string;
  type?: string;
  brand?: string | null;
};

const Channels: React.FC = () => {
  const [channelEdit, setChannelEdit] = React.useState<ModalType | null>(null);
  const [channelSearch, setChannelSearch] = React.useState<string>("");
  const [toggleChannelView, setToggleChannelView] = useState<string>("list");
  const [channelStatus, setChannelStatus] = useState<Array<string>>([
    "No Active Deal","Ordered and Active","Opportunity / Quote Created","Ordered and Churned"
  ]);
  const [columnSearch, setColumnSearch] = React.useState<string>("");
  const [debouncedColumnSearch] = useDebounce(columnSearch, 100);
  const [debouncedChannelSearch] = useDebounce(channelSearch, 100);
  const { setCDPRole } = useCDPRole();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const channelTabs = [
    {
      label: "Linear Channels",
      url: "/channels/linear",
      active: pathname === "/channels/linear",
    },
    {
      label: "Grouped Channels",
      url: "/channels/grouped",
      active: pathname === "/channels/grouped",
    },
    {
      label: "Redundant Channels",
      url: "/channels/redundant",
      active: pathname === "/channels/redundant",
    },
    {
      label: "Subfeed Channels",
      url: "/channels/subfeed",
      active: pathname === "/channels/subfeed",
    },
    {
      label: "Test Channels",
      url: "/channels/test",
      active: pathname === "/channels/test",
    },
    {
      label: "VOD Channels",
      url: "/channels/vod",
      active: pathname === "/channels/vod",
    },
    {
      label: "Pop-up Channels",
      url: "/channels/pop-up",
      active: pathname === "/channels/pop-up",
    },
  ];

  const handleChannelStatus = (e: ChangeEvent<HTMLInputElement>) => {
    const t = e.target.name;
    if (channelStatus.length === 1 && channelStatus.includes(t)) {
      setChannelStatus([]);
    } else if (channelStatus && channelStatus.includes(t)) {
      setChannelStatus((prevChecked) => {
        return prevChecked.filter((v) => v !== t);
      });
    } else {
      setChannelStatus((prevChecked) => [...prevChecked, t]);
    }
  };

  const debouncedColumns = [
    "Ordered and Active",
    "Ordered and Churned",
    "Opportunity / Quote Created",
    "No Active Deal",
  ].filter((opt) => {
    if (!debouncedColumnSearch) {
      return true;
    }
    return opt.toLowerCase().includes(debouncedColumnSearch.toLowerCase());
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const role_data = useFeatureFlagQuery({
    onCompleted: (data) => {
      setCDPRole(data.feature_flag.map((feat) => feat.feature));
    },
  });
  
  const { data, loading, error } = useLinearChannelsQuery();

  if (loading) {
    return (
      <>
        <div
          className={`hidden sm:block sticky top-14 z-20 bg-gradient-to-r from-gradientBlue via-gradientBlue to-gradientPurple w-full`}
        >
          <div className="border-b border-gray-200 rounded-t-md">
            <nav className="-mb-px px-6 py-1 flex space-x-8" aria-label="Tabs">
              {channelTabs.map((tab) => {
                return (
                  <div
                    onClick={() => {
                      navigate(tab.url);
                    }}
                    className={`${
                      tab.active
                        ? "text-orange-500"
                        : "text-white hover:scale-105 transition-all"
                    } whitespace-nowrap p-2 font-medium text-sm cursor-pointer`}
                  >
                    {tab.label}
                  </div>
                );
              })}
            </nav>
          </div>
        </div>
        <div className="mx-6 mt-4">
          <Loader />
        </div>
      </>
    );
  }
  if (error) {
    return <i>Error</i>;
  }
  if (!data) {
    return <i>Channels data not available</i>;
  }

  const orderedChannels = data.channel.filter((chn) =>
    channelStatus.includes(chn.ordered_channel!)
  );

  const channelCount = [
    data.channel.filter((chn) =>
      chn.ordered_channel!.includes("No Active Deal")
    ).length,
    data.channel.filter((chn) =>
      chn.ordered_channel!.includes("Opportunity / Quote Created")
    ).length,
    data.channel.filter((chn) =>
      chn.ordered_channel!.includes("Ordered and Active")
    ).length,
    data.channel.filter((chn) =>
      chn.ordered_channel!.includes("Ordered and Churned")
    ).length,
  ];

  const debouncedChannels = orderedChannels.filter((channel) => {
    if (!debouncedChannelSearch) {
      return true;
    }
    return `${channel.id} ${channel.salesforce_id} ${channel.name} ${channel.channel_brand} ${
      channel.customer?.account_name
    } ${channel.customer?.amagi_id} ${channel.ordered_channel} ${channel.genre
      .map((gen: string) => gen)
      .join(" ")} ${channel.keyring
      .map((gen: ChannelKeyringType) => gen.id)
      .join(" ")}`
      .toLowerCase()
      .includes(debouncedChannelSearch.toLowerCase());
  });

  return (
    <ErrorBoundary>
      <div>
        <div
          className={`hidden sm:block sticky top-14 z-20 bg-gradient-to-r from-gradientBlue via-gradientBlue to-gradientPurple w-full`}
        >
          <div className="border-b border-gray-200 rounded-t-md">
            <nav className="-mb-px px-6 py-1 flex space-x-8" aria-label="Tabs">
              {channelTabs.map((tab) => {
                return (
                  <div
                    onClick={() => {
                      navigate(tab.url);
                    }}
                    className={`${
                      tab.active
                        ? "text-orange-500"
                        : "text-white hover:scale-105 transition-all"
                    } whitespace-nowrap p-2 font-medium text-sm cursor-pointer`}
                  >
                    {tab.label}
                  </div>
                );
              })}
            </nav>
          </div>
        </div>
        <div className="mx-6">
          {orderedChannels.length > 0 ? (
            <>
              {toggleChannelView === "grid" ? (
                <>
                  <div className="w-full flex justify-between sticky top-24 h-14 z-10 items-center bg-secondaryBg">
                    <div className="flex items-center w-1/3">
                      <div className="text-lg font-medium">
                        Linear Channels ({debouncedChannels.length})
                      </div>
                      <div className="flex items-center ml-3 w-3/12 cursor-pointer">
                        <div
                          onClick={() => setToggleChannelView("list")}
                          className={`p-2 w-full h-8 flex items-center justify-center rounded-sm border-2 border-r-0 border-gray-300`}
                        >
                          <img
                            src="/assets/channel-list.svg"
                            alt="channel-list"
                          />
                        </div>
                        <div
                          onClick={() => setToggleChannelView("grid")}
                          className={`p-2 w-full h-8 flex items-center justify-center rounded-sm ${
                            toggleChannelView === "grid"
                              ? "border-2 border-primaryColor"
                              : "border-2 border-l-0 border-gray-300"
                          }`}
                        >
                          <img
                            src="/assets/channel-grid.svg"
                            alt="channel-grid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex w-2/5 items-center">
                      <PopoverMenu
                        icon={
                          channelStatus.length > 0
                            ? "filter-fill.svg"
                            : "filter.svg"
                        }
                        styles={`right-10`}
                      >
                        <div className="py-2">
                          <TableFilter
                            filter={columnSearch}
                            setFilter={setColumnSearch}
                            placeholder="Search"
                          />
                        </div>
                        <button
                          className="text-blue-500 text-sm font-medium py-1 hover:underline"
                          onClick={() => {
                            setChannelStatus([]);
                          }}
                        >
                          Remove filters
                        </button>
                        {debouncedColumns.sort().map((sts, index) => (
                          <div className="py-px" key={index}>
                            <input
                              type="checkbox"
                              className="focus:ring-0"
                              id={sts}
                              name={sts}
                              onChange={handleChannelStatus}
                              title={sts}
                              checked={channelStatus.includes(sts)}
                            />
                            <label htmlFor={sts} className="pl-2 text-sm">
                              {sts} ({channelCount[index]})
                            </label>
                          </div>
                        ))}
                      </PopoverMenu>
                      <div className="w-4/5">
                        <TableFilter
                          filter={channelSearch}
                          setFilter={setChannelSearch}
                          placeholder="Search by channel, customer and genre"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-5 gap-8 mt-2">
                    {debouncedChannels.map((channel) => {
                      return (
                        <div
                          key={channel.id}
                          onClick={() => {
                            setChannelEdit({
                              id: channel.id,
                              name: channel.name,
                              brand: channel.channel_brand
                            });
                          }}
                          className="cursor-pointer transition-transform hover:scale-105"
                        >
                          <ChannelCard
                            channel={channel}
                            url={`/channels/linear/${channel.id}`}
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <ChannelList
                  setChannelSearch={setChannelSearch}
                  channelSearch={channelSearch}
                  toggleView={toggleChannelView}
                  setToggleView={setToggleChannelView}
                  channels={debouncedChannels}
                  header="Linear Channels"
                  url="/channels/linear"
                />
              )}
            </>
          ) : (
            <div className="p-4 mt-4 bg-white border rounded-md italic font-medium">
              No Linear Channels
            </div>
          )}
        </div>
        {channelEdit && (
          <Modal
            title="All Linear Channels"
            modalWidth="100vw"
            modalShow={channelEdit}
            onClose={setChannelEdit}
          >
            <ChannelViewForm
              channelId={channelEdit.id}
              isModal={true}
              header="All Linear Channels"
              url="/channels/linear"
            />
          </Modal>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default Channels;
