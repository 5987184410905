import { ChannelRatingType } from "../channel-edit-form/types";

const ViewChannelRating: React.FC<{ chnRating: Array<ChannelRatingType> }> = ({
  chnRating,
}) => {
  return (
    <div>
      <label className="text-gray-400">Channel Rating</label>
      {chnRating.length > 0 ? (
        <table
          className="mt-4 border"
          style={{ width: "55%", fontSize: "16px" }}
        >
          <thead className="border-0 border-b border-gray-50">
            <tr className="bg-gray-300">
              <th className="py-2 pl-2">S No.</th>
              <th className="text-left py-2 pl-2">Region</th>
              <th className="text-left py-2 pl-2">Country</th>
              <th className="text-left py-2 pl-2">Rating</th>
            </tr>
          </thead>
          <tbody className="bg-gray-50">
            {chnRating.map((rating: ChannelRatingType, index: number) => (
              <tr
                key={index}
                className="border-0 border-b border-gray-200 last:border-0"
              >
                <td className="py-1 pl-2">{index + 1}</td>
                <td className="text-left py-2 pl-2">{rating.region}</td>
                <td className="text-left py-2 pl-2">{rating.country || "-"}</td>
                <td className="text-left py-2 pl-2">{rating.rating}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="mt-2">-</div>
      )}
    </div>
  );
};

export default ViewChannelRating;
