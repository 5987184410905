import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import React from "react";

const ToggleBox: React.FC<{
  header: string;
  columns: Array<string>;
  handleHideColumns: (col: string) => void;
  handleUnhideColumns: (col: string) => void;
  children: React.ReactNode;
}> = ({
  header,
  columns,
  handleHideColumns,
  handleUnhideColumns,
  children,
}) => {
  return (
    <div className="mb-5">
      <div
        className={`bg-white py-4 flex justify-between ${
          columns.includes(header) ? "rounded-xl" : "rounded-t-xl"
        }`}
      >
        <h2 className="mx-6 text-xl font-semibold">{header}</h2>
        {columns.includes(header) ? (
          <ChevronDownIcon
            onClick={() => handleUnhideColumns(header)}
            className="h-5 w-5 mx-6 cursor-pointer"
          />
        ) : (
          <ChevronUpIcon
            onClick={() => handleHideColumns(header)}
            className="h-5 w-5 mx-6 cursor-pointer"
          />
        )}
      </div>
      <div
        className={`bg-white border-t rounded-b-xl p-6 ${
          columns.includes(header) ? "hidden" : "block"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default ToggleBox;
