import { useState } from "react";
import { PlatformFieldsFragment } from "../../../hasura.graphql";
import ToggleBox from "../../toggle-box";
import ViewTechnicalTable from "./view-technical-table";
import ViewMultiPicklist from "../../channels/channel-view-form/view-multi-picklist";

const PlatformTechnicalSpecs: React.FC<{
  platformData: PlatformFieldsFragment;
}> = ({ platformData }) => {
  const [hideColumns, setHideColumns] = useState<Array<string>>([]);
  const handleHideColumns = (tab: string) => {
    setHideColumns((hideColumns) => [...hideColumns, tab]);
  };
  const handleUnhideColumns = (tab: string) => {
    setHideColumns(hideColumns.filter((f: string) => f !== tab));
  };

  return (
    <div style={{ color: "#4E4E4E", fontSize: "14px", fontWeight: "500" }}>
      <ToggleBox
        columns={hideColumns}
        handleHideColumns={handleHideColumns}
        handleUnhideColumns={handleUnhideColumns}
        header="Stream Specs"
      >
        <>
          <div>
            <div>
              <ViewMultiPicklist
                inlineLabel="Stream Type"
                picklist={platformData.stream_type}
              />
            </div>
          </div>
          <div className="my-8">
            <ViewTechnicalTable technicalSpecs={platformData.technical_spec} />
          </div>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div>
              <label className="text-gray-400">EPG</label>
              <div className="mt-2">{platformData?.epg || "-"}</div>
            </div>
            <div>
              <label className="text-gray-400">EPG Format</label>
              <div className="mt-2">{platformData?.epg_format || "-"}</div>
            </div>
            <div>
              <label className="text-gray-400">EPG Format Example</label>
              <div className="mt-2">
                {platformData?.epg_format_example || "-"}
              </div>
            </div>
            <div>
              <label className="text-gray-400">Overall Bitrate (in Mbps)</label>
              <div className="mt-2">
                {platformData?.overall_bitrate
                  ? platformData?.overall_bitrate
                  : "-"}
              </div>
            </div>
            <div>
              <label className="text-gray-400">Subtitle/CC Format</label>
              <div className="mt-2">
                {platformData?.subtitle_cc_format || "-"}
              </div>
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Redundancy</label>
              <div className="mt-2">
                {platformData?.redundancy !== null
                  ? platformData?.redundancy
                    ? "Yes"
                    : "No"
                  : "-"}
              </div>
            </div>
            {platformData.redundancy === "Yes" && (
              <>
                <div>
                  <label className="text-gray-400">
                    Redundancy Player Count
                  </label>
                  <div className="mt-2">
                    {platformData?.redundancy_player_count || "-"}
                  </div>
                </div>
                <div>
                  <label className="text-gray-400">
                    Redundancy Delivery Count
                  </label>
                  <div className="mt-2">
                    {platformData?.redundancy_delivery_count || "-"}
                  </div>
                </div>
              </>
            )}

            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Ad Trigger Type</label>
              <div className="mt-2">{platformData?.ad_trigger_type || "-"}</div>
            </div>
            <div>
              <label className="text-gray-400">Ad Break</label>
              <div className="mt-2">
                {platformData?.ad_break ? platformData?.ad_break : "-"}
              </div>
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Platform QA Cycle Time</label>
              <div className="mt-2">
                {platformData?.platform_qa_cycle
                  ? `${platformData?.platform_qa_cycle} weeks`
                  : "-"}
              </div>
            </div>
            <div>
              <label className="text-gray-400">Video Player</label>
              <div className="mt-2">{platformData?.video_player || "-"}</div>
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">
                Platform Specific Encoding
              </label>
              <div className="mt-2">
                {platformData?.platform_specific_encoding || "-"}
              </div>
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Analytics Share Rule</label>
              <div className="mt-2">
                {platformData?.analytics_share_rule || "-"}
              </div>
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Specifications URL</label>
              <div className="mt-2">
                {platformData.specifications_url ? (
                  <a
                    href={platformData.specifications_url}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-500"
                  >
                    <span>{platformData.specifications_url}</span>
                  </a>
                ) : (
                  <span>-</span>
                )}
              </div>
            </div>
          </div>
        </>
      </ToggleBox>
    </div>
  );
};

export default PlatformTechnicalSpecs;
