import ErrorBoundary from "../error-boundary";
import { useMemo, useState } from "react";
import { Toaster } from "react-hot-toast";
import SubTabs from "../sub-tabs";
import Receipts from "./receipts";
import FinancialStatement from "./financial-statement";
import Invoices from "./invoices";
import CreditMemos from "./credit-memos";
import { useNavigate, useParams } from "react-router-dom";
import { useCDM } from "../../cdm-context";

export type TransactionType = {
  id: string;
  date: string | null;
};

const urls = [
  {
    name: "Invoices",
    url: "invoices",
  },
  {
    name: "Credit Memos",
    url: "credit-memos",
  },
  {
    name: "Receipts",
    url: "receipts",
  },
  {
    name: "Statement of Accounts",
    url: "financial-statements",
  },
];

const Financials: React.FC<{
  isModal: boolean;
  setTransaction: React.Dispatch<React.SetStateAction<TransactionType>>;
  transaction: TransactionType;
}> = ({ isModal, transaction, setTransaction }) => {
  const navigate = useNavigate();
  // const [searchParam, setSearchParam] = useState("");
  const { id } = useParams();
  const { show } = useCDM();

  const getPath = useMemo(() => {
    const url = window.location.pathname;
    const splitUrl = url.split("/");
    const pathIndex = splitUrl.indexOf(show?.amg_id!);
    const path = splitUrl[pathIndex + 1];
    return path;
  }, [show?.amg_id]);

  // const [transaction, setTransaction] = useState<TransactionType>({
  //   id: "",
  //   date: null,
  // });
  const [financialTab, setFinancialTab] = useState<string>(
    urls.find((route) => route.url === getPath)?.name! || "Invoices"
  );

  const handleTab = (tab: string) => {
    const url = urls.find((route) => route.name === tab)?.url;
    setFinancialTab(tab);
    !isModal && navigate(`/customers/${id}/${url}`);
  };

  return (
    <ErrorBoundary>
      <div>
        <Toaster />
        <div className="">
          <SubTabs
            customStyles=""
            currentTab={financialTab}
            toggleTab={handleTab}
            tabList={[
              { name: "Invoices" },
              { name: "Credit Memos" },
              { name: "Receipts" },
              { name: "Statement of Accounts" },
            ]}
          />
        </div>
        {financialTab === "Invoices" && <Invoices transaction={transaction} />}
        {financialTab === "Credit Memos" && (
          <CreditMemos transaction={transaction} />
        )}
        {financialTab === "Receipts" && <Receipts transaction={transaction} />}
        {financialTab === "Statement of Accounts" && (
          <FinancialStatement
            setTransaction={setTransaction}
            setTab={setFinancialTab}
            isModal={isModal}
          />
        )}
      </div>
    </ErrorBoundary>
  );
};

export default Financials;
