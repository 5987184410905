import { ChevronRightIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCDPRole } from "../../../cdp-role-context";
import Loader from "../../../common/loader";
import { useChannelByPkQuery } from "../../../hasura.graphql";
import { ModalType } from "../../../pages/channels";
import Modal from "../../modal";
import ChannelEditForm from "../channel-edit-form";
import ChannelBusinessFields from "../channel-view-form/business-fields";
import LegalRights from "../channel-view-form/legal-rights";
import TechnicalSpecs from "../channel-view-form/technical-specs";
import SubTabs from "../../sub-tabs";

const ChannelViewForm: React.FC<{
  channelId: string;
  isModal: boolean;
  header?: string;
  url?: string;
}> = ({ channelId, isModal, header, url }) => {
  const navigate = useNavigate();
  const [channelTab, setChannelTab] = useState("Business Specs");
  const [editForm, setEditForm] = useState<ModalType | null>(null);
  const { CDPRole } = useCDPRole();

  const { data, loading, error } = useChannelByPkQuery({
    variables: {
      _id: channelId,
    },
  });

  if (loading) {
    return (
      <div className={`${!isModal && "mx-6 mt-4"}`}>
        <Loader />
      </div>
    );
  }
  if (error) {
    return <i>Error</i>;
  }
  if (!data) {
    return <i>Channel data not available</i>;
  }

  const channelData = data?.channel;
  const customers =
    data.customer && data.customer.length > 0
      ? data.customer.map((cust) => {
          return {
            amagi_id: cust.amagi_id || "",
            account_name: cust.account_name || "",
          };
        })
      : [];

  const channels =
    data.channels && data.channels.length > 0
      ? data.channels.map((cust) => {
          return {
            salesforce_id: cust.salesforce_id || "",
            source: cust.source || "",
          };
        })
      : [];

  return (
    <div className={`${!isModal && "mx-6"}`}>
      {isModal ? (
        <>
          <div className="mb-2 text-sm sticky bg-secondaryBg top-12 z-20 flex justify-between">
            <div>
              <SubTabs
                customStyles=""
                currentTab={channelTab}
                tabList={[
                  { name: "Business Specs" },
                  { name: "Channel Legal Rights" },
                  { name: "Technical Specs" },
                ]}
                toggleTab={setChannelTab}
              />
            </div>
            {CDPRole.includes("channel-edit") && (
              <div className="">
                <button
                  type="button"
                  onClick={() =>
                    setEditForm({
                      id: channelData?.id!,
                      name: channelData?.name!,
                    })
                  }
                  className="py-1 px-3 bg-primaryColor text-white rounded-sm"
                >
                  Edit
                </button>
              </div>
            )}
          </div>
          {editForm && CDPRole.includes("channel-edit") && (
            <Modal
              title={header}
              modalWidth="100vw"
              modalShow={editForm}
              onClose={setEditForm}
            >
              <ChannelEditForm
                channelData={channelData!}
                customers={customers}
                channels={channels}
                close={() => setEditForm(null)}
                isModal={true}
              />
            </Modal>
          )}
        </>
      ) : (
        <>
          <div className="sticky top-14 z-10">
            <div className="flex justify-between items-center sticky top-14 h-14 w-full bg-secondaryBg">
              <div className="flex items-center">
                <span
                  onClick={() => navigate(`${url}`)}
                  className="font-medium text-gray-600 cursor-pointer hover:text-blue-500 hover:underline"
                >
                  {header}
                </span>
                <ChevronRightIcon className="h-5 w-5 mx-3 text-gray-400" />
                <span className="text-gray-800">{channelData?.name}</span>
              </div>
              {CDPRole.includes("channel-edit") && (
                <div className="">
                  <button
                    type="button"
                    onClick={() => navigate(`${url}/${channelId}/edit`)}
                    className="py-1 px-3 bg-primaryColor text-white rounded-sm"
                  >
                    Edit
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="mb-4 sticky top-28 z-20">
            <SubTabs
              customStyles=""
              currentTab={channelTab}
              tabList={[
                { name: "Business Specs" },
                { name: "Channel Legal Rights" },
                { name: "Technical Specs" },
              ]}
              toggleTab={setChannelTab}
            />
          </div>
        </>
      )}

      <div className="w-full">
        {channelTab === "Business Specs" && (
          <ChannelBusinessFields channelData={channelData!} />
        )}
        {channelTab === "Channel Legal Rights" && (
          <LegalRights channelData={channelData!} />
        )}
        {channelTab === "Technical Specs" && (
          <TechnicalSpecs channelData={channelData!} />
        )}
      </div>
    </div>
  );
};

export default ChannelViewForm;
