import { ReferenceLinksType } from "../platform-edit-form/types";

const ViewReferenceLinks: React.FC<{
  references: Array<ReferenceLinksType>;
}> = ({ references }) => {
  return (
    <div className="w-1/2">
      <label className="text-gray-400">Reference Links</label>
      {references.length > 0 ? (
        <table className="mt-4" style={{ fontSize: "16px" }}>
          <thead className="border-0 border-b border-gray-50">
            <tr className="bg-gray-300">
              <th className="py-2 pl-2">S No.</th>
              <th className="text-left py-2 pl-2">Name</th>
              <th className="text-left py-2 pl-2">Link</th>
            </tr>
          </thead>
          <tbody>
            {references.map((ref: ReferenceLinksType, index: number) => (
              <tr
                key={index}
                className="border-0 border-b border-gray-50 bg-gray-50"
              >
                <td className="py-1 pl-2">{index + 1}</td>
                <td className="text-left py-2 pl-2">{ref.name}</td>
                <td className="text-left py-2 pl-2">
                  <a
                    href={ref.link}
                    className="text-blue-500 underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {ref.link}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="mt-2">-</div>
      )}
    </div>
  );
};

export default ViewReferenceLinks;
