import { TrashIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { ReferenceLinksType } from "./types";
import { useField } from "formik";

const ReferenceLinks: React.FC<{
  references: Array<ReferenceLinksType>;
}> = ({ references }) => {
  const _field = useField("reference_links");
  const { setValue } = _field[2];

  const [inputTable, setInputTable] = useState<Array<ReferenceLinksType>>([
    ...references,
  ]);

  const handleChange = (index: number, value: string, inputKey: string) => {
    let referenceLinks: Array<ReferenceLinksType> = JSON.parse(
      JSON.stringify(inputTable)
    );
    referenceLinks[index][inputKey as keyof ReferenceLinksType] = value;
    setInputTable(referenceLinks);
  };

  const handleAddClick = () => {
    setInputTable((input) => [
      ...input,
      {
        name: "",
        link: "",
      },
    ]);
  };

  useEffect(() => {
    (inputTable.every((tab) => tab.name.length > 0 || tab.link.length > 0) ||
      inputTable.length === 0) &&
      setValue(inputTable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputTable]);

  return (
    <div style={{ padding: "5px 0px" }}>
      {/* <label className="text-gray-400">Reference Links</label> */}
      <div style={{ padding: "5px 0px", borderRadius: 13 }}>
        <table
          className="roundedCorners"
          style={{ width: "50%", fontSize: "16px" }}
        >
          <thead className="bg-secondaryBg">
            <tr className="text-sm">
              <th className="py-2 pl-2 w-20">S No.</th>
              <th className="text-left py-2 pl-2">Name</th>
              <th className="text-left py-2 pl-2">Link</th>
              <th className="text-left py-2 pl-2 w-10"></th>
            </tr>
          </thead>
          <tbody className="bg-gray-50">
            {inputTable.map((data: ReferenceLinksType, index: number) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td className="py-1 pl-2 w-20" style={{ fontSize: "12px" }}>
                      {index + 1}
                    </td>
                    <td className="text-left py-1 pl-2 text-sm">
                      <input
                        type="text"
                        defaultValue={`${data.name}`}
                        onChange={(e) =>
                          handleChange(index, e.target.value, "name")
                        }
                        className="focus:ring-0 px-0 py-2 border-0 border-b text-sm bg-transparent w-full"
                        placeholder="Enter name"
                      />
                    </td>
                    <td className="text-left py-1 pl-2 text-sm flex items-center">
                      <input
                        type="text"
                        defaultValue={`${data.link}`}
                        onChange={(e) =>
                          handleChange(index, e.target.value, "link")
                        }
                        className="focus:ring-0 px-0 py-2 border-0 border-b text-sm bg-transparent w-full"
                        placeholder="Enter url or link"
                      />
                    </td>
                    <td className="py-2 pl-2 cursor-pointer text-sm">
                      <TrashIcon
                        onClick={() => {
                          setInputTable(inputTable.filter((f) => f !== data));
                        }}
                        className="h-5 w-5 text-sm"
                      />
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
        <div className="py-1 pl-2 text-sm text-left cursor-pointer text-blue-500">
          <span onClick={handleAddClick}>+ ADD NEW ROW</span>
        </div>
      </div>
    </div>
  );
};

export default ReferenceLinks;
