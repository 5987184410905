import React from "react";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";

const OneLoginButton = () => {
  return (
    <button
      className="py-2 px-3 text-white"
      style={{ background: "#1924C1", fontSize: 12, fontWeight: 700 }}
      type="submit"
      onClick={() =>
        Auth.federatedSignIn({
          customProvider: "OneLogin",
        })
      }
    >
      ONE LOGIN
    </button>
  );
};

const EmailLoginButton = () => {
  return (
    <button
      className="py-2 px-3 text-white"
      style={{ background: "#1924C1", fontSize: 12, fontWeight: 700 }}
      type="submit"
      onClick={() =>
        Auth.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Cognito,
        })
      }
    >
      EMAIL
    </button>
  );
};

const LoginForm: React.FC = () => {
  return (
    <div>
      <div
        style={{
          fontFamily: "Roboto",
          fontSize: 18,
          fontWeight: 400,
          marginTop: "50px",
          textAlign: "left",
        }}
      >
        Sign In with
      </div>
      <div
        style={{ display: "flex", marginTop: 10, justifyContent: "flex-start" }}
        className="form-buttons"
      >
        {process.env.REACT_APP_ONELOGIN === "true" ? (
          <OneLoginButton />
        ) : (
          <EmailLoginButton />
        )}
      </div>
      <div className="mt-5" style={{ fontSize: 12, marginBottom: 130 }}>
        <div>
          By signing in, you agree to the Amagi{" "}
          <a href="/" className="text-blue-500">
            Terms of Service
          </a>{" "}
          and
        </div>
        <div className="text-left text-blue-500">
          <a href="/">Privacy Policy</a>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
