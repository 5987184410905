import React, { useEffect, useState } from "react";
import { useField } from "formik";
import { TrashIcon } from "@heroicons/react/outline";
import { ChannelKeyringType } from "../channels/channel-edit-form/types";

const FormikKeyring: React.FC<{
  keyring: Array<ChannelKeyringType>;
  defaultId: string;
}> = ({ keyring, defaultId }) => {
  const [inputTable, setInputTable] =
    useState<Array<ChannelKeyringType>>(keyring);

  const handleChange = (index: number, value: string, inputKey: string) => {
    let contactsValues: Array<ChannelKeyringType> = JSON.parse(
      JSON.stringify(inputTable)
    );
    contactsValues[index][inputKey as keyof ChannelKeyringType] = value;
    setInputTable(contactsValues);
  };

  const handleAddClick = () => {
    setInputTable((input) => [
      ...input,
      {
        id: "",
        source: "",
      },
    ]);
  };

  const _field = useField("keyring");
  const { setValue } = _field[2];

  useEffect(() => {
    setValue(inputTable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputTable]);

  return (
    <div style={{ padding: "5px 0px" }}>
      <label className="text-gray-400">Keyring</label>
      <table className="mt-4" style={{ width: "65%", fontSize: "16px" }}>
        <thead>
          <tr className="bg-gray-300">
            <th className="py-2 pl-2">S No.</th>
            <th className="text-left py-2 pl-2">Source</th>
            <th className="text-left py-2 pl-2">ID</th>
          </tr>
        </thead>
        <tbody className="bg-gray-50">
          <tr>
            <td className="py-2 pl-2">1</td>
            <td className="text-left py-2 pl-2">Salesforce</td>
            <td className="text-left py-2 pl-2">{defaultId}</td>
          </tr>
          {inputTable.map((data: ChannelKeyringType, index: number) => {
            return (
              <tr key={index}>
                <td className="py-2 pl-2">{index + 2}</td>
                <td className="text-left py-1 pl-2">
                  <input
                    type="text"
                    style={{ fontSize: 14 }}
                    defaultValue={`${data.source}`}
                    onChange={(e) =>
                      handleChange(index, e.target.value, "source")
                    }
                    className="py-0 px-0 border-0 focus:ring-0 outline-none placeholder:text-gray-200 bg-transparent w-full"
                    placeholder="Input Source"
                  />
                </td>
                <td className="text-left py-1 pl-2">
                  <input
                    type="text"
                    style={{ fontSize: 14 }}
                    defaultValue={`${data.id}`}
                    onChange={(e) => handleChange(index, e.target.value, "id")}
                    className="py-0 px-0 border-0 focus:ring-0 outline-none placeholder:text-gray-200 bg-transparent w-full"
                    placeholder="Input ID"
                  />
                </td>
                <td className="bg-white py-2 pl-2 cursor-pointer">
                  <TrashIcon
                    onClick={() =>
                      setInputTable(inputTable.filter((f) => f !== data))
                    }
                    className="h-5 w-5"
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="py-1 pl-2 text-sm text-left cursor-pointer text-blue-500">
        <span onClick={handleAddClick}>+ ADD NEW ROW</span>
      </div>
    </div>
  );
};

export default FormikKeyring;
