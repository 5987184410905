import { useParams } from "react-router-dom";
import { useCDPRole } from "../cdp-role-context";
import Loader from "../common/loader";
import ErrorBoundary from "../components/error-boundary";
import VODDeliveryViewForm from "../components/vod-delivery/delivery-view-form";
import { useFeatureFlagQuery } from "../hasura.graphql";

const VODDeliveryById = () => {
  const { id } = useParams();
  const { setCDPRole } = useCDPRole();
  const { data, loading, error } = useFeatureFlagQuery({
    onCompleted: (data) => {
      setCDPRole(data.feature_flag.map((feat) => feat.feature));
    },
  });
  if (loading)
    return (
      <div className="mx-6 mt-4">
        <Loader />
      </div>
    );
  if (error) return null;
  if (!data) return null;

  return (
    <ErrorBoundary>
      <VODDeliveryViewForm isModal={false} deliveryId={id!} />
    </ErrorBoundary>
  );
};

export default VODDeliveryById;
