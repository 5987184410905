import Loader from "../../common/loader";
import { useCustomerOverviewMrrByPkQuery } from "../../hasura.graphql";
import Card from "../cards";
import CustomerMRR from "../mrr/customer-mrr";
import ServiceAvailability from "../service-availability";

const OverviewMRR: React.FC<{
  amagi_id: string;
}> = ({ amagi_id }) => {
  const { data, loading, error } = useCustomerOverviewMrrByPkQuery({
    variables: {
      amagi_id: amagi_id,
    },
  });

  if (loading) return <Loader />;
  if (error) return <i>Error</i>;
  if (!data) return <i>No Customer MRR Data</i>;

  return (
    <div className="grid grid-cols-8 gap-4">
      <div className="grid col-span-4">
        <div className="bg-white p-4 rounded-md border">
          <CustomerMRR />
        </div>
      </div>
      <div className="grid col-span-4">
        <ServiceAvailability />
        <div className="mt-4">
          <Card
            title={`Products/Services (${
              Array.from(
                new Set(
                  data.customer?.orders
                    .map((o) =>
                      o.order_products.map((oi) => oi.product?.family)
                    )
                    .flat()
                    .filter((f) => f)
                )
              ).length
            })`}
          >
            <div className="flex flex-wrap text-sm">
              {Array.from(
                new Set(
                  data.customer?.orders
                    .map((o) =>
                      o.order_products.map((oi) => oi.product?.family)
                    )
                    .flat()
                    .filter((f) => f)
                )
              ).map((family) => (
                <span key={family} className="w-1/3 my-px text-primaryText">
                  {family}
                </span>
              ))}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default OverviewMRR;
