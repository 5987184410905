import React, { useMemo, useState } from "react";
import { useCDM } from "../../cdm-context";
import Loader from "../../common/loader";
import NestedTable from "../../common/nested-table";
import SubTableContainer from "../../common/sub-table";
import { useReceiptsByCustomerQuery } from "../../hasura.graphql";
import DelayedToolTip from "../delayed-tooltip";
import { DateParser, DateSort, DescDateSort } from "../utils";
import { TransactionType } from ".";
import TableRowsFilter from "../../common/filters/table-rows-filter";

const Receipts: React.FC<{
  transaction: TransactionType;
}> = ({ transaction }) => {
  const { show } = useCDM();
  const [rowId, setRowId] = useState<string | null>("");

  const statusColorCode = (status: string) => {
    if (status.includes("Deposited"))
      return "text-chartGreen border-chartGreen";
    return "border-gray-500";
  };

  const receipts_columns: any = useMemo(
    () => [
      {
        Header: "RECEIPT NO.",
        accessor: "receipt_no",
      },
      {
        Header: "STATUS",
        accessor: "status",
        Cell: ({ cell }: any) => (
          <span
            className={`border ${statusColorCode(
              cell.row.values.status
            )} rounded-full px-2 inline-flex leading-5 text-xs`}
          >
            {cell.row.values.status}
          </span>
        ),
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "TOTAL AMOUNT",
        accessor: "total_amount",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.total_amount !== "--"
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: cell.row.original.currency
                    ? cell.row.original.currency
                    : show?.currency,
                }).format(cell.row.values.total_amount)
              : "--"}
          </span>
        ),
        textAlign: "text-right",
      },
      {
        Header: "APPLIED AMOUNT",
        accessor: "applied_amount",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.applied_amount !== "--"
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: cell.row.original.currency
                    ? cell.row.original.currency
                    : show?.currency,
                }).format(cell.row.values.applied_amount)
              : "--"}
          </span>
        ),
        textAlign: "text-right",
      },
      {
        Header: "UNAPPLIED AMOUNT",
        accessor: "unapplied_amount",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.unapplied_amount !== "--"
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: cell.row.original.currency
                    ? cell.row.original.currency
                    : show?.currency,
                }).format(cell.row.values.unapplied_amount)
              : "--"}
          </span>
        ),
        textAlign: "text-right",
      },
      {
        Header: "RECEIPT DATE",
        accessor: "date",
        sortType: (a: any, b: any) => DateSort(a.values.date, b.values.date),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.date !== "--"
              ? DateParser(cell.row.values.date)
              : "--"}
          </span>
        ),
        autoResetSortBy: false,
      },
      {
        Header: "MEMO",
        accessor: "memo",
        Cell: ({ cell }: any) => (
          <DelayedToolTip title={cell.row.original.memo} position="top">
            <span className="inline-block">
              {cell.row.values.memo && cell.row.values.memo.length > 30
                ? `${cell.row.values.memo.slice(0, 30)}...`
                : cell.row.values.memo || "--"}
            </span>
          </DelayedToolTip>
        ),
      },
      {
        Header: "CURRENCY",
        accessor: "currency",
      },
    ],
    [show?.currency]
  );

  const receipts_line_columns: any = useMemo(
    () => [
      {
        Header: "DATE",
        accessor: "date",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.date !== "--"
              ? DateParser(cell.row.values.date)
              : "--"}
          </span>
        ),
      },
      {
        Header: "TYPE",
        accessor: "type",
        Filter: TableRowsFilter,
        position: "relative",
        filter: "multiSelect",
      },
      {
        Header: "REF. NO.",
        accessor: "netsuite_transaction_id",
      },
      {
        Header: "ORIGINAL AMOUNT",
        accessor: "original_amount",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.original_amount !== "--"
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: cell.row.values.receipt_currency
                    ? cell.row.values.receipt_currency
                    : show?.currency,
                }).format(cell.row.values.original_amount)
              : "--"}
          </span>
        ),
        textAlign: "text-right",
      },
      {
        Header: "AMOUNT DUE",
        accessor: "amount_due",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.amount_due !== "--"
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: cell.row.values.receipt_currency
                    ? cell.row.values.receipt_currency
                    : show?.currency,
                }).format(cell.row.values.amount_due)
              : "--"}
          </span>
        ),
        textAlign: "text-right",
      },
      {
        Header: "CURRENCY",
        accessor: "currency",
      },
      {
        Header: "PAYMENT",
        accessor: "payment",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.payment !== "--"
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: cell.row.values.receipt_currency
                    ? cell.row.values.receipt_currency
                    : show?.currency,
                }).format(cell.row.values.payment)
              : "--"}
          </span>
        ),
        textAlign: "text-right",
      },
    ],
    [show?.currency]
  );

  const { data, loading, error } = useReceiptsByCustomerQuery({
    variables: {
      amagi_id: show?.amg_id!,
    },
  });

  if (loading) return <Loader />;
  if (error) return <i>Error</i>;
  if (!data) return <i>No Data</i>;

  const filteredReceipts: Array<any> =
    data.customer?.netsuite_receipts &&
    data.customer?.netsuite_receipts.length > 0
      ? data.customer?.netsuite_receipts.map((rec) => {
          return {
            receipt_no: rec.receipt_no ? rec.receipt_no : "--",
            status: rec.status ? rec.status : "--",
            total_amount: rec.total_amount ? rec.total_amount : 0,
            applied_amount: rec.applied_amount ? rec.applied_amount : 0,
            unapplied_amount: rec.unapplied_amount ? rec.unapplied_amount : 0,
            date: rec.date ? rec.date : "--",
            memo: rec.memo ? rec.memo : "--",
            currency: rec.currency ? rec.currency : "--",
            receipts_lines:
              rec.netsuite_receipt_lines &&
              rec.netsuite_receipt_lines.map((line) => {
                return {
                  date: line.date ? line.date : "--",
                  type: line.type ? line.type : "--",
                  receipt_currency: rec.currency ? rec.currency : "--",
                  netsuite_transaction_id: line.netsuite_transaction_id
                    ? line.netsuite_transaction_id
                    : "--",
                  original_amount: line.original_amount
                    ? line.original_amount
                    : 0,
                  amount_due: line.amount_due ? line.amount_due : 0,
                  currency: line.currency ? line.currency : "--",
                  payment: line.payment ? line.payment : "--",
                };
              }),
          };
        })
      : [];

  filteredReceipts.sort((a, b) => DescDateSort(a.date, b.date));
  filteredReceipts.forEach((receipt) => {
    return receipt.receipts_lines.sort((a: { date: string; }, b: { date: string; }) => DescDateSort(a.date, b.date));
  });

  const exportData = filteredReceipts?.map((rec) => {
    return rec.receipts_lines.map((line: { date: any; type: any; netsuite_transaction_id: any; original_amount: number | bigint; amount_due: number | bigint; currency: any; payment: number | bigint; }) => {
      return {
        receipt_no: rec.receipt_no ? rec.receipt_no : "--",
        status: rec.status ? rec.status : "--",
        total_amount: rec.total_amount
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: rec.currency || show?.currency,
            }).format(rec.total_amount)
          : "--",
        applied_amount: rec.applied_amount
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: rec.currency || show?.currency,
            }).format(rec.applied_amount)
          : "--",
        unapplied_amount: rec.unapplied_amount
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: rec.currency || show?.currency,
            }).format(rec.unapplied_amount)
          : "--",
        receipt_date: rec.date ? rec.date : "--",
        memo: rec.memo ? rec.memo : "--",
        currency: rec.currency ? rec.currency : "--",
        line_date: line.date ? line.date : "--",
        type: line.type ? line.type : "--",
        netsuite_transaction_id: line.netsuite_transaction_id
          ? line.netsuite_transaction_id
          : "--",
        original_amount: line.original_amount
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: rec.currency || show?.currency,
            }).format(line.original_amount)
          : "--",
        amount_due: line.amount_due
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: rec.currency || show?.currency,
            }).format(line.amount_due)
          : "--",
        line_currency: line.currency ? line.currency : "--",
        payment: line.payment
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: rec.currency || show?.currency,
            }).format(line.payment)
          : "--",
      };
    });
  });

  const exportHeaders = [
    { label: "Receipt No.", key: "receipt_no" },
    { label: "Status", key: "status" },
    { label: "Total Amount", key: "total_amount" },
    { label: "Applied Amount", key: "applied_amount" },
    { label: "Unapplied Amount", key: "unapplied_amount" },
    { label: "Receipt Date", key: "date" },
    { label: "Memo", key: "memo" },
    { label: "Receipt Currency", key: "currency" },
    { label: "Receipt Line Date", key: "date" },
    { label: "Type", key: "type" },
    { label: "Ref. No.", key: "netsuite_transaction_id" },
    { label: "Original Amount", key: "original_amount" },
    { label: "Amount Due", key: "amount_due" },
    { label: "Receipt Line Currency", key: "currency" },
    { label: "Payment", key: "payment" },
  ];

  return (
    <div>
      {filteredReceipts.length > 0 && (
        <div className="mt-4">
          <NestedTable
            searchParam={transaction.id}
            data={filteredReceipts}
            columns={receipts_columns}
            height={`${window.innerHeight - 310}px`}
            open={rowId}
            setOpen={setRowId}
            exportData={exportData}
            fileName={`${show?.amg_id}_Receipts`}
            exportHeader={exportHeaders}
            isToggle={false}
            header="Receipts"
            warning="Receipts"
            subTable={
              <>
                {rowId &&
                  filteredReceipts[parseInt(rowId!)].receipts_lines.length >
                    0 && (
                    <SubTableContainer
                      header={`(${
                        filteredReceipts[parseInt(rowId!)].receipts_lines.length
                      }) Receipt Lines for ${
                        filteredReceipts[parseInt(rowId!)].receipt_no
                      }`}
                      placeholder={`Search for line items`}
                      data={
                        rowId
                          ? filteredReceipts[parseInt(rowId!)].receipts_lines
                          : []
                      }
                      columns={receipts_line_columns}
                    />
                  )}
              </>
            }
          />
        </div>
      )}
    </div>
  );
};

export default Receipts;