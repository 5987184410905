import { ReactNode } from "react";

export const ViewList: React.FC<{
  name: string;
  bigTitle?: boolean;
  children: ReactNode;
}> = ({ name, bigTitle = false, children }) => (
  <div className="flex flex-col">
    <span className="text-sm">{name}</span>
    <span className="mt-1 text-base font-medium">{children}</span>
  </div>
);
