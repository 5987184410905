import React from "react";
import { DateParser } from "./utils";
import DelayedToolTip from "./delayed-tooltip";
import { InformationCircleIcon } from "@heroicons/react/outline";

const Timeline: React.FC<{
  items: Array<{
    name: string;
    active: boolean;
    date: string | null;
    reason: string | null;
  }>;
}> = ({ items }) => {
  const totalItems = items.length;
  const numberOfActiveItems = items.filter((item) => item.active).length;
  const progressBarWidth =
    totalItems > 1 ? ((numberOfActiveItems - 1) / (totalItems - 1)) * 100 : 0;
  return (
    <div className="timeline">
      <div
        className="timeline-progress"
        style={{ width: `${progressBarWidth}%` }}
      ></div>
      <div className="timeline-items">
        {items.map((item, i) => (
          <div
            key={i}
            className={"timeline-item" + (item.active ? " active" : "")}
          >
            <div className={`timeline-content even`}>{item.name}</div>
            <div className={`timeline-content-date`}>
              {item.date ? DateParser(item.date!) : "-"}
            </div>
            {item.reason ? (
              <div className={`timeline-content-reason flex items-center`}>
                <span>
                  {item.reason.length > 15
                    ? `${item.reason.slice(0, 15)}...`
                    : item.reason}
                </span>
                <div className="ml-2">
                  <DelayedToolTip position="top" delay={0} title={item.reason}>
                    <InformationCircleIcon className="w-4 h-4" />
                  </DelayedToolTip>
                </div>
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Timeline;
