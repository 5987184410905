import React from "react";
import {
  SortDescendingIcon,
  SortAscendingIcon,
  MenuAlt3Icon,
} from "@heroicons/react/solid";
import {
  Column,
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
  ColumnGroup,
  useFilters,
  Row,
} from "react-table";
import { TableInstanceWithHooks } from "./custom-table";
import TableFilter from "./table-filter";

export type ExportHeaderType = {
  label: string;
  key: string;
};

const SubTableContainer: React.FC<{
  placeholder?: string;
  columns: Column<ColumnGroup>[];
  header?: string;
  data: any;
  width?: number;
  isSearchable?: boolean;
}> = ({
  columns,
  header,
  data,
  width = 415,
  placeholder = "Search",
  isSearchable = true,
}) => {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      multiSelect: (rows: Row<any>[], id: string | any, filterValues: any) => {
        if (filterValues.length === 0) return [];
        return rows.filter((r) => filterValues.includes(r.values[id]));
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    setGlobalFilter,
    rows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0, hiddenColumns: [] },
      autoResetGlobalFilter: false,
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as TableInstanceWithHooks<object>;

  const { globalFilter } = state;

  const tableStyle: any =
    rows.length > 0
      ? {
          overflowX: "scroll",
          overflowY: "auto",
          whiteSpace: "nowrap",
          borderRadius: "10px",
        }
      : {
          overflowX: "scroll",
          overflowY: "auto",
          whiteSpace: "nowrap",
          minHeight: "200px",
          borderRadius: "10px",
        };

  return (
    <div className="mr-3">
      {isSearchable && (
        <div
          className="custom_container p-2 px-4 flex justify-start items-center"
          style={{
            width: `${window.innerWidth - (width - 280)}px`,
          }}
        >
          <div className="text-left font-medium">{header}</div>
          <div className="w-1/2 flex justify-end items-center">
            <div className="w-4/5">
              <TableFilter
                filter={globalFilter}
                setFilter={setGlobalFilter}
                placeholder={placeholder}
              />
            </div>
          </div>
        </div>
      )}

      {data.length > 0 || rows.length > 0 ? (
        <div
          className=""
          style={{
            width: `${window.innerWidth - (width - 290)}px`,
          }}
        >
          <div style={tableStyle}>
            <table
              {...getTableProps()}
              className={`divide-y divide-gray-300 roundedCorners`}
            >
              <thead
                className="bg-gray-50"
                // style={{ overflowX: "scroll", width: "100%" }}
              >
                {headerGroups.map((headerGroup: any, index: number) => (
                  <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any, hIndex: number) => (
                      <th
                        key={hIndex}
                        scope="col"
                        className="py-3.5 text-left font-medium sm:pl-6"
                        style={{ fontSize: "12px", color: "#47536B" }}
                      >
                        <div
                          className={`flex ${
                            column.textAlign ? "justify-end" : ""
                          }`}
                        >
                          <span className="text-center">
                            {column.render("Header")}
                          </span>
                          <div className="ml-1">
                            {column.canFilter ? column.render("Filter") : null}
                          </div>
                          {column.render("Header") !== "DELIVERY" ? (
                            <span
                              className="mt-px ml-5"
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortDescendingIcon className="h-4 w-4" />
                                ) : (
                                  <SortAscendingIcon className="h-4 w-4" />
                                )
                              ) : (
                                <MenuAlt3Icon className="h-4 w-4" />
                              )}
                            </span>
                          ) : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                {...getTableBodyProps()}
                className="divide-y divide-gray-200 bg-white"
              >
                {rows.map((row: any) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="last:rounded-b">
                      {row.cells.map((cell: any) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={`${
                              cell.column.textAlign
                                ? cell.column.textAlign
                                : "text-left"
                            } whitespace-nowrap py-2 pr-3 font-normal`}
                            style={{ color: "#181D25" }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="p-4 bg-white">No Records found</div>
      )}
    </div>
  );
};

export default SubTableContainer;
