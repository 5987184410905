import { ChartOptions } from "chart.js";
import { ChangeEvent, useState } from "react";
import PopoverMenu from "../popover-menu";
import { Pie } from "react-chartjs-2";
import { DeliveryInfoFragment } from "../../hasura.graphql";
import _, { camelCase, startCase } from "lodash";
import { DeliveryRegionsType } from "../channel-delivery/delivery-edit-form/types";
import { cloudportProducts } from "../utils";

const OverviewChart: React.FC<{
  data: DeliveryInfoFragment[];
  filterBy: string;
  groupBy: string;
  groupMap: Array<{ original: string[]; replace_with: string }>;
  countBy: string;
  header: string;
  customColor?: boolean;
  isFilter: boolean;
}> = ({
  data,
  header,
  filterBy,
  groupBy,
  groupMap,
  countBy,
  customColor = false,
  isFilter,
}) => {
  const deliveryData = data.map((data) => ({
    ...data,
    platform_name: data.platform?.name,
    region: data.delivered_region
      .map((del: DeliveryRegionsType) => del.region.trim())
      .sort()
      .join(),
    businessModel: data.business_model
      .map((model: string) => model.trim())
      .sort()
      .join(),
    ssaiString: data.ssai !== undefined ? (data.ssai ? "Yes" : "No") : "-",
    cloudport:
      data.product_purchased_for_this_delivery &&
      data.product_purchased_for_this_delivery.filter((data: any) =>
        cloudportProducts.includes(data.code)
      ).length > 0
        ? "Yes"
        : "No",
  }));

  const overviewData = (
    data: number[],
    labels: string[],
    colors?: string[] | undefined
  ) => {
    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: colors,
          borderColor: ["#fff", "#fff", "#fff", "#fff", "#fff", "#fff"],
          borderWidth: 2,
        },
      ],
    };
  };

  const colors = [
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(255, 159, 64, 1)",
  ];

  const filterOptions = Array.from(
    new Set(
      deliveryData.map((dt) => dt[filterBy as keyof DeliveryInfoFragment])
    )
  ).map((d) => ({
    key: d,
    value: startCase(d),
  }));

  const statusColorCode = (sts: string) => {
    if (sts === "Delivered & Active") return "#8AD879";
    else if (sts === "Ordering") return "#5ACFC9";
    else if (sts === "Provisioning") return "#FA9F42";
    else if (sts === "Provisioning Backlog") return "#FCC287";
    else if (sts === "Provisioning On Hold") return "#F98610";
    else if (sts === "Suspended") return "#E84258";
    else if (sts=== "Delivery Closed Lost") return "#FFD700";
    else return "";
  };

  const recursiveColor: (index: number, colors: string[]) => string = (
    index,
    colors
  ) => {
    if (index >= colors.length) {
      if (index >= Math.abs(colors.length - index)) {
        return recursiveColor(Math.abs(colors.length - index), colors);
      }
      return colors[colors.length - index];
    }
    return colors[index];
  };

  const [filter, setFilter] = useState(
    isFilter ? [filterOptions[0]] : filterOptions
  );

  const options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 1,
      },
    },
  };

  const chartData = _.orderBy(
    Object.entries(
      _.groupBy(
        deliveryData
          .filter((d) =>
            filter
              .map((fl) => fl.key)
              .includes(d[filterBy as keyof DeliveryInfoFragment])
          )
          .map((d) => {
            let groupedObj = d[groupBy as keyof DeliveryInfoFragment];
            return {
              section:
                groupMap.length > 0
                  ? groupMap.find((g) => g.original.includes(groupedObj))
                      ?.replace_with
                  : startCase(groupedObj) || "-",
              value: _.get(d, countBy),
            };
          }),
        (d) => d.section
      )
    ).map(([key, value], index) => ({
      name: key,
      count: Array.from(new Set(value.map((v) => v.value))).length,
      color: customColor ? statusColorCode(key) : recursiveColor(index, colors),
    })),
    (o) => o.count,
    ["desc"]
  );

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const t = e.target.value;
    if (filter && filter.map((f) => f.key).includes(t)) {
      setFilter((prevFilter) => {
        return prevFilter.filter((v) => v.key !== t);
      });
    } else {
      setFilter((prevFilter) => [
        ...prevFilter,
        { key: t, value: startCase(t) },
      ]);
    }
  };

  return (
    <div className="bg-white max-h-64 border rounded-lg">
      <div className="flex justify-between border-b">
        <div className="text-left pl-4 py-2 font-medium">{header}</div>
        {isFilter && (
          <div>
            <PopoverMenu
              icon={filter!.length > 0 ? "filter-fill.svg" : "filter.svg"}
              styles="mr-3 mt-2"
              positionStyles="right-4"
              position="relative"
              header="Filter"
            >
              {filterOptions.map((option, index) => (
                <div className="py-1 top-0" key={index}>
                  <input
                    type="checkbox"
                    className="focus:ring-0"
                    id={option.key}
                    name={camelCase(header)}
                    value={option.key}
                    onChange={onChange}
                    title={option.key}
                    checked={filter.map((f) => f.key).includes(option.key)}
                  />
                  <label htmlFor={option.key} className="pl-2 text-xs">
                    {option.value}
                  </label>
                </div>
              ))}
            </PopoverMenu>
          </div>
        )}
      </div>
      {chartData.length > 0 ? (
        <div className="p-3 flex items-center">
          <div className="h-40 w-2/3">
            <Pie
              data={overviewData(
                chartData.map((co) => co.count),
                chartData.map((co) => {
                  return co.name;
                }),
                chartData.map((co) => co.color!)
              )}
              options={options}
            />
          </div>
          <div className="flex flex-col max-h-40 overflow-y-scroll">
            {chartData.map((data, index) => (
              <div className="flex items-center my-px" key={index}>
                <div className="w-4 h-1.5" style={{ background: data.color }} />
                <div className="text-xs ml-2">{`${data.name} (${data.count})`}</div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="p-4 italic font-medium">No Delivery Data</div>
      )}
    </div>
  );
};

export default OverviewChart;
