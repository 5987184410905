import { MinusIcon, PlusIcon } from "@heroicons/react/outline";
import React, { ChangeEvent, useMemo, useState } from "react";
// import TableFilter from "../../common/table-filter";
// import { useDebounce } from "use-debounce";
import { AllCustomerDataFragment } from "../../hasura.graphql";

const Filter: React.FC<{
  label: string;
  filter_type: string;
  customers: AllCustomerDataFragment[];
}> = ({ label, filter_type, customers }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState<any>([]);
  console.log(filter);
  // const [columnSearch, setColumnSearch] = useState<string>("");
  // const [debouncedColumnSearch] = useDebounce(columnSearch, 100);

  const options = useMemo(() => {
    let counts: any = {};
    customers.forEach((x: any) => {
      x = x[filter_type];
      counts[x] = (counts[x] || 0) + 1;
    });
    return counts;
  }, [filter_type, customers]);

  // const debouncedColumns = Object.entries(options).filter(([opt, count]) => {
  //   if (!debouncedColumnSearch) {
  //     return true;
  //   }
  //   return opt.toLowerCase().includes(debouncedColumnSearch.toLowerCase());
  // });

  const [checked, setChecked] = useState<Array<string>>([]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const t = e.target.name;
    if (checked.length === 1 && checked.includes(t)) {
      setFilter(Object.keys(options));
      setChecked([]);
    } else if (checked && checked.includes(t)) {
      setFilter(checked.filter((v) => v !== t));
      setChecked((prevChecked) => {
        return prevChecked.filter((v) => v !== t);
      });
    } else {
      setFilter([...checked, t]);
      setChecked((prevChecked) => [...prevChecked, t]);
    }
  };

  return (
    <div className="border-b last:border-0">
      <div className="flex px-1 py-3 items-center font-medium">
        <div
          className="text-base cursor-pointer"
          onClick={() => setOpenFilter(!openFilter)}
        >
          {openFilter ? (
            <MinusIcon className="h-4 w-4 font-semibold" />
          ) : (
            <PlusIcon className="h-4 w-4 font-semibold" />
          )}
        </div>
        <div className="ml-3">{label}</div>
      </div>
      <div className={`${openFilter ? "" : "hidden"}`}>
        {Object.entries(options)
          .sort()
          .map(([option, count]: [string, any], index: number) => (
            <div className="px-5 py-2 text-sm top-0" key={index}>
              <input
                type="checkbox"
                className="focus:ring-0"
                id={option}
                name={option}
                onChange={onChange}
                title={`${option} (${count})`}
                checked={checked.includes(option)}
              />
              <label
                htmlFor={option}
                className="pl-2"
              >{`${option} (${count})`}</label>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Filter;
