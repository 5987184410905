import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";

import reportWebVitals from "./reportWebVitals";
// import awsExports from "./aws-exports";

import "./index.css";
// import "@aws-amplify/ui-react/styles.css";
// import { PublicRoutes } from "./public-routes";
import LoginPage from "./pages/login";
import { ThemeProvider } from "@amagimedia/design-system";

Amplify.configure({
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  aws_cognito_identity_pool_id: process.env.REACT_APP_IDENTITY_POOL_ID,
  oauth: {
    domain: process.env.REACT_APP_OAUTH_DOMAIN,
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: process.env.REACT_APP_DOMAIN,
    redirectSignOut: process.env.REACT_APP_DOMAIN,
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Router>
    <ThemeProvider theme="light">
      <AmplifyProvider>
        <Authenticator.Provider>
          <Routes>
            <Route path="/*" element={<LoginPage />} />
          </Routes>
        </Authenticator.Provider>
      </AmplifyProvider>
    </ThemeProvider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
