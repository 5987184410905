import { useEffect, useState } from "react";
import { useCustomerNetsuitePdfQuery } from "../../hasura.graphql";
import Loader from "../../common/loader";
import { DocumentDownloadIcon } from "@heroicons/react/outline";

interface NetsuitePDFProps {
  transaction_id: string;
}

const NetsuitePDF: React.FC<NetsuitePDFProps> = ({ transaction_id }) => {
  const [base64File, setBase64File] = useState("");

  const { data, loading, error } = useCustomerNetsuitePdfQuery({
    variables: {
      id: transaction_id,
    },
  });

  useEffect(() => {
    if (data) {
      setBase64File(data.transaction_file?.base64_file || "");
    }
  }, [data]);

  if (loading) return <Loader />;

  if (error) return <div>No Documents for {transaction_id}</div>;

  function downloadPDF() {
    const pdfData = `data:application/pdf;base64,${base64File}`;
    const a = document.createElement("a");
    a.href = pdfData;
    a.download = `${transaction_id}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return (
    <>
      {base64File ? (
        <div style={{ position: "relative" }}>
          <button
            onClick={downloadPDF}
            style={{
              padding: "10px 20px",
              backgroundColor: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              position: "absolute",
              top: "-50px",
              right: "80px",
            }}
          >
            <DocumentDownloadIcon className="w-5 h-5" /> Download PDF
          </button>
          <iframe
            title={transaction_id}
            className="w-full"
            style={{ height: "80vh" }}
            src={`data:application/pdf;base64,${base64File}#toolbar=0&zoom=100`}
          />
        </div>
      ) : (
        <div>No Documents for {transaction_id}</div>
      )}
    </>
  );
};

export default NetsuitePDF;
