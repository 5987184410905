import { SearchIcon, XIcon } from "@heroicons/react/outline";
import { useCallback, useEffect, useState } from "react";
import { capitalize } from "../components/utils";
import {
  useGetCustomerDetailsQuery,
  useSearchCustomerQuery,
} from "../hasura.graphql";
import Loader from "./loader";

export const CustomerFilter = ({
  filter,
  setFilter,
  setSearchData,
  placeholder,
}: any) => {
  const [search, setSearch] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");

  const getCategory = useCallback((mrr: number | null) => {
    if (mrr !== null) {
      if (mrr > 100000) {
        return "Platinum";
      } else if (mrr > 50000) {
        return "Gold";
      } else {
        return "Silver";
      }
    } else {
      return "Silver";
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchValue(search);
      setFilter(search);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue, search, filter, setFilter]);

  const { data, loading, error } = useSearchCustomerQuery({
    variables: {
      query: searchValue,
    },
    skip: !searchValue,
    onCompleted: (data) => {
      const filteredSearchData = data.search_customer.map((cust) => {
        return {
          amagi_id: cust.amagi_id,
          account_name: cust.account_name,
          account_legal_name: cust.account_legal_name,
          customer_type: cust.customer_type ? cust.customer_type : "-",
          record_type: cust.record_type?.name ? cust.record_type?.name : "-",
          tam: cust.tam ? cust.tam : "-",
          invoicing_currency: cust.invoicing_currency,
          segment: cust.industry_segment ? cust.industry_segment : "-",
          region: cust.address?.region ? cust.address?.region : "-",
          size: cust.size ? cust.size : "-",
          customer_status: cust.status ? cust.status : "-",
          logo: cust.logo,
          category: cust.customer_tier
            ? capitalize(cust.customer_tier)
            : getCategory(cust.invoices_aggregate.aggregate?.sum?.total_amount),
        };
      });
      setSearchData(filteredSearchData);
    },
  });

  if (loading) {
    return (
      <span className="flex">
        <div className="flex items-center border rounded pr-3 bg-white w-4/5">
          <SearchIcon className="w-10 h-8 p-2 text-gray-300 rounded bg-white outline-none border-gray-300" />
          <input
            autoFocus
            className="w-full h-8 border-0 p-0 pr-2 text-sm focus:ring-0 focus:border-gray-300 bg-white outline-none border-gray-300"
            type="text"
            value={search || ""}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={placeholder}
          />
          {search && search.length > 0 && (
            <XIcon className="h-5 w-5" onClick={() => setSearch("")} />
          )}
        </div>
        <Loader />
      </span>
    );
  }

  if (error) {
    return <i>Error</i>;
  }

  if (data?.search_customer && data.search_customer.length === 0) {
    return (
      <FallbackFilter
        filter={filter}
        setFilter={setFilter}
        setSearchData={setSearchData}
        placeholder={placeholder}
        customSearchValue={searchValue}
        setCustomerSearchValue={setSearchValue}
        setCustomerSearch={setSearch}
      />
    );
  }

  return (
    <span className="relative">
      {/* {searchAttributes && (
        <Popup align="top-10 left-44">
          <div className="break-words">
            Search by Amagi ID, Account Name, Account Legal Name, Segment,
            Region, Size, Customer Category, Customer Type, Sales Region,
            Industry Segment, Customer Since, NetSuite ID, Customer Currency,
            Products Purchased, Product Family, Sales Account Manager, Technical
            Account Manager, Channels, Platforms, Payment Status
          </div>
        </Popup>
      )} */}
      <div className="flex items-center border rounded pr-3 bg-white">
        <SearchIcon
          // onMouseOver={() => setSearchAttributes(true)}
          // onMouseOut={() => setSearchAttributes(false)}
          className="w-10 h-8 p-2 rounded text-gray-300 bg-white outline-none border-gray-300"
        />
        <input
          autoFocus
          className="w-full h-8 p-0 border-0 pr-2 text-sm rounded-r-md focus:ring-0 focus:border-gray-300 bg-white outline-none border-gray-300"
          type="text"
          value={search || ""}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={placeholder}
        />
        {search && search.length > 0 && (
          <XIcon className="h-5 w-5" onClick={() => setSearch("")} />
        )}
      </div>
    </span>
  );
};

export const FallbackFilter = ({
  filter,
  setFilter,
  setSearchData,
  placeholder,
  customSearchValue,
  setCustomerSearchValue,
  setCustomerSearch,
}: any) => {
  const [search, setSearch] = useState<string>(customSearchValue);
  const [searchValue, setSearchValue] = useState<string>(customSearchValue);

  const getCategory = useCallback((mrr: number | null) => {
    if (mrr !== null) {
      if (mrr > 100000) {
        return "Platinum";
      } else if (mrr > 50000) {
        return "Gold";
      } else {
        return "Silver";
      }
    } else {
      return "Silver";
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchValue(search);
      setCustomerSearchValue(search);
      setFilter(search);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue, search, filter, setFilter, setCustomerSearchValue]);

  const { loading, error } = useGetCustomerDetailsQuery({
    variables: {
      amagi_id_or_customer_name: searchValue,
    },
    skip: !searchValue,
    onCompleted: (data) => {
      const filteredSearchData = data.customer.map((cust) => {
        return {
          amagi_id: cust.amagi_id,
          account_name: cust.account_name,
          account_legal_name: cust.account_legal_name,
          customer_type: cust.customer_type ? cust.customer_type : "-",
          invoicing_currency: cust.invoicing_currency,
          segment: cust.industry_segment ? cust.industry_segment : "-",
          region: cust.address?.region ? cust.address?.region : "-",
          size: cust.size ? cust.size : "-",
          customer_status: cust.status ? cust.status : "-",
          logo: cust.logo,
          category: cust.customer_tier
            ? capitalize(cust.customer_tier)
            : getCategory(cust.invoices_aggregate.aggregate?.sum?.total_amount),
        };
      });
      setSearchData(filteredSearchData);
    },
  });

  if (loading) {
    return (
      <span className="flex">
        <div className="flex items-center border rounded pr-3 bg-white w-4/5">
          <SearchIcon className="w-10 h-8 p-2 text-gray-300 rounded bg-white outline-none border-gray-300" />
          <input
            autoFocus
            className="w-full h-8 border-0 p-0 pr-2 text-sm focus:ring-0 focus:border-gray-300 bg-white outline-none border-gray-300"
            type="text"
            value={search || ""}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={placeholder}
          />
          {search && search.length > 0 && (
            <XIcon className="h-5 w-5" onClick={() => setSearch("")} />
          )}
        </div>
        <Loader />
      </span>
    );
  }

  if (error) {
    return <i>Error</i>;
  }

  return (
    <span className="relative">
      <div className="flex items-center border rounded pr-3 bg-white">
        <SearchIcon className="w-10 h-8 p-2 rounded text-gray-300 bg-white outline-none border-gray-300" />
        <input
          autoFocus
          className="w-full h-8 p-0 border-0 pr-2 text-sm rounded-r-md focus:ring-0 focus:border-gray-300 bg-white outline-none border-gray-300"
          type="text"
          value={search || ""}
          onChange={(e) => {
            setSearch(e.target.value);
            setCustomerSearch(e.target.value);
          }}
          placeholder={placeholder}
        />
        {search && search.length > 0 && (
          <XIcon className="h-5 w-5" onClick={() => setSearch("")} />
        )}
      </div>
    </span>
  );
};
