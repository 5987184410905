import React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Login, Icon, IconType } from "@amagimedia/design-system";

import App from "../App";
import "@aws-amplify/ui-react/styles.css";
import LoginForm from "../components/login-form";

const LoginPage = () => {
  const { route } = useAuthenticator((context) => [context.route]);

  if (route === "setup") {
    return (
      <div id="login-footer">
        <Login
          sliderImages={[
            {
              image: "/assets/login-bg.png",
              title: "background Image - CDP",
              description: "",
            },
          ]}
          loginPageDescription={
            <React.Fragment>
              <div className="w-1/2 ml-4">
                <div className="text-2xl">
                  Amagi's Customer Data Platform provides a 360 degree view of
                  customer with contextual insights
                </div>
              </div>
            </React.Fragment>
          }
          headerLogo="/logo-white.svg"
          headerText={
            <React.Fragment>
              <Icon
                size={16}
                style={{ color: "#fff" }}
                type={IconType["email"]}
              />
              <a href="mailto:cdp-support@amagi.com" style={{ color: "#fff" }}>
                cdp-support@amagi.com
              </a>
            </React.Fragment>
          }
          productLogo="/assets/cdp-logo.svg"
          renderForm={<LoginForm />}
        />
      </div>
    );
  }

  return <App />;
};

export default LoginPage;
