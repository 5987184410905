import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarController,
  LineController,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  PointElement,
  Legend,
  LogarithmicScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LogarithmicScale,
  LinearScale,
  BarController,
  LineController,
  BarElement,
  ChartDataLabels,
  LineElement,
  Title,
  PointElement,
  Tooltip,
  Legend
);

const DQChart: React.FC<{
  chartData: any;
  stackedBar?: boolean;
  chartType?: string;
  chartOptions?: any;
}> = ({ chartData, stackedBar = false, chartType = "linear" }) => {
  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        stacked: stackedBar,
        grid: {
          drawBorder: false,
          display: true,
        },
        min: 0,
        ticks: {
          padding: 8,
          font: {
            size: 10,
          },
          beginAtZero: true,
        },
        type: chartType,
      },
      x: {
        stacked: stackedBar,
        ticks: {
          font: {
            size: 10,
          },
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += `${context.parsed.y}`;
            }
            return label;
          },
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
  };

  return <Chart type="bar" data={chartData} options={options} />;
};

export default DQChart;
