import {
  ChevronDownIcon,
  ChevronRightIcon,
  SearchIcon,
} from "@heroicons/react/outline";
import _ from "lodash";
import { useState } from "react";
import ProgressBarComp from "../progress-bar";
import { useDataQualityDetailsQuery } from "../../hasura.graphql";
import { Loader } from "@aws-amplify/ui-react";
import { DateTime } from "luxon";
import { capitalize, DateParser } from "../utils";
import { useDebounce } from "use-debounce";

const DQProgressBars: React.FC = () => {
  const [toggleRulesTabs, setToggleRulesTabs] = useState<string[]>([]);
  const [toggleMetrics, setToggleMetrics] = useState(true);
  const [keyMetricsSearch, setKeyMetricsSearch] = useState("");
  const [rulesMetricsSearch, setRulesMetricsSearch] = useState("");
  const [debouncedKeyMetricsSearch] = useDebounce(keyMetricsSearch, 100);
  const [debouncedRulesMetricsSearch] = useDebounce(rulesMetricsSearch, 100);

  const { data, loading, error } = useDataQualityDetailsQuery({
    variables: {
      from_date: DateTime.local()
        .startOf("day")
        .minus({ weeks: 1 })
        .toISO()
        .slice(0, 10),
      to_date: DateTime.local().toISO().slice(0, 10),
    },
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <i>Error</i>;
  }

  if (!data) {
    return <i>Data Quality details not found</i>;
  }

  const handleHideTabs = (tab: string) => {
    setToggleRulesTabs((toggleRulesTabs) => [...toggleRulesTabs, tab]);
  };
  const handleUnhideTabs = (tab: string) => {
    setToggleRulesTabs(toggleRulesTabs.filter((f) => f !== tab));
  };

  const filteredMetricsData = data.key_metrics
    .filter((data) => data.column_name?.includes("custom:"))
    .map((data) => {
      return {
        date: data.date,
        column_name: capitalize(
          data.column_name?.replaceAll("_", " ").replaceAll("custom:", "")
        ),
        completeness: (data.custom_value * 100).toFixed(2),
      };
    });

  const filteredRulesData = Object.entries(
    _.groupBy(data.data_quality, (table) => table.table_name)
  ).map(([key, value]) => {
    const accuracy = value.reduce((a, b) => {
      return a + b.accuracy;
    }, 0);
    const completeness = value.reduce((a, b) => {
      return a + b.completeness_weight;
    }, 0);
    return {
      date: value[0].date,
      table_name: capitalize(key.replaceAll("_", " ")),
      accuracy: Math.round(accuracy / value.length),
      completeness: Math.round(completeness / value.length),
      columns: value.map((col) => {
        return {
          date: col.date,
          column_name: capitalize(col.column_name?.replaceAll("_", " ")),
          accuracy: Math.round(col.accuracy),
          completeness: Math.round(col.completeness),
        };
      }),
    };
  });

  const debouncedMetricsTable = filteredMetricsData.filter((data) => {
    if (!debouncedKeyMetricsSearch) {
      return true;
    }
    return `${data.column_name}`
      .toLowerCase()
      .includes(debouncedKeyMetricsSearch.toLowerCase());
  });

  const debouncedRulesTable = filteredRulesData.some((data) =>
    data.table_name
      .toLowerCase()
      .includes(debouncedRulesMetricsSearch.toLowerCase())
  )
    ? filteredRulesData.filter((data) => {
        if (!debouncedRulesMetricsSearch) {
          return true;
        }
        return `${data.table_name}`
          .toLowerCase()
          .includes(debouncedRulesMetricsSearch.toLowerCase());
      })
    : filteredRulesData
        .filter((data) => {
          if (!debouncedRulesMetricsSearch) {
            return true;
          }
          return `${data.columns.map((col) => col.column_name).join("")}`
            .toLowerCase()
            .includes(debouncedRulesMetricsSearch.toLowerCase());
        })
        .map((dt) => {
          return Object.assign({}, dt, {
            columns: dt.columns.filter((col) => {
              if (!debouncedRulesMetricsSearch) {
                return true;
              }
              return `${col.column_name}`
                .toLowerCase()
                .includes(debouncedRulesMetricsSearch.toLowerCase());
            }),
          });
        });

  return (
    <>
      <div className="mb-4">
        <div className="py-3 flex justify-between items-center">
          <div className="font-medium flex items-center">
            <div className="flex items-center">
              <div className="bg-white">
                {toggleMetrics ? (
                  <ChevronDownIcon
                    onClick={() => setToggleMetrics(false)}
                    className="h-6 w-6 border border-primaryColor bg-primaryColor text-white rounded p-px px-1 cursor-pointer"
                  />
                ) : (
                  <ChevronRightIcon
                    onClick={() => setToggleMetrics(true)}
                    className="h-6 w-6 border border-gray-200 rounded-sm p-px px-1 cursor-pointer"
                  />
                )}
              </div>
              <div className="ml-3">Key Data Quality Metrics</div>
            </div>
            <span className="ml-2 text-xs">{`(${DateParser(
              filteredMetricsData[0].date
            )})`}</span>
          </div>
          <div className="flex w-1/4 justify-between items-center bg-white border">
            <div className="flex w-full bg-white">
              <SearchIcon
                style={{ color: "#B5BAC5" }}
                className="h-10 w-10 p-2 rounded-l-md"
              />
              <input
                className="w-full pl-0 text-sm rounded-r-md focus:ring-0 focus:border-0 border-0 bg-white outline-none"
                type="text"
                value={keyMetricsSearch}
                onChange={(e) => setKeyMetricsSearch(e.target.value)}
                placeholder="Search by Attributes"
              />
            </div>
          </div>
        </div>
        <div className="bg-white border border-gray-300">
          {toggleMetrics && (
            <>
              <div
                className="flex justify-start p-2 border-b border-gray-300"
                style={{ background: "#FAFAFA" }}
              >
                <span className="mx-6"></span>
                <span className="text-xs w-1/3">DESCRIPTION</span>
                <span className="text-xs w-1/3">COMPLETENESS</span>
              </div>
              <div>
                {debouncedMetricsTable.map((data, index) => (
                  <div key={index}>
                    <div
                      key={index}
                      className={`flex justify-start items-center h-14 pl-3 border-b border-gray-200`}
                      style={{ background: "#BCCDFB26" }}
                    >
                      <span
                        className="mx-4 text-sm"
                        style={{ color: "#A9AFBC" }}
                      >
                        {index + 1}
                      </span>
                      <span
                        className="w-1/3 pl-1 text-sm"
                        style={{ color: "#47536B" }}
                      >
                        {data.column_name}
                      </span>
                      <span className="w-1/3">
                        <ProgressBarComp
                          progress={parseFloat(data.completeness)}
                          color="#F8C9F1"
                        />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="mt-4">
          <div className="flex items-center justify-between">
            <div className="flex font-medium items-center py-4">
              <div className="bg-white">
                {toggleRulesTabs.length === 0 ? (
                  <ChevronRightIcon
                    onClick={() =>
                      setToggleRulesTabs(
                        debouncedRulesTable.map((data) => data.table_name)
                      )
                    }
                    className="h-6 w-6 border border-gray-200 rounded-sm p-px px-1 cursor-pointer"
                  />
                ) : (
                  <ChevronDownIcon
                    onClick={() => setToggleRulesTabs([])}
                    className="h-6 w-6 border border-primaryColor bg-primaryColor text-white rounded p-px px-1 cursor-pointer"
                  />
                )}
              </div>
              <div className="font-semibold ml-3">
                Data Model Attribute Completeness Metrics
              </div>
              <span className="ml-2 text-xs">{`(${DateParser(
                filteredRulesData[0].date
              )})`}</span>
            </div>
            <div className="flex w-1/4 justify-between items-center bg-white border">
              <div className="flex w-full bg-white">
                <SearchIcon
                  style={{ color: "#B5BAC5" }}
                  className="h-10 w-10 p-2 rounded-l-md"
                />
                <input
                  className="w-full pl-0 text-sm rounded-r-md focus:ring-0 focus:border-0 border-0 bg-white outline-none"
                  type="text"
                  value={rulesMetricsSearch}
                  onChange={(e) => setRulesMetricsSearch(e.target.value)}
                  placeholder="Search by Attributes"
                />
              </div>
            </div>
          </div>
          <div className="bg-white border border-gray-300">
            <div
              className="flex justify-start p-2 border-y"
              style={{ background: "#FAFAFA" }}
            >
              <span className="mx-6"></span>
              <span className="text-xs w-1/3">DESCRIPTION</span>
              <span className="text-xs w-1/3">COMPLETENESS</span>
            </div>
            <div>
              {debouncedRulesTable.map((data, index) => (
                <div key={index} className="">
                  <div
                    className="flex justify-start h-14 items-center border-b border-gray-300"
                    style={{ background: "#BCCDFB26" }}
                  >
                    {toggleRulesTabs.includes(data.table_name) !== false ? (
                      <ChevronDownIcon
                        onClick={() => handleUnhideTabs(data.table_name)}
                        className="h-6 w-6 mx-4 border border-gray-200 rounded-sm p-px px-1 cursor-pointer"
                      />
                    ) : (
                      <ChevronRightIcon
                        onClick={() => handleHideTabs(data.table_name)}
                        className="h-6 w-6 mx-4 border border-gray-200 rounded-sm p-px px-1 cursor-pointer"
                      />
                    )}
                    <div className="w-1/3 flex flex-col">
                      <span className="text-sm font-medium">
                        {data.table_name}
                      </span>
                      <span className="text-xs" style={{ color: "#626C84" }}>
                        {data.columns.length} items
                      </span>
                    </div>
                    {/* <span className="w-1/3 mx-8">
                    <ProgressBarComp progress={data.accuracy} color="#715EC2" />
                  </span> */}
                    <span className="w-1/3">
                      <ProgressBarComp
                        progress={data.completeness}
                        color="#B82B94"
                      />
                    </span>
                  </div>
                  {data.columns.map((col, index) => (
                    <div
                      key={index}
                      className={`flex justify-start items-center h-14 pl-3 border-b border-gray-200 ${
                        toggleRulesTabs.includes(data.table_name)
                          ? "block"
                          : "hidden"
                      }`}
                    >
                      <span
                        className="mx-4 text-sm"
                        style={{ color: "#A9AFBC" }}
                      >
                        {index + 1}
                      </span>
                      <span
                        className="w-1/3 text-sm"
                        style={{ color: "#47536B" }}
                      >
                        {col.column_name}
                      </span>
                      {/* <span className="w-1/3 mx-8">
                      <ProgressBarComp
                        progress={col.accuracy}
                        color="#B5ACEC"
                      />
                    </span> */}
                      <span className="w-1/3">
                        <ProgressBarComp
                          progress={col.completeness}
                          color="#F8C9F1"
                        />
                      </span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DQProgressBars;
