import ErrorBoundary from "../error-boundary";
import OverviewFinancial from "./financial";
import OverviewMRR from "./overview-mrr";
import OverviewRevenue from "./revenue";
import TotalOutstandingAmount from "./total-outstanding-amount";

const Overview: React.FC<{
  amagi_id: string;
}> = ({ amagi_id }) => {
  return (
    <div>
      <ErrorBoundary>
        <OverviewMRR amagi_id={amagi_id} />
        <div className="grid grid-cols-2 gap-4 mt-4">
          <OverviewRevenue amagi_id={amagi_id} />
          <OverviewFinancial amagi_id={amagi_id} />
        </div>
        <div className="mt-4">
          <TotalOutstandingAmount />
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default Overview;
