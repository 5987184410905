/* eslint-disable react-hooks/exhaustive-deps */
import { SearchIcon, XIcon } from "@heroicons/react/outline";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const TableFilter = ({ filter, search, setFilter, placeholder }: any) => {
  const [searchParam, setSearchParam] = useSearchParams();
  useEffect(() => {
    setFilter(searchParam.get("search") || search);
  }, []);
  return (
    <div className="flex items-center border rounded pr-3 bg-white">
      <SearchIcon className="w-10 h-8 p-2 text-gray-300 rounded bg-white outline-none border-gray-300" />
      <input
        className="w-full h-8 p-0 pr-3 text-sm focus:ring-0 focus:border-gray-300 bg-white border-0 outline-none border-gray-300"
        type="text"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        placeholder={placeholder}
      />
      {filter && filter.length > 0 && (
        <XIcon
          className="h-5 w-5"
          onClick={() => {
            setFilter("");
            setSearchParam("");
          }}
        />
      )}
    </div>
  );
};

export default TableFilter;
