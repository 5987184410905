import React from "react";
import { useCDM } from "../../cdm-context";
import Loader from "../../common/loader";
import { useCustomerContractTermsByPkQuery } from "../../hasura.graphql";
import { contentWidth, DateParser } from "../utils";
import { ViewList } from "../view-list";

const ContractTerms = () => {
  const { show } = useCDM();
  const { data, loading, error } = useCustomerContractTermsByPkQuery({
    variables: {
      amagi_id: show?.amg_id!,
    },
  });

  if (loading) return <Loader />;
  if (error) return <i>Error</i>;
  if (!data) return <i>No data</i>;

  const contractTerms = data.customer?.contract_terms;
  console.log(data.customer?.contract_terms);

  return (
    <div
      className="mt-4 grid grid-cols-3 gap-x-0 gap-y-5 p-4 bg-white border rounded-md"
      style={{
        width: contentWidth,
      }}
    >
      <div>
        <ViewList name="Billing Type">
          {contractTerms?.billing_type ? contractTerms?.billing_type : "-"}
        </ViewList>
      </div>
      <div>
        <ViewList name="Payment Terms">
          {contractTerms?.payment_terms ? contractTerms?.payment_terms : "-"}
        </ViewList>
      </div>
      <div style={{ display: "grid", gridColumn: "1" }}>
        <ViewList name="General MSA Effective Date">
          {contractTerms?.msa_effective_date
            ? DateParser(contractTerms?.msa_effective_date)
            : "-"}
        </ViewList>
      </div>
      <div>
        <ViewList name="CPlus MSA Effective Date">
          {data.customer?.cplus_msa_effective_date
            ? DateParser(data.customer?.cplus_msa_effective_date)
            : "-"}
        </ViewList>
      </div>
      <div>
        <ViewList name="CONNECT MSA Effective Date">
          {data.customer?.connect_msa_effective_date
            ? DateParser(data.customer?.connect_msa_effective_date)
            : "-"}
        </ViewList>
      </div>
      <div>
        <ViewList name="Price Escalation">
          {contractTerms?.price_escalation !== null
            ? contractTerms?.price_escalation
              ? "Yes"
              : "No"
            : "-"}
        </ViewList>
      </div>
      {contractTerms?.price_escalation && (
        <>
          <div>
            <ViewList name="Yearly Escalation %">
              {contractTerms?.yearly_escalation_percentage
                ? contractTerms?.yearly_escalation_percentage
                : "-"}
            </ViewList>
          </div>
          <div>
            <ViewList name="Price Escalation Period (Months)">
              {contractTerms?.price_escalation_period
                ? contractTerms?.price_escalation_period
                : "-"}
            </ViewList>
          </div>
        </>
      )}
      <div
        style={{ display: "grid", gridColumn: "1" }}
        className="text-base font-medium"
      >
        Order Terms
      </div>
      <div style={{ display: "grid", gridColumn: "1" }}>
        <ViewList name="Order Initial Term (Months)">
          {contractTerms?.order_initial_term
            ? contractTerms?.order_initial_term
            : "-"}
        </ViewList>
      </div>
      <div>
        <ViewList name="Order Renewal Term (Months)">
          {contractTerms?.order_renewal_term
            ? contractTerms?.order_renewal_term
            : "-"}
        </ViewList>
      </div>
      <div
        style={{ display: "grid", gridColumn: "1" }}
        className="text-base font-medium"
      >
        Service Order Termination Notice Period (Days)
      </div>
      <div style={{ display: "grid", gridColumn: "1" }}>
        <ViewList name="SO Cancel Notice During OIT (Days)">
          {contractTerms?.so_cancel_notice_during_oit
            ? contractTerms?.so_cancel_notice_during_oit
            : "-"}
        </ViewList>
      </div>
      <div>
        <ViewList name="SO Cancel Notice After OIT (Days)">
          {contractTerms?.so_cancel_notice_after_oit
            ? contractTerms?.so_cancel_notice_after_oit
            : "-"}
        </ViewList>
      </div>
      <div
        style={{ display: "grid", gridColumn: "1" }}
        className="text-base font-medium"
      >
        Invoice Terms
      </div>
      <div style={{ display: "grid", gridColumn: "1" }}>
        <ViewList name="Security Deposit">
          {contractTerms?.security_deposit !== null
            ? contractTerms?.security_deposit
              ? "Yes"
              : "No"
            : "-"}
        </ViewList>
      </div>
      <div>
        <ViewList name="Deposit Amount - MRR Months">
          {contractTerms?.deposit_amount_mrr_months
            ? contractTerms?.deposit_amount_mrr_months
            : "-"}
        </ViewList>
      </div>
      <div>
        <ViewList name="Payment Terms for Terminated Services">
          {contractTerms?.payment_term_for_terminated_services
            ? contractTerms?.payment_term_for_terminated_services
            : "-"}
        </ViewList>
      </div>
      <div>
        <ViewList name="Entitled to Stop Services After (Months)">
          {contractTerms?.entitled_to_stop_services_after
            ? contractTerms?.entitled_to_stop_services_after
            : "-"}
        </ViewList>
      </div>
      <div>
        <ViewList name="Termination Due to Plat Notice (Months)">
          {contractTerms?.termination_due_to_platform_notice
            ? contractTerms?.termination_due_to_platform_notice
            : "-"}
        </ViewList>
      </div>
      <div>
        <ViewList name="Interest % Applicable Post Due Date">
          {contractTerms?.interest_percent_applicable_post_due_date
            ? contractTerms?.interest_percent_applicable_post_due_date
            : "-"}
        </ViewList>
      </div>
      <div>
        <ViewList name="Billing Date Rule">
          {contractTerms?.billing_date_rule
            ? contractTerms?.billing_date_rule
            : "-"}
        </ViewList>
      </div>
      <div>
        <ViewList name="Billing Threshold Rule">
          {contractTerms?.billing_threshold_rule
            ? contractTerms?.billing_threshold_rule
            : "-"}
        </ViewList>
      </div>
    </div>
  );
};

export default ContractTerms;
