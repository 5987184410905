import { DeliveryProductsType } from "../delivery-edit-form/types";

const ViewDeliveryProducts: React.FC<{
  products: Array<DeliveryProductsType>;
}> = ({ products }) => {
  return (
    <div>
      <div className="text-base font-semibold mt-8 mb-2">
        Products in this delivery
      </div>
      {products && products.length > 0 ? (
        <table className="mt-4" style={{ width: "70%", fontSize: "16px" }}>
          <thead className="border-0 border-b border-gray-50">
            <tr className="bg-gray-300">
              <th className="py-2 pl-2">S No.</th>
              <th className="text-left py-2 pl-2">Product Code</th>
              <th className="text-left py-2 pl-2">Product Name</th>
            </tr>
          </thead>
          <tbody className="bg-gray-50">
            {products.map((product: DeliveryProductsType, index: number) => (
              <tr
                key={index}
                className="border-0 border-b border-gray-200 last:border-0"
              >
                <td className="py-1 pl-2">{index + 1}</td>
                <td className="text-left py-2 pl-2">{product.code}</td>
                <td className="text-left py-2 pl-2">{product.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="mt-2">-</div>
      )}
    </div>
  );
};

export default ViewDeliveryProducts;
