import React from "react";
import { DeliveryInfoFragment } from "../../hasura.graphql";
import {
  churnedStatus,
  greenStatus,
  onHoldStatus,
  orderingStatus,
  backlogStatus,
  progressStatus,
  closedLostStatus
} from "../utils";
import OverviewChart from "./overview-chart";

const DeliveryOverview: React.FC<{
  channelDeliveryData: DeliveryInfoFragment[];
  vodDeliveryData: DeliveryInfoFragment[];
  livedeliverydata: DeliveryInfoFragment[];
  playoutdata: DeliveryInfoFragment[];
}> = ({ channelDeliveryData, vodDeliveryData, livedeliverydata, playoutdata }) => {
  const chartData = [
    {
      header: "Type of Delivery",
      filterBy: "cdp_delivery_type",
      isFilter: false,
      groupBy: "cdp_delivery_type",
      countBy: "id",
      groupMap: [],
    },
    {
      header: "Channel by Status",
      filterBy: "cdp_delivery_type",
      customColor: true,
      isFilter: true,
      groupBy: "delivery_status",
      countBy: "channel.name",
      groupMap: [
        { original: greenStatus, replace_with: "Delivered & Active" },
        { original: churnedStatus, replace_with: "Suspended" },
        { original: progressStatus, replace_with: "Provisioning" },
        { original: orderingStatus, replace_with: "Ordering" },
        { original: backlogStatus, replace_with: "Provisioning Backlog" },
        { original: onHoldStatus, replace_with: "Provisioning On Hold" },
        { original: closedLostStatus, replace_with: "Delivery Closed Lost"}
      ],
    },
    {
      header: "Platform by Status",
      filterBy: "cdp_delivery_type",
      customColor: true,
      isFilter: true,
      groupBy: "delivery_status",
      countBy: "platform.name",
      groupMap: [
        { original: greenStatus, replace_with: "Delivered & Active" },
        { original: churnedStatus, replace_with: "Suspended" },
        { original: progressStatus, replace_with: "Provisioning" },
        { original: orderingStatus, replace_with: "Ordering" },
        { original: backlogStatus, replace_with: "Provisioning Backlog" },
        { original: onHoldStatus, replace_with: "Provisioning On Hold" },
        { original: closedLostStatus, replace_with: "Delivery Closed Lost"}
      ],
    },
    {
      header: "Status",
      filterBy: "cdp_delivery_type",
      customColor: true,
      isFilter: true,
      groupBy: "delivery_status",
      countBy: "id",
      groupMap: [
        { original: greenStatus, replace_with: "Delivered & Active" },
        { original: churnedStatus, replace_with: "Suspended" },
        { original: progressStatus, replace_with: "Provisioning" },
        { original: orderingStatus, replace_with: "Ordering" },
        { original: backlogStatus, replace_with: "Provisioning Backlog" },
        { original: onHoldStatus, replace_with: "Provisioning On Hold" },
        { original: closedLostStatus, replace_with: "Delivery Closed Lost" }
      ],
    },
    {
      header: "Request Origin",
      filterBy: "cdp_delivery_type",
      customColor: false,
      isFilter: true,
      groupBy: "request_origin",
      countBy: "id",
      groupMap: [],
    },
    {
      header: "Region",
      filterBy: "cdp_delivery_type",
      customColor: false,
      isFilter: true,
      groupBy: "delivered_region",
      countBy: "id",
      groupMap: [],
    },
    {
      header: "Platform",
      filterBy: "cdp_delivery_type",
      customColor: false,
      isFilter: true,
      groupBy: "platform_name",
      countBy: "id",
      groupMap: [],
    },
    {
      header: "Business Model",
      filterBy: "cdp_delivery_type",
      customColor: false,
      isFilter: true,
      groupBy: "business_model",
      countBy: "id",
      groupMap: [],
    },
    {
      header: "Deal Type",
      filterBy: "cdp_delivery_type",
      customColor: false,
      isFilter: true,
      groupBy: "deal_type_for_content_partner",
      countBy: "id",
      groupMap: [],
    },
    {
      header: "Stream Type",
      filterBy: "cdp_delivery_type",
      customColor: false,
      isFilter: true,
      groupBy: "delivery_type",
      countBy: "id",
      groupMap: [],
    },
    {
      header: "SSAI",
      filterBy: "cdp_delivery_type",
      customColor: false,
      isFilter: true,
      groupBy: "ssaiString",
      countBy: "id",
      groupMap: [],
    },
    {
      header: "Cloudport",
      filterBy: "cdp_delivery_type",
      customColor: false,
      isFilter: true,
      groupBy: "cloudport",
      countBy: "id",
      groupMap: [],
    },
  ];

  return (
    <div className="mt-4">
      {[...channelDeliveryData, ...vodDeliveryData, ...livedeliverydata, ...playoutdata].length > 0 ? (
        <div className="mt-4 grid grid-cols-3 gap-4">
          {chartData.map((chart) => (
            <OverviewChart
              key={chart.header}
              {...chart}
              data={[...channelDeliveryData, ...vodDeliveryData, ...livedeliverydata, ...playoutdata]}
            />
          ))}
        </div>
      ) : (
        <div className="p-4 mt-4 bg-white border rounded-md italic font-medium">
          No Data Available
        </div>
      )}
    </div>
  );
};

export default DeliveryOverview;
