export interface ChannelAvailabilityRegionsType {
  country: Array<string>;
  region: string;
}

const ViewAvailabilityRegions: React.FC<{
  availRegions: Array<ChannelAvailabilityRegionsType>;
}> = ({ availRegions }) => {
  return (
    <div>
      <label className="text-gray-400">
        Available in Regions and Countries
      </label>
      {availRegions.length > 0 ? (
        <table
          className="mt-4 border"
          style={{ width: "55%", fontSize: "16px" }}
        >
          <thead className="border-0 border-b border-gray-50">
            <tr className="bg-gray-300">
              <th className="py-2 pl-2">S No.</th>
              <th className="text-left py-2 pl-2">Region</th>
              <th className="text-left py-2 pl-2">Countries</th>
            </tr>
          </thead>
          <tbody className="bg-gray-50">
            {availRegions.map(
              (region: ChannelAvailabilityRegionsType, index: number) => (
                <tr
                  key={index}
                  className="border-0 border-b border-gray-200 last:border-0"
                >
                  <td className="py-1 pl-2">{index + 1}</td>
                  <td className="text-left py-2 pl-2">{region.region}</td>
                  <td className="text-left py-2 pl-2">
                    {typeof region.country === "object" ? (
                      region.country.map((con, index) => (
                        <span
                          key={index}
                          className="mb-1 px-3 cursor-pointer rounded-sm text-black font-semibold mr-2 border border-gray-300 w-max"
                        >
                          {con}
                        </span>
                      ))
                    ) : (
                      <span className="mb-1 px-3 cursor-pointer rounded-sm text-black font-semibold mr-2 border border-gray-300 w-max">
                        {region.country}
                      </span>
                    )}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      ) : (
        <div className="mt-2">-</div>
      )}
    </div>
  );
};

export default ViewAvailabilityRegions;
