import { useGetAllChannelsQuery } from "./hasura.graphql";

interface Channel {
  id: string;
  name: string;
}

export const useChannelNames = () => {
  const { data, loading, error } = useGetAllChannelsQuery();

  if (loading) {
    return { loading: true, error: null, channelNames: [] };
  }

  if (error) {
    return { loading: false, error: error.message, channelNames: [] };
  }

  const channelNames: string[] = data?.channel.map((channel: Channel) => `${channel.id} : ${channel.name}`) || [];
  return { loading: false, error: null, channelNames };
};

