export interface PlatformArtworkType {
  name: string;
  mandatory: boolean;
  artwork: string;
  aspect_ratio: string;
  format: string;
  max_file_size: number;
  application: string;
  comments: string;
}

const ViewArtworkTable: React.FC<{ artwork: Array<PlatformArtworkType> }> = ({
  artwork,
}) => {
  return (
    <div>
      <label className="text-gray-400">
        Platform's Channel Artwork Requirement
      </label>
      {artwork.length > 0 ? (
        <table className="mt-4" style={{ fontSize: "16px" }}>
          <thead className="border-0 border-b border-gray-50">
            <tr style={{ background: "#FAFAFB" }}>
              <th className="py-2 pl-2">S No.</th>
              <th className="text-left py-2 pl-2">Name</th>
              <th className="text-left py-2 pl-2">Mandatory</th>
              <th className="text-left py-2 pl-2">Artwork Dimensions</th>
              <th className="text-left py-2 pl-2">Aspect Ratio</th>
              <th className="text-left py-2 pl-2">Format</th>
              <th className="text-left py-2 pl-2">Max File Size</th>
              <th className="text-left py-2 pl-2">Application</th>
              <th className="text-left py-2 pl-2">Comments</th>
              <th className="text-left py-2 pl-2"></th>
            </tr>
          </thead>
          <tbody>
            {artwork.map((art: PlatformArtworkType, index: number) => (
              <tr
                key={index}
                className="border-0 border-b text-start border-gray-50"
              >
                <td className="py-1 pl-2">{index + 1}</td>
                <td className="text-left py-2 pl-2">{art.name}</td>
                <td className="text-left py-2 pl-2">
                  {art.mandatory ? "Yes" : "No"}
                </td>
                <td className="text-left py-2 pl-2">{art.artwork}</td>
                <td className="text-left py-2 pl-2">{art.aspect_ratio}</td>
                <td className="text-left py-2 pl-2">{art.format}</td>
                <td className="text-left py-2 pl-2">{art.max_file_size} MB</td>
                <td className="text-left py-2 pl-2 w-64 flex-wrap">
                  {art.application}
                </td>
                <td className="text-left py-2 pl-2 w-64 flex-wrap">
                  {art.comments}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="mt-2">-</div>
      )}
    </div>
  );
};

export default ViewArtworkTable;
