import { PlatformTechnicalType } from "../platform-edit-form/types";

const ViewTechnicalTable: React.FC<{
  technicalSpecs: Array<PlatformTechnicalType>;
}> = ({ technicalSpecs }) => {
  return (
    <div>
      <label className="text-gray-400">Stream Specs</label>
      {technicalSpecs.length > 0 ? (
        <table
          className="mt-4 border"
          style={{ width: "80%", fontSize: "16px" }}
        >
          <thead className="border-0 border-b border-gray-50">
            <tr className="bg-gray-300">
              <th className="py-2 pl-2">S No.</th>
              <th className="text-left py-2 pl-2">Resolution Type</th>
              <th className="text-left py-2 pl-2">Resolution</th>
              <th className="text-left py-2 pl-2">Bitrate</th>
              <th className="text-left py-2 pl-2">Frame Rate</th>
              <th className="text-left py-2 pl-2">Audio</th>
              <th className="text-left py-2 pl-2">GOP</th>
            </tr>
          </thead>
          <tbody className="bg-gray-50">
            {technicalSpecs.map(
              (tech: PlatformTechnicalType, index: number) => (
                <tr
                  key={index}
                  className="border-0 border-b border-gray-200 last:border-0"
                >
                  <td className="py-1 pl-2">{index + 1}</td>
                  <td className="text-left py-2 pl-2">
                    {tech.resolution_type}
                  </td>
                  <td className="text-left py-2 pl-2">
                    {tech.resolution.map((res, index) => (
                      <span
                        key={index}
                        className="mb-1 px-3 flex cursor-pointer rounded-sm text-black font-semibold mr-2 border border-gray-300 w-max"
                      >
                        {res}
                      </span>
                    ))}
                  </td>
                  <td className="text-left py-2 pl-2">{tech.bitrate} kbps</td>

                  <td className="text-left py-2 pl-2">
                    {tech.frame_rate.map((rate, index) => (
                      <span
                        key={index}
                        className="mb-1 px-3 flex cursor-pointer rounded-sm text-black font-semibold mr-2 border border-gray-300 w-max"
                      >
                        {rate}
                      </span>
                    ))}
                  </td>
                  <td className="text-left py-2 pl-2">{tech.audio}</td>
                  <td className="text-left py-2 pl-2">{tech.gop}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      ) : (
        <div className="mt-2">-</div>
      )}
    </div>
  );
};

export default ViewTechnicalTable;
