import { useNavigate, useParams } from "react-router-dom";
import ChannelDeliveryEditForm from "../components/channel-delivery/delivery-edit-form";
import { useFeatureFlagQuery } from "../hasura.graphql";

const ChannelDeliveryByIdEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const role_data = useFeatureFlagQuery();

  return (
    <>
      {role_data.data?.feature_flag.find(
        (feat) => feat.feature === "channel-edit"
      ) !== undefined ? (
        <ChannelDeliveryEditForm
          close={() => navigate(`/deliveries/channel-deliveries/${id}`)}
          deliveryId={id!}
          isModal={false}
        />
      ) : (
        navigate(`/deliveries/channel-deliveries/${id}`)
      )}
    </>
  );
};

export default ChannelDeliveryByIdEdit;
