import { ExternalLinkIcon } from "@heroicons/react/outline";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { PlatformGridFragment } from "../../hasura.graphql";
import { AvailabilityRegionsType } from "../channels/channel-edit-form/types";

const PlatformCard: React.FC<{
  platform: PlatformGridFragment;
  url: string;
}> = ({ platform, url }) => {
  const {
    name,
    logo,
    platform_tier,
    availablity_country_and_regions,
    account,
  } = platform;

  const regions: string[] = Array.from(
    new Set(
      availablity_country_and_regions.map(
        (d: AvailabilityRegionsType) => d.region
      )
    )
  );

  const handleExternalLink = (e: any) => {
    e.stopPropagation();
  };
  return (
    <div className="rounded-md border bg-white border-gray-300">
      <LazyLoadImage
        className="w-full h-32 rounded-t-md object-contain border-b bg-lightPrimary"
        src={
          logo
            ? `/${logo}`
            : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${name}`
        }
        alt={name}
      />
      <div className="flex justify-between items-center">
        <div className="p-2">
          <div className="text-xs" style={{ color: "#47536B" }}>
            Platform Name
          </div>
          <div className="text-sm font-medium">
            {name.length > 30 ? `${name.slice(0, 30)}` : name}
          </div>
        </div>
        <div className="p-2" onClick={(e) => handleExternalLink(e)}>
          <a href={url} target="_blank" rel="noreferrer">
            <ExternalLinkIcon className="h-5 w-5 text-blue-500" />
          </a>
        </div>
      </div>
      <div className="px-2">
        <div className="text-xs" style={{ color: "#47536B" }}>
          Customer ID / Name
        </div>
        <div className="text-sm font-medium">
          {account
            ? `${account?.amagi_id} / ${
                account?.account_name && account?.account_name.length > 20
                  ? `${account?.account_name.slice(0, 20)}...`
                  : account?.account_name
              }`
            : "-"}
        </div>
      </div>
      <div className="p-2">
        <div className="text-xs" style={{ color: "#47536B" }}>
          Tier
        </div>
        <div className="text-sm font-medium">{platform_tier || "-"}</div>
      </div>
      <div
        className="px-2 flex overflow-hidden h-10 items-center"
        style={{ fontSize: "12px" }}
      >
        {regions.map((g: string, index: number) => {
          if (index < 3) {
            return (
              <span
                key={index}
                className="border border-gray-300 px-1 mr-2 rounded-lg"
              >
                {g}
              </span>
            );
          } else if (index === 3) {
            return (
              <span
                key={index}
                className="border border-gray-300 px-1 mr-2 rounded-lg"
              >{`+ ${
                availablity_country_and_regions.length - (index + 1)
              }`}</span>
            );
          } else return "-";
        })}
      </div>
    </div>
  );
};

export default PlatformCard;
