import { useState } from "react";
import { channelFields } from "../../../constants";
import FormikInput from "../../formik/input";
import FormikInputTime from "../../formik/input-time";
import FormikMultiSelect from "../../formik/multi-select";
import FormikSelect from "../../formik/select";
import FormikSwitch from "../../formik/switch";
import ToggleBox from "../../toggle-box";
import { ChannelEditValuesType } from "./types";

const ChannelTechnicalSpecsForm: React.FC<{
  values: ChannelEditValuesType;
}> = ({ values }) => {
  const [hideTabs, setHideTabs] = useState<Array<string>>([]);

  const handleHideTabs = (tab: string) => {
    setHideTabs((hideTabs) => [...hideTabs, tab]);
  };
  const handleUnhideTabs = (tab: string) => {
    setHideTabs(hideTabs.filter((f: string) => f !== tab));
  };
  return (
    <div>
      <ToggleBox
        header="Broadcast Details"
        columns={hideTabs}
        handleHideColumns={handleHideTabs}
        handleUnhideColumns={handleUnhideTabs}
      >
        <>
          <div className="grid grid-cols-2 gap-y-8 gap-x-36 mb-3">
            <div>
              <FormikSelect
                inlinelabel="Time Zone"
                name="time_zone"
                defaultValue={
                  values.time_zone && values.time_zone.length > 20
                    ? values.time_zone.slice(0, 20)
                    : values.time_zone
                }
                defFields={channelFields.timeZone.map(
                  (tz) => `${tz.zone}-${tz.name} (${tz.utc})`
                )}
              />
            </div>
            <div>
              <div className="text-gray-400">Start Time</div>
              <div style={{ display: "grid", gridColumn: "1" }}>
                <FormikInputTime
                  name="start_time"
                  defaultValue={values.start_time}
                  inlinelabel=""
                />
              </div>
            </div>
          </div>
        </>
      </ToggleBox>
      <ToggleBox
        header="Channel Formats"
        columns={hideTabs}
        handleHideColumns={handleHideTabs}
        handleUnhideColumns={handleUnhideTabs}
      >
        <>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36 mb-3">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Playout Formats"
                name="playout_format"
                defaultValue={values.playout_format}
                defFields={["HD NTSC", "SD"]}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Video Formats"
                name="video_format"
                defaultValue={values.video_format}
                defFields={channelFields.videoFormat2.sort()}
              />
            </div>
            {(values.video_format === "Others" ||
              (values.video_format !== null &&
                channelFields.videoFormat2
                  .sort()
                  .includes(values.video_format!) === false)) && (
              <div>
                <FormikInput
                  inlinelabel="Custom Video Format"
                  name="video_format"
                  defaultValue={
                    values.video_format !== "Others" ? values.video_format : ""
                  }
                />
              </div>
            )}
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Channel Resolution"
                name="channel_resolution"
                defaultValue={values.channel_resolution}
                defFields={channelFields.resolution.sort()}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Audio Formats"
                name="audio_format"
                defaultValue={values.audio_format}
                defFields={channelFields.audioFormat}
              />
            </div>
            {(values.audio_format === "Others" ||
              (values.audio_format !== null &&
                channelFields.audioFormat
                  .sort()
                  .includes(values.audio_format!) === false)) && (
              <div>
                <FormikInput
                  inlinelabel="Custom Audio Format"
                  name="audio_format"
                  defaultValue={
                    values.audio_format !== "Others" ? values.audio_format : ""
                  }
                />
              </div>
            )}
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Playlist Format"
                name="playlist_format"
                defaultValue={values.playlist_format}
                defFields={channelFields.playlistFormat.sort()}
              />
            </div>
            {(values.playlist_format === "Others" ||
              (values.playlist_format !== null &&
                channelFields.playlistFormat
                  .sort()
                  .includes(values.playlist_format!) === false)) && (
              <div>
                <FormikInput
                  inlinelabel="Custom Playlist Format"
                  name="playlist_format"
                  defaultValue={
                    values.playlist_format !== "Others"
                      ? values.playlist_format
                      : ""
                  }
                />
              </div>
            )}
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Metadata Ingest Format"
                name="metadata_ingest_format"
                defaultValue={values.metadata_ingest_format}
                defFields={channelFields.metadataIngestFormat.sort()}
              />
            </div>
            {(values.metadata_ingest_format === "Others" ||
              (values.metadata_ingest_format !== null &&
                channelFields.metadataIngestFormat
                  .sort()
                  .includes(values.metadata_ingest_format!) === false)) && (
              <div>
                <FormikInput
                  inlinelabel="Custom Metadata Ingest Format"
                  name="metadata_ingest_format"
                  defaultValue={
                    values.metadata_ingest_format !== "Others"
                      ? values.metadata_ingest_format
                      : ""
                  }
                />
              </div>
            )}
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Content Ingest Format"
                name="content_ingest_format"
                defaultValue={values.content_ingest_format}
                defFields={channelFields.contentIngestFormat.sort()}
              />
            </div>
            {(values.content_ingest_format === "Others" ||
              (values.content_ingest_format !== null &&
                channelFields.contentIngestFormat
                  .sort()
                  .includes(values.content_ingest_format!) === false)) && (
              <div>
                <FormikInput
                  inlinelabel="Custom Content Ingest Format"
                  name="content_ingest_format"
                  defaultValue={
                    values.content_ingest_format !== "Others"
                      ? values.content_ingest_format
                      : ""
                  }
                />
              </div>
            )}
          </div>
        </>
      </ToggleBox>
      <ToggleBox
        header="Live"
        columns={hideTabs}
        handleHideColumns={handleHideTabs}
        handleUnhideColumns={handleUnhideTabs}
      >
        <>
          <div className="grid grid-cols-4 gap-y-8 gap-x-36">
            <div style={{ padding: "5px 0px" }}>
              <label className="text-gray-400">Live Playout</label>
              <FormikSwitch
                name="live_playout"
                defaultValue={values.live_playout}
              />
            </div>
            {values.live_playout && (
              <>
                <div>
                  <FormikSelect
                    inlinelabel="Live Feed Inputs"
                    name="live_feed_input"
                    defaultValue={values.live_feed_input}
                    defFields={channelFields.liveFeedInput.sort()}
                  />
                </div>
                <div style={{ padding: "5px 0px" }}>
                  <label className="text-gray-400">Live Recording</label>
                  <FormikSwitch
                    name="live_recording"
                    defaultValue={values.live_recording}
                  />
                </div>
                <div>
                  <label className="text-gray-400">
                    Live Management (Managed Services)
                  </label>
                  <FormikSwitch
                    name="live_management"
                    defaultValue={values.live_management}
                  />
                </div>
              </>
            )}
          </div>
        </>
      </ToggleBox>
      <ToggleBox
        header="Captions"
        columns={hideTabs}
        handleHideColumns={handleHideTabs}
        handleUnhideColumns={handleUnhideTabs}
      >
        <>
          <div className="grid grid-cols-4 gap-y-8 gap-x-36 mb-3">
            <div style={{ padding: "5px 0px" }}>
              <label className="text-gray-400">Closed Captions</label>
              <FormikSwitch
                name="closed_caption"
                defaultValue={values.closed_caption}
              />
            </div>
            {values.closed_caption && (
              <>
                <div>
                  <FormikSelect
                    inlinelabel="Closed Captions Type"
                    name="closed_caption_type"
                    defaultValue={values.closed_caption_type}
                    defFields={channelFields.subtitleType.sort()}
                  />
                </div>
                <div>
                  <FormikMultiSelect
                    inlinelabel="Closed Caption Language"
                    name="closed_caption_language"
                    defaultValue={values.closed_caption_language}
                    defFields={channelFields.languages.sort()}
                  />
                </div>
                <div style={{ padding: "5px 0px" }}>
                  <label className="text-gray-400">
                    Amagi Auto CC Generation
                  </label>
                  <FormikSwitch
                    name="amagi_auto_cc_generation"
                    defaultValue={values.amagi_auto_cc_generation}
                  />
                </div>
              </>
            )}
          </div>
        </>
      </ToggleBox>
      <ToggleBox
        header="Graphics"
        columns={hideTabs}
        handleHideColumns={handleHideTabs}
        handleUnhideColumns={handleUnhideTabs}
      >
        <>
          <div className="grid grid-cols-4 gap-y-8 gap-x-36 mb-3">
            <div style={{ padding: "5px 0px" }}>
              <label className="text-gray-400">Graphics Enabled</label>
              <FormikSwitch
                name="graphics_enabled"
                defaultValue={values.graphics_enabled}
              />
            </div>
            {values.graphics_enabled && (
              <>
                <div>
                  <FormikSelect
                    inlinelabel="Graphics Type"
                    name="graphics_type"
                    defaultValue={values.graphics_type}
                    defFields={channelFields.graphicsType.sort()}
                  />
                </div>
                <div style={{ padding: "5px 0px" }}>
                  <label className="text-gray-400">Channel LOGO Bug</label>
                  <FormikSwitch
                    name="channel_logo_bug"
                    defaultValue={values.channel_logo_bug}
                  />
                </div>
                <div>
                  <FormikSelect
                    inlinelabel="Graphics Format"
                    name="graphics_format"
                    defaultValue={values.graphics_format}
                    defFields={channelFields.graphicsFormat.sort()}
                  />
                </div>
              </>
            )}
          </div>
        </>
      </ToggleBox>
    </div>
  );
};

export default ChannelTechnicalSpecsForm;
