import React, { useState } from "react";
import { useMemo } from "react";
import { CustomerSalesDataFragment } from "../../hasura.graphql";
import { capitalize, contentWidth } from "../utils";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import SingleChart from "../charts/single-chart";
import { useCDM } from "../../cdm-context";
import { OpportunityType } from "./types";
import OpportunityFileTable from "./opportunity-file";
import { DocumentDownloadIcon } from "@heroicons/react/outline";
import NestedToggleTableContainer from "../../common/nested-table-toggle-container";
import DelayedToolTip from "../delayed-tooltip";
import { useSearchParams } from "react-router-dom";
import TableRowsFilter from "../../common/filters/table-rows-filter";

const showClosedDate = ["Closed Won", "Closed Lost", "Closed Churned"];

const Sales: React.FC<{
  salesData: CustomerSalesDataFragment;
  searchParam: string;
}> = ({ salesData, searchParam }) => {
  const { opportunity, account_manager, csm, owner, sales } = salesData;
  const { show } = useCDM();
  const [searchParams] = useSearchParams();
  const [rowId, setRowId] = useState<string>("");
  const [opportunityId, setOpportunityId] = useState<string | null>(null);

  const statusColorCode = (status: string) => {
    if (status === "Closed Churned") return "text-red-500 border-red-500";
    else if (status === "Closed Lost")
      return "text-chartOrange border-chartOrange";
    else if (status === "Closed Won")
      return "text-chartGreen border-chartGreen";
    return "border-gray-500";
  };

  const chart_data = useMemo(() => {
    return sales!.map((x) => {
      return {
        label:
          x.source === "order" || x.source === "invoice"
            ? `${x.status} ${x.source}`
            : `${x.status}`,
        amount: x.amount,
      };
    });
  }, [sales]);

  const sortBy = useMemo(
    () => [
      "10 - Initial Discovery",
      "20 - Establish Use Case",
      "30 - Solution Overview and Demo",
      "40 - Success Criteria and Solution Fit",
      "50 - POC",
      "60 - POC Completed",
      "70 - Preferred Solution",
      "80 - Negotiation",
      "90 - Verbal Commit / Awaiting PO",
      "95 - Done Deal OnHold",
      "100 - Closed Won",
      "Closed Won",
      "Closed Lost",
      "Closed Churned",
    ],
    []
  );

  const sorted_chart_data = useMemo(() => {
    let findData = (label: string) =>
      chart_data.find((data) => data.label === label);
    const sorted = sortBy.map((sort) => {
      if (findData(sort))
        return {
          label: findData(sort)?.label,
          amount: findData(sort)?.amount,
        };
      return null;
    });
    return sorted.filter((data) => data !== null);
  }, [chart_data, sortBy]);

  // const customSort = ({
  //   data,
  //   sortBy,
  //   sortField,
  // }: {
  //   data: Array<any>;
  //   sortBy: string[];
  //   sortField: string;
  // }) => {
  //   const sortByObject: any = sortBy.reduce((obj, item, index) => {
  //     return {
  //       ...obj,
  //       [item]: index,
  //     };
  //   }, {});
  //   return data.sort(
  //     (a, b) => sortByObject[a[sortField]] - sortByObject[b[sortField]]
  //   );
  // };

  // const sorted_chart_data = customSort({
  //   data: chart_data,
  //   sortBy,
  //   sortField: "label",
  // });

  console.log(chart_data, sorted_chart_data);

  const columns: any = useMemo(
    () => [
      {
        Header: "DOCUMENTS",
        accessor: "",
        Cell: ({ cell }: any) => (
          <DelayedToolTip title="download" position="left">
            <span
              className="text-blue-500 underline cursor-pointer hover:text-blue-800 text-center"
              onClick={() => {
                setOpportunityId(cell.row.original.opportunity_id);
                setRowId(cell.row.id);
              }}
            >
              <DocumentDownloadIcon className="h-5 w-5" />
            </span>
          </DelayedToolTip>
        ),
      },
      {
        Header: "OPPORTUNITY ID",
        accessor: "opportunity_id",
      },
      {
        Header: "OPPORTUNITY NAME",
        accessor: "opportunity_name",
      },
      {
        Header: "OPPORTUNITY TYPE",
        accessor: "opportunity_type",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "STATUS",
        accessor: "status",
        Filter: TableRowsFilter,
        filter: "multiSelect",
        Cell: ({ cell }: any) => (
          <span
            className={`border ${statusColorCode(
              cell.row.values.status
            )} rounded-full px-2 inline-flex leading-5 text-xs`}
          >
            {cell.row.values.status}
          </span>
        ),
      },
      {
        Header: "EXPECTED REVENUE",
        accessor: "expected_revenue",
        Cell: ({ cell }: any) => (
          <span>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: opportunity.find(opp => opp.salesforce_id === cell.row.original.opportunity_id)?.currency_iso_code || "USD",
            }).format(cell.row.values.expected_revenue)}
          </span>
        ),
        textAlign: "text-right",
      },
      {
        Header: "CLOSED DATE",
        accessor: "close_date",
      },
      {
        Header: "DESCRIPTION",
        accessor: "description",
        Cell: ({ cell }: any) => (
          <DelayedToolTip title={cell.row.original.description} position="top">
            <span className="inline-block">
              {cell.row.values.description &&
              cell.row.values.description.length > 30
                ? `${cell.row.values.description.slice(0, 30)}...`
                : cell.row.values.description || "--"}
            </span>
          </DelayedToolTip>
        ),
      },
      {
        Header: "LEAD SOURCE",
        accessor: "lead_source",
      },
      {
        Header: "MONETIZATION MODEL",
        accessor: "monetization_model",
        Filter: TableRowsFilter,
        filter: "multiselect",
      },
      {
        Header: "SO ID",
        accessor: "so_id",
      },
      {
        Header: "COMPETITION",
        accessor: "competition",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "REASON FOR WINNING",
        accessor: "reason_for_closed_won",
        Cell: ({ cell }: any) => (
          <DelayedToolTip
            title={cell.row.original.reason_for_closed_won}
            position="top"
          >
            <span className="inline-block">
              {cell.row.values.reason_for_closed_won &&
              cell.row.values.reason_for_closed_won.length > 30
                ? `${cell.row.values.reason_for_closed_won.slice(0, 30)}...`
                : cell.row.values.reason_for_closed_won || "--"}
            </span>
          </DelayedToolTip>
        ),
      },
      {
        Header: "REASON FOR LOSING",
        accessor: "reason_for_closed_lost",
      },
      {
        Header: "PRODUCTS ASSOCIATED WITH THE OPPORTUNITY",
        accessor: "product",
      },
    ],
    [opportunity]
  );

  let chart_values: number[] = chart_data!.map((num: any) => {
    return num.amount !== null ? num.amount : 0;
  });

  const filteredOpportunity: Array<OpportunityType> = opportunity.map((opp) => {
    return {
      opportunity_id: opp.salesforce_id ? opp.salesforce_id : "-",
      opportunity_name: opp.name ? opp.name : "-",
      description: opp.description ? opp.description : "-",
      opportunity_type: opp.opportunity_type ? opp.opportunity_type : "-",
      status: opp.stage_name ? opp.stage_name : "-",
      currency: opp.currency_iso_code ? opp.currency_iso_code : "-",
      expected_revenue: opp.expected_revenue ? opp.expected_revenue : 0,
      closed_date: showClosedDate.includes(opp.stage_name || "")
        ? opp.close_date
          ? opp.close_date
          : "-"
        : "-",
      lead_source: opp.lead_source ? opp.lead_source : "-",
      monetization_model: opp.monetization_model ? opp.monetization_model : "-",
      so_id: opp.so ? opp.so : "-",
      competition: opp.competition ? opp.competition : "-",
      reason_for_closed_won: opp.reason_for_closed_won
        ? opp.reason_for_closed_won
        : "-",
      reason_for_closed_lost: opp.reason_for_closed_lost
        ? opp.reason_for_closed_lost
        : "-",
      product: opp.product ? opp.product : "-",
    };
  });

  filteredOpportunity.sort((a: any, b: any) => {
    if (a.status === "Closed Won") return -1;
    else return 1;
  });

  const exportOpportunity = filteredOpportunity.map((opp) => {
    return {
      opportunity_id: opp.opportunity_id,
      opportunity_name: opp.opportunity_name,
      description: opp.description,
      opportunity_type: opp.opportunity_type,
      status: opp.status,
      expected_revenue: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: opp.currency || "USD",
      }).format(opp.expected_revenue),
      closed_date: opp.closed_date,
      lead_source: opp.lead_source,
      monetization_model: opp.monetization_model,
      so_id: opp.so_id,
      competition: opp.competition,
      reason_for_closed_won: opp.reason_for_closed_won,
      reason_for_closed_lost: opp.reason_for_closed_lost,
      product: opp.product,
    };
  });

  const exportHeaders = columns.map((col: any) => {
    return {
      label: col.Header,
      key: col.accessor,
    };
  });

  return (
    <div className="text-primaryText">
      <Toaster />
      <div className="grid grid-cols-3 gap-4 text-black">
        <div className="bg-white p-3 rounded-md border">
          <div className="text-sm">Account Manager</div>
          <div className="text-xs my-1 font-extrabold">
            {account_manager?.first_name} {account_manager?.last_name}
          </div>
          <div className="flex text-xs">
            <span className="mr-3">
              {account_manager ? account_manager.email : "-"}
            </span>
            {account_manager && (
              <DelayedToolTip title="copy">
                <CopyToClipboard text={account_manager.email}>
                  <img
                    src="/assets/file-copy.svg"
                    onClick={() => toast.success("Copied")}
                    alt="file-copy"
                  />
                </CopyToClipboard>
              </DelayedToolTip>
            )}
          </div>
        </div>
        <div className="bg-white p-3 rounded-md border">
          <div className="text-sm">Technical Account Manager</div>
          <div className="text-xs my-1 font-extrabold">
            {csm ? `${csm.first_name} ${csm?.last_name}` : "-"}
          </div>
          <div className="flex text-xs">
            <span className="mr-3">{csm ? csm.email : "-"}</span>
            {csm && (
              <DelayedToolTip title="copy">
                <CopyToClipboard text={csm.email}>
                  <img
                    src="/assets/file-copy.svg"
                    onClick={() => toast.success("Copied")}
                    alt="file-copy"
                  />
                </CopyToClipboard>
              </DelayedToolTip>
            )}
          </div>
        </div>
        <div className="bg-white p-3 rounded-md border">
          <div className="text-sm">Sales Region</div>
          <div className="font-extrabold my-1 text-xs flex">
            <span className="mr-3 text-xs">{owner?.region || "-"}</span>
            {owner && owner.region && (
              <DelayedToolTip title="Copy">
                <CopyToClipboard text={owner.region}>
                  <img
                    src="/assets/file-copy.svg"
                    onClick={() => toast.success("Copied")}
                    alt="file-copy"
                  />
                </CopyToClipboard>
              </DelayedToolTip>
            )}
          </div>
        </div>
      </div>

      {opportunity.length > 0 && (
        <div className="mt-4">
          <NestedToggleTableContainer
            header="Opportunity Details"
            placeholder="Search for opportunities"
            data={filteredOpportunity}
            columns={columns}
            searchParam={searchParam || searchParams.get("search") || undefined}
            highlightRow={rowId}
            height={`${window.innerHeight - 360}px`}
            exportData={exportOpportunity}
            exportHeader={exportHeaders}
            fileName={`${show?.amg_id}_Opportunities`}
            warning="Opportunities"
            open={rowId}
            isToggle={true}
            subTable={
              <>
                {rowId && (
                  <div className="text-left">
                    <OpportunityFileTable
                      opportunity_id={opportunityId!}
                      setOpenModal={setOpportunityId}
                    />
                  </div>
                )}
              </>
            }
            chart={
              <>
                {chart_values.find((val: number) => val === 0) !== undefined ? (
                  <div className="bg-white">
                    <div
                      className="p-4"
                      style={{
                        width: contentWidth,
                        height: `${window.innerHeight - 305}px`,
                      }}
                    >
                      <SingleChart
                        chartData={{
                          labels: sorted_chart_data!.map((c) =>
                            capitalize(c?.label)
                          ),
                          datasets: [
                            {
                              type: "bar" as const,
                              label: "Status",
                              data: sorted_chart_data!.map((c) => c?.amount),
                              backgroundColor: ["#b82b94"],
                              borderWidth: 0,
                              maxBarThickness: 40,
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="italic font-medium">
                    No Opportunities data found
                  </div>
                )}
              </>
            }
          />
        </div>
      )}
      {/* {opportunityId && (
        <div ref={myRef}>
          <OpportunityFileTable
            opportunity_id={opportunityId!}
            setOpenModal={setOpportunityId}
          />
        </div>
      )} */}
    </div>
  );
};

export default Sales;
