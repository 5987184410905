import React, { Dispatch, SetStateAction, useState } from "react";
import FormikSwitch from "../../formik/switch";
import FormikInput from "../../formik/input";
import FormikSelect from "../../formik/select";
import FormikMultiSelect from "../../formik/multi-select";
import { genreOptions,subGenreOptions } from "./picklist-options";
import { channelFields } from "../../../constants";
import Description from "../../formik/description";
import FormikCustomFields from "../../formik/custom-fields";
import MultiCheckTable from "../../formik/multi-check-table";
import FormikChannelRating from "../../formik/channel-rating";
import FileUpload from "../../file-upload";
import ReactPlayer from "react-player";
import ProgressBarComp from "../../progress-bar";
import FormikMultiInput from "../../formik/multi-input";
import FormikInputDateTime from "../../formik/input-datetime";
import { ChannelEditValuesType } from "./types";
import { useGetRedundantChannelNamesQuery } from "../../../hasura.graphql";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillTwitterSquare,
} from "react-icons/ai";
import { ChannelTrailersType } from "./types";
import FormikAvailabilityRegions from "../../formik/availability-regions";
// import SearchSelect from "../../formik/search-select";
// import CustomMultiSelect from "../../platforms/platform-edit-form/custom-multi-select";
import FormikKeyring from "../../formik/keyring";
import ToggleBox from "../../toggle-box";
import FormikGenre from "../../formik/genre";
import { useChannelNames } from "../../../all-channels-list";

export type ReactSelectType = {
  label: string;
  value: string;
};

const ChannelBusinessFieldsForm: React.FC<{
  posters: Array<string>;
  amagiIds: Array<ReactSelectType>;
  channelIds: Array<ReactSelectType>;
  videos: Array<ChannelTrailersType>;
  presentations: Array<string>;
  setPosters: Dispatch<SetStateAction<Array<string>>>;
  setTrailers: Dispatch<SetStateAction<Array<ChannelTrailersType>>>;
  setPresentations: Dispatch<SetStateAction<Array<string>>>;
  values: ChannelEditValuesType;
  redundant_playout_channels: Array<string>
  setFieldValue: any;
}> = ({
  posters,
  videos,
  amagiIds,
  channelIds,
  presentations,
  setPosters,
  setTrailers,
  setPresentations,
  values,
  redundant_playout_channels,
  setFieldValue,
}) => {
  const [hideTabs, setHideTabs] = useState<Array<string>>([]);
  const [logoProgress, setLogoProgress] = useState<number>(0);
  const [posterProgress, setPosterProgress] = useState<number>(0);
  const [trailerProgress, setTrailerProgress] = useState<number>(0);
  const [presentationProgress, setPresentationProgress] = useState<number>(0);

  const handleHideTabs = (tab: string) => {
    setHideTabs((hideTabs) => [...hideTabs, tab]);
  };
  const handleUnhideTabs = (tab: string) => {
    setHideTabs(hideTabs.filter((f: string) => f !== tab));
  };
 const { channelNames } = useChannelNames();
 const { data: redundantChannelData} = useGetRedundantChannelNamesQuery({
  variables: {
    args: {
      input_channel_id: values.channel_id
    },
  },
});
 const redundantChannels = redundantChannelData?.get_redundant_channel_names[0]?.redundant_playout_channels || [];

  return (
    <div>
      <ToggleBox
        header="Basic Information"
        columns={hideTabs}
        handleHideColumns={handleHideTabs}
        handleUnhideColumns={handleUnhideTabs}
      >
        <>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div>
              <FormikInput
                inlinelabel="Channel ID"
                name="channel_id"
                disabled
                defaultValue={values.channel_id}
              />
            </div>
            <div>
              <FormikInput
                inlinelabel="Channel Name"
                name="name"
                defaultValue={values.name}
              />
            </div>
            <div>
              <FormikInput
                inlinelabel="Channel Brand"
                name="channel_brand"
                defaultValue={values.channel_brand}
              />
            </div>
          </div>
          <div className="my-8">
            <FormikKeyring
              defaultId={values.salesforce_id!}
              keyring={values.keyring}
            />
          </div>
          {/* <div className="text-base font-semibold mt-8 mb-2">Channel Owner</div> */}
          {/* <div className="grid grid-cols-3 gap-y-8 gap-x-36 mb-8">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <SearchSelect
                inlinelabel="Amagi ID"
                name="amagi_id_of_channel_owner"
                defaultValue={values.amagi_id_of_channel_owner}
                defFields={amagiIds}
              />
            </div>
          </div> */}
          <div className="text-base font-semibold mb-2">Description</div>
          <Description name="description" description={values.description} />
          <div className="grid grid-cols-3 gap-y-8 gap-x-36 mt-8">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikGenre
                inlinelabel="Channel Genre"
                name="genre"
                defaultValue={values.genre}
                defFields={genreOptions}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikMultiSelect
                inlinelabel="Sub Genre"
                name="sub_genre"
                defaultValue={values.sub_genre}
                defFields={subGenreOptions}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Channel Type"
                name="channel_type"
                defaultValue={values.channel_type}
                defFields={channelFields.channelType.sort()}
              />
            </div>
            {values.channel_type === "Pop-up" && (
              <div className="">
                <div className="text-gray-400">Active Timeline</div>
                <div className="flex">
                  <div className="mr-8">
                    <FormikInputDateTime
                      name="channel_start_time"
                      defaultValue={values.channel_start_time}
                      inlinelabel=""
                    />
                  </div>
                  <div>
                    <FormikInputDateTime
                      name="channel_end_time"
                      defaultValue={values.channel_end_time}
                      inlinelabel=""
                    />
                  </div>
                </div>
              </div>
            )}
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Feed Type"
                name="feed_type"
                defaultValue={values.feed_type}
                defFields={channelFields.feedType.sort()}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Fast"
                name="fast"
                defaultValue={values.fast}
                defFields={["Yes", "No"]}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
            <FormikMultiSelect
              inlinelabel="Redundant Playout Channels"
              name="redundant_playout_channels"
              defaultValue={redundantChannels}
              defFields={channelNames}
              isRedundant={true}
            />
          </div>
          </div>
        </>
      </ToggleBox>
      <ToggleBox
        header="Channel Demographics"
        columns={hideTabs}
        handleHideColumns={handleHideTabs}
        handleUnhideColumns={handleUnhideTabs}
      >
        <>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Channel Origin Country / Region"
                name="channel_origin_country_region"
                defaultValue={values.channel_origin_country_region}
                defFields={channelFields.availability_countries.sort()}
              />
            </div>
          </div>
          <div className="my-8">
            <FormikChannelRating
              chnRating={
                values?.channel_rating.length > 0
                  ? values?.channel_rating
                  : [
                      {
                        region: "",
                        country: "",
                        rating: "",
                      },
                    ]
              }
            />
          </div>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikMultiSelect
                inlinelabel="Target Audience"
                name="target_audience"
                defaultValue={values.target_audience}
                defFields={channelFields.targetAudience.sort()}
              />
            </div>
          </div>
          <div className="mt-8">
            <FormikAvailabilityRegions
              availRegion={
                values?.availablity_country_and_regions.length > 0
                  ? values?.availablity_country_and_regions
                  : [
                      {
                        country: [],
                        region: "",
                      },
                    ]
              }
              fieldName="availablity_country_and_regions"
            />
          </div>
        </>
      </ToggleBox>
      <ToggleBox
        header="Channel Artwork and Social Media"
        columns={hideTabs}
        handleHideColumns={handleHideTabs}
        handleUnhideColumns={handleUnhideTabs}
      >
        <>
          <div className="mb-12">
            <span className="text-gray-400">Channel Logo</span>
            {values.logo && (
              <div className="flex mt-4">
                <div className="w-44 h-24 flex justify-center">
                  <img
                    className="w-24 h-24 object-fill"
                    src={`/${values.logo}`}
                    alt="Channel Logo"
                  />
                </div>
                <div className="ml-4">
                  <div>Channel Logo</div>
                  <div
                    className="text-blue-500 mt-2 cursor-pointer"
                    onClick={() => {
                      setFieldValue("logo", "");
                    }}
                  >
                    Remove
                  </div>
                </div>
              </div>
            )}
            {logoProgress > 0 && logoProgress < 100 && (
              <ProgressBarComp progress={logoProgress} color="green" />
            )}
            <div className="flex items-center cursor-pointer mt-3 text-blue-500">
              <FileUpload
                setProgress={setLogoProgress}
                value={values.logo!}
                fileType=".jpg, .png"
                amagi_id={values.customer?.amagi_id}
                onUpload={(filekey) => {
                  setFieldValue("logo", filekey);
                }}
              />
            </div>
          </div>
          <div className="mb-12">
            <span className="text-gray-400">Posters</span>
            {posters.length > 0 &&
              posters.length < 7 &&
              posters.map((poster: string, index: number) => {
                return (
                  <div className="flex mt-4">
                    <div className="w-44 h-24 flex justify-center">
                      <img
                        className="w-full rounded-md"
                        src={`/${poster}`}
                        alt={poster}
                      />
                    </div>
                    <div className="ml-4">
                      <div>{`Channel Poster ${index + 1}`}</div>
                      <div
                        className="text-blue-500 mt-2 cursor-pointer"
                        onClick={() =>
                          setPosters(
                            posters.filter((post: string) => post !== poster)
                          )
                        }
                      >
                        Remove
                      </div>
                    </div>
                  </div>
                );
              })}
            {posterProgress > 0 && posterProgress < 100 && (
              <ProgressBarComp progress={posterProgress} color="green" />
            )}
            <div className="flex items-center cursor-pointer mt-3 text-blue-500">
              <FileUpload
                setProgress={setPosterProgress}
                fileType=".png, .jpg"
                amagi_id={values.customer?.amagi_id}
                onUpload={(filekey) => {
                  setPosters((prevPost) => [...prevPost, filekey]);
                }}
              />
            </div>
          </div>
          <div className="mb-12">
            <span className="text-gray-400">Trailers</span>
            {videos &&
              videos.map((trail: ChannelTrailersType, index: number) => {
                return (
                  <div className="flex mt-4">
                    <div className="flex justify-center rounded-md bg-gray-600">
                      <ReactPlayer
                        style={{ width: 300, height: 250 }}
                        url={`/${trail.url}`}
                        light={true}
                        controls={true}
                      />
                    </div>
                    <div className="ml-4">
                      <div>{`Trailer ${index + 1}`}</div>
                      <div
                        className="text-blue-500 mt-2 cursor-pointer"
                        onClick={() =>
                          setTrailers(
                            videos.filter(
                              (trailer: ChannelTrailersType) =>
                                trailer.url !== trail.url
                            )
                          )
                        }
                      >
                        Remove
                      </div>
                    </div>
                  </div>
                );
              })}
            {trailerProgress > 0 && trailerProgress < 100 && (
              <ProgressBarComp progress={trailerProgress} color="green" />
            )}
            <div className="flex items-center cursor-pointer mt-3 text-blue-500">
              <FileUpload
                setProgress={setTrailerProgress}
                fileType=".mp4, .mov"
                amagi_id={values.customer?.amagi_id}
                onUpload={(filekey) => {
                  setTrailers((prevTrailer) => [
                    ...prevTrailer,
                    {
                      url: filekey,
                      isPrimary: values.videos.length > 0 ? false : true,
                    },
                  ]);
                }}
              />
            </div>
          </div>
          <div className="mb-12">
            <span className="text-gray-400">Presentations</span>
            {presentations.length > 0 &&
              presentations.map((pres: string, index: number) => {
                return (
                  <>
                    {/* <div className="ml-4">
                      <div>{`Presentation ${index + 1}`}</div>
                      <div
                        className="text-blue-500 mt-2 cursor-pointer"
                        onClick={() =>
                          setTrailers(
                            videos.filter(
                              (trailer: ChannelTrailersType) =>
                                trailer.url !== trail.url
                            )
                          )
                        }
                      >
                        Remove
                      </div>
                    </div> */}
                    <div key={index} className="flex mt-4">
                      <div className="w-44 h-24 border flex justify-center rounded">
                        <img
                          src={
                            pres.slice(pres.length - 3) === "pdf"
                              ? "/assets/pdf-icon.svg"
                              : "/assets/ppt-icon.svg"
                          }
                          alt="file-icon"
                        />
                      </div>
                      <div className="ml-4">
                        <div>{`Presentation ${index + 1}`}</div>
                        <div
                          className="text-blue-500 mt-2 cursor-pointer"
                          onClick={() =>
                            setPresentations(
                              presentations.filter((prest) => prest !== pres)
                            )
                          }
                        >
                          Remove
                        </div>
                      </div>
                    </div>
                    {/* {openPresentation && (
                      <DialogModal
                        onClose={setOpenPresentation}
                        modalShow={openPresentation}
                      >
                        <div>
                          <DocViewer
                            documents={presentations
                              .filter((present: string) => present === pres)
                              .map((prest: string) => {
                                return {
                                  uri: `/${prest}`,
                                };
                              })}
                            pluginRenderers={DocViewerRenderers}
                          />
                        </div>
                      </DialogModal>
                    )} */}
                  </>
                );
              })}
            {presentationProgress > 0 && presentationProgress < 100 && (
              <ProgressBarComp progress={presentationProgress} color="green" />
            )}
            <div className="flex items-center cursor-pointer mt-3 text-blue-500">
              <FileUpload
                setProgress={setPresentationProgress}
                fileType=".pdf, .ppt, .pptx"
                amagi_id={values.customer?.amagi_id}
                onUpload={(filekey) => {
                  console.log(filekey);
                  setPresentations((present) => [...present, filekey]);
                }}
              />
            </div>
          </div>
          <div className="text-base font-semibold mt-8 mb-2">
            Social Profiles
          </div>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div className="flex justify-start items-center">
              <span className="mr-2 mt-5 text-xl">
                <AiFillInstagram />
              </span>
              <FormikInput
                inlinelabel="Instagram"
                name="instagram"
                placeholder="Ex: https://instagram.com"
                defaultValue={values.instagram}
              />
            </div>
            <div className="flex justify-start items-center">
              <span className="mr-2 mt-5 text-xl">
                <AiFillFacebook />
              </span>
              <FormikInput
                inlinelabel="Facebook"
                name="facebook"
                placeholder="Ex: https://facebook.com"
                defaultValue={values.facebook}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <div className="flex justify-start items-center">
                <span className="mr-2 mt-5 text-xl">
                  <AiFillLinkedin />
                </span>
                <FormikInput
                  inlinelabel="Linkedin"
                  name="linkedin"
                  placeholder="Ex: https://linkedin.com"
                  defaultValue={values.linkedin}
                />
              </div>
            </div>
            <div className="flex justify-start items-center">
              <span className="mr-2 mt-5 text-xl">
                <AiFillTwitterSquare />
              </span>
              <FormikInput
                inlinelabel="Twitter"
                name="twitter"
                placeholder="Ex: https://twitter.com"
                defaultValue={values.twitter}
              />
            </div>
          </div>
          {/* <div>
                    <span>Trailer</span>
                    <ChannelTrailer values={values.channel_trailers} />
                  </div> */}
        </>
      </ToggleBox>
      <ToggleBox
        header="Channel Meta"
        columns={hideTabs}
        handleHideColumns={handleHideTabs}
        handleUnhideColumns={handleUnhideTabs}
      >
        <>
          <div className="grid grid-cols-3 gap-y-4 gap-x-36 mb-8">
            <div>
              <FormikSelect
                inlinelabel="Original Audio Language"
                name="original_audio_language"
                defaultValue={values.original_audio_language}
                defFields={channelFields.languages.sort()}
              />
            </div>
            <div>
              <FormikMultiSelect
                inlinelabel="Dubbing Languages"
                name="dubbing_language"
                defaultValue={values.dubbing_language}
                defFields={channelFields.languages.sort()}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Subtitle</label>
              <FormikSwitch
                name="subtitle_support"
                defaultValue={values.subtitle_support}
              />
            </div>
            {values.subtitle_support && (
              <>
                <div>
                  <FormikMultiSelect
                    inlinelabel="Subtitle Language"
                    name="subtitle_language"
                    defaultValue={values.subtitle_language}
                    defFields={channelFields.languages.sort()}
                  />
                </div>
                <div>
                  <FormikSelect
                    inlinelabel="Subtitle Type"
                    name="subtitle_type"
                    defaultValue={values.subtitle_type}
                    defFields={channelFields.subtitleType.sort()}
                  />
                </div>
              </>
            )}
          </div>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36 mb-3">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="SSAI Partner"
                name="ssai_partner"
                defaultValue={values.ssai_partner}
                defFields={channelFields.ssaiPartner.sort()}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <MultiCheckTable
                paid_flag={values.paid_flag}
                ad_flag={values.ad_flag}
                promotion_flag={values.promotion_flag}
                is_adsplus_channel={values.is_adsplus_channel}
                is_contentplus_channel={values.is_contentplus_channel}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Channel Redundant</label>
              <FormikSwitch
                name="is_redundant"
                defaultValue={values.is_redundant}
              />
            </div>
          </div>
        </>
      </ToggleBox>
      <ToggleBox
        header="Custom Inputs"
        columns={hideTabs}
        handleHideColumns={handleHideTabs}
        handleUnhideColumns={handleUnhideTabs}
      >
        <>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div>
              <FormikMultiInput
                inlinelabel="Custom Tags"
                name="custom_tags"
                defaultValue={values.custom_tags}
              />
            </div>
          </div>
          <div className="mt-8">
            <FormikCustomFields values={values.custom_fields} />
          </div>
        </>
      </ToggleBox>
    </div>
  );
};

export default ChannelBusinessFieldsForm;
