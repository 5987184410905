import React from "react";
import { useField } from "formik";

const FormikInput: React.FC<{
  inlinelabel: string;
  defaultValue: string | null;
  placeholder?: string;
  disabled?: boolean;
  name: string;
}> = ({
  inlinelabel,
  defaultValue,
  disabled = false,
  placeholder = "Enter Text",
  name,
}) => {
  const _field = useField(name);
  const field = _field[0];
  const { setValue } = _field[2];
  return (
    <div style={{ padding: "5px 0px" }} className="w-full">
      <label className="text-gray-400">{inlinelabel || ""}</label>
      <input
        type="text"
        value={defaultValue || ""}
        disabled={disabled}
        name={field.name}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
        className="py-1 px-0 border-0 border-b focus:ring-0 border-gray-300 outline-none bg-transparent w-full"
        style={{ fontSize: 14 }}
      />
    </div>
  );
};

export default FormikInput;
