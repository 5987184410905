import { Dialog, Transition } from "@headlessui/react";
import { DownloadIcon, XIcon } from "@heroicons/react/outline";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import Loader from "../../common/loader";
import SubTableContainer from "../../common/sub-table";
import { useOpportunityFileQuery } from "../../hasura.graphql";
import axios from "axios";

type ViewFileType = {
  file_name: string;
  file_type: string;
  file: string;
};

const OpportunityFileTable: React.FC<{
  opportunity_id: string;
  setOpenModal: React.Dispatch<React.SetStateAction<string | null>>;
}> = ({ opportunity_id, setOpenModal }) => {
  const [viewFile, setViewFile] = useState<ViewFileType>({
    file_name: "",
    file_type: "",
    file: "",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [filteredOpportunityFiles, setFilteredOpportunityFiles] = useState([]);
  const [s3FetchError, sets3FetchError] = useState(false);
  const { data, loading, error } = useOpportunityFileQuery({
    variables: {
      id: opportunity_id,
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          data?.opportunity &&
          data.opportunity.length > 0 &&
          data.opportunity[0].files!?.records.length > 0
        ) {
          let tempFilteredOpportunityFiles: any = [];
          const files = data?.opportunity[0].files?.records;
          for (const index of files!) {
            let file = index;
            const s3UrlWithQueryParams = file?.ContentVersion.Content;
            const response = await axios.get(s3UrlWithQueryParams);
            if (file) {
              tempFilteredOpportunityFiles.push({
                file_id: file.Id,
                file_name: file.ContentDocument.Title,
                file_type: file.ContentDocument.FileType,
                file_ext: file.ContentDocument.FileExtension,
                file: response.data,
              });
            }
          }
          setFilteredOpportunityFiles(tempFilteredOpportunityFiles);
        }
        if (data) {
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        sets3FetchError(true);
      }
    };
    fetchData();
  }, [data, loading, error]);

  const columns: any = useMemo(
    () => [
      {
        Header: "File ID",
        accessor: "file_id",
      },
      {
        Header: "File Name",
        accessor: "file_name",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.original.file_name.length > 60
              ? `${cell.row.original.file_name.slice(
                  0,
                  20
                )}........${cell.row.original.file_name.slice(
                  cell.row.original.file_name.length - 20,
                  cell.row.original.file_name.length
                )}`
              : cell.row.original.file_name}
          </span>
        ),
      },
      {
        Header: "File Type",
        accessor: "file_type",
      },
      {
        Header: "View",
        accessor: "",
        Cell: ({ cell }: any) => (
          <>
            {cell.row.original.file_type === "PDF" ? (
              <span
                className="text-blue-500 underline cursor-pointer"
                onClick={() =>
                  setViewFile({
                    file_name: cell.row.original.file_name,
                    file_type: cell.row.original.file_type,
                    file: cell.row.original.file,
                  })
                }
              >
                View
              </span>
            ) : (
              <span>-</span>
            )}
          </>
        ),
      },
      {
        Header: "Download",
        accessor: "",
        Cell: ({ cell }: any) => (
          <span className="text-blue-500 underline cursor-pointer">
            <a
              href={`data:application/octet-stream;base64,${cell.row.original.file}`}
              download={`${cell.row.original.file_name}.${cell.row.original.file_ext}`}
            >
              <DownloadIcon className="h-5 w-5 text-blue-500" />
            </a>
          </span>
        ),
      },
      // {
      //   Header: "File Name",
      //   accessor: 'file_name'
      // },
    ],
    []
  );

  if (loading) return <Loader />;
  if (error) return <i>Error while fetching files</i>;
  if (s3FetchError) return <i>Timed out</i>;
  if (!data) return <i>No files found</i>;

  return (
    <>
      {!isLoading ? (
        <div>
          {" "}
          <div>
            {filteredOpportunityFiles!.length > 0 ? (
              <SubTableContainer
                data={filteredOpportunityFiles}
                header="Opportunity Files"
                columns={columns}
              />
            ) : (
              <div
                className="p-4 bg-white border rounded-md italic font-medium text-left"
                style={{
                  width: `${window.innerWidth * 0.91}px`,
                }}
              >
                No Records found
              </div>
            )}
          </div>
          {viewFile.file_name.length > 0 && viewFile.file_type === "PDF" && (
            <Transition
              appear
              show={viewFile.file_name.length > 0 ? true : false}
              as={Fragment}
            >
              <Dialog
                as="div"
                className="relative z-50"
                onClose={() =>
                  setViewFile({
                    file_name: "",
                    file_type: "",
                    file: "",
                  })
                }
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div
                  className="fixed inset-0 overflow-y-auto"
                  style={{ zIndex: 999 }}
                >
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                        <div>
                          <div className="flex justify-between items-center mb-3">
                            <div className="font-semibold text-lg">
                              {viewFile.file_name}
                            </div>
                            <span
                              className="cursor-pointer"
                              onClick={() =>
                                setViewFile({
                                  file_name: "",
                                  file_type: "",
                                  file: "",
                                })
                              }
                            >
                              <XIcon className="h-5 w-5 text-gray-500" />
                            </span>
                          </div>
                          <iframe
                            title={viewFile.file_name!}
                            className="w-full"
                            style={{ height: "80vh" }}
                            src={`data:application/pdf;base64,${viewFile.file}`}
                          />
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default OpportunityFileTable;
