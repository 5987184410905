import { DateTime } from "luxon";
import { useState } from "react";
import Loader from "../common/loader";
import DQChart from "../components/charts/dq-chart";
import { months } from "../components/utils";
import { useDqCustomerTicketsQuery } from "../hasura.graphql";

const DashboardTickets = () => {
  const [monthRange, setMonthRange] = useState<string>("6");
  const { data, loading, error } = useDqCustomerTicketsQuery({
    variables: {
      from_date: DateTime.local()
        .startOf("month")
        .minus({ months: parseInt(monthRange) })
        .toISO()
        .slice(0, 10),
    },
  });

  if (loading) return <Loader />;
  if (error) return <i>Error</i>;
  if (!data) return <i>No Platform Trend Data</i>;

  // const sample = [
  //   {
  //     month: "2022-03",
  //     open_ticket: 3729,
  //     closed_ticket: 0,
  //   },
  //   {
  //     month: "2022-04",
  //     open_ticket: 3967,
  //     closed_ticket: 0,
  //   },
  //   {
  //     month: "2022-05",
  //     open_ticket: 3837,
  //     closed_ticket: 0,
  //   },
  //   {
  //     month: "2022-06",
  //     open_ticket: 3851,
  //     closed_ticket: 0,
  //   },
  //   {
  //     month: "2022-07",
  //     open_ticket: 4152,
  //     closed_ticket: 0,
  //   },
  //   {
  //     month: "2022-08",
  //     open_ticket: 4084,
  //     closed_ticket: 0,
  //   },
  //   {
  //     month: "2022-09",
  //     open_ticket: 3990,
  //     closed_ticket: 0,
  //   },
  //   {
  //     month: "2022-10",
  //     open_ticket: 3894,
  //     closed_ticket: 0,
  //   },
  //   {
  //     month: "2022-11",
  //     open_ticket: 481,
  //     closed_ticket: 0,
  //   },
  //   {
  //     month: "2022-12",
  //     open_ticket: 0,
  //     closed_ticket: 0,
  //   },
  //   {
  //     month: "2023-01",
  //     open_ticket: 0,
  //     closed_ticket: 0,
  //   },
  // ];

  return (
    <div className="grid grid-cols-3 gap-4 mt-4">
      <div
        className="bg-white rounded-md pb-4 flex flex-col justify-center"
        style={{ border: "1px solid #E0E0E0" }}
      >
        <div className="flex justify-between border-b border-gray-200">
          <div className="text-left pl-4 py-2 font-medium">Tickets</div>
          <select
            value={monthRange}
            onChange={(e) => setMonthRange(e.target.value)}
            className="border-0 rounded text-sm text-gray-500 focus:ring-0"
          >
            <option value={3}>Last 3 months</option>
            <option value={6}>Last 6 months</option>
            <option value={12}>Last 12 months</option>
          </select>
        </div>
        <div className="p-2 mt-2">
          <div className="flex justify-end items-center mx-2">
            <div
              style={{ width: 6, height: 6, background: "#5E81C0" }}
              className="rounded-full"
            ></div>
            <div className="mx-2 text-xs text-gray-600">Open</div>
            <div
              style={{ width: 6, height: 6, background: "#B82B94" }}
              className="rounded-full ml-4"
            ></div>
            <div className="mx-2 text-xs text-gray-600">Closed</div>
            {/* <div
            style={{ width: 6, height: 6, background: "#F48229" }}
            className="rounded-full ml-4"
          ></div>
          <div className="mx-2 text-xs text-gray-600">Churned</div> */}
          </div>
          <DQChart
            chartData={{
              labels: data.tickets.map(
                (cust) =>
                  `${
                    months[parseInt(cust.month.slice(5)) - 1]
                  } '${cust.month.slice(2, 4)}`
              ),
              datasets: [
                {
                  type: "line" as const,
                  label: "Open Tickets",
                  data: data.tickets.map((c) => c.open_ticket),
                  borderColor: ["#5E81C0"],
                  backgroundColor: ["#5E81C0"],
                  borderWidth: 1,
                  tension: 0.5,
                },
                {
                  type: "line" as const,
                  label: "Closed Tickets",
                  data: data.tickets.map((c) => c.closed_ticket),
                  borderColor: ["#B82B94"],
                  backgroundColor: ["#B82B94"],
                  borderWidth: 1,
                  tension: 0.5,
                },
                // {
                //   type: "line" as const,
                //   label: "Churned Platforms",
                //   data: data.tickets?.map((c) => c.churned),
                //   borderColor: ["#F48229"],
                //   backgroundColor: ["#F48229"],
                //   borderWidth: 1,
                //   tension: 0.5,
                // },
              ],
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardTickets;
