import Loader from "../../common/loader";
import { useDashboardCountQuery } from "../../hasura.graphql";
import CountCard from "../cards/count-card";
import DQOverviewChannel from "./dq-overview-channel";
import DQOverviewCustomer from "./dq-overview-customer";
import DQOverviewPlatform from "./dq-overview-platform";

const DQOverview = () => {
  const { data, loading, error } = useDashboardCountQuery();

  if (loading) {
    return <Loader />;
  }

  if (!data) {
    return <i>Data unavailable</i>;
  }

  if (error) {
    return <i>There was an error</i>;
  }

  const paying =
    data.customer_count.find((cus) => cus.customer_type === "paying")?.count ||
    0;
  const total =
    data.customer_count.find((cus) => cus.customer_type === "total")?.count ||
    0;
  const nonPaying = total - paying;

  return (
    <div>
      <div className="mt-4 grid grid-cols-3 gap-4">
        <CountCard
          count={total}
          cardText="Total Active Customers"
          icon="/assets/total-customers.svg"
        />
        <CountCard
          count={paying}
          cardText="Total Paying Customers"
          icon="/assets/total-customers.svg"
        />
        <CountCard
          count={nonPaying}
          cardText="Total Non-Paying Customers"
          icon="/assets/total-customers.svg"
        />
      </div>
      <div className="grid grid-cols-2 gap-4 mt-4">
        <CountCard
          count={data.channel_aggregate.aggregate?.count || 0}
          cardText="Total Channels"
          icon="/assets/total-channels.svg"
        />
        <CountCard
          count={data.platform_aggregate.aggregate?.count || 0}
          cardText="Total Platforms"
          icon="/assets/total-platforms.svg"
        />
        {/* <CountCard
          count={data.customer_aggregate.aggregate?.count || 0}
          cardText="Total Deliveries"
          icon="/assets/total-deliveries.svg"
        /> */}
      </div>
      <div className="grid grid-cols-3 gap-4 mt-4">
        <DQOverviewCustomer />
        <DQOverviewChannel />
        <DQOverviewPlatform />
        {/* <div
          className="bg-white rounded-md pb-4 flex flex-col justify-center"
          style={{ border: "1px solid #E0E0E0" }}
        >
          <div className="flex justify-between border-b border-gray-200">
            <div className="text-left pl-4 py-2 font-medium">
              Channel Trends
            </div>
            <select
              value={monthRange}
              onChange={(e) => setMonthRange(e.target.value)}
              className="border-0 text-sm text-gray-500 focus:ring-0"
            >
              <option value={3}>Last 3 months</option>
              <option value={6}>Last 6 months</option>
              <option value={12}>Last 12 months</option>
            </select>
          </div>
          <div className="p-2 mt-2">
            <div className="flex justify-end items-center mx-2">
              <div
                style={{ width: 6, height: 6, background: "#5E81C0" }}
                className="rounded-full"
              ></div>
              <div className="mx-2 text-xs text-gray-600">New Channels</div>
              <div className="text-sm font-medium">
                {Math.round(sampleData[sampleData.length - 1].value)}
              </div>
              <div
                style={{ width: 6, height: 6, background: "#F48229" }}
                className="rounded-full ml-4"
              ></div>
              <div className="mx-2 text-xs text-gray-600">Churned Channels</div>
              <div className="text-sm font-medium">
                {Math.round(sampleData_2[sampleData_2.length - 1].value)}
              </div>
            </div>
            <DQChart
              chartData={{
                labels: sampleData.map((data) => data.label),
                datasets: [
                  {
                    type: "line" as const,
                    label: "New Channels",
                    data: sampleData?.map((c) => c.value),
                    borderColor: ["#5E81C0"],
                    backgroundColor: ["#5E81C0"],
                    borderWidth: 1,
                  },
                  {
                    type: "line" as const,
                    label: "Churned Channels",
                    data: sampleData_2?.map((c) => c.value),
                    borderColor: ["#F48229"],
                    backgroundColor: ["#F48229"],
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </div>
        </div>
        <div
          className="bg-white rounded-md pb-4 flex flex-col justify-center"
          style={{ border: "1px solid #E0E0E0" }}
        >
          <div className="flex justify-between border-b border-gray-200">
            <div className="text-left pl-4 py-2 font-medium">
              Billed Revenue
            </div>
            <select
              value={monthRange}
              onChange={(e) => setMonthRange(e.target.value)}
              className="border-0 text-sm text-gray-500 focus:ring-0"
            >
              <option value={3}>Last 3 months</option>
              <option value={6}>Last 6 months</option>
              <option value={12}>Last 12 months</option>
            </select>
          </div>
          <div className="p-2 mt-2">
            <div className="flex justify-end items-center mx-2">
              <div
                style={{ width: 6, height: 6, background: "#5E81C0" }}
                className="rounded-full"
              ></div>
              <div className="mx-2 text-xs text-gray-600">Subscription</div>
              <div
                style={{ width: 6, height: 6, background: "#715EC2" }}
                className="rounded-full ml-4"
              ></div>
              <div className="mx-2 text-xs text-gray-600">Consumption</div>
              <div
                style={{ width: 6, height: 6, background: "#B82B94" }}
                className="rounded-full ml-4"
              ></div>
              <div className="mx-2 text-xs text-gray-600">Others</div>
            </div>
            <DQChart
              stackedBar={true}
              chartData={{
                labels: sampleData.map((data) => data.label),
                datasets: [
                  {
                    type: "bar" as const,
                    label: "Subscription",
                    data: sampleData?.map((c) => c.value),
                    backgroundColor: ["#5E81C0"],
                  },
                  {
                    type: "bar" as const,
                    label: "Consumption",
                    data: sampleData_2?.map((c) => c.value),
                    backgroundColor: ["#715EC2"],
                  },
                  {
                    type: "bar" as const,
                    label: "Others",
                    data: sampleData_3?.map((c) => c.value),
                    backgroundColor: ["#B82B94"],
                  },
                ],
              }}
            />
          </div>
        </div>
        <div
          className="bg-white rounded-md pb-4 flex flex-col justify-center"
          style={{ border: "1px solid #E0E0E0" }}
        >
          <div className="flex justify-between border-b border-gray-200">
            <div className="text-left pl-4 py-2 font-medium">
              Customer Lifetime Value
            </div>
            <select
              value={monthRange}
              onChange={(e) => setMonthRange(e.target.value)}
              className="border-0 text-sm text-gray-500 focus:ring-0"
            >
              <option value={3}>Last 3 months</option>
              <option value={6}>Last 6 months</option>
              <option value={12}>Last 12 months</option>
            </select>
          </div>
          <div className="p-2 mt-2">
            <div className="flex justify-end items-center mx-2">
              <div
                style={{ width: 6, height: 6, background: "#5E81C0" }}
                className="rounded-full"
              ></div>
              <div className="mx-2 text-xs text-gray-600">Avg. CLTV</div>
              <div className="text-sm font-medium">
                {currencyConverter(
                  sampleData[sampleData.length - 1].value,
                  show?.currency || "USD"
                )}
              </div>
            </div>
            <DQChart
              chartData={{
                labels: sampleData.map((data) => data.label),
                datasets: [
                  {
                    type: "line" as const,
                    label: "Customer Lifetime Value",
                    data: sampleData?.map((c) => c.value),
                    borderColor: ["#5E81C0"],
                    backgroundColor: ["#5E81C0"],
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </div>
        </div>
        <div
          className="bg-white rounded-md pb-4 flex flex-col justify-center"
          style={{ border: "1px solid #E0E0E0" }}
        >
          <div className="flex justify-between border-b border-gray-200">
            <div className="text-left pl-4 py-2 font-medium">
              Days Sales Outstanding
            </div>
            <select
              value={monthRange}
              onChange={(e) => setMonthRange(e.target.value)}
              className="border-0 text-sm text-gray-500 focus:ring-0"
            >
              <option value={3}>Last 3 months</option>
              <option value={6}>Last 6 months</option>
              <option value={12}>Last 12 months</option>
            </select>
          </div>
          <div className="p-2 mt-2">
            <div className="flex justify-end items-center mx-2">
              <div
                style={{ width: 6, height: 6, background: "#5E81C0" }}
                className="rounded-full"
              ></div>
              <div className="mx-2 text-xs text-gray-600">Avg. DSO</div>
              <div className="text-sm font-medium">
                {`${Math.round(sampleData[sampleData.length - 1].value)} days`}
              </div>
            </div>
            <DQChart
              chartData={{
                labels: sampleData.map((data) => data.label),
                datasets: [
                  {
                    type: "line" as const,
                    label: "Days Sales Outstanding",
                    data: sampleData?.map((c) => c.value),
                    borderColor: ["#5E81C0"],
                    backgroundColor: ["#5E81C0"],
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default DQOverview;
