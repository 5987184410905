import { ChevronRightIcon } from "@heroicons/react/outline";
import { Form, Formik } from "formik";
import { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useCDPRole } from "../../../cdp-role-context";
import Loader from "../../../common/loader";
import {
  PlatformsDocument,
  usePlatformByPkQuery,
  useUpdatePlatformMutation,
} from "../../../hasura.graphql";
import PlatformBusinessFieldsForm from "./business-fields-form";
import PlatformTechnicalSpecsForm from "./technical-specs-form";
import SubTabs from "../../sub-tabs";

export type ChannelVideosType = {
  url: string;
  isPrimary: boolean;
};

const PlatformEditForm: React.FC<{
  platformId: string;
  isModal: boolean;
  close: () => void;
  header?: string;
  url?: string;
}> = ({ platformId, isModal, close, header, url }) => {
  const navigate = useNavigate();
  const [platformTab, setPlatformTab] = useState("Business Specs");
  const [amagiId, setAmagiId] = useState<string>("");
  const [postersList, setPostersList] = useState<Array<string>>([]);
  const [videos, setVideos] = useState<Array<ChannelVideosType>>([]);
  const [presentationsList, setPresentationsList] = useState<Array<string>>([]);
  const { CDPRole } = useCDPRole();
  const [update_platform] = useUpdatePlatformMutation({
    refetchQueries: [
      {
        query: PlatformsDocument,
        variables: {
          amagi_id: amagiId,
        },
      },
    ],
  });

  const { data, loading, error } = usePlatformByPkQuery({
    variables: {
      _id: platformId,
    },
    onCompleted: (data) => {
      setPostersList(data.platform?.poster);
      setVideos(data.platform?.video);
      setPresentationsList(data.platform?.presentation);
      setAmagiId(data.platform?.account?.amagi_id!);
    },
  });

  if (loading) {
    return (
      <div className={`${!isModal && "mx-6 mt-4"}`}>
        <Loader />
      </div>
    );
  }
  if (error) {
    return <i>Error</i>;
  }
  if (!data) {
    return <i>Channel data not available</i>;
  }

  const platformData = data.platform;

  const amagiIds = data.customer.map((cus) => {
    return {
      label: `${cus.amagi_id} - ${cus.account_name}` || "",
      value: cus.salesforce_id || "",
    };
  });

  const platformIds = data.platforms.map((chn) => {
    return {
      label: chn.salesforce_id,
      value: chn.salesforce_id,
    };
  });

  return (
    <div className={`${!isModal && "mx-6"}`}>
      <Toaster />
      <Formik
        initialValues={{
          name: platformData?.name ? platformData?.name : null,
          platform_id: platformData?.id ? platformData?.id : null,
          onboarded_date: platformData?.onboarded_date ? platformData?.onboarded_date : null,
          keyring: platformData?.keyring ? platformData?.keyring : [],
          salesforce_id: platformData?.salesforce_id
            ? platformData?.salesforce_id
            : null,
          multiple_salesforce_id: platformData?.multiple_salesforce_id
            ? platformData?.multiple_salesforce_id
            : [],
          account: platformData?.account,
          salesforce_account_id: platformData?.salesforce_account_id
            ? platformData?.salesforce_account_id
            : null,
          platform_brand: platformData?.platform_brand
            ? platformData?.platform_brand
            : null,
          platform_description: platformData?.platform_description
            ? platformData?.platform_description
            : null,
          platform_tier: platformData?.platform_tier
            ? platformData?.platform_tier
            : null,
          website_url: platformData?.website_url
            ? platformData?.website_url
            : null,
          devices_field: platformData?.devices_field
            ? platformData?.devices_field
            : [],
          logo: platformData?.logo ? platformData?.logo : null,
          poster: postersList,
          video: videos,
          presentation: presentationsList,
          twitter: platformData?.twitter ? platformData?.twitter : null,
          instagram: platformData?.instagram ? platformData?.instagram : null,
          facebook: platformData?.facebook ? platformData?.facebook : null,
          linkedin: platformData?.linkedin ? platformData?.linkedin : null,
          availablity_country_and_regions:
            platformData?.availablity_country_and_regions
              ? platformData?.availablity_country_and_regions
              : [],
          target_audience: platformData?.target_audience
            ? platformData?.target_audience
            : null,
          preferred_genre: platformData?.preferred_genre
            ? platformData?.preferred_genre
            : null,
          preferred_language: platformData?.preferred_language
            ? platformData?.preferred_language
            : null,
          avg_monthly_active_user: platformData?.avg_monthly_active_user
            ? platformData?.avg_monthly_active_user
            : null,
          avg_monthly_hov: platformData?.avg_monthly_hov
            ? platformData?.avg_monthly_hov
            : null,
          avg_ad_minute_per_hour: platformData?.avg_ad_minute_per_hour
            ? platformData?.avg_ad_minute_per_hour
            : null,
          platform_artwork: platformData?.platform_artwork
            ? platformData?.platform_artwork
            : [],
          technical_spec: platformData?.technical_spec
            ? platformData?.technical_spec
            : [],
          stream_type: platformData?.stream_type
            ? platformData?.stream_type
            : null,
          epg: platformData?.epg ? platformData?.epg : null,
          epg_format: platformData?.epg_format
            ? platformData?.epg_format
            : null,
          epg_format_example: platformData?.epg_format_example
            ? platformData?.epg_format_example
            : null,
          overall_bitrate: platformData?.overall_bitrate
            ? platformData?.overall_bitrate
            : null,
          subtitle_cc_format: platformData?.subtitle_cc_format
            ? platformData?.subtitle_cc_format
            : null,
          redundancy: platformData?.redundancy
            ? platformData?.redundancy
            : null,
          no_of_player_needed: platformData?.no_of_player_needed
            ? platformData?.no_of_player_needed
            : null,
          ad_trigger_type: platformData?.ad_trigger_type
            ? platformData?.ad_trigger_type
            : null,
          ad_break: platformData?.ad_break ? platformData?.ad_break : null,
          platform_qa_cycle: platformData?.platform_qa_cycle
            ? platformData?.platform_qa_cycle
            : null,
          video_player: platformData?.video_player
            ? platformData?.video_player
            : null,
          // preferred_business_model: platformData?.preferred_business_model
          //   ? platformData?.preferred_business_model
          //   : null,
          estimated_channel_deliveries:
            platformData?.estimated_channel_deliveries
              ? platformData?.estimated_channel_deliveries
              : null,
          is_platform_group: platformData?.is_platform_group
            ? platformData?.is_platform_group
            : null,
          platforms_grouped: platformData?.platforms_grouped
            ? platformData?.platforms_grouped
            : null,
          vod_support: platformData?.vod_support
            ? platformData?.vod_support
            : null,
          vod_delivery_mode: platformData?.vod_delivery_mode
            ? platformData?.vod_delivery_mode
            : null,
          vod_connector_availability: platformData?.vod_connector_availability
            ? platformData?.vod_connector_availability
            : null,
          ssai_by: platformData?.ssai_by ? platformData?.ssai_by : null,
          platform_specific_encoding: platformData?.platform_specific_encoding
            ? platformData?.platform_specific_encoding
            : null,
          inventory_model: platformData?.inventory_model
            ? platformData?.inventory_model
            : null,
          analytics_share_rule: platformData?.analytics_share_rule
            ? platformData?.analytics_share_rule
            : null,
          redundancy_player_count: platformData?.redundancy_player_count
            ? platformData?.redundancy_player_count
            : null,
          redundancy_delivery_count: platformData?.redundancy_delivery_count
            ? platformData?.redundancy_delivery_count
            : null,
          platform_contact: platformData?.platform_contact
            ? platformData?.platform_contact
            : null,
          platform_contacts: platformData?.platform_contacts
            ? platformData?.platform_contacts
            : [],
          platform_manager: platformData?.platform_manager
            ? platformData?.platform_manager
            : null,
          reference_links: platformData?.reference_links
            ? platformData?.reference_links
            : [],
          available_in_amagi_now: platformData?.available_in_amagi_now
            ? platformData?.available_in_amagi_now
            : null,
          specifications_url: platformData?.specifications_url
            ? platformData?.specifications_url
            : null,
        }}
        onSubmit={async ({
          video,
          poster,
          presentation,
          platform_id,
          overall_bitrate,
          account,
          ...values
        }) => {
          try {
            await update_platform({
              variables: {
                id: platformData?.id,
                input: {
                  video: videos,
                  poster: postersList,
                  presentation: presentationsList,
                  overall_bitrate: overall_bitrate
                    ? overall_bitrate.toString().includes("Mbps")
                      ? overall_bitrate
                      : `${overall_bitrate} Mbps`
                    : null,
                  ...values,
                },
              },
            });
            toast.success("Updated successfully");
            close();
          } catch (e) {
            toast.error("There was an error");
            console.log(e);
          }
          console.log(values, overall_bitrate);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form
            style={{ color: "#4E4E4E", fontSize: "14px", fontWeight: "500" }}
          >
            {isModal !== true ? (
              <>
                <div className="sticky top-14 z-20 text-base">
                  <div className="flex justify-between items-center sticky top-14 h-14 w-full bg-secondaryBg">
                    <div className="flex items-center">
                      <span
                        onClick={() => navigate(`${url}`)}
                        className="font-medium text-gray-600 cursor-pointer hover:text-blue-500 hover:underline"
                      >
                        All Platforms
                      </span>
                      <ChevronRightIcon className="h-5 w-5 mx-3 text-gray-400" />
                      <span>{data.platform?.name}</span>
                    </div>
                    <div className="flex justify-end">
                      <button
                        type="submit"
                        className="py-1 px-3 bg-primaryColor text-white rounded-sm"
                      >
                        Submit
                      </button>
                      <button
                        className="py-1 px-3 text-primaryColor"
                        onClick={() => navigate(`${url}/${platformId}`)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mb-4 sticky top-28 z-20">
                  <SubTabs
                    customStyles=""
                    currentTab={platformTab}
                    tabList={[
                      { name: "Business Specs" },
                      { name: "Technical Specs" },
                    ]}
                    toggleTab={setPlatformTab}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="mb-2 sticky bg-secondaryBg top-12 z-20 flex justify-between">
                  <div>
                    <SubTabs
                      customStyles=""
                      currentTab={platformTab}
                      tabList={[
                        { name: "Business Specs" },
                        { name: "Technical Specs" },
                      ]}
                      toggleTab={setPlatformTab}
                    />
                  </div>
                  {CDPRole.includes("channel-edit") && (
                    <div className="">
                      <button
                        type="submit"
                        className="py-1 px-3 bg-primaryColor text-white rounded-sm"
                      >
                        Submit
                      </button>
                      <button
                        className="py-1 px-3 text-primaryColor"
                        onClick={() => close()}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}

            <div className="w-full">
              {platformTab === "Business Specs" && (
                <PlatformBusinessFieldsForm
                  posters={postersList}
                  videos={videos}
                  presentations={presentationsList}
                  amagiIds={amagiIds}
                  platformIds={platformIds}
                  values={values}
                  setFieldValue={setFieldValue}
                  setPosters={setPostersList}
                  setVideos={setVideos}
                  setPresentations={setPresentationsList}
                />
              )}
              {platformTab === "Technical Specs" && (
                <PlatformTechnicalSpecsForm values={values} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PlatformEditForm;
