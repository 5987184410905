import { TrashIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { PlatformContactsType } from "./types";
import { useField } from "formik";

const PlatformContacts: React.FC<{
  contacts: Array<PlatformContactsType>;
}> = ({ contacts }) => {
  const _field = useField("platform_contacts");
  const { setValue } = _field[2];

  const [inputTable, setInputTable] = useState<Array<PlatformContactsType>>([
    ...contacts,
  ]);

  const handleChange = (index: number, value: string, inputKey: string) => {
    let contactsValues: Array<PlatformContactsType> = JSON.parse(
      JSON.stringify(inputTable)
    );
    contactsValues[index][inputKey as keyof PlatformContactsType] = value;
    setInputTable(contactsValues);
  };

  const handleAddClick = () => {
    setInputTable((input) => [
      ...input,
      {
        name: "",
        email: "",
      },
    ]);
  };

  // const onSubmit = async () => {
  //   if (
  //     inputTable.every((input) => input.name !== null && input.email !== null)
  //   ) {
  //     try {
  //       console.log(inputTable);
  //       toast.success("Updated successfully");
  //       close();
  //     } catch (e) {
  //       toast.error(`There was an error ${e}`);
  //       console.log(e);
  //     }
  //     console.log(inputTable);
  //   } else {
  //     toast.error("All fields should be populated");
  //   }
  // };

  useEffect(() => {
    (inputTable.every((tab) => tab.name.length > 0 || tab.email.length > 0) ||
      inputTable.length === 0) &&
      setValue(inputTable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputTable]);

  return (
    <div style={{ padding: "5px 0px" }}>
      <div style={{ padding: "5px 0px", borderRadius: 13 }}>
        <table
          className="roundedCorners"
          style={{ width: "50%", fontSize: "16px" }}
        >
          <thead className="bg-secondaryBg">
            <tr className="text-sm">
              <th className="py-2 pl-2 w-20">S No.</th>
              <th className="text-left py-2 pl-2">Name</th>
              <th className="text-left py-2 pl-2">Email</th>
              <th className="text-left py-2 pl-2 w-10"></th>
            </tr>
          </thead>
          <tbody className="bg-gray-50">
            {inputTable.map((data: PlatformContactsType, index: number) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td className="py-2 pl-2">{index + 1}</td>
                    <td className="text-left py-1 pl-2 text-sm">
                      <input
                        type="text"
                        defaultValue={`${data.name}`}
                        onChange={(e) =>
                          handleChange(index, e.target.value, "name")
                        }
                        className="focus:ring-0 px-0 py-2 border-0 border-b text-sm bg-transparent w-full"
                        placeholder="Enter name"
                      />
                    </td>
                    <td className="text-left py-1 pl-2 text-sm flex items-center">
                      <input
                        type="email"
                        defaultValue={`${data.email}`}
                        onChange={(e) =>
                          handleChange(index, e.target.value, "email")
                        }
                        className="focus:ring-0 px-0 py-2 border-0 border-b text-sm bg-transparent w-full"
                        placeholder="Enter email"
                      />
                    </td>
                    <td className="py-2 pl-2 cursor-pointer text-sm">
                      <TrashIcon
                        onClick={() => {
                          setInputTable(inputTable.filter((f) => f !== data));
                        }}
                        className="h-5 w-5 text-sm"
                      />
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
        <div className="py-1 pl-2 text-sm text-left cursor-pointer text-blue-500">
          <span onClick={handleAddClick}>+ ADD NEW ROW</span>
        </div>
      </div>
    </div>
  );
};

export default PlatformContacts;
