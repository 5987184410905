import { InformationCircleIcon } from "@heroicons/react/outline";
import React, { useMemo, useState } from "react";
import { useCDM } from "../../cdm-context";
import NestedTable from "../../common/nested-table";
import SubTableContainer from "../../common/sub-table";
import { CustomerQuotesDataFragment } from "../../hasura.graphql";
import DelayedToolTip from "../delayed-tooltip";
import { DateParser, DateSort } from "../utils";

const Quotes: React.FC<{
  quotes: CustomerQuotesDataFragment;
  isModal: boolean;
  setTab: (tab: string) => void;
  setSearchParams: (search: string) => void;
}> = ({ quotes, isModal, setTab, setSearchParams }) => {
  const { show } = useCDM();
  const [rowId, setRowId] = useState<string | null>(null);

  const quote_columns: any = useMemo(
    () => [
      {
        Header: "QUOTE NUMBER",
        accessor: "quote_number",
      },
      {
        Header: "QUOTE TYPE",
        accessor: "quote_type",
      },
      {
        Header: "MSA QUOTE TYPE",
        accessor: "msa_quote_type",
      },
      {
        Header: "STATUS",
        accessor: "status",
      },
      {
        Header: "OPPORTUNITY",
        accessor: "opportunity",
        Cell: ({ cell }: any) => (
          <>
            {isModal ? (
              <span
                className="hover:text-blue-500 hover:underline cursor-pointer"
                onClick={() => {
                  setTab("Opportunities");
                  setSearchParams(cell.row.values.opportunity);
                }}
              >
                {cell.row.values.opportunity}
              </span>
            ) : cell.row.values.opportunity !== "--" ? (
              <a
                href={`/customers/${show?.amg_id}/opportunities?search=${cell.row.values.opportunity}`}
              >
                <span>{cell.row.values.opportunity}</span>
              </a>
            ) : (
              <span>{cell.row.values.opportunity}</span>
            )}
          </>
        ),
      },
      {
        Header: "PRIMARY",
        accessor: "primary",
      },
      {
        Header: "START DATE",
        accessor: "start_date",
        sortType: (a: any, b: any) =>
          DateSort(a.values.start_date, b.values.start_date),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.start_date !== "--"
              ? DateParser(cell.row.values.start_date)
              : "--"}
          </span>
        ),
      },
      {
        Header: "END DATE",
        accessor: "end_date",
        sortType: (a: any, b: any) =>
          DateSort(a.values.end_date, b.values.end_date),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.end_date !== "--"
              ? DateParser(cell.row.values.end_date)
              : "--"}
          </span>
        ),
      },
    ],
    [isModal, setSearchParams, setTab, show?.amg_id]
  );

  const quote_line_columns: any = useMemo(
    () => [
      {
        Header: "PRODUCT CODE",
        accessor: "product_code",
      },
      {
        Header: "PRODUCT NAME",
        accessor: "product_name",
        Cell: ({ cell }: any) => (
          <div className="flex items-center">
            <span>
              {cell.row.original.product_short_name
                ? cell.row.original.product_short_name
                : cell.row.original.product_name}
            </span>
            {cell.row.original.product_short_name && (
              <span className="ml-2">
                <DelayedToolTip
                  delay={0}
                  title={cell.row.original.product_name}
                  position="top"
                >
                  <InformationCircleIcon
                    className="w-4 h-4"
                    style={{ color: "#94ACEB" }}
                  />
                </DelayedToolTip>
              </span>
            )}
          </div>
        ),
      },
      {
        Header: "CHANNEL",
        accessor: "channel",
      },
      {
        Header: "PLATFORM",
        accessor: "platform",
      },
      {
        Header: "UNIT NET PRICE",
        accessor: "unit_net_price",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.unit_net_price !== "--"
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency:
                    cell.row.original.currency !== "--"
                      ? cell.row.original.currency
                      : show?.currency,
                }).format(cell.row.values.unit_net_price)
              : "--"}
          </span>
        ),
        textAlign: "text-right",
      },
      {
        Header: "LIST UNIT PRICE",
        accessor: "list_unit_price",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.list_unit_price !== "--"
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency:
                    cell.row.original.currency !== "--"
                      ? cell.row.original.currency
                      : show?.currency,
                }).format(cell.row.values.list_unit_price)
              : "--"}
          </span>
        ),
        textAlign: "text-right",
      },
      {
        Header: "DISCOUNT MODEL",
        accessor: "discount_model_amount",
        Cell: ({ cell }: any) => (
          <div>
            {cell.row.original.discount_model_amount !== "--"
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency:
                    cell.row.original.currency !== "--"
                      ? cell.row.original.currency
                      : show?.currency,
                }).format(cell.row.values.discount_model_amount)
              : cell.row.original.discount_model_percentage !== "--"
              ? `${cell.row.original.discount_model_percentage}%`
              : "--"}
          </div>
        ),
      },
    ],
    [show?.currency]
  );

  const filteredQuotes = quotes.quotes.map((quote) => {
    return {
      quote_number: quote.quote_number ? quote.quote_number : "--",
      quote_type: quote.quote_type ? quote.quote_type : "--",
      msa_quote_type: quote.msa_quote_type ? quote.msa_quote_type : "--",
      status: quote.status ? quote.status : "--",
      opportunity: quote.opportunity?.name ? quote.opportunity?.name : "--",
      primary:
        quote.primary_quote_actions !== undefined
          ? quote.primary_quote_actions
            ? "Yes"
            : "No"
          : "--",
      start_date: quote.start_date ? quote.start_date : "--",
      end_date: quote.end_date ? quote.end_date : "--",
      quote_lines: quote.quote_lines
        ? quote.quote_lines.map((line) => {
            return {
              product_code: line.product_code ? line.product_code : "--",
              product_name: line.product_name ? line.product_name : "--",
              product_short_name: line.product_details?.short_name
                ? line.product_details?.short_name
                : "--",
              currency: line.currency ? line.currency : "--",
              channel: line.channel_details?.name
                ? line.channel_details?.name
                : "--",
              platform: line.platform?.name ? line.platform?.name : "--",
              unit_net_price: line.unit_net_price ? line.unit_net_price : "--",
              list_unit_price: line.list_unit_price
                ? line.list_unit_price
                : "--",
              discount_model_amount: line.additional_discount
                ? line.additional_discount
                : 0,
              discount_model_percentage: line.additional_discount_percentage
                ? line.additional_discount_percentage
                : "--",
            };
          })
        : [],
    };
  });

  const exportData = filteredQuotes.map((quote) => {
    return quote.quote_lines.map((line) => {
      return {
        quote_number: quote.quote_number ? quote.quote_number : "--",
        quote_type: quote.quote_type ? quote.quote_type : "--",
        msa_quote_type: quote.msa_quote_type ? quote.msa_quote_type : "--",
        status: quote.status ? quote.status : "--",
        opportunity: quote.opportunity ? quote.opportunity : "--",
        primary: quote.primary ? quote.primary : "--",
        start_date: quote.start_date ? quote.start_date : "--",
        end_date: quote.end_date ? quote.end_date : "--",
        product_details_code: line.product_code ? line.product_code : "--",
        product_name: line.product_name ? line.product_name : "--",
        channel: line.channel ? line.channel : "--",
        platform: line.platform ? line.platform : "--",
        unit_net_price: line.unit_net_price ? line.unit_net_price : "--",
        list_unit_price: line.list_unit_price ? line.list_unit_price : "--",
        discount_model: line.discount_model_amount
          ? line.discount_model_amount
          : "--",
      };
    });
  });

  const exportHeaders = [
    { label: "Quote Number", key: "quote_number" },
    { label: "Quote Type", key: "quote_type" },
    { label: "MSA Quote Type", key: "msa_quote_type" },
    { label: "Status", key: "status" },
    { label: "Opportunity", key: "opportunity" },
    { label: "Primary", key: "primary" },
    { label: "Start Date", key: "start_date" },
    { label: "End Date", key: "end_date" },
    { label: "Product Code", key: "product_code" },
    { label: "Product Name", key: "product_name" },
    { label: "Channel", key: "channel" },
    { label: "Platform", key: "platform" },
    { label: "Unit Net Price", key: "unit_net_price" },
    { label: "List Unit Price", key: "list_unit_price" },
    { label: "Discount Model", key: "discount_model" },
  ];

  return (
    <div>
      {filteredQuotes.length > 0 && (
        <div className="mt-4">
          <NestedTable
            header="Quotes"
            placeholder="Search for quotes"
            warning="Quotes"
            data={filteredQuotes}
            columns={quote_columns}
            height={`${window.innerHeight - 255}px`}
            exportData={exportData}
            exportHeader={exportHeaders}
            fileName={`${show?.amg_id}_Quotes`}
            open={rowId}
            setOpen={setRowId}
            isToggle={false}
            subTable={
              <>
                {rowId &&
                  filteredQuotes[parseInt(rowId!)].quote_lines.length > 0 && (
                    <SubTableContainer
                      header={`(${
                        filteredQuotes[parseInt(rowId!)].quote_lines.length
                      }) Quote Lines for ${
                        filteredQuotes[parseInt(rowId!)].quote_number
                      }`}
                      placeholder={`Search for line items`}
                      data={
                        rowId
                          ? filteredQuotes[parseInt(rowId!)].quote_lines
                          : []
                      }
                      columns={quote_line_columns}
                    />
                  )}
              </>
            }
          />
        </div>
      )}
    </div>
  );
};

export default Quotes;
