import { useState } from "react";
import ErrorBoundary from "../error-boundary";
import SubTabs from "../sub-tabs";
import LinearChannelsByCustomer from "./linear-channels";
import GroupedChannelsByCustomer from "./grouped-channels";
import CommonChannelsByCustomer from "./common-channels";

const CustomerChannels: React.FC = () => {
  const [channelTab, setChannelTab] = useState("Linear");

  return (
    <ErrorBoundary>
      <div className="my-2">
        <SubTabs
          customStyles=""
          currentTab={channelTab}
          toggleTab={setChannelTab}
          tabList={[
            { name: "Linear" },
            { name: "Grouped" },
            { name: "Redundant" },
            { name: "Subfeed" },
            { name: "Test" },
            { name: "VOD" },
            { name: "Pop-up" },
          ]}
        />
      </div>
      {channelTab === "Linear" && <LinearChannelsByCustomer />}
      {channelTab === "Grouped" && <GroupedChannelsByCustomer />}
      {channelTab === "Redundant" && (
        <CommonChannelsByCustomer channelType="Redundant" />
      )}
      {channelTab === "Subfeed" && (
        <CommonChannelsByCustomer channelType="Subfeed" />
      )}
      {channelTab === "Test" && <CommonChannelsByCustomer channelType="Test" />}
      {channelTab === "VOD" && <CommonChannelsByCustomer channelType="VOD" />}
      {channelTab === "Pop-up" && (
        <CommonChannelsByCustomer channelType="Pop-up" />
      )}
    </ErrorBoundary>
  );
};

export default CustomerChannels;
