import React, { useState } from "react";
import { useGetConsumptionSubscriptionRevenueQuery } from "../../hasura.graphql";
import { DateTime } from "luxon";
import {
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  ResponsiveContainer,
} from "recharts";

const cardContainerStyle = {
  display: "flex",
  gap: "8px",
};

function formatMonth(timestamp: string): string {
  const date = new Date(timestamp);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const month = monthNames[date.getUTCMonth()];
  const year = date.getUTCFullYear().toString().slice(2);

  return `${month}'${year}`;
}

const DQConsumptionSubscription: React.FC = () => {
  const [dateRange, setDateRange] = useState<string>("12");
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [showConsumptionMRR, setShowConsumptionMRR] = useState(true);
  const [showSubscriptionMRR, setShowSubscriptionMRR] = useState(true);

  const { loading, error, data } = useGetConsumptionSubscriptionRevenueQuery({
    variables: {
      from_date: getFromDate(dateRange),
      to_date: getToDate(),
    },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const consumptionMrrData = data?.cdp_consumption_mrr || [];
  const subscriptionMrrData = data?.cdp_subscription_mrr || [];

  // Create a Set to store unique x values
  const uniqueXValues = new Set<string>();

  // Add x values from all datasets to the Set
  consumptionMrrData.forEach((entry) =>
    uniqueXValues.add(formatMonth(entry.billing_month))
  );
  subscriptionMrrData.forEach((entry) =>
    uniqueXValues.add(formatMonth(entry.billing_month))
  );

  const chartData = {
    data: Array.from(uniqueXValues).map((xValue) => ({
      x: xValue,
      consumptionMRR:
        consumptionMrrData.find(
          (entry) => formatMonth(entry.billing_month) === xValue
        )?.amount_usd / 1_000_000 || null,
      subscriptionMRR:
        subscriptionMrrData.find(
          (entry) => formatMonth(entry.billing_month) === xValue
        )?.amount_usd / 1_000_000 || null,
    })),
  };

  const options: React.ComponentProps<typeof AreaChart> = {
    margin: { top: 5, right: 20, left: -20, bottom: 5 },
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-4">
          <div className="text-2xl font-semibold mb-2">
            Consumption vs Subscription revenue
          </div>
        </div>
        <div className="relative">
          <div
            onClick={() => setDropdownOpen(!isDropdownOpen)}
            className="cursor-pointer flex items-center bg-gray-200 rounded-md border border-black p-1 gap-2"
            style={{
              width: "149px",
              height: "32px",
              borderRadius: "4px",
              border: "1px solid #CDD2D5",
              background: "#EDF2F6",
            }}
          >
            <div className="ml-1">{`Last ${dateRange} months`}</div>
            {/* Add your custom calendar icon here */}
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="text-gray-500"
            >
              <path
                d="M21 3H20V1H18V3H6V1H4V3H3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H21C22.1046 21 23 20.1046 23 19V5C23 3.89543 22.1046 3 21 3ZM21 19H3V8H21V19ZM21 6H3V5H21V6Z"
                fill="currentColor"
              />
            </svg>
          </div>
          {isDropdownOpen && (
            <div
              className="absolute top-full left-0 mt-1 bg-white border rounded-md overflow-hidden z-10"
              style={{
                width: "149px",
                borderRadius: "4px",
                border: "1px solid #e0e0e0",
                padding: "6px 12px",
                gap: "2px",
              }}
            >
              <div
                onClick={() => {
                  setDropdownOpen(false);
                  setDateRange("3");
                }}
                className="text-sm text-gray-800 cursor-pointer p-2 hover:bg-gray-100"
              >
                Last 3 months
              </div>
              <div
                onClick={() => {
                  setDropdownOpen(false);
                  setDateRange("6");
                }}
                className="text-sm text-gray-800 cursor-pointer p-2 hover:bg-gray-100"
              >
                Last 6 months
              </div>
              <div
                onClick={() => {
                  setDropdownOpen(false);
                  setDateRange("12");
                }}
                className="text-sm text-gray-800 cursor-pointer p-2 hover:bg-gray-100"
              >
                Last 12 months
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          ...cardContainerStyle,
          justifyContent: "space-between",
          paddingRight: "16px",
          marginBottom: "16px",
        }}
      ></div>

      <div className="bg-white rounded-md pb-6 w-full h-[630px] flex flex-col justify-center p-[32px 16px] gap-[32px] border border-gray-200">
        <div className="flex items-center justify-between mb-4">
          <div className="text-xl font-semibold p-4 mb-4">
            Consumption and Subscription revenue trends
          </div>
          <div className="flex items-center p-4 gap-4">
            <CheckboxToggle
              label="Consumption"
              color="blue"
              checked={showConsumptionMRR}
              onChange={() => setShowConsumptionMRR(!showConsumptionMRR)}
            />
            <CheckboxToggle
              label="Subscription"
              color="green"
              checked={showSubscriptionMRR}
              onChange={() => setShowSubscriptionMRR(!showSubscriptionMRR)}
            />
          </div>
        </div>
        <ResponsiveContainer width="99%" height="100%">
          <AreaChart {...options} data={chartData.data}>
            <XAxis dataKey="x" />
            <YAxis tickFormatter={(value: any) => `${value}M`} />
            <Tooltip
              formatter={(value: any, name: string) => [
                `${value.toFixed(2)}M USD`,
                name,
              ]}
            />
            {showConsumptionMRR && (
              <Area
                type="linear"
                stroke="#7BB2EE"
                fill="#DEE5F2"
                dataKey="consumptionMRR"
                name="Consumption Revenue"
              />
            )}
            {showSubscriptionMRR && (
              <Area
                type="linear"
                dataKey="subscriptionMRR"
                stroke="#00845C"
                fill="rgba(238, 247, 244, 0.8)"
                name="Subscription Revenue"
              />
            )}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );

  function getFromDate(monthRange: string) {
    return (
      DateTime.local()
        .startOf("month")
        .minus({ months: parseInt(monthRange) })
        .toISODate() || ""
    );
  }

  function getToDate() {
    return (
      DateTime.local().minus({ months: 1 }).endOf("month").toISODate() || ""
    );
  }
};

interface CheckboxToggleProps {
  label: string;
  color: string;
  checked: boolean;
  onChange: () => void;
}

const CheckboxToggle: React.FC<CheckboxToggleProps> = ({
  label,
  color,
  checked,
  onChange,
}) => {
  return (
    <label
      className={`inline-flex items-center cursor-pointer text-${color} mr-2`}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className={`form-checkbox h-5 w-5 text-${color}-500`}
      />
      <span className="ml-2">{label}</span>
    </label>
  );
};

export default DQConsumptionSubscription;
