import React, { useState } from "react";
import FormikSelect from "../../formik/select";
import FormikMultiSelect from "../../formik/multi-select";
import {
  channelDeliveryFields,
  channelFields,
  platformFields,
} from "../../../constants";
// import FormikInputDateTime from "../../formik/input-datetime";
import { DeliveryEditType, ProductListType } from "./types";
// import SearchSelect from "../../formik/search-select";
import FormikDeliveryRegions from "../../formik/delivery-regions";
import FormikInputDate from "../../formik/input-date";
import FormikDeliveryProducts from "../../formik/delivery-products";
import ToggleBox from "../../toggle-box";
import FormikTextField from "../../formik/text-field";
import { useChannelNames } from "../../../all-channels-list";

export type ReactSelectType = {
  label: string;
  value: string;
};

const PlayoutDeliveryFieldsEditForm: React.FC<{
  values: DeliveryEditType;
  channelIds: Array<ReactSelectType>;
  platformIds: Array<ReactSelectType>;
  productLists: Array<ProductListType>;
}> = ({ values, channelIds, platformIds, productLists }) => {
  const [hideTabs, setHideTabs] = useState<Array<string>>([]);

  const handleHideTabs = (tab: string) => {
    setHideTabs((hideTabs) => [...hideTabs, tab]);
  };
  const handleUnhideTabs = (tab: string) => {
    setHideTabs(hideTabs.filter((f: string) => f !== tab));
  };
  const { channelNames } = useChannelNames();
  console.log(channelNames);

  return (
    <div>
      <ToggleBox
        columns={hideTabs}
        handleHideColumns={handleHideTabs}
        handleUnhideColumns={handleUnhideTabs}
        header="Playout Details"
      >
        <>
          <div className="mb-8 grid grid-cols-2 gap-x-8">
            <div>
              <FormikSelect
                inlinelabel="Test Playout"
                name="test_playout"
                defaultValue={values.test_playout}
                defFields={["Yes", "No"]}
              />
            </div>
          </div>
          <div className="mb-8">
            <FormikDeliveryRegions
              availRegion={
                values.delivered_region.length > 0
                  ? values.delivered_region
                  : [
                      {
                        region: "",
                        countries: [],
                        countries_exception: [],
                      },
                    ]
              }
              fieldName="delivered_region"
            />
          </div>
          <div className="grid grid-cols-3 gap-y-8 items-start gap-x-36 my-8">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Channel / Feed Type"
                name="feed_type"
                defaultValue={values.feed_type}
                defFields={channelDeliveryFields.feedType.sort()}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikMultiSelect
                inlinelabel="Delivery Stream Type"
                name="delivery_stream_type"
                defaultValue={values.delivery_stream_type}
                defFields={platformFields.stream_type.sort()}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikMultiSelect
                inlinelabel="Audio Languages"
                name="audio_languages"
                defaultValue={values.audio_languages}
                defFields={channelFields.languages.sort()}
              />
            </div>
          </div>
          <div className="text-base font-semibold mb-2">Delivery Timelines</div>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikInputDate
                name="ordered_date"
                defaultValue={values.ordered_date}
                inlinelabel="Ordered Date"
                minDate=""
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikInputDate
                name="provisioning_start_date"
                defaultValue={values.provisioning_start_date}
                inlinelabel="Provisioning Start Date"
                minDate={values.ordered_date || ""}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikInputDate
                name="delivered_date"
                defaultValue={values.delivered_date}
                inlinelabel="Delivered Date"
                minDate={values.provisioning_start_date || ""}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikInputDate
                name="on_hold_date"
                defaultValue={values.on_hold_date}
                inlinelabel="On Hold Date"
                minDate={values.delivered_date || ""}
              />
            </div>
            {values.on_hold_date && (
              <div>
                <FormikTextField
                  name="on_hold_reason"
                  defaultValue={values.on_hold_reason}
                  inlinelabel="On Hold Reason"
                />
              </div>
            )}
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikInputDate
                name="go_live_date"
                defaultValue={values.go_live_date}
                inlinelabel="Go Live Date"
                minDate={values.delivered_date || ""}
              />
            </div>
          </div>
          <div className="my-8">
            <FormikDeliveryProducts
              fieldName="product_purchased_for_this_delivery"
              products={values.product_purchased_for_this_delivery}
              productsLists={productLists}
            />
          </div>
        </>
      </ToggleBox>
    </div>
  );
};

export default PlayoutDeliveryFieldsEditForm;
