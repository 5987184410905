import React from "react";
import { useField } from "formik";

const FormikRange: React.FC<any> = ({
  inlinelabel,
  defaultValue,
  min,
  max,
  subText,
  placeholder = "Enter Text",
  ...props
}) => {
  const _field = useField(props);
  const field = _field[0];
  const { setValue } = _field[2];
  return (
    <div style={{ padding: "5px 0px" }}>
      <label className="text-gray-400">{inlinelabel || ""}</label>
      <div className="flex items-end">
        <input
          type="number"
          min={min}
          max={max}
          value={defaultValue}
          disabled={props.disabled ? true : false}
          name={field.name}
          onChange={(e) => setValue(parseInt(e.target.value))}
          placeholder={placeholder}
          className="py-1 px-0 border-0 text-left border-b focus:ring-0 border-gray-300 outline-none bg-transparent w-full placeholder:text-gray-300"
          style={{ fontSize: 14 }}
        />
        <span className="ml-3" style={{ marginBottom: 7 }}>
          {subText}
        </span>
      </div>
    </div>
  );
};

export default FormikRange;
