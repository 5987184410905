import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import Loader from "../../common/loader";
import {
  CustomerOverviewFinancialDataFragment,
  useCustomerOverviewFinancialQuery,
} from "../../hasura.graphql";
import CommonChart from "../charts/chart";
import { months, DateSort } from "../utils";

const OverviewFinancial: React.FC<{ amagi_id: string }> = ({ amagi_id }) => {
  const [fromDate, setFromDate] = useState<string>("6");
  const [overviewFinancial, setOverviewFinancial] =
    useState<CustomerOverviewFinancialDataFragment>();

  const booked = useMemo(
    () =>
      overviewFinancial?.booked_revenue
        ? overviewFinancial?.booked_revenue
        : [],
    [overviewFinancial?.booked_revenue]
  );
  const billed = useMemo(
    () =>
      overviewFinancial?.billed_revenue
        ? overviewFinancial?.billed_revenue
        : [],
    [overviewFinancial?.billed_revenue]
  );
  const paid = useMemo(
    () =>
      overviewFinancial?.actual_revenue
        ? overviewFinancial?.actual_revenue
        : [],
    [overviewFinancial?.actual_revenue]
  );

  const overview_fin_chart = [...booked, ...billed, ...paid];
  const chart_label = Array.from(
    new Set(overview_fin_chart.map((a) => a.month))
  );

  chart_label.sort((a, b) => DateSort(a, b));

  const filtered_chart_label =
    chart_label.length > 12
      ? chart_label.slice(chart_label.length - 12, chart_label.length)
      : chart_label;

  const paid_chart_data = useMemo(() => {
    return filtered_chart_label.map((data: any) => {
      let credit = paid?.find((s: any) => s.month === data);
      if (!credit) {
        return {
          amount: null,
        };
      }
      return {
        amount: credit.amount,
      };
    });
  }, [filtered_chart_label, paid]);

  const booked_chart_data = useMemo(() => {
    return filtered_chart_label.map((data: any) => {
      let order = booked?.find((s: any) => s.month === data);
      if (!order) {
        return {
          amount: null,
        };
      }
      return {
        amount: order.amount,
      };
    });
  }, [filtered_chart_label, booked]);

  const billed_chart_data = useMemo(() => {
    return filtered_chart_label.map((data: any) => {
      let invoice = billed?.find((s: any) => s.month === data);
      if (!invoice) {
        return {
          amount: null,
        };
      }
      return {
        amount: invoice.amount,
      };
    });
  }, [filtered_chart_label, billed]);

  const { data, loading, error } = useCustomerOverviewFinancialQuery({
    variables: {
      amagi_id: amagi_id,
      from_date: DateTime.local()
        .startOf("month")
        .minus({ months: parseInt(fromDate) - 1 })
        .toISO()
        .slice(0, 10),
    },
    onCompleted: (data) => {
      setOverviewFinancial(data.customer!);
    },
  });

  if (loading) return <Loader />;
  if (error) return <i>Error</i>;
  if (!data) return <i>No Customer Financial Data</i>;

  return (
    <div
      className="bg-white rounded-md pb-4 flex flex-col justify-center"
      style={{ border: "1px solid #E0E0E0" }}
    >
      <div className="flex justify-between border-b border-gray-200">
        <div className="text-left pl-4 py-2 font-medium">
          Booked vs Billed vs Paid
        </div>
        <select
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          className="border-0 rounded text-sm text-gray-500 focus:ring-0"
        >
          <option value={3}>Last 3 months</option>
          <option value={6}>Last 6 months</option>
          <option value={12}>Last 12 months</option>
        </select>
      </div>
      <div className="p-2 mt-2">
        <div className="flex justify-end items-center mx-2">
          <div
            style={{ width: 6, height: 6, background: "#5E81C0" }}
            className="rounded-full"
          ></div>
          <div className="mx-2 text-xs text-gray-600">Booked</div>
          <div className="text-sm font-medium">{``}</div>
          <div
            style={{ width: 6, height: 6, background: "#715EC2" }}
            className="rounded-full ml-4"
          ></div>
          <div className="mx-2 text-xs text-gray-600">Billed</div>
          <div className="text-sm font-medium">{``}</div>
          <div
            style={{ width: 6, height: 6, background: "#B82B94" }}
            className="rounded-full ml-4"
          ></div>
          <div className="mx-2 text-xs text-gray-600">Paid</div>
          <div className="text-sm font-medium">{``}</div>
        </div>
        <div
          className="h-60"
          style={{
            height: `${window.innerHeight - 600}px`,
          }}
        >
          <CommonChart
            showLegend={false}
            showDatalabels={true}
            chartData={{
              labels: filtered_chart_label.map(
                (label) =>
                  `${months[parseInt(label.slice(5)) - 1]}'${label.slice(2, 4)}`
              ),
              datasets: [
                {
                  type: "bar" as const,
                  label: "Booked",
                  data: booked_chart_data.map((c) => c.amount),
                  backgroundColor: ["#5E81C0"],
                  borderWidth: 0,
                  maxBarThickness: 40,
                },
                {
                  type: "bar" as const,
                  label: "Billed",
                  data: billed_chart_data.map((c) => c.amount),
                  backgroundColor: ["#715EC2"],
                  borderWidth: 0,
                  maxBarThickness: 40,
                },
                {
                  type: "bar" as const,
                  label: "Paid",
                  data: paid_chart_data.map((c) => c.amount),
                  backgroundColor: ["#B82B94"],
                  borderWidth: 0,
                  maxBarThickness: 40,
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OverviewFinancial;
