import { Popover, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import React, { Fragment } from "react";

const PopoverMenu: React.FC<{
  children: React.ReactNode;
  icon: string;
  header?: string;
  styles?: string;
  position?: string;
  positionStyles?: string;
}> = ({
  children,
  icon,
  header,
  styles = "",
  position = "absolute",
  positionStyles = "left-3",
}) => {
  return (
    <div className={position}>
      <Popover as="div" className={`relative ${styles} items-center`}>
        {({ open }) => (
          <>
            <Popover.Button className="rounded-full flex items-center justify-center text-sm focus:outline-none">
              <img
                style={{ minWidth: "16px" }}
                src={`/assets/${icon}`}
                alt={icon}
              />
            </Popover.Button>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Popover.Panel
                className={`origin-top-right text-left z-50 absolute ${positionStyles} min-w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
              >
                {({ close }) => (
                  <div>
                    {header && (
                      <div
                        className="px-3 py-2 border-b flex justify-between items-center"
                        style={{ background: "#fafafa", fontSize: "14px" }}
                      >
                        <span className="mr-3 font-medium">{header}</span>
                        <span>
                          <XIcon
                            className="h-5 w-5 cursor-pointer"
                            onClick={() => close()}
                          />
                        </span>
                      </div>
                    )}
                    <div className="p-3 pt-0 pb-1 max-h-48 overflow-y-scroll">
                      {children}
                    </div>
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default PopoverMenu;
