import React from "react";
import Loader from "../../common/loader";
import { useCustomerProductRevenueQuery } from "../../hasura.graphql";
import ErrorBoundary from "../error-boundary";
import Products from ".";

const ProductsWrapper: React.FC<{ amagi_id: string }> = ({ amagi_id }) => {
  const { data, loading, error } = useCustomerProductRevenueQuery({
    variables: {
      amagi_id,
    },
  });

  if (loading) return <Loader />;
  if (error) return <i>Error</i>;
  if (!data) return <i>No Product Revenue</i>;

  const productRevenue =
    data.delivery_channel_platform && data.delivery_channel_platform.length > 0
      ? data.delivery_channel_platform
          .map((prod) => {
            return prod.products.map((rev: any) => {
              return {
                channel_name: prod.channel_name,
                channel_logo: prod.channel_logo
                  ? `/${prod.channel_logo}`
                  : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${prod.channel_name}`,
                platform_name: prod.platform_name,
                logo: prod.platform_logo,
                product_name: rev.product_name,
                product_code: rev.product_code,
                product_short_name: rev.short_name,
                charge_type: rev.charge_type,
                amount: rev.amount,
              };
            });
          })
          .flat()
      : [];

  return (
    <ErrorBoundary>
      <Products productRevenue={productRevenue} />
    </ErrorBoundary>
  );
};

export default ProductsWrapper;
