import React from "react";

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export const msaStatusChip = (status: string) => {
  if (status === "Accepted")
    return "border-green-500 bg-green-100 text-green-600";
  else if (status === "Rejected")
    return "border-red-500 bg-red-100 text-red-600";
  else if (status === "Approved")
    return "border-orange-500 bg-orange-100 text-orange-600";
  else if (status === "Approval Required")
    return "border-blue-500 bg-blue-100 text-blue-600";
  else if (status === "Draft")
    return "border-gray-500 bg-gray-100 text-gray-600";
  else if (status === "In Review")
    return "border-yellow-500 bg-yellow-100 text-yellow-600";
};

const MSATabs: React.FC<{
  tabList: any;
  currentTab: string;
  toggleTab: React.Dispatch<React.SetStateAction<string>>;
  customStyles?: string;
}> = ({ tabList, currentTab, toggleTab, customStyles = "fixed" }) => {
  let defValue = tabList.find((tab: any) => tab.quote_number === currentTab);

  return (
    <div>
      {/* Mobile view */}
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          defaultValue={defValue?.name}
        >
          {tabList.map((tab: any) => (
            <option key={tab.quote_number}>{tab.name}</option>
          ))}
        </select>
      </div>

      {/* Desktop view */}
      <div className={`hidden sm:block bg-secondaryBg w-full`}>
        <div className="rounded-t-md">
          <nav
            className="-mb-px flex space-x-8 border-b border-gray-200"
            aria-label="Tabs"
            style={{
              maxWidth: `${window.innerWidth - 100}px`,
              overflowX: "scroll",
            }}
          >
            {tabList.map((tab: any) => (
              <div
                key={tab.quote_number}
                onClick={() => {
                  toggleTab(tab.quote_number);
                }}
                className={classNames(
                  tab.quote_number === currentTab
                    ? "border-orange-500 text-orange-500"
                    : "border-transparent hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap text-black p-2 border-b-2 font-medium text-sm cursor-pointer"
                )}
              >
                <span>{tab.name}</span>
                <span
                  style={{ fontSize: 8 }}
                  className={`ml-1 relative bottom-1 border px-1 rounded-full ${msaStatusChip(
                    tab.status
                  )}`}
                >
                  {tab.status}
                </span>
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default MSATabs;
