import { TrashIcon, XIcon } from "@heroicons/react/outline";
import { useField } from "formik";
import React, { useEffect, useState } from "react";

const FormikCustomFields: React.FC<{
  values: any;
}> = ({ values }) => {
  const _field = useField("custom_fields");
  const field = _field[0];
  const { setValue } = _field[2];
  const [attributeLists, setAttributeLists] = useState<Array<string>>(
    Object.keys(values).map((key) => {
      return key;
    })
  );
  const [customFields, setCustomFields] = useState<any>(
    JSON.parse(JSON.stringify(values))
  );
  const [inputGroup, setInputGroup] = useState<string>("");
  const [inputKey, setInputKey] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedField, setSelectedField] = useState<string>(attributeLists[0]);

  const handleSubmit = () => {
    let input: any =
      customFields[selectedField] !== undefined
        ? customFields[selectedField]
        : {};
    input[inputKey] = inputValue;
    inputKey.length > 0 &&
      inputValue.length > 0 &&
      setCustomFields({ ...customFields, [selectedField]: input });
    setInputKey("");
    setInputValue("");
  };

  const removeCustomAttributeFields = (key: string) => {
    let filteredCustomFields = customFields[selectedField];
    console.log(filteredCustomFields, key);
    delete filteredCustomFields[key];
    console.log(filteredCustomFields, key);
    setCustomFields((prev: any) => {
      delete prev[filteredCustomFields];
      return { ...prev };
    });
  };
  useEffect(() => {
    if (
      customFields[selectedField] !== undefined &&
      Object.keys(customFields[selectedField]).length === 0
    ) {
      delete customFields[selectedField];
    }
    setValue(customFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputKey, inputValue]);

  return (
    <div style={{ padding: "5px 0px" }}>
      <div>
        <label className="text-gray-400">Attribute Group</label>
        <div className="flex">
          <input
            type="text"
            value={inputGroup}
            onChange={(e) => setInputGroup(e.target.value)}
            placeholder="Enter Text"
            className="py-1 px-0 border-0 border-b focus:ring-0 border-gray-300 outline-none bg-transparent"
          />
          <button
            type="button"
            onClick={() => {
              inputGroup.length > 0 &&
                setAttributeLists((oldArr) => [...oldArr, inputGroup]);
              setSelectedField(inputGroup);
              setInputGroup("");
            }}
            className="ml-3 py-px px-2 bg-primaryColor text-white rounded-sm"
          >
            Add Group
          </button>
        </div>
        <div className="flex mt-2">
          {attributeLists.map((data, index) => {
            return (
              <div
                key={index}
                style={{
                  backgroundColor: `${
                    selectedField === data ? "#BDC2CB" : "#fff"
                  }`,
                  border: "1px solid #BDC2CB",
                }}
                className="px-3 flex cursor-pointer rounded-sm text-black font-semibold mr-2"
              >
                <div onClick={() => setSelectedField(data)}>{data}</div>
                {data !== "salesforce" && (
                  <XIcon
                    onClick={() => {
                      setAttributeLists(
                        attributeLists.filter((f) => f !== data)
                      );
                      delete customFields[selectedField];
                      setSelectedField(attributeLists[0]);
                    }}
                    className="h-4 w-4 text-gray-400"
                    style={{ marginTop: "2.2px", marginLeft: "12px" }}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt-8">
        <table className="mt-4" style={{ width: "55%", fontSize: "16px" }}>
          <thead>
            <tr className="bg-gray-300">
              <th className="py-2 pl-2">S No.</th>
              <th className="text-left py-2 pl-2">Key Parameter</th>
              <th className="text-left py-2 pl-2">Value</th>
            </tr>
          </thead>
          <tbody className="bg-gray-50">
            {customFields &&
              customFields[selectedField] !== undefined &&
              Object.entries(customFields[selectedField]).map(
                ([key, value]: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td style={{ fontSize: "16px" }} className="py-2 pl-2">
                        {index + 1}
                      </td>
                      <td
                        style={{ fontSize: "16px" }}
                        className="text-left py-2 pl-2"
                      >
                        {key}
                      </td>
                      <td
                        style={{ fontSize: "16px" }}
                        className="text-left py-2 pl-2"
                      >
                        {value}
                      </td>
                      {selectedField !== "salesforce" && (
                        <td className="bg-white py-2 pl-2 cursor-pointer">
                          <TrashIcon
                            onClick={() => removeCustomAttributeFields(key)}
                            className="h-5 w-5"
                          />
                        </td>
                      )}
                    </tr>
                  );
                }
              )}
            {selectedField !== "salesforce" && (
              <tr>
                <td className="py-1 pl-2" style={{ fontSize: "16px" }}>
                  {customFields[selectedField]
                    ? Object.entries(customFields[selectedField]).length + 1
                    : 1}
                </td>
                <td className="text-left py-1 pl-2">
                  <input
                    type="text"
                    name={field.name}
                    value={inputKey}
                    onChange={(e) => setInputKey(e.target.value)}
                    className="py-0 px-0 border-0 focus:ring-0 outline-none placeholder:text-gray-200 bg-transparent w-full"
                    placeholder="Input key parameter"
                  />
                </td>
                <td className="text-left py-1 pl-2">
                  <input
                    type="text"
                    name={field.name}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    className="py-0 px-0 border-0 focus:ring-0 outline-none placeholder:text-gray-200 bg-transparent w-full"
                    placeholder="Input value"
                  />
                </td>
                {inputKey && inputValue && (
                  <td className="py-1 pl-2 bg-white text-left cursor-pointer text-blue-500">
                    <span onClick={handleSubmit}>+ ADD</span>
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FormikCustomFields;
