import { InformationCircleIcon } from "@heroicons/react/outline";
import React, { useState, useMemo } from "react";
import { useCDM } from "../../cdm-context";
import Loader from "../../common/loader";
import NestedTable from "../../common/nested-table";
import SubTableContainer from "../../common/sub-table";
import { OrderDataFragment, useOrderCountQuery } from "../../hasura.graphql";
import SingleChart from "../charts/single-chart";
import DelayedToolTip from "../delayed-tooltip";
import { contentWidth, DateParser, DateSort } from "../utils";
import { OrdersType } from "./types";
import TableRowsFilter from "../../common/filters/table-rows-filter";

const Orders: React.FC<{
  orders: Array<OrderDataFragment>;
  isModal: boolean;
  setTab: (tab: string) => void;
  setSearchParams: (search: string) => void;
}> = ({ orders, isModal, setTab, setSearchParams }) => {
  const { show } = useCDM();
  const [rowId, setRowId] = useState<string | null>(null);

  const order_columns: any = useMemo(
    () => [
      {
        Header: "ORDER ID",
        accessor: "order_number",
      },
      {
        Header: "STATUS",
        accessor: "status",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "TOTAL AMOUNT",
        accessor: "amount",
        Cell: ({ cell }: any) => (
          <span>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency:
                (cell.row.original.currency !== "-"
                  ? cell.row.original.currency
                  : show?.currency) || "USD",
            }).format(cell.row.values.amount)}
          </span>
        ),
        textAlign: "text-right",
      },
      {
        Header: "CURRENCY",
        accessor: "currency",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "OPPORTUNITY NAME",
        accessor: "opportunity_name",
        Cell: ({ cell }: any) => (
          <>
            {isModal ? (
              <span
                className="hover:text-blue-500 hover:underline cursor-pointer"
                onClick={() => {
                  setTab("Opportunities");
                  setSearchParams(cell.row.values.opportunity_name);
                }}
              >
                {cell.row.values.opportunity_name}
              </span>
            ) : cell.row.values.opportunity_name !== "--" ? (
              <a
                href={`/customers/${show?.amg_id}/opportunities?search=${cell.row.values.opportunity_name}`}
              >
                <span>{cell.row.values.opportunity_name}</span>
              </a>
            ) : (
              <span>{cell.row.values.opportunity_name}</span>
            )}
          </>
        ),
      },
    ],
    [isModal, setSearchParams, setTab, show?.amg_id, show?.currency]
  );

  const order_products_columns: any = useMemo(
    () => [
      {
        Header: "ORDER PRODUCT ID",
        accessor: "order_product_number",
      },
      {
        Header: "PRODUCT CODE",
        accessor: "product_code",
        Filter: TableRowsFilter,
        position: "relative",
        filter: "multiSelect",
      },
      {
        Header: "PRODUCT NAME",
        accessor: "product_short_name",
        Cell: ({ cell }: any) => (
          <div className="flex items-center">
            <span>
              {cell.row.original.product_short_name
                ? cell.row.original.product_short_name
                : cell.row.original.product_name}
            </span>
            {cell.row.original.product_short_name && (
              <span className="ml-2">
                <DelayedToolTip
                  delay={0}
                  title={cell.row.original.product_name}
                  position="top"
                >
                  <InformationCircleIcon
                    className="w-4 h-4"
                    style={{ color: "#94ACEB" }}
                  />
                </DelayedToolTip>
              </span>
            )}
          </div>
        ),
        Filter: TableRowsFilter,
        position: "relative",
        filter: "multiSelect",
      },
      {
        Header: "CHANNEL",
        accessor: "channel_name",
        Filter: TableRowsFilter,
        position: "relative",
        filter: "multiSelect",
      },
      {
        Header: "PLATFORM",
        accessor: "platform_name",
        Filter: TableRowsFilter,
        position: "relative",
        filter: "multiSelect",
      },
      {
        Header: "STATUS",
        accessor: "order_product_line_status",
        Filter: TableRowsFilter,
        position: "relative",
        filter: "multiSelect",
      },
      {
        Header: "TOTAL AMOUNT",
        accessor: "total_price",
        Cell: ({ cell }: any) => (
          <span>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency:
                (cell.row.original.currency
                  ? cell.row.original.currency
                  : show?.currency) || "USD",
            }).format(cell.row.values.total_price)}
          </span>
        ),
        textAlign: "text-right",
      },
      {
        Header: "BILLABLE UNIT PRICE",
        accessor: "billable_unit_price",
        Cell: ({ cell }: any) => (
          <span>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency:
                (cell.row.original.currency
                  ? cell.row.original.currency
                  : show?.currency) || "USD",
            }).format(cell.row.values.billable_unit_price)}
          </span>
        ),
        textAlign: "text-right",
      },
      {
        Header: "LIST PRICE",
        accessor: "list_price",
        Cell: ({ cell }: any) => (
          <span>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency:
                (cell.row.original.currency
                  ? cell.row.original.currency
                  : show?.currency) || "USD",
            }).format(cell.row.values.list_price)}
          </span>
        ),
        textAlign: "text-right",
      },
      {
        Header: "UNIT PRICE",
        accessor: "unit_price",
        Cell: ({ cell }: any) => (
          <span>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency:
                (cell.row.original.currency
                  ? cell.row.original.currency
                  : show?.currency) || "USD",
            }).format(cell.row.values.unit_price)}
          </span>
        ),
        textAlign: "text-right",
      },
      {
        Header: "TAX AMOUNT",
        accessor: "tax_amount",
        Cell: ({ cell }: any) => (
          <span>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency:
                (cell.row.original.currency
                  ? cell.row.original.currency
                  : show?.currency) || "USD",
            }).format(cell.row.values.tax_amount)}
          </span>
        ),
        textAlign: "text-right",
      },
    ],
    [show?.currency]
  );

  const order_items_columns: any = useMemo(
    () => [
      {
        Header: "ORDER ITEM ID",
        accessor: "order_item_id",
      },
      {
        Header: "ORDER ITEM NAME",
        accessor: "order_item_name",
      },
      {
        Header: "TECH LEAD",
        accessor: "tech_lead",
      },
      {
        Header: "ASSIGNEE",
        accessor: "assignee",
      },
      {
        Header: "ORDER TYPE",
        accessor: "order_type",
      },
      {
        Header: "ON HOLD REASON",
        accessor: "on_hold_reason",
      },
      {
        Header: "STAGE",
        accessor: "stage",
      },
      {
        Header: "OIT CREATED",
        accessor: "oit_created",
      },
      {
        Header: "OIT TICKET STATUS",
        accessor: "oit_ticket_status",
      },
      {
        Header: "REVISED EXPECTED DELIVERY DATE",
        accessor: "revised_expected_delivery_date",
        sortType: (a: any, b: any) =>
          DateSort(
            a.values.revised_expected_delivery_date,
            b.values.revised_expected_delivery_date
          ),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.revised_expected_delivery_date !== "-"
              ? DateParser(cell.row.values.revised_expected_delivery_date)
              : "--"}
          </span>
        ),
      },
      {
        Header: "REVISED EXPECTED GO LIVE DATE",
        accessor: "revised_expected_go_live_date",
        sortType: (a: any, b: any) =>
          DateSort(
            a.values.revised_expected_go_live_date,
            b.values.revised_expected_go_live_date
          ),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.revised_expected_go_live_date !== "-"
              ? DateParser(cell.row.values.revised_expected_go_live_date)
              : "--"}
          </span>
        ),
      },
      {
        Header: "ACTUAL DELIVERY DATE",
        accessor: "actual_delivery_date",
        sortType: (a: any, b: any) =>
          DateSort(
            a.values.actual_delivery_date,
            b.values.actual_delivery_date
          ),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.actual_delivery_date !== "-"
              ? DateParser(cell.row.values.actual_delivery_date)
              : "--"}
          </span>
        ),
      },
      {
        Header: "ACTUAL GO LIVE DATE",
        accessor: "actual_go_live_date",
        sortType: (a: any, b: any) =>
          DateSort(a.values.actual_go_live_date, b.values.actual_go_live_date),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.actual_go_live_date !== "-"
              ? DateParser(cell.row.values.actual_go_live_date)
              : "--"}
          </span>
        ),
      },
      {
        Header: "APPROVED FOR ONBOARDING DATE",
        accessor: "approved_for_onboarding_date",
        sortType: (a: any, b: any) =>
          DateSort(
            a.values.approved_for_onboarding_date,
            b.values.approved_for_onboarding_date
          ),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.approved_for_onboarding_date !== "-"
              ? DateParser(cell.row.values.approved_for_onboarding_date)
              : "--"}
          </span>
        ),
      },
    ],
    []
  );

  const order_item_lines_columns: any = useMemo(
    () => [
      {
        Header: "ORDER ITEM LINE ID",
        accessor: "order_item_line_id",
      },
      {
        Header: "ORDER ITEM LINE NAME",
        accessor: "order_item_line_name",
      },
      {
        Header: "ORDER PRODUCT NO",
        accessor: "order_product_number",
      },
      {
        Header: "ASSIGNEE",
        accessor: "assignee",
      },
      {
        Header: "STAGE",
        accessor: "stage",
      },
      {
        Header: "BILLING START DATE RULE",
        accessor: "billing_start_date_rule",
      },
      {
        Header: "LINE INVOICED OR NOT",
        accessor: "line_invoiced_or_not",
      },
      {
        Header: "REVISED EXPECTED DELIVERY DATE",
        accessor: "revised_expected_delivery_date",
        sortType: (a: any, b: any) =>
          DateSort(
            a.values.revised_expected_delivery_date,
            b.values.revised_expected_delivery_date
          ),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.revised_expected_delivery_date !== "-"
              ? DateParser(cell.row.values.revised_expected_delivery_date)
              : "--"}
          </span>
        ),
      },
      {
        Header: "REVISED EXPECTED GO LIVE DATE",
        accessor: "revised_expected_go_live_date",
        sortType: (a: any, b: any) =>
          DateSort(
            a.values.revised_expected_go_live_date,
            b.values.revised_expected_go_live_date
          ),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.revised_expected_go_live_date !== "-"
              ? DateParser(cell.row.values.revised_expected_go_live_date)
              : "--"}
          </span>
        ),
      },
      {
        Header: "ACTUAL DELIVERY DATE",
        accessor: "actual_delivery_date",
        sortType: (a: any, b: any) =>
          DateSort(
            a.values.actual_delivery_date,
            b.values.actual_delivery_date
          ),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.actual_delivery_date !== "-"
              ? DateParser(cell.row.values.actual_delivery_date)
              : "--"}
          </span>
        ),
      },
      {
        Header: "ACTUAL GO LIVE DATE",
        accessor: "actual_go_live_date",
        sortType: (a: any, b: any) =>
          DateSort(a.values.actual_go_live_date, b.values.actual_go_live_date),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.actual_go_live_date !== "-"
              ? DateParser(cell.row.values.actual_go_live_date)
              : "--"}
          </span>
        ),
      },
    ],
    []
  );

  const { loading, data, error } = useOrderCountQuery({
    variables: {
      amagi_id: show!.amg_id,
    },
    skip: !show?.amg_id,
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <i>Error</i>;
  }

  if (!data) {
    return <i>Orders data not available</i>;
  }

  const chart_data: any = [
    {
      label: `Draft (${data.draft?.aggregate?.count})`,
      amount: data.draft?.aggregate?.sum?.amount,
    },
    {
      label: `In Progress (${data.in_progress?.aggregate?.count})`,
      amount: data.in_progress?.aggregate?.sum?.amount,
    },
    {
      label: `On Hold (${data.on_hold?.aggregate?.count})`,
      amount: data.on_hold?.aggregate?.sum?.amount,
    },
    {
      label: `Activated (${data.activated?.aggregate?.count})`,
      amount: data.activated?.aggregate?.sum?.amount,
    },
    {
      label: `Total (${data.total?.aggregate?.count})`,
      amount: data.total?.aggregate?.sum?.amount,
    },
  ];

  let chart_values: number[] = chart_data.map((num: any) => {
    return num.amount !== null ? num.amount : 0;
  });

  const filteredOrders: Array<OrdersType> = orders.map((ord) => {
    return {
      opportunity_id: ord.opportunity?.id ? ord.opportunity?.id : "-",
      opportunity_name: ord.opportunity?.name ? ord.opportunity?.name : "-",
      order_number: ord.order_number ? ord.order_number : "-",
      status: ord.status ? ord.status : "-",
      currency: ord.currency ? ord.currency : "-",
      amount: ord.amount ? ord.amount : 0,
      order_products:
        ord.order_products && ord.order_products.length > 0
          ? ord.order_products.map((ordPrd) => {
              return {
                order_product_number: ordPrd.order_product_number
                  ? ordPrd.order_product_number
                  : "-",
                currency: ord.currency,
                order_product_line_status: ordPrd.order_product_line_status
                  ? ordPrd.order_product_line_status
                  : "-",
                channel_name: ordPrd.channel?.name ? ordPrd.channel?.name : "-",
                platform_name: ordPrd.platform?.name
                  ? ordPrd.platform?.name
                  : "-",
                product_code: ordPrd.product?.code ? ordPrd.product?.code : "-",
                product_name: ordPrd.product?.name ? ordPrd.product?.name : "-",
                product_short_name: ordPrd.product?.short_name
                  ? ordPrd.product?.short_name
                  : "-",
                billable_unit_price: ordPrd.fsys_unit_net_price
                  ? ordPrd.fsys_unit_net_price
                  : 0,
                list_price: ordPrd.list_price ? ordPrd.list_price : 0,
                unit_price: ordPrd.unit_price ? ordPrd.unit_price : 0,
                tax_amount: ordPrd.tax_amount ? ordPrd.tax_amount : 0,
                total_price: ordPrd.total_price ? ordPrd.total_price : 0,
                service_date: ordPrd.service_date,
                end_date: ordPrd.end_date,
              };
            })
          : [],
      order_items:
        ord.order_items_by_salesforce_order_id &&
        ord.order_items_by_salesforce_order_id.length > 0
          ? ord.order_items_by_salesforce_order_id.map((ordIt) => {
              return {
                order_item_id: ordIt.order_item_id ? ordIt.order_item_id : "-",
                order_item_name: ordIt.order_item_name
                  ? ordIt.order_item_name
                  : "-",
                tech_lead: ordIt.tech_lead ? ordIt.tech_lead : "-",
                assignee: ordIt.owner
                  ? `${ordIt.owner.first_name ? ordIt.owner.first_name : ""} ${
                      ordIt.owner.last_name ? ordIt.owner.last_name : ""
                    }`
                  : "-",
                order_type: ordIt.order_type ? ordIt.order_type : "-",
                on_hold_reason: ordIt.on_hold_reason
                  ? ordIt.on_hold_reason
                  : "-",
                stage: ordIt.stage ? ordIt.stage : "-",
                oit_created: ordIt.oit_created ? ordIt.oit_created : "-",
                oit_ticket_status: ordIt.oit_ticket_status
                  ? ordIt.oit_ticket_status
                  : "-",
                revised_expected_go_live_date:
                  ordIt.revised_expected_go_live_date
                    ? ordIt.revised_expected_go_live_date
                    : "-",
                revised_expected_delivery_date:
                  ordIt.revised_expected_delivery_date
                    ? ordIt.revised_expected_delivery_date
                    : "-",
                actual_go_live_date: ordIt.actual_go_live_date
                  ? ordIt.actual_go_live_date
                  : "-",
                actual_delivery_date: ordIt.actual_delivery_date
                  ? ordIt.actual_delivery_date
                  : "-",
                approved_for_onboarding_date: ordIt.approved_for_onboarding_date
                  ? ordIt.approved_for_onboarding_date
                  : "-",
              };
            })
          : [],
      order_item_lines:
        ord.order_items_by_salesforce_order_id &&
        ord.order_items_by_salesforce_order_id.length > 0
          ? ord.order_items_by_salesforce_order_id[0].order_item_lines.map(
              (ordItL) => {
                return {
                  order_item_line_id: ordItL.order_item_line_id
                    ? ordItL.order_item_line_id
                    : "-",
                  order_item_line_name: ordItL.order_item_line_name
                    ? ordItL.order_item_line_name
                    : "-",
                  order_product_number: ordItL.order_product_number
                    ? ordItL.order_product_number
                    : "-",
                  assignee: ordItL.owner
                    ? `${
                        ordItL.owner.first_name ? ordItL.owner.first_name : ""
                      } ${ordItL.owner.last_name ? ordItL.owner.last_name : ""}`
                    : "-",
                  stage: ordItL.stage ? ordItL.stage : "-",
                  billing_start_date_rule: ordItL.billing_start_date_rule
                    ? ordItL.billing_start_date_rule
                    : "-",
                  line_invoiced_or_not: ordItL.line_invoiced_or_not
                    ? ordItL.line_invoiced_or_not
                    : "-",
                  actual_go_live_date: ordItL.actual_go_live_date
                    ? ordItL.actual_go_live_date
                    : "-",
                  actual_delivery_date: ordItL.actual_delivery_date
                    ? ordItL.actual_delivery_date
                    : "-",
                  revised_expected_go_live_date:
                    ordItL.revised_expected_go_live_date
                      ? ordItL.revised_expected_go_live_date
                      : "-",
                  revised_expected_delivery_date:
                    ordItL.revised_expected_delivery_date
                      ? ordItL.revised_expected_delivery_date
                      : "-",
                };
              }
            )
          : [],
    };
  });

  filteredOrders.sort((a, b) => {
    if (parseInt(a.order_number) > parseInt(b.order_number)) return -1;
    else return 1;
  });

  const exportOrders = filteredOrders.map((ord) => {
    return ord.order_products.map((ordPrd, index) => {
      return {
        opportunity_id: ord.opportunity_id,
        opportunity_name: ord.opportunity_name,
        order_number: `'${ord.order_number}'`,
        status: ord.status,
        currency: ord.currency,
        amount: new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: (ord.currency ? ord.currency : show?.currency) || "USD",
        }).format(ord.amount),
        order_product_number: `'${ordPrd.order_product_number}'`,
        order_product_line_status: ordPrd.order_product_line_status,
        channel_name: ordPrd.channel_name,
        platform_name: ordPrd.platform_name,
        product_code: ordPrd.product_code,
        product_name: ordPrd.product_name,
        billable_unit_price: new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: (ord.currency ? ord.currency : show?.currency) || "USD",
        }).format(ordPrd.billable_unit_price),
        list_price: new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: (ord.currency ? ord.currency : show?.currency) || "USD",
        }).format(ordPrd.list_price),
        unit_price: new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: (ord.currency ? ord.currency : show?.currency) || "USD",
        }).format(ordPrd.unit_price),
        tax_amount: new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: (ord.currency ? ord.currency : show?.currency) || "USD",
        }).format(ordPrd.tax_amount),
        total_price: new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: (ord.currency ? ord.currency : show?.currency) || "USD",
        }).format(ordPrd.total_price),
        service_date: ordPrd.service_date,
        end_date: ordPrd.end_date,
        order_item_id:
          ord.order_items.length > 0
            ? `'${ord.order_items[0].order_item_id}'`
            : "-",
        order_item_name:
          ord.order_items.length > 0 ? ord.order_items[0].order_item_name : "-",
        tech_lead:
          ord.order_items.length > 0 ? ord.order_items[0].tech_lead : "-",
        assignee:
          ord.order_items.length > 0 ? ord.order_items[0].assignee : "-",
        order_type:
          ord.order_items.length > 0 ? ord.order_items[0].order_type : "-",
        on_hold_reason:
          ord.order_items.length > 0 ? ord.order_items[0].on_hold_reason : "-",
        stage: ord.order_items.length > 0 ? ord.order_items[0].stage : "-",
        oit_created:
          ord.order_items.length > 0 ? ord.order_items[0].oit_created : "-",
        oit_ticket_status:
          ord.order_items.length > 0
            ? ord.order_items[0].oit_ticket_status
            : "-",
        revised_expected_go_live_date:
          ord.order_items.length > 0
            ? ord.order_items[0].revised_expected_go_live_date
            : "-",
        revised_expected_delivery_date:
          ord.order_items.length > 0
            ? ord.order_items[0].revised_expected_delivery_date
            : "-",
        actual_go_live_date:
          ord.order_items.length > 0
            ? ord.order_items[0].actual_go_live_date
            : "-",
        actual_delivery_date:
          ord.order_items.length > 0
            ? ord.order_items[0].actual_delivery_date
            : "-",
        approved_for_onboarding_date:
          ord.order_items.length > 0
            ? ord.order_items[0].approved_for_onboarding_date
            : "-",
        order_item_line_id:
          ord.order_item_lines && ord.order_item_lines.length > 0
            ? `'${
                ord.order_item_lines[
                  index < ord.order_item_lines.length
                    ? index
                    : ord.order_item_lines.length - 1
                ].order_item_line_id
              }'`
            : "-",
        order_item_line_name:
          ord.order_item_lines.length > 0
            ? ord.order_item_lines[
                index < ord.order_item_lines.length
                  ? index
                  : ord.order_item_lines.length - 1
              ].order_item_line_name
            : "-",
        item_assignee:
          ord.order_item_lines.length > 0
            ? ord.order_item_lines[
                index < ord.order_item_lines.length
                  ? index
                  : ord.order_item_lines.length - 1
              ].assignee
            : "-",
        item_stage:
          ord.order_item_lines.length > 0
            ? ord.order_item_lines[
                index < ord.order_item_lines.length
                  ? index
                  : ord.order_item_lines.length - 1
              ].stage
            : "-",
        billing_start_date_rule:
          ord.order_item_lines.length > 0
            ? ord.order_item_lines[
                index < ord.order_item_lines.length
                  ? index
                  : ord.order_item_lines.length - 1
              ].billing_start_date_rule
            : "-",
        line_invoiced_or_not:
          ord.order_item_lines.length > 0
            ? ord.order_item_lines[
                index < ord.order_item_lines.length
                  ? index
                  : ord.order_item_lines.length - 1
              ].line_invoiced_or_not
            : "-",
        item_actual_go_live_date:
          ord.order_item_lines.length > 0
            ? ord.order_item_lines[
                index < ord.order_item_lines.length
                  ? index
                  : ord.order_item_lines.length - 1
              ].actual_go_live_date
            : "-",
        item_actual_delivery_date:
          ord.order_item_lines.length > 0
            ? ord.order_item_lines[
                index < ord.order_item_lines.length
                  ? index
                  : ord.order_item_lines.length - 1
              ].actual_delivery_date
            : "-",
        item_revised_expected_go_live_date:
          ord.order_item_lines.length > 0
            ? ord.order_item_lines[
                index < ord.order_item_lines.length
                  ? index
                  : ord.order_item_lines.length - 1
              ].revised_expected_go_live_date
            : "-",
        item_revised_expected_delivery_date:
          ord.order_item_lines.length > 0
            ? ord.order_item_lines[
                index < ord.order_item_lines.length
                  ? index
                  : ord.order_item_lines.length - 1
              ].revised_expected_delivery_date
            : "-",
      };
    });
  });

  const exportHeaders = [
    { label: "Opportunity ID", key: "opportunity_id" },
    { label: "Opportunity Name", key: "opportunity_name" },
    { label: "Order Number", key: "order_number" },
    { label: "Status", key: "status" },
    { label: "Currency", key: "currency" },
    { label: "Amount", key: "amount" },
    { label: "Order Product Number", key: "order_product_number" },
    { label: "Order Product Line Status", key: "order_product_line_status" },
    { label: "Channel Name", key: "channel_name" },
    { label: "Platform Name", key: "platform_name" },
    { label: "Product Code", key: "product_code" },
    { label: "Product Name", key: "product_name" },
    { label: "Billable Unit Price", key: "billable_unit_price" },
    { label: "List Price", key: "list_price" },
    { label: "Unit Price", key: "unit_price" },
    { label: "Tax Amount", key: "tax_amount" },
    { label: "Total Price", key: "total_price" },
    { label: "Service Date", key: "service_date" },
    { label: "End Date", key: "end_date" },
    { label: "Order Item ID", key: "order_item_id" },
    { label: "Order Item Name", key: "order_item_name" },
    { label: "Tech Lead", key: "tech_lead" },
    { label: "Assignee", key: "assignee" },
    { label: "Order Type", key: "order_type" },
    { label: "On Hold Reason", key: "on_hold_reason" },
    { label: "Stage", key: "stage" },
    { label: "OIT Created", key: "oit_created" },
    { label: "OIT Ticket Status", key: "oit_ticket_status" },
    {
      label: "Revised Expected Go Live Date",
      key: "revised_expected_go_live_date",
    },
    {
      label: "Revised Expected Delivery Date",
      key: "revised_expected_delivery_date",
    },
    { label: "Actual Go Live Date", key: "actual_go_live_date" },
    { label: "Actual Delivery Date", key: "actual_delivery_date" },
    {
      label: "Approved For Onboarding Date",
      key: "approved_for_onboarding_date",
    },
    { label: "Order Item Line ID", key: "order_item_line_id" },
    { label: "Order Item Line Name", key: "order_item_line_name" },
    { label: "Item Line Assignee", key: "item_assignee" },
    { label: "Item Line Stage", key: "item_stage" },
    { label: "Billing Start Date Rule", key: "billing_start_date_rule" },
    { label: "Line Invoiced or Not", key: "line_invoiced_or_not" },
    { label: "Line Actual Go Live Date", key: "item_actual_go_live_date" },
    { label: "Line Actual Delivery Date", key: "item_actual_delivery_date" },
    {
      label: "Line Revised Expected Go Live Date",
      key: "item_revised_expected_go_live_date",
    },
    {
      label: "Line Revised Expected Delivery Date",
      key: "item_revised_expected_delivery_date",
    },
  ];

  return (
    <>
      {filteredOrders.length > 0 ? (
        <div className="">
          <NestedTable
            data={filteredOrders}
            columns={order_columns}
            open={rowId}
            setOpen={setRowId}
            height={`${window.innerHeight - 260}px`}
            warning="Orders"
            header="Order Details"
            exportData={exportOrders}
            exportHeader={exportHeaders}
            fileName={`${show?.amg_id}_Orders`}
            isToggle={true}
            subTable={
              <>
                {rowId &&
                  filteredOrders[parseInt(rowId)].order_products.length > 0 && (
                    <div className="">
                      <SubTableContainer
                        header={`Order Products of Order: ${
                          filteredOrders[parseInt(rowId)].order_number
                        }`}
                        placeholder="Search for order products"
                        data={filteredOrders[parseInt(rowId)].order_products}
                        columns={order_products_columns}
                      />
                    </div>
                  )}
                {rowId &&
                  filteredOrders[parseInt(rowId)].order_items.length > 0 && (
                    <div className="mt-2">
                      <SubTableContainer
                        header={`Order Items of Order: ${
                          filteredOrders[parseInt(rowId)].order_number
                        }`}
                        placeholder="Search for order items"
                        data={filteredOrders[parseInt(rowId)].order_items}
                        columns={order_items_columns}
                      />
                    </div>
                  )}
                {rowId &&
                  filteredOrders[parseInt(rowId)].order_item_lines.length >
                    0 && (
                    <div className="mt-2">
                      <SubTableContainer
                        header={`Order Item Lines of Order: ${
                          filteredOrders[parseInt(rowId)].order_number
                        }`}
                        placeholder="Search for order item lines"
                        data={filteredOrders[parseInt(rowId)].order_item_lines}
                        columns={order_item_lines_columns}
                      />
                    </div>
                  )}
              </>
            }
            chart={
              <div className="">
                {chart_values.find((val: number) => val > 0) !== undefined ? (
                  <div className="bg-white text-primaryText">
                    <div
                      className="p-4"
                      style={{
                        width: contentWidth,
                        height: `${window.innerHeight - 205}px`,
                      }}
                    >
                      <SingleChart
                        chartData={{
                          labels: chart_data?.map((c: any) => c.label),
                          datasets: [
                            {
                              type: "bar" as const,
                              label: "Status",
                              data: chart_data?.map((c: any) => c.amount),
                              backgroundColor: ["#b82b94"],
                              borderWidth: 0,
                              maxBarThickness: 40,
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="italic font-medium">
                    No Order Products found
                  </div>
                )}
              </div>
            }
          />
        </div>
      ) : (
        <div className="p-4 mt-4 bg-white border rounded-md italic font-medium">
          No Orders
        </div>
      )}
    </>
  );
};

export default Orders;
