import { useCDM } from "../../../cdm-context";
import { CustomerFinChartDataFragment } from "../../../hasura.graphql";
import { contentWidth, currencyConverter } from "../../utils";
import MonthlyChart from "./monthly-chart";
import CummulativeChart from "./cummulative-chart";

const FinChart: React.FC<{
  data: CustomerFinChartDataFragment;
  fromDate: string;
  setFromDate: React.Dispatch<React.SetStateAction<string>>;
}> = ({ data, fromDate, setFromDate }) => {
  const { show } = useCDM();

  return (
    <div>
      {/* {chart_values.find((val: number) => val > 0) !== undefined ? ( */}
      <div className="">
        <div className="">
          <div className="flex items-start mb-3 p-4 pb-0">
            <div className="w-1/4">
              <select
                value={fromDate}
                onChange={(e) => {
                  setFromDate(e.target.value);
                }}
                className="border border-gray-200 rounded text-sm text-gray-500 focus:ring-0"
              >
                <option value={3}>Last 3 months</option>
                <option value={6}>Last 6 months</option>
                <option value={12}>Last 12 months</option>
              </select>
            </div>
            <div className="w-full flex mr-1">
              <div className="flex w-1/3 justify-end">
                <div className="mr-2">
                  <div className="flex items-center">
                    <div
                      style={{
                        width: 8,
                        height: 8,
                        background: "#5E81C0",
                      }}
                      className=""
                    ></div>
                    <div className="mx-1 text-xs text-gray-600">
                      Booked Monthly
                    </div>
                  </div>
                  <div className="flex items-center mt-2">
                    <div
                      style={{
                        width: 8,
                        height: 8,
                        background: "#B82B94",
                      }}
                    ></div>
                    <div className="mx-1 text-xs text-gray-600">
                      Paid Monthly
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <div
                      style={{
                        width: 8,
                        height: 8,
                        background: "#715EC2",
                      }}
                    ></div>
                    <div className="mx-1 text-xs text-gray-600">
                      Billed Monthly
                    </div>
                  </div>
                  <div className="flex items-center mt-2">
                    <div
                      style={{
                        width: 8,
                        height: 8,
                        background: "green",
                      }}
                    ></div>
                    <div className="mx-1 pr-2 text-xs text-gray-600">
                      Credited Monthly
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-2/3 justify-end mr-10">
                <div className="mr-2">
                  <div className="flex items-center">
                    <div
                      style={{
                        width: 8,
                        height: 3,
                        background: "#5E81C0",
                      }}
                      className="rounded"
                    ></div>
                    <div className="mx-1 text-xs text-gray-600">
                      Booked Amount
                    </div>
                    <div className="text-sm font-medium">
                      {currencyConverter(
                        data.booked_revenue &&
                          data.booked_revenue[data.booked_revenue.length - 1]
                            .amount,
                        show?.currency || "USD"
                      )}
                    </div>
                  </div>
                  <div className="flex items-center mt-1">
                    <div
                      style={{
                        width: 8,
                        height: 3,
                        background: "#B82B94",
                      }}
                      className="rounded"
                    ></div>
                    <div className="mx-1 text-xs text-gray-600">
                      Paid Amount
                    </div>
                    <div className="text-sm font-medium">
                      {currencyConverter(
                        data.actual_revenue &&
                          data.actual_revenue[data.actual_revenue.length - 1]
                            .amount,
                        show?.currency || "USD"
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <div
                      style={{
                        width: 8,
                        height: 3,
                        background: "#715EC2",
                      }}
                      className="ml-2 rounded"
                    ></div>
                    <div className="mx-1 text-xs text-gray-600">
                      Billed Amount
                    </div>
                    <div className="text-sm font-medium">
                      {currencyConverter(
                        data.billed_revenue &&
                          data.billed_revenue[data.billed_revenue.length - 1]
                            .amount,
                        show?.currency || "USD"
                      )}
                    </div>
                  </div>
                  <div className="flex items-center mt-1">
                    <div
                      style={{
                        width: 8,
                        height: 3,
                        background: "green",
                      }}
                      className="ml-2 rounded"
                    ></div>
                    <div className="mx-1 text-xs text-gray-600">
                      Credited Amount
                    </div>
                    <div className="text-sm font-medium">
                      {currencyConverter(
                        data.netsuite_credit_issued &&
                          data.netsuite_credit_issued[
                            data.netsuite_credit_issued.length - 1
                          ].amount,
                        show?.currency || "USD"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="p-4 grid grid-cols-2 gap-4"
            style={{
              width: contentWidth,
            }}
          >
            <MonthlyChart fromDate={fromDate} setFromDate={setFromDate} />
            <CummulativeChart fromDate={fromDate} setFromDate={setFromDate} />
          </div>
        </div>
      </div>
      {/* ) : (
        <div className="italic font-medium">No Revenue data found</div>
      )} */}
    </div>
  );
};

export default FinChart;
