import React, { useState } from "react";
import { useFeatureFlagQuery, usePlatformsQuery } from "../hasura.graphql";
import Modal from "../components/modal";
import Loader from "../common/loader";
import { useDebounce } from "use-debounce";
import { useCDPRole } from "../cdp-role-context";
import { ModalType } from "./channels";
import PlatformCard from "../components/cards/platform-card";
import PlatformViewForm from "../components/platforms/platform-view-form";
import { AvailabilityCountriesandRegionsType } from "../components/formik/availability-regions";
import TableFilter from "../common/table-filter";
import { useLocation, useNavigate } from "react-router-dom";
import DelayedToolTip from "../components/delayed-tooltip";
import PlatformList from "../components/platforms/platform-list";

const Platforms: React.FC = () => {
  const [platformEdit, setPlatformEdit] = React.useState<ModalType | null>(
    null
  );
  const [platformSearch, setPlatformSearch] = React.useState<string>("");
  const [togglePlatformView, setTogglePlatformView] = useState<string>("list");
  const [debouncedPlatformSearch] = useDebounce(platformSearch, 100);
  const { setCDPRole } = useCDPRole();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const platformTabs = [
    {
      label: "Platforms",
      url: "/platforms/platform",
      active: pathname === "/platforms/platform",
    },
    {
      label: "Grouped Platforms",
      url: "/platforms/grouped-platform",
      active: pathname === "/platforms/grouped-platform",
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const role_data = useFeatureFlagQuery({
    onCompleted: (data) => {
      setCDPRole(data.feature_flag.map((feat) => feat.feature));
    },
  });
  
  const { data, loading, error } = usePlatformsQuery();

  if (loading) {
    return (
      <>
        <div
          className={`hidden sm:block sticky top-14 z-20 bg-gradient-to-r from-gradientBlue via-gradientBlue to-gradientPurple w-full`}
        >
          <div className="border-b border-gray-200 rounded-t-md">
            <nav className="-mb-px px-6 py-1 flex space-x-8" aria-label="Tabs">
              {platformTabs.map((tab, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      navigate(tab.url);
                    }}
                    className={`${
                      tab.active
                        ? "text-orange-500"
                        : "text-white hover:scale-105 transition-all"
                    } whitespace-nowrap p-2 font-medium text-sm cursor-pointer`}
                  >
                    {tab.label}
                  </div>
                );
              })}
            </nav>
          </div>
        </div>
        <div className="mx-6 mt-4">
          <Loader />
        </div>
      </>
    );
  }
  if (error) {
    return <i>Error</i>;
  }
  if (!data) {
    return <i>Channels data not available</i>;
  }

  const debouncedPlatforms = data.platform.filter((pform) => {
    if (!debouncedPlatformSearch) {
      return true;
    }
    return `${pform.id} ${pform.salesforce_id} ${pform.name} ${
      pform.account?.account_name
    }${pform.account?.amagi_id} ${pform.platform_brand} ${
      pform.platform_tier
    } ${pform.availablity_country_and_regions
      .map((reg: AvailabilityCountriesandRegionsType) => reg.region)
      .join(" ")}`
      .toLowerCase()
      .includes(debouncedPlatformSearch.toLowerCase());
  });

  return (
    <div>
      <div
        className={`hidden sm:block sticky top-14 z-20 bg-gradient-to-r from-gradientBlue via-gradientBlue to-gradientPurple w-full`}
      >
        <div className="border-b border-gray-200 rounded-t-md">
          <nav className="-mb-px px-6 py-1 flex space-x-8" aria-label="Tabs">
            {platformTabs.map((tab, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    navigate(tab.url);
                  }}
                  className={`${
                    tab.active
                      ? "text-orange-500"
                      : "text-white hover:scale-105 transition-all"
                  } whitespace-nowrap p-2 font-medium text-sm cursor-pointer`}
                >
                  {tab.label}
                </div>
              );
            })}
          </nav>
        </div>
      </div>
      <div className="mx-6">
        {data.platform.length > 0 ? (
          <>
            {togglePlatformView === "grid" ? (
              <>
                <div className="w-full flex justify-between sticky top-24 h-14 z-10 bg-secondaryBg items-center">
                  <div className="flex items-center w-1/3">
                    <div className="text-lg font-medium">
                      Platforms ({debouncedPlatforms.length})
                    </div>
                    <div className="flex items-center ml-3 w-3/12 cursor-pointer">
                      <DelayedToolTip title="list view" styles="w-full">
                        <div
                          onClick={() => setTogglePlatformView("list")}
                          className={`p-2 w-full h-8 flex items-center justify-center rounded-sm border-2 border-r-0 border-gray-300`}
                        >
                          <img
                            src="/assets/channel-list.svg"
                            alt="channel-list"
                          />
                        </div>
                      </DelayedToolTip>
                      <DelayedToolTip title="grid view" styles="w-full">
                        <div
                          onClick={() => setTogglePlatformView("grid")}
                          className={`p-2 w-full h-8 flex items-center justify-center rounded-sm ${
                            togglePlatformView === "grid"
                              ? "border-2 border-primaryColor"
                              : "border-2 border-l-0 border-gray-300"
                          }`}
                        >
                          <img
                            src="/assets/channel-grid.svg"
                            alt="channel-grid"
                          />
                        </div>
                      </DelayedToolTip>
                    </div>
                  </div>
                  <div className="flex w-2/5">
                    <div className="w-4/5">
                      <TableFilter
                        filter={platformSearch}
                        setFilter={setPlatformSearch}
                        placeholder="Search by platform, customer, tier and region"
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-5 gap-8 mt-2">
                  {debouncedPlatforms.map((plat) => {
                    return (
                      <div
                        key={plat.salesforce_id}
                        onClick={() => {
                          setPlatformEdit({ id: plat.id, name: plat.name });
                        }}
                        className="cursor-pointer transition-transform hover:scale-105"
                      >
                        <PlatformCard
                          url={`/platforms/platform/${plat.id}`}
                          platform={plat}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <PlatformList
                platforms={debouncedPlatforms}
                toggleView={togglePlatformView}
                setToggleView={setTogglePlatformView}
                platformSearch={platformSearch}
                setPlatformSearch={setPlatformSearch}
                header="Platforms"
                url="/platforms/platform"
              />
            )}
          </>
        ) : (
          <div className="p-4 mt-4 bg-white border rounded-md italic font-medium">
            No Platforms
          </div>
        )}
      </div>
      {platformEdit && (
        <Modal
          title="All Platforms"
          modalWidth="100vw"
          modalShow={platformEdit}
          onClose={setPlatformEdit}
        >
          <PlatformViewForm
            platformId={platformEdit.id}
            isModal={true}
            header="All Platforms"
            url="/platforms/platform"
          />
        </Modal>
      )}
    </div>
  );
};

export default Platforms;
