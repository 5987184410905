import { useState } from "react";
import { platformFields } from "../../../constants";
import FormikInput from "../../formik/input";
import FormikSelect from "../../formik/select";
import FormikRange from "../../formik/range";
import FormikStreamSpecs from "./stream-specs";
import { PlatformEditTypes } from "./types";
import ToggleBox from "../../toggle-box";
import FormikMultiSelect from "../../formik/multi-select";
import FormikTextField from "../../formik/text-field";

const PlatformTechnicalSpecsForm: React.FC<{
  values: PlatformEditTypes;
}> = ({ values }) => {
  const [hideTabs, setHideTabs] = useState<Array<string>>([]);

  const handleHideTabs = (tab: string) => {
    setHideTabs((hideTabs) => [...hideTabs, tab]);
  };
  const handleUnhideTabs = (tab: string) => {
    setHideTabs(hideTabs.filter((f: string) => f !== tab));
  };
  return (
    <div>
      <ToggleBox
        columns={hideTabs}
        handleHideColumns={handleHideTabs}
        handleUnhideColumns={handleUnhideTabs}
        header="Stream Specs"
      >
        <>
          <FormikMultiSelect
            inlinelabel="Stream Type"
            name="stream_type"
            defaultValue={values.stream_type}
            defFields={platformFields.stream_type.sort()}
          />
          <div className="my-8">
            <FormikStreamSpecs
              streamSpecs={
                values.technical_spec.length > 0
                  ? values.technical_spec
                  : [
                      {
                        resolution: [],
                        resolution_type: "",
                        frame_rate: [],
                        bitrate: null,
                        audio: "",
                        gop: "",
                      },
                    ]
              }
            />
          </div>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div>
              <FormikSelect
                inlinelabel="EPG"
                name="epg"
                defaultValue={values.epg}
                defFields={["Yes", "No"]}
              />
            </div>
            <div>
              <FormikSelect
                inlinelabel="EPG Format"
                name="epg_format"
                defaultValue={values.epg_format}
                defFields={platformFields.epg_format.sort()}
              />
            </div>
            <div>
              <FormikTextField
                inlinelabel="EPG Format Example"
                name="epg_format_example"
                defaultValue={values.epg_format_example}
              />
            </div>
            <div>
              <FormikRange
                inlinelabel="Overall Bitrate"
                placeholder="Range: 0 - 8000"
                min="0"
                max="8000"
                subText="Mbps"
                name="overall_bitrate"
                defaultValue={parseInt(values.overall_bitrate!)}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Subtitle / CC Format"
                name="subtitle_cc_format"
                defaultValue={values.subtitle_cc_format}
                defFields={platformFields.subtitle.sort()}
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36 mt-8">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Redundancy"
                name="redundancy"
                defaultValue={values.redundancy}
                defFields={["Yes", "No"]}
              />
            </div>
            {values.redundancy === "Yes" && (
              <>
                <div>
                  <FormikRange
                    inlinelabel="Redundancy Player Count"
                    placeholder="Range: 0 - x"
                    min="0"
                    name="redundancy_player_count"
                    defaultValue={values.redundancy_player_count}
                  />
                </div>
                <div>
                  <FormikRange
                    inlinelabel="Redundancy Delivery Count"
                    placeholder="Range: 0 - x"
                    min="0"
                    name="redundancy_delivery_count"
                    defaultValue={values.redundancy_delivery_count}
                  />
                </div>
              </>
            )}
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Ad Trigger Type"
                name="ad_trigger_type"
                defaultValue={values.ad_trigger_type}
                defFields={platformFields.ad_trigger.sort()}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Ad Break"
                name="ad_break"
                defaultValue={values.ad_break}
                defFields={platformFields.ad_break}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Platform QA Cycle Time"
                name="platform_qa_cycle"
                defaultValue={values.platform_qa_cycle}
                defFields={platformFields.platform_qa_cycle}
              />
            </div>
            <div>
              <FormikInput
                inlinelabel="Video Player"
                name="video_player"
                defaultValue={values.video_player}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Platform Specific Encoding"
                name="platform_specific_encoding"
                defaultValue={values.platform_specific_encoding}
                defFields={["Yes", "No"]}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikSelect
                inlinelabel="Analytics Share Rule"
                name="analytics_share_rule"
                defaultValue={values.analytics_share_rule}
                defFields={platformFields.analytics_share_rule}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <FormikInput
                inlinelabel="Specifications URL"
                name="specifications_url"
                defaultValue={values.specifications_url}
              />
            </div>
          </div>
        </>
      </ToggleBox>
    </div>
  );
};

export default PlatformTechnicalSpecsForm;
