import { useCDM } from "../cdm-context";
import CustomerModal from "./info/customer-modal";

const ShowInfoDialog = () => {
  const { show } = useCDM();

  if (show === null) {
    return <></>;
  }

  if (show.type === "customer-modal") {
    return <CustomerModal />;
  }

  return <></>;
};

export default ShowInfoDialog;
