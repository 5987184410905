import {
  ChevronDownIcon,
  ChevronRightIcon,
  ExternalLinkIcon,
} from "@heroicons/react/outline";
import _ from "lodash";
import { useMemo, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CustomerStatusColumnFilter from "../../common/filters/customer-status-filter";
import { useCDM } from "../../cdm-context";
import { CustomerFilter } from "../../common/customer-filter";
import SidebarFilter from "./sidebar-filter";
import {
  AllCustomerDataFragment,
  useCustomerQuery,
} from "../../hasura.graphql";
import Table from "./table";
import TableRowsFilter from "../../common/filters/table-rows-filter";
import Loader from "../../common/loader";

const SalesView: React.FC = () => {
  const { setShow } = useCDM();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchData, setSearchData] = useState<AllCustomerDataFragment[]>([]);
  const [openDirector, setOpenDirector] = useState<number[]>([]);
  const [openManager, setOpenManager] = useState<number[]>([]);
  const [openUser, setOpenUser] = useState<number[]>([]);

  const columns: any = useMemo(
    () => [
      {
        Header: "CUSTOMER",
        accessor: "account_name",
        Cell: ({ cell }: any) => (
          <div className="flex w-full">
            <div className="w-16 h-12">
              <LazyLoadImage
                className="h-full w-full border rounded-lg object-contain"
                style={{ background: "#C1CFFB" }}
                src={
                  cell.row.original.logo
                    ? `logos/${cell.row.original.logo}`
                    : `https://via.placeholder.com/300x200.png/C1CFFB/1924C1?text=${cell.row.original.account_name}`
                }
                alt={cell.row.original.logo}
              />
            </div>
            <div className="flex justify-between items-center w-full">
              <div className="ml-3 flex flex-col">
                <span
                  className="cursor-pointer mr-2 text-blue-500 underline"
                  data-tip={cell.row.original.account_name}
                  onClick={() => {
                    setShow({
                      amg_id: cell.row.values.amagi_id,
                      name: cell.row.original.account_name,
                      currency: cell.row.original.invoicing_currency,
                      type: "customer-modal",
                    });
                  }}
                >
                  {cell.row.original.account_name.length > 25
                    ? `${cell.row.original.account_name.slice(0, 25)}...`
                    : cell.row.original.account_name}
                </span>

                <span
                  data-tip={cell.row.original.account_legal_name}
                  className="text-sm text-gray-500"
                >
                  {cell.row.original.account_legal_name &&
                  cell.row.original.account_legal_name.length > 25
                    ? `${cell.row.original.account_legal_name.slice(0, 25)}...`
                    : cell.row.original.account_legal_name}
                </span>
              </div>
              <a
                href={`/customers/${cell.row.values.amagi_id}`}
                target="_blank"
                rel="noreferrer"
              >
                <ExternalLinkIcon className="h-5 w-5 text-blue-500" />
              </a>
            </div>
          </div>
        ),
        Filter: CustomerStatusColumnFilter,
        filter: "multiSelect",
      },
      {
        Header: "AMAGI ID",
        always_show: true,
        accessor: "amagi_id",
      },
      {
        Header: "TYPE",
        accessor: "customer_type",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "SEGMENT",
        accessor: "segment",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "REGION",
        accessor: "region",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "SIZE",
        accessor: "size",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
    ],
    [setShow]
  );

  const {
    data: rowData,
    loading,
    error,
  } = useCustomerQuery({
    fetchPolicy: "cache-first",
  });

  if (loading) {
    return (
      <div className="mx-6 mt-4">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <i>Error</i>;
  }

  if (!rowData) {
    return <i>Customer data not available</i>;
  }

  const salesData = rowData.customer.map((cust) => ({
    rep_director: cust.owner?.owner_to_user?.rep_director || "-",
    rep_manager: cust.owner?.owner_to_user?.rep_manager || "-",
    user: cust.owner?.owner_to_user?.name || "-",
    customer: cust,
  }));

  const data =
    searchQuery && searchQuery.length > 0
      ? searchData.map((cust) => ({
          rep_director: cust.owner?.owner_to_user?.rep_director || "-",
          rep_manager: cust.owner?.owner_to_user?.rep_manager || "-",
          user: cust.owner?.owner_to_user?.name || "-",
          customer: cust,
        }))
      : salesData;

  const nest: any = (data: any, keys: string[]) => {
    if (!keys.length) return data;
    var first = keys[0];
    var rest = keys.slice(1);
    return _.mapValues(_.groupBy(data, first), (value) => {
      return nest(value, rest);
    });
  };

  const filteredSalesData = nest(data, ["rep_director", "rep_manager", "user"]);

  console.log(filteredSalesData, salesData);

  return (
    <div className="border bg-white mx-6 mt-4">
      <div className="flex justify-between items-center p-3 border-b">
        <div className="text-xl font-semibold">Sales View</div>
        <div className="w-1/3">
          <CustomerFilter
            filter={searchQuery}
            setFilter={setSearchQuery}
            setSearchData={setSearchData}
            placeholder="Search with customer attributes"
          />
        </div>
      </div>
      <div className="flex">
        <div className="w-1/5">
          <SidebarFilter customers={rowData.customer} />
        </div>
        <table>
          {/* <thead className="border">
            <tr>
              <th className="p-2">Customer</th>
              <th className="p-2">Amagi ID</th>
              <th className="p-2">Type</th>
              <th className="p-2">Type</th>
              <th className="p-2">Segment</th>
              <th className="p-2">Region</th>
              <th className="p-2">Size</th>
            </tr>
          </thead> */}
          <tbody>
            {Object.entries(filteredSalesData).map(
              ([key, value]: any, index) => {
                return (
                  <>
                    <tr className="flex items-center px-2 border-b border-gray-300 last:border-0">
                      <td
                        onClick={() => {
                          setOpenDirector((director) =>
                            !director.includes(index)
                              ? [...director, index]
                              : director.filter((d) => d !== index)
                          );
                        }}
                        className={`cursor-pointer p-px border rounded ${
                          openDirector.includes(index)
                            ? "bg-primaryColor border-primaryColor text-white"
                            : ""
                        }`}
                      >
                        {openDirector.length !== 0 &&
                        openDirector.includes(index) ? (
                          <ChevronDownIcon className="h-4 w-4" />
                        ) : (
                          <ChevronRightIcon className="h-4 w-4" />
                        )}
                      </td>
                      <td key={index} className="ml-3">
                        {key}
                      </td>
                    </tr>
                    {openDirector.includes(index) &&
                      Object.entries(value).map(
                        ([mKey, mValue]: any, mIndex) => {
                          return (
                            <>
                              <tr className="flex items-center pl-7 bg-primaryColor/10 border-b-2 border-white last:border-0">
                                <td
                                  onClick={() => {
                                    setOpenManager((manager) =>
                                      !manager.includes(mIndex)
                                        ? [...manager, mIndex]
                                        : manager.filter((m) => m !== mIndex)
                                    );
                                  }}
                                  className={`cursor-pointer p-px border rounded ${
                                    openManager.includes(mIndex)
                                      ? "bg-primaryColor border-primaryColor text-white"
                                      : "border-gray-400"
                                  }`}
                                >
                                  {openManager.length !== 0 &&
                                  openManager.includes(mIndex) ? (
                                    <ChevronDownIcon className="h-4 w-4" />
                                  ) : (
                                    <ChevronRightIcon className="h-4 w-4" />
                                  )}
                                </td>
                                <td key={mIndex} className="ml-3">
                                  {mKey}
                                </td>
                              </tr>
                              {openManager.includes(mIndex) &&
                                Object.entries(mValue).map(
                                  ([uKey, uValue]: any, uIndex) => {
                                    console.log(
                                      uValue.map((val: any) => val.customer)
                                    );
                                    return (
                                      <>
                                        <tr className="flex items-center pl-12 bg-primaryColor/20 border-b-2 border-white last:border-0">
                                          <td
                                            onClick={() =>
                                              setOpenUser((user) =>
                                                !user.includes(uIndex)
                                                  ? [...user, uIndex]
                                                  : user.filter(
                                                      (m) => m !== uIndex
                                                    )
                                              )
                                            }
                                            className={`cursor-pointer p-px border rounded ${
                                              openUser.includes(uIndex)
                                                ? "bg-primaryColor border-primaryColor text-white"
                                                : "border-gray-400"
                                            }`}
                                          >
                                            {openUser.length !== 0 &&
                                            openUser.includes(uIndex) ? (
                                              <ChevronDownIcon className="h-4 w-4" />
                                            ) : (
                                              <ChevronRightIcon className="h-4 w-4" />
                                            )}
                                          </td>
                                          <td key={uIndex} className="ml-3">
                                            {uKey}
                                          </td>
                                        </tr>
                                        {openManager.includes(mIndex) &&
                                          openUser.includes(uIndex) && (
                                            <div className="pl-20 py-6 pr-6 bg-primaryColor/30">
                                              <Table
                                                rowData={uValue.map(
                                                  (val: any) => val.customer
                                                )}
                                                columns={columns}
                                                placeholder=""
                                                tooltipAlign="top"
                                              />
                                            </div>
                                          )}
                                      </>
                                    );
                                  }
                                )}
                            </>
                          );
                        }
                      )}
                  </>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SalesView;
