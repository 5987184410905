import React, { useState, useEffect, Fragment, ChangeEvent } from "react";
import { useField } from "formik";
import { XIcon } from "@heroicons/react/solid";
import { Popover, Transition } from "@headlessui/react";
import TableFilter from "../../common/table-filter";
import { PlusIcon } from "@heroicons/react/outline";

const FormikGenre: React.FC<any> = ({
  inlinelabel,
  defaultValue,
  ...props
}) => {
  const _field = useField(props);
  const [search, setSearch] = useState<string | null>(null);
  const defFields = props.defFields;
  const [inputArr, setInputArr] = useState(defaultValue);
  const [input, setInput] = useState("");
  const field = _field[0];
  const { setValue } = _field[2];

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const t = e.target.name;
    if (inputArr && inputArr.includes(t)) {
      setInputArr((previnputArr: any) => {
        return previnputArr.filter((v: string) => v !== t);
      });
    } else {
      setInputArr((previnputArr: any) => [...previnputArr, t]);
    }
  };

  const debouncedValues = defFields.filter((field: string) => {
    if (!search) {
      return true;
    }
    return field.toLowerCase().includes(search.toLowerCase());
  });

  useEffect(() => {
    setValue(inputArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputArr.length]);

  return (
    <div style={{ padding: "5px 0px" }} className="flex flex-col">
      <label className="text-gray-400">{inlinelabel}</label>
      <Popover as="div" className="relative items-center max-w-sm">
        {({ open }) => (
          <>
            <Popover.Button className="rounded-full w-full focus:outline-none">
              <select
                name={field.name}
                value={`${inputArr.length} selected`}
                placeholder={"Enter Text"}
                style={{ padding: "5.5px 0px" }}
                onChange={() => null}
                className="border-0 border-b focus:ring-0 border-gray-300 outline-none bg-transparent w-full"
              >
                <option disabled>{inputArr.length} selected</option>
              </select>
            </Popover.Button>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Popover.Panel className="origin-top-right text-left z-10 absolute right-0 min-w-full max-h-60 overflow-y-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                {() => (
                  <div className="px-3">
                    <div className="py-3 sticky top-0 bg-white">
                      <TableFilter
                        filter={search}
                        setFilter={setSearch}
                        placeholder="Search"
                      />
                    </div>
                    <div className="pb-2">
                      {debouncedValues.map((inp: string, index: number) => {
                        return (
                          <div key={index} className="py-1">
                            <input
                              type="checkbox"
                              className="focus:ring-0"
                              id={inp}
                              name={inp}
                              onChange={onChange}
                              title={inp}
                              checked={inputArr.includes(inp)}
                            />
                            <label htmlFor={inp} className="pl-2">
                              {inp}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <div className="flex mt-2 w-full">
        {inputArr.map((data: any, index: any) => {
          return (
            <div
              key={index}
              className="px-3 flex cursor-pointer rounded-sm text-black font-semibold mr-2 border border-gray-300"
            >
              <div className="whitespace-nowrap">{data}</div>
              <XIcon
                onClick={() =>
                  setInputArr(inputArr.filter((f: any) => f !== data))
                }
                className="h-4 w-4 text-gray-400"
                style={{ marginTop: "2.2px", marginLeft: "12px" }}
              />
            </div>
          );
        })}
      </div>
      {inputArr.includes("Other") ? (
        <div className="mt-4">
          <div className="flex items-center">
            <input
              type="text"
              autoFocus
              value={input}
              // disabled={disabled}
              name={field.name}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Add Genre"
              className="py-1 px-0 border-0 border-b focus:ring-0 border-gray-300 outline-none bg-transparent w-full"
              style={{ fontSize: 14 }}
            />
            <div className="ml-1" onClick={() => setInput("")}>
              <XIcon className="h-4 w-4" />
            </div>
            <div className="ml-1">
              <PlusIcon className="h-4 w-4" />
            </div>
          </div>
        </div>
      ) : null}

      {/* <select
        name={field.name}
        value={`${inputArr.length} selected`}
        placeholder={"Enter Text"}
        onChange={(e) => {
          setInputArr((oldArr: any) => [...oldArr, e.target.value])
        }}
        style={{ padding: "5.5px 0px"}}
        className="border-0 border-b focus:ring-0 border-gray-300 outline-none bg-transparent w-full max-w-xs"
      >
        <option disabled>{inputArr.length} selected</option>
        {mappedField.map((inp: any, index: any) => {
          return (
            <option key={index} value={inp}>{inp}</option>
          )
        })}
      </select> */}
    </div>
  );
};

export default FormikGenre;
