import { DateRangePicker, Stack } from "rsuite";
import { DateTime } from "luxon";

const predefinedRanges: any = [
  {
    label: "Today",
    value: [DateTime.utc().toBSON(), DateTime.utc().toBSON()],
    placement: "left",
  },
  {
    label: "Yesterday",
    value: [
      DateTime.utc().minus({ day: 1 }).toBSON(),
      DateTime.utc().minus({ day: 1 }).toBSON(),
    ],
    placement: "left",
  },
  {
    label: "This week",
    value: [
      DateTime.utc().startOf("week").toBSON(),
      DateTime.utc().endOf("week").toBSON(),
    ],
    placement: "left",
  },
  {
    label: "Last 7 days",
    value: [DateTime.utc().minus({ day: 6 }).toBSON(), DateTime.utc().toBSON()],
    placement: "left",
  },
  {
    label: "Last 30 days",
    value: [
      DateTime.utc().minus({ day: 29 }).toBSON(),
      DateTime.utc().toBSON(),
    ],
    placement: "left",
  },
  {
    label: "This month",
    value: [DateTime.utc().startOf("month").toBSON(), DateTime.utc().toBSON()],
    placement: "left",
  },
  {
    label: "Last month",
    value: [
      DateTime.utc().minus({ month: 1 }).startOf("month").toBSON(),
      DateTime.utc().minus({ month: 1 }).endOf("month").toBSON(),
    ],
    placement: "left",
  },
  {
    label: "This year",
    value: [DateTime.utc().startOf("year").toBSON(), DateTime.utc().toBSON()],
    placement: "left",
  },
  {
    label: "Last year",
    value: [
      DateTime.utc().minus({ year: 1 }).startOf("year").toBSON(),
      DateTime.utc().minus({ year: 1 }).endOf("year").toBSON(),
    ],
    placement: "left",
  },
  // {
  //   label: "All time",
  //   value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
  //   placement: "left",
  // },
  {
    label: "Last week",
    closeOverlay: false,
    value: [
      DateTime.utc().minus({ week: 1 }).startOf("week").toBSON(),
      DateTime.utc().minus({ week: 1 }).endOf("week").toBSON(),
    ],
    appearance: "default",
  },
];

const CustomDatePicker: React.FC<{
  handleDateRange: (fromDate: string, toDate: string) => void;
  fromDate: string;
  toDate: string;
}> = ({ handleDateRange, fromDate, toDate }) => {
  return (
    <Stack direction="column" spacing={8} alignItems="flex-start">
      <DateRangePicker
        className="z-50"
        value={[new Date(fromDate), new Date(toDate)]}
        onChange={(date) =>
          handleDateRange(
            date![0].toISOString().slice(0, 10),
            date![1].toISOString().slice(0, 10)
          )
        }
        placement="bottomEnd"
        placeholder="Select date range"
        ranges={predefinedRanges}
        style={{ width: 300 }}
      />
    </Stack>
  );
};

export default CustomDatePicker;
