import React, { useState, useEffect, useRef } from "react";
import {
  useGetCustomerRegionRevenueQuery,
  useGetCustomerTierRevenueQuery,
  useGetCustomerTypeRevenueQuery,
} from "../../hasura.graphql";
import Loader from "../../common/loader";
import { DateTime } from "luxon";
import { DonutChart } from "./DonutChart";

const DQCustomerRevenue: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<string>("Region");
  const [monthRange, setMonthRange] = useState<string>("12");
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const renderChart = () => {
    return (
      <DoughnutChart
        selectedOption={selectedOption}
        monthRange={monthRange}
        queryHook={
          selectedOption === "Region"
            ? useGetCustomerRegionRevenueQuery
            : selectedOption === "Tier"
            ? useGetCustomerTierRevenueQuery
            : useGetCustomerTypeRevenueQuery
        }
      />
    );
  };

  return (
    <div className="bg-transperent">
      <div
        className="p-2  flex"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* Dropdowns in the top left corner */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <strong
            className="text-xl font-semibold"
            style={{ marginRight: "10px" }}
          >
            Revenue splits by
          </strong>
          <select
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
            className="text-xl font-semibold"
            style={{
              borderBottom: "1px solid #000000",
              width: "170px",
              borderLeft: "none",
              borderRight: "none",
              borderTop: "none",
              padding: "5px 0",
              outline: "none",
              backgroundColor: "transparent",
            }}
          >
            <option value="Region">Sales region</option>
            <option value="Tier">Customer tier</option>
            <option value="Type">Customer type</option>
          </select>
        </div>

        {/* Month range dropdown in the top right corner */}
        <div className="relative" ref={dropdownRef}>
          <div
            onClick={() => setDropdownOpen(!isDropdownOpen)}
            className="cursor-pointer flex items-center bg-gray-200 rounded-md border border-black p-1 gap-2"
            style={{
              width: "149px",
              height: "32px",
              borderRadius: "4px",
              border: "1px solid #CDD2D5",
              background: "#EDF2F6",
            }}
          >
            <div className="ml-1">{`Last ${monthRange} months`}</div>
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="text-gray-500"
            >
              <path
                d="M21 3H20V1H18V3H6V1H4V3H3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H21C22.1046 21 23 20.1046 23 19V5C23 3.89543 22.1046 3 21 3ZM21 19H3V8H21V19ZM21 6H3V5H21V6Z"
                fill="currentColor"
              />
            </svg>
          </div>
          {isDropdownOpen && (
            <div className="absolute top-full left-0 mt-1 bg-white border rounded-md overflow-hidden">
              <div
                onClick={() => {
                  setDropdownOpen(false);
                  setMonthRange("3");
                }}
                className="text-sm text-gray-800 cursor-pointer p-2 hover:bg-gray-100"
              >
                Last 3 months
              </div>
              <div
                onClick={() => {
                  setDropdownOpen(false);
                  setMonthRange("6");
                }}
                className="text-sm text-gray-800 cursor-pointer p-2 hover:bg-gray-100"
              >
                Last 6 months
              </div>
              <div
                onClick={() => {
                  setDropdownOpen(false);
                  setMonthRange("12");
                }}
                className="text-sm text-gray-800 cursor-pointer p-2 hover:bg-gray-100"
              >
                Last 12 months
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="bg-white p-4 rounded-md border border-solid border-gray-300">
        {/* Render the DoughnutChart component */}
        {renderChart()}
      </div>
    </div>
  );
};
interface DoughnutChartProps {
  selectedOption: string;
  monthRange: string;
  queryHook: (variables: any) => any;
}

const DoughnutChart: React.FC<DoughnutChartProps> = ({
  monthRange,
  queryHook,
}) => {
  const { data, loading, error } = queryHook({
    variables: {
      from_date: DateTime.local()
        .startOf("month")
        .minus({ months: parseInt(monthRange) })
        .toISO()
        .slice(0, 10),
      to_date: DateTime.local()
        .startOf("month")
        .minus({ months: 1 })
        .endOf("month")
        .toISO()
        .slice(0, 10),
    },
  });

  if (loading) return <Loader />;
  if (error) return <i>Error</i>;
  if (!data) return <i>No Revenue Data</i>;

  const getColorData = (revenueData: any, colors: string[]) => {
    return revenueData
      .filter((item: any) => item[getLabelKey()] !== null)
      .slice()
      .sort((a: any, b: any) => b.total - a.total)
      .map((element: any, index: number) => ({
        ...element,
        color: colors[index % colors.length],
      }));
  };

  const getLabelKey = () => {
    if (queryHook === useGetCustomerRegionRevenueQuery)
      return "customer_region";
    if (queryHook === useGetCustomerTierRevenueQuery) return "customer_tier";
    if (queryHook === useGetCustomerTypeRevenueQuery) return "customer_type";
    return "";
  };

  const getTableKey = () => {
    if (queryHook === useGetCustomerRegionRevenueQuery) return "Sales region";
    if (queryHook === useGetCustomerTierRevenueQuery) return "Customer tier";
    if (queryHook === useGetCustomerTypeRevenueQuery) return "Customer type";
    return "";
  };

  const getColors = () => {
    // Define static colors order for each category
    const regionColors = [
      "#4CAF50",
      "#87CEEB",
      "#FFFF00",
      "#FFA500",
      "#9966FF",
      "#FF8C00",
    ];
    const tierColors = [
      "#FF6384",
      "#FFD700",
      "#C0C0C0",
      "#FF8C00",
      "#9966FF",
      "#4CAF50",
    ];
    const typeColors = [
      "#FF6384",
      "#36A2EB",
      "#FFCE56",
      "#4CAF50",
      "#9966FF",
      "#FF8C00",
    ];

    // Return colors based on selected category
    if (queryHook === useGetCustomerRegionRevenueQuery) return regionColors;
    if (queryHook === useGetCustomerTierRevenueQuery) return tierColors;
    if (queryHook === useGetCustomerTypeRevenueQuery) return typeColors;
    return [];
  };

  const getDataKey = () => {
    if (queryHook === useGetCustomerRegionRevenueQuery)
      return "customer_region_revenue";
    if (queryHook === useGetCustomerTierRevenueQuery)
      return "customer_tier_revenue";
    if (queryHook === useGetCustomerTypeRevenueQuery)
      return "customer_type_revenue";
    return "";
  };

  const filteredData =
  data[getDataKey()]
    ?.map((item: any) => ({
      ...item,
      [getLabelKey()]: item[getLabelKey()] || "Unsegmented",
    }))
    .filter((item: any) => item.total >= 1000) || [];

const sortedData = getColorData(filteredData, getColors());

const top3Slices = sortedData.slice(0, 3);
const remainingSlices = sortedData.slice(3);

const alternatingSortedData = [];
for (let i = 0; i < top3Slices.length; i++) {
  alternatingSortedData.push(top3Slices[i]);
  if (i < remainingSlices.length) {
    alternatingSortedData.push(remainingSlices[i]);
  }
}


const doughnutData = {
    labels: alternatingSortedData.map((item: any) => item[getLabelKey()]),
    datasets: [
      {
        data: alternatingSortedData.map((item: any) => item.total),
        backgroundColor: alternatingSortedData.map(
          (element: any) => element.color
        ),
        hoverBackgroundColor: alternatingSortedData.map(
          (element: any) => element.color
        ),
      },
    ],
  };


const totalRevenue = sortedData.reduce(
    (acc: number, item: any) => acc + item.total,
    0
  );
  
  
const chartdata = alternatingSortedData.map((item) => ({
    name: `${item[getLabelKey()]} 
    ${(((item.total /totalRevenue))*100).toFixed(2)}%`,
    value: item.total / 1_000_000,
  }))

const dounuttitle= "Revenue generated" // eslint-disable-next-line
const totalRevenueText = (totalRevenue / 1_000_000).toFixed(2) + "M" + " USD";
  

  return (
    <div
      className="bg-white rounded-md pb-6 flex flex-col justify-center"
      style={{ height: "578px", width: "100%" }}
    >
      <div
        className="p-2 mt-6 flex"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* Doughnut Chart */}
        <div
          style={{
            textAlign: "right",
            height: "95%",
            width: "70%",
            position: "relative",
          }}
        >
          <DonutChart
           width={1200} 
           height={500} 
           chartdata={chartdata} 
           colors={doughnutData.datasets[0].backgroundColor} 
           TitleText={dounuttitle} TotalText={totalRevenueText} />
        </div>
        {/* End Doughnut Chart */}
        {/* Table Section */}
        <div
          className="mr-4 rounded-md"
          style={{
            flex: "1",
            textAlign: "right",
            overflow: "auto",
            maxHeight: "100%",
            maxWidth: "100%",
            border: "1px solid #E0E0E0",
          }}
        >
          <table className="min-w-full">
            <thead>
              <tr className="bg-gray-200">
                <th className="text-left p-4">{getTableKey()}</th>
                <th className="text-center p-1">Revenue</th>
              </tr>
            </thead>
            <tbody>
              {alternatingSortedData
                .sort((a, b) => b.total - a.total)
                .map((item: any, index: number) => (
                  <tr key={item[getLabelKey()]}>
                    <td
                      className="text-left p-4"
                      style={{
                        borderBottom:
                          index < alternatingSortedData.length - 1
                            ? "1px solid #E0E0E0"
                            : "none",
                      }}
                    >
                      <span
                        style={{
                          display: "inline-block",
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: item.color,
                          marginRight: "8px",
                        }}
                      ></span>
                      {item[getLabelKey()]}
                    </td>
                    <td
                      className="text-right p-4"
                      style={{
                        borderBottom:
                          index < alternatingSortedData.length - 1
                            ? "1px solid #E0E0E0"
                            : "none",
                      }}
                    >
                      {`${(item.total / 1_000_000).toFixed(2)}M USD `}
                      <span style={{ color: "#888888" }}>{`(${(
                        (item.total / totalRevenue) *
                        100
                      ).toFixed(2)}%)`}</span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DQCustomerRevenue;
