import { DeliveryInfoFragment } from "../../hasura.graphql";
import ErrorBoundary from "../error-boundary";
import { contentWidth } from "../utils";
import DeliveriesChartTable from "./delivery-chart-table";

const DeliveriesByCustomer: React.FC<{
  deliveryData: Array<DeliveryInfoFragment>;
  header: string;
}> = ({ deliveryData, header }) => {
  return (
    <ErrorBoundary>
      <div className="mt-4" style={{ width: contentWidth }}>
        {deliveryData.length > 0 ? (
          <DeliveriesChartTable header={header} data={deliveryData} />
        ) : (
          <div className="p-4 mt-4 w-full bg-white border rounded-md italic font-medium">
            No {header}
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default DeliveriesByCustomer;
