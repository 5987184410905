import { ChannelKeyringType } from "../channel-edit-form/types";

const ViewKeyring: React.FC<{
  keyring: Array<ChannelKeyringType>;
  defaultId: string;
}> = ({ keyring, defaultId }) => {
  return (
    <div>
      <label className="text-gray-400">Keyring</label>
      <table className="mt-4" style={{ width: "50%", fontSize: "16px" }}>
        <thead className="border-0 border-b border-gray-50">
          <tr className="bg-gray-300">
            <th className="py-2 pl-2">S No.</th>
            <th className="text-left py-2 pl-2">Source</th>
            <th className="text-left py-2 pl-2">ID</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-0 border-b border-gray-50 bg-gray-50">
            <td className="py-1 pl-2">1</td>
            <td className="text-left py-2 pl-2">Salesforce</td>
            <td className="text-left py-2 pl-2">{defaultId}</td>
          </tr>
          {keyring.map((rating: ChannelKeyringType, index: number) => (
            <tr
              key={index}
              className="border-0 border-b border-gray-50 bg-gray-50"
            >
              <td className="py-1 pl-2">{index + 2}</td>
              <td className="text-left py-2 pl-2">{rating.source}</td>
              <td className="text-left py-2 pl-2">{rating.id}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewKeyring;
