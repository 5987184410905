import { useField } from "formik";

const FormikTextField: React.FC<any> = ({
  inlinelabel,
  defaultValue,
  placeholder = "Enter Text",
  ...props
}) => {
  const _field = useField(props);
  const field = _field[0];
  const { setValue } = _field[2];
  return (
    <div style={{ padding: "5px 0px" }}>
      <label className="text-gray-400">{inlinelabel}</label>
      <textarea
        value={defaultValue || ""}
        disabled={props.disabled ? true : false}
        name={field.name}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
        className="py-2 px-3 mt-2 rounded focus:ring-0 border-gray-300 outline-none bg-transparent w-full"
      />
    </div>
  );
};

export default FormikTextField;
