import React from "react";
import { ReactNode } from "react";
import "./styles.scss";

const Card: React.FC<{
  title: string;
  icon?: any;
  bgColor?: string;
  children: ReactNode;
}> = ({ title, icon, children, bgColor }) => {
  return (
    <div className={`amagi-card bg-${bgColor ? bgColor : "white"} border`}>
      <div className="amagi-card-header">
        <h3 className="amagi-card-title">{title}</h3>
        <span>{icon}</span>
        {/* {icon && <img className='amagi-card-icon' src={icon} alt={title} />} */}
      </div>
      <div className="amagi-card-body">{children}</div>
    </div>
  );
};

export default Card;
