import React from "react";
import { useField } from "formik";

const FormikSwitch: React.FC<any> = ({ inlinelabel, options, ...props }) => {
  const _field = useField(props);
  const field = _field[0];
  const { setValue } = _field[2];
  return (
    <div className={`flex mt-2 r-switch ${inlinelabel ? "inline-label" : ""}`}>
      <label>{props.label}</label>
      <div className="flex justify-between items-center">
        <input
          id={`${field.name}_yes`}
          name={field.name}
          type="radio"
          onClick={() => setValue(true)}
          defaultChecked={field.value}
          className="checked:bg-black focus:ring-0"
          style={{ border: "2px solid black", width: "20px", height: "20px" }}
        />
        <label htmlFor={`${field.name}_yes`} className="ml-2 font-semibold">
          Yes
        </label>
      </div>
      <div className="flex justify-between items-center ml-3">
        <input
          id={`${field.name}_no`}
          name={field.name}
          type="radio"
          onClick={() => setValue(false)}
          defaultChecked={field.value !== null && !field.value}
          className="checked:bg-black focus:ring-0"
          style={{ border: "2px solid black", width: "20px", height: "20px" }}
        />
        <label htmlFor={`${field.name}_no`} className="ml-2 font-semibold">
          No
        </label>
      </div>
    </div>
  );
};

export default FormikSwitch;
