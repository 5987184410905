export const genreOptions = [
  "Documentaries",
  "Entertainment",
  "Kids",
  "Lifestyle",
  "Music",
  "News",
  "Religion",
  "Sports"
  ];

export const subGenreOptions: string[] = [
 "Action",
"Action Sports",
"Adventure",
"Adventure Sport",
"African American",
"African Entertainment",
"Animal Sports",
"Animals",
"Animation",
"Anime",
"Architecture",
"Arts",
"Arts and Crafts",
"Aspirational",
"Automobile",
"Aviation",
"Bare Knuckle Boxing",
"Baseball",
"Basketball",
"Beauty",
"Billiards",
"Black Culture",
"Black Stories",
"Bollywood",
"Boxing",
"Broadway Theater",
"Business",
"Business News",
"Camp Classics",
"Cartoons",
"Celebrity News",
"Celebrity Stories",
"Christmas",
"Classic Game Shows",
"Classic Movies",
"Classic TV",
"Classical Music",
"Collectives",
"Combat Sports",
"Comedy",
"Comics",
"Concert",
"Cooking",
"Cornhole",
"Country Music",
"Crime",
"Cult Programming",
"Culture",
"Current Affairs",
"Dance",
"Dance Anthem",
"Darts",
"Design",
"Digital Sports",
"Do It Yourself",
"Documentaries",
"Dog Shows",
"Drama",
"Drawing",
"DYI",
"E-Sports",
"Educational",
"Entertainment",
"Entrepreneurship",
"Environment",
"Escape to the Country",
"Factual Entertainment",
"Faith & Religion",
"Family Friendly",
"Fan Favorite",
"Fantasy",
"Fashion",
"Fashion and Celebrity - stories and highlights",
"Finance",
"Fishing",
"Fitness",
"Food",
"Football",
"Forensic Science",
"Game show",
"Games",
"Gaming",
"Gemology",
"Golf",
"Grand Designs",
"Grappling",
"Health",
"Heritage",
"Hip-hop",
"Hispanic Culture",
"History",
"Hobbies",
"Hockey",
"Home & Garden",
"Home Improvement",
"Horror",
"Hunting",
"Indie",
"Infotainment",
"Inspirational",
"Investigation",
"Italian Culture",
"K-Pop",
"Kickboxing",
"Kids",
"Kung Fu",
"Learning",
"Legal Reporting",
"LGBTQ",
"Lifestyle",
"Light Sports",
"Live Action",
"Local News",
"Lounge Music",
"Martial arts",
"Mens fashion",
"Mini Movies",
"Mini Series",
"MMA",
"Motivational and Uplifting",
"Motorsports",
"Movie Trailers",
"Movie/Series reviews",
"Movies",
"Music",
"Music Bios",
"Mystery",
"Natural Sciences",
"Nature",
"News",
"Observational Documentary",
"Obstacle Races",
"Opinion",
"Original Movies",
"Other",
"Outdoor",
"Outdoor Sports",
"Padel",
"Performances",
"Personal Development / Self help",
"Pets & Animals",
"Pickleball",
"Poker",
"Police Procedural",
"Pop",
"Pop Culture",
"Pranks",
"Property Ladder",
"Punjabi",
"Quiz Show",
"Real Estate",
"Reality Shows",
"Relaxation",
"Retro",
"Rock",
"Romance",
"RomComs",
"Science & Technology",
"Science Fiction",
"Shopping",
"Sitcom",
"Skateboarding",
"Sketch Comedy",
"Soap Opera",
"Social Cause",
"Social Justice",
"Space",
"Specials & Variety",
"Sport celebrities",
"Sports",
"Sports News",
"Suspense",
"Talk & Interview TV",
"Talk Show",
"Technology",
"Telenovel",
"Tennis",
"Thriller",
"Tourism",
"Track Racing",
"Trading",
"Travel",
"Trials",
"True Crime",
"Turkish Drama",
"TV Series",
"Updates",
"Urban Lifestyle",
"Variety",
"Video Games",
"Weather News",
"Wellbeing",
"Western",
"Wildlife",
"Women Centric",
"Womens sports",
"Wrestling",
"Yoga and Meditation"

];

