import React from "react";
import "./styles.scss";

const CountCard: React.FC<{
  count: string | any;
  cardText?: string;
  icon?: string;
  subText?: string;
}> = ({ count, cardText, icon, subText }) => {
  return (
    <div
      className="bg-white px-5 py-3 flex h-full items-center rounded-md"
      style={{ border: "1px solid #E0E0E0" }}
    >
      <div>
        {icon && <img src={icon} alt={cardText} className="data-card-icon" />}
      </div>
      <div className="ml-3 flex flex-col">
        <span className="text-sm text-gray-600">{cardText}</span>
        <span className="text-2xl font-bold">{count}</span>
        <span className="" style={{ fontSize: 9 }}>
          {subText}
        </span>
      </div>
    </div>
  );
};

export default CountCard;
