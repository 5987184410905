/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  SortDescendingIcon,
  SortAscendingIcon,
  MenuAlt3Icon,
  // ChevronLeftIcon,
  // ChevronRightIcon,
} from "@heroicons/react/solid";
import {
  Column,
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useFilters,
  ColumnGroup,
  UsePaginationInstanceProps,
  UseSortByInstanceProps,
  UsePaginationState,
  TableInstance,
  Row,
} from "react-table";
import moment from "moment";
import ReactTooltip, { Place } from "react-tooltip";
import DelayedToolTip from "../delayed-tooltip";
import ExportCSV from "../exportComponents/exportCSV";

export type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
  };

const Table: React.FC<{
  columns: Column<ColumnGroup>[];
  rowData: Array<any>;
  placeholder: string;
  tooltipAlign: Place;
}> = ({ columns, rowData, tooltipAlign, placeholder }) => {
  // const [searchData, setSearchData] = useState<Array<any>>([]);
  // const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
  const [filteredRows, setFilteredRows] = useState<Array<any>>([]);
  const [checked, setChecked] = useState<Array<string>>([]);
  const defaultColumn = React.useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      multiSelect: (rows: Row<any>[], id: string | any, filterValues: any) => {
        if (filterValues.length === 0) {
          setFilteredRows([]);
          return [];
        } else {
          setFilteredRows(
            rows.filter((r) => filterValues.includes(r.values[id]))
          );
          return rows.filter((r) => filterValues.includes(r.values[id]));
        }
      },
    }),
    []
  );

  const data = rowData;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    // gotoPage,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // nextPage,
    // previousPage,
    // setPageSize,
    // state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 100, hiddenColumns: [] },
      autoResetGlobalFilter: false,
      autoResetHiddenColumns: false,
      autoResetResize: false,
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as TableInstanceWithHooks<object>;

  const exportHeaders = [
    { label: "Amagi ID", key: "amagi_id" },
    { label: "Customer Name", key: "account_name" },
    { label: "Customer Legal Name", key: "account_legal_name" },
    { label: "Customer Type", key: "customer_type" },
    { label: "Segment", key: "segment" },
    { label: "Region", key: "region" },
    { label: "Size", key: "size" },
    { label: "Category", key: "category" },
  ];

  const handleSelectRows = (id: string) => {
    if (checked.length === 1 && checked.includes(id)) {
      setSelectedRows([]);
      setChecked([]);
    } else if (checked && checked.includes(id)) {
      setSelectedRows(
        selectedRows!.filter((row, index) => index !== parseInt(id))
      );
      setChecked((prevChecked) => {
        return prevChecked.filter((v) => v !== id);
      });
    } else {
      setSelectedRows((selRows) => [...selRows, data![parseInt(id)]]);
      setChecked((prevChecked) => [...prevChecked, id]);
    }
  };

  const handleSelectAllRows = () => {
    if (checked.length === rows.length) {
      setChecked([]);
      setSelectedRows([]);
    } else {
      if (filteredRows.length > 0) {
        setChecked(filteredRows.map((row) => row.id));
        setSelectedRows(
          filteredRows!.map((fRows) => {
            let temp = data!.find((row, index) => index === parseInt(fRows.id));
            return temp;
          })
        );
      } else {
        setChecked(rows.map((row) => row.id));
        setSelectedRows(
          rows!.map((fRows) => {
            let temp = data!.find(
              (data, index) => index === parseInt(fRows.id)
            );
            return temp;
          })
        );
      }
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <div className="bg-white">
      <div>
        <div className="flex justify-between items-center"></div>
        <div className="custom_container py-2 flex justify-between items-center px-3">
          <span className="text-lg font-medium">Customers ({rows.length})</span>
          <div className="flex">
            {checked.length > 0 && (
              <div className="ml-3">
                <DelayedToolTip title="Download selected columns">
                  <ExportCSV
                    csvData={selectedRows}
                    csvHeaders={exportHeaders}
                    fileName={`CDP_Customers_${moment().format(
                      "YYYYMMDDHHmmss"
                    )}.csv`}
                  />
                </DelayedToolTip>
              </div>
            )}
          </div>
        </div>
      </div>
      <ReactTooltip
        className="react-tooltip"
        place={tooltipAlign}
        type="dark"
        effect="solid"
      />
      <div style={{ width: "100%" }}>
        <div className="table-container">
          <table
            {...getTableProps()}
            style={{ width: "100%" }}
            className="min-w-full divide-y divide-gray-200 border"
          >
            <thead
              style={{ display: "table", width: "100%", tableLayout: "fixed" }}
              className="bg-gray-50"
            >
              {headerGroups.map((headerGroup: any, index: number) => (
                <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                  <th
                    className="text-left font-medium w-10"
                    style={{ fontSize: "12px", color: "#47536B" }}
                  >
                    <span>
                      <input
                        type="checkbox"
                        className="focus:ring-0"
                        onChange={handleSelectAllRows}
                        checked={
                          filteredRows.length === 0
                            ? checked.length === rows.length
                            : checked.length === filteredRows.length
                        }
                      />
                    </span>
                  </th>
                  {headerGroup.headers.map((column: any, hIndex: number) => (
                    <th
                      key={hIndex}
                      scope="col"
                      className={`py-3.5 text-left text-sm text-gray-500 sm:pl-6 ${
                        column.render("Header") === "CUSTOMER"
                          ? "w-80"
                          : column.render("Header") === "CUSTOMER TIER"
                          ? "w-44"
                          : column.render("Header") === "CUSTOMER TYPE" &&
                            "w-52"
                      }`}
                      style={{ fontSize: "12px" }}
                    >
                      <div className="flex">
                        <span className="text-center">
                          {column.render("Header")}
                        </span>
                        {/* <div className="ml-1">
                          <DelayedToolTip title="filter">
                            {column.canFilter ? column.render("Filter") : null}
                          </DelayedToolTip>
                        </div> */}
                        <span
                          className="mt-px ml-1"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <SortDescendingIcon className="h-4 w-4" />
                            ) : (
                              <SortAscendingIcon className="h-4 w-4" />
                            )
                          ) : (
                            <MenuAlt3Icon className="h-4 w-4" />
                          )}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              style={{
                display: "block",
                // maxHeight: window.innerHeight - 310,
                // overflow: "auto",
              }}
              className="divide-y divide-gray-200 bg-white"
            >
              {page.map((row: any) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    style={{
                      display: "table",
                      width: "100%",
                      tableLayout: "fixed",
                    }}
                  >
                    <td className="text-left w-10">
                      <span>
                        <input
                          type="checkbox"
                          className="focus:ring-0"
                          onChange={() => handleSelectRows(row.id)}
                          checked={checked.includes(row.id)}
                        />
                      </span>
                    </td>
                    {row.cells.map((cell: any) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`text-left text-sm whitespace-nowrap py-2 pr-3 font-medium text-gray-900 ${
                            cell.render("Header") === "CUSTOMER"
                              ? "w-80"
                              : cell.render("Header") === "CUSTOMER TYPE" &&
                                "w-52"
                          }`}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing{" "}
                <span className="font-medium">{pageIndex * pageSize + 1}</span>{" "}
                to{" "}
                <span className="font-medium">
                  {page.length < pageSize
                    ? rows.length
                    : (pageIndex + 1) * page.length}
                </span>{" "}
                of <span className="font-medium">{rows.length}</span> results
              </p>
            </div>
            <div className="flex sm:items-center sm:justify-between">
              <span className="mr-3 text-sm text-gray-700">Rows per page</span>
              <select
                className="block w-max pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <nav
                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                aria-label="Pagination"
              >
                <DelayedToolTip title="Previous page" position="top">
                  <button
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium ${
                      canPreviousPage
                        ? "text-gray-500 hover:bg-gray-50"
                        : "text-gray-200 border-r-0"
                    }`}
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </DelayedToolTip>
                {pageOptions
                  .slice(
                    pageIndex >= 1 ? pageIndex - 1 : pageIndex,
                    pageIndex >= 1 ? pageIndex + 2 : pageIndex + 3
                  )
                  .map((page: number) => (
                    <button
                      key={page}
                      onClick={() => gotoPage(page)}
                      className={`z-10 border m-0 ${
                        page === pageIndex
                          ? "bg-indigo-50 border-indigo-500 text-indigo-600"
                          : "bg-white border-gray-300 border-l-0 text-gray-500 hover:bg-gray-50"
                      } ${
                        page < pageIndex ? "border-r-0" : ""
                      } relative inline-flex items-center px-4 py-2 text-sm font-medium`}
                    >
                      {page + 1}
                    </button>
                  ))}
                <DelayedToolTip title="Next page" position="top">
                  <button
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium ${
                      canNextPage
                        ? "text-gray-500 hover:bg-gray-50"
                        : "text-gray-200"
                    }`}
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </DelayedToolTip>
              </nav>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Table;
