import { InformationCircleIcon } from "@heroicons/react/outline";
import React from "react";
import { CSVLink } from "react-csv";
import { toast } from "react-hot-toast";

const ExportCSV: React.FC<{
  csvHeaders: Array<any>;
  csvData: Array<any>;
  fileName: string;
  warning?: string;
}> = ({ csvHeaders, csvData, fileName, warning = "rows" }) => {
  const handleEmptyDownload = () => {
    if (csvData.length === 0)
      // return toast.error(warning || "Select rows to download");
      return toast.custom((t) => (
        <span className="flex items-center bg-secPrimary border border-primaryColor p-4 rounded-md shadow-xl">
          <InformationCircleIcon className="h-5 w-5 text-yellow-500" />
          <span className="ml-3 font-medium text-primaryColor">
            {`Select ${warning} for Download`}
          </span>
        </span>
      ));
  };

  return (
    <>
      {csvData.length > 0 ? (
        <CSVLink headers={csvHeaders} data={csvData} filename={fileName}>
          <img
            src="/assets/download.svg"
            alt="download"
            className="h-full"
            onClick={handleEmptyDownload}
          />
        </CSVLink>
      ) : (
        <img
          src="/assets/download.svg"
          alt="download"
          className="h-full"
          onClick={handleEmptyDownload}
        />
      )}
    </>
  );
};

export default ExportCSV;
