import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import React, { ReactNode, useState } from "react";

const CustomerCard: React.FC<{
  title?: string;
  children: ReactNode;
  isSubSection?: boolean;
  isToggle?: boolean;
}> = ({ title, children, isSubSection = false, isToggle = false }) => {
  const [show, setShow] = useState<boolean>(false);
  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="font-medium mb-3">{title}</div>
        {isToggle ? (
          <div
            className="border p-1 rounded bg-white flex items-center mb-2"
            onClick={() => setShow(!show)}
          >
            {show ? (
              <ChevronUpIcon className="h-5 w-5" />
            ) : (
              <ChevronDownIcon className="h-5 w-5" />
            )}
          </div>
        ) : null}
      </div>
      {isToggle ? (
        <>
          {show ? (
            <div
              className={`bg-white ${isSubSection ? "py-5" : "p-5"} rounded-lg`}
            >
              {children}
            </div>
          ) : (
            <div className="border-b border-gray-300"></div>
          )}
        </>
      ) : (
        <div className={`bg-white ${isSubSection ? "py-5" : "p-5"} rounded-lg`}>
          {children}
        </div>
      )}
    </div>
  );
};

export default CustomerCard;
