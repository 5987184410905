import React, { useMemo } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import { useCDM } from "../../cdm-context";
import NestedToggleTableContainer from "../../common/nested-table-toggle-container";
import { CustomerContactsDataFragment } from "../../hasura.graphql";
import DelayedToolTip from "../delayed-tooltip";

const Contacts: React.FC<CustomerContactsDataFragment> = ({ contacts }) => {
  const { show } = useCDM();
  const filteredContacts = contacts.map((con) => {
    return {
      title: con.title ? con.title : "---",
      first_name: con.first_name ? con.first_name : "---",
      last_name: con.last_name ? con.last_name : "---",
      email_id: con.email_id ? con.email_id : "---",
      work_phone_number: con.work_phone_number ? con.work_phone_number : "---",
    };
  });

  filteredContacts.sort((a, b) => {
    if (a.first_name! < b.first_name!) return -1;
    else return 1;
  });

  const columns: any = useMemo(
    () => [
      {
        Header: "TITLE",
        accessor: "title",
        Cell: ({ cell }: any) => (
          <DelayedToolTip title={cell.row.original.title} position="top">
            <span className="inline-block">
              {cell.row.values.title && cell.row.values.title.length > 30
                ? `${cell.row.values.title.slice(0, 30)}...`
                : cell.row.values.title || "--"}
            </span>
          </DelayedToolTip>
        ),
      },
      {
        Header: "FIRST NAME",
        accessor: "first_name",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.first_name ? cell.row.values.first_name : "---"}
          </span>
        ),
      },
      {
        Header: "LAST NAME",
        accessor: "last_name",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.last_name ? cell.row.values.last_name : "---"}
          </span>
        ),
      },
      {
        Header: "EMAIL",
        accessor: "email_id",
        Cell: ({ cell }: any) => (
          <div>
            <div className="flex">
              <span className="mr-3">
                {cell.row.values.email_id ? cell.row.values.email_id : "---"}
              </span>
              {cell.row.values.email_id && (
                <DelayedToolTip title="copy">
                  <CopyToClipboard text={cell.row.values.email_id}>
                    <img
                      src="/assets/file-copy.svg"
                      onClick={() => toast.success("Copied")}
                      alt="file-copy"
                    />
                  </CopyToClipboard>
                </DelayedToolTip>
              )}
            </div>
          </div>
        ),
      },
      {
        Header: "PHONE NUMBER",
        accessor: "work_phone_number",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.work_phone_number
              ? cell.row.values.work_phone_number
              : "---"}
          </span>
        ),
      },
    ],
    []
  );

  const exportHeaders = columns.map((col: any) => {
    return {
      label: col.Header,
      key: col.accessor,
    };
  });

  return (
    <div className="text-primaryText">
      <Toaster />
      {filteredContacts.length > 0 ? (
        <NestedToggleTableContainer
          open=""
          header={`Contacts (${filteredContacts.length})`}
          height={`${window.innerHeight - 265}px`}
          placeholder="Search for contacts"
          warning="Contacts"
          overFlow={false}
          columns={columns}
          exportData={filteredContacts}
          exportHeader={exportHeaders}
          fileName={`${show?.amg_id}_Contacts`}
          data={filteredContacts}
        />
      ) : (
        <div className="p-4 mt-4 bg-white border rounded-md italic font-medium">
          No Records Found
        </div>
      )}
    </div>
  );
};

export default Contacts;
