import { useState } from "react";
import { classNames } from "../tabs";
import MRR from "./mrr";
import ARR from "./arr";

const CustomerMRR = () => {
  const [toggleTab, setToggleTab] = useState<string>("MRR");

  return (
    <div>
      <div className="flex w-full justify-center items-center">
        <div
          className={classNames(
            toggleTab === "MRR"
              ? "bg-white border border-gray-300 border-b-0"
              : "rounded-md bg-gray-300 rounded-b-none border-b border-gray-300",
            "w-1/2 whitespace-nowrap text-center p-2 px-6 rounded-md rounded-b-none font-medium text-sm cursor-pointer"
          )}
          onClick={() => setToggleTab("MRR")}
        >
          MRR
        </div>
        <div
          className={classNames(
            toggleTab === "ARR"
              ? "bg-white border border-gray-300 border-b-0"
              : "rounded-md bg-gray-300 rounded-b-none border-b border-gray-300",
            "w-1/2 whitespace-nowrap p-2 text-center px-6 rounded-md rounded-b-none font-medium text-sm cursor-pointer"
          )}
          onClick={() => setToggleTab("ARR")}
        >
          ARR
        </div>
      </div>
      {toggleTab === "MRR" && <MRR />}
      {toggleTab === "ARR" && <ARR />}
    </div>
  );
};

export default CustomerMRR;
