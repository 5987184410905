import { ChevronRightIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCDPRole } from "../../../cdp-role-context";
import Loader from "../../../common/loader";
import { usePlatformByPkQuery } from "../../../hasura.graphql";
import { ModalType } from "../../../pages/channels";
import Modal from "../../modal";
import PlatformEditForm from "../platform-edit-form";
import PlatformBusinessFields from "./business-fields";
import PlatformTechnicalSpecs from "./technical-specs";
import SubTabs from "../../sub-tabs";

const PlatformViewForm: React.FC<{
  platformId: string;
  isModal: boolean;
  header?: string;
  url?: string;
}> = ({ platformId, isModal, header, url }) => {
  const navigate = useNavigate();
  const [platformTab, setPlatformTab] = useState("Business Specs");
  const [editForm, setEditForm] = useState<ModalType | null>(null);
  const { CDPRole } = useCDPRole();

  const { data, loading, error } = usePlatformByPkQuery({
    variables: {
      _id: platformId,
    },
  });

  if (loading) {
    return (
      <div className={`${!isModal && "mx-6 mt-4"}`}>
        <Loader />
      </div>
    );
  }
  if (error) {
    return <i>Error</i>;
  }
  if (!data) {
    return <i>Platform data not available</i>;
  }

  const platformData = data.platform;

  return (
    <div className={`${!isModal && "mx-6"}`}>
      {isModal ? (
        <>
          <div className="mb-2 text-sm sticky bg-secondaryBg top-12 z-20 flex justify-between">
            <div>
              <SubTabs
                customStyles=""
                currentTab={platformTab}
                tabList={[
                  { name: "Business Specs" },
                  { name: "Technical Specs" },
                ]}
                toggleTab={setPlatformTab}
              />
            </div>
            {CDPRole.includes("channel-edit") && (
              <div className="">
                <button
                  type="button"
                  onClick={() =>
                    setEditForm({
                      id: platformData?.id!,
                      name: platformData?.name!,
                    })
                  }
                  className="py-1 px-3 bg-primaryColor text-white rounded-sm"
                >
                  Edit
                </button>
              </div>
            )}
          </div>
          {editForm && CDPRole.includes("channel-edit") && (
            <Modal
              title={header}
              modalWidth="100vw"
              modalShow={editForm}
              onClose={setEditForm}
            >
              <PlatformEditForm
                close={() => setEditForm(null)}
                platformId={platformData?.id!}
                isModal={true}
                header={header}
                url={url}
              />
            </Modal>
          )}
        </>
      ) : (
        <>
          <div className="sticky top-14 z-10">
            <div className="flex justify-between items-center sticky top-14 h-14 w-full bg-secondaryBg">
              <div className="flex items-center">
                <span
                  onClick={() => navigate(`${url}`)}
                  className="font-medium text-gray-600 cursor-pointer hover:text-blue-500 hover:underline"
                >
                  {header}
                </span>
                <ChevronRightIcon className="h-5 w-5 mx-3 text-gray-400" />
                <span className="text-gray-800">{platformData?.name}</span>
              </div>
              {CDPRole.includes("channel-edit") && (
                <div className="">
                  <button
                    type="button"
                    onClick={() => navigate(`${url}/${platformId}/edit`)}
                    className="py-1 px-3 bg-primaryColor text-white rounded-sm"
                  >
                    Edit
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="mb-4 sticky top-28 z-20">
            <SubTabs
              customStyles=""
              currentTab={platformTab}
              tabList={[
                { name: "Business Specs" },
                { name: "Technical Specs" },
              ]}
              toggleTab={setPlatformTab}
            />
          </div>
        </>
      )}
      <div className="w-full">
        {platformTab === "Business Specs" && (
          <PlatformBusinessFields platformData={platformData!} />
        )}
        {platformTab === "Technical Specs" && (
          <PlatformTechnicalSpecs platformData={platformData!} />
        )}
      </div>
    </div>
  );
};

export default PlatformViewForm;
