import React from "react";
import DelayedToolTip from "../components/delayed-tooltip";

const ToggleTableChart: React.FC<{
  toggle: string;
  setToggle: (toggle: string) => void;
}> = ({ toggle, setToggle }) => {
  return (
    <div className="flex items-center ml-3 w-2/12">
      <div
        onClick={() => setToggle("table")}
        className={`p-2 w-full h-9 flex items-center justify-center rounded-sm ${
          toggle === "table"
            ? "border-2 border-primaryColor"
            : "border-2 border-r-0 border-gray-300"
        }`}
      >
        <DelayedToolTip title="table view">
          <img src="/assets/table-icon.svg" alt="table-icon" />
        </DelayedToolTip>
      </div>
      <div
        onClick={() => setToggle("chart")}
        className={`p-2 w-full h-9 flex items-center justify-center rounded-sm ${
          toggle === "chart"
            ? "border-2 border-primaryColor"
            : "border-2 border-l-0 border-gray-300"
        }`}
      >
        <DelayedToolTip title="graph view">
          <img src="/assets/chart-icon.svg" alt="chart-icon" />
        </DelayedToolTip>
      </div>
    </div>
  );
};

export default ToggleTableChart;
