import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ChevronRightIcon, XIcon } from "@heroicons/react/outline";

const Modal: React.FC<{
  children: React.ReactNode;
  title?: string | null;
  badge?: string | null;
  modalShow?: string | any;
  onClose?: any;
  modalWidth: string;
  opacity?: string;
  isCustomer?: boolean;
}> = ({
  children,
  title,
  badge,
  onClose,
  modalShow,
  modalWidth,
  opacity = "1",
  isCustomer = false,
}) => {
  return (
    <Transition.Root show={modalShow ? true : false} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-30 inset-0 overflow-hidden"
        onClose={() => onClose(null || false)}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transform transition ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div
                className={`pointer-events-auto`}
                style={{
                  width: modalWidth,
                  opacity: opacity,
                  marginTop: 60,
                }}
              >
                <div className="flex h-full flex-col overflow-y-scroll bg-secondaryBg shadow-xl">
                  <div
                    className={`px-4 sm:px-6 ${
                      !isCustomer ? "py-3" : ""
                    } fixed z-20 w-full bg-secondaryBg`}
                  >
                    <div
                      className={`flex items-center ${
                        isCustomer ? "justify-end" : "justify-between"
                      }`}
                    >
                      {!isCustomer && (
                        <div className="flex items-center">
                          <span
                            onClick={() => onClose(null)}
                            className="font-medium text-gray-600 cursor-pointer hover:text-blue-500 hover:underline"
                          >
                            {title}
                          </span>
                          <ChevronRightIcon className="h-5 w-5 mx-3 text-gray-400" />
                          <span>{modalShow?.name}</span>
                        </div>
                      )}
                      <div
                        className={`flex h-7 items-center ${
                          isCustomer ? "absolute top-2" : "relative top-0"
                        }`}
                      >
                        <button
                          type="button"
                          className="rounded-full border p-1.5 focus:outline-none"
                          style={{
                            background: "#F1F4FE",
                            color: "#1924C1",
                          }}
                          onClick={() => onClose(null || false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-5 w-5" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`relative ${
                      isCustomer ? "" : "mt-12 px-4 sm:px-6"
                    } flex-1`}
                  >
                    {children}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
