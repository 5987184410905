import { DateTime } from "luxon";
import { useState } from "react";
import Loader from "../../common/loader";
import { useDqOverviewChannelTrendsQuery } from "../../hasura.graphql";
import DQChart from "../charts/dq-chart";
import { months } from "../utils";

const DQOverviewChannel = () => {
  const [monthRange, setMonthRange] = useState<string>("6");
  const { data, loading, error } = useDqOverviewChannelTrendsQuery({
    variables: {
      from_date: DateTime.local()
        .startOf("month")
        .minus({ months: parseInt(monthRange) })
        .toISO()
        .slice(0, 10),
    },
  });

  if (loading) return <Loader />;
  if (error) return <i>Error</i>;
  if (!data) return <i>No Platform Trend Data</i>;

  return (
    <div
      className="bg-white rounded-md pb-4 flex flex-col justify-center"
      style={{ border: "1px solid #E0E0E0" }}
    >
      <div className="flex justify-between border-b border-gray-200">
        <div className="text-left pl-4 py-2 font-medium">Channel</div>
        <select
          value={monthRange}
          onChange={(e) => setMonthRange(e.target.value)}
          className="border-0 rounded text-sm text-gray-500 focus:ring-0"
        >
          <option value={3}>Last 3 months</option>
          <option value={6}>Last 6 months</option>
          <option value={12}>Last 12 months</option>
        </select>
      </div>
      <div className="p-2 mt-2">
        <div className="flex justify-end items-center mx-2">
          <div
            style={{ width: 6, height: 6, background: "#5E81C0" }}
            className="rounded-full"
          ></div>
          <div className="mx-2 text-xs text-gray-600">New</div>
          <div className="text-sm font-medium">
            {Math.round(
              data.channel_trends[data.channel_trends.length - 1].new
            )}
          </div>
          <div
            style={{ width: 6, height: 6, background: "#B82B94" }}
            className="rounded-full ml-4"
          ></div>
          <div className="mx-2 text-xs text-gray-600">Existing</div>
          <div className="text-sm font-medium">
            {Math.round(
              data.channel_trends[data.channel_trends.length - 1].existing
            )}
          </div>
          <div
            style={{ width: 6, height: 6, background: "#F48229" }}
            className="rounded-full ml-4"
          ></div>
          <div className="mx-2 text-xs text-gray-600">Churned</div>
          <div className="text-sm font-medium">
            {Math.round(
              data.channel_trends[data.channel_trends.length - 1].churned
            )}
          </div>
        </div>
        <div className="h-48">
          <DQChart
            chartType="logarithmic"
            chartData={{
              labels: data?.channel_trends.map(
                (cust) =>
                  `${
                    months[parseInt(cust.month.slice(5)) - 1]
                  } '${cust.month.slice(2, 4)}`
              ),
              datasets: [
                {
                  type: "line" as const,
                  label: "New Channels",
                  data: data.channel_trends?.map((c) => c.new),
                  borderColor: ["#5E81C0"],
                  backgroundColor: ["#5E81C0"],
                  borderWidth: 1,
                  tension: 0.5,
                },
                {
                  type: "line" as const,
                  label: "Existing Channels",
                  data: data.channel_trends?.map((c) => c.existing),
                  borderColor: ["#B82B94"],
                  backgroundColor: ["#B82B94"],
                  borderWidth: 1,
                  tension: 0.5,
                },
                {
                  type: "line" as const,
                  label: "Churned Channels",
                  data: data.channel_trends?.map((c) => c.churned),
                  borderColor: ["#F48229"],
                  backgroundColor: ["#F48229"],
                  borderWidth: 1,
                  tension: 0.5,
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DQOverviewChannel;
