import { useState } from "react";
import { ChanelFieldsFragment } from "../../../hasura.graphql";
import FormikSwitch from "../../formik/switch";
import ToggleBox from "../../toggle-box";
import { ChannelEditValuesType } from "./types";

const ChannelLegalRightsForm: React.FC<{
  channelData: ChanelFieldsFragment;
  values: ChannelEditValuesType;
}> = ({ channelData, values }) => {
  const [hideTabs, setHideTabs] = useState<Array<string>>([]);

  const handleHideTabs = (tab: string) => {
    setHideTabs((hideTabs) => [...hideTabs, tab]);
  };
  const handleUnhideTabs = (tab: string) => {
    setHideTabs(hideTabs.filter((f: string) => f !== tab));
  };
  return (
    <div>
      <ToggleBox
        header="Channel Legal Rights"
        columns={hideTabs}
        handleHideColumns={handleHideTabs}
        handleUnhideColumns={handleUnhideTabs}
      >
        <>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Subtitling</label>
              <FormikSwitch
                name="subtitling"
                defaultValue={values.subtitling}
              />
            </div>
            <div>
              <label className="text-gray-400">Closed captioning</label>
              <FormikSwitch
                name="closed_captioning"
                defaultValue={values.closed_captioning}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Dubbing</label>
              <FormikSwitch name="dubbing" defaultValue={values.dubbing} />
            </div>
            <div>
              <label className="text-gray-400">Sponsorship</label>
              <FormikSwitch
                name="sponsorship"
                defaultValue={values.sponsorship}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">
                Trademarks & Logos of content partner
              </label>
              <FormikSwitch
                name="trademark_and_logo"
                defaultValue={values.trademark_and_logo}
              />
            </div>
            <div>
              <label className="text-gray-400">
                Create promotional / marketing material
              </label>
              <FormikSwitch
                name="create_promotional"
                defaultValue={values.create_promotional}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">
                Edit promotional / marketing material provided by content owner
              </label>
              <FormikSwitch
                name="edit_promotional"
                defaultValue={values.edit_promotional}
              />
            </div>
            <div>
              <label className="text-gray-400">Create short form videos</label>
              <FormikSwitch
                name="create_short_form_video"
                defaultValue={values.create_short_form_video}
              />
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Create pop-channels</label>
              <FormikSwitch
                name="create_pop_channel"
                defaultValue={values.create_pop_channel}
              />
            </div>
            <div>
              <label className="text-gray-400">
                Use of display technology (AR/VR)
              </label>
              <FormikSwitch
                name="use_of_display_technology"
                defaultValue={values.use_of_display_technology}
              />
            </div>
          </div>
        </>
      </ToggleBox>
    </div>
  );
};

export default ChannelLegalRightsForm;
