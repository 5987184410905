/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import {
  Column,
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useFilters,
  ColumnGroup,
  UsePaginationInstanceProps,
  UseSortByInstanceProps,
  UsePaginationState,
  TableInstance,
  Row,
} from "react-table";
import moment from "moment";
import { CustomerFilter } from "./customer-filter";
import ExportCSV from "../components/exportComponents/exportCSV";
import ReactTooltip, { Place } from "react-tooltip";
import DelayedToolTip from "../components/delayed-tooltip";
import { Toaster } from "react-hot-toast";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { Popover, Transition } from "@headlessui/react";
import {
  contentWidth,
  tableCellCheckboxStyle,
  tableExcludeCellStyle,
  tableExcludeHeaderStyle,
  tableHeaderStyle,
} from "../components/utils";

export type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
  };

const CustomTable: React.FC<{
  columns: Column<ColumnGroup>[];
  rowData: Array<any>;
  placeholder: string;
  tooltipAlign: Place;
}> = ({ columns, rowData, tooltipAlign, placeholder }) => {
  const [searchData, setSearchData] = useState<Array<any>>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
  const [filteredRows, setFilteredRows] = useState<Array<any>>([]);
  const [checked, setChecked] = useState<Array<string>>([]);
  const defaultColumn = React.useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      multiSelect: (rows: Row<any>[], id: string | any, filterValues: any) => {
        if (filterValues.length === 0) {
          setFilteredRows([]);
          return [];
        } else {
          setFilteredRows(
            rows.filter((r) => filterValues.includes(r.values[id]))
          );
          return rows.filter((r) => filterValues.includes(r.values[id]));
        }
      },
    }),
    []
  );

  const data = searchQuery && searchQuery.length > 0 ? searchData : rowData;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    gotoPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: 100,
        hiddenColumns: [],
        sortBy:
          searchQuery.length === 0
            ? [
                {
                  id: "account_name",
                  desc: false,
                },
              ]
            : undefined,
      },
      autoResetGlobalFilter: false,
      autoResetHiddenColumns: false,
      autoResetResize: false,
      autoResetPage: searchQuery ? true : false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: true,
      autoResetFilters: false,
      autoResetRowState: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as TableInstanceWithHooks<object>;

  const exportHeaders = [
    { label: "Amagi ID", key: "amagi_id" },
    { label: "Customer Name", key: "account_name" },
    { label: "Customer Legal Name", key: "account_legal_name" },
    { label: "Customer Type", key: "customer_type" },
    { label: "Segment", key: "segment" },
    { label: "Region", key: "region" },
    { label: "Size", key: "size" },
    { label: "Category", key: "category" },
    { label: "Record Type", key: "record_type"},
    { label: "TAM", key: "tam"}
  ];

  const handleSelectRows = (id: string) => {
    if (checked.length === 1 && checked.includes(id)) {
      setSelectedRows([]);
      setChecked([]);
    } else if (checked && checked.includes(id)) {
      setSelectedRows(
        selectedRows!.filter((row, index) => index !== parseInt(id))
      );
      setChecked((prevChecked) => {
        return prevChecked.filter((v) => v !== id);
      });
    } else {
      setSelectedRows((selRows) => [...selRows, data![parseInt(id)]]);
      setChecked((prevChecked) => [...prevChecked, id]);
    }
  };

  const handleSelectAllRows = () => {
    if (checked.length === rows.length) {
      setChecked([]);
      setSelectedRows([]);
    } else {
      if (filteredRows.length > 0) {
        setChecked(filteredRows.map((row) => row.id));
        setSelectedRows(
          filteredRows!.map((fRows) => {
            let temp = data!.find((row, index) => index === parseInt(fRows.id));
            return temp;
          })
        );
      } else {
        setChecked(rows.map((row) => row.id));
        setSelectedRows(
          rows!.map((fRows) => {
            let temp = data!.find(
              (data, index) => index === parseInt(fRows.id)
            );
            return temp;
          })
        );
      }
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      <Toaster />
      <div className="">
        <div>
          <div className="flex justify-between items-center"></div>
          <div className="custom_container py-2 flex justify-between items-center">
            <span className="text-lg font-medium">
              Customers ({rows.length})
            </span>
            <div className="w-2/5 flex items-center">
              <div className="w-full">
                <CustomerFilter
                  filter={searchQuery}
                  setFilter={setSearchQuery}
                  setSearchData={setSearchData}
                  placeholder={placeholder}
                />
              </div>
              <div className="ml-3">
                <DelayedToolTip title="Download selected Customers">
                  <ExportCSV
                    csvData={selectedRows}
                    csvHeaders={exportHeaders}
                    warning="Customers"
                    fileName={`CDP_Customers_${moment().format(
                      "YYYYMMDDHHmmss"
                    )}.csv`}
                  />
                </DelayedToolTip>
              </div>
            </div>
          </div>
        </div>
        <ReactTooltip
          className="react-tooltip"
          place={tooltipAlign}
          type="dark"
          effect="solid"
        />
        <div
          style={{
            width: contentWidth,
          }}
        >
          <div
            style={{
              maxWidth: contentWidth,
              height: window.innerHeight - 210,
              overflow: "scroll",
            }}
            className="rounded-t border border-b-0 bg-lightPrimary/50"
          >
            <table {...getTableProps()} className="rounded-t border-collapse">
              <thead
                className="bg-white border-b shadow-sm"
                style={{
                  position: "sticky",
                  insetBlockStart: 0,
                  zIndex: 10,
                }}
              >
                {headerGroups.map((headerGroup: any, index: number) => (
                  <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                    <th
                      scope="col"
                      className="text-left font-semibold w-10"
                      style={tableHeaderStyle}
                    >
                      <span>
                        <input
                          type="checkbox"
                          className="focus:ring-0"
                          onChange={handleSelectAllRows}
                          checked={
                            filteredRows.length === 0
                              ? checked.length === rows.length
                              : checked.length === filteredRows.length
                          }
                        />
                      </span>
                    </th>
                    {headerGroup.headers.map((column: any, hIndex: number) => (
                      <th
                        key={hIndex}
                        scope="col"
                        className="text-left font-semibold"
                        style={tableExcludeHeaderStyle}
                      >
                        <div className="flex items-center">
                          <span
                            className={`text-center ${
                              column.isSorted && "text-primaryColor"
                            }`}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                          </span>
                          <span
                            className="mt-px ml-1"
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <ChevronDownIcon className="h-4 w-4 text-primaryColor" />
                              ) : (
                                <ChevronUpIcon className="h-4 w-4 text-primaryColor" />
                              )
                            ) : null}
                          </span>
                          {column.filter ? (
                            <div className="ml-1">
                              <DelayedToolTip title="filter">
                                {column.canFilter
                                  ? column.render("Filter")
                                  : null}
                              </DelayedToolTip>
                            </div>
                          ) : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                {...getTableBodyProps()}
                className="divide-y divide-gray-200 bg-white"
              >
                {page.map((row: any) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      <td className="text-left" style={tableCellCheckboxStyle}>
                        <span>
                          <input
                            type="checkbox"
                            className="focus:ring-0"
                            onChange={() => handleSelectRows(row.id)}
                            checked={checked.includes(row.id)}
                          />
                        </span>
                      </td>
                      {row.cells.map((cell: any) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={`text-left text-sm whitespace-nowrap py-2 text-gray-900 ${
                              cell.render("Header") === "CUSTOMER"
                                ? "w-80"
                                : cell.render("Header") === "CUSTOMER TYPE" &&
                                  "w-52"
                            }`}
                            style={tableExcludeCellStyle}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div
            className="absolute bottom-7 bg-white px-4 py-3 flex items-center justify-between border border-gray-200 sm:px-6"
            style={{ width: contentWidth }}
          >
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">
                    {pageIndex * pageSize + 1}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium">
                    {page.length < pageSize
                      ? rows.length
                      : (pageIndex + 1) * page.length}
                  </span>{" "}
                  of <span className="font-medium">{rows.length}</span> results
                </p>
              </div>
              <div className="flex sm:items-center sm:justify-between">
                <span className="mr-3 text-sm text-gray-700">
                  Rows per page
                </span>
                <Popover as="div" className="relative items-center max-w-sm">
                  {({ open, close }) => (
                    <>
                      <Popover.Button className="rounded-full w-full focus:outline-none">
                        <div
                          placeholder={"Enter Text"}
                          className="flex justify-between border rounded px-3 py-1.5 items-center text-left focus:ring-0 border-gray-300 outline-none bg-transparent w-full"
                        >
                          <span>{pageSize}</span>
                          <span className="ml-2">
                            <ChevronDownIcon className="h-4 w-4" />
                          </span>
                        </div>
                      </Popover.Button>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Popover.Panel className="origin-top-right text-left z-10 absolute bottom-0 right-0 min-w-full max-h-60 overflow-y-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {() => (
                            <div className="">
                              {[10, 20, 30, 40, 50, 100].map((inp, rIndex) => {
                                return (
                                  <div key={rIndex}>
                                    <div
                                      className={`focus:ring-0 cursor-pointer hover:bg-blue-100 px-5 py-1 ${
                                        pageSize === inp ? "bg-blue-100" : ""
                                      }`}
                                      onClick={() => {
                                        close();
                                        setPageSize(Number(inp));
                                      }}
                                    >
                                      {inp}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </div>
              <div>
                <nav
                  className="relative z-0 inline-flex rounded-md -space-x-px"
                  aria-label="Pagination"
                >
                  {canPreviousPage && (
                    <DelayedToolTip title="Previous page" position="top">
                      <button
                        onClick={() => previousPage()}
                        className={`relative inline-flex items-center px-2 py-2 rounded-l-md bg-white text-sm font-medium text-primaryColor`}
                      >
                        <span className="sr-only">Previous</span>
                        <ChevronLeftIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>
                    </DelayedToolTip>
                  )}
                  {pageOptions
                    .slice(
                      pageIndex >= 1 ? pageIndex - 1 : pageIndex,
                      pageIndex >= 1 ? pageIndex + 2 : pageIndex + 3
                    )
                    .map((page: number) => (
                      <button
                        key={page}
                        onClick={() => gotoPage(page)}
                        className={`z-10 m-0 ${
                          page === pageIndex
                            ? "text-gray-200"
                            : "bg-white border-gray-300 border-l-0 text-primaryColor hover:underline"
                        } ${
                          page < pageIndex ? "border-r-0" : ""
                        } relative inline-flex items-center px-4 py-2 text-sm font-medium`}
                      >
                        {page + 1}
                      </button>
                    ))}
                  {canNextPage && (
                    <DelayedToolTip title="Next page" position="top">
                      <button
                        onClick={() => nextPage()}
                        className={`relative inline-flex items-center px-2 py-2 rounded-r-md bg-white text-sm font-medium text-primaryColor`}
                      >
                        <span className="sr-only">Next</span>
                        <ChevronRightIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>
                    </DelayedToolTip>
                  )}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomTable;
