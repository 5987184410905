import React, { useMemo, useState } from "react";
import ProductTableContainer from "../../common/product-table";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDebounce } from "use-debounce";
// import { DateTime } from "luxon";
// import { DateRangeType } from "../financials/types";
import { useCDM } from "../../cdm-context";
import DelayedToolTip from "../delayed-tooltip";

interface ProductRevenueProps {
  channel_name: string;
  platform_name: string;
  channel_logo: string;
  logo: string;
  product_name: string;
  product_code: string;
  product_short_name: string;
  charge_type: string;
  amount: number;
}

const Products: React.FC<{ productRevenue: Array<ProductRevenueProps> }> = ({
  productRevenue,
}) => {
  const { show } = useCDM();
  const [rowId, setRowId] = useState<string | null>(null);
  const [search, setSearch] = React.useState<string>("");
  const [debouncedColumnSearch] = useDebounce(search, 100);
  // const [dateRange, setDateRange] = useState<DateRangeType>({
  //   from_date: DateTime.local().minus({ year: 1 }).toISO().slice(0, 10),
  //   to_date: DateTime.local().toISO().slice(0, 10),
  // });

  const columns: any = useMemo(
    () => [
      {
        id: "expander",
        positionStyles: "sticky left-0 bg-white",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? (
              <div className="p-px border border-primaryColor bg-primaryColor text-white rounded">
                <ChevronDownIcon className="h-5 w-5" />
              </div>
            ) : (
              <div className="p-px border rounded">
                <ChevronRightIcon className="h-5 w-5" />
              </div>
            )}
          </span>
        ),
        Cell: ({ row }: any) =>
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
            >
              {row.isExpanded ? (
                <div className="p-px border border-primaryColor bg-primaryColor text-white rounded w-max">
                  <ChevronDownIcon className="h-5 w-5" />
                </div>
              ) : (
                <div className="p-px border rounded w-max">
                  <ChevronRightIcon className="h-5 w-5" />
                </div>
              )}
            </span>
          ) : null,
      },
      {
        id: "initial",
        positionStyles: "sticky left-12 bg-white",
        Header: "Channel",
        columns: [
          {
            id: "channel",
            positionStyles: "sticky left-12 bg-white",
            Header: "",
            accessor: "channel_name",
            Cell: ({ row }: any) =>
              row.original?.channel_logo ? (
                <div
                  className="flex justify-start items-center w-full"
                  style={{ minWidth: "200px" }}
                >
                  {/* <div className="w-12 h-8">
                    <LazyLoadImage
                      className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                      src={row.original?.channel_logo}
                      alt={row.original?.channel_name}
                    />
                  </div> */}
                  <DelayedToolTip
                    position="top"
                    delay={0}
                    title={row.original.channel_name}
                  >
                    <div className="ml-2 font-medium text-left text-sm">
                      {row.original.channel_name.length > 25
                        ? `${row.original.channel_name.slice(0, 25)}...`
                        : row.original.channel_name}
                    </div>
                  </DelayedToolTip>
                </div>
              ) : null,
          },
        ],
      },
      {
        id: "initial_1",
        Header: "Products",
        positionStyles: "sticky left-60 bg-white border-r",
        columns: [
          {
            id: "product",
            positionStyles: "sticky left-60 bg-white border-r",
            Header: "",
            accessor: "product_name",
            Cell: ({ row }: any) =>
              row.canExpand ? null : (
                <div
                  style={{ minWidth: "200px" }}
                  className="text-sm flex justify-start items-center"
                >
                 <span className="w-max font-medium">
                    {`${row.original.product_code} : ${
                      row.original.short_name ? row.original.short_name : row.original.product_name
                    } ${
                      row.original.charge_type === "Usage" ? "(Rate)" : ""
                    }`}
                  </span>
                  <span className="ml-2">
                    <DelayedToolTip
                      position="top"
                      delay={0}
                      title={`${row.original.product_code}: ${row.original.product_name}`}
                    >
                      <InformationCircleIcon className="h-3 w-3 text-sky-500" />
                    </DelayedToolTip>
                  </span>
                </div>
              ),
          },
        ],
      },
      {
        id: "platforms",
        Header: "Platforms",
        columns: Array.from(
          new Set(productRevenue.map((data) => data.platform_name))
        ).map((plat, index) => {
          // let selectedPlatform = productRevenue.find(
          //   (platform) => platform.platform_name === plat
          // );
          return {
            id: `platform_${index}`,
            Header: () => (
              <div
                style={{ minWidth: "100px", maxWidth: "160px" }}
                className="flex flex-col items-center justify-start"
              >
                {/* <div className="w-12 h-8">
                  <LazyLoadImage
                    className="h-full w-full border rounded-lg object-contain bg-lightPrimary"
                    src={
                      selectedPlatform?.logo
                        ? `/${selectedPlatform.logo}`
                        : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${selectedPlatform?.platform_name}`
                    }
                    alt={selectedPlatform?.platform_name}
                  />
                </div> */}
                <span className="mt-1 text-sm">
                  {plat.length > 25 ? `${plat.slice(0, 25)}...` : plat}
                </span>
              </div>
            ),
            columnId: `platform_${index}`,
            accessor: `${plat}.total`,
            Cell: ({ cell }: any) => (
              <span className="font-medium">
                {cell.row.original[`${plat}`].total
                  ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: show?.currency,
                    }).format(cell.row.original[`${plat}`].total || 0)
                  : ""}
              </span>
            ),
          };
        }),
      },
    ],
    [productRevenue, show?.currency]
  );

  // const handleDateRange = (fromDate: string, toDate: string) => {
  //   setDateRange({
  //     from_date: fromDate,
  //     to_date: toDate,
  //   });
  // };

  const productsData = Array.from(
    new Set(productRevenue.map((p) => p.channel_name))
  ).map((channel) => {
    let selectedChannel = productRevenue.find(
      (smp) => smp.channel_name === channel
    );
    return {
      channel_name: selectedChannel?.channel_name,
      channel_logo: selectedChannel?.channel_logo,
      product_name: selectedChannel?.product_name,
      product_short_name: selectedChannel?.product_short_name,
      ...Array.from(new Set(productRevenue.map((p) => p.platform_name))).reduce(
        (accumulator, pValue: any) => {
          return {
            ...accumulator,
            [pValue]: {
              total: productRevenue
                .filter((smp) => smp.channel_name === channel)
                .filter((smp) => smp.platform_name === pValue)
                .some((d) => d.amount !== undefined)
                ? productRevenue
                    .filter((smp) => smp.channel_name === channel)
                    .filter((smp) => smp.platform_name === pValue)
                    .reduce((a: any, b: any) => a + b.amount, 0)
                    .toFixed(3)
                : undefined,
              ...Array.from(
                new Set(productRevenue.map((p) => p.product_name))
              ).reduce((accumulator, sValue: any, index) => {
                let selected = productRevenue.find(
                  (smp) =>
                    smp.channel_name === channel &&
                    smp.platform_name === pValue &&
                    smp.product_name === sValue
                );
                return {
                  ...accumulator,
                  [`${index}_${sValue}`]: selected?.amount.toFixed(3),
                };
              }, {}),
            },
          };
        },
        {}
      ),
      [`${selectedChannel?.platform_name!}_${selectedChannel?.product_name!}`]:
        productRevenue
          .filter((smp) => smp.channel_name === channel)
          .filter((smp) => smp.platform_name === selectedChannel?.platform_name)
          .filter((smp) => smp.product_name === selectedChannel?.product_name)
          .reduce((a: any, b: any) => a + b.amount, 0),
      subRows: Array.from(
        new Set(
          productRevenue
            .filter((smp) => smp.channel_name === channel)
            .map((smpl) => smpl.product_name)
        )
      ).map((product) => {
        let subProduct = productRevenue.find(
          (prod) => prod.product_name === product
        );
        return {
          name: product,
          product_name: product,
          short_name: subProduct?.product_short_name,
          charge_type: subProduct?.charge_type,
          product_code: subProduct?.product_code,
          ...Array.from(
            new Set(productRevenue.map((p) => p.platform_name))
          ).reduce((accumulator, pValue: any) => {
            return {
              ...accumulator,
              [pValue]: {
                total: productRevenue
                  .filter((smp) => smp.channel_name === channel)
                  .filter((smp) => smp.platform_name === pValue)
                  // .find((smp) => smp.product_name === product)
                  // ?.amount.toFixed(3),
                  .filter((smp) => smp.product_name === product)
                  .reduce((acc: any, val: any) => acc + val.amount, 0),
              },
            };
          }, {}),
        };
      }),
    };
  });

  const debouncedProducts = productsData.filter((prod) => {
    if (!debouncedColumnSearch) {
      return true;
    }
    return `${prod.channel_name}`!
      .toLowerCase()
      .includes(debouncedColumnSearch.toLowerCase());
  });

  return (
    <div>
      {productRevenue.length > 0 ? (
        <ProductTableContainer
          rowId={rowId}
          setRowId={setRowId}
          columns={columns}
          data={debouncedProducts}
          search={search}
          setSearch={setSearch}
          // handleDateRange={handleDateRange}
          // dateRange={dateRange}
        />
      ) : (
        <div className="p-4 mt-4 bg-white border rounded-md italic font-medium">
          No Products
        </div>
      )}
    </div>
  );
};

export default Products;
