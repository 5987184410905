import React from "react";
import {
  Column,
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
  ColumnGroup,
  useFilters,
  // Row,
} from "react-table";
import { TableInstanceWithHooks } from "./custom-table";
// import TableFilter from "./table-filter";
// import PopoverMenu from "../components/popover-menu";
// import ExportCSV from "../components/exportComponents/exportCSV";
// import moment from "moment";
import { contentWidth } from "../components/utils";
import { useCDM } from "../cdm-context";
import getSymbolFromCurrency from "currency-symbol-map";

export type ExportHeaderType = {
  label: string;
  key: string;
};

const TableContainer: React.FC<{
  placeholder?: string;
  columns: Column<ColumnGroup>[];
  header?: string;
  data: any;
  exportData?: Array<any>;
  exportHeader?: Array<ExportHeaderType>;
  amagiId?: string;
  fileName?: string;
  showPagination?: boolean;
  outstanding: number | null;
  charges: number | null;
  payment: number | null;
  balance: number | null;
  credits: number | null;
}> = ({ columns, data, outstanding, charges, payment, credits, balance }) => {
  const { show } = useCDM();
  // const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
  // const [filteredRows, setFilteredRows] = useState<Array<any>>([]);
  // const [checked, setChecked] = useState<Array<string>>([]);
  const defaultColumn = React.useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  // const filterTypes = React.useMemo(
  //   () => ({
  //     multiSelect: (rows: Row<any>[], id: string | any, filterValues: any) => {
  //       if (filterValues.length === 0) {
  //         setFilteredRows([]);
  //         return [];
  //       } else {
  //         setFilteredRows(
  //           rows.filter((r) => filterValues.includes(r.values[id]))
  //         );
  //         return rows.filter((r) => filterValues.includes(r.values[id]));
  //       }
  //     },
  //   }),
  //   []
  // );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        // filterTypes,
        initialState: { pageIndex: 0, hiddenColumns: [] },
        autoResetGlobalFilter: false,
        autoResetPage: false,
        autoResetExpanded: false,
        autoResetGroupBy: false,
        autoResetSelectedRows: false,
        autoResetSortBy: false,
        autoResetFilters: false,
        autoResetRowState: false,
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination
    ) as TableInstanceWithHooks<object>;

  const rightAlignCells = [
    "INVOICED AMT",
    "RECEIVED AMT",
    "BALANCE",
    "CREDITED AMT",
  ];

  return (
    <>
      {data.length > 0 ? (
        <div
          className="md:rounded-md"
          style={{
            width: contentWidth,
          }}
        >
          <div
            style={{
              maxWidth: contentWidth,
              maxHeight: `${window.innerHeight - 245}px`,
              overflow: "scroll",
            }}
            // className={`table-container`}
          >
            <table {...getTableProps()} className={`divide-y divide-gray-300`}>
              <thead
                className="bg-gray-200"
                style={{
                  position: "sticky",
                  insetBlockStart: 0,
                  zIndex: 5,
                }}
                // style={{ overflowX: "scroll", width: "100%" }}
              >
                {headerGroups.map((headerGroup: any, index: number) => (
                  <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any, hIndex: number) => (
                      <th
                        key={hIndex}
                        scope="col"
                        className="py-3.5 font-medium"
                        style={{
                          fontSize: "12px",
                          color: "#47536B",
                          padding: "10px 15px",
                          textAlign: rightAlignCells.includes(
                            column.render("Header")
                          )
                            ? "right"
                            : "left",
                        }}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                {...getTableBodyProps()}
                className="divide-y divide-gray-200 bg-white"
              >
                {outstanding ? (
                  <tr>
                    <td
                      className={`text-left whitespace-nowrap py-2 pr-3 font-normal`}
                      style={{ color: "#181D25" }}
                    ></td>
                    <td
                      className={`text-left whitespace-nowrap py-2 pr-3 font-normal`}
                      style={{ color: "#181D25" }}
                    ></td>
                    <td
                      className={`text-left whitespace-nowrap py-2 pr-3 font-medium`}
                      style={{ color: "#181D25" }}
                    >
                      Outstanding
                    </td>
                    <td
                      className={`text-left whitespace-nowrap py-2 pr-3 font-normal`}
                      style={{ color: "#181D25" }}
                    ></td>
                    <td
                      className={`text-left whitespace-nowrap py-2 pr-3 font-normal`}
                      style={{ color: "#181D25" }}
                    ></td>
                    <td
                      className={`text-left whitespace-nowrap py-2 pr-3 font-normal`}
                      style={{ color: "#181D25" }}
                    ></td>
                    <td
                      className={`text-right whitespace-nowrap py-2 pr-3 font-medium`}
                      style={{ color: "#181D25" }}
                    >
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: show?.currency || "USD",
                      }).format(outstanding!)}
                    </td>
                  </tr>
                ) : null}

                {rows.map((row: any) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell: any) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={`${
                              rightAlignCells.includes(cell.render("Header"))
                                ? "text-right"
                                : "text-left"
                            } whitespace-nowrap py-2 pr-3 font-normal`}
                            style={{ color: "#181D25" }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                <tr className="bg-gray-200 sticky bottom-0">
                  <td
                    className={`text-right whitespace-nowrap py-2 pr-3 font-medium`}
                    style={{ color: "#181D25" }}
                  ></td>
                  <td
                    className={`text-left whitespace-nowrap py-2 pr-3 font-medium`}
                    style={{ color: "#181D25" }}
                  ></td>
                  <td
                    className={`text-left whitespace-nowrap py-2 pr-3 font-medium`}
                    style={{ color: "#181D25" }}
                  >
                    GRAND TOTAL
                  </td>
                  <td
                    className={`text-right whitespace-nowrap py-2 pr-3 font-medium`}
                    style={{ color: "#181D25" }}
                  >
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: show?.currency || "USD",
                    }).format(charges!)}
                  </td>
                  <td
                    className={`text-right whitespace-nowrap py-2 pr-3 font-medium`}
                    style={{ color: "#181D25" }}
                  >
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: show?.currency || "USD",
                    }).format(payment!)}
                  </td>
                  <td
                    className={`text-right whitespace-nowrap py-2 pr-3 font-medium`}
                    style={{ color: "#181D25" }}
                  >
                    {`${getSymbolFromCurrency(
                      show?.currency || "USD"
                    )}(${credits!.toFixed(2)})`}
                  </td>
                  <td
                    className={`text-right whitespace-nowrap py-2 pr-3 font-medium`}
                    style={{ color: "#181D25" }}
                  >
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: show?.currency || "USD",
                    }).format(balance!)}
                  </td>
                </tr>
              </tbody>
            </table>
            {/* <table>
              <tbody>
                <tr className="bg-gray-200 bottom-0">
                  <td
                    className={`text-right whitespace-nowrap py-2 pr-3 font-medium`}
                    style={{ color: "#181D25" }}
                  ></td>
                  <td
                    className={`text-left whitespace-nowrap py-2 pr-3 font-medium`}
                    style={{ color: "#181D25" }}
                  ></td>
                  <td
                    className={`text-left whitespace-nowrap py-2 pr-3 font-medium`}
                    style={{ color: "#181D25" }}
                  >
                    GRAND TOTAL
                  </td>
                  <td
                    className={`text-right whitespace-nowrap py-2 pr-3 font-medium`}
                    style={{ color: "#181D25" }}
                  >
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: show?.currency || "USD",
                    }).format(charges!)}
                  </td>
                  <td
                    className={`text-right whitespace-nowrap py-2 pr-3 font-medium`}
                    style={{ color: "#181D25" }}
                  >
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: show?.currency || "USD",
                    }).format(payment!)}
                  </td>
                  <td
                    className={`text-right whitespace-nowrap py-2 pr-3 font-medium`}
                    style={{ color: "#181D25" }}
                  >
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: show?.currency || "USD",
                    }).format(balance!)}
                  </td>
                </tr>
              </tbody>
            </table> */}
          </div>
        </div>
      ) : (
        <div className="p-4 bg-white">No Records found</div>
      )}
    </>
  );
};

export default TableContainer;
