// import {
//   CheckIcon,
//   InformationCircleIcon,
//   XIcon,
// } from "@heroicons/react/outline";

interface CheckBoxType {
  paid_flag: boolean | null | undefined;
  ad_flag: boolean | null | undefined;
  promotion_flag: boolean | null | undefined;
  is_adsplus_channel: boolean | null | undefined;
  is_contentplus_channel: boolean | null | undefined;
}

type CheckBox = {
  key: string;
  value: string;
};

const CheckBoxes: CheckBox[] = [
  { key: "paid_flag", value: "Paid Flag" },
  { key: "ad_flag", value: "Ads Flag" },
  { key: "promotion_flag", value: "Promotion Flag" },
  { key: "is_adsplus_channel", value: "Ads Plus Flag" },
  { key: "is_contentplus_channel", value: "Content Plus Flag" },
];

const ViewCheckTable: React.FC<CheckBoxType> = (props) => {
  const checkbox = { ...props };
  return (
    <div className="mt-4">
      <table>
        <thead className="text-gray-400 border-0 border-b border-gray-50">
          <tr style={{ background: "#FAFAFB" }}>
            <th className="text-left py-2 pl-2 text-gray-800">Flags</th>
            <th className="text-left py-2 pl-2"></th>
            <th className="text-left py-2 pl-2"></th>
          </tr>
        </thead>
        <tbody>
          {CheckBoxes.map((cb: CheckBox, index: number) => {
            return (
              <tr key={index} className="border-0 border-b border-gray-50">
                <td className="text-left py-2 pl-2 flex">
                  <span className="mr-2">{cb.value}</span>
                  {/* <InformationCircleIcon
                    className="h-5 w-5"
                    style={{ color: "#9EA4B3" }}
                  /> */}
                </td>
                <td className="py-2">
                  <input
                    type="checkbox"
                    name={cb.key}
                    disabled
                    checked={checkbox[cb.key as keyof CheckBoxType]!}
                    className="focus:ring-0"
                  />
                </td>
                {/* <td className="py-2">
                  <CheckIcon
                    name={cb.key}
                    className={`h-4 w-4 ${
                      checkbox[cb.key as keyof CheckBoxType]
                        ? "text-gray-500"
                        : "text-gray-300"
                    }`}
                  />
                </td>
                <td className="py-2">
                  <XIcon
                    name={cb.key}
                    className={`h-4 w-4 ${
                      checkbox[cb.key as keyof CheckBoxType]
                        ? "text-gray-300"
                        : "text-gray-500"
                    }`}
                  />
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ViewCheckTable;
