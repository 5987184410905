import { InformationCircleIcon } from "@heroicons/react/outline";
import { useMemo, useState } from "react";
import { useCDM } from "../../cdm-context";
import {
  CustomerCreditMemosDataFragment,
  CustomerInvoicesDataFragment,
} from "../../hasura.graphql";
import DelayedToolTip from "../delayed-tooltip";
import { DateParser, DateSort, MonthParser, months } from "../utils";
import FinancialsChartWrapper from "./chart/chart-wrapper";
import { CheckNetsuiteSalesforcePDF } from "./file-modal";
import { InvoicesandCreditMemosType } from "./types";
// import FinLineTable from "./fin-line-table";
import FinancialTable from "./table";
import SubTableContainer from "../../common/sub-table";
import TableRowsFilter from "../../common/filters/table-rows-filter";

const FinTable: React.FC<{
  customerFinancial:
    | CustomerInvoicesDataFragment
    | CustomerCreditMemosDataFragment;
  isToggle: boolean;
  height?: number;
  header: string;
  yearFields: Array<string>;
  dateFilter: { from: string; to: string };
  setDateFilter: React.Dispatch<
    React.SetStateAction<{ from: string; to: string }>
  >;
  columns: Array<any>;
  setTransactionId: React.Dispatch<React.SetStateAction<string | null>>;
  transactionId: string | null;
  searchParam?: string;
  warning?: string;
}> = ({
  customerFinancial,
  isToggle,
  height = 425,
  header,
  yearFields,
  dateFilter,
  setDateFilter,
  columns,
  transactionId,
  setTransactionId,
  searchParam,
  warning,
}) => {
  const { financials } = customerFinancial;

  const statusMap = useMemo(
    () => [
      {
        status: "Invoice : Open",
        value: "Unpaid",
      },
      {
        status: "Invoice : Paid In Full",
        value: "Paid",
      },
      {
        status: "Credit Memo : Fully Applied",
        value: "Applied",
      },
      {
        status: "Credit Memo : Open",
        value: "Unapplied",
      },
    ],
    []
  );

  const { show } = useCDM();
  const [rowId, setRowId] = useState<string | null>("");

  const filteredInvoicesAndCredits: Array<InvoicesandCreditMemosType> = useMemo(
    () =>
      financials
        ? financials.map((fin) => {
            return {
              invoice_id: fin.invoice_id,
              invoice_date: fin.invoice_date ? fin.invoice_date : "--",
              due_date: fin.due_date ? fin.due_date : "--",
              so_po_reference_number: fin.so_po_reference_number
                ? fin.so_po_reference_number
                : "--",
              billing_period: fin.billing_period
                ? `${fin.billing_period.slice(4)}-${
                    months.findIndex(
                      (month) => month === fin.billing_period?.slice(0, 3)
                    ) < 10
                      ? `0${
                          months.findIndex(
                            (month) => month === fin.billing_period?.slice(0, 3)
                          ) + 1
                        }`
                      : months.findIndex(
                          (month) => month === fin.billing_period?.slice(0, 3)
                        ) + 1
                  }`
                : "--",
              currency: fin.currency ? fin.currency : show?.currency || "",
              invoice_amount: fin.invoice_amount ? fin.invoice_amount : 0,
              amount_due: fin.amount_due ? fin.amount_due : 0,
              status: fin.payment_status
                ? statusMap.find((sts) => sts.status === fin.payment_status)
                    ?.value || "--"
                : "--",
              root_cause_reason: fin.root_cause_reason
                ? fin.root_cause_reason
                : "--",
              type: fin.type
                ? fin.type === "creditmemo"
                  ? "credit"
                  : "invoice"
                : "--",
              fin_lines: fin.lines
                ? fin.lines.map((line) => {
                    return {
                      product_code: line.product_id
                        ? line.product_id
                        : line.product_info && line.product_info.code
                        ? line.product_info.code
                        : "--",
                      product_name: line.product
                        ? line.product
                        : line.product_info && line.product_info.name
                        ? line.product_info.name
                        : "--",
                      product_short_name:
                        line.product_info && line.product_info.short_name
                          ? line.product_info.short_name
                          : null,
                      description: line.description
                        ? line.description.replaceAll("&gt;", ">")
                        : line.product_info &&
                          line.product_info.description?.replaceAll("&gt;", ">")
                        ? line.product_info.description?.replaceAll("&gt;", ">")
                        : "--",
                      channel: line.channel ? line.channel : "--",
                      platform: line.platform ? line.platform : "--",
                      region: line.region ? line.region : "--",
                      quantity: line.quantity ? line.quantity : "--",
                      currency: fin.currency
                        ? fin.currency
                        : show?.currency || "",
                      rate: line.rate ? line.rate : 0,
                      amount: line.amount ? line.amount : 0,
                      tax_type: line.tax_type ? line.tax_type : "--",
                      tax_rate: line.tax_rate ? line.tax_rate : "--",
                      gross_amount: line.gross_amount ? line.gross_amount : 0,
                      revenue_type: line.revenue_type
                        ? line.revenue_type
                        : "--",
                      start_date: line.start_date ? line.start_date : "--",
                      end_date: line.end_date ? line.end_date : "--",
                    };
                  })
                : [],
            };
          })
        : [],
    [financials, show?.currency, statusMap]
  );

  filteredInvoicesAndCredits.sort((a, b) =>
    DateSort(b.invoice_date, a.invoice_date)
  );

  const invoice_and_credit_line_columns: any = useMemo(
    () => [
      {
        Header: "PRODUCT CODE",
        accessor: "product_code",
      },
      {
        Header: "PRODUCT DETAILS",
        accessor: "product_name",
        Cell: ({ cell }: any) => (
          <div className="flex items-center">
            <span>
              {cell.row.original.product_short_name
                ? cell.row.original.product_short_name
                : cell.row.original.product_name}
            </span>
            {cell.row.original.product_short_name && (
              <span className="ml-2">
                <DelayedToolTip
                  delay={0}
                  title={cell.row.original.product_name}
                  position="top"
                >
                  <InformationCircleIcon
                    className="w-4 h-4"
                    style={{ color: "#94ACEB" }}
                  />
                </DelayedToolTip>
              </span>
            )}
          </div>
        ),
      },
      {
        Header: "DESCRIPTION",
        accessor: "description",
        Cell: ({ cell }: any) => (
          <DelayedToolTip title={cell.row.original.description} position="top">
            <span className="inline-block">
              {cell.row.values.description &&
              cell.row.values.description.length > 30
                ? `${cell.row.values.description.slice(0, 30)}...`
                : cell.row.values.description || "--"}
            </span>
          </DelayedToolTip>
        ),
      },
      {
        Header: "CHANNEL",
        accessor: "channel",
        Filter: TableRowsFilter,
        position: "relative",
        filter: "multiSelect",
      },
      {
        Header: "PLATFORM",
        accessor: "platform",
        Filter: TableRowsFilter,
        position: "relative",
        filter: "multiSelect",
      },
      {
        Header: "REGION",
        accessor: "region",
        Filter: TableRowsFilter,
        position: "relative",
        filter: "multiSelect",
      },
      {
        Header: "QUANTITY",
        accessor: "quantity",
      },
      {
        Header: "RATE",
        accessor: "rate",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.rate !== "--"
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency:
                    filteredInvoicesAndCredits[parseInt(rowId!)].currency ||
                    show?.currency,
                }).format(cell.row.values.rate)
              : "--"}
          </span>
        ),
        textAlign: "text-right",
      },
      {
        Header: "AMOUNT",
        accessor: "amount",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.amount !== "--"
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency:
                    filteredInvoicesAndCredits[parseInt(rowId!)].currency ||
                    show?.currency,
                }).format(cell.row.values.amount)
              : "--"}
          </span>
        ),
        textAlign: "text-right",
      },
      {
        Header: "TAX TYPE",
        accessor: "tax_type",
        Filter: TableRowsFilter,
        position: "relative",
        filter: "multiSelect",
      },
      {
        Header: "TAX RATE",
        accessor: "tax_rate",
      },
      {
        Header: "GROSS AMOUNT",
        accessor: "gross_amount",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.gross_amount !== "--"
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency:
                    filteredInvoicesAndCredits[parseInt(rowId!)].currency ||
                    show?.currency,
                }).format(cell.row.values.gross_amount)
              : "--"}
          </span>
        ),
        textAlign: "text-right",
      },
      {
        Header: "REVENUE TYPE",
        accessor: "revenue_type",
        Filter: TableRowsFilter,
        position: "relative",
        filter: "multiSelect",
      },
      {
        Header: "START DATE",
        accessor: "start_date",
        sortType: (a: any, b: any) =>
          DateSort(a.values.start_date, b.values.start_date),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.start_date !== "--"
              ? DateParser(cell.row.values.start_date)
              : "--"}
          </span>
        ),
      },
      {
        Header: "END DATE",
        accessor: "end_date",
        sortType: (a: any, b: any) =>
          DateSort(a.values.end_date, b.values.end_date),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.end_date !== "--"
              ? DateParser(cell.row.values.end_date)
              : "--"}
          </span>
        ),
      },
    ],
    [filteredInvoicesAndCredits, rowId, show?.currency]
  );

  const exportInvoices = filteredInvoicesAndCredits?.map((inv) => {
    return inv.fin_lines?.map((invL) => {
      return {
        invoice_id: inv.invoice_id,
        invoice_date: inv.invoice_date ? inv.invoice_date : "--",
        due_date: inv.due_date ? inv.due_date : "--",
        so_po_reference_number: inv.so_po_reference_number
          ? inv.so_po_reference_number
          : "--",
        billing_period: inv.billing_period
          ? MonthParser(inv.billing_period)
          : "--",
        currency: inv.currency ? inv.currency : show?.currency || "",
        invoice_amount: inv.invoice_amount
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: inv.currency || show?.currency,
            }).format(inv.invoice_amount)
          : "--",
        amount_due: inv.amount_due
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: inv.currency || show?.currency,
            }).format(inv.amount_due)
          : "--",
        status: inv.status ? inv.status : "--",
        root_cause_reason: inv.root_cause_reason ? inv.root_cause_reason : "--",
        type: inv.type ? inv.type : "--",
        product_code: invL.product_code ? invL.product_code : "--",
        product_name: invL.product_name ? invL.product_name : "--",
        description: invL.description ? invL.description : "--",
        channel: invL.channel ? invL.channel : "--",
        platform: invL.platform ? invL.platform : "--",
        region: invL.region ? invL.region : "--",
        quantity: invL.quantity ? invL.quantity : "--",
        rate: invL.rate
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: show?.currency || "USD",
            }).format(invL.rate)
          : "--",
        amount: invL.amount
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: show?.currency || "USD",
            }).format(invL.amount)
          : "--",
        tax_type: invL.tax_type ? invL.tax_type : "--",
        tax_rate: invL.tax_rate ? invL.tax_rate : "--",
        gross_amount: invL.gross_amount
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: show?.currency || "USD",
            }).format(invL.gross_amount)
          : "--",
        revenue_type: invL.revenue_type ? invL.revenue_type : "--",
        start_date: invL.start_date,
        end_date: invL.end_date,
      };
    });
  });

  // lineItems.sort((a, b) => {
  //   if (a.amount > b.amount) return -1;
  //   else return 1;
  // });

  const exportHeaders = [
    { label: "Type", key: "type" },
    { label: "Transaction ID", key: "invoice_id" },
    { label: "Transaction Date", key: "invoice_date" },
    { label: "Due Date", key: "due_date" },
    { label: "SO/PO Ref", key: "so_po_reference_number" },
    { label: "Billing Period", key: "billing_period" },
    { label: "Currency", key: "currency" },
    { label: "Total Transaction Amount", key: "invoice_amount" },
    { label: "Amount Due", key: "amount_due" },
    { label: "Status", key: "status" },
    { label: "Root Cause Reason", key: "root_cause_reason" },
    { label: "Product Code", key: "product_code" },
    { label: "Product Details", key: "product_name" },
    { label: "Description", key: "description" },
    { label: "Channel", key: "channel" },
    { label: "Platform", key: "platform" },
    { label: "Region", key: "region" },
    { label: "Quantity", key: "quantity" },
    { label: "Rate", key: "rate" },
    { label: "Amount", key: "amount" },
    { label: "Tax Type", key: "tax_type" },
    { label: "Tax Rate", key: "tax_rate" },
    { label: "Gross Amount", key: "gross_amount" },
    { label: "Revenue Type", key: "revenue_type" },
    { label: "Start Date", key: "start_date" },
    { label: "End Date", key: "end_date" },
  ];

  return (
    <div>
      <div className="mt-4">
        <FinancialTable
          data={filteredInvoicesAndCredits}
          columns={columns}
          height={`${window.innerHeight - height}px`}
          chartHeight={`${window.innerHeight - 357}px`}
          open={rowId}
          setOpen={setRowId}
          exportData={exportInvoices}
          fileName={`${show?.amg_id}_${header}`}
          exportHeader={exportHeaders}
          searchParam={searchParam}
          header={header}
          warning={warning}
          isToggle={isToggle}
          yearFields={yearFields}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          subTable={
            <>
              {rowId &&
                filteredInvoicesAndCredits[parseInt(rowId!)].fin_lines.length >
                  0 && (
                  <SubTableContainer
                    header={`(${
                      filteredInvoicesAndCredits[parseInt(rowId!)].fin_lines
                        .length
                    }) ${
                      header === "Invoices" ? "Invoice" : "Credit"
                    } Lines for ${
                      filteredInvoicesAndCredits[parseInt(rowId!)].invoice_id
                    }`}
                    placeholder={`Search for line items`}
                    data={
                      rowId
                        ? filteredInvoicesAndCredits[parseInt(rowId!)].fin_lines
                        : []
                    }
                    columns={invoice_and_credit_line_columns}
                  />
                )}
            </>
          }
          chart={<FinancialsChartWrapper />}
        />
      </div>
      {transactionId && (
        <CheckNetsuiteSalesforcePDF
          setOpenModal={setTransactionId}
          transaction_id={transactionId}
        />
      )}
    </div>
  );
};

export default FinTable;
