import React, { useCallback,useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CustomerAllDataFragment, useGetRecordTypeNameQuery, useCustomerNetsuiteIdsQuery } from "../../hasura.graphql";
import CustomerCard from "../cards/customer-card";
import FolderTree from "../folderTree";
import { capitalize, DateParser } from "../utils";
import { ViewList } from "../view-list";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import DelayedToolTip from "../delayed-tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast, Toaster } from "react-hot-toast";
import CustomerLogoUpload from "../logo-upload";


const domain = window.location.origin;

const CustomerDetails: React.FC<{
  customer: CustomerAllDataFragment;
}> = ({ customer }) => {
  const [uploadedImage, setUploadedImage] = useState<string | null>(() => {
    return localStorage.getItem("uploadedLogoImage");
  });

  const [logoUploadCompleted, setLogoUploadCompleted] = useState<boolean>(false); 

  const handleLogoUploadComplete = useCallback((path: string) => {
    setUploadedImage(path);
    setLogoUploadCompleted(true); 
  }, []);


  
  const statusColorCode = (status: string) => {
    if (status === "Churned") return "text-red-500 border-red-500";
    else if (status === "Prospect")
      return "text-chartOrange border-chartOrange";
    else if (status === "Customer") return "text-chartGreen border-chartGreen";
    return "border-gray-500";
  };

  const salesforceId = customer.salesforce_record_type_id || '';
  const customer_amagi_id = customer.amagi_id || '';
 
  const record_type_query = useGetRecordTypeNameQuery({
    variables: { salesforceId },
  });

  const customer_netsuite_id_list = useCustomerNetsuiteIdsQuery({
  variables:{

    amagi_id: customer_amagi_id
   
  }
})
let netsuiteIds = "";


if (customer_netsuite_id_list.data) {
  const customerData = customer_netsuite_id_list.data.customer_by_amagi_id;

 
  const { netsuite_id, old_netsuite_Id } = customerData as {
    netsuite_id?: number;
    old_netsuite_Id: { netsuite_id?: number }[];
  };

  const allNetsuiteIds = [
    netsuite_id,
    ...old_netsuite_Id.map((item) => item.netsuite_id).filter((id) => id !== undefined),
  ];

  netsuiteIds = allNetsuiteIds.join(", ");
}

const customer_record_type = record_type_query.data?.record_type[0].name

  const getCategory = useCallback((mrr: number | null) => {
    if (mrr !== null) {
      if (mrr > 100000) {
        return "Platinum";
      } else if (mrr > 50000) {
        return "Gold";
      } else {
        return "Silver";
      }
    } else {
      return "Silver";
    }
  }, []);

  const billingContacts =
    customer.billing_contact && customer.billing_contact.length > 0
      ? customer.billing_contact.filter(
          (contact: string | null) => contact !== "null" && contact !== null
        )
      : [];

  return (
    <div className="grid gap-8">
      <Toaster />
      <div className="flex justify-center">
        <div className="w-2/3 mr-4">
          <CustomerCard title="Customer">
            <div className="overflow-y-scroll" style={{ height: 360 }}>
              <div className="flex items-start justify-between">
                <div className="flex items-start">
                <div style={{ width: 90, height: 60 }}>
                  <div className="relative" style={{ width: 90, height: 60 }}>
                    {logoUploadCompleted && uploadedImage ? (
                      <LazyLoadImage
                      className="h-full w-full rounded-lg object-contain bg-gradient-to-r from-gradientBlue to-gradientOrange"
                      src={uploadedImage ? `${domain}/${uploadedImage}` : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${customer.account_name}`}
                      alt={customer.account_name!}
                    />
                    ) : (
                      <LazyLoadImage
                        className="h-full w-full rounded-lg object-contain bg-gradient-to-r from-gradientBlue to-gradientOrange"
                        src={
                          customer.logo && customer.logo.startsWith("uploads/")
                            ? `/${customer.logo}`
                            : customer.logo
                            ? `/logos/${customer.logo}`
                            : `https://via.placeholder.com/300x200.png/D3DDFC/1924C1?text=${customer.account_name}`
                        }
                        alt={customer.account_name!}
                      />
                    )}
                    <div className="relative top-10 left-5">
                      <CustomerLogoUpload
                        amagi_id={customer_amagi_id}
                        value={customer.logo!}
                        onUpload={handleLogoUploadComplete} // Pass the handler function
                        fileType="image/*"
                      />
                    </div>
                  </div>
                </div>
                  <div className="flex flex-col ml-3 mt-1">
                    <span className="font-medium" style={{ fontSize: 15 }}>
                      {customer.account_name}
                    </span>
                    <span style={{ fontSize: 15 }}>{customer.amagi_id}</span>
                  </div>
                </div>
                <div style={{marginRight:"61%"}}>
                  <ViewList name="Status">
                    <span
                      className={`border ${statusColorCode(
                        customer.status!
                      )} rounded-full px-2 inline-flex leading-5 text-xs`}
                    >
                      {customer.status}
                    </span>
                  </ViewList>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-x-4 gap-y-8 mt-14">
                <ViewList name="Customer Record Type">
                  {customer_record_type ? customer_record_type : "-"}
                </ViewList>
                <ViewList name="Customer Since">
                  {customer.status === "Customer" &&
                  customer.customer_since_date
                    ? DateParser(customer.customer_since_date)
                    : "-"}
                </ViewList>
                <ViewList name="Customer Hierarchy">
                  {customer.parent || customer.children.length > 0 ? (
                    <FolderTree customer={customer} />
                  ) : (
                    "-"
                  )}
                </ViewList>
                <ViewList name="Phone">
                  {customer.phone ? customer.phone : "-"}
                </ViewList>
                <ViewList name="Website">
                  {customer.address && customer.address?.company_website ? (
                    <div className="flex">
                      <span>{customer.address?.company_website}</span>
                      <a
                        href={`${
                          customer.address?.company_website?.includes("http")
                            ? ""
                            : "https://"
                        }${customer.address?.company_website!}`}
                        target="_blank"
                        rel="noreferrer"
                        className="ml-2 text-xs hover:text-blue-500 hover:underline"
                      >
                        <ExternalLinkIcon className="h-5 w-5 text-blue-500" />
                      </a>
                    </div>
                  ) : (
                    "-"
                  )}
                </ViewList>
                <ViewList name="Billing Address">
                  <div className="flex flex-col">
                    <span>
                      {customer.billing_address &&
                        customer.billing_address.billing_street &&
                        customer.billing_address.billing_street}
                    </span>
                    <span>
                      {customer.billing_address &&
                        customer.billing_address.billing_city &&
                        customer.billing_address.billing_city}
                      ,{" "}
                      {customer.billing_address &&
                        customer.billing_address.billing_state_code &&
                        customer.billing_address.billing_state_code}
                    </span>
                    <span>
                      {customer.billing_address &&
                        customer.billing_address.billing_country_code &&
                        customer.billing_address.billing_country_code}
                      ,{" "}
                      {customer.billing_address &&
                        customer.billing_address.billing_postal_code &&
                        customer.billing_address.billing_postal_code}
                    </span>
                  </div>
                </ViewList>
                <ViewList name="Account Manager">
                  {customer.account_manager ? (
                    <div className="">
                      <div className="text-xs my-1 font-extrabold">
                        {customer.account_manager?.first_name}{" "}
                        {customer.account_manager?.last_name}
                      </div>
                      <div className="flex text-xs">
                        <span className="mr-3">
                          {customer.account_manager
                            ? customer.account_manager.email
                            : "-"}
                        </span>
                        {customer.account_manager && (
                          <DelayedToolTip title="copy">
                            <CopyToClipboard
                              text={customer.account_manager.email}
                            >
                              <img
                                src="/assets/file-copy.svg"
                                onClick={() => toast.success("Copied")}
                                alt="file-copy"
                              />
                            </CopyToClipboard>
                          </DelayedToolTip>
                        )}
                      </div>
                    </div>
                  ) : (
                    "-"
                  )}
                </ViewList>
                <ViewList name="Technical Account Manager">
                  <div className="text-xs my-1 font-extrabold">
                    {customer.tam || "-"}
                  </div>
                </ViewList>
              </div>
            </div>
          </CustomerCard>
        </div>
        <div className="w-1/3">
          <CustomerCard title="About Customer">
            <div className="overflow-y-scroll" style={{ height: 360 }}>
              <ViewList name="">
                <div className="text-justify mx-3">
                  {customer.description ? customer.description : "-"}
                </div>
              </ViewList>
            </div>
          </CustomerCard>
        </div>
      </div>
      <CustomerCard title="Demographics">
        <div className="grid grid-cols-4 gap-x-4 gap-y-8">
          <ViewList name="Type">
            {customer.customer_type ? customer.customer_type : "-"}
          </ViewList>
          <ViewList name="Region">
            {customer.address?.region ? customer.address?.region : "-"}
          </ViewList>
          <ViewList name="Category">
            {customer.account_category ? customer.account_category : "-"}
          </ViewList>
          <ViewList name="Segment">
            {customer.industry_segment ? customer.industry_segment : "-"}
          </ViewList>
          <ViewList name="Service Tier">
            {customer.service_tier ? capitalize(customer.service_tier) : "-"}
          </ViewList>
          <ViewList name="Platform Tier">
            {customer.platform_tier ? capitalize(customer.platform_tier) : "-"}
          </ViewList>
          <ViewList name="Tier">
            {customer.customer_tier
              ? capitalize(customer.customer_tier)
              : getCategory(
                  customer.invoices_aggregate.aggregate?.sum?.total_amount
                )}
          </ViewList>
          <ViewList name="Business Category">
            {customer.business_category ? customer.business_category : "-"}
          </ViewList>
          <ViewList name="Company Category">
            {customer.company_category ? customer.company_category : "-"}
          </ViewList>
          <ViewList name="Size">{customer.size ? customer.size : "-"}</ViewList>
          <ViewList name="Scale">
            {customer.customer_scale ? customer.customer_scale : "-"}
          </ViewList>
          <ViewList name="Employee Count">
            {customer.no_of_employees ? customer.no_of_employees : "-"}
          </ViewList>
          <ViewList name="Financial Risk Category">
            {customer.customer_financial_risk_category
              ? customer.customer_financial_risk_category
              : "-"}
          </ViewList>
        </div>
      </CustomerCard>
      <CustomerCard title="Financial and Legal">
        <div className="grid grid-cols-4 gap-x-4 gap-y-8">
          <ViewList name="Customer Legal Name">
            {customer.account_legal_name ? customer.account_legal_name : "-"}
          </ViewList>
          <ViewList name="Netsuite ID">
            {netsuiteIds ? netsuiteIds : "-"}
          </ViewList>
          <ViewList name="Customer Currency">
            {customer.invoicing_currency ? customer.invoicing_currency : "-"}
          </ViewList>
          <ViewList name="Amagi Legal Entity">
            {customer.legal_entity_name ? customer.legal_entity_name : "-"}
          </ViewList>
        </div>
      </CustomerCard>
      <CustomerCard title="Product URLs">
        <div>
          <div className="font-medium mb-3">Cloudport URLs</div>
          {customer.product_url?.cloudport_urls ? (
            <div>
              <table className="w-1/2 border border-gray-300">
                <thead className="border-b border-gray-300">
                  <tr>
                    <th className="border-r border-gray-300">Env</th>
                    <th className="border-r border-gray-300">Blip URL</th>
                    <th className="border-r border-gray-300">Blip Version</th>
                    <th className="border-r border-gray-300">Plec Version</th>
                  </tr>
                </thead>
                <tbody>
                  {customer.product_url?.cloudport_urls.map(
                    (
                      cp: {
                        environment: string | null;
                        url: string;
                        blip_version: string | null;
                        plec_version: string | null;
                      },
                      index: number
                    ) => (
                      <tr key={index} className="border-b border-gray-300">
                        <td className="border-r border-gray-300">
                          {cp.environment}
                        </td>
                        <td className="border-r border-gray-300">
                          <a
                            href={`${
                              cp.url?.includes("http") ? "" : "https://"
                            }${cp.url}`}
                            target="_blank"
                            rel="noreferrer"
                            className="text-xs text-blue-500 underline"
                          >
                            {cp.url}
                          </a>
                        </td>
                        <td className="border-r border-gray-300">
                          {cp.blip_version}
                        </td>
                        <td className="border-r border-gray-300">
                          {cp.plec_version}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div>-</div>
          )}
          {/* <ViewList name="Cloudport">
            {customer.product_url?.cloudport_url ? (
              <a
                href={`${
                  customer.product_url?.cloudport_url?.includes("http")
                    ? ""
                    : "https://"
                }${customer.product_url?.cloudport_url!}`}
                target="_blank"
                rel="noreferrer"
                className="text-xs hover:text-blue-500 hover:underline"
              >
                {customer.product_url?.cloudport_url}
              </a>
            ) : (
              <span>-</span>
            )}
          </ViewList> */}
          <div className="grid grid-cols-4 gap-x-4 gap-y-8 mt-8">
            <ViewList name="Thunderstorm">
              {customer.product_url?.thunderstorm_url ? (
                <a
                  href={`${
                    customer.product_url?.thunderstorm_url?.includes("http")
                      ? ""
                      : "https://"
                  }${customer.product_url?.thunderstorm_url!}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-xs hover:text-blue-500 hover:underline"
                >
                  {customer.product_url?.thunderstorm_url}
                </a>
              ) : (
                <span>-</span>
              )}
            </ViewList>
            <ViewList name="Planner">
              {customer.product_url?.planner_url ? (
                <a
                  href={`${
                    customer.product_url?.planner_url?.includes("http")
                      ? ""
                      : "https://"
                  }${customer.product_url?.planner_url!}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-xs hover:text-blue-500 hover:underline"
                >
                  {customer.product_url?.planner_url}
                </a>
              ) : (
                <span>-</span>
              )}
            </ViewList>
            <ViewList name="Amagi NOW">
              {customer.product_url?.amagi_now_url ? (
                <a
                  href={`${
                    customer.product_url?.amagi_now_url?.includes("http")
                      ? ""
                      : "https://"
                  }${customer.product_url?.amagi_now_url!}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-xs hover:text-blue-500 hover:underline"
                >
                  {customer.product_url?.amagi_now_url}
                </a>
              ) : (
                <span>-</span>
              )}
            </ViewList>
          </div>
        </div>
      </CustomerCard>
      <CustomerCard title="Contacts" isToggle={true}>
        <>
          <div className="border-b border-gray-300">
            <CustomerCard title="Content Plus Contacts" isSubSection={true}>
              <div className="mb-8 grid grid-cols-4 gap-x-4 gap-y-8">
                <ViewList name="Amagi Content Plus Owner">
                  {customer.content_plus_contacts?.amagi_content_plus_owner ? (
                    <div className="">
                      <div className="text-xs my-1 font-extrabold">
                        {
                          customer.content_plus_contacts
                            ?.amagi_content_plus_owner?.first_name
                        }{" "}
                        {
                          customer.content_plus_contacts
                            ?.amagi_content_plus_owner?.last_name
                        }
                      </div>
                      <div className="flex text-xs">
                        <span className="mr-3">
                          {customer.content_plus_contacts
                            ?.amagi_content_plus_owner
                            ? customer.content_plus_contacts
                                ?.amagi_content_plus_owner.email
                            : "-"}
                        </span>
                        {customer.content_plus_contacts
                          ?.amagi_content_plus_owner && (
                          <DelayedToolTip title="copy">
                            <CopyToClipboard
                              text={
                                customer.content_plus_contacts
                                  ?.amagi_content_plus_owner.email
                              }
                            >
                              <img
                                src="/assets/file-copy.svg"
                                onClick={() => toast.success("Copied")}
                                alt="file-copy"
                              />
                            </CopyToClipboard>
                          </DelayedToolTip>
                        )}
                      </div>
                    </div>
                  ) : (
                    "-"
                  )}
                </ViewList>
                <ViewList name="Content Plus Internal Email ID">
                  {customer.content_plus_contacts
                    ?.content_plus_internal_email_id
                    ? customer.content_plus_contacts
                        ?.content_plus_internal_email_id
                    : "-"}
                </ViewList>
              </div>
              <div className="grid grid-cols-4 gap-x-4 gap-y-8">
                <ViewList name="Customer Email ID 1">
                  {customer.content_plus_contacts
                    ?.content_plus_customer_email_id_1
                    ? customer.content_plus_contacts
                        ?.content_plus_customer_email_id_1
                    : "-"}
                </ViewList>
                <ViewList name="Customer Email ID 2">
                  {customer.content_plus_contacts
                    ?.content_plus_customer_email_id_2
                    ? customer.content_plus_contacts
                        ?.content_plus_customer_email_id_2
                    : "-"}
                </ViewList>
                <ViewList name="Customer Email ID 3">
                  {customer.content_plus_contacts
                    ?.content_plus_customer_email_id_3
                    ? customer.content_plus_contacts
                        ?.content_plus_customer_email_id_3
                    : "-"}
                </ViewList>
                <ViewList name="Customer Email ID 4">
                  {customer.content_plus_contacts
                    ?.content_plus_customer_email_id_4
                    ? customer.content_plus_contacts
                        ?.content_plus_customer_email_id_4
                    : "-"}
                </ViewList>
                <ViewList name="Customer Email ID 5">
                  {customer.content_plus_contacts
                    ?.content_plus_customer_email_id_5
                    ? customer.content_plus_contacts
                        ?.content_plus_customer_email_id_5
                    : "-"}
                </ViewList>
                <ViewList name="Customer Email ID 6">
                  {customer.content_plus_contacts
                    ?.content_plus_customer_email_id_6
                    ? customer.content_plus_contacts
                        ?.content_plus_customer_email_id_6
                    : "-"}
                </ViewList>
              </div>
            </CustomerCard>
          </div>
          <div className="border-b border-gray-300 mt-5">
            <CustomerCard title="Billing Contacts" isSubSection={true}>
              <div className="grid grid-cols-4 gap-x-4 gap-y-8">
                {billingContacts.length > 0
                  ? billingContacts.map(
                      (contact: string | null, index: number) => {
                        return (
                          <ViewList name={`Billing Contact ${index + 1}`}>
                            {contact}
                          </ViewList>
                        );
                      }
                    )
                  : "-"}
              </div>
            </CustomerCard>
          </div>
          <div className="border-b border-gray-300 mt-5">
            <CustomerCard
              title="Onboarding Customer Contacts"
              isSubSection={true}
            >
              <div className="grid grid-cols-4 gap-x-4 gap-y-8">
                <ViewList name="Onboarding Customer Email ID 1">
                  {customer.onboarding_customer_contacts
                    ?.onboarding_customer_email_id_1
                    ? customer.onboarding_customer_contacts
                        ?.onboarding_customer_email_id_1
                    : "-"}
                </ViewList>
                <ViewList name="Onboarding Customer Email ID 2">
                  {customer.onboarding_customer_contacts
                    ?.onboarding_customer_email_id_2
                    ? customer.onboarding_customer_contacts
                        ?.onboarding_customer_email_id_2
                    : "-"}
                </ViewList>
                <ViewList name="Onboarding Customer Email ID 3">
                  {customer.onboarding_customer_contacts
                    ?.onboarding_customer_email_id_3
                    ? customer.onboarding_customer_contacts
                        ?.onboarding_customer_email_id_3
                    : "-"}
                </ViewList>
                <ViewList name="Onboarding Customer Email ID 4">
                  {customer.onboarding_customer_contacts
                    ?.onboarding_customer_email_id_4
                    ? customer.onboarding_customer_contacts
                        ?.onboarding_customer_email_id_4
                    : "-"}
                </ViewList>
                <ViewList name="Onboarding Customer Email ID 5">
                  {customer.onboarding_customer_contacts
                    ?.onboarding_customer_email_id_5
                    ? customer.onboarding_customer_contacts
                        ?.onboarding_customer_email_id_5
                    : "-"}
                </ViewList>
                <ViewList name="Onboarding Customer Email ID 6">
                  {customer.onboarding_customer_contacts
                    ?.onboarding_customer_email_id_6
                    ? customer.onboarding_customer_contacts
                        ?.onboarding_customer_email_id_6
                    : "-"}
                </ViewList>
                <ViewList name="Onboarding Customer Email ID 7">
                  {customer.onboarding_customer_contacts
                    ?.onboarding_customer_email_id_7
                    ? customer.onboarding_customer_contacts
                        ?.onboarding_customer_email_id_7
                    : "-"}
                </ViewList>
                <ViewList name="Onboarding Customer Email ID 8">
                  {customer.onboarding_customer_contacts
                    ?.onboarding_customer_email_id_8
                    ? customer.onboarding_customer_contacts
                        ?.onboarding_customer_email_id_8
                    : "-"}
                </ViewList>
                <ViewList name="Onboarding Customer Email ID 9">
                  {customer.onboarding_customer_contacts
                    ?.onboarding_customer_email_id_9
                    ? customer.onboarding_customer_contacts
                        ?.onboarding_customer_email_id_9
                    : "-"}
                </ViewList>
                <ViewList name="Onboarding Customer Email ID 10">
                  {customer.onboarding_customer_contacts
                    ?.onboarding_customer_email_id_10
                    ? customer.onboarding_customer_contacts
                        ?.onboarding_customer_email_id_10
                    : "-"}
                </ViewList>
              </div>
            </CustomerCard>
          </div>
          <div className="mt-5">
            <CustomerCard
              title="Ads PLUS Customer Contacts"
              isSubSection={true}
            >
              <div className="grid grid-cols-4 gap-x-4 gap-y-8">
                <ViewList name="Ads PLUS Customer Email ID 1">
                  {customer.ads_plus_customer_contacts
                    ?.ads_plus_customer_email_id_1
                    ? customer.ads_plus_customer_contacts
                        ?.ads_plus_customer_email_id_1
                    : "-"}
                </ViewList>
                <ViewList name="Ads PLUS Customer Email ID 2">
                  {customer.ads_plus_customer_contacts
                    ?.ads_plus_customer_email_id_2
                    ? customer.ads_plus_customer_contacts
                        ?.ads_plus_customer_email_id_2
                    : "-"}
                </ViewList>
                <ViewList name="Ads PLUS Customer Email ID 3">
                  {customer.ads_plus_customer_contacts
                    ?.ads_plus_customer_email_id_3
                    ? customer.ads_plus_customer_contacts
                        ?.ads_plus_customer_email_id_3
                    : "-"}
                </ViewList>
                <ViewList name="Ads PLUS Customer Email ID 4">
                  {customer.ads_plus_customer_contacts
                    ?.ads_plus_customer_email_id_4
                    ? customer.ads_plus_customer_contacts
                        ?.ads_plus_customer_email_id_4
                    : "-"}
                </ViewList>
                <ViewList name="Ads PLUS Customer Email ID 5">
                  {customer.ads_plus_customer_contacts
                    ?.ads_plus_customer_email_id_5
                    ? customer.ads_plus_customer_contacts
                        ?.ads_plus_customer_email_id_5
                    : "-"}
                </ViewList>
                <ViewList name="Ads PLUS Customer Email ID 6">
                  {customer.ads_plus_customer_contacts
                    ?.ads_plus_customer_email_id_6
                    ? customer.ads_plus_customer_contacts
                        ?.ads_plus_customer_email_id_6
                    : "-"}
                </ViewList>
              </div>
            </CustomerCard>
          </div>
        </>
      </CustomerCard>
    </div>
  );
};

export default CustomerDetails;
