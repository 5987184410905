import React from "react";
import { useCustomerTotalOutstandingQuery } from "../../hasura.graphql";
import { useCDM } from "../../cdm-context";
import { DateTime } from "luxon";
import Loader from "../../common/loader";
import CountCard from "../cards/count-card";
import { currencyConverter } from "../utils";

const TotalOutstandingAmount = () => {
  const { show } = useCDM();
  const { data, loading, error } = useCustomerTotalOutstandingQuery({
    variables: {
      amagi_id: show?.amg_id!,
      from_date: "2011-01-01",
      to_date: DateTime.utc().toISO().slice(0, 10),
    },
  });

  if (loading) return <Loader />;
  if (error) return <i>Error</i>;
  if (!data) return <i>No data found</i>;

  const invoice_due =
    data.customer?.invoices_due && data.customer?.invoices_due[0].amount;
  const invoice_overdue =
    data.customer?.invoices_overdue &&
    data.customer?.invoices_overdue[0].amount;
  const total = (invoice_due || 0) + (invoice_overdue || 0);

  return (
    <div className="grid grid-cols-3 gap-4">
      <CountCard
        count={`${
          total
            ? currencyConverter(total, show?.currency || "USD", "0.00a")
            : "-"
        }`}
        cardText="Total Outstanding Amount"
      />
      <CountCard
        count={`${
          invoice_due
            ? currencyConverter(invoice_due, show?.currency || "USD", "0.00a")
            : "-"
        }`}
        cardText="Net Due Amount"
      />
      <CountCard
        count={`${
          invoice_overdue
            ? currencyConverter(
                invoice_overdue,
                show?.currency || "USD",
                "0.00a"
              )
            : "-"
        }`}
        cardText="Net Overdue Amount"
      />
    </div>
  );
};

export default TotalOutstandingAmount;
