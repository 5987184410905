import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import Header from "./common/header";
import { AppRoutes } from "./app-routes";

import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import { CDMContext, ShowInfo } from "./cdm-context";
import { useState } from "react";
import ShowInfoDialog from "./pages/show-info-dialog";
import "./styles/style.css";
import { Auth } from "aws-amplify";
import { setContext } from "@apollo/client/link/context";
import { CDPRoleContext } from "./cdp-role-context";
import { ProductMonthContext, ProductMonthType } from "./product-month-context";

function App() {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_HASURA_ENDPOINT_HTTP,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = `Bearer ${(await Auth.currentSession())
      .getIdToken()
      .getJwtToken()}`;
    return {
      headers: {
        ...headers,
        authorization: token || "",
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
  return (
    <ApolloProvider client={client}>
      <ApolloConsumer />
    </ApolloProvider>
  );
}

const ApolloConsumer = () => {
  const [show, setShow] = useState<ShowInfo | null>(null);
  const [CDPRole, setCDPRole] = useState<Array<string>>([]);
  const [date, setDate] = useState<ProductMonthType | null>(null);

  return (
    <CDMContext.Provider value={{ show, setShow }}>
      <ProductMonthContext.Provider value={{ date, setDate }}>
        <CDPRoleContext.Provider value={{ CDPRole, setCDPRole }}>
          <div className="amagi-theme">
            <Header />
            <div className="mb-6">
              <AppRoutes />
              <ShowInfoDialog />
            </div>
          </div>
        </CDPRoleContext.Provider>
      </ProductMonthContext.Provider>
    </CDMContext.Provider>
  );
};

export default App;
