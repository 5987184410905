import React from "react";
import Orders from ".";
import { useCDM } from "../../cdm-context";
import Loader from "../../common/loader";
import { useOrderQuery } from "../../hasura.graphql";
import ErrorBoundary from "../error-boundary";

const OrdersWrapper: React.FC<{
  isModal: boolean;
  setTab: (tab: string) => void;
  setSearchParams: (search: string) => void;
}> = ({ isModal, setTab, setSearchParams }) => {
  const { show } = useCDM();
  const { loading, data, error } = useOrderQuery({
    variables: {
      amagi_id: show!.amg_id,
    },
    skip: !show?.amg_id,
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <i>Error</i>;
  }

  if (!data) {
    return <i>Orders data not available</i>;
  }

  return (
    <ErrorBoundary>
      <Orders
        orders={data.order}
        isModal={isModal}
        setTab={setTab}
        setSearchParams={setSearchParams}
      />
    </ErrorBoundary>
  );
};

export default OrdersWrapper;
