import React, { Fragment, useEffect, useState } from "react";
import { useField } from "formik";
import { ChevronDownIcon, TrashIcon } from "@heroicons/react/outline";
import { Popover, Transition } from "@headlessui/react";
import {
  DeliveryProductsType,
  ProductListType,
} from "../channel-delivery/delivery-edit-form/types";
import TableFilter from "../../common/table-filter";

type InputType = {
  inputCode: string;
  inputName: string;
};

const FormikDeliveryProducts: React.FC<{
  products: Array<DeliveryProductsType>;
  fieldName: string;
  productsLists: Array<ProductListType>;
}> = ({ products, fieldName, productsLists }) => {
  const [search, setSearch] = useState<string | null>(null);
  const [inputTable, setInputTable] =
    useState<Array<DeliveryProductsType>>(products);
  const [input, setInput] = useState<InputType>({
    inputCode: "",
    inputName: "",
  });

  const [productCode, setProductCode] = useState<string>("Please select");
  const [productName, setProductName] = useState<string>("Please select code");

  const handleInput = (inp: string) => {
    setInput({
      inputCode: inp,
      inputName: productsLists.find((prod) => prod.code === inp)?.name!,
    });
  };

  const handleSubmit = () => {
    input.inputCode &&
      input.inputCode &&
      setInputTable((inputTable) => [
        ...inputTable,
        {
          code: input.inputCode,
          name: input.inputName,
        },
      ]);
    setInput({ inputCode: "", inputName: "" });
    setProductCode("Please select");
    setProductName("Please select code");
    setSearch(null);
  };
  const _field = useField(fieldName);
  const { setValue } = _field[2];

  useEffect(() => {
    setValue(inputTable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputTable.length]);

  const debouncedProducts = productsLists.filter((prod) => {
    if (!search) {
      return true;
    }
    return `${prod.code} ${prod.name} ${prod.label}`
      .toLowerCase()
      .includes(search.toLowerCase());
  });

  return (
    <div style={{ padding: "5px 0px" }}>
      <div className="text-base font-semibold mt-8 mb-2">
        Products in this delivery
      </div>
      <table className="mt-4" style={{ width: "80%", fontSize: "16px" }}>
        <thead>
          <tr className="bg-gray-300">
            <th className="py-2 pl-2">S No.</th>
            <th className="text-left py-2 pl-2">Product Code</th>
            <th className="text-left py-2 pl-2">Product Name</th>
          </tr>
        </thead>
        <tbody className="bg-gray-50">
          {inputTable.map((data: DeliveryProductsType, index: number) => {
            return (
              <tr key={index}>
                <td className="py-2 pl-2">{index + 1}</td>
                <td className="text-left py-2 pl-2">{data.code}</td>
                <td className="text-left py-2 pl-2">{data.name}</td>
                <td className="bg-white py-2 pl-2 cursor-pointer">
                  <TrashIcon
                    onClick={() =>
                      setInputTable(inputTable.filter((f) => f !== data))
                    }
                    className="h-5 w-5"
                  />
                </td>
              </tr>
            );
          })}
          <tr>
            <td className="py-1 pl-2" style={{ fontSize: "16px" }}>
              {inputTable.length + 1}
            </td>
            <td className="text-left py-1 pl-2">
              <Popover as="div" className="relative items-center max-w-sm">
                {({ open, close }) => (
                  <>
                    <Popover.Button className="rounded-full w-full focus:outline-none">
                      <div
                        placeholder={"Enter Text"}
                        style={{ padding: "5.5px 0px" }}
                        className="flex justify-between border-0 text-left focus:ring-0 border-gray-300 outline-none bg-transparent w-full"
                      >
                        <span>{productCode || "Please select"}</span>
                        <span className="mr-3">
                          <ChevronDownIcon className="h-4 w-4" />
                        </span>
                      </div>
                    </Popover.Button>
                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Popover.Panel className="origin-top-right text-left z-10 absolute bottom-10 right-0 min-w-full max-h-60 overflow-y-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {() => (
                          <div className="">
                            <div className="p-2 sticky top-0 bg-white">
                              <TableFilter
                                filter={search}
                                setFilter={setSearch}
                                placeholder="Search"
                              />
                            </div>
                            {debouncedProducts
                              .sort()
                              .filter(
                                (con) =>
                                  !inputTable.some(
                                    (inp) => inp.code === con.code
                                  )
                              )
                              .map((inp: ProductListType, index: number) => {
                                return (
                                  <div key={index} className="">
                                    <div
                                      className={`focus:ring-0 cursor-pointer hover:bg-blue-100 px-3 py-1 ${
                                        inp.code === productCode &&
                                        "bg-blue-500 text-white"
                                      }`}
                                      onClick={() => {
                                        close();
                                        handleInput(inp.code);
                                        setProductCode(inp.code);
                                        setProductName(inp.name);
                                      }}
                                    >
                                      {inp.label}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </td>
            <td className="text-left py-1 pl-2">{productName}</td>
            <td className="py-1 pl-2 bg-white text-left cursor-pointer text-blue-500">
              <span onClick={handleSubmit}>+ ADD</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FormikDeliveryProducts;
