import React, { useMemo, useState } from "react";
import { CustomerFdTicketsDataFragment } from "../../hasura.graphql";
import DelayedToolTip from "../delayed-tooltip";
import SubTabs from "../sub-tabs";
import { DateParser, DateSort } from "../utils";
import BillingTicket from "./billing-ticket";
import ProductTicket from "./product-ticket";
import { TransactionType } from "../financials";
import TableRowsFilter from "../../common/filters/table-rows-filter";

const status = [
  { key: 2, value: "Open" },
  { key: 3, value: "Pending" },
  { key: 4, value: "Resolved" },
  { key: 5, value: "Closed" },
  { key: 11, value: "Awaiting Customer Update" },
  { key: 12, value: "Awaiting Content Partner Update" },
  { key: 13, value: "Incident recovered, RCA in progress" },
];
const source = [
  { key: 1, value: "Email" },
  { key: 2, value: "Portal" },
  { key: 3, value: "Phone" },
  { key: 7, value: "Chat" },
  { key: 9, value: "Feedback Widget" },
  { key: 10, value: "Outbound Email" },
  { key: 101, value: "Customer Email" },
  { key: 102, value: "System Alert" },
  { key: 103, value: "Internal Email" },
];
const priority = ["Low", "Medium", "High", "Urgent"];

const FDTickets: React.FC<{
  tickets: Array<CustomerFdTicketsDataFragment>;
  isModal: boolean;
  setTransaction: React.Dispatch<React.SetStateAction<TransactionType>>;
  setTab: (tab: string) => void;
}> = ({ tickets, isModal, setTransaction, setTab }) => {
  const [ticketTab, setTicketTab] = useState<string>("Product Tickets");

  const columns: any = useMemo(
    () => [
      {
        Header: "TICKET ID",
        accessor: "ticket_id",
        Cell: ({ cell }: any) => (
          <a
            href={`https://support.amagi.tv/a/tickets/${cell.row.original.ticket_id}`}
            className="text-blue-500 underline"
            target="_blank"
            rel="noreferrer"
          >
            {cell.row.original.ticket_id}
          </a>
        ),
      },
      {
        Header: "PRODUCT",
        accessor: "product_resp",
        Filter: TableRowsFilter,
        positionStyles: "left-0",
        filter: "multiSelect",
      },
      {
        Header: "SUBJECT",
        accessor: "subject",
        Cell: ({ cell }: any) => (
          <DelayedToolTip title={cell.row.original.subject} position="top">
            <span className="inline-block">
              {cell.row.values.subject && cell.row.values.subject.length > 30
                ? `${cell.row.values.subject.slice(0, 30)}...`
                : cell.row.values.subject || "--"}
            </span>
          </DelayedToolTip>
        ),
      },
      {
        Header: "SEVERITY",
        accessor: "impact",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "PRIORITY",
        accessor: "priority",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "STATUS",
        accessor: "status",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "PRODUCT AREA",
        accessor: "product_resp_area",
      },
      {
        Header: "DESCRIPTION",
        accessor: "description",
        Cell: ({ cell }: any) => (
          <DelayedToolTip title={cell.row.original.description} position="top">
            <span className="inline-block">
              {cell.row.values.description &&
              cell.row.values.description.length > 30
                ? `${cell.row.values.description.slice(0, 30)}...`
                : cell.row.values.description || "--"}
            </span>
          </DelayedToolTip>
        ),
      },
      {
        Header: "CHANNEL",
        accessor: "delivery_channel",
        Cell: ({ cell }: any) => (
          <DelayedToolTip
            title={cell.row.original.delivery_channel}
            position="top"
          >
            <span className="inline-block">
              {cell.row.values.delivery_channel &&
              cell.row.values.delivery_channel.length > 30
                ? `${cell.row.values.delivery_channel.slice(0, 30)}...`
                : cell.row.values.delivery_channel || "--"}
            </span>
          </DelayedToolTip>
        ),
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "PRODUCT COMPONENT",
        accessor: "product_resp_area_component",
      },
      {
        Header: "INVOICE NUMBER",
        accessor: "invoice_number",
      },
      {
        Header: "INVOICE MONTH",
        accessor: "invoice_month",
      },
      {
        Header: "INVOICE AMOUNT",
        accessor: "invoice_amount",
      },
      {
        Header: "DISPUTED AMOUNT",
        accessor: "disputed_amount",
      },
      {
        Header: "CHARGE CODES",
        accessor: "charge_codes",
      },
      {
        Header: "REGION",
        accessor: "region",
      },
      {
        Header: "COORDINATOR",
        accessor: "coordinator",
      },
      {
        Header: "BILLING ISSUE STATUS",
        accessor: "billing_issue_status",
      },
      {
        Header: "ROOT CAUSE CATEGORY",
        accessor: "root_cause_category",
      },
      {
        Header: "ROOT CAUSE OWNER",
        accessor: "root_cause_owner",
      },
      {
        Header: "GROUP",
        accessor: "group",
      },
      {
        Header: "GROUP AGENT",
        accessor: "group_agent",
      },
      {
        Header: "SOURCE",
        accessor: "source",
      },
      {
        Header: "LAST UPDATED DATE",
        accessor: "last_updated_date",
        sortType: (a: any, b: any) =>
          DateSort(a.values.last_updated_date, b.values.last_updated_date),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.last_updated_date !== "--"
              ? DateParser(cell.row.values.last_updated_date)
              : "--"}
          </span>
        ),
      },
      {
        Header: "DISPUTE TYPE",
        accessor: "dispute_type",
      },
      {
        Header: "DISPUTE OUTCOME",
        accessor: "dispute_outcome",
      },
      {
        Header: "TICKET TYPE",
        accessor: "ticket_type",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "CREATED ON",
        accessor: "created_date",
        sortType: (a: any, b: any) =>
          DateSort(a.values.created_date, b.values.created_date),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.created_date !== "--"
              ? DateParser(cell.row.values.created_date)
              : "--"}
          </span>
        ),
      },
      {
        Header: "CLOSED ON",
        accessor: "closed_date",
        sortType: (a: any, b: any) =>
          DateSort(a.values.closed_date, b.values.closed_date),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.closed_date !== "--"
              ? DateParser(cell.row.values.closed_date)
              : "--"}
          </span>
        ),
      },
      {
        Header: "TAGS",
        accessor: "tags",
      },
      {
        Header: "ROOT CAUSE",
        accessor: "root_cause",
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    []
  );

  const exportHeaders = columns.map((col: any) => {
    return {
      label: col.Header,
      key: col.accessor,
    };
  });

  const productTickets = tickets.filter(
    (ticket) => ticket.product_resp !== "Amagi BILLING"
  );

  const billingTickets = tickets.filter(
    (ticket) => ticket.product_resp === "Amagi BILLING"
  );

  return (
    <div className="text-primaryText">
      <div className="">
        <SubTabs
          customStyles=""
          currentTab={ticketTab}
          toggleTab={setTicketTab}
          tabList={[{ name: "Product Tickets" }, { name: "Billing Tickets" }]}
        />
      </div>
      {ticketTab === "Product Tickets" && (
        <ProductTicket
          tickets={productTickets}
          columns={columns}
          priority={priority}
          status={status}
          source={source}
          exportHeaders={exportHeaders}
        />
      )}
      {ticketTab === "Billing Tickets" && (
        <BillingTicket
          tickets={billingTickets}
          priority={priority}
          status={status}
          source={source}
          isModal={isModal}
          setTab={setTab}
          setTransaction={setTransaction}
        />
      )}
    </div>
  );
};

export default FDTickets;
