import React from "react";
import { useCustomerSalesByPkQuery } from "../../hasura.graphql";
import Sales from ".";
import Loader from "../../common/loader";
import ErrorBoundary from "../error-boundary";

const SalesWrapper: React.FC<{
  amagi_id: string;
  searchParam: string;
}> = ({ amagi_id, searchParam }) => {
  const { data, loading, error } = useCustomerSalesByPkQuery({
    variables: {
      amagi_id,
    },
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <i>${JSON.stringify(error)}</i>;
  }

  if (!data || !data.customer) {
    return <i>Could not retrieve customer sales data</i>;
  }

  return (
    <ErrorBoundary>
      <Sales salesData={data.customer} searchParam={searchParam} />
    </ErrorBoundary>
  );
};

export default SalesWrapper;
