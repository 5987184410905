import React from "react";
import FDTickets from ".";
import Loader from "../../common/loader";
import { useServiceTicketsQuery } from "../../hasura.graphql";
import ErrorBoundary from "../error-boundary";
import { TransactionType } from "../financials";

const FDTicketsWrapper: React.FC<{
  amagi_id: string;
  isModal: boolean;
  setTab: (tab: string) => void;
  setTransaction: React.Dispatch<React.SetStateAction<TransactionType>>;
}> = ({ amagi_id, isModal, setTransaction, setTab }) => {
  const { data, loading, error } = useServiceTicketsQuery({
    variables: {
      amagi_id,
    },
  });
  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <i>Error</i>;
  }
  if (!data || !data.service_tickets) {
    return (
      <div className="p-4 mt-4 bg-white border rounded-md italic font-medium">
        No Service Tickets generated
      </div>
    );
  }

  console.log(data);

  return (
    <ErrorBoundary>
      <FDTickets
        isModal={isModal}
        setTab={setTab}
        setTransaction={setTransaction}
        tickets={
          data.service_tickets && data.service_tickets.length > 0
            ? data.service_tickets[0].tickets
            : []
        }
      />
    </ErrorBoundary>
  );
};

export default FDTicketsWrapper;
