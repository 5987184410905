import React from "react";
import generateComponent from "../../../channel-fragment";

const ViewMultiPicklist: React.FC<{
  inlineLabel: string;
  picklist: Array<string>;
  isRedundant?: boolean;
}> = ({ inlineLabel, picklist, isRedundant = false }) => {
  const itemsPerRow = 5; // Number of items per row

  return (
    <div>
      <label className="text-gray-400">{inlineLabel}</label>
      <div className="flex mt-2 flex-wrap">
        {picklist && picklist.length > 0
          ? picklist.map((data: string, index: number) => {
              let id: string | null, name: string | null;
              if (isRedundant) {
                [id, name] = data.split(" : ");
              } else {
                id = null;
                name = null;
              }
              return (
                <div
                  key={index}
                  className={`px-3 flex cursor-pointer rounded-sm text-black font-semibold mr-2 border border-transparent ${
                    index % itemsPerRow === itemsPerRow - 1
                      ? "mb-4" 
                      : "mb-2"
                  }`}
                >
                  {isRedundant ? (
                    <div className="whitespace-nowrap">
                      {generateComponent(name!, id!)} 
                    </div>
                  ) : (
                    <div className="whitespace-nowrap">{data}</div>
                  )}
                </div>
              );
            })
          : "-"}
      </div>
    </div>
  );
};

export default ViewMultiPicklist;
