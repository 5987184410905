import { InformationCircleIcon } from "@heroicons/react/outline";
import { useMemo, useState } from "react";
import { useCDM } from "../../cdm-context";
// import { useCDPRole } from "../../cdp-role-context";
import Loader from "../../common/loader";
import NestedTable from "../../common/nested-table";
import SubTableContainer from "../../common/sub-table";
import { useMsaRateCardByAmagiIdQuery } from "../../hasura.graphql";
import DelayedToolTip from "../delayed-tooltip";
// import MSARateCardEdit from "./msa-rate-card-edit";
import {
  ViewTierType,
  ViewRateType,
  ViewRateCardType,
  MSARateCardType,
} from "./types";
// import Tabs from "../tabs";
import MSATabs, { msaStatusChip } from "./msa-tabs";

const MSATable: React.FC = () => {
  const { show } = useCDM();
  const [rowId, setRowId] = useState<string | null>("");
  const [msaRateCard, setMsaRateCard] = useState<Array<MSARateCardType>>([]);
  // const [editForm, setEditForm] = useState<boolean>(false);
  const [navTab, setNavTab] = useState<string>("");
  // const { CDPRole } = useCDPRole();

  const msaType = [
    {
      key: "Usage",
      value: "Consumption",
    },
    {
      key: "Recurring",
      value: "Subscription",
    },
    {
      key: "One-Time",
      value: "Others",
    },
  ];

  const msa_columns: any = useMemo(
    () => [
      {
        Header: "Product Code",
        accessor: "code",
      },
      {
        Header: "Product Name",
        accessor: "name",
        Cell: ({ cell }: any) => (
          <div className="flex items-center">
            <span>
              {cell.row.original.short_name
                ? cell.row.original.short_name
                : cell.row.original.name}
            </span>

            <span className="ml-2">
              <DelayedToolTip
                delay={0}
                title={cell.row.original.name}
                position="top"
              >
                <InformationCircleIcon
                  className="w-4 h-4"
                  style={{ color: "#94ACEB" }}
                />
              </DelayedToolTip>
            </span>
          </div>
        ),
      },
      {
        Header: "Units",
        accessor: "unit",
      },
      {
        Header: "Billing Frequency",
        accessor: "billing_frequency",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Currency",
        accessor: "currency",
      },
      {
        Header: "Price",
        accessor: "price",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.price !== "--"
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: cell.row.original.currency || show?.currency,
                }).format(cell.row.values.price)
              : "--"}
          </span>
        ),
        textAlign: "text-right",
      },
    ],
    [show?.currency]
  );

  const msa_tier_columns: any = useMemo(
    () => [
      {
        Header: "Consumption Rate Name",
        accessor: "name",
      },
      {
        Header: "Pricing Method",
        accessor: "pricing_method",
      },
      {
        Header: "Lower Bounds",
        accessor: "lower_bound",
      },
      {
        Header: "Upper Bounds",
        accessor: "upper_bound",
      },
      {
        Header: "Pricing",
        accessor: "price",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.price !== "--"
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  minimumSignificantDigits: 2,
                  currency:
                    rowId && cell.row.original.currency
                      ? cell.row.original.currency
                      : show?.currency,
                }).format(cell.row.values.price)
              : // currencyConverter(cell.row.values.price, show?.currency!)
                "--"}
          </span>
        ),
        textAlign: "text-right",
      },
    ],
    [rowId, show?.currency]
  );

  const { data, loading, error } = useMsaRateCardByAmagiIdQuery({
    variables: {
      amagiId: show?.amg_id!,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const msaStatusSortOrder = [
        "Accepted",
        "Approved",
        "Approval Required",
        "In Review",
        "Draft",
        "Rejected",
      ];
      const sortByStatus: any = msaStatusSortOrder.reduce(
        (obj, item, index) => {
          return {
            ...obj,
            [item]: index,
          };
        },
        {}
      );

      const sortedRateCards =
        data.msa_rate_card && data.msa_rate_card.length > 0
          ? data.msa_rate_card.sort(
              (a, b) =>
                sortByStatus[a.status ? a.status : ""] -
                sortByStatus[b?.status ? b?.status : ""]
            )
          : [];

      sortedRateCards.sort((a, b) => {
        if (a.quote_type === null) {
          return 1;
        }

        if (b.quote_type === null) {
          return -1;
        }

        if (a.quote_type === b.quote_type) {
          return 0;
        }
        return 0;
      });
      setMsaRateCard(
        sortedRateCards.map((rate) => {
          return {
            status: rate.status || null,
            quote_type: rate.quote_type || null,
            quote_number: rate.quote_number || null,
            products: rate.products,
          };
        })
      );
      setNavTab(sortedRateCards[0].quote_number || "");
    },
  });
  if (loading) return <Loader />;
  if (error) return <i>Error</i>;
  if (!data) return <i>No data</i>;

  const msaRateValues: Array<ViewRateType> =
    msaRateCard && msaRateCard.length > 0
      ? msaRateCard
          .find(
            (msa) => msa.quote_type === navTab || msa.quote_number === navTab
          )!
          .products.map((rate: ViewRateCardType) => {
            let productTier =
              rate.tier_at_product_level &&
              rate.tier_at_product_level.length > 0
                ? rate.tier_at_product_level
                    .slice()
                    .sort((p1: any, p2: any) =>
                      p1.price < p2.price ? 1 : p1.price > p2.price ? -1 : 0
                    )
                : [];

            let discountTier =
              rate.discount_tiers_at_product_level &&
              rate.discount_tiers_at_product_level.length > 0
                ? rate.discount_tiers_at_product_level
                    .slice()
                    .sort((p1: any, p2: any) =>
                      p1.price < p2.price ? 1 : p1.price > p2.price ? -1 : 0
                    )
                : null;
            return {
              amagi_id: show?.amg_id || null,
              code: rate.product?.code ? rate.product?.code : null,
              name: rate.product?.name ? rate.product?.name : null,
              unit: rate.product?.display_unit
                ? rate.product?.display_unit
                : null,
              billing_frequency: rate.product?.billing_frequency
                ? rate.product?.billing_frequency
                : null,
              quote_number: navTab,
              type: rate.type
                ? msaType.find((type) => type.key === rate.type) !== undefined
                  ? msaType.find((type) => type.key === rate.type)?.value
                  : "Others"
                : "Others",
              currency: rate.currency ? rate.currency : null,
              price: rate.price ? rate.price : null,
              ads_plus_revenue: rate.amagi_adsplus_revenue_share
                ? rate.amagi_adsplus_revenue_share
                : null,
              tier_at_product_level:
                productTier && productTier.length > 0
                  ? productTier.map((tier: ViewTierType) => {
                      return {
                        ...tier,
                        isDiscount: false,
                        currency: rate.currency || "",
                      };
                    })
                  : discountTier && discountTier.length > 0
                  ? discountTier.map((tier: any) => {
                      return {
                        name: tier.tier_name,
                        pricing_method: tier.pricing_method
                          ? tier.pricing_method
                          : "-",
                        lower_bound: tier.lower_bound,
                        upper_bound: tier.upper_bound,
                        price: tier.price,
                        isDiscount: true,
                        currency: rate.currency || "",
                      };
                    })
                  : [],
            };
          })
      : [];

  // const products = data.product.map((prod) => {
  //   return {
  //     label: `${prod.code} - ${prod.name}` || "",
  //     code: prod.code || "",
  //     name: prod.name || "",
  //   };
  // });

  // const rateCardValues = msaRateValues.map((rate: any) => {
  //   return {
  //     amagi_id: rate.amagi_id ? rate.amagi_id : null,
  //     code: rate.code ? rate.code : null,
  //     currency: rate.currency ? rate.currency : null,
  //     quote_number: rate.quote_number ? rate.quote_number : null,
  //     price: rate.price ? rate.price : null,
  //     tier_at_product_level: rate.tier_at_product_level
  //       ? rate.tier_at_product_level
  //       : [],
  //   };
  // });

  const exportMSARateCard = msaRateValues?.map((rate) => {
    if (rate.tier_at_product_level.length > 0) {
      return rate.tier_at_product_level?.map((tier) => {
        return {
          code: rate.code ? rate.code : "--",
          product_name: rate.name ? rate.name : "--",
          unit: rate.unit ? rate.unit : "--",
          billing_frequency: rate.billing_frequency
            ? rate.billing_frequency
            : "--",
          type: rate.type ? rate.type : "--",
          currency: rate.currency ? rate.currency : show?.currency || "",
          price: rate.price
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: rate.currency || show?.currency,
              }).format(rate.price)
            : "--",
          consumption_rate_name: tier.name ? tier.name : "--",
          pricing_method: tier.pricing_method ? tier.pricing_method : "--",
          lower_bound: tier.lower_bound ? tier.lower_bound : "--",
          upper_bound: tier.upper_bound ? tier.upper_bound : "--",
          pricing: tier.price ? tier.price : "--",
        };
      });
    } else {
      return {
        code: rate.code ? rate.code : "--",
        product_name: rate.name ? rate.name : "--",
        unit: rate.unit ? rate.unit : "--",
        billing_frequency: rate.billing_frequency
          ? rate.billing_frequency
          : "--",
        type: rate.type ? rate.type : "--",
        currency: rate.currency ? rate.currency : show?.currency || "",
        price: rate.price
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: rate.currency || show?.currency,
            }).format(rate.price)
          : "--",
      };
    }
  });

  const exportHeaders = [
    { label: "Product Code", key: "code" },
    { label: "Product Name", key: "product_name" },
    { label: "Unit", key: "unit" },
    { label: "Billing Frequency", key: "billing_frequency" },
    { label: "Type", key: "type" },
    { label: "Currency", key: "currency" },
    { label: "Price", key: "price" },
    { label: "Consumption Rate Name", key: "consumption_rate_name" },
    { label: "Pricing Method", key: "pricing_method" },
    { label: "Lower Bounds", key: "lower_bound" },
    { label: "Upper Bounds", key: "upper_bound" },
    { label: "Price", key: "pricing" },
  ];

  return (
    <>
      {data.msa_rate_card && data.msa_rate_card.length > 0 ? (
        <>
          <div className="mt-4">
            <MSATabs
              currentTab={navTab}
              tabList={msaRateCard.map((msa: any) => {
                return {
                  name: msa.quote_type || msa.quote_number,
                  status: msa.status,
                  quote_number: msa.quote_number,
                };
              })}
              toggleTab={setNavTab}
              // setEditForm={setEditForm}
            />
          </div>
          {/* {editForm ? (
            <MSARateCardEdit
              close={() => {
                refetch({
                  amagiId: show?.amg_id!,
                });
                setEditForm(false);
              }}
              rateValues={
                rateCardValues.length > 0
                  ? rateCardValues
                  : [
                      {
                        amagi_id: show?.amg_id!,
                        code: null,
                        currency: null,
                        quote_number: null,
                        price: null,
                        tier_at_product_level: [],
                      },
                      ...rateCardValues,
                    ]
              }
              products={products}
            />
          ) : ( */}
          <div>
            {/* {msaRateValues.length > 0 ? (
              <> */}
            {/* {CDPRole.includes("channel-edit") && (
                    <div className="flex justify-end mt-3 font-medium mr-5">
                      <button
                        type="button"
                        onClick={() => setEditForm(true)}
                        className="py-1 px-2 bg-primaryColor text-white text-sm rounded-sm"
                      >
                        Edit
                      </button>
                    </div>
                  )} */}
            {msaRateCard.map((msa: any) => {
              if (msa.quote_type === navTab || msa.quote_number === navTab) {
                return (
                  <div className="mt-4">
                    <div className="flex w-full">
                      <div className="flex p-2 border bg-white w-max rounded">
                        <strong>Rate Type: </strong>
                        <span className={`ml-2`}>{msa.quote_type || "-"}</span>
                      </div>
                      <div className="flex ml-3 p-2 border bg-white w-max rounded">
                        <strong>Status: </strong>
                        <span
                          className={`ml-2 flex items-center text-xs rounded-full border px-2 ${msaStatusChip(
                            msa.status
                          )}`}
                        >
                          {msa.status}
                        </span>
                      </div>
                      <div className="flex ml-3 p-2 border bg-white w-max rounded">
                        <strong>Quote Number: </strong>
                        <span className={`ml-2`}>{msa.quote_number}</span>
                      </div>
                    </div>
                    <NestedTable
                      data={msaRateValues}
                      columns={msa_columns}
                      height={`${window.innerHeight - 425}px`}
                      open={rowId}
                      setOpen={setRowId}
                      exportData={exportMSARateCard}
                      warning="Rate Cards"
                      fileName={`${show?.amg_id}_MSA_Rate_Card`}
                      isSubTable={
                        rowId &&
                        msaRateValues[parseInt(rowId!)].tier_at_product_level
                          .length > 0
                          ? true
                          : rowId &&
                            msaRateValues[parseInt(rowId!)].code === "CO_FS_009"
                          ? true
                          : false
                      }
                      exportHeader={exportHeaders}
                      isToggle={false}
                      header="MSA Rate Card"
                      subTable={
                        <>
                          {rowId &&
                          msaRateValues[parseInt(rowId!)].tier_at_product_level
                            .length > 0 ? (
                            <SubTableContainer
                              header={`(${
                                msaRateValues[parseInt(rowId!)]
                                  .tier_at_product_level.length
                              }) ${
                                msaRateValues[parseInt(rowId!)]
                                  .tier_at_product_level[0].isDiscount
                                  ? "Discount"
                                  : ""
                              } Tiers at Product Level`}
                              placeholder="Search"
                              data={
                                rowId
                                  ? msaRateValues[parseInt(rowId!)]
                                      .tier_at_product_level
                                  : []
                              }
                              columns={msa_tier_columns}
                            />
                          ) : (
                            rowId &&
                            msaRateValues[parseInt(rowId!)]
                              .ads_plus_revenue && (
                              <div className="ml-3 mt-2 border rounded bg-white px-3 py-2">
                                Amagi Ads Plus Revenue:{" "}
                                <strong>
                                  {
                                    msaRateValues[parseInt(rowId!)]
                                      .ads_plus_revenue
                                  }
                                </strong>
                              </div>
                            )
                          )}
                        </>
                      }
                    />
                  </div>
                );
              }
              return null;
            })}
            {/* </>
            ) : ( */}
            {/* <div className="p-3 mt-4 bg-white flex justify-between items-center border rounded-md font-medium">
              <div className="italic">No MSA Rate Card Values</div>
              {CDPRole.includes("channel-edit") && (
                <button
                  type="button"
                  onClick={() => setEditForm(true)}
                  className="py-1 px-2 bg-primaryColor text-white text-sm rounded-sm"
                >
                  Add
                </button>
              )}
            </div> */}
            {/* )} */}
          </div>
          {/* )} */}
        </>
      ) : (
        <div className="p-4 mt-4 bg-white border rounded-md italic font-medium">
          No MSA Rate Card Values
        </div>
      )}
    </>
  );
};

export default MSATable;
