import React from "react";
import ReactPlayer from 'react-player'

interface TrailerValues {
  url: string,
  isPrimary: boolean
}

const ViewChannelTrailer: React.FC<{ trailers: TrailerValues[] }> = ({ trailers }) => {
  return (
    <div>
      <div className="mt-2">
        {trailers.length > 0 ? (trailers.map((data: TrailerValues, index: number) => {
          return (
            <div
              key={index}
              // style={{ backgroundColor: "#BDC2CB", border: "1px solid #BDC2CB" }}
              className="mt-3 flex"
            >
                <div className="flex justify-center rounded-md bg-gray-600">
                  <ReactPlayer style={{ width: 300, height: 250 }} url={data.url} light={true} controls={true} />
                </div>
                <div className="ml-4">
                  <div>Trailer {index + 1}</div>
                </div>
            </div>
          )
        })) : "-"}
      </div>
    </div>
  )
}

export default ViewChannelTrailer 