import React, { Component, ErrorInfo, ReactNode } from "react";

interface Props {
  message?: string;
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  // static getDerivedStateFromProps(error: any) {
  //   return {
  //     hasError: true,
  //   };
  // }

  componentDidCatch(error: any, errorInfo: ErrorInfo) {
    this.setState({
      hasError: true,
    });
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="italic text-lg">
          {this.props.message || "Something went wrong"}
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
