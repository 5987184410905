import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarController,
  LineController,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  PointElement,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "react-chartjs-2";
import { useCDM } from "../../cdm-context";
import { currencyConverter } from "../utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarController,
  LineController,
  BarElement,
  ChartDataLabels,
  LineElement,
  Title,
  PointElement,
  Tooltip,
  Legend
);

const SingleChart: React.FC<{
  chartData: any;
}> = ({ chartData }) => {
  const { show } = useCDM();
  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        grid: {
          drawBorder: false,
          display: true,
        },
        ticks: {
          padding: 8,
          font: {
            size: 10,
          },
          beginAtZero: true,
          callback: function (value: number) {
            return currencyConverter(value, show?.currency || "USD", "0.00a");
          },
        },
      },
      x: {
        display: true,
        ticks: {
          font: {
            size: 10,
          },
          maxRotation: 45,
          minRotation: 0,
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        ticks: {
          font: {
            size: 10,
          },
        },
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: show?.currency || "USD",
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
      legend: {
        display: false,
        position: "top",
        align: "end",
        labels: {
          usePointStyle: true,
          boxWidth: 5,
          boxHeight: 5,
        },
      },
      datalabels: {
        display: "auto",
        formatter: (value: any) => {
          if (value !== null && value !== 0) {
            return currencyConverter(value, show?.currency || "USD", "0.00a");
          }
          return null;
        },
        clamp: true,
        align: "top",
        anchor: "end",
        color: "gray",
        font: {
          size: "10px",
          weight: "bold",
        },
      },
    },
  };

  return <Chart type="bar" data={chartData} options={options} />;
};

export default SingleChart;
