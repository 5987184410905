import React from "react";
import { useNetBillingSummaryQuery } from "../../hasura.graphql";
import { DateTime } from "luxon";
import Loader from "../../common/loader";
import DQCustomerRevenue from "./dq-financials-customer-revenue";
import DQCustomerMetrics from "./dq-financials-customer-metrics";
import DQCustomerAverageMetrics from "./dq-financials-customer-average-revenue";
import DQCustomerLossMetrics from "./dq-financials-customer-loss-revenue";
import DQCustomerMRRTrends from "./dq-financials-mrr-trends";
import DQConsumptionSubscription from "./dq-financials-consumption-subscription";



const DQFinancials = () => {
  const { data, loading, error } = useNetBillingSummaryQuery({
    variables: {
      from_date: "2011-01-01",
      to_date: DateTime.utc().toISO().slice(0, 10),
    },
  });

  if (loading) return <Loader />;
  if (error) return <i>Error</i>;
  if (!data) return <i>No data found</i>;

  return (
    <div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
        <div className="p-4">
          <DQCustomerMRRTrends/>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
        <div className="p-4">
          <DQConsumptionSubscription/>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
        <div className="p-4">
          <DQCustomerRevenue/>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
        <div className="p-4">
          <DQCustomerAverageMetrics/>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
        <div className="p-4">
          <DQCustomerMetrics/>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
        <div className="p-4">
          <DQCustomerLossMetrics/>
        </div>
      </div>
    </div>
  );
};

export default DQFinancials;
