import { useParams } from "react-router-dom";
import { useCDM } from "../cdm-context";
import { useCDPRole } from "../cdp-role-context";
import Loader from "../common/loader";
import Customer from "../components/customer";
import { useCustomerByPkQuery, useFeatureFlagQuery } from "../hasura.graphql";

const CustomerById: React.FC<{ param: string }> = ({ param }) => {
  const { setShow } = useCDM();
  const { id } = useParams();

  const { setCDPRole } = useCDPRole();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const role_data = useFeatureFlagQuery({
    onCompleted: (data) => {
      setCDPRole(data.feature_flag.map((feat) => feat.feature));
    },
  });

  const { data, loading, error } = useCustomerByPkQuery({
    variables: {
      amagi_id: id!.toUpperCase(),
    },
    onCompleted: (data) => {
      setShow({
        amg_id: data.customer?.amagi_id!,
        name: data.customer?.account_name!,
        currency: data.customer?.invoicing_currency!,
        type: "customer",
      });
    },
    skip: !id,
  });

  if (loading) {
    return (
      <div className="mx-6 mt-4">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>There was an error: {JSON.stringify(error, null, 2)}</div>;
  }

  if (!data || !data.customer) {
    return <div>Customer not found</div>;
  }

  return (
    <div>
      {/* <div className="flex items-center w-full bg-secondaryBg">
        <Link to={`/customers`}>
          <span className="font-medium text-gray-600 cursor-pointer hover:text-blue-500 hover:underline">
            All Customers
          </span>
        </Link>
        <ChevronRightIcon className="h-5 w-5 mx-3 text-gray-400" />
        <span>{show?.name}</span>
      </div> */}
      <div>
        <Customer param={param} isModal={false} customer={data.customer} />
      </div>
    </div>
  );
};

export default CustomerById;
