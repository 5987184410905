import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

const MatrixModal: React.FC<{
  children: React.ReactNode;
  modalShow: boolean;
  onClose: () => void;
}> = ({ children, onClose, modalShow }) => {
  return (
    <Transition.Root show={modalShow} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-30 inset-0 overflow-hidden"
        onClose={onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transform transition ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div
                className={`pointer-events-auto`}
                style={{
                  width: window.innerWidth,
                }}
              >
                <div className="flex h-full flex-col overflow-y-scroll bg-secondaryBg shadow-xl">
                  <div className={`relative flex-1`}>{children}</div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MatrixModal;
