import React, { useState, useEffect } from "react";
import { useField } from "formik";
import { XIcon } from "@heroicons/react/solid";

const FormikMultiInput: React.FC<any> = ({ inlinelabel, defaultValue, ...props }) => {
  const _field = useField(props);
  const [inputArr, setInputArr] = useState<Array<string>>(defaultValue);
  const [input, setInput] = useState<string>('');
  const field = _field[0];
  const { setValue } = _field[2];

  useEffect(() => {
    setValue(inputArr)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputArr.length])

  return (
    <div style={{ padding: "5px 0px" }} className="flex flex-col">
      <label className="text-gray-400">{inlinelabel}</label>
      <div className="flex justify-between items-center">
        <input 
          type="text"
          value={input}
          name={field.name}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Enter Text"
          className="py-1 px-0 border-0 border-b focus:ring-0 border-gray-300 outline-none bg-transparent w-full"
        />
        <span 
          className="ml-4 w-1/3 text-center cursor-pointer text-blue-500" 
          onClick={() => {
            setInputArr((oldArr) => [...oldArr, input])
            setInput('')
          }}
        >
          + Add
        </span>
      </div>
      <div className="flex mt-2 w-full">
        {inputArr.map((data: any, index: any) => {
          return (
            <div key={index} className="px-3 flex cursor-pointer rounded-sm text-black font-semibold mr-2 border border-gray-300">
              <div className="whitespace-nowrap">{data}</div>
              <XIcon onClick={() => setInputArr(inputArr.filter((f: any) => f !== data))} className="h-4 w-4 text-gray-400" style={{ marginTop: "2.2px", marginLeft: "12px" }} />
            </div>
          )
        })}
      </div>     
    </div>
  );
};

export default FormikMultiInput;
