import React, { ChangeEvent, useState, useMemo, useEffect } from "react";
import { useDebounce } from "use-debounce";
import TableFilter from "../table-filter";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/outline";
import PopoverMenu from "../../components/popover-menu";

const CustomerStatusColumnFilter: React.FC<any> = ({
  column: { setFilter, preFilteredRows, id },
}) => {
  const [columnSearch, setColumnSearch] = React.useState<string>("");
  const [debouncedColumnSearch] = useDebounce(columnSearch, 100);
  const [checked, setChecked] = useState<Array<string>>(
    preFilteredRows
      .filter((row: any) => row.original.customer_status === "Customer")
      .map((row: any) => row.original.account_name)
  );
  const [statusChecked, setStatusChecked] = useState<Array<string>>([
    "Customer",
  ]);
  const [statusOpen, setStatusOpen] = useState<number | null>(null);

  useEffect(() => {
    setChecked(preFilteredRows.map((row: any) => row.original.account_name));
    setFilter(
      preFilteredRows.filter(
        (row: any) => row.original["customer_status"] === "Customer"
      ).length > 0
        ? preFilteredRows
            .filter(
              (row: any) => row.original["customer_status"] === "Customer"
            )
            .map((row: any) => row.original.account_name)
        : preFilteredRows.map((row: any) => row.original.account_name)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preFilteredRows]);

  const options = useMemo(() => {
    let counts: any = {};
    preFilteredRows.forEach((x: any) => {
      let sts = x.original["customer_status"];
      counts[sts] = {};
    });
    preFilteredRows.forEach((x: any) => {
      let sts = x.original["customer_status"];
      counts[sts][x.original[id]] = (counts[sts][x.original[id]] || 0) + 1;
    });
    return counts;
  }, [id, preFilteredRows]);

  const debouncedColumns = Object.entries(options).filter(([opt, count]) => {
    if (!debouncedColumnSearch) {
      return true;
    }
    return opt.toLowerCase().includes(debouncedColumnSearch.toLowerCase());
  });

  const handleStatus = (e: ChangeEvent<HTMLInputElement>) => {
    const t = e.target.name;
    if (statusChecked.length === 1 && statusChecked.includes(t)) {
      setFilter(
        Object.entries(options)
          .map(([opt, count]: any, index) => Object.keys(count))
          .flat()
      );
      setChecked([]);
      setStatusChecked([]);
    } else if (statusChecked && statusChecked.includes(t)) {
      setFilter(
        preFilteredRows
          .filter((row: any) =>
            statusChecked.includes(row.original["customer_status"])
          )
          .filter((row: any) => row.original["customer_status"] !== t)
          .map((row: any) => row.original.account_name)
      );
      setChecked((prevChecked) => {
        return prevChecked.filter((v) => v !== t);
      });
      setStatusChecked((prevChecked) => {
        return prevChecked.filter((v) => v !== t);
      });
    } else {
      setFilter([
        ...preFilteredRows
          .filter((row: any) =>
            statusChecked.includes(row.original["customer_status"])
          )
          .map((row: any) => row.original.account_name),
        ...preFilteredRows
          .filter((row: any) => row.original["customer_status"] === t)
          .map((row: any) => row.original.account_name),
      ]);
      setChecked((prevChecked) => [...prevChecked, t]);
      setStatusChecked((prevChecked) => [...prevChecked, t]);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const t = e.target.name;
    if (checked.length === 1 && checked.includes(t)) {
      setFilter(
        Object.entries(options)
          .map(([opt, count]: any, index) => Object.keys(count))
          .flat()
      );
      setChecked([]);
    } else if (checked && checked.includes(t)) {
      setFilter(checked.filter((v) => v !== t));
      setChecked((prevChecked) => {
        return prevChecked.filter((v) => v !== t);
      });
    } else {
      setFilter([...checked, t]);
      setChecked((prevChecked) => [...prevChecked, t]);
    }
  };

  return (
    <PopoverMenu
      icon={checked.length > 0 ? "filter-fill.svg" : "filter.svg"}
      styles="ml-1"
      header="Customer"
    >
      <div className="py-2">
        <TableFilter
          filter={columnSearch}
          setFilter={setColumnSearch}
          placeholder="Search"
        />
      </div>
      <button
        className="text-blue-500 py-1 hover:underline"
        onClick={() => {
          setFilter(
            Object.entries(options)
              .map(([opt, count]: any, index) => Object.keys(count))
              .flat()
          );
          setChecked([]);
          setStatusChecked([]);
        }}
      >
        Remove filters
      </button>
      {debouncedColumns.sort().map(([option, count]: any, index) => (
        <div>
          <div className="py-1 top-0 flex items-center" key={index}>
            <div
              onClick={() =>
                setStatusOpen((statusOpen) =>
                  statusOpen === null ? index : null
                )
              }
            >
              {statusOpen === index ? (
                <ChevronDownIcon className="h-4 w-4" />
              ) : (
                <ChevronRightIcon className="h-4 w-4" />
              )}
            </div>
            <input
              type="checkbox"
              className="focus:ring-0 ml-2"
              id={option}
              name={option}
              onChange={handleStatus}
              title={`${option}`}
              checked={statusChecked.includes(option)}
            />
            <label
              htmlFor={option}
              className="pl-2 text-sm font-medium text-black"
            >{`${option} (${Object.keys(count).length})`}</label>
          </div>
          {statusOpen === index && (
            <>
              {Object.entries(count).map(([opt, co], cIndex) => (
                <div className="py-1 ml-8 top-0 flex items-center" key={cIndex}>
                  <input
                    type="checkbox"
                    className="focus:ring-0"
                    id={opt}
                    name={opt}
                    onChange={onChange}
                    title={`${opt}`}
                    checked={checked.includes(option) || checked.includes(opt)}
                  />
                  <label htmlFor={opt} className="pl-2">
                    {opt.length > 40 ? `${opt.slice(0, 40)}...` : `${opt}`}
                  </label>
                </div>
              ))}
            </>
          )}
        </div>
      ))}
    </PopoverMenu>
  );
};

export default CustomerStatusColumnFilter;
