import { createContext, Dispatch, SetStateAction, useContext } from "react";

export type ProductMonthType = {
  from_date: string;
  to_date: string;
};
interface ProductMonthContextProps {
  date: ProductMonthType | null;
  setDate: Dispatch<SetStateAction<ProductMonthType | null>>;
}

export const ProductMonthContext = createContext(
  {} as ProductMonthContextProps
);

export function useProductMonth() {
  return useContext(ProductMonthContext);
}
