import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline";
import { months } from "../utils";
import { Fragment, useState } from "react";
import { DateTime } from "luxon";
import { Popover, Transition } from "@headlessui/react";

const MonthRangePicker: React.FC<{
  fromDate: string;
  toDate: string;
  max?: string;
  min?: string;
  range?: number;
  onChange: (fromDate: string, toDate: string) => void;
}> = ({ fromDate, toDate, onChange }) => {
  const [fromYear, setFromYear] = useState(parseInt(fromDate.slice(0, 4)));
  const [fromMonth, setFromMonth] = useState(parseInt(fromDate.slice(5, 7)));
  const [toYear, setToYear] = useState(parseInt(toDate.slice(0, 4)));
  const [toMonth, setToMonth] = useState(parseInt(toDate.slice(5, 7)));

  const handleDecreaseYear = (isFrom?: boolean) => {
    if (isFrom) {
      if (fromYear <= toYear) {
        setFromYear(fromYear - 1);
      } else setFromYear(fromYear);
    } else {
      if (toYear > fromYear) {
        if (toYear === fromYear + 1) {
          setToYear(toYear - 1);
          setToMonth(fromMonth);
        } else setToYear(toYear - 1);
      } else setToYear(toYear);
    }
  };

  const handleIncreaseYear = (isFrom?: boolean) => {
    if (isFrom) {
      if (fromYear < toYear) {
        setFromYear(fromYear + 1);
      } else {
        setToYear(toYear + 1);
        setFromYear(fromYear + 1);
      }
    } else {
      if (toYear >= fromYear) {
        setToYear(toYear + 1);
      } else setToYear(toYear);
    }
  };

  const handleFromMonth = (month: number) => {
    if (fromYear === toYear) {
      if (fromMonth >= toMonth) {
        setFromMonth(month);
        setToMonth(month);
      } else setFromMonth(month);
    } else setFromMonth(month);
  };

  const handleToMonth = (month: number) => {
    if (toYear > fromYear) {
      setToMonth(month);
    } else if (toYear === fromYear) {
      if (fromMonth <= month) {
        setToMonth(month);
      }
    }
  };

  const handleSubmit = () => {
    onChange(
      DateTime.local(fromYear, fromMonth).startOf("month").toISO().slice(0, 10),
      DateTime.local(toYear, toMonth).endOf("month").toISO().slice(0, 10)
    );
  };

  return (
    <div className="flex items-center">
      <Popover as="div" className={`relative`}>
        {({ open }) => (
          <>
            <Popover.Button className="focus:outline-none">
              <div className="flex items-center border bg-white rounded px-2">
                <div className="rounded focus:ring-0 border-0 text-sm p-1 py-1.5 cursor-pointer text-gray-500 mr-2">
                  {`${months[fromMonth - 1]} ${fromYear} ~ ${
                    months[toMonth - 1]
                  } ${toYear}`}
                </div>
                <CalendarIcon className="h-5 w-5 text-primaryColor" />
              </div>
            </Popover.Button>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Popover.Panel
                className={`bg-white origin-top-right text-left z-50 absolute min-w-max rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
              >
                {({ close }) => (
                  <div className="p-3 py-1 max-h-64 overflow-y-scroll">
                    <div className="flex border-b">
                      {/* From Month Range */}
                      <div className="w-60 py-2">
                        <div className="flex justify-center items-center">
                          <div onClick={() => handleDecreaseYear(true)}>
                            <ChevronLeftIcon
                              className={`h-4 w-4 text-primaryColor`}
                            />
                          </div>
                          <div className="font-medium mx-3">{fromYear}</div>
                          <div onClick={() => handleIncreaseYear(true)}>
                            <ChevronRightIcon
                              className={`h-4 w-4 text-primaryColor`}
                            />
                          </div>
                        </div>
                        <div className="mt-3 grid grid-cols-3 gap-3 text-center">
                          {months.map((mon, index) => (
                            <div
                              key={index}
                              className={`py-1 rounded cursor-pointer ${
                                fromMonth === index + 1
                                  ? "bg-primaryColor text-white"
                                  : "hover:bg-primaryColor/10 text-gray-900"
                              }`}
                              onClick={() => handleFromMonth(index + 1)}
                            >
                              {mon}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="border-r border-gray-300 mx-2"></div>
                      {/* To Month Range */}
                      <div className="w-60 py-2">
                        <div className="flex justify-center items-center">
                          <div onClick={() => handleDecreaseYear(false)}>
                            <ChevronLeftIcon
                              className={`h-4 w-4 ${
                                toYear === fromYear
                                  ? "text-gray-400 cursor-not-allowed"
                                  : "text-primaryColor"
                              }`}
                            />
                          </div>
                          <div className="font-medium mx-3">{toYear}</div>
                          <div onClick={() => handleIncreaseYear(false)}>
                            <ChevronRightIcon
                              className={`h-4 w-4 "text-primaryColor`}
                            />
                          </div>
                        </div>
                        <div className="mt-3 grid grid-cols-3 gap-3 text-center">
                          {months.map((mon, index) => (
                            <div
                              key={index}
                              className={`py-1 rounded cursor-pointer ${
                                toMonth === index + 1
                                  ? "bg-primaryColor text-white"
                                  : fromYear === toYear && fromMonth > index + 1
                                  ? "text-gray-400 cursor-not-allowed"
                                  : "hover:bg-primaryColor/10 text-gray-900"
                              }`}
                              onClick={() => handleToMonth(index + 1)}
                            >
                              {mon}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="my-2 flex justify-end">
                      <button
                        onClick={() => {
                          close();
                          handleSubmit();
                        }}
                        className="bg-lightPrimary text-primaryColor font-medium px-3 py-1 rounded"
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default MonthRangePicker;
