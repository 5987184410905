import { useNavigate, useParams } from "react-router-dom";
import Loader from "../common/loader";
import ChannelEditForm from "../components/channels/channel-edit-form";
import { useChannelByPkQuery, useFeatureFlagQuery } from "../hasura.graphql";

const ChannelByIdEdit: React.FC<{ header: string; url: string }> = ({
  header,
  url,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const role_data = useFeatureFlagQuery();

  const { data, loading, error } = useChannelByPkQuery({
    variables: {
      _id: id,
    },
  });

  if (loading) {
    return (
      <div className="mx-6 mt-4">
        <Loader />
      </div>
    );
  }
  if (error) {
    return <i>Error</i>;
  }
  if (!data) {
    return <i>Channel data not available</i>;
  }

  const customers =
    data.customer && data.customer.length > 0
      ? data.customer.map((cust) => {
          return {
            amagi_id: cust.amagi_id || "",
            account_name: cust.account_name || "",
          };
        })
      : [];

  const channels =
    data.channels && data.channels.length > 0
      ? data.channels.map((cust) => {
          return {
            salesforce_id: cust.salesforce_id || "",
            source: cust.source || "",
          };
        })
      : [];

  return (
    <>
      {role_data.data?.feature_flag.find(
        (feat) => feat.feature === "channel-edit"
      ) !== undefined ? (
        <ChannelEditForm
          channelData={data.channel!}
          customers={customers}
          channels={channels}
          close={() => navigate(`${url}/${id}`)}
          isModal={false}
          url={url}
          header={header}
        />
      ) : (
        navigate(`${url}/${id}`)
      )}
    </>
  );
};

export default ChannelByIdEdit;
