import React, { useState } from "react";
import { useGetMrrTrendsQuery} from "../../hasura.graphql";
import { DateTime } from "luxon";
import {
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  ResponsiveContainer,
} from "recharts";

const cardContainerStyle = {
  display: "flex",
  gap: "8px",
};

const cardStyle = {
  flex: "1",
  width: "360px",
  height: "100px",
  border: "1px solid #e0e0e0",
};

function formatMonth(timestamp: string): string {
  const date = new Date(timestamp);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const month = monthNames[date.getUTCMonth()];
  const year = date.getUTCFullYear().toString().slice(2);

  return `${month}'${year}`;
}

const DQCustomerMRRTrends: React.FC = () => {
  const [dateRange, setDateRange] = useState<string>("12");
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [showContractedMRR, setShowContractedMRR] = useState(true);
  const [showBilledMRR, setShowBilledMRR] = useState(true);
  const [showBookedMRR, setShowBookedMRR] = useState(true); // eslint-disable-next-line
  const [selectedMetric, setSelectedMetric] = useState<"MRR" | "ARR">("MRR");

  const { loading, error, data } = useGetMrrTrendsQuery({
    variables: {
      from_date: getFromDate(dateRange),
      to_date: getToDate(),
      from_year:2023,
      to_year: DateTime.local().year 
    },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const billingMrrData = data?.cdp_billed_mrr || [];
  const bookedMrrData = data?.cdp_booked_mrr || [];
  const contractedMrrData = data?.cdp_contracted_mrr || [];
 
 

  const annualMrrData = {
    billedMrr: data?.cdp_annual_billed_mrr || [],
    bookedMrr: data?.cdp_annual_booked_mrr || [],
    contractedMrr: data?.cdp_annual_contracted_mrr || [],
  };
  

  const chartData = {
    data:
      selectedMetric === "MRR"
        ? prepareChartData(
            billingMrrData,
            bookedMrrData,
            contractedMrrData,
            selectedMetric,
            [],
            [],
            [],
            
          )
        : prepareChartData(
            [],
            [],
            [],
            selectedMetric,
            annualMrrData.billedMrr,
            annualMrrData.bookedMrr,
            annualMrrData.contractedMrr,
          ),
  };
  

  const options: React.ComponentProps<typeof AreaChart> = {
    margin: { top: 5, right: 20, left: -20, bottom: 5 },
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-4">
          <div style={{ display: "flex", alignItems: "center" }}>
            <strong
              className="text-xl font-semibold"
              style={{ marginRight: "10px" }}
            >
              Revenue metrics by
            </strong>
            <select
              value={selectedMetric}
              onChange={(e) =>
                setSelectedMetric(e.target.value as "MRR" | "ARR")
              }
              className="text-xl font-semibold"
              style={{
                borderBottom: "1px solid #000000",
                width: "80px",
                borderLeft: "none",
                borderRight: "none",
                borderTop: "none",
                padding: "5px 0",
                outline: "none",
                backgroundColor: "transparent",
              }}
            >
              <option value="MRR">MRR</option>
              <option value="ARR">ARR</option>
            </select>
          </div>
        </div>
        <div className="relative">
          <div
            onClick={() => setDropdownOpen(!isDropdownOpen)}
            className="cursor-pointer flex items-center bg-gray-200 rounded-md border border-black p-1 gap-2"
            style={{
              width: "149px",
              height: "32px",
              borderRadius: "4px",
              border: "1px solid #CDD2D5",
              background: "#EDF2F6",
            }}
          >
            <div className="ml-1">{`Last ${dateRange} months`}</div>
            {/* Add your custom calendar icon here */}
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="text-gray-500"
            >
              <path
                d="M21 3H20V1H18V3H6V1H4V3H3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H21C22.1046 21 23 20.1046 23 19V5C23 3.89543 22.1046 3 21 3ZM21 19H3V8H21V19ZM21 6H3V5H21V6Z"
                fill="currentColor"
              />
            </svg>
          </div>
          {isDropdownOpen && (
            <div
              className="absolute top-full left-0 mt-1 bg-white border rounded-md overflow-hidden z-10"
              style={{
                width: "149px",
                borderRadius: "4px",
                border: "1px solid #e0e0e0",
                padding: "6px 12px",
                gap: "2px",
              }}
            >
              <div
                onClick={() => {
                  setDropdownOpen(false);
                  setDateRange("3");
                }}
                className="text-sm text-gray-800 cursor-pointer p-2 hover:bg-gray-100"
              >
                Last 3 months
              </div>
              <div
                onClick={() => {
                  setDropdownOpen(false);
                  setDateRange("6");
                }}
                className="text-sm text-gray-800 cursor-pointer p-2 hover:bg-gray-100"
              >
                Last 6 months
              </div>
              <div
                onClick={() => {
                  setDropdownOpen(false);
                  setDateRange("12");
                }}
                className="text-sm text-gray-800 cursor-pointer p-2 hover:bg-gray-100"
              >
                Last 12 months
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          ...cardContainerStyle,
          justifyContent: "space-between",
          paddingRight: "16px",
          marginBottom: "16px",
        }}
      >
        {renderCard(
          selectedMetric === "ARR" ? "Contracted ARR" : "Contracted MRR",
          selectedMetric === "MRR"
            ? contractedMrrData
            : annualMrrData.contractedMrr,
          selectedMetric
        )}
        {renderCard(
          selectedMetric === "ARR" ? "Booked ARR" : "Booked MRR",
          selectedMetric === "MRR"
            ? bookedMrrData
            : annualMrrData.bookedMrr,
          selectedMetric
        )}
        {renderCard(
          selectedMetric === "ARR" ? "Billed ARR" : "Billed MRR",
          selectedMetric === "MRR"
            ? billingMrrData
            : annualMrrData.billedMrr,
          selectedMetric
        )}
      </div>
      <div className="bg-white rounded-md pb-6 w-full h-[630px] flex flex-col justify-center p-[32px 16px] gap-[32px] border border-gray-200">
        <div className="flex items-center justify-between mb-4">
          <div className="text-xl font-semibold p-4 mb-4">
            {selectedMetric} Trends
          </div>
          <div className="flex items-center p-4 gap-4">
            <CheckboxToggle
              label="Contracted"
              color="blue"
              checked={showContractedMRR}
              onChange={() => setShowContractedMRR(!showContractedMRR)}
            />
            <CheckboxToggle
              label="Booked"
              color="red"
              checked={showBookedMRR}
              onChange={() => setShowBookedMRR(!showBookedMRR)}
            />
            <CheckboxToggle
              label="Billed"
              color="green"
              checked={showBilledMRR}
              onChange={() => setShowBilledMRR(!showBilledMRR)}
            />
          </div>
        </div>
        <ResponsiveContainer width="99%" height="100%" style={{ marginLeft: "10px" }}>
          <AreaChart {...options} data={chartData.data}>
            <XAxis dataKey="x" />
            <YAxis
              tickFormatter={(value) => `${value}M`}
              domain={[0, "auto"]}
              ticks={generateYAxisTicks(chartData.data)} // Dynamically generate ticks
            />

            <Tooltip
              formatter={(value: any, name: string) => [
                `${value.toFixed(2)}M USD`,
                name,
              ]}
            />
            {showBilledMRR && (
              <Area
                type="linear"
                dataKey="billedMRR"
                stroke="#00845C"
                fill="#EEF7F4"
                name="Billed MRR"
              />
            )}
            {showBookedMRR && (
              <Area
                type="linear"
                dataKey="bookedMRR"
                stroke="#cc0000"
                fill="#E9D6D5"
                name="Booked MRR"
              />
            )}
            {showContractedMRR && (
              <Area
                type="linear"
                dataKey="contractedMRR"
                stroke="#7BB2EE"
                fill="#DEE5F2"
                name="Contracted MRR"
              />
            )}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );

  function renderCard(
    title: string,
    data: any[],
    selectedMetric: "MRR" | "ARR"
  ) {
    let amount;
    if (selectedMetric === "MRR") {
      // Compute MRR
      amount = calculateMrr(data);
    } else {
      // Use the amount of the last data point
     amount = calculateArr(data)
    }

    return (
      <div
        className="bg-white p-4 border border-gray-100 rounded-md"
        style={cardStyle}
        key={title}
      >
        <div>
          <div className="font-bold mb-2 flex items-center relative">
            {title}
            <div className="icon-container">
              <div className="info-icon" title={title}>
                <svg
                  width="13.33"
                  height="13.33"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-black"
                  style={{
                    width: "25.33px",
                    height: "13.33px",
                    top: "1.33px",
                    left: "4.33px",
                  }}
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="10"
                    fill="rgba(0,0,0,0)"
                    stroke="black"
                    strokeWidth="2"
                  />
                  <text
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                    dy=".3em"
                    fontSize="14"
                    fill="black"
                  >
                    i
                  </text>
                </svg>
              </div>
            </div>
          </div>
          <div className="text-2xl font-bold text-gray-800">
            {formatAmount(amount)}
          </div>
        </div>
      </div>
    );
  }

  function formatAmount(amount: number | undefined) {
    if (amount !== undefined) {
      const amountInMillion = amount / 1_000_000;
      const formattedAmount = `${amountInMillion.toFixed(2)}M USD`;

      // Split the formatted amount into parts
      const [amountValue, currency] = formattedAmount.split(" ");

      return (
        <span>
          <span style={{ fontSize: "1.2em", color: "#0F0F0F" }}>
            {amountValue}
          </span>
          <span style={{ fontSize: "0.8em", color: "grey", marginLeft: "4px" }}>
            {currency}
          </span>
        </span>
      );
    }
    return "0M USD";
  }
  function prepareChartData(
    billingMrrData: any[],
    bookedMrrData: any[],
    contractedMrrData: any[],
    selectedMetric: "MRR" | "ARR",
    annualBilledMrr: any[],
    annualBookedMrr: any[],
    annualContractedMrr: any[],
  ) {
    // Create a Set to store unique x values
    const uniqueXValues = new Set<string>();
  
    if (selectedMetric === "MRR") {
      // Add x values from all datasets to the Set
      billingMrrData.forEach((entry) => uniqueXValues.add(formatMonth(entry.billing_month)));
      bookedMrrData.forEach((entry) => uniqueXValues.add(formatMonth(entry.billing_month)));
      contractedMrrData.forEach((entry) => uniqueXValues.add(formatMonth(entry.billing_month)));
    } else {
      // Add year values for annual MRR data
      annualBilledMrr.forEach((entry) => uniqueXValues.add(entry.year.toString()));
      annualBookedMrr.forEach((entry) => uniqueXValues.add(entry.year.toString()));
      annualContractedMrr.forEach((entry) => uniqueXValues.add(entry.year.toString()));
    }
  
    return Array.from(uniqueXValues).map((xValue) => ({
      x: xValue,
      billedMRR: selectedMetric === "MRR" ?
        billingMrrData.find((entry) => formatMonth(entry.billing_month) === xValue)?.amount_usd / 1_000_000 || null :
        annualBilledMrr.find((entry) => entry.year.toString() === xValue)?.amount_usd / 1_000_000 || null,
      bookedMRR: selectedMetric === "MRR" ?
        bookedMrrData.find((entry) => formatMonth(entry.billing_month) === xValue)?.amount_usd / 1_000_000 || null :
        annualBookedMrr.find((entry) => entry.year.toString() === xValue)?.amount_usd / 1_000_000 || null,
      contractedMRR: selectedMetric === "MRR" ?
        contractedMrrData.find((entry) => formatMonth(entry.billing_month) === xValue)?.amount_usd / 1_000_000 || null :
        annualContractedMrr.find((entry) => entry.year.toString() === xValue)?.amount_usd / 1_000_000 || null,
    }));
  }
  

  function calculateMrr(
    dataArray: { billing_month: string; amount_usd?: number }[] | undefined
  ): number {
    if (!dataArray) return 0;

    return dataArray.reduce((total, entry) => {
      const billingMonthDate = DateTime.fromISO(entry.billing_month);
      const fromDate = DateTime.fromISO(getFromDate(dateRange));

      if (billingMonthDate >= fromDate && entry.amount_usd !== undefined) {
        total += entry.amount_usd;
      }

      return total;
    }, 0);
  }

  function calculateArr(
    dataArray: { year: number; amount_usd?: number }[] | undefined
  ): number {
    if (!dataArray) return 0;
  
    return dataArray.reduce((total, entry) => {
      if (entry.amount_usd !== undefined) {
        total += entry.amount_usd;
      }
      return total;
    }, 0);
  }
  

  function generateYAxisTicks(data: any[]) {
    const maxMRR = Math.max(
      ...data.map((entry) =>
        Math.max(entry.billedMRR, entry.bookedMRR, entry.contractedMRR)
      )
    );
    const maxTickValue = Math.ceil(maxMRR / 4) * 4; // Round up to the nearest multiple of 4
    const ticks = [];

    for (let i = 0; i <= maxTickValue; i += 4) {
      ticks.push(i);
    }

    return ticks;
  }

  function getFromDate(monthRange: string) {
    return (
      DateTime.local()
        .startOf("month")
        .minus({ months: parseInt(monthRange) })
        .toISODate() || ""
    );
  }

  function getToDate() {
    return DateTime.local().minus({ months: 1 }).endOf("month").toISODate() || "";
  }
};

interface CheckboxToggleProps {
  label: string;
  color: string;
  checked: boolean;
  onChange: () => void;
}

const CheckboxToggle: React.FC<CheckboxToggleProps> = ({
  label,
  color,
  checked,
  onChange,
}) => {
  return (
    <label
      className={`inline-flex items-center cursor-pointer text-${color} mr-2`}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className={`form-checkbox h-5 w-5 text-${color}-500`}
      />
      <span className="ml-2">{label}</span>
    </label>
  );
};

export default DQCustomerMRRTrends;
