import React from "react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const SubTabs: React.FC<{
  tabList: any;
  currentTab: string;
  toggleTab: (arg: string) => void;
  customStyles?: string;
}> = ({ tabList, currentTab, toggleTab, customStyles = "fixed" }) => {
  let defValue = tabList.find((tab: any) => tab.name === currentTab);
  return (
    <div>
      {/* Mobile view */}
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          defaultValue={defValue.name}
        >
          {tabList.map((tab: any) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>

      {/* Desktop view */}
      <div className={`hidden sm:block bg-secondaryBg w-full`}>
        <div className="rounded-t-md">
          <nav className="-mb-px pb-2 flex space-x-2" aria-label="Tabs">
            {tabList.map((tab: any) => (
              <div
                key={tab.name}
                onClick={() => toggleTab(tab.name)}
                className={classNames(
                  tab.name === currentTab
                    ? "border-orange-500 bg-orange-50 text-orange-500 font-medium"
                    : "border-gray-200 bg-white text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap px-2 py-1 border rounded-md text-sm cursor-pointer"
                )}
              >
                {tab.name}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SubTabs;
