import { ChangeEvent, useState } from "react";
import { ModalType } from "../../pages/channels";
import { useDebounce } from "use-debounce";
import TableFilter from "../../common/table-filter";
import Loader from "../../common/loader";
import {
  useFeatureFlagQuery,
  useLinearChannelsByCustomerQuery,
} from "../../hasura.graphql";
import { useCDM } from "../../cdm-context";
import { useCDPRole } from "../../cdp-role-context";
import PopoverMenu from "../popover-menu";
import Modal from "../modal";
import ChannelViewForm from "../channels/channel-view-form";
import CustomerChannelCard from "../cards/customer-channel-card";
import { contentWidth } from "../utils";

const LinearChannelsByCustomer = () => {
  const { show } = useCDM();
  const [channelEdit, setChannelEdit] = useState<ModalType | null>(null);
  const [channelSearch, setChannelSearch] = useState<string>("");
  const [channelStatus, setChannelStatus] = useState<Array<string>>([
    "Ordered and Active",
  ]);
  const [debouncedChannelSearch] = useDebounce(channelSearch, 100);
  const { setCDPRole } = useCDPRole();

  const handleChannelStatus = (e: ChangeEvent<HTMLInputElement>) => {
    const t = e.target.name;
    if (channelStatus.length === 1 && channelStatus.includes(t)) {
      setChannelStatus([]);
    } else if (channelStatus && channelStatus.includes(t)) {
      setChannelStatus((prevChecked) => {
        return prevChecked.filter((v) => v !== t);
      });
    } else {
      setChannelStatus((prevChecked) => [...prevChecked, t]);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const role_data = useFeatureFlagQuery({
    onCompleted: (data) => {
      setCDPRole(data.feature_flag.map((feat) => feat.feature));
    },
  });

  const { data, loading, error } = useLinearChannelsByCustomerQuery({
    variables: {
      amagi_id: show?.amg_id!,
    },
  });

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <i>Error</i>;
  }
  if (!data) {
    return <i>Channels data not available</i>;
  }

  const orderedChannels = data.channel.filter((chn) =>
    channelStatus.includes(chn.ordered_channel!)
  );

  const debouncedChannels = orderedChannels.filter((channel) => {
    if (!debouncedChannelSearch) {
      return true;
    }
    return `${channel.id} ${channel.salesforce_id} ${channel.name} ${
      channel.customer?.account_name
    } ${channel.customer?.amagi_id} ${channel.genre
      .map((gen: string) => gen)
      .join(" ")}`
      .toLowerCase()
      .includes(debouncedChannelSearch.toLowerCase());
  });

  return (
    <div
      style={{
        width: contentWidth,
        height: window.innerHeight - 150,
        overflow: "scroll",
      }}
    >
      <div className="w-full flex justify-between z-10 sticky top-0 h-14 items-center bg-secondaryBg">
        <div className="text-lg font-medium">
          Linear Channels ({debouncedChannels.length})
        </div>
        <div className="flex w-2/5 items-center">
          <PopoverMenu
            icon={channelStatus.length > 0 ? "filter-fill.svg" : "filter.svg"}
            styles={`right-6`}
          >
            {[
              "Ordered and Active",
              "Ordered and Churned",
              "Opportunity / Quote Created",
              "No Active Deal",
            ].map((sts, index) => (
              <div className="py-px" key={index}>
                <input
                  type="checkbox"
                  className="focus:ring-0"
                  id={sts}
                  name={sts}
                  onChange={handleChannelStatus}
                  title={sts}
                  checked={channelStatus.includes(sts)}
                />
                <label htmlFor={sts} className="pl-2 text-sm">
                  {sts}
                </label>
              </div>
            ))}
          </PopoverMenu>
          <div className="w-full">
            <TableFilter
              filter={channelSearch}
              setFilter={setChannelSearch}
              placeholder="Search by channel, customer and genre"
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-5 gap-8 mt-3">
        {debouncedChannels.map((channel) => {
          return (
            <div
              key={channel.id}
              onClick={() => {
                setChannelEdit({
                  id: channel.id,
                  name: channel.name,
                  type: channel.channel_type!,
                });
              }}
              className="cursor-pointer transition-transform hover:scale-105"
            >
              <CustomerChannelCard
                channel={channel}
                url={`/channels/${
                  channel.channels_grouped !== null &&
                  channel.channels_grouped !== "No"
                    ? "grouped-channel"
                    : channel.channel_type
                    ? channel.channel_type.toLowerCase()
                    : "linear"
                }/${channel.id}`}
              />
            </div>
          );
        })}
      </div>
      {channelEdit && (
        <Modal
          title={`All ${
            channelEdit.type ? channelEdit.type : "Linear"
          } Channels`}
          modalWidth="100vw"
          modalShow={channelEdit}
          onClose={setChannelEdit}
        >
          <ChannelViewForm
            channelId={channelEdit.id}
            isModal={true}
            header={`All ${
              channelEdit.type ? channelEdit.type : "Linear"
            } Channels`}
            url={`/channels/${
              channelEdit?.type ? channelEdit?.type?.toLowerCase() : "linear"
            }`}
          />
        </Modal>
      )}
    </div>
  );
};

export default LinearChannelsByCustomer;
