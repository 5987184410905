import { useState } from "react";
import { ChanelFieldsFragment } from "../../../hasura.graphql";
import ToggleBox from "../../toggle-box";

const TechnicalSpecs: React.FC<{ channelData: ChanelFieldsFragment }> = ({
  channelData,
}) => {
  const [hideColumns, setHideColumns] = useState<Array<string>>([]);
  const handleHideColumns = (tab: string) => {
    setHideColumns((hideColumns) => [...hideColumns, tab]);
  };
  const handleUnhideColumns = (tab: string) => {
    setHideColumns(hideColumns.filter((f: string) => f !== tab));
  };
  return (
    <div style={{ color: "#4E4E4E", fontSize: "14px", fontWeight: "500" }}>
      <ToggleBox
        header="Broadcast Details"
        columns={hideColumns}
        handleHideColumns={handleHideColumns}
        handleUnhideColumns={handleUnhideColumns}
      >
        <>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div>
              <label className="text-gray-400">Time Zone</label>
              <div className="mt-2">{channelData?.time_zone || "-"}</div>
            </div>
            <div>
              <label className="text-gray-400">Start Time</label>
              <div className="mt-2">{channelData?.start_time || "-"}</div>
            </div>
          </div>
        </>
      </ToggleBox>
      <ToggleBox
        header="Channel Formats"
        columns={hideColumns}
        handleHideColumns={handleHideColumns}
        handleUnhideColumns={handleUnhideColumns}
      >
        <>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Playout Format</label>
              <div className="mt-2">{channelData?.playout_format || "-"}</div>
            </div>
            <div>
              <label className="text-gray-400">Video Format</label>
              <div className="mt-2">{channelData?.video_format || "-"}</div>
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Channel Resolution</label>
              <div className="mt-2">
                {channelData?.channel_resolution || "-"}
              </div>
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Audio Format</label>
              <div className="mt-2">{channelData?.audio_format || "-"}</div>
            </div>
            <div>
              <label className="text-gray-400">Playlist Format</label>
              <div className="mt-2">{channelData?.playlist_format || "-"}</div>
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Metadata Ingest Format</label>
              <div className="mt-2">
                {channelData?.metadata_ingest_format || "-"}
              </div>
            </div>
            <div>
              <label className="text-gray-400">Content Ingest Format</label>
              <div className="mt-2">
                {channelData?.content_ingest_format || "-"}
              </div>
            </div>
          </div>
        </>
      </ToggleBox>
      <ToggleBox
        header="Live"
        columns={hideColumns}
        handleHideColumns={handleHideColumns}
        handleUnhideColumns={handleUnhideColumns}
      >
        <>
          <div className="grid grid-cols-4 gap-y-8 gap-x-36">
            <div>
              <label className="text-gray-400">Live Playout</label>
              <div className="mt-2">
                {channelData?.live_playout !== null
                  ? channelData?.live_playout
                    ? "Yes"
                    : "No"
                  : "-"}
              </div>
            </div>
            {channelData?.live_playout && (
              <>
                <div>
                  <label className="text-gray-400">Live Feed Input</label>
                  <div className="mt-2">
                    {channelData?.live_feed_input || "-"}
                  </div>
                </div>
                <div>
                  <label className="text-gray-400">Live Recording</label>
                  <div className="mt-2">
                    {channelData?.live_recording !== null
                      ? channelData?.live_recording
                        ? "Yes"
                        : "No"
                      : "-"}
                  </div>
                </div>
                <div>
                  <label className="text-gray-400">Live Management</label>
                  <div className="mt-2">
                    {channelData?.live_management !== null
                      ? channelData?.live_management
                        ? "Yes"
                        : "No"
                      : "-"}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      </ToggleBox>
      <ToggleBox
        header="Captions"
        columns={hideColumns}
        handleHideColumns={handleHideColumns}
        handleUnhideColumns={handleUnhideColumns}
      >
        <>
          <div className="grid grid-cols-4 gap-y-8 gap-x-36">
            <div>
              <label className="text-gray-400">Closed Captions</label>
              <div className="mt-2">
                {channelData?.closed_caption !== null
                  ? channelData?.closed_caption
                    ? "Yes"
                    : "No"
                  : "-"}
              </div>
            </div>
            {channelData?.closed_caption && (
              <>
                <div>
                  <label className="text-gray-400">Closed Captions Type</label>
                  <div className="mt-2">
                    {channelData?.closed_caption_type || "-"}
                  </div>
                </div>
                <div>
                  <label className="text-gray-400">
                    Closed Captions Language
                  </label>
                  <div className="mt-2">
                    {channelData?.closed_caption_language || "-"}
                  </div>
                </div>
                <div>
                  <label className="text-gray-400">
                    Amagi Auto CC Generation
                  </label>
                  <div className="mt-2">
                    {channelData?.amagi_auto_cc_generation !== null
                      ? channelData?.amagi_auto_cc_generation
                        ? "Yes"
                        : "No"
                      : "-"}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      </ToggleBox>
      <ToggleBox
        header="Graphics"
        columns={hideColumns}
        handleHideColumns={handleHideColumns}
        handleUnhideColumns={handleUnhideColumns}
      >
        <>
          <div className="grid grid-cols-4 gap-y-8 gap-x-36">
            <div>
              <label className="text-gray-400">Graphics Enabled</label>
              <div className="mt-2">
                {channelData?.graphics_enabled !== null
                  ? channelData?.graphics_enabled
                    ? "Yes"
                    : "No"
                  : "-"}
              </div>
            </div>
            {channelData?.graphics_enabled && (
              <>
                <div>
                  <label className="text-gray-400">Graphics Type</label>
                  <div className="mt-2">
                    {channelData?.graphics_type || "-"}
                  </div>
                </div>
                <div>
                  <label className="text-gray-400">Channel LOGO Bug</label>
                  <div className="mt-2">
                    {channelData?.channel_logo_bug !== null
                      ? channelData?.channel_logo_bug
                        ? "Yes"
                        : "No"
                      : "-"}
                  </div>
                </div>
                <div>
                  <label className="text-gray-400">Graphics Format</label>
                  <div className="mt-2">
                    {channelData?.graphics_format || "-"}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      </ToggleBox>
    </div>
  );
};

export default TechnicalSpecs;
