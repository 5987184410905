import { DateTime } from "luxon";
// import { useMemo } from "react";
import { useCDM } from "../cdm-context";
import Loader from "../common/loader";
import { useServiceAvailabilityQuery } from "../hasura.graphql";
import DelayedToolTip from "./delayed-tooltip";
import { InformationCircleIcon } from "@heroicons/react/outline";

const ServiceAvailability = () => {
  const { show } = useCDM();
  // const from = useMemo(
  //   () => DateTime.now().minus({ month: 1 }).startOf("month"),
  //   []
  // );
  // const to = useMemo(
  //   () => DateTime.now().minus({ month: 1 }).endOf("month"),
  //   []
  // );
  // const eom = useMemo(() => DateTime.now().endOf("month"), []);

  const { data, loading, error } = useServiceAvailabilityQuery({
    variables: {
      amagi_id: show!.amg_id,
      // from: from.toISO(),
      // to: to.toISO(),
      // eom: eom.toISO(),
    },
  });

  if (loading) {
    return <Loader />;
  }

  if (!data) {
    return <i>Data Unavailable</i>;
  }

  if (error) {
    return <i>There was an error</i>;
  }

  const currentMonth = DateTime.local().startOf("month").toISO().slice(0, 10);
  const previousMonth = DateTime.local()
    .minus({ month: 1 })
    .startOf("month")
    .toISO()
    .slice(0, 10);

  const currentAvgServiceAvailability = data.customer?.avg_service_availibity
    ? data.customer?.avg_service_availibity.find(
        (sla) => sla.months === currentMonth
      )?.avg_service_availability
    : null;

  const previousAvgServiceAvailability = data.customer?.avg_service_availibity
    ? data.customer?.avg_service_availibity.find(
        (sla) => sla.months === previousMonth
      )?.avg_service_availability
    : null;

  // const uptime_previous =
  //   to.diff(from, "seconds").seconds -
  //   (data?.previous.aggregate?.sum?.duration || 0);

  // const uptime_current =
  //   DateTime.now().diff(to, "seconds").seconds -
  //   (data?.current.aggregate?.sum?.duration || 0);

  console.log(currentAvgServiceAvailability);

  // const serviceCreditPayable = () => {
  //   if (currentAvgServiceAvailability < 98) return "5%";
  //   if (
  //     currentAvgServiceAvailability > 98.01 &&
  //     currentAvgServiceAvailability < 99
  //   )
  //     return "4%";
  //   if (
  //     currentAvgServiceAvailability > 99.01 &&
  //     currentAvgServiceAvailability < 99.1
  //   )
  //     return "3%";
  //   if (
  //     currentAvgServiceAvailability > 99.11 &&
  //     currentAvgServiceAvailability < 99.2
  //   )
  //     return "2%";
  //   if (
  //     currentAvgServiceAvailability > 99.21 &&
  //     currentAvgServiceAvailability < 99.5
  //   )
  //     return "1%";
  //   if (currentAvgServiceAvailability > 99.5) return "0%";
  // };

  return (
    <div className={`amagi-card bg-white border`}>
      <div className="flex justify-start items-center w-full mb-16">
        <h3
          className="amagi-card-title"
          style={{ color: "#4E4E4E", fontSize: 18 }}
        >
          Average Service Availability
        </h3>
        <span className="ml-2">
          <DelayedToolTip delay={0} title={<PopupComponent />} position="top">
            <InformationCircleIcon
              className="w-4 h-4"
              style={{ color: "#94ACEB" }}
            />
          </DelayedToolTip>
        </span>
      </div>
      <div className="amagi-card-body">
        <div className="flex text-primaryText">
          <div>
            <span>Current Month</span>
            {/* <h1 className="text-3xl">
            {(
              (uptime_current * 100) /
              DateTime.now().diff(to, "seconds").seconds
            ).toFixed(2)}
            %
          </h1> */}
            <h1 className="text-3xl">
              {currentAvgServiceAvailability
                ? `${currentAvgServiceAvailability}%`
                : "100%"}
            </h1>
          </div>
          <div className="ml-32">
            <span>Previous Month</span>
            {/* <h1 className="text-3xl">
            {(
              (uptime_previous * 100) /
              to.diff(from, "seconds").seconds
            ).toFixed(2)}
            %
          </h1> */}
            <h1 className="text-3xl">
              {previousAvgServiceAvailability
                ? `${previousAvgServiceAvailability}%`
                : "100%"}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

const PopupComponent = () => {
  return (
    <div>
      <table className="border" style={{ fontSize: 10 }}>
        <thead>
          <tr className="border-b">
            <td className="border-r" style={{ fontSize: 10 }}>
              Service Level Uptime
            </td>
            <td style={{ fontSize: 10 }}>
              Service Credit Payable as a percentage of invoice value
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="border-b">
            <td className="border-r" style={{ fontSize: 10 }}>
              Less than 98.0%
            </td>
            <td style={{ fontSize: 10 }}>5%</td>
          </tr>
          <tr className="border-b">
            <td className="border-r" style={{ fontSize: 10 }}>
              98.01% to 99.00%
            </td>
            <td style={{ fontSize: 10 }}>4%</td>
          </tr>
          <tr className="border-b">
            <td className="border-r" style={{ fontSize: 10 }}>
              99.01% to 99.10%
            </td>
            <td style={{ fontSize: 10 }}>3%</td>
          </tr>
          <tr className="border-b">
            <td className="border-r" style={{ fontSize: 10 }}>
              99.11% to 99.20%
            </td>
            <td style={{ fontSize: 10 }}>2%</td>
          </tr>
          <tr className="border-b">
            <td className="border-r" style={{ fontSize: 10 }}>
              99.21% to 99.5%
            </td>
            <td style={{ fontSize: 10 }}>1%</td>
          </tr>
          <tr>
            <td className="border-r" style={{ fontSize: 10 }}>
              Greater than 99.5%
            </td>
            <td style={{ fontSize: 10 }}>0%</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ServiceAvailability;
