import { useMemo } from "react";
import { useCDM } from "../../cdm-context";
import NestedToggleTableContainer from "../../common/nested-table-toggle-container";
import { CustomerFdTicketsDataFragment } from "../../hasura.graphql";
import CountCard from "../cards/count-card";
import DelayedToolTip from "../delayed-tooltip";
import { compareHST, DateParser, DateSort } from "../utils";
import { FDTicketType, StatusType } from "./types";
import { useNavigate } from "react-router-dom";
import { TransactionType } from "../financials";
import TableRowsFilter from "../../common/filters/table-rows-filter";

const BillingTicket: React.FC<{
  tickets: Array<CustomerFdTicketsDataFragment>;
  status: Array<StatusType>;
  source: Array<StatusType>;
  priority: Array<string>;
  isModal: boolean;
  setTransaction: React.Dispatch<React.SetStateAction<TransactionType>>;
  setTab: (tab: string) => void;
}> = ({
  tickets,
  status,
  source,
  priority,
  isModal,
  setTab,
  setTransaction,
}) => {
  const { show } = useCDM();
  const navigate = useNavigate();
  const openTickets = tickets.filter((f) => f.status !== 5);
  const resolvedTickets = tickets.filter((f) => f.status === 4);
  const closedTickets = tickets.filter((f) => f.status === 5);

  const high_standing_open_tickets = openTickets.slice().sort(compareHST);
  const high_standing_resolved_tickets = resolvedTickets
    .slice()
    .sort(compareHST);
  const high_standing_close_tickets = closedTickets.slice().sort(compareHST);

  const all_tickets = [
    ...high_standing_open_tickets,
    ...high_standing_resolved_tickets,
    ...high_standing_close_tickets,
  ];

  const columns: any = useMemo(
    () => [
      {
        Header: "TICKET ID",
        accessor: "ticket_id",
        Cell: ({ cell }: any) => (
          <a
            href={`https://support.amagi.tv/a/tickets/${cell.row.original.ticket_id}`}
            className="text-blue-500 underline"
            target="_blank"
            rel="noreferrer"
          >
            {cell.row.original.ticket_id}
          </a>
        ),
      },
      {
        Header: "PRODUCT",
        accessor: "product_resp",
        Filter: TableRowsFilter,
        positionStyles: "left-0",
        filter: "multiSelect",
      },
      {
        Header: "SUBJECT",
        accessor: "subject",
        Cell: ({ cell }: any) => (
          <DelayedToolTip title={cell.row.original.subject} position="top">
            <span className="inline-block">
              {cell.row.values.subject && cell.row.values.subject.length > 30
                ? `${cell.row.values.subject.slice(0, 30)}...`
                : cell.row.values.subject || "--"}
            </span>
          </DelayedToolTip>
        ),
      },
      {
        Header: "SEVERITY",
        accessor: "impact",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "PRIORITY",
        accessor: "priority",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "STATUS",
        accessor: "status",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "PRODUCT AREA",
        accessor: "product_resp_area",
      },
      {
        Header: "DESCRIPTION",
        accessor: "description",
        Cell: ({ cell }: any) => (
          <DelayedToolTip title={cell.row.original.description} position="top">
            <span className="inline-block">
              {cell.row.values.description &&
              cell.row.values.description.length > 30
                ? `${cell.row.values.description.slice(0, 30)}...`
                : cell.row.values.description || "--"}
            </span>
          </DelayedToolTip>
        ),
      },
      {
        Header: "CHANNEL",
        accessor: "delivery_channel",
        Cell: ({ cell }: any) => (
          <DelayedToolTip
            title={cell.row.original.delivery_channel}
            position="top"
          >
            <span className="inline-block">
              {cell.row.values.delivery_channel &&
              cell.row.values.delivery_channel.length > 30
                ? `${cell.row.values.delivery_channel.slice(0, 30)}...`
                : cell.row.values.delivery_channel || "--"}
            </span>
          </DelayedToolTip>
        ),
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "PRODUCT COMPONENT",
        accessor: "product_resp_area_component",
      },
      {
        Header: "INVOICE NUMBER",
        accessor: "invoice_number",
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.invoice_number.map(
              (invoice: any, index: number) => (
                <span
                  key={index}
                  className="border border-gray-300 rounded-xl px-2 py-1 text-xs mx-1 hover:text-blue-500 hover:underline cursor-pointer"
                  onClick={() => {
                    setTab("Financials");
                    isModal
                      ? setTransaction({
                          id: invoice,
                          date: cell.row.values.invoice_month,
                        })
                      : navigate(
                          `/customers/${show?.amg_id}/invoices?search=${invoice}&date=${cell.row.values.invoice_month}`
                        );
                  }}
                >
                  {invoice}
                </span>
              )
            )}
          </span>
        ),
      },
      {
        Header: "INVOICE MONTH",
        accessor: "invoice_month",
      },
      {
        Header: "INVOICE AMOUNT",
        accessor: "invoice_amount",
      },
      {
        Header: "DISPUTED AMOUNT",
        accessor: "disputed_amount",
      },
      {
        Header: "CREDIT AMOUNT",
        accessor: "credit_amount",
      },
      {
        Header: "CREDIT CURRENCY",
        accessor: "credit_currency",
      },
      {
        Header: "CHARGE CODES",
        accessor: "charge_codes",
      },
      {
        Header: "REGION",
        accessor: "region",
      },
      {
        Header: "COORDINATOR",
        accessor: "coordinator",
      },
      {
        Header: "BILLING ISSUE STATUS",
        accessor: "billing_issue_status",
      },
      {
        Header: "ROOT CAUSE CATEGORY",
        accessor: "root_cause_category",
      },
      {
        Header: "ROOT CAUSE OWNER",
        accessor: "root_cause_owner",
      },
      {
        Header: "GROUP",
        accessor: "group",
      },
      {
        Header: "GROUP AGENT",
        accessor: "group_agent",
      },
      {
        Header: "SOURCE",
        accessor: "source",
      },
      {
        Header: "LAST UPDATED DATE",
        accessor: "last_updated_date",
        sortType: (a: any, b: any) =>
          DateSort(a.values.last_updated_date, b.values.last_updated_date),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.last_updated_date !== "--"
              ? DateParser(cell.row.values.last_updated_date)
              : "--"}
          </span>
        ),
      },
      {
        Header: "DISPUTE TYPE",
        accessor: "dispute_type",
      },
      {
        Header: "DISPUTE OUTCOME",
        accessor: "dispute_outcome",
      },
      {
        Header: "TICKET TYPE",
        accessor: "ticket_type",
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "CREATED ON",
        accessor: "created_date",
        sortType: (a: any, b: any) =>
          DateSort(a.values.created_date, b.values.created_date),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.created_date !== "--"
              ? DateParser(cell.row.values.created_date)
              : "--"}
          </span>
        ),
      },
      {
        Header: "CLOSED ON",
        accessor: "closed_date",
        sortType: (a: any, b: any) =>
          DateSort(a.values.closed_date, b.values.closed_date),
        Cell: ({ cell }: any) => (
          <span>
            {cell.row.values.closed_date !== "--"
              ? DateParser(cell.row.values.closed_date)
              : "--"}
          </span>
        ),
      },
      {
        Header: "TAGS",
        accessor: "tags",
      },
      {
        Header: "ROOT CAUSE",
        accessor: "root_cause",
      },
    ],
    [isModal, navigate, setTab, setTransaction, show?.amg_id]
  );

  const exportHeaders = columns.map((col: any) => {
    return {
      label: col.Header,
      key: col.accessor,
    };
  });

  const filteredTickets: Array<FDTicketType> = all_tickets.map((ticket) => {
    return {
      ticket_id: ticket.ticket_id,
      subject: ticket.subject ? ticket.subject : "--",
      description: ticket.description ? ticket.description : "--",
      delivery_channel: ticket.delivery_channel
        ? ticket.delivery_channel
        : "--",
      impact: ticket.impact ? ticket.impact : "--",
      priority: priority[ticket.priority - 1],
      product_resp: ticket.product_resp ? ticket.product_resp : "--",
      product_resp_area: ticket.product_resp_area
        ? ticket.product_resp_area
        : "--",
      product_resp_area_component: ticket.product_resp_area_component
        ? ticket.product_resp_area_component
        : "--",
      ticket_type: ticket.ticket_type ? ticket.ticket_type : "--",
      status: status.find((st: any) => st.key === ticket.status)?.value || "--",
      created_date: ticket.created_date ? ticket.created_date : "--",
      closed_date:
        ticket.closed_at !== null &&
        status.find((st: any) => st.key === ticket.status)?.value === "Closed"
          ? ticket.closed_at
          : "--",
      tags: ticket.tags,
      root_cause: ticket.root_cause ? ticket.root_cause : "-",
      invoice_number: ticket.invoice_number
        ? ticket.invoice_number?.split(", ")
        : [],
      invoice_month: ticket.invoice_month ? ticket.invoice_month : "--",
      invoice_amount: ticket.invoice_amount ? ticket.invoice_amount : "--",
      disputed_amount: ticket.disputed_amount ? ticket.disputed_amount : "--",
      credit_amount: ticket.credit_amount ? ticket.credit_amount : "--",
      credit_currency: ticket.credit_currency ? ticket.credit_currency : "--",
      charge_codes: ticket.charge_codes ? ticket.charge_codes : "--",
      region: ticket.region ? ticket.region : "--",
      coordinator: ticket.coordinator ? ticket.coordinator : "--",
      billing_issue_status: ticket.billing_issue_status
        ? ticket.billing_issue_status
        : "--",
      root_cause_category: ticket.root_cause_category
        ? ticket.root_cause_category
        : "--",
      root_cause_owner: ticket.root_cause_owner
        ? ticket.root_cause_owner
        : "--",
      group: ticket.group ? ticket.group : "--",
      group_agent: ticket.group_agent ? ticket.group_agent : "--",
      customer_id: ticket.customer_id ? ticket.customer_id : "--",
      customer_name: ticket.customer_name ? ticket.customer_name : "--",
      source: source.find((st: any) => st.key === ticket.source)?.value || "--",
      last_updated_date: ticket.last_updated_date
        ? ticket.last_updated_date
        : "--",
      dispute_type: ticket.dispute_type ? ticket.dispute_type : "--",
      dispute_outcome: ticket.dispute_outcome ? ticket.dispute_outcome : "--",
    };
  });

  const exportTickets = filteredTickets.map((ticket) => {
    return {
      ticket_id: ticket.ticket_id,
      subject: ticket.subject,
      description: ticket.description,
      delivery_channel: ticket.delivery_channel,
      impact: ticket.impact,
      priority: ticket.priority,
      product_resp: ticket.product_resp,
      product_resp_area: ticket.product_resp_area,
      product_resp_area_component: ticket.product_resp_area_component,
      ticket_type: ticket.ticket_type,
      status: ticket.status,
      created_date:
        ticket.created_date !== "--" ? DateParser(ticket.created_date) : "--",
      closed_date:
        ticket.closed_date !== "--" ? DateParser(ticket.closed_date) : "--",
      tags: ticket.tags,
      root_cause: ticket.root_cause,
      invoice_number: ticket.invoice_number,
      invoice_month: ticket.invoice_month,
      invoice_amount: ticket.invoice_amount,
      disputed_amount: ticket.disputed_amount,
      credit_amount: ticket.credit_amount,
      credit_currency: ticket.credit_currency,
      charge_codes: ticket.charge_codes,
      region: ticket.region,
      coordinator: ticket.coordinator,
      billing_issue_status: ticket.billing_issue_status,
      root_cause_category: ticket.root_cause_category,
      root_cause_owner: ticket.root_cause_owner,
      group: ticket.group,
      group_agent: ticket.group_agent,
      customer_id: ticket.customer_id,
      customer_name: ticket.customer_name,
      source: ticket.source,
      last_updated_date:
        ticket.last_updated_date !== "--"
          ? DateParser(ticket.last_updated_date)
          : "--",
      dispute_type: ticket.dispute_type,
      dispute_outcome: ticket.dispute_outcome,
    };
  });

  return (
    <div>
      <div className="my-4 grid grid-cols-4 gap-4">
        <CountCard
          count={tickets.length}
          cardText="Total Tickets"
          icon="/assets/total-tickets.svg"
        />
        <CountCard
          count={openTickets.length}
          cardText="Open Tickets"
          icon="/assets/open-tickets.svg"
        />
        <CountCard
          count={resolvedTickets.length}
          cardText="Resolved Tickets"
          icon="/assets/closed-tickets.svg"
        />
        <CountCard
          count={closedTickets.length}
          cardText="Closed Tickets"
          icon="/assets/closed-tickets.svg"
        />
      </div>
      {filteredTickets.length > 0 ? (
        <NestedToggleTableContainer
          open=""
          header="Ticket Details"
          warning="Tickets"
          height={`${window.innerHeight - 410}px`}
          placeholder="Search for tickets"
          data={filteredTickets}
          columns={columns}
          exportData={exportTickets}
          exportHeader={exportHeaders}
          fileName={`${show?.amg_id}_Product_Tickets`}
        />
      ) : (
        <div className="p-4 mt-4 bg-white border rounded-md italic font-medium">
          No Billing Tickets generated
        </div>
      )}
    </div>
  );
};

export default BillingTicket;
