import { useState } from "react";
import { ChanelFieldsFragment } from "../../../hasura.graphql";
import ToggleBox from "../../toggle-box";

const LegalRights: React.FC<{ channelData: ChanelFieldsFragment }> = ({
  channelData,
}) => {
  const [hideColumns, setHideColumns] = useState<Array<string>>([]);
  const handleHideColumns = (tab: string) => {
    setHideColumns((hideColumns) => [...hideColumns, tab]);
  };
  const handleUnhideColumns = (tab: string) => {
    setHideColumns(hideColumns.filter((f: string) => f !== tab));
  };

  return (
    <div style={{ color: "#4E4E4E", fontSize: "14px", fontWeight: "500" }}>
      <ToggleBox
        header="Channel Legal Rights"
        columns={hideColumns}
        handleHideColumns={handleHideColumns}
        handleUnhideColumns={handleUnhideColumns}
      >
        <>
          <div className="grid grid-cols-3 gap-y-8 gap-x-36">
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Subtitling</label>
              <div className="mt-2">
                {channelData?.subtitling !== null
                  ? channelData?.subtitling
                    ? "Yes"
                    : "No"
                  : "-"}
              </div>
            </div>
            <div>
              <label className="text-gray-400">Closed Captioning</label>
              <div className="mt-2">
                {channelData?.closed_captioning !== null
                  ? channelData?.closed_captioning
                    ? "Yes"
                    : "No"
                  : "-"}
              </div>
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Dubbing</label>
              <div className="mt-2">
                {channelData?.dubbing !== null
                  ? channelData?.dubbing
                    ? "Yes"
                    : "No"
                  : "-"}
              </div>
            </div>
            <div>
              <label className="text-gray-400">Sponsorship</label>
              <div className="mt-2">
                {channelData?.sponsorship !== null
                  ? channelData?.sponsorship
                    ? "Yes"
                    : "No"
                  : "-"}
              </div>
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">
                Trademarks & Logos of content partner
              </label>
              <div className="mt-2">
                {channelData?.trademark_and_logo !== null
                  ? channelData?.trademark_and_logo
                    ? "Yes"
                    : "No"
                  : "-"}
              </div>
            </div>
            <div>
              <label className="text-gray-400">
                Create promotional / marketing material
              </label>
              <div className="mt-2">
                {channelData?.create_promotional !== null
                  ? channelData?.create_promotional
                    ? "Yes"
                    : "No"
                  : "-"}
              </div>
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">
                Edit promotional / marketing material provided by content owner
              </label>
              <div className="mt-2">
                {channelData?.edit_promotional !== null
                  ? channelData?.edit_promotional
                    ? "Yes"
                    : "No"
                  : "-"}
              </div>
            </div>
            <div>
              <label className="text-gray-400">Create short form videos</label>
              <div className="mt-2">
                {channelData?.create_short_form_video !== null
                  ? channelData?.create_short_form_video
                    ? "Yes"
                    : "No"
                  : "-"}
              </div>
            </div>
            <div style={{ display: "grid", gridColumn: "1" }}>
              <label className="text-gray-400">Create pop-channels</label>
              <div className="mt-2">
                {channelData?.create_pop_channel !== null
                  ? channelData?.create_pop_channel
                    ? "Yes"
                    : "No"
                  : "-"}
              </div>
            </div>
            <div>
              <label className="text-gray-400">
                Use of display technology (AR/VR)
              </label>
              <div className="mt-2">
                {channelData?.use_of_display_technology !== null
                  ? channelData?.use_of_display_technology
                    ? "Yes"
                    : "No"
                  : "-"}
              </div>
            </div>
          </div>
        </>
      </ToggleBox>
    </div>
  );
};

export default LegalRights;
