import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarController,
  LineController,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  PointElement,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "react-chartjs-2";
import { useCDM } from "../../cdm-context";
import { currencyConverter } from "../utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarController,
  LineController,
  BarElement,
  ChartDataLabels,
  LineElement,
  Title,
  PointElement,
  Tooltip,
  Legend
);

const CommonChart: React.FC<{
  chartData: any;
  showLegend: boolean;
  stackedBar?: boolean;
  showDatalabels?: boolean;
  labelRotation?: boolean;
}> = ({
  chartData,
  showLegend,
  stackedBar = false,
  showDatalabels = false,
  labelRotation = true,
}) => {
  const { show } = useCDM();
  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        stacked: stackedBar,
        grid: {
          drawBorder: false,
          display: true,
        },
        ticks: {
          padding: 8,
          font: {
            size: 10,
          },
          beginAtZero: true,
          callback: function (value: number) {
            return currencyConverter(value, show?.currency || "USD", "0.00a");
          },
        },
      },
      x: {
        stacked: stackedBar,
        ticks: {
          font: {
            size: 10,
          },
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        ticks: {
          font: {
            size: 10,
          },
        },
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: show?.currency || "USD",
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
      legend: {
        display: showLegend,
        // position: "top",
        // align: "end",
        // onClick: (legendItem: any, legend: any) => {
        //   const datasets = legend.legendItems.map((dataset: any) => {
        //     return dataset.text;
        //   });
        //   const index = datasets.indexOf(legendItem.text);
        //   if (legend.chart.isDatasetVisible(index) === true) {
        //     legend.chart.hide(index);
        //   } else {
        //     legend.chart.show(index);
        //   }
        // },
        // labels: {
        //   usePointStyle: true,
        //   boxWidth: 5,
        //   boxHeight: 5,
        //   generateLabels: (chart: any) => {
        //     let visibility: Array<boolean> = [];
        //     for (let i = 0; i < chart.data.datasets.length; i++) {
        //       if (chart.isDatasetVisible(i) === true) {
        //         visibility.push(false);
        //       } else {
        //         visibility.push(true);
        //       }
        //     }
        //     let pointStyle: Array<string> = [];
        //     chart.data.datasets.forEach((dataset: any) => {
        //       if (dataset.type === "line") {
        //         pointStyle.push("line");
        //       } else {
        //         pointStyle.push("rect");
        //       }
        //     });
        //     return chart.data.datasets.map((dataset: any, index: number) => {
        //       return {
        //         text: dataset.label,
        //         fillStyle: dataset.backgroundColor,
        //         strokeStyle: dataset.backgroundColor,
        //         lineWidth: 3,
        //         pointStyle: pointStyle[index],
        //         hidden: visibility[index],
        //       };
        //     });
        //   },
        // },
      },
      datalabels: {
        display: "auto",
        formatter: (value: any, context: any) => {
          if (
            showDatalabels &&
            value !== null &&
            value !== 0 &&
            context.dataset.type === "bar"
          ) {
            return currencyConverter(value, show?.currency || "USD", "0.00a");
          }
          return null;
        },
        clamp: false,
        align: "top",
        anchor: "end",
        color: "gray",
        font: {
          size: "10px",
          weight: "bold",
        },
        rotation: labelRotation ? 315 : 0,
      },
    },
  };

  return <Chart type="bar" data={chartData} options={options} />;
};

export default CommonChart;
