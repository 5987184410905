import React, { useState } from "react";
import { CustomerAllDataFragment } from "../../hasura.graphql";
import Tabs from "../tabs";
import SalesWrapper from "../sales/wrapper";
import FDTicketsWrapper from "../fd-tickets/wrapper";
import ContactsWrapper from "../contacts/wrapper";
import Overview from "../overview";
import OrdersWrapper from "../orders/wrapper";
import ContractDetails from "../contract-details";
import CustomerChannels from "../customer-channels";
import Financials, { TransactionType } from "../financials";
import CustomerDeliveries from "../customer-delivery";
import CustomerDetails from "../customer-details";
import QuotesWrapper from "../quotes/wrapper";
import ProductsWrapper from "../products/wrapper";
import { useNavigate, useParams } from "react-router-dom";
import { customerRoutes } from "../../app-routes";

export const CustomerTabs: React.FC<{
  customer: CustomerAllDataFragment;
  isModal: boolean;
  param?: string;
}> = ({ customer, isModal, param }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParam, setSearchParam] = useState("");
  const [transaction, setTransaction] = useState<TransactionType>({
    id: "",
    date: null,
  });
  const [navTab, setNavTab] = useState(
    param === "Details"
      ? `${
          customer.account_name && customer.account_name.length > 15
            ? `${customer.account_name?.slice(0, 15)}...`
            : customer.account_name
        }'s Details`
      : param ||
          `${
            customer.account_name && customer.account_name.length > 15
              ? `${customer.account_name?.slice(0, 15)}...`
              : customer.account_name
          }'s Details`
  );

  const handleTab = (tab: string) => {
    const url = customerRoutes.find(
      (route) =>
        route.label === tab ||
        (tab.includes("Details") && !tab.includes("Contract"))
    )?.param;
    setNavTab(tab);
    !isModal && navigate(`/customers/${id}/${url}`);
  };

  return (
    <>
      <div className={`mb-4 sticky z-10`} style={{ top: isModal ? 0 : 56 }}>
        <Tabs
          currentTab={navTab}
          tabList={[
            {
              name: `${
                customer.account_name && customer.account_name.length > 15
                  ? `${customer.account_name?.slice(0, 15)}...`
                  : customer.account_name
              }'s Details`,
            },
            { name: "Overview" },
            { name: "Contract Details" },
            { name: "Opportunities" },
            { name: "Quotes" },
            { name: "Orders" },
            { name: "Financials" },
            { name: "Products" },
            { name: "Channels" },
            { name: "Deliveries" },
            { name: "Service Tickets" },
            { name: "Contacts" },
          ]}
          toggleTab={handleTab}
        />
      </div>
      <div className={`mx-6`}>
        {navTab ===
          `${
            customer.account_name && customer.account_name.length > 15
              ? `${customer.account_name?.slice(0, 15)}...`
              : customer.account_name
          }'s Details` && <CustomerDetails customer={customer} />}
        {navTab === "Overview" && <Overview amagi_id={customer.amagi_id!} />}
        {navTab === "Contract Details" && <ContractDetails />}
        {navTab === "Opportunities" && (
          <SalesWrapper
            amagi_id={customer.amagi_id!}
            searchParam={searchParam}
          />
        )}
        {navTab === "Quotes" && (
          <QuotesWrapper
            isModal={isModal}
            setTab={setNavTab}
            setSearchParams={setSearchParam}
          />
        )}
        {navTab === "Financials" && (
          <Financials
            isModal={isModal}
            setTransaction={setTransaction}
            transaction={transaction}
          />
        )}
        {navTab === "Products" && (
          <ProductsWrapper amagi_id={customer.amagi_id!} />
        )}
        {navTab === "Orders" && (
          <OrdersWrapper
            isModal={isModal}
            setTab={setNavTab}
            setSearchParams={setSearchParam}
          />
        )}
        {navTab === "Channels" && <CustomerChannels />}
        {navTab === "Deliveries" && <CustomerDeliveries />}
        {navTab === "Service Tickets" && (
          <FDTicketsWrapper
            amagi_id={customer.amagi_id!}
            isModal={isModal}
            setTab={setNavTab}
            setTransaction={setTransaction}
          />
        )}
        {navTab === "Contacts" && (
          <ContactsWrapper amagi_id={customer.amagi_id!} />
        )}
      </div>
    </>
  );
};
