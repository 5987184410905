import { Tooltip } from "@mui/material";

const DelayedToolTip: React.FC<{
  children: React.ReactNode;
  title: string | React.ReactNode;
  delay?: number;
  position?:
    | "bottom"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  styles?: string;
}> = ({
  children,
  title,
  position = "bottom",
  delay = 1000,
  styles = "justify-center",
}) => {
  return (
    <Tooltip
      arrow
      title={title}
      enterDelay={delay}
      leaveDelay={0}
      placement={position}
    >
      <span
        style={{ minWidth: 16, minHeight: 16 }}
        className={`flex items-center ${styles} justify-center`}
      >
        {children}
      </span>
    </Tooltip>
  );
};

export default DelayedToolTip;
