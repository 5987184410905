import React from "react";

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const Tabs: React.FC<{
  tabList: any;
  currentTab: string;
  toggleTab: (tab: string) => void;
  customStyles?: string;
}> = ({ tabList, currentTab, toggleTab, customStyles = "fixed" }) => {
  let defValue = tabList.find((tab: any) => tab.name === currentTab);
  return (
    <div>
      {/* Mobile view */}
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          defaultValue={defValue.name}
        >
          {tabList.map((tab: any) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>

      {/* Desktop view */}
      <div
        className={`hidden sm:block px-5 bg-gradient-to-r from-gradientBlue via-gradientBlue to-gradientPurple w-full`}
      >
        <div className="rounded-t-md">
          <nav className="-mb-px py-1 flex space-x-8" aria-label="Tabs">
            {tabList.map((tab: any) => (
              <div
                key={tab.name}
                onClick={() => toggleTab(tab.name)}
                className={classNames(
                  tab.name === currentTab
                    ? "text-orange-500"
                    : "text-white hover:scale-105 transition-all",
                  "whitespace-nowrap p-2 font-medium text-sm cursor-pointer"
                )}
              >
                {tab.name}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
