// import {
//   CheckIcon,
//   InformationCircleIcon,
//   XIcon,
// } from "@heroicons/react/outline";
import { useField } from "formik";
import React, { useEffect, useState } from "react";

interface CheckBoxType {
  paid_flag: boolean | null | undefined;
  ad_flag: boolean | null | undefined;
  promotion_flag: boolean | null | undefined;
  is_adsplus_channel: boolean | null | undefined;
  is_contentplus_channel: boolean | null | undefined;
}

const CheckBoxes: any[] = [
  { key: "paid_flag", value: "Paid Flag" },
  { key: "ad_flag", value: "Ads Flag" },
  { key: "promotion_flag", value: "Promotion Flag" },
  { key: "is_adsplus_channel", value: "Ads Plus Flag" },
  { key: "is_contentplus_channel", value: "Content Plus Flag" },
];

const MultiCheckTable: React.FC<CheckBoxType> = (props) => {
  const paid_field = useField("paid_flag");
  const { setValue: paidValue } = paid_field[2];
  const ad_field = useField("ad_flag");
  const { setValue: adValue } = ad_field[2];
  const promotion_field = useField("promotion_flag");
  const { setValue: promotionValue } = promotion_field[2];
  const ads_plus_field = useField("is_adsplus_channel");
  const { setValue: adsPlusValue } = ads_plus_field[2];
  const content_plus_field = useField("is_contentplus_channel");
  const { setValue: contentPlusValue } = content_plus_field[2];
  const [checkbox, setCheckbox] = useState<CheckBoxType>({ ...props });

  useEffect(() => {
    paidValue(checkbox.paid_flag);
    adValue(checkbox.ad_flag);
    promotionValue(checkbox.promotion_flag);
    adsPlusValue(checkbox.is_adsplus_channel);
    contentPlusValue(checkbox.is_contentplus_channel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkbox]);

  return (
    <div style={{ padding: "5px 0px" }}>
      <table>
        <thead className="text-gray-400 border-0 border-b border-gray-50">
          <tr style={{ background: "#FAFAFB" }}>
            <th className="text-left py-2 pl-2 text-gray-800">Flags</th>
            <th className="text-left py-2 pl-2"></th>
          </tr>
        </thead>
        <tbody>
          {CheckBoxes.map((cb: any, index: number) => {
            return (
              <tr key={index} className="border-0 border-b border-gray-50">
                <td className="text-left py-2 pl-2 flex">
                  <span className="mr-2">{cb.value}</span>
                  {/* <InformationCircleIcon
                    className="h-5 w-5"
                    style={{ color: "#9EA4B3" }}
                  /> */}
                </td>
                <td className="py-2">
                  <input
                    type="checkbox"
                    name={cb.key}
                    onChange={(e) =>
                      setCheckbox({
                        ...checkbox,
                        [cb.key]: e.target.checked,
                      })
                    }
                    checked={checkbox[cb.key as keyof CheckBoxType]!}
                    // className={`h-4 w-4 font-bold ${
                    //   checkbox[cb.key as keyof CheckBoxType]
                    //     ? "text-gray-500"
                    //     : "text-gray-300"
                    // }`}
                    className="focus:ring-0"
                  />
                </td>
                {/* <td className="py-2">
                  <CheckIcon
                    name={cb.key}
                    onClick={() =>
                      setCheckbox({
                        ...checkbox,
                        [cb.key]: true,
                      })
                    }
                    className={`h-4 w-4 font-bold ${
                      checkbox[cb.key as keyof CheckBoxType]
                        ? "text-gray-500"
                        : "text-gray-300"
                    }`}
                  />
                </td>
                <td className="py-2">
                  <XIcon
                    name={cb.key}
                    onClick={() =>
                      setCheckbox({
                        ...checkbox,
                        [cb.key]: false,
                      })
                    }
                    className={`h-4 w-4 font-bold ${
                      checkbox[cb.key as keyof CheckBoxType]
                        ? "text-gray-300"
                        : "text-gray-500"
                    }`}
                  />
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MultiCheckTable;
