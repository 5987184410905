import React, { useMemo } from "react";
import CommonChart from "../../charts/chart";
import { DateSort, months } from "../../utils";
import {
  CustomerFinChartDataFragment,
  useCustomerFinChartByPkQuery,
} from "../../../hasura.graphql";
import { useCDM } from "../../../cdm-context";
import { DateTime } from "luxon";
import Loader from "../../../common/loader";

const MonthlyChart: React.FC<{
  fromDate: string;
  setFromDate: React.Dispatch<React.SetStateAction<string>>;
}> = ({ fromDate, setFromDate }) => {
  const { show } = useCDM();
  const { data, loading, error } = useCustomerFinChartByPkQuery({
    variables: {
      amagi_id: show?.amg_id!,
      from_date: DateTime.local()
        .startOf("month")
        .minus({ months: parseInt(fromDate) - 1 })
        .toISO()
        .slice(0, 10),
    },
  });

  if (loading) {
    return (
      <div className="m-5">
        <Loader />
      </div>
    );
  }
  if (error) {
    return (
      <div className="mx-6 my-3">
        <i>Error</i>
      </div>
    );
  }
  if (!data || !data.customer) {
    return <i>Could not retrieve customer financials data</i>;
  }

  const chartData = data.customer;

  return <Chart chartData={chartData} />;
};

const Chart: React.FC<{
  chartData: CustomerFinChartDataFragment;
}> = ({ chartData }) => {
  const {
    booked_revenue,
    billed_revenue,
    actual_revenue,
    netsuite_credit_issued,
  } = chartData;

  const orders = useMemo(
    () => (booked_revenue ? booked_revenue : []),
    [booked_revenue]
  );
  const invoices = useMemo(
    () => (billed_revenue ? billed_revenue : []),
    [billed_revenue]
  );
  const revenue = useMemo(
    () => (actual_revenue ? actual_revenue : []),
    [actual_revenue]
  );

  const credit_issued = useMemo(
    () => (netsuite_credit_issued ? netsuite_credit_issued : []),
    [netsuite_credit_issued]
  );

  const financial_chart = [
    ...orders,
    ...invoices,
    ...revenue,
    ...credit_issued,
  ];
  const chart_label = Array.from(new Set(financial_chart.map((a) => a.month)));

  chart_label.sort((a, b) => DateSort(a, b));

  const filtered_chart_label =
    chart_label.length > 12
      ? chart_label.slice(chart_label.length - 12, chart_label.length)
      : chart_label;

  const revenue_chart_data = useMemo(() => {
    return filtered_chart_label.map((data: any) => {
      let credit = revenue?.find((s: any) => s.month === data);
      if (!credit) {
        return {
          amount: null,
          cummulative: null,
          periodic_total: null,
        };
      }
      return {
        amount: credit.amount,
        // cummulative: credit.cummulative,
        // periodic_total: credit.periodic_total,
      };
    });
  }, [filtered_chart_label, revenue]);

  const orders_chart_data = useMemo(() => {
    return filtered_chart_label.map((data: any) => {
      let order = orders?.find((s: any) => s.month === data);
      if (!order) {
        return {
          amount: null,
          cummulative: null,
          periodic_total: null,
        };
      }
      return {
        amount: order.amount,
        // cummulative: order.cummulative,
        // periodic_total: order.periodic_total,
      };
    });
  }, [filtered_chart_label, orders]);

  const invoices_chart_data = useMemo(() => {
    return filtered_chart_label.map((data: any) => {
      let invoice = invoices?.find((s: any) => s.month === data);
      if (!invoice) {
        return {
          amount: null,
          cummulative: null,
          periodic_total: null,
        };
      }
      return {
        amount: invoice.amount,
        // cummulative: invoice.cummulative,
        // periodic_total: invoice.periodic_total,
      };
    });
  }, [filtered_chart_label, invoices]);

  const credits_chart_data = useMemo(() => {
    return filtered_chart_label.map((data: any) => {
      let credit = credit_issued?.find((s: any) => s.month === data);
      if (!credit) {
        return {
          amount: null,
          cummulative: null,
          periodic_total: null,
        };
      }
      return {
        amount: credit.amount,
        // cummulative: credit.cummulative,
        // periodic_total: credit.periodic_total,
      };
    });
  }, [filtered_chart_label, credit_issued]);

  // let chart_data = [
  //   ...revenue_chart_data,
  //   ...orders_chart_data,
  //   ...invoices_chart_data,
  //   ...credits_chart_data,
  // ];

  // let chart_values: number[] = chart_data.map((num: any) => {
  //   return num.cummulative !== undefined ? num.cummulative : 0;
  // });

  return (
    <div
      className="bg-white border rounded"
      style={{
        height: `${window.innerHeight - 458}px`,
      }}
    >
      <div className="px-5 py-2 text-sm font-semibold border-b">Monthly</div>
      <div
        className="p-3"
        style={{
          height: `${window.innerHeight - 495}px`,
        }}
      >
        <CommonChart
          showLegend={false}
          showDatalabels={true}
          chartData={{
            labels: filtered_chart_label.map(
              (label) =>
                `${months[parseInt(label.slice(5)) - 1]}'${label.slice(2, 4)}`
            ),
            datasets: [
              {
                type: "bar" as const,
                label: "Booked Monthly",
                data: orders_chart_data.map((c: any) => c.amount),
                backgroundColor: ["#5E81C0"],
                maxBarThickness: 15,
                spanGaps: true,
                rotation: 90,
              },
              {
                type: "bar" as const,
                label: "Billed Monthly",
                data: invoices_chart_data.map((c: any) => c.amount),
                backgroundColor: ["#715EC2"],
                maxBarThickness: 15,
                spanGaps: true,
              },
              {
                type: "bar",
                label: "Paid Monthly",
                data: revenue_chart_data.map((c: any) => c.amount),
                backgroundColor: ["#B82B94"],
                maxBarThickness: 15,
                spanGaps: true,
              },
              {
                type: "bar",
                label: "Credit Monthly",
                data: credits_chart_data.map((c: any) => c.amount),
                backgroundColor: ["green"],
                maxBarThickness: 15,
                spanGaps: true,
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

export default MonthlyChart;
