import { useNavigate, useParams } from "react-router-dom";
import LiveDeliveryEditForm from "../components/live-delivery/delivery-edit-form";
import { useFeatureFlagQuery } from "../hasura.graphql";

const LiveDeliveryByIdEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const role_data = useFeatureFlagQuery();

  return (
    <>
      {role_data.data?.feature_flag.find(
        (feat) => feat.feature === "channel-edit"
      ) !== undefined ? (
        <LiveDeliveryEditForm
          close={() => navigate(`/deliveries/live-deliveries/${id}`)}
          deliveryId={id!}
          isModal={false}
        />
      ) : (
        navigate(`/deliveries/live-deliveries/${id}`)
      )}
    </>
  );
};

export default LiveDeliveryByIdEdit;
