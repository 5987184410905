import React, { Fragment, useEffect, useState } from "react";
import { useField } from "formik";
import { ChevronDownIcon, TrashIcon } from "@heroicons/react/outline";
import { channelFields } from "../../constants";
import { ChannelRatingType } from "../channels/channel-edit-form/types";
import { Popover, Transition } from "@headlessui/react";
import TableFilter from "../../common/table-filter";

export type RatingType = {
  country: string;
  region: string;
  region_code: string;
  rating: string;
};

const FormikChannelRating: React.FC<{
  chnRating: Array<ChannelRatingType>;
}> = ({ chnRating }) => {
  const [search, setSearch] = useState<string | null>(null);
  const [inputTable, setInputTable] =
    useState<Array<ChannelRatingType>>(chnRating);

  const handleChange = (
    index: number,
    value: number | string | Array<any>,
    inputKey: string
  ) => {
    let channelRating: Array<any> = inputTable;
    channelRating[index][`${inputKey}` as keyof ChannelRatingType] = value;
    setInputTable(channelRating);
  };

  const handleAddClick = () => {
    setInputTable((input) => [
      ...input,
      {
        region: "",
        country: "",
        rating: "",
      },
    ]);
  };

  const _field = useField("channel_rating");
  const { setValue } = _field[2];

  useEffect(() => {
    (inputTable.every(
      (tab) =>
        tab.country.length > 0 || tab.rating.length > 0 || tab.region.length > 0
    ) ||
      inputTable.length === 0) &&
      setValue(inputTable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputTable]);

  const debouncedRegions =
    channelFields.availability_countries_and_regions.filter((region) => {
      if (!search) {
        return true;
      }
      return region.country.toLowerCase().includes(search.toLowerCase());
    });

  return (
    <div style={{ padding: "5px 0px" }}>
      <label className="text-gray-400 mr-3">Channel Rating</label>
      {inputTable && inputTable.length > 0 && (
        <table className="mt-4" style={{ width: "80%", fontSize: "16px" }}>
          <thead>
            <tr className="bg-gray-300">
              <th className="py-2 pl-2">S No.</th>
              <th className="text-left py-2 pl-2">Region</th>
              <th className="text-left py-2 pl-2">Country</th>
              <th className="text-left py-2 pl-2">Rating</th>
            </tr>
          </thead>
          <tbody className="bg-gray-50">
            {inputTable.map((data: ChannelRatingType, index: number) => {
              return (
                <React.Fragment key={index}>
                  <tr className="">
                    <td className="py-2 pl-2">{index + 1}</td>
                    <td className="text-left py-1 pl-2">
                      <Popover
                        as="div"
                        className="relative items-center max-w-sm"
                      >
                        {({ open, close }) => (
                          <>
                            <Popover.Button className="rounded-full w-full focus:outline-none">
                              <div
                                placeholder={"Enter Text"}
                                style={{ padding: "5.5px 0px" }}
                                className="flex justify-between border-0 text-left focus:ring-0 border-gray-300 outline-none bg-transparent w-full"
                              >
                                <span>{data.region || "Please select"}</span>
                                <span className="mr-3">
                                  <ChevronDownIcon className="h-4 w-4" />
                                </span>
                              </div>
                            </Popover.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Popover.Panel className="origin-top-right text-left z-10 absolute right-0 min-w-full max-h-60 overflow-y-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {() => (
                                  <div className="">
                                    {channelFields.availablity_regions
                                      .sort()
                                      .map((inp: string, rIndex: number) => {
                                        return (
                                          <div key={rIndex} className="">
                                            <div
                                              className={`focus:ring-0 cursor-pointer hover:bg-blue-100 px-3 py-1`}
                                              onClick={() => {
                                                close();
                                                handleChange(
                                                  index,
                                                  inp,
                                                  "region"
                                                );
                                              }}
                                            >
                                              {inp}
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                )}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </td>
                    <td className="text-left py-1 pl-2">
                      <Popover
                        as="div"
                        className="relative items-center max-w-sm"
                      >
                        {({ open, close }) => (
                          <>
                            <Popover.Button className="rounded-full w-full focus:outline-none">
                              <div
                                placeholder={"Enter Text"}
                                style={{ padding: "5.5px 0px" }}
                                className="flex justify-between border-0 text-left focus:ring-0 border-gray-300 outline-none bg-transparent w-full"
                              >
                                <span>{data.country || "Please select"}</span>
                                <span className="mr-3">
                                  <ChevronDownIcon className="h-4 w-4" />
                                </span>
                              </div>
                            </Popover.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Popover.Panel className="origin-top-right text-left z-10 absolute right-0 min-w-full max-h-60 overflow-y-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {() => (
                                  <div className="">
                                    {data.region && (
                                      <div className="p-2 sticky top-0 bg-white">
                                        <TableFilter
                                          filter={search}
                                          setFilter={setSearch}
                                          placeholder="Search"
                                        />
                                      </div>
                                    )}
                                    {debouncedRegions
                                      .filter(
                                        (avail) => avail.region === data.region
                                      )
                                      .filter(
                                        (con) =>
                                          !inputTable.some(
                                            (inp) => inp.country === con.country
                                          )
                                      )
                                      .map((inp, cIndex: number) => {
                                        return (
                                          <div key={cIndex} className="">
                                            <div
                                              className={`focus:ring-0 cursor-pointer hover:bg-blue-100 px-3 py-1`}
                                              onClick={() => {
                                                close();
                                                handleChange(
                                                  index,
                                                  inp.country,
                                                  "country"
                                                );
                                              }}
                                            >
                                              {inp.country}
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                )}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </td>
                    <td className="text-left py-1 pl-2">
                      <Popover
                        as="div"
                        className="relative items-center max-w-sm"
                      >
                        {({ open, close }) => (
                          <>
                            <Popover.Button className="rounded-full w-full focus:outline-none">
                              <div
                                placeholder={"Enter Text"}
                                style={{ padding: "5.5px 0px" }}
                                className="flex justify-between border-0 text-left focus:ring-0 border-gray-300 outline-none bg-transparent w-full"
                              >
                                <span>{data.rating || "Please select"}</span>
                                <span className="mr-3">
                                  <ChevronDownIcon className="h-4 w-4" />
                                </span>
                              </div>
                            </Popover.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Popover.Panel className="origin-top-right text-left z-10 absolute right-0 min-w-full max-h-60 overflow-y-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {() => (
                                  <div className="">
                                    {channelFields.channelRating
                                      .sort()
                                      .map((inp: string, raIndex: number) => {
                                        return (
                                          <div key={raIndex} className="">
                                            <div
                                              className={`focus:ring-0 cursor-pointer hover:bg-blue-100 px-3 py-1 ${
                                                inp === data.rating &&
                                                "bg-blue-500 text-white"
                                              }`}
                                              onClick={() => {
                                                close();
                                                handleChange(
                                                  index,
                                                  inp,
                                                  "rating"
                                                );
                                              }}
                                            >
                                              {inp}
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                )}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </td>
                    <td className="bg-white py-2 pl-2 cursor-pointer">
                      <TrashIcon
                        onClick={() =>
                          setInputTable(inputTable.filter((f) => f !== data))
                        }
                        className="h-5 w-5"
                      />
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      )}
      <div className="py-1 mt-1 w-max rounded px-2 text-sm text-left cursor-pointer text-blue-500">
        <span onClick={handleAddClick}>+ ADD NEW ROW</span>
      </div>
    </div>
  );
};

export default FormikChannelRating;
