import React, { useState, useEffect } from "react";
import { useField } from "formik";
import { XIcon } from "@heroicons/react/outline";

const FormikInputTime: React.FC<any> = ({
  inlinelabel,
  defaultValue,
  ...props
}) => {
  let dateValue =
    defaultValue !== null ? defaultValue.slice(0, 16) : defaultValue;
  const [timeValue, setTimeValue] = useState<string | null>(dateValue);
  const _field = useField(props);
  const field = _field[0];
  const { setValue } = _field[2];

  useEffect(() => {
    if (timeValue) setValue(`${timeValue}:00.000`);
    else setValue(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeValue]);
  return (
    <div className="flex items-center">
      <div style={{ padding: "5px 0px" }}>
        <label className="text-gray-400 text-sm">{inlinelabel}</label>
        <input
          type="time"
          id="time_demo"
          value={timeValue || ""}
          name={field.name}
          onChange={(e) => {
            setTimeValue(e.target.value);
          }}
          className="py-1 px-2 focus:ring-0 font-semibold border-0 border-b border-gray-300 outline-none text-gray-800 w-full"
        />
      </div>
      <span
        onClick={() => setTimeValue(null)}
        className="text-blue-500 cursor-pointer"
      >
        <XIcon className="h-5 w-5" />
      </span>
    </div>
  );
};

export default FormikInputTime;
