import { createContext, useContext, Dispatch, SetStateAction } from "react";

interface CDPRoleContextProps {
  CDPRole: Array<string>;
  setCDPRole: Dispatch<SetStateAction<Array<string>>>;
}

export const CDPRoleContext = createContext({} as CDPRoleContextProps);

export function useCDPRole() {
  return useContext(CDPRoleContext);
}
